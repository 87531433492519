import {
  fetchMicrosoft365Integrations,
  fetchMicrosoft365IntegrationsDone,
  genericError,
  removeMicrosoft365IntegrationDone,
  success,
} from 'actions'
import {handler} from './handler'
import config from 'config'

export function* handleFetchMicrosoft365Integrations(action) {
  yield handler({
    handler: _fetchMicrosoft365Integrations,
    error: [
      () =>
        genericError({
          loading: {microsoft365Integrations: false},
        }),
    ],
    success: fetchMicrosoft365IntegrationsDone,
    action,
  })
}

const _fetchMicrosoft365Integrations = function () {
  return fetch(config.SERVER_URL + '/microsoft365/integrations', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleRemoveMicrosoft365Integration(action) {
  yield handler({
    handler: removeMicrosoft365Integration,
    error: genericError,
    success: [
      () =>
        success({
          message: action.payload.successMessage,
        }),
      removeMicrosoft365IntegrationDone,
      fetchMicrosoft365Integrations,
    ],
    action,
  })
}

const removeMicrosoft365Integration = function (body) {
  return fetch(
    `${config.SERVER_URL}/microsoft365/integrations/${body.integrationId}`,
    {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

const _fetchMicrosoft365Authorization = body => {
  const url = `${config.SERVER_URL}/microsoft365/integrations`

  return fetch(url, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchMicrosoft365Authorization(action) {
  yield handler({
    handler: _fetchMicrosoft365Authorization,
    success: res => (window.location.href = res.authUrl),
    action,
  })
}
