import React from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'

import {DefaultModal} from 'components/partials/DefaultModal'
import {removeQuestionnaire} from 'actions'
import {Trans} from 'react-i18next'

const QuestionnaireRemoveModal = ({
  removeQuestionnaireModal,
  setRemoveQuestionnaireModal,
}) => {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)

  const handleRemoveQuestionnaire = questionnaireId => {
    dispatch(
      removeQuestionnaire({
        questionnaireId,
        _csrf: csrfState,
      })
    )

    setRemoveQuestionnaireModal({
      selectedQuestionnaire: null,
      showModal: false,
    })
  }

  return (
    <DefaultModal
      id="removeQuestionnaireModal"
      title={i18next.t('questionnaires.remove.modal.title')}
      primaryButtonTheme="red"
      primaryBtnText={i18next.t('buttons.remove')}
      secondayBtnText={i18next.t('buttons.cancel')}
      body={
        <div className="text-sm text-gray-700 leading-6">
          <Trans
            i18nKey="questionnaires.remove.modal.body"
            values={{
              questionnaireName:
                removeQuestionnaireModal.selectedQuestionnaire.name,
            }}
          />
        </div>
      }
      onClickPrimaryBtn={() =>
        handleRemoveQuestionnaire(
          removeQuestionnaireModal.selectedQuestionnaire.id
        )
      }
      onClickSecondaryBtn={() => {
        setRemoveQuestionnaireModal({
          selectedQuestionnaire: null,
          showModal: false,
        })
      }}
    />
  )
}

export default QuestionnaireRemoveModal

QuestionnaireRemoveModal.propTypes = {
  removeQuestionnaireModal: PropTypes.object,
  setRemoveQuestionnaireModal: PropTypes.func,
}
