import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import {batch, useDispatch, useSelector} from 'react-redux'

import images from 'res'
import {
  fetchAwsStats,
  fetchAzureStats,
  fetchGcpStats,
  toggleRestrictionModal,
} from 'actions'
import {hasCloudIntegration} from 'utils'

import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import Spinner from 'components/partials/Spinner'
import BackButton from 'components/partials/buttons/BackButton'
import {Page} from 'components/partials/Page'
import {useNavigate} from 'react-router-dom'
import PlanUtils from 'utils/plan'

export default function Integration() {
  const [shouldDisplayOptions, setshouldDisplayOptions] = useState(false)

  const awsStatsState = useSelector(state => state.awsStats)
  const gcpStatsState = useSelector(state => state.gcpStats)
  const azureStatsState = useSelector(state => state.azureStats)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const planUtils = PlanUtils()

  const providers = [
    {
      name: 'Amazon Web Services',
      icon: images.awsIcon,
      integrationPath: '/cloud/aws/integration/options',
    },
    {
      name: 'Google Cloud Platform',
      icon: images.gcpIcon,
      integrationPath: '/cloud/gcp/integration/options',
    },
    {
      name: 'Microsoft Azure',
      icon: images.azureIcon,
      integrationPath: '/cloud/azure/integration',
    },
  ]

  const providerContainerStyle =
    'flex flex-col items-center justify-center text-center text-sm border border-sideral-100 rounded-md w-64 h-64 mt-16 px-2'
  const figureContainerStyle = 'flex justify-center my-8'
  const textcontainerStyle = 'text-astral-900 text-base'

  useEffect(() => {
    if (!planUtils.hasPlanRestriction())
      batch(() => {
        dispatch(fetchAzureStats())
        dispatch(fetchAwsStats())
        dispatch(fetchGcpStats())
      })
  }, [])

  useEffect(() => {
    const shouldDisplay =
      (awsStatsState && gcpStatsState && azureStatsState) ||
      planUtils.hasPlanRestriction()

    setshouldDisplayOptions(shouldDisplay)
  }, [awsStatsState, azureStatsState, gcpStatsState])

  const onClickIntegrationMethod = provider => {
    provider.name === 'Microsoft Azure' && planUtils.hasPlanRestriction()
      ? dispatch(
          toggleRestrictionModal({
            contentId: 'cloudIntegrationTutorialBtn',
            showModal: true,
          })
        )
      : navigate(provider.integrationPath)
  }

  return (
    <Page pageTitle={i18next.t('pageTitles.cloud')}>
      {shouldDisplayOptions ? (
        <>
          {hasCloudIntegration(
            awsStatsState,
            gcpStatsState,
            azureStatsState
          ) ? (
            <BackButton fallbackLink="/cloud" />
          ) : (
            <></>
          )}
          <div
            className={`border border-sideral-100 bg-white rounded-lg p-2 py-16 mt-4`}>
            <div className="text-center text-astral-700">
              <p className="text-base md:text-lg">
                {i18next.t('cloud.monitorYourCloud1')}
                <br />
                {i18next.t('cloud.monitorYourCloud2')}
              </p>
              <p className="text-sm font-light mt-4">
                {i18next.t('cloud.startIntegration')}
              </p>
            </div>
            <div className="flex flex-wrap w-full justify-around">
              {providers.map((provider, index) => (
                <div key={`${provider} ${index}`}>
                  <div className={providerContainerStyle}>
                    <div className={textcontainerStyle}>
                      <p>{provider.name}</p>
                      <figure className={figureContainerStyle}>
                        <img src={provider.icon} className="h-12" />
                      </figure>
                      <SecondaryButton
                        text={i18next.t('buttons.startMonitoring')}
                        direction="right"
                        size="sm"
                        onClick={() => onClickIntegrationMethod(provider)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </Page>
  )
}
