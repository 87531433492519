import React, {useState, useEffect} from 'react'
import Spinner from '../partials/Spinner'
import {WebhookForm} from './WebhookForm'
import {WebhookList} from './WebhookList'
import {fetchWebhooks} from 'actions'
import {useSelector, useDispatch} from 'react-redux'
import {WebhookLogs} from './WebhookLogs'
import ArrowIcon from 'res/icons/ArrowIcon'
import PlanUtils from 'utils/plan'
import Subsection from 'components/partials/headers/Subsection'

const Webhooks = () => {
  const webhooksState = useSelector(state => state.webhooks)

  const dispatch = useDispatch()

  const [addScreen, showAddScreen] = useState(false)
  const [webhookSelected, selectWebhook] = useState(null)
  const [webhooks, setWebhooks] = useState([])

  const planUtils = PlanUtils()

  useEffect(() => {
    if (!planUtils.hasPlanRestriction()) {
      dispatch(fetchWebhooks())
    }
  }, [dispatch])

  useEffect(() => {
    if (webhooksState) setWebhooks(webhooksState)
  }, [webhooksState])

  return (
    <Subsection
      title={
        <div className="flex">
          {(addScreen || webhookSelected) && (
            <figure
              className="cursor-pointer pr-4"
              onClick={() => {
                showAddScreen(false)
                selectWebhook(null)
              }}>
              <ArrowIcon color="#528CD4" width="16" direction="left" />
            </figure>
          )}
          <div>Webhooks</div>
        </div>
      }
      content={
        <>
          <div className="flex flex-wrap border border-sideral-50 p-6">
            {planUtils.hasPlanRestriction() ? (
              <WebhookList
                webhooks={[]}
                showAddScreen={showAddScreen}
                selectWebhook={selectWebhook}
              />
            ) : webhookSelected ? (
              <>
                <WebhookForm
                  selectWebhook={selectWebhook}
                  webhookSelected={webhookSelected}
                  showAddScreen={showAddScreen}
                  webhook={webhookSelected}
                />
              </>
            ) : (
              <>
                {addScreen && webhooksState ? (
                  <WebhookForm
                    showAddScreen={showAddScreen}
                    addScreen={addScreen}
                    webhookSelected={webhookSelected}
                    selectWebhook={selectWebhook}
                  />
                ) : (
                  <>
                    {webhooksState ? (
                      <WebhookList
                        webhooks={webhooks}
                        showAddScreen={showAddScreen}
                        selectWebhook={selectWebhook}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </>
                )}
              </>
            )}
          </div>

          {webhookSelected && (
            <div className="rounded-md border border-sideral-50 mt-8">
              <WebhookLogs webhook={webhookSelected} />
            </div>
          )}
        </>
      }
    />
  )
}

export default Webhooks
