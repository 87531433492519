import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {useLocation} from 'react-router-dom'
import images from 'res'
import {useDispatch, useSelector} from 'react-redux'
import {fetchBulkFindings, loading} from 'actions'
import {getSeverityColor} from 'utils/score'
import {sideral300, statusList} from 'constant'
import PrimaryTable from 'components/partials/tables/PrimaryTable'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {DefaultModal} from 'components/partials/DefaultModal'
import Spinner from 'components/partials/Spinner'
import {Trans} from 'react-i18next'
import {isLoading} from 'utils'

const StepTwo = ({bulkForm, setCurrentStep}) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const bulkFindingsState = useSelector(state => state.bulkFindings)

  const [findings, setFindings] = useState([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const {
    allRowsSelected,
    selectedRowIds,
    unselectedRowIds,
    filterApplied,
    globalFilter,
  } = location.state

  useEffect(() => {
    dispatch(loading({bulkFindings: true}))
    dispatch(
      fetchBulkFindings({
        allSelected: allRowsSelected,
        selected: selectedRowIds,
        unselected: unselectedRowIds,
        filterApplied: {
          ...filterApplied,
          global: globalFilter,
        },
        _csrf: csrfState,
      })
    )
  }, [])

  useEffect(() => {
    if (bulkFindingsState) setFindings(bulkFindingsState.findings)
  }, [bulkFindingsState])

  const columns = useMemo(
    () => [
      {
        Header: i18next.t('management.bulkChange.stepTwo.table.title'),
        accessor: 'title',
        classes: 'text-left',
        Cell: cell => (
          <div className="flex items-center">
            <div
              className={`${getSeverityColor(
                cell.row.original.severity
              )} severity-indicator flex-shrink-0 mr-2`}
            />
            <div className="break-all truncate">{cell.value}</div>
          </div>
        ),
      },
      {
        Header: i18next.t('management.bulkChange.stepTwo.table.transition'),
        accessor: 'status',
        classes: 'text-center',
        Cell: cell => (
          <div className="flex items-center justify-center">
            <div>{statusList[cell.value]}</div>
            <i className="icofont-long-arrow-right text-3xl mx-4" />
            <div>{statusList[bulkForm.newStatus]}</div>
          </div>
        ),
      },
    ],
    []
  )

  return (
    <div id="body">
      <div className="font-medium text-xl bg-astral-50 border-b px-8 py-8 mb-10">
        <Trans
          i18nKey="management.bulkChange.stepTwo.title"
          values={{
            qty: findings.length,
          }}
        />
      </div>

      <div className="px-8">
        {!isLoading(loadingState.bulkFindings) ? (
          <PrimaryTable
            data={findings}
            columns={columns}
            pageNumber={0}
            pageSize={10}
            emptyStateWithFilter={
              <GenericEmptyState
                icon={<images.ManagementIcon width="50" color={sideral300} />}
                title={i18next.t('tables.nothingFoundWithSearch')}
                body={i18next.t('tables.searchSomethingElse')}
                margin="m-2 mt-4"
              />
            }
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<images.ManagementIcon width="50" color={sideral300} />}
                title={i18next.t('tables.nothingFound')}
                body={i18next.t('management.bulkChange.nothingFound')}
                margin="m-2 mt-4"
              />
            }
          />
        ) : (
          <div className="mt-20">
            <Spinner />
          </div>
        )}

        <div id="footer" className="flex justify-end mt-8">
          <PrimaryButton
            text={i18next.t('management.bulkChange.backBtn')}
            margin="mr-2"
            theme="blue-outline"
            onClick={() => setCurrentStep(prev => prev - 1)}
          />
          <PrimaryButton
            text={i18next.t('management.bulkChange.confirmBtn')}
            onClick={() => setShowConfirmationModal(true)}
          />
        </div>
      </div>

      {showConfirmationModal && (
        <DefaultModal
          id="answeredQuestionnaireModal"
          title={i18next.t('management.bulkChange.stepTwo.modal.title')}
          body={
            <div className="text-gray-700">
              <Trans
                i18nKey="management.bulkChange.stepTwo.modal.description"
                values={{
                  qty: findings.length,
                }}
              />
            </div>
          }
          primaryBtnText={i18next.t(
            'management.bulkChange.stepTwo.modal.continueBtn'
          )}
          secondayBtnText={i18next.t(
            'management.bulkChange.stepTwo.modal.cancelBtn'
          )}
          onClickPrimaryBtn={() => setCurrentStep(prev => prev + 1)}
          onClickSecondaryBtn={() => setShowConfirmationModal(false)}
        />
      )}
    </div>
  )
}

export default StepTwo

StepTwo.propTypes = {
  bulkForm: PropTypes.object,
  setCurrentStep: PropTypes.func,
}
