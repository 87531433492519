import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

export default function Pagination({
  handleNext,
  handlePrevious,
  canNextPage,
  canPreviousPage,
  paginationClass,
  pageIndex,
  pageCount,
  total,
}) {
  return (
    <div className="flex items-center justify-end text-sideral-700">
      <div className={`${total === 0 && 'hidden'} text-right text-xs px-2`}>
        <span>
          {i18next.t('tables.page')}{' '}
          <strong>
            {pageIndex + 1} {i18next.t('misc.of').toLowerCase()} {pageCount}
          </strong>
        </span>
        <span>
          {' '}
          | <strong>{total}</strong> {i18next.t('tables.items').toLowerCase()}
        </span>
      </div>
      <div className={`pagination ${paginationClass}`}>
        <button onClick={handlePrevious} disabled={!canPreviousPage}>
          <i
            className={
              'text-xl icofont-rounded-left' +
              (canPreviousPage ? ' text-sky-500' : ' text-sideral-100')
            }></i>
        </button>
        <button onClick={handleNext} disabled={!canNextPage}>
          <i
            className={
              'text-xl icofont-rounded-right' +
              (canNextPage ? ' text-sky-500' : ' text-sideral-100')
            }></i>
        </button>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  handleNext: PropTypes.func,
  handlePrevious: PropTypes.func,
  canNextPage: PropTypes.bool,
  canPreviousPage: PropTypes.bool,
  paginationClass: PropTypes.string,
  pageIndex: PropTypes.number,
  pageCount: PropTypes.number,
  rows: PropTypes.array,
  total: PropTypes.number,
}
