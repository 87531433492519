import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import Select from 'react-select'
import {updateFilter} from 'actions'
import {useDispatch, useSelector} from 'react-redux'
import {severityList, statusList} from 'constant'
import i18next from 'i18next'

const reactSelectStyles = {
  placeholder: provided => ({
    ...provided,
    color: '#a0afc0',
  }),
  container: provided => ({
    ...provided,
  }),
}

const reactSelectTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral20: '#e2e8f0',
  },
})

export function matchesSeverityMultiSelect(rows, id, filterValue) {
  return rows.filter(row => {
    const prop = row && row.values[id].toLowerCase()
    return filterValue && filterValue.length
      ? filterValue.find(x => x.value === prop)
      : true
  })
}

export function StatusFilter({column: {filterValue, setFilter, defaultValue}}) {
  const dispatch = useDispatch()
  const filtersState = useSelector(state => state.filters)

  const filterType = 'status'

  const filterShouldBeUpdated =
    filtersState &&
    filtersState.partner &&
    filtersState.partner[filterType] &&
    filterValue !== filtersState.partner[filterType]

  const filterShouldBeEmptied =
    filtersState &&
    filtersState.partner &&
    !filtersState.partner[filterType] &&
    filterValue

  function handleFilter(filter) {
    dispatch(
      updateFilter({
        partner: {
          ...filtersState.partner,
          [filterType]: filter,
        },
      })
    )
  }

  useEffect(() => {
    if (!filterValue && !filtersState.partner) {
      dispatch(
        updateFilter({
          partner: {
            ...filtersState.partner,
            [filterType]: defaultValue,
          },
        })
      )
    }
  }, [])

  useEffect(() => {
    if (filterShouldBeEmptied || filterShouldBeUpdated) {
      setFilter(filtersState.partner[filterType])
    }
  }, [filtersState.partner])

  return (
    <div className="w-full text-xs inline-block pl-2">
      <Select
        isMulti
        placeholder={i18next.t('tables.filterByStatus')}
        styles={reactSelectStyles}
        options={
          statusList &&
          Object.keys(statusList)
            .filter(key => {
              return key === 'new' ? false : true
            })
            .map(key => ({
              value: key,
              label: statusList[key],
            }))
        }
        theme={reactSelectTheme}
        value={filterValue}
        onChange={item => {
          handleFilter(item)
        }}
      />
    </div>
  )
}

export function SeverityFilter({column: {filterValue, setFilter}}) {
  const dispatch = useDispatch()
  const filtersState = useSelector(state => state.filters)

  const filterType = 'severity'

  const filterShouldBeUpdated =
    filtersState &&
    filtersState.partner &&
    filtersState.partner[filterType] &&
    filterValue !== filtersState.partner[filterType]

  const filterShouldBeEmptied =
    filtersState &&
    filtersState.partner &&
    !filtersState.partner[filterType] &&
    filterValue

  function handleFilter(filter) {
    dispatch(
      updateFilter({
        partner: {
          ...filtersState.partner,
          [filterType]: filter,
        },
      })
    )
  }

  useEffect(() => {
    if (filterShouldBeEmptied || filterShouldBeUpdated) {
      setFilter(filtersState.partner[filterType])
    }
  }, [filtersState.partner])

  return (
    <div className="text-xs w-full ">
      <Select
        isMulti
        placeholder={i18next.t('tables.filterBySeverity')}
        styles={reactSelectStyles}
        options={
          severityList &&
          Object.keys(severityList).map(key => ({
            value: key,
            label: severityList[key],
          }))
        }
        theme={reactSelectTheme}
        value={filterValue}
        onChange={item => {
          handleFilter(item)
        }}
      />
    </div>
  )
}

SeverityFilter.propTypes = {column: PropTypes.object}
StatusFilter.propTypes = {column: PropTypes.object}
