import i18next from 'i18next'

export const findingsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.title'),
        accessor: 'title',
      },
      {
        Header: 'Resource ID',
        accessor: 'resourceId',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 60,
      },
      {
        Header: i18next.t('tables.severity'),
        accessor: 'severity',
        width: 50,
      },
      {
        Header: i18next.t('tables.seen'),
        accessor: 'bestDate',
        width: 50,
        sortType: (a, b) => {
          return new Date(a.original.updatedAt) - new Date(b.original.updatedAt)
        },
      },
    ],
  },
]

export const subscriptionsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'ID',
        accessor: 'resourceId',
      },
      {
        Header: 'Display name',
        accessor: 'data.displayName',
      },
      {
        Header: 'Tenant',
        accessor: 'data.tenantId',
      },
      {
        Header: 'Status',
        accessor: 'data.state',
      },
    ],
  },
]

export const mysqlServersColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'data.name',
      },
      {
        Header: 'FQDN',
        accessor: 'data.fullyQualifiedDomainName',
      },
      {
        Header: 'Resource group',
        accessor: 'resourceGroup',
      },
      {
        Header: 'Location',
        accessor: 'data.location',
      },
      {
        Header: 'Storage (MB)',
        accessor: 'data.storageProfile.storageMB',
      },
    ],
  },
]

export const postgresqlServersColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'data.name',
      },
      {
        Header: 'FQDN',
        accessor: 'data.fullyQualifiedDomainName',
      },
      {
        Header: 'Resource group',
        accessor: 'resourceGroup',
      },
      {
        Header: 'Location',
        accessor: 'data.location',
      },
      {
        Header: 'Storage (MB)',
        accessor: 'data.storageProfile.storageMB',
      },
    ],
  },
]

export const resourceGroupsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'data.name',
      },
      {
        Header: 'Location',
        accessor: 'data.location',
      },
      {
        Header: 'Subscription',
        accessor: 'subscriptionId',
      },
    ],
  },
]

export const sqlDatabasesColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'data.name',
      },
      {
        Header: 'Resource Group',
        accessor: 'resourceGroup',
      },
      {
        Header: 'Server name',
        accessor: 'data.serverName',
      },
    ],
  },
]

export const sqlServersColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'data.name',
      },
      {
        Header: 'Resource Group',
        accessor: 'resourceGroup',
      },
      {
        Header: 'Location',
        accessor: 'data.location',
      },
      {
        Header: 'Type',
        accessor: 'data.type',
      },
    ],
  },
]

export const storageAccountsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'data.name',
      },
      {
        Header: 'Resource Group',
        accessor: 'resourceGroup',
      },
      {
        Header: 'Kind',
        accessor: 'data.kind',
      },
      {
        Header: 'Status',
        accessor: 'data.statusOfPrimary',
      },
      {
        Header: 'Created at',
        accessor: 'data.creationTime',
      },
    ],
  },
]

export const blobContainersColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'data.name',
      },
      {
        Header: 'Resource Group',
        accessor: 'resourceGroup',
      },
      {
        Header: 'Status',
        accessor: 'data.leaseState',
      },
      {
        Header: 'Last modified time',
        accessor: 'data.lastModifiedTime',
      },
    ],
  },
]

export const vmInstancesColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'data.name',
      },
      {
        Header: 'Resource Group',
        accessor: 'resourceGroup',
      },
      {
        Header: 'OS Type',
        accessor: 'data.storageProfile.osDisk.osType',
      },
      {
        Header: 'SKU',
        accessor: 'data.storageProfile.imageReference.sku',
      },
      {
        Header: 'VM Size',
        accessor: 'data.hardwareProfile.vmSize',
      },
    ],
  },
]

export const disksColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'data.sku.name',
      },
      {
        Header: 'OS type',
        accessor: 'data.osType',
      },
      {
        Header: 'Disk state',
        accessor: 'data.diskState',
      },
      {
        Header: 'Disk size (GB)',
        accessor: 'data.diskSizeGB',
      },
    ],
  },
]
