import React from 'react'
import PropTypes from 'prop-types'

export default function WebIcon({width, height, color, opacity}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path
          d="M30.6667 14.6801L24 14.6667C23.2667 14.6667 22.6667 15.2667 22.6667 16.0001V28.0001C22.6667 28.7334 23.2667 29.3334 24 29.3334H30.6667C31.4 29.3334 32 28.7334 32 28.0001V16.0001C32 15.2667 31.4 14.6801 30.6667 14.6801ZM30.6667 26.6667H24V17.3334H30.6667V26.6667ZM26.6667 2.66675H2.66667C1.2 2.66675 0 3.86675 0 5.33341V21.3334C0 22.8001 1.2 24.0001 2.66667 24.0001H12V26.6667H10.6667C9.93333 26.6667 9.33333 27.2667 9.33333 28.0001C9.33333 28.7334 9.93333 29.3334 10.6667 29.3334H18.6667C19.4 29.3334 20 28.7334 20 28.0001C20 27.2667 19.4 26.6667 18.6667 26.6667H17.3333V24.0001H18.6667C19.4 24.0001 20 23.4001 20 22.6667C20 21.9334 19.4 21.3334 18.6667 21.3334H4C3.26667 21.3334 2.66667 20.7334 2.66667 20.0001V6.66675C2.66667 5.93341 3.26667 5.33341 4 5.33341H25.3333C26.0667 5.33341 26.6667 5.93341 26.6667 6.66675V10.6667C26.6667 11.4001 27.2667 12.0001 28 12.0001C28.7333 12.0001 29.3333 11.4001 29.3333 10.6667V5.33341C29.3333 3.86675 28.1333 2.66675 26.6667 2.66675Z"
          fill={color}
        />
        <path
          d="M19.6525 17.1755L16.7798 14.3229L19.1006 12.7066C19.5747 12.3764 19.5199 11.9491 18.9787 11.7572L11.6923 9.17191C11.6038 9.14047 11.5136 9.11695 11.4233 9.10065C11.0291 8.91832 10.5644 8.98305 10.2671 9.30022C9.9193 9.67117 9.91477 10.2652 10.2324 10.6914C10.2533 10.7771 10.2784 10.8611 10.3082 10.9419L12.93 18.0555C13.1329 18.6065 13.5515 18.651 13.8601 18.1549L15.2722 15.8843C15.2931 15.8507 15.3157 15.8163 15.3394 15.7811L18.2501 18.6714C18.68 19.0983 19.3473 19.1109 19.7328 18.6996C20.1184 18.2884 20.0824 17.6024 19.6525 17.1755Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

WebIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
}
