import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import i18next from 'i18next'
import {fetchQuestionnaireDetails, loading} from 'actions'
import {baseQuestionnaireId} from 'constant'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import Spinner from 'components/partials/Spinner'
import images from 'res'
import {isLoading} from 'utils'

export const QuestionnaireBase = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loadingState = useSelector(state => state.loading)
  const questionnaireBaseDetailsState = useSelector(
    state => state.questionnaireBaseDetails
  )

  useEffect(() => {
    dispatch(loading({questionnaireBaseDetails: true}))
    dispatch(
      fetchQuestionnaireDetails({
        questionnaireId: baseQuestionnaireId,
      })
    )
  }, [])

  return (
    <div className="bg-blue-100 border border-blue-200 rounded-lg p-6 mb-10">
      <div className="md:flex items-center">
        <div className="md:flex items-center basis-5/6 text-center md:text-left">
          <div className="mr-8 mb-4 md:mb-0">
            <img
              className="w-32 md:w-56 m-auto"
              src={images.BaseQuestionnaire}
            />
          </div>
          <div>
            <div className="font-medium text-lg">
              {i18next.t('questionnaires.base.title')}
            </div>
            <div className="mt-2 mb-3 mr-4 text-sm">
              {i18next.t('questionnaires.base.description')}
            </div>
            <div className="md:w-32">
              <PrimaryButton
                text={i18next.t('questionnaires.base.actionBtn')}
                theme="blue-outline"
                size="full"
                onClick={() =>
                  navigate(`/questionnaires/${baseQuestionnaireId}`)
                }
              />
            </div>
          </div>
        </div>
        <div className="text-center basis-1/6 border-l border-blue-200 pl-6 xl:pl-0 hidden md:block">
          {isLoading(loadingState.questionnaireBaseDetails) ? (
            <Spinner />
          ) : (
            <>
              <div className="font-medium text-lg uppercase">
                /{i18next.t('questionnaires.base.progress')}
              </div>
              <div className="font-medium text-4xl">
                {questionnaireBaseDetailsState?.progress}
              </div>
              <div className="m-auto w-20">
                <div className="h-2 bg-blue-200 rounded-sm">
                  <div
                    className="h-2 bg-sky-500"
                    style={{width: questionnaireBaseDetailsState?.progress}}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
