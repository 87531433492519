import AwesomeDebouncePromise from 'awesome-debounce-promise'
import {i18next} from 'translate/i18n'
import Moment from 'react-moment'
import PropTypes from 'prop-types'
import React, {useEffect, useRef, useState} from 'react'
import Select from 'react-select'
import useConstant from 'use-constant'
import {v4 as uuidv4} from 'uuid'
import {useNavigate, Link} from 'react-router-dom'
import {saveFilter} from 'actions'
import {useDispatch} from 'react-redux'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import {getSeverityString} from 'utils/score'
import {tableStyles, reactSelectStyles, reactSelectTheme} from 'constant'

import Spinner from 'components/partials/Spinner'
import GlobalFilter from 'components/partials/tables/GlobalFilter'
import Pagination from 'components/partials/tables/Pagination'
import ScoreBadge from 'components/partials/ScoreBadge'
import ServicesCell from './ServicesCell'

function Table(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [asOrgFilter, setAsOrgFilter] = useState([])
  const [countryFilter, setCountryFilter] = useState([])
  const [asOrgFilterOptions, setAsOrgFilterOptions] = useState([])
  const [countryFilterOptions, setCountryFilterOptions] = useState([])
  const [dynamicHeight, setDynamicHeight] = useState('156')
  const [expanded, setExpanded] = useState({})
  const [filterValue, setFilterValue] = useState('')
  const [firstPageLoad, setFirstPageLoad] = useState(true)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  const tableRef = useRef()

  const params = new URLSearchParams(window.location.search)

  const prepareFilterArray = filterItems => {
    return filterItems && filterItems.length
      ? filterItems.map(filterItems => btoa(encodeURI(filterItems.value)))
      : []
  }

  const fetchData = (asOrgFilter, countryFilter, fromNextPage = false) => {
    setLoading(true)

    props.fetchData({
      filterValue,
      filterOptions: getFilterOptions(asOrgFilter, countryFilter),
      pageIndex: fromNextPage ? pageIndex + 1 : pageIndex - 1,
      pageSize: props.pageSize,
      sortBy: sortBy,
    })
  }

  const deleteUrlParam = param => {
    if (params.has(param)) {
      params.delete(param)
      navigate(params)
    }
  }

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 200, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,

    setGlobalFilter,

    state: {pageIndex, sortBy},
  } = useTable(
    {
      columns: props.columns,
      data: items,
      defaultColumn,

      manualPagination: true,
      manualSortBy: true,

      autoResetPage: false,
      autoResetSortBy: false,

      pageCount: props.pageCount,

      initialState: {
        pageIndex: props.pageIndex || 0,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  const handleViewMore = row => {
    expanded[row.id] = true
    setExpanded({...expanded})
  }

  const handleClickIssue = issueId => {
    dispatch(saveFilter(filterValue))
    navigate(`/issue/${issueId}`)
  }

  const handlePrevious = () => {
    fetchData(asOrgFilter, countryFilter)
    previousPage()
  }

  const handleNext = () => {
    fetchData(asOrgFilter, countryFilter, true)
    nextPage()
  }

  const refreshTableData = (
    filterValue,
    sortBy,
    asOrgFilter,
    countryFilter
  ) => {
    setLoading(true)

    props.fetchData({
      pageIndex: pageIndex,
      pageSize: props.pageSize,
      filterValue: filterValue,
      filterOptions: getFilterOptions(asOrgFilter, countryFilter),
      sortBy: sortBy,
    })

    props.setFilterOptions({
      ...getFilterOptions(asOrgFilter, countryFilter),
      filterValue,
    })
  }

  const debouncedRefreshTable = useConstant(() =>
    AwesomeDebouncePromise(refreshTableData, 1000)
  )

  const initAsOrgFilter = () => {
    if (props.asOrgOptions?.length) {
      const options = []

      props.asOrgOptions.map(asOrg => {
        options.push({
          label: asOrg.asOrg,
          value: asOrg.id ? asOrg.id : asOrg.asOrg,
        })
      })

      setAsOrgFilterOptions(options)
    }
  }

  const initCountryFilter = () => {
    if (props.countryFilterOptions?.length) {
      const options = []

      props.countryFilterOptions.map(item => {
        options.push({
          label: item.name,
          value: item.code,
        })
      })

      setCountryFilterOptions(options)
    }
  }

  const handleChangeAsorgFilter = asOrgFilterItems => {
    setAsOrgFilter(asOrgFilterItems)
    fetchData(asOrgFilterItems, countryFilter)

    deleteUrlParam('asOrg')
  }

  const handleChangeCountryFilter = countryFilterItems => {
    setCountryFilter(countryFilterItems)
    fetchData(asOrgFilter, countryFilterItems)

    deleteUrlParam('country')
  }

  const getFilterOptions = (asOrgFilter, countryFilter) => ({
    asOrgFilter: [prepareFilterArray(asOrgFilter)],
    countryFilter: countryFilter.map(item => item.value),
  })

  useEffect(() => {
    setFirstPageLoad(false)
  }, [])

  useEffect(() => {
    if (!firstPageLoad)
      props.setFilterOptions({
        ...getFilterOptions(asOrgFilter, countryFilter),
        filterValue,
      })
  }, [asOrgFilter, countryFilter])

  useEffect(() => {
    setLoading(false)
    if (props.data) setItems(() => props.data)
  }, [props.data])

  useEffect(() => {
    if (!firstPageLoad)
      refreshTableData(filterValue, sortBy, asOrgFilter, countryFilter)
  }, [sortBy])

  useEffect(() => {
    if (!firstPageLoad)
      debouncedRefreshTable(filterValue, null, asOrgFilter, countryFilter)
  }, [debouncedRefreshTable, filterValue])

  useEffect(() => {
    if (tableRef && tableRef.current) {
      setDynamicHeight(tableRef.current.clientHeight)
    }
  }, [items])

  useEffect(() => {
    initAsOrgFilter()
    initCountryFilter()
  }, [props.asOrgOptions, props.countryFilterOptions])

  useEffect(() => {
    if (asOrgFilterOptions?.length && params.has('asOrg')) {
      setAsOrgFilter(
        asOrgFilterOptions.filter(
          option => option.value === params.get('asOrg')
        )
      )
    }

    if (countryFilterOptions?.length && params.has('country')) {
      setCountryFilter(
        countryFilterOptions.filter(
          option => option.value === params.get('country')
        )
      )
    }
  }, [asOrgFilterOptions, countryFilterOptions])

  return (
    <div className="tableWrap">
      <div className="flex flex-wrap xl:flex-nowrap justify-end xl:justify-between items-center pb-2">
        <div className="flex flex-wrap sm:flex-nowrap items-center gap-2 order-last xl:order-none text-sm w-full xl:w-3/4">
          <GlobalFilter
            globalFilter={filterValue}
            gotoPage={gotoPage}
            setFilterValue={setFilterValue}
            setGlobalFilter={setGlobalFilter}
            setLoading={setLoading}
            totalPages={props.total}
            filterWidth="w-full"
          />

          <div className="w-full">
            <Select
              isMulti
              placeholder={i18next.t('network.filterByAsorg.placeholder')}
              styles={reactSelectStyles}
              options={asOrgFilterOptions}
              theme={reactSelectTheme}
              value={asOrgFilter}
              onChange={handleChangeAsorgFilter}
            />
          </div>

          <div className="w-full">
            <Select
              isMulti
              placeholder={i18next.t('network.filterbyCountry.placeholder')}
              styles={reactSelectStyles}
              options={countryFilterOptions}
              theme={reactSelectTheme}
              value={countryFilter}
              onChange={handleChangeCountryFilter}
            />
          </div>
        </div>

        <div className="mb-2 xl:mb-0">
          <Pagination
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            paginationClass={props.paginationClass}
            pageIndex={pageIndex}
            pageCount={pageCount}
            total={props.total}
          />
        </div>
      </div>

      {props.data && props.data.length && !loading ? (
        <>
          <div className="overflow-x-auto">
            <table ref={tableRef} {...getTableProps()} className="w-full">
              <thead>
                {headerGroups.map(headerGroup => {
                  // trick to disable the first headerGroup
                  if (headerGroup.headers.length === 1)
                    return <tr key={uuidv4()}></tr>

                  return (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={uuidv4()}
                      className="p-2">
                      {headerGroup.headers.map(column => (
                        <th
                          className="dm-mono text-sideral-300 text-sm"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={uuidv4()}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' ↓'
                                : ' ↑'
                              : ''}
                          </span>
                        </th>
                      ))}
                    </tr>
                  )
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={tableStyles.tableRow}
                      key={uuidv4()}>
                      {row.cells.map(cell => {
                        if (
                          cell.column.id === 'asset' &&
                          cell.row.original.type === 'network'
                        )
                          return (
                            <td
                              className="p-2 pr-2 text-center cursor-pointer hover:underline"
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              <Link
                                to={`/network/host/${cell.row.original.hostId}`}>
                                {cell.row.original.hostId}:
                                {cell.row.original.port}
                              </Link>
                            </td>
                          )

                        if (cell.column.id === 'records') {
                          const value = cell.row.original.dnsRecords
                            ? cell.row.original.dnsRecords
                            : '-'

                          return (
                            <td
                              className="py-2 pr-2 text-center"
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              {value.map((record, index) => {
                                const limit = 3
                                if (index === limit && !expanded[row.id])
                                  return (
                                    <div
                                      key={uuidv4()}
                                      className="text-indigo-600 cursor-pointer mr-1"
                                      onClick={() => handleViewMore(row)}>
                                      view more
                                    </div>
                                  )

                                if (index > limit && !expanded[row.id]) {
                                  return ''
                                }

                                return (
                                  <div key={uuidv4()}>
                                    {record}
                                    {index % limit === 0 ? <br /> : <></>}
                                  </div>
                                )
                              })}
                            </td>
                          )
                        }

                        if (cell.column.id === 'asNum')
                          return (
                            <td
                              className="py-2 pr-2 text-center"
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              {cell.value
                                ? cell.value
                                : cell.row.original.autNum}
                            </td>
                          )

                        if (cell.column.id === 'asOrg')
                          return (
                            <td
                              className="py-2 pr-2 truncate break-word text-center"
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              {cell.value
                                ? cell.value
                                : cell.row.original.owner
                                ? cell.row.original.owner.replace(/�/g, '')
                                : '—'}
                            </td>
                          )

                        if (cell.column.id === 'name') {
                          const url = `/countries/${cell.row.original.country}.png`

                          return (
                            <td
                              className="text-center py-2 pr-2"
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              <div>
                                <ScoreBadge letter={cell.row.original.score} />
                                {cell.row.original.country && (
                                  <img
                                    src={url}
                                    className="inline mr-2 -mt-1"
                                    style={{maxWidth: '20px'}}
                                    onError={e =>
                                      (e.target.src = '/countries/US.png')
                                    }
                                    alt={cell.row.original.country}
                                  />
                                )}
                                <Link
                                  to={`/network/host/${cell.row.original.id}`}>
                                  <span className="hover:underline cursor-pointer">
                                    {cell.render('Cell')}
                                  </span>
                                </Link>
                              </div>
                            </td>
                          )
                        }

                        // Host detected services
                        if (cell.column.id === 'services') {
                          return (
                            <ServicesCell
                              cell={cell}
                              row={row}
                              key={uuidv4()}
                            />
                          )
                        }

                        if (cell.column.id === 'bestDate') {
                          const moment =
                            cell.row.original.lastNmapScan ||
                            cell.row.original.checkedAt ||
                            cell.row.original.updatedAt
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={uuidv4()}
                              className="text-center py-2">
                              <Moment date={new Date(moment)} fromNow />
                            </td>
                          )
                        }

                        if (
                          cell.column.id === 'data' &&
                          typeof cell.value === 'object' &&
                          cell.column.clickable
                        ) {
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={uuidv4()}
                              className="text-center py-2 pr-2">
                              {Array.isArray(cell.value) &&
                                cell.value.map((value, index) => {
                                  return (
                                    <Link
                                      key={uuidv4()}
                                      to={`/network/host/${cell.row.original.id}`}>
                                      <span className="cursor-pointer hover:underline">
                                        {typeof value === 'string'
                                          ? value
                                          : JSON.stringify(value)}

                                        {index < cell.value.length - 1
                                          ? ', '
                                          : ''}
                                      </span>
                                    </Link>
                                  )
                                })}
                            </td>
                          )
                        }

                        if (
                          cell.column.id === 'data' &&
                          typeof cell.value === 'object'
                        ) {
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={uuidv4()}
                              className="text-center py-2 pr-2">
                              {Array.isArray(cell.value) &&
                                cell.value.map((value, index) => {
                                  return (
                                    <span key={uuidv4()}>
                                      {typeof value === 'string'
                                        ? value
                                        : JSON.stringify(value)}

                                      {index < cell.value.length - 1
                                        ? ', '
                                        : ''}
                                    </span>
                                  )
                                })}
                            </td>
                          )
                        }

                        if (typeof cell.value === 'object') {
                          if (cell.value && cell.value.join) {
                            var output = cell.value.join(', ')

                            if (output === '[object Object]')
                              return (
                                <td
                                  className="text-centerpy-2 pr-2"
                                  {...cell.getCellProps()}
                                  key={uuidv4()}>
                                  {JSON.stringify(cell.value)}
                                </td>
                              )
                          }

                          return (
                            <td
                              className="text-center py-2 pr-2"
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              {output || '—'}
                            </td>
                          )
                        }

                        if (cell.column.Header === 'Title')
                          return (
                            <td
                              className="text-center py-2 pr-2 hover:underline cursor-pointer"
                              {...cell.getCellProps()}
                              key={uuidv4()}
                              onClick={() =>
                                handleClickIssue(cell.row.original.id)
                              }>
                              {cell.render('Cell')}
                            </td>
                          )

                        if (
                          cell.column.id === 'severity' &&
                          cell.value &&
                          typeof cell.value === 'number'
                        )
                          return (
                            <td
                              className="text-center py-2 pr-2 capitalize"
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              {getSeverityString(cell.value)}
                            </td>
                          )

                        if (cell.column.id === 'monitored')
                          return (
                            <td
                              className={
                                cell.column.classes
                                  ? cell.column.classes
                                  : 'text-center py-2 pr-2 truncate break-word'
                              }
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              {cell.value ? 'enabled' : 'disabled'}
                            </td>
                          )

                        if (cell.column.id === 'records') {
                          const value = cell.row.original.dnsRecords
                            ? cell.row.original.dnsRecords
                                .map(x => x.name)
                                .join(', ')
                            : '-'

                          return (
                            <td
                              className="py-2 pr-2 text-center"
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              {value}
                            </td>
                          )
                        }

                        return (
                          <td
                            className={
                              cell.column.classes
                                ? cell.column.classes
                                : 'text-center py-2 pr-2 truncate break-word'
                            }
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {typeof cell.value === 'undefined'
                              ? '-'
                              : cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <div
              className="flex items-center justify-center"
              style={{height: `${dynamicHeight}px`}}>
              <Spinner />
            </div>
          ) : (
            <>
              {filterValue
                ? props.emptyStateWithFilter
                : props.emptyStateWithoutFilter}
            </>
          )}
        </>
      )}
    </div>
  )
}

Table.propTypes = {
  asOrgOptions: PropTypes.array,
  countryFilterOptions: PropTypes.array,
  columns: PropTypes.array,
  data: PropTypes.array,
  defaultSorted: PropTypes.array,
  headerClasses: PropTypes.string,
  history: PropTypes.object,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  pageCount: PropTypes.number,
  total: PropTypes.number,
  paginationClass: PropTypes.string,
  fetchData: PropTypes.func,
  rowClasses: PropTypes.string,
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
  setFilterOptions: PropTypes.func,
}

export default React.memo(Table)
