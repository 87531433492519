import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import {Trans} from 'react-i18next'
import {useDropzone} from 'react-dropzone'
import i18next from 'i18next'
import FileIcon from 'res/icons/FileIcon'
import {mars700} from 'constant'

const PrimaryDropFile = ({
  file,
  setFile,
  acceptedFileType,
  dragAndDropError,
  footerText,
}) => {
  const onDrop = useCallback(acceptedFiles => setFile(acceptedFiles), [])

  const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone(
    {
      onDrop,
      accept: acceptedFileType,
      multiple: false,
    }
  )

  return (
    <div id="drag-and-drop-container" className="mt-6">
      {file.length ? (
        <div className="flex items-center justify-center h-64 border border-2 border-dashed border-sky-900 rounded">
          <div className="text-center">
            <div className="text-2xl mb-4">
              <Trans
                i18nKey="dropFile.fileSelected"
                components={{
                  strong: <span className="text-sky-900 font-medium" />,
                }}
              />
            </div>
            <div className="inline-block border border-astral-400 rounded-md">
              <div className="flex items-center p-3">
                <FileIcon height="26" width="26" color={mars700} />
                <div className="truncate ml-3 w-48">{file[0].name}</div>
                <button className="mt-1 ml-6" onClick={() => setFile([])}>
                  <i className="icofont-close text-astral-800 text-xl border-l border-astral-400 pl-2 pr-1" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`flex items-center justify-center h-64 border border-2 border-dashed rounded-md text-center hover:cursor-pointer ${
            isDragActive
              ? isDragReject
                ? 'border-red-700'
                : 'border-sky-900'
              : 'border-sideral-100'
          }`}>
          <div>
            <input {...getInputProps()} />

            {isDragActive ? (
              <div className="text-2xl">{i18next.t('dropFile.dragHere')}</div>
            ) : (
              <>
                <div className="text-2xl">
                  <Trans
                    i18nKey="dropFile.dragAndDrop"
                    components={{
                      strong: <span className="text-sky-900 font-medium" />,
                    }}
                  />
                </div>
                <div className="text-sm text-sideral-400 mt-3">
                  <Trans i18nKey={footerText} />
                </div>
              </>
            )}

            {isDragReject && (
              <div className="inline-block bg-red-100 text-red-700 rounded px-2 py-1 mt-2 text-sm">
                <Trans i18nKey={dragAndDropError} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default PrimaryDropFile

PrimaryDropFile.propTypes = {
  file: PropTypes.array,
  setFile: PropTypes.func,
  acceptedFileType: PropTypes.object,
  dragAndDropError: PropTypes.string,
  footerText: PropTypes.string,
}
