import config from '../config'
import {handler} from './handler'
import {getRequestParams} from 'utils/pagination'
import i18next from 'i18next'
import {
  addWebAppDone,
  fetchFingerprintDetailsDone,
  fetchWebAppDone,
  fetchWebApps,
  fetchWebAppsByProtocolDone,
  fetchWebAppsDone,
  fetchWebAppsFindingsDone,
  fetchWebAppsFingerprintsDone,
  fetchWebFinding,
  fetchWebFindingActivity,
  fetchWebFindingActivityDone,
  fetchWebFindingDone,
  fetchWebImportantFindingsDone,
  fetchWebStatsDone,
  genericError,
  redirect404,
  success,
  updateWebFindingDone,
} from 'actions'

export function* handleFetchWebFindingActivity(action) {
  yield handler({
    handler: _fetchWebFindingActivity,
    success: fetchWebFindingActivityDone,
    action,
  })
}

const _fetchWebFindingActivity = findingId => {
  const url = `${config.SERVER_URL}/web/finding/${findingId}/activity`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
}

export function* handleUpdateWebFinding(action) {
  const {updateStatusMessage, findingId} = action.payload

  yield handler({
    action,
    handler: _updateWebFinding,
    success: [
      updateWebFindingDone,
      () =>
        success({
          message: updateStatusMessage,
        }),
      () => fetchWebFinding(findingId),
      () => fetchWebFindingActivity(findingId),
    ],
    error: genericError,
  })
}

const _updateWebFinding = body => {
  const url = `${config.SERVER_URL}/web/finding/${body.findingId}/${body.status}`

  return fetch(url, {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'PATCH',
  })
}

export function* handleFetchWebFinding(action) {
  yield handler({
    action,
    error: genericError,
    handler: _fetchWebFinding,
    success: [fetchWebFindingDone],
  })
}

const _fetchWebFinding = findingId => {
  const url = `${config.SERVER_URL}/web/finding/${findingId}`

  return fetch(url, {
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'GET',
  })
}

export function* handleFetchWebStats(action) {
  yield handler({
    handler: _fetchWebStats,
    success: fetchWebStatsDone,
    action,
  })
}

const _fetchWebStats = () => {
  const url = `${config.SERVER_URL}/web/stats`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchWebImportantFindings(action) {
  yield handler({
    handler: _fetchWebImportantFindings,
    success: fetchWebImportantFindingsDone,
    action,
  })
}

const _fetchWebImportantFindings = () => {
  const url = `${config.SERVER_URL}/web/important`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchWebApps(action) {
  yield handler({
    handler: _fetchWebApps,
    success: fetchWebAppsDone,
    action,
  })
}

const _fetchWebApps = params => {
  const url = new URL(`${config.SERVER_URL}/web/apps`)
  url.search = new URLSearchParams(getRequestParams(params)).toString()

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchWebApp(action) {
  yield handler({
    action,
    error: genericError,
    handler: _fetchWebApp,
    success: fetchWebAppDone,
  })
}

const _fetchWebApp = props => {
  const url = `${config.SERVER_URL}/web/app/${props.webAppId}`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchWebAppFindings(action) {
  yield handler({
    action,
    error: genericError,
    handler: _fetchWebAppFindings,
    success: fetchWebAppsFindingsDone,
  })
}

const _fetchWebAppFindings = props => {
  const url = `${config.SERVER_URL}/web/app/${props.webAppId}/findings`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchWebAppsByProtocol(action) {
  yield handler({
    handler: _fetchWebAppsByProtocol,
    success: fetchWebAppsByProtocolDone,
    action,
  })
}

const _fetchWebAppsByProtocol = () => {
  const url = `${config.SERVER_URL}/web/apps/protocol`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchWebAppsFingerprints(action) {
  yield handler({
    action,
    handler: _fetchWebAppsFingerprints,
    success: fetchWebAppsFingerprintsDone,
  })
}

const _fetchWebAppsFingerprints = () => {
  const url = `${config.SERVER_URL}/web/apps/fingerprints`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchFingerprintDetails(action) {
  yield handler({
    action,
    handler: _fetchFingerprintDetails,
    success: fetchFingerprintDetailsDone,
    error: redirect404,
  })
}

const _fetchFingerprintDetails = query => {
  const url = new URL(
    `${config.SERVER_URL}/web/apps/fingerprints/${query.fingerprintId}`
  )
  url.search = new URLSearchParams(getRequestParams(query)).toString()

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleAddWebApp(action) {
  yield handler({
    handler: addWebApp,
    error: res => {
      let message

      switch (res.code) {
        case 'INVALID_URL':
          message = i18next.t('web.addWebApps.messages.invalidUrl')
          break
        case 'IPS_NOT_ALLOWED':
          message = i18next.t('web.addWebApps.messages.ipsNotAllowed')
          break
        case 'DOMAIN_NOT_FOUND':
          message = i18next.t('web.addWebApps.messages.unmonitoredDomainerror')
          break
        case 'DOMAIN_NOT_OWNED':
          message = i18next.t('web.addWebApps.messages.domainNotOwned')
          break
        case 'DNS_RECORD_DOES_NOT_RESOLVE':
          message = i18next.t('web.addWebApps.messages.noResponseUrl')
          break
        case 'TOO_MANY_REQUESTS':
          message = i18next.t('web.addWebApps.messages.tooManyRequests')
          break
        case 'WEB_APP_NOT_LIVE':
          message = i18next.t('web.addWebApps.messages.webAppNotLive')
          break
        case 'SERVICE_BEHIND_CLOUDFLARE':
          message = i18next.t('web.addWebApps.messages.serviceBehindCloudflare')
          break
        case 'WEB_APP_EXISTS':
          message =
            res.url === res.finalUrl
              ? i18next.t('web.addWebApps.messages.urlExists', {
                  url: res.url,
                  interpolation: {escapeValue: false},
                })
              : i18next.t('web.addWebApps.messages.urlExistsEntryPoint', {
                  url: res.url,
                  entryPoint: res.finalUrl,
                  interpolation: {escapeValue: false},
                })
          break
        default:
          message = i18next.t('errors.unexpectedError')
      }

      return genericError({
        code: res.code,
        message,
        loading: {addWebApp: false},
      })
    },
    success: [
      res =>
        success({
          message: i18next.t('web.addWebApps.messages.webAppSuccess', {
            url: res.url,
            interpolation: {escapeValue: false},
          }),
        }),
      fetchWebApps,
      addWebAppDone,
    ],
    action,
  })
}

const addWebApp = function (body) {
  return fetch(config.SERVER_URL + '/web/apps', {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'POST',
  }).catch(err => {
    console.trace(err)
  })
}
