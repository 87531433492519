import React from 'react'
import PropTypes from 'prop-types'
import {v4 as uuidv4} from 'uuid'

const SectionAdvantages = ({
  headerIcon,
  headerTitle,
  title,
  customTitle,
  advantagesItems,
  actionButtons,
  rightSideVideo,
  rightSideImage,
}) => {
  return (
    <div className="mx-5 text-left">
      {headerIcon && headerTitle && (
        <div className="flex mt-3 items-center border-b pb-4">
          {headerIcon}
          <p className="w-full font-medium ml-4">{headerTitle}</p>
        </div>
      )}

      <div className="xl:flex items-center my-6">
        <div id="left-side" className="basis-3/5 pr-8">
          <div className="flex items-center">
            {customTitle || <h2>{title}</h2>}
          </div>
          <ul className="check-icon text-gray-700 font-light my-6">
            {advantagesItems.map(item => (
              <li key={uuidv4()} className="mb-3 last:mb-0">
                {item}
              </li>
            ))}
          </ul>
          <div className="flex">{actionButtons}</div>
        </div>

        {rightSideVideo && (
          <div
            id="right-side-video"
            className="relative group basis-2/5 h-72 mr-[10px] mt-10 xl:mt-0 z-10 basis-2/5">
            <div className="z-50 absolute top-0 left-0 w-full h-full">
              {rightSideVideo}
            </div>
            <div className="absolute -bottom-[10px] -right-[10px] w-4/5 h-4/5 group-hover:w-full group-hover:h-full z-40 transition-all ease-in-out duration-200 bg-gradient-to-r from-violet to-ripeOrange" />
          </div>
        )}

        {rightSideImage && (
          <figure id="right-side-image" className="hidden xl:block basis-4/5">
            {rightSideImage}
          </figure>
        )}
      </div>
    </div>
  )
}

SectionAdvantages.propTypes = {
  headerIcon: PropTypes.object,
  headerTitle: PropTypes.string,
  title: PropTypes.string,
  customTitle: PropTypes.object,
  advantagesItems: PropTypes.array,
  actionButtons: PropTypes.object,
  rightSideVideo: PropTypes.object,
  rightSideImage: PropTypes.object,
}

export default SectionAdvantages
