import Spinner from './partials/Spinner'
import React from 'react'
import {useSelector} from 'react-redux'
import {Navigate} from 'react-router-dom'

import {getLocationToRedirect, isLoading, resetUIComponentsState} from 'utils'

export default function LoginRedirect() {
  const userState = useSelector(state => state.user)
  const loadingState = useSelector(state => state.loading)

  resetUIComponentsState()

  return isLoading(loadingState.user) ? (
    <div className="flex items-center justify-center h-screen">
      <Spinner />
    </div>
  ) : (
    <Navigate to={getLocationToRedirect(userState.role)} />
  )
}
