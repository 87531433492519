import React, {useEffect} from 'react'
import i18next from 'i18next'
import {batch, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import images from 'res'
import {
  fetchAwsStats,
  fetchAzureStats,
  fetchGcpStats,
  toggleRestrictionModal,
} from 'actions'

import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import BackButton from 'components/partials/buttons/BackButton'
import {Page} from 'components/partials/Page'
import PlanUtils from 'utils/plan'

export default function GcpIntegrationOptions() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const planUtils = PlanUtils()

  useEffect(() => {
    batch(() => {
      dispatch(fetchAzureStats())
      dispatch(fetchAwsStats())
      dispatch(fetchGcpStats())
    })
  }, [])

  const onClickIntegrationMethod = redirectPath => {
    planUtils.hasPlanRestriction()
      ? dispatch(
          toggleRestrictionModal({
            contentId: 'cloudIntegrationTutorialBtn',
            showModal: true,
          })
        )
      : navigate(redirectPath)
  }

  return (
    <Page pageTitle={i18next.t('pageTitles.cloud')}>
      <BackButton fallbackLink="/cloud/integration" />
      <div
        className={`border border-sideral-100 bg-white rounded-lg p-2 py-16 mt-4`}>
        <div className="text-center text-astral-700">
          <p className="text-base md:text-lg">
            {i18next.t('cloud.gcp.integrationOptions.chooseMethod')}
          </p>
        </div>

        <div className="flex flex-wrap w-full justify-center">
          {/* Manual setup integration  */}
          <div className="mr-8">
            <div className="relative flex justify-center text-center text-sm border border-sideral-100 rounded-md w-64 h-64 mt-16 px-2">
              <div className="text-astral-900 text-base mt-6">
                <div className="flex items-center justify-center">
                  {i18next.t('cloud.gcp.integrationOptions.manualSetup')}
                  <figure className="ml-2">
                    <img src={images.gcpIcon} className="h-5" />
                  </figure>
                </div>

                <div className="text-sm text-center font-light text-astral-700 px-2 mt-10">
                  {i18next.t(
                    'cloud.gcp.integrationOptions.manualSetupDescription'
                  )}
                </div>

                <div className="absolute bottom-0 w-full mb-6">
                  <SecondaryButton
                    text={i18next.t(
                      'cloud.gcp.integrationOptions.selectThisMethod'
                    )}
                    direction="right"
                    size="sm"
                    onClick={() =>
                      onClickIntegrationMethod('/cloud/gcp/integration/manual')
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Terraform integration */}
          <div className="ml-8">
            <div className="relative flex justify-center text-center text-sm border border-sideral-100 rounded-md w-64 h-64 mt-16 px-2">
              <div className="text-astral-900 text-base mt-4">
                <div className="flex flex-wrap items-center justify-center">
                  <p>Terraform</p>
                  <figure>
                    <images.TerraformIcon width="38" />
                  </figure>
                </div>

                <div className="text-sm text-center font-light text-astral-700 px-2 mt-10">
                  {i18next.t(
                    'cloud.gcp.integrationOptions.terraformDescription'
                  )}
                </div>

                <div className="absolute bottom-0 w-full mb-6">
                  <SecondaryButton
                    text={i18next.t(
                      'cloud.gcp.integrationOptions.selectThisMethod'
                    )}
                    direction="right"
                    size="sm"
                    onClick={() =>
                      onClickIntegrationMethod(
                        '/cloud/gcp/integration/terraform'
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}
