import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {useParams, useNavigate} from 'react-router-dom'

import {genericError, loading, resetPassword} from 'actions'

import Logo from 'res/brand/logo.svg'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import {isLoading} from 'utils'

const ResetPassword = () => {
  const params = useParams()
  const navigate = useNavigate()

  const initialFormState = {
    id: params.id || false,
    password: '',
    passwordConfirmation: '',
  }

  const ReCaptchaRef = React.createRef()
  const dispatch = useDispatch()

  const successState = useSelector(state => state.success)
  const errorState = useSelector(state => state.error)
  const loadingState = useSelector(state => state.loading)

  const [form, setForm] = useState(initialFormState)

  const handleInputChange = event => {
    const {name, value} = event.target
    setForm({...form, [name]: value})
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (!form.password || !form.passwordConfirmation) {
      dispatch(
        genericError({
          loading: {resetPassword: false},
          message: i18next.t('errors.fieldsMustBeFilled'),
        })
      )
      return
    }

    if (form.password !== form.passwordConfirmation) {
      dispatch(
        genericError({
          loading: {resetPassword: false},
          message: i18next.t('errors.confirmationNotMatching'),
        })
      )
      return
    }

    ReCaptchaRef.current.execute()
    dispatch(loading({resetPassword: true}))

    setForm({...form})
  }

  const handleCaptchaChange = token => {
    setForm({...form, captcha: token})
  }

  useEffect(() => {
    if (successState) {
      navigate('/login')
      setForm(initialFormState)
    }
  }, [successState])

  useEffect(() => {
    if (form.captcha) {
      dispatch(resetPassword(form))

      // clear captcha after submit to avoid resend
      setForm({...form, captcha: null})
    }
  }, [dispatch, form.captcha])

  useEffect(() => {
    if (errorState) ReCaptchaRef.current.reset()
  }, [errorState])

  return (
    <div className="dm-mono fixed right-0 flex flex-col items-center bg-white lg:max-w-xl w-full h-screen ml-auto pt-12 z-50">
      <img
        src={Logo}
        alt="Unxpose"
        className="mx-auto cursor-pointer w-56 mb-24"
      />
      <form
        onSubmit={handleSubmit}
        className="relative w-full text-sideral-700 px-6 md:px-24">
        <div>
          <PrimaryInput
            text={i18next.t('labels.password')}
            name="password"
            type="password"
            value={form.password}
            onChange={handleInputChange}
            required={true}
            minLength={10}
            placeholder={i18next.t('placeholders.minCharacters')}
            margin="mb-8"
          />

          <PrimaryInput
            text={i18next.t('labels.passwordConfirmation')}
            name="passwordConfirmation"
            type="password"
            value={form.passwordConfirmation}
            onChange={handleInputChange}
            required={true}
            minLength={10}
            placeholder={i18next.t('placeholders.passwordConfirmation')}
            margin="mb-8"
          />
        </div>
        <ReCAPTCHA
          ref={ReCaptchaRef}
          sitekey="6LeqSV8aAAAAAHHZEv5J9NqKNy_D_uNiwEJQjsLO"
          size="invisible"
          onChange={handleCaptchaChange}
        />

        <div className="flex justify-between items-center mt-6">
          <SecondaryButton
            text={i18next.t('buttons.backLogin').toUpperCase()}
            direction="left"
            size="sm"
            onClick={() => navigate('/login')}
          />
          <PrimaryButton
            text={i18next.t('buttons.createPassword')}
            type="submit"
            size="lg"
            loading={isLoading(loadingState.resetPassword, false)}
          />
        </div>
      </form>
    </div>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.object,
}

export default ResetPassword
