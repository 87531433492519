import React from 'react'
import Moment from 'react-moment'
import PropTypes from 'prop-types'

export default function Timestamp(props) {
  if (!props.date) {
    return props.placeholder || '—'
  }

  return <Moment format={props.format} date={props.date} />
}

Timestamp.propTypes = {
  date: PropTypes.date,
  format: PropTypes.string,
  placeholder: PropTypes.string,
}
