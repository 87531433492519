import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useSelector, useDispatch} from 'react-redux'
import {Steps} from 'intro.js-react'

import {completeGuide} from 'actions'

import {introOptions, introStepsPeople} from 'constant'

const PeopleIntro = ({hasCompanyEmails, hasActiveIntegration}) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const csrf = useSelector(state => state.csrf)

  const [showIntro, setShowIntro] = useState(false)

  useEffect(() => {
    const userHasCompletedGuide = user.guides?.peopleIntegration || !user.guides
    const isPeoplePage = window.location.pathname === '/people'
    const screenIsBigEnough = window.innerWidth > 800

    const initialIntroState =
      !userHasCompletedGuide &&
      isPeoplePage &&
      screenIsBigEnough &&
      !hasActiveIntegration

    setTimeout(() => {
      setShowIntro(initialIntroState)
    }, 500)
  }, [])

  if (showIntro) {
    const workspacePrompt = document.getElementById('workspace-prompt')
    if (workspacePrompt && hasCompanyEmails) workspacePrompt.scrollIntoView()

    document.documentElement.classList.add('overflow-hidden')
  }

  return (
    <Steps
      enabled={showIntro}
      steps={introStepsPeople(hasCompanyEmails)}
      initialStep={0}
      options={introOptions}
      onExit={() => {
        if (showIntro) {
          document.documentElement.classList.remove('overflow-hidden')
          dispatch(completeGuide({_csrf: csrf, endpoint: 'peopleIntegration'}))

          setShowIntro(false)
        }
      }}
    />
  )
}

export default PeopleIntro

PeopleIntro.propTypes = {
  hasCompanyEmails: PropTypes.bool,
  hasActiveIntegration: PropTypes.bool,
}
