import React from 'react'
import PropTypes from 'prop-types'

export default function Chevron({width, height, color, direction}) {
  return (
    <div className={direction === 'up' ? 'transform rotate-180' : ''}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 1L7 7L13 1"
          stroke={color || 'white'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

Chevron.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.oneOf(['up', 'down']),
}
