import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {v4 as uuidv4} from 'uuid'

import {DefaultModal} from 'components/partials/DefaultModal'
import {Trans} from 'react-i18next'
import moment from 'moment'

export default function DocumentRemoveFeedbackModal({
  removeDocumentModal,
  setRemoveDocumentModal,
}) {
  return (
    <DefaultModal
      id="removeDocumentFeedbackModal"
      title={i18next.t('documents.modal.removeFeedback.title')}
      primaryBtnText={i18next.t('documents.modal.removeFeedback.primaryBtn')}
      body={
        <div className="text-sm text-gray-700 leading-6">
          <Trans
            i18nKey="documents.modal.removeFeedback.body"
            values={{
              documentName: removeDocumentModal.selected.name,
              documentDate: moment(
                removeDocumentModal.selected.documentDate
              ).format('LL'),
            }}
          />

          <ul className="list-disc ml-8 my-4">
            {removeDocumentModal.selected.questionnaires.map(questionnaire => (
              <li key={uuidv4()}>{questionnaire}</li>
            ))}
          </ul>
        </div>
      }
      onClickPrimaryBtn={() =>
        setRemoveDocumentModal({
          selected: null,
          showModal: false,
        })
      }
    />
  )
}

DocumentRemoveFeedbackModal.propTypes = {
  removeDocumentModal: PropTypes.object,
  setRemoveDocumentModal: PropTypes.func,
}
