import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducer'
import rootSaga from './sagas/index'
import {composeWithDevTools} from '@redux-devtools/extension'
import {createLogger} from 'redux-logger'
import {createStore, applyMiddleware} from 'redux'

const logger = createLogger({
  collapsed: true,
})
const saga = createSagaMiddleware()

const enhancer =
  process.env.REACT_APP_ENV === 'production'
    ? applyMiddleware(saga)
    : composeWithDevTools(applyMiddleware(saga, logger))

const store = createStore(rootReducer, enhancer)

saga.run(rootSaga)

export default store
