import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {DefaultModal} from 'components/partials/DefaultModal'
import FileIcon from 'res/icons/FileIcon'
import {sky900} from 'constant'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {fetchQuestionReferences, loading} from 'actions'
import i18next from 'i18next'

export default function QuestionReferencesModal({
  questionId,
  references,
  setShowReferencesModal,
}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)

  useEffect(() => {
    dispatch(loading({questionReferences: true}))
    dispatch(fetchQuestionReferences({questionId, _csrf: csrfState}))
  }, [])

  return (
    <DefaultModal
      id="questionReferencesModal"
      title={i18next.t('questionnaires.questionReferences.references')}
      body={
        <div className="overflow-y-scroll h-[26rem] max-h-1/2 w-full">
          {references.map(reference => (
            <div
              key={reference.questionnaireId}
              id="item-questionnaire"
              className="border rounded-md mr-4 mb-6 last:mb-0">
              <header className="flex items-center justify-between bg-gray-100 px-4 py-3 border-b">
                <div className="flex items-center">
                  <div className="mr-2">
                    <FileIcon height="24" width="24" color={sky900} />
                  </div>
                  <div className="font-medium">
                    {reference.questionnaireName}
                  </div>
                </div>
                <Link
                  to={`/questionnaires/${reference.questionnaireId}`}
                  target="_blank">
                  <i className="icofont-external-link text-sky-900 text-xl" />
                </Link>
              </header>
              <div id="body" className="px-4 py-6">
                {reference.questions.map(item => (
                  <div
                    key={item.questionId}
                    id="item-question"
                    className="flex mb-6 last:mb-0">
                    <div className="font-medium">{item.questionOrder}. </div>
                    <div className="ml-1">
                      <div className="mb-2">
                        <div>{item.questionContent}</div>
                      </div>
                      <div> {item.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      }
      onCloseModal={() => setShowReferencesModal(false)}
      width="max-w-3xl"
    />
  )
}

QuestionReferencesModal.propTypes = {
  questionId: PropTypes.string,
  references: PropTypes.array,
  setShowReferencesModal: PropTypes.func,
}
