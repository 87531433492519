import Moment from 'react-moment'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {i18next} from 'translate/i18n'

import {fetchExploitationDetails, startExploitation} from 'actions'

import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import Tag from 'components/partials/Tag'

const unfinishedStatus = ['started', 'enqueued', 'workInProgress']

export default function Exploitation({finding}) {
  const dispatch = useDispatch()
  const exploitationState = useSelector(state => state.exploitation)

  const [exploitation, setExploitation] = useState({})
  const [buttonLoading, setButtonLoading] = useState(false)

  const initialState = {}

  const [form, setForm] = useState(initialState)

  const handleSubmit = e => {
    e.preventDefault()

    setButtonLoading(true)

    const formElement = document.getElementById('exploitation-form')

    formElement.reportValidity()

    if (formElement.checkValidity()) {
      dispatch(
        startExploitation({
          findingId: finding.id,
          type: 'web',
          userParams: form,
        })
      )

      setForm(initialState)
    }
  }

  useEffect(() => {
    dispatch(fetchExploitationDetails(finding.id))
  }, [])

  useEffect(() => {
    if (exploitationState) {
      const inProgress =
        exploitationState.history &&
        exploitationState.history.find(log =>
          unfinishedStatus.includes(log.status)
        )

      setExploitation({
        ...exploitationState,
        inProgress,
      })

      if (inProgress) setButtonLoading(true)

      const interval = setInterval(() => {
        if (inProgress) {
          dispatch(fetchExploitationDetails(finding.id))
          setButtonLoading(true)
        } else {
          setButtonLoading(false)
        }
      }, 3000)

      return () => clearInterval(interval)
    } else {
      setButtonLoading(false)
    }
  }, [exploitationState])

  return (
    <>
      {exploitation ? (
        <form action="#" onSubmit={handleSubmit} id="exploitation-form">
          <div className="py-4 flex">
            <div className="lg:w-1/2 w-full">
              <div className="py-1 px-2">
                <span className="font-semibold mr-2">
                  {i18next.t('exploitation.name')}:
                </span>
                {exploitation.name}
              </div>
              <div className="py-1 px-2">
                <span className="font-semibold mr-2">
                  {i18next.t('exploitation.description')}:
                </span>
                <span className="">{exploitation.description}</span>
              </div>
            </div>

            {exploitation.userParams &&
            Object.keys(exploitation.userParams).length &&
            !exploitation.inProgress ? (
              <ExploitationParams
                form={form}
                setForm={setForm}
                exploitation={exploitation}
              />
            ) : (
              <></>
            )}

            <ExploitationProgress exploitation={exploitation} />
          </div>

          <div className="flex justify-end py-4">
            <PrimaryButton
              loading={buttonLoading}
              onClick={handleSubmit}
              size="lg"
              text={i18next.t('exploitation.exploitButton')}
              theme="blue"
              type="submit"
            />
          </div>

          <div className="pb-8">
            <ExploitationLogs exploitation={exploitation} />
          </div>
        </form>
      ) : (
        <></>
      )}
    </>
  )
}

const ExploitationParams = ({exploitation, form, setForm}) => {
  const handleInputChange = e => {
    const {name, value} = e.target
    setForm({...form, [name]: value})
  }

  return (
    <>
      <div className="lg:w-1/2 w-full">
        <div className="">
          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('exploitation.requiredParameters')}:
            </span>
            {exploitation.userParams.description}
            In order to exploit this vulnerability a valid and existent email
            address from this Microsoft Exchange Server is required.
          </div>
          <div className="py-1 px-2">
            {Object.keys(exploitation.userParams).map((param, index) => {
              return (
                <div className="w-1/2" key={`exp-param-${index}`}>
                  <PrimaryInput
                    name={param}
                    required={true}
                    text={param.charAt(0).toUpperCase() + param.slice(1)}
                    type={exploitation.userParams[param].format}
                    onChange={handleInputChange}
                    value={form[param]}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

const ExploitationProgress = ({exploitation}) => {
  return (
    <>
      {exploitation && exploitation.inProgress ? (
        <div className="lg:w-1/2 text-xs">
          <span className="font-semibold mr-2">
            {i18next.t('exploitation.progressTitle')}
          </span>

          <div className="border rounded-lg bg-gray-100 p-2 my-2">
            <CheckMark />
            <span>{i18next.t('exploitation.steps.exploitationRequested')}</span>
            <span className="float-right">
              <Moment date={exploitation.inProgress.createdAt} fromNow />
            </span>
          </div>

          <ProgressLane
            exploitation={exploitation}
            previousStatus={'started'}
            currentStatus={'enqueued'}
            startLabel={i18next.t('exploitation.steps.addToQueue')}
            finalLabel={i18next.t('exploitation.steps.addedToQueue')}
            progressLabel={i18next.t('exploitation.steps.addingToQueue')}
            startPulsing={true}
          />

          <ProgressLane
            exploitation={exploitation}
            previousStatus={'enqueued'}
            currentStatus={'workInProgress'}
            startLabel={i18next.t('exploitation.steps.startExploitation')}
            finalLabel={i18next.t('exploitation.steps.exploitationStarted')}
            progressLabel={i18next.t('exploitation.steps.exploiting')}
          />

          <ProgressLane
            exploitation={exploitation}
            previousStatus={'workInProgress'}
            currentStatus={'exploitable'}
            startLabel={i18next.t('exploitation.steps.verifyCallback')}
            finalLabel={i18next.t('exploitation.steps.callbackVerified')}
            progressLabel={i18next.t('exploitation.steps.waitingCallback')}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const ExploitationLogs = ({exploitation}) => {
  const renderParameters = params => {
    params = JSON.stringify(params)
    params = params.replace(/\{/g, '')
    params = params.replace(/"/g, '')
    params = params.replace(/\}/g, ' ')
    params = params.replace(/:/g, ': ')

    return !params.trim() ? '–' : params
  }

  return (
    <>
      {exploitation && exploitation.history && exploitation.history.length ? (
        <div className="border-t mt-4 pt-4 pl-2">
          <div className="flex">
            <div className="w-3/12">
              <div className="font-medium">
                {i18next.t('exploitation.logs.startedAt')}
              </div>
            </div>
            <div className="w-3/12">
              <div className="font-medium">
                {i18next.t('exploitation.logs.finishedAt')}
              </div>
            </div>
            <div className="w-2/12">
              <div className="font-medium">
                {i18next.t('exploitation.logs.status')}
              </div>
            </div>
            <div className="w-4/12">
              <div className="font-medium">
                {i18next.t('exploitation.logs.parameters')}
              </div>
            </div>
          </div>
          {exploitation.history.slice(0, 5).map((log, index) => {
            return (
              <div className="flex" key={`exp-log-${index}`}>
                <div className="w-3/12">
                  <Moment format="lll" date={log.createdAt} />
                </div>
                <div className="w-3/12">
                  {unfinishedStatus.includes(log.status) ? (
                    '-'
                  ) : (
                    <Moment format="lll" date={log.updatedAt} />
                  )}
                </div>
                <div className="w-2/12">
                  <Tag content={log.status} />
                </div>
                <div className="w-4/12 break-all">
                  <div>{renderParameters(log.params)}</div>
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const ProgressLane = ({
  currentStatus,
  exploitation,
  finalLabel,
  previousStatus,
  progressLabel,
  startLabel,
  startPulsing,
}) => {
  if (
    (exploitation.currentProgress &&
      exploitation.currentProgress.status === previousStatus) ||
    (!exploitation.currentProgress && startPulsing)
  )
    return (
      <div className="strong animate-pulse border rounded-lg bg-gray-100 p-2 my-2">
        <EmptyCircle />
        <>{progressLabel}</>
      </div>
    )

  if (
    exploitation.currentProgress &&
    exploitation.currentProgress.history[currentStatus].status === 'success'
  )
    return (
      <div className="border rounded-lg bg-gray-100 p-2 my-2">
        <CheckMark />
        <>{finalLabel}</>

        <span className="float-right">
          {exploitation.currentProgress &&
          exploitation.currentProgress.history[currentStatus].status ===
            'success' ? (
            <Moment
              date={exploitation.currentProgress.history[currentStatus].moment}
              fromNow
            />
          ) : (
            <></>
          )}
        </span>
      </div>
    )

  return (
    <div className="border rounded-lg bg-gray-100 p-2 my-2">
      <EmptyCircle />
      <>{startLabel}</>
    </div>
  )
}

const CheckMark = () => {
  return (
    <svg
      className="float-left mr-2"
      viewBox="0 -14 80 80"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 64 64">
      <path
        d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
    l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z"
        fill="#43a047"
      />
    </svg>
  )
}

const EmptyCircle = () => {
  return (
    <svg height="16" width="16" className="float-left mr-2">
      <circle
        cx="7"
        cy="9"
        fill="black"
        fillOpacity="0"
        r="5"
        stroke="#BF8700"
        strokeWidth="1"
      />
    </svg>
  )
}

/*
const FailCircle = () => {
  return (
    <svg
      className="float-left mr-2"
      viewBox="0 -22 148 148"
      xmlns="http://www.w3.org/2000/svg"
      width="15">
      <path
        fill="#f44336"
        fillRule="evenodd"
        className="cls-1"
        d="M61.44,0A61.44,61.44,0,1,1,0,61.44,61.44,61.44,0,0,1,61.44,0ZM74.58,36.8c1.74-1.77,2.83-3.18,5-1l7,7.13c2.29,2.26,2.17,3.58,0,5.69L73.33,61.83,86.08,74.58c1.77,1.74,3.18,2.83,1,5l-7.13,7c-2.26,2.29-3.58,2.17-5.68,0L61.44,73.72,48.63,86.53c-2.1,2.15-3.42,2.27-5.68,0l-7.13-7c-2.2-2.15-.79-3.24,1-5l12.73-12.7L36.35,48.64c-2.15-2.11-2.27-3.43,0-5.69l7-7.13c2.15-2.2,3.24-.79,5,1L61.44,49.94,74.58,36.8Z"
      />
    </svg>
  )
}
*/

ExploitationParams.propTypes = {
  exploitation: PropTypes.object,
  form: PropTypes.object,
  setForm: PropTypes.func,
}

ExploitationProgress.propTypes = {
  exploitation: PropTypes.object,
}

ExploitationLogs.propTypes = {
  exploitation: PropTypes.object,
}

ProgressLane.propTypes = {
  currentStatus: PropTypes.string,
  exploitation: PropTypes.object,
  finalLabel: PropTypes.string,
  previousStatus: PropTypes.string,
  progressLabel: PropTypes.string,
  startLabel: PropTypes.string,
  startPulsing: PropTypes.bool,
}

Exploitation.propTypes = {
  finding: PropTypes.object,
}
