import React from 'react'
import PropTypes from 'prop-types'
import {DefaultModal} from 'components/partials/DefaultModal'
import i18next from 'i18next'
import DocumentForm from 'components/documents/DocumentForm'

export default function QuestionDocumentsModal({setShowDocumentsModal}) {
  return (
    <DefaultModal
      id="documentsModal"
      title={i18next.t('questionnaires.evidences.addDocumentTitleModal')}
      body={
        <div className="overflow-y-scroll h-[26rem] max-h-1/2 w-full">
          <DocumentForm classes="pl-2 pr-8" />
        </div>
      }
      onCloseModal={() => setShowDocumentsModal(false)}
      width="max-w-3xl"
    />
  )
}

QuestionDocumentsModal.propTypes = {
  setShowDocumentsModal: PropTypes.func,
}
