import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {Trans} from 'react-i18next'
import Moment from 'react-moment'
import {v4 as uuidv4} from 'uuid'

import {DefaultModal} from 'components/partials/DefaultModal'
import QuestionnaireStatusBadge from 'components/questionnaires/QuestionnaireStatusBadge'
import {useDispatch, useSelector} from 'react-redux'
import {fetchQuestionnaireEvents} from 'actions'

export default function QuestionnaireHistoryModal({
  questionnaireId,
  supplierId,
  setShowHistoryModal,
}) {
  const dispatch = useDispatch()

  const questionnaireEventsState = useSelector(
    state => state.questionnaireEvents
  )

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    dispatch(
      fetchQuestionnaireEvents({
        questionnaireId,
        supplierId,
      })
    )
  }, [])

  useEffect(() => {
    if (questionnaireEventsState) setLoading(false)
  }, [questionnaireEventsState])

  return (
    <DefaultModal
      id="questionnaireHistoryModal"
      title={i18next.t('questionnaires.history.title')}
      bodyClasses="h-72 overflow-y-auto"
      body={
        <>
          {questionnaireEventsState?.map((item, i) => (
            <HistoryItem
              key={uuidv4()}
              changedAt={item.statusChangedAt}
              status={item.type}
              firstName={item.firstName}
              lastName={item.lastName}
              supplierCompany={item.companyName}
              isLatestStatus={i === 0}
              isOldestStatus={i === questionnaireEventsState.length - 1}
            />
          ))}
        </>
      }
      onCloseModal={() => setShowHistoryModal(false)}
      loadingContent={loading}
    />
  )
}

const HistoryItem = ({
  changedAt,
  status,
  firstName,
  lastName,
  supplierCompany,
  isLatestStatus,
  isOldestStatus,
}) => {
  const userState = useSelector(state => state.user)

  const fullName = lastName ? `${firstName} ${lastName}` : firstName

  const getHistoryDescription = (status, fullName, supplierCompany) => {
    switch (status) {
      case 'sent':
        return (
          <Trans
            i18nKey="questionnaires.history.byFor"
            values={{
              fullName,
              buyerCompany: userState.company.name,
              supplierCompany,
            }}
            components={{strong: <span className="font-medium" />}}
          />
        )

      default:
        return (
          <Trans
            i18nKey="questionnaires.history.by"
            values={{
              fullName,
              supplierCompany,
            }}
            components={{strong: <span className="font-medium" />}}
          />
        )
    }
  }

  return (
    <div className="flex items-baseline mb-6">
      <div className="text-xs text-right leading-tight">
        <div>
          <Moment format="L" date={changedAt} />
        </div>
        <div>
          <Moment format="LT" date={changedAt} />
        </div>
      </div>

      <div className="relative ml-5">
        <div
          id="animated-circle"
          className="absolute top-[7px] -left-[17px] mx-3">
          <span className="relative flex h-3 w-3">
            {isLatestStatus && (
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-75 [animation-duration:_2s]" />
            )}
            <span className="relative inline-flex rounded-full h-3 w-3 bg-gray-300" />
          </span>
        </div>

        {!isOldestStatus && (
          <div
            id="line"
            className="absolute top-[25px] border-l-2 h-full border-l-gray-300"
          />
        )}

        <div className="ml-6 mr-3">
          <div className="flex items-center">
            <div>
              <QuestionnaireStatusBadge status={status} hideTooltip />
            </div>
          </div>
          <div className="text-sm mt-2">
            {getHistoryDescription(status, fullName, supplierCompany)}
          </div>
        </div>
      </div>
    </div>
  )
}

HistoryItem.propTypes = {
  changedAt: PropTypes.string,
  status: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  supplierCompany: PropTypes.string,
  isLatestStatus: PropTypes.bool,
  isOldestStatus: PropTypes.bool,
}

QuestionnaireHistoryModal.propTypes = {
  questionnaireId: PropTypes.string,
  supplierId: PropTypes.string,
  setShowHistoryModal: PropTypes.func,
}
