import React, {useState} from 'react'

import {i18next} from 'translate/i18n'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'

import {v4 as uuidv4} from 'uuid'

import {userRolesList} from 'constant'

import {updateUserRole} from 'actions'

import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import RemoveUserModal from './RemoveUserModal'
import InviteMemberModal from './InviteUserModal'
import MemberItem from './MemberItem'
import {capitalizeWord} from 'utils'

export default function MembersList({
  membersList,
  showInviteModal,
  setShowInviteModal,
}) {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)

  const [removeUserModal, setRemoveUserModal] = useState({
    showModal: false,
    member: {commonCompanies: []},
  })

  const handleDelete = member => {
    setRemoveUserModal({
      member,
      showModal: true,
    })
  }

  const handleUpdateUserRole = (option, member) => {
    dispatch(
      updateUserRole({
        _csrf: csrfState,
        roleName: option.value,
        userId: member.id,
      })
    )
  }

  return (
    <>
      {membersList?.map(member => {
        const isCurrentUser = member.email === userState.email

        let fullUserName = `${member.firstName} ${member.lastName || ''}`
        if (isCurrentUser) fullUserName += ` (${i18next.t('labels.you')})`

        return (
          <MemberItem
            key={uuidv4()}
            name={fullUserName}
            email={member.email}
            onDelete={() => handleDelete(member)}
            disabledDelete={isCurrentUser}
            rightSideContent={
              <PrimarySelect
                placeholder={i18next.t('misc.select')}
                name="roleName"
                className="text-sm"
                width="16rem"
                options={userRolesList}
                onChange={option => handleUpdateUserRole(option, member)}
                value={
                  userRolesList.find(role => role.value === member.role) || {
                    label: capitalizeWord(member.role),
                  }
                }
                padding={1}
                marginBottom="0"
                disabled={userState?.role === 'read-only' || isCurrentUser}
                isSearchable={false}
              />
            }
          />
        )
      })}

      {showInviteModal && (
        <InviteMemberModal setShowInviteModal={setShowInviteModal} />
      )}

      {removeUserModal.showModal && (
        <RemoveUserModal
          removeUserModal={removeUserModal}
          setRemoveUserModal={setRemoveUserModal}
        />
      )}
    </>
  )
}

MembersList.propTypes = {
  membersList: PropTypes.array,
  showInviteModal: PropTypes.bool,
  setShowInviteModal: PropTypes.func,
}
