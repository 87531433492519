import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'

const SeverityChip = props => {
  const [severity, setSeverity] = useState('')

  useEffect(() => {
    if (props.severity) setSeverity(props.severity)
  }, [props.severity])

  if (typeof severity !== 'string') return <></>

  switch (severity.toLowerCase()) {
    case 'info':
      return (
        <span
          className={
            'rounded-full bg-sky-300 uppercase px-3 py-1 text-xs font-bold text-white ' +
            props.class
          }>
          info
        </span>
      )
    case 'low':
      return (
        <span
          className={
            'rounded-full bg-summer-300 uppercase px-3 py-1 text-xs font-bold text-white ' +
            props.class
          }>
          low
        </span>
      )
    case 'medium':
      return (
        <span
          className={
            'rounded-full bg-summer-700 uppercase px-3 py-1 text-xs font-bold text-white ' +
            props.class
          }>
          medium
        </span>
      )
    case 'high':
      return (
        <span
          className={
            'rounded-full bg-mars-300 uppercase px-3 py-1 text-xs font-bold text-white ' +
            props.class
          }>
          high
        </span>
      )
    case 'critical':
      return (
        <span
          className={
            'rounded-full bg-coral-400 uppercase px-3 py-1 text-xs font-bold text-white ' +
            props.class
          }>
          critical
        </span>
      )
    default:
      return (
        <span
          className={
            'rounded-full bg-gray-500 uppercase px-3 py-1 text-xs font-bold text-white ' +
            props.class
          }>
          -
        </span>
      )
  }
}

SeverityChip.propTypes = {
  severity: PropTypes.string,
  class: PropTypes.string,
}

export default SeverityChip
