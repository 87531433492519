import i18next from 'i18next'

export const shouldRenderErrors = logs => {
  const values = Object.values(logs)
  let shouldRender = false

  values.forEach(value => {
    if (value && value.code && value.code !== 'ok') shouldRender = true
  })

  return shouldRender
}

export const shouldRenderProvider = state => {
  if (!state || !Array.isArray(state)) return false

  return state.find(
    integration =>
      integration.status === 'active' ||
      integration.status === 'started' ||
      integration.status === 'failed'
  )
}

export const hasPeopleIntegration = (
  googleWorkspaceIntegrationsState,
  microsoft365IntegrationsState
) =>
  googleWorkspaceIntegrationsState.length > 0 ||
  microsoft365IntegrationsState.length > 0

export const mappedSourcesNames = {
  manual: i18next.t('people.emailSources.manual'),
  microsoft365: 'Microsoft 365',
  registration: 'Unxpose',
  web: 'Web',
  workspace: 'Google Workspace',
}
