import React from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'

import {DefaultModal} from 'components/partials/DefaultModal'
import {removeDocument} from 'actions'
import {Trans} from 'react-i18next'

const DocumentRemoveModal = ({removeDocumentModal, setRemoveDocumentModal}) => {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)

  const handleRemoveDocument = documentId => {
    dispatch(
      removeDocument({
        artifactId: documentId,
        _csrf: csrfState,
      })
    )

    setRemoveDocumentModal({
      selected: null,
      showModal: false,
    })
  }

  return (
    <DefaultModal
      id="removeDocumentModal"
      title={i18next.t('documents.modal.remove.title')}
      primaryButtonTheme="red"
      primaryBtnText={i18next.t('documents.modal.remove.primaryBtn')}
      secondayBtnText={i18next.t('documents.modal.remove.secondaryBtn')}
      body={
        <div className="text-sm text-gray-700 leading-6">
          <Trans
            i18nKey="documents.modal.remove.body"
            values={{
              documentName: removeDocumentModal.selected.name,
            }}
          />
        </div>
      }
      onClickPrimaryBtn={() =>
        handleRemoveDocument(removeDocumentModal.selected.id)
      }
      onClickSecondaryBtn={() => {
        setRemoveDocumentModal({
          selected: null,
          showModal: false,
        })
      }}
    />
  )
}

export default DocumentRemoveModal

DocumentRemoveModal.propTypes = {
  id: PropTypes.string,
  removeDocumentModal: PropTypes.object,
  setRemoveDocumentModal: PropTypes.func,
}
