import React from 'react'
import {i18next} from 'translate/i18n'
import bgOrangePurple from 'res/backgrounds/bgOrangePurple.svg'
import LogoWhite from 'res/brand/logo-white.svg'

export default function PublicViewBg() {
  return (
    <div
      style={{backgroundImage: `url(${bgOrangePurple})`}}
      className="hidden lg:flex items-center justify-center fixed h-screen
      w-screen bg-cover text-white z-0">
      <div className="transform -translate-x-full translate-y-1/2 text-2xl">
        <img src={LogoWhite} alt="Unxpose" className="w-64 mb-10" />
        <h1>{i18next.t('titles.public')}</h1>
        <h2>{i18next.t('subtitles.public')}</h2>
      </div>
    </div>
  )
}
