import PropTypes from 'prop-types'
import React from 'react'
import {useNavigate} from 'react-router-dom'

import QuestionMarkIcon from 'res/icons/questionMarkIcon'

export default function WrappedProperty({label, value, helpPath}) {
  const navigate = useNavigate()

  return (
    <div className="py-1 px-2 hover:bg-astral-200 rounded-lg inline-block group pr-8">
      <span className="font-semibold mr-2">{label}:</span>
      <div className="inline-block">{value}</div>
      <div
        className="hidden group-hover:inline-block px-2 mt-1 absolute cursor-pointer"
        onClick={() => navigate(`#/help/${helpPath}`, {replace: true})}>
        <QuestionMarkIcon size="20" height="20" color="#0A50A0" />
      </div>
    </div>
  )
}

WrappedProperty.propTypes = {
  label: PropTypes.string,
  helpPath: PropTypes.string,
  value: PropTypes.any,
}
