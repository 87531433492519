import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'

import {addSupplier, loading} from 'actions'
import {Page} from 'components/partials/Page'
import BackButton from 'components/partials/buttons/BackButton'
import Subsection from 'components/partials/headers/Subsection'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {isLoading} from 'utils'

export default function SuppliersNew() {
  return (
    <Page pageTitle={i18next.t('pageTitles.suppliers')}>
      <BackButton fallbackLink="/suppliers" />
      <div className="mt-4">
        <Subsection
          title={i18next.t('suppliers.registeredSuppliers.addNew.title')}
          content={<Form />}
        />
      </div>
    </Page>
  )
}

const Form = () => {
  const initialFormState = {
    name: '',
    email: '',
  }

  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)

  const [form, setForm] = useState(initialFormState)

  const handleInputChange = e => {
    const {name} = e.target
    setForm({...form, [name]: e.target.value})
  }

  const handleSubmit = e => {
    e.preventDefault()

    dispatch(loading({addSupplier: true}))
    dispatch(
      addSupplier({
        name: form.name,
        email: form.email,
        _csrf: csrfState,
      })
    )
  }

  return (
    <>
      <form className="xl:w-10/12 py-4 px-6" onSubmit={handleSubmit}>
        <div id="inputs-container">
          <div className="md:flex border-b border-sideral-50 pt-8 pb-6">
            <div className="basis-1/3 mr-8">
              <p className="text-sm text-sideral-900">
                {i18next.t('suppliers.registeredSuppliers.addNew.companyName')}*
              </p>
              <p className="text-light text-xs text-sideral-400 pt-1">
                {i18next.t(
                  'suppliers.registeredSuppliers.addNew.companyNameHelper'
                )}
              </p>
            </div>
            <div className="w-full sm:basis-2/3">
              <PrimaryInput
                name="name"
                type="text"
                value={form.name}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="md:flex pt-8 pb-6">
            <div className="basis-1/3 mr-8">
              <p className="text-sm text-sideral-900">
                {i18next.t(
                  'suppliers.registeredSuppliers.addNew.invitationEmail.label'
                )}
                *
              </p>
              <p className="text-light text-xs text-sideral-400 pt-1">
                {i18next.t(
                  'suppliers.registeredSuppliers.addNew.invitationEmail.helper'
                )}
              </p>
            </div>
            <div className="w-full sm:basis-2/3">
              <PrimaryInput
                name="email"
                type="email"
                value={form.email}
                onChange={handleInputChange}
                placeholder={i18next.t(
                  'suppliers.registeredSuppliers.addNew.invitationEmail.placeholder'
                )}
                required
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-2 mb-4">
          <PrimaryButton
            text={i18next.t('suppliers.registeredSuppliers.addNew.addBtn')}
            type="submit"
            loading={isLoading(loadingState.addSupplier, false)}
          />
        </div>
      </form>
    </>
  )
}
