import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from './buttons/PrimaryButton'
import Spinner from './Spinner'

export const DefaultModal = ({
  id,
  title,
  body,
  bodyClasses,
  primaryBtnText,
  secondayBtnText,
  onClickPrimaryBtn,
  onClickSecondaryBtn,
  secondaryBtnTooltip,
  disablePrimaryBtn,
  disableSecondaryBtn,
  primaryButtonTheme,
  primaryButtonType,
  primaryButtonLoading,
  loadingContent,
  onCloseModal,
  width,
}) => {
  useEffect(() => {
    if (!onCloseModal) return

    document.addEventListener('mouseup', e => {
      const modal = document.querySelector(`#${id}`)
      if (e.target === modal) onCloseModal()
    })
  }, [])

  return (
    <div id={id} className="modal-bg fixed top-0 left-0 w-screen h-screen z-50">
      <div
        className={`fixed bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 bg-white rounded-md shadow-md p-6 w-full ${
          width || 'max-w-lg'
        }`}>
        <div className="relative">
          {onCloseModal && (
            <div className="absolute right-0 z-50">
              <button type="button" onClick={() => onCloseModal()}>
                <i className="icofont-close text-xl" />
              </button>
            </div>
          )}
          <div
            id="modal-header"
            className="text-sideral-900 leading-none border-b border-sideral-50 text-lg pb-6 mb-6 font-medium">
            {title}
          </div>

          {!loadingContent ? (
            <>
              <div id="modal-body" className={bodyClasses}>
                {body}
              </div>

              {primaryBtnText && (
                <div
                  id="modal-footer"
                  className={`flex w-full mt-6 ${
                    !secondayBtnText && 'justify-end'
                  }`}>
                  <div className={`${secondayBtnText && 'w-full'}`}>
                    <PrimaryButton
                      text={primaryBtnText}
                      size="full"
                      margin="mr-2"
                      theme={primaryButtonTheme}
                      onClick={onClickPrimaryBtn}
                      disabled={disablePrimaryBtn}
                      type={primaryButtonType}
                      loading={primaryButtonLoading}
                    />
                  </div>
                  {secondayBtnText && (
                    <div className="w-full">
                      <PrimaryButton
                        text={secondayBtnText}
                        size="full"
                        margin="ml-2"
                        theme="blue-outline"
                        onClick={onClickSecondaryBtn}
                        disabled={disableSecondaryBtn}
                        tooltip={secondaryBtnTooltip}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </div>
  )
}

DefaultModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.object,
  bodyClasses: PropTypes.string,
  primaryBtnText: PropTypes.string,
  primaryButtonTheme: PropTypes.string,
  secondayBtnText: PropTypes.string,
  onClickPrimaryBtn: PropTypes.func,
  onClickSecondaryBtn: PropTypes.func,
  secondaryBtnTooltip: PropTypes.object,
  disablePrimaryBtn: PropTypes.bool,
  disableSecondaryBtn: PropTypes.bool,
  primaryButtonType: PropTypes.string,
  primaryButtonLoading: PropTypes.bool,
  loadingContent: PropTypes.bool,
  onCloseModal: PropTypes.func,
  width: PropTypes.string,
}
