import React from 'react'
import i18next from 'i18next'
import ReactMarkdown from 'react-markdown'
import {anchorLinkMarkdown} from 'utils'

import images from 'res'
import {integrationStyles, awsUnxposePolicy} from 'constant'
import Tutorial from 'components/partials/Tutorial'
import CopyToClipboard from 'components/partials/CopyToClipboard'

const AwsManualUpdateTutorial = () => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(awsUnxposePolicy, null, 2))
  }

  const markdownSteps = `
  
  ${i18next.t('cloud.aws.integrationUpdate.manual.steps.one')}

  ${i18next.t('cloud.aws.integrationUpdate.manual.steps.two')}
 

  ${i18next.t('cloud.aws.integrationUpdate.manual.steps.three')}
  ![policiesMenuAWS](${images.policiesMenuAWS})


  ${i18next.t('cloud.aws.integrationUpdate.manual.steps.four')}
  ![permissionsTabAWS](${images.permissionsTabAWS})


  ${i18next.t('cloud.aws.integrationUpdate.manual.steps.five')}
  ${`~~~json
  ~~~`}
  ![policyEditorAWS](${images.policyEditorAWS})


  ${i18next.t('cloud.aws.integrationUpdate.manual.steps.six')}
  
  ${i18next.t('cloud.aws.integrationUpdate.manual.steps.seven')}

  ${i18next.t('cloud.aws.integrationUpdate.manual.conclusion')}`

  return (
    <Tutorial pageTitle={'Cloud'} fallbackLink="/cloud">
      <div className={integrationStyles.mainTitle}>
        {i18next.t('cloud.aws.integrationUpdate.manual.title')}
      </div>

      <div className={integrationStyles.secondaryTitle}>
        {i18next.t('cloud.aws.integrationUpdate.manual.description')}
      </div>

      <div className="template-markdown break-words">
        <ReactMarkdown
          components={{
            a: anchorLinkMarkdown,
            code: () => (
              <CopyToClipboard
                content={
                  <pre>
                    <code>{JSON.stringify(awsUnxposePolicy, null, 2)}</code>
                  </pre>
                }
                onClick={copyToClipboard}
              />
            ),
          }}>
          {markdownSteps}
        </ReactMarkdown>
      </div>
    </Tutorial>
  )
}

export default AwsManualUpdateTutorial
