import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {Trans} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'

import CheckIcon from 'res/icons/checkIcon'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import i18next from 'i18next'
import images from 'res'
import {
  loading,
  updateQuestionnaireAnswer,
  clearAnswerFeedback,
  generateAnswer,
  toggleRestrictionModal,
  fetchDocuments,
  clearAction,
} from 'actions'
import {
  gray200,
  sideral300,
  sky500,
  green500,
  mars300,
  green700,
  mars700,
  orange700,
} from 'constant'
import {
  getDocumentDate,
  getDocumentTitle,
  getFeatureSubscription,
  getPropByLocale,
  isLoading,
} from 'utils'
import MagicWandIcon from 'res/icons/magicWandIcon'
import InfoIcon from 'res/icons/infoIcon'
import PlanUtils from 'utils/plan'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import FileIcon from 'res/icons/FileIcon'
import PrimaryTextArea from 'components/partials/inputs/PrimaryTextArea'
import {createFilter} from 'react-select'
import DownloadIcon from 'res/icons/downloadIcon'
import config from 'config'
import QuestionReferencesModal from './modals/question/QuestionReferencesModal'
import QuestionGenerateAnswerModal from './modals/question/QuestionGenerateAnswerModal'
import QuestionSaveAlertModal from './modals/question/QuestionSaveAlertModal'
import QuestionDocumentsModal from './modals/question/QuestionDocumentsModal'
import {getTextAreaRowCount} from './questionnairesUtils'
import QuestionnaireEvaluationBadge from './QuestionnaireEvaluationBadge'

export default function QuestionBox({
  questionnaireId,
  questionnaireRules,
  questionGroup,
  questionIndex,
  openQuestionId,
  setOpenQuestionId,
  userLocale,
  setChangesTracking,
  questionHasUnsavedChanges,
}) {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const answersFeedbackState = useSelector(state => state.answersFeedback)
  const generatedAnswerState = useSelector(state => state.generatedAnswer)
  const documentsState = useSelector(state => state.documents)
  const isDocumentUploadedState = useSelector(state => state.isDocumentUploaded)

  const initialFormState = {
    answer: questionGroup?.answer?.values[0] || '',
    considerations: questionGroup?.answer?.comments || '',
    evidences: questionGroup?.artifacts?.map(item => ({
      value: item.id,
      label: getDocumentTitle(item.type),
      consultancy: item.data.consultancy,
      date: moment(getDocumentDate(item.type, item.data)).format('L'),
    })),
  }

  const initialFeedbackMsg = {
    type: '',
    message: '',
    showFeedback: false,
  }

  const [form, setForm] = useState(initialFormState)
  const [isQuestionOpen, setQuestionOpen] = useState(false)
  const [showUnableToGenerateAnswerModal, setShowUnableToGenerateAnswerModal] =
    useState(false)
  const [feedbackMsg, setFeedbackMsg] = useState(initialFeedbackMsg)
  const [showSaveAlertModal, setShowSaveAlertModal] = useState(false)
  const [showDocumentsModal, setShowDocumentsModal] = useState(false)
  const [showReferencesModal, setShowReferencesModal] = useState(false)
  const [showReferencesLink, setShowReferencesLink] = useState(false)
  const [references, setReferences] = useState([])
  const [preventSelect, setPreventSelect] = useState(false)

  const planUtils = PlanUtils()

  const hasAnswer = questionGroup => {
    return questionGroup.answer?.values?.length && !questionGroup.answer.empty
  }

  const getDisplayName = user => {
    if (!user) {
      return ''
    }

    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`
    }

    return user.firstName
  }

  const handleQuestionChanges = ({answer, considerations, evidences}) => {
    const originalAnswer = questionGroup?.answer?.values[0] || ''
    const originalConsiderations = questionGroup?.answer?.comments || ''
    const originalEvidences = questionGroup?.artifacts

    setChangesTracking({
      isAnswerModified: originalAnswer !== answer,
      isConsiderationsModified: originalConsiderations !== considerations,
      isEvidencesModified:
        JSON.stringify(originalEvidences.map(item => item.id).sort()) !==
        JSON.stringify(evidences.map(item => item.value).sort()),
    })
  }

  const handleInputChange = event => {
    const {name, value} = event.target

    setForm({...form, [name]: value})
    handleQuestionChanges({...form, [name]: value})

    setShowReferencesLink(false)
    setReferences([])
  }

  const handleSelectEvidenceChange = selectedDocs => {
    if (preventSelect) return

    setForm({...form, evidences: selectedDocs})
    handleQuestionChanges({...form, evidences: selectedDocs})
  }

  const handleDiscard = () => {
    setChangesTracking({
      isAnswerModified: false,
      isConsiderationsModified: false,
      isEvidencesModified: false,
    })

    setForm(initialFormState)
  }

  const submitAnswer = e => {
    e.preventDefault()

    dispatch(loading({answer: true}))

    dispatch(
      updateQuestionnaireAnswer({
        questionnaireId,
        questionId: questionGroup.id,
        answer: {
          comments: form.considerations,
          values: [form.answer],
        },
        artifactIds: form.evidences.map(doc => doc.value),
        _csrf: csrfState,
      })
    )

    setOpenQuestionId(null)

    setTimeout(() => {
      dispatch(
        clearAnswerFeedback({
          questionId: questionGroup.id,
        })
      )
    }, 3000)
  }

  const handleEnterKeyToggleQuestion = event => {
    if (
      event.key === 'Enter' &&
      !['button', 'submit'].includes(event.target.type)
    ) {
      handleToggleQuestion(event)
    }
  }

  const handleToggleQuestion = () => {
    const preventToggleQuestion = isLoading(loadingState.generatedAnswer, false)

    if (!preventToggleQuestion) {
      if (questionHasUnsavedChanges() && openQuestionId !== questionGroup.id) {
        setShowSaveAlertModal(true)
        return
      }

      setOpenQuestionId(questionGroup.id)
    }
  }

  const handleGenerateAnswer = () => {
    if (!planUtils.userHasPremiumPlan()) {
      return dispatch(
        toggleRestrictionModal({
          contentId: 'generateAnswerQuestionnaire',
          showModal: true,
        })
      )
    }

    dispatch(loading({generatedAnswer: true}))

    dispatch(
      generateAnswer({
        questionId: questionGroup.id,
        _csrf: csrfState,
      })
    )
  }

  const getDocumentsList = () =>
    documentsState?.map(doc => ({
      id: doc.id,
      value: doc.id,
      label: getDocumentTitle(doc.type),
      consultancy: doc.data.consultancy,
      date: moment(getDocumentDate(doc.type, doc.data)).format('L'),
    }))

  useEffect(() => {
    setQuestionOpen(openQuestionId === questionGroup.id)
  }, [openQuestionId])

  useEffect(() => {
    if (answersFeedbackState?.[questionGroup.id] === 'success') {
      setChangesTracking({
        isAnswerModified: false,
        isConsiderationsModified: false,
        isEvidencesModified: false,
      })
    }
  }, [answersFeedbackState])

  useEffect(() => {
    if (isDocumentUploadedState) {
      setShowDocumentsModal(false)
      dispatch(clearAction('isDocumentUploaded'))
    }
  }, [isDocumentUploadedState])

  useEffect(() => {
    if (generatedAnswerState && openQuestionId === questionGroup.id) {
      if (generatedAnswerState.unableToGenerateAnswers) {
        return setShowUnableToGenerateAnswerModal(true)
      }

      if (generatedAnswerState.answer) {
        const generatedData = {
          ...form,
          answer: generatedAnswerState.answer,
          considerations: generatedAnswerState.comment,
        }

        setForm(generatedData)
        setFeedbackMsg({
          type: 'success',
          message: i18next.t('questionnaires.generationSuccess'),
          showFeedback: true,
        })

        if (generatedAnswerState.context) {
          setReferences(generatedAnswerState.context)
          setShowReferencesLink(true)
        }

        handleQuestionChanges(generatedData)
      } else {
        setFeedbackMsg({
          type: 'noAnswer',
          message: i18next.t('questionnaires.generationNoAnswer'),
          showFeedback: true,
        })
      }

      setTimeout(() => setFeedbackMsg(initialFeedbackMsg), 6000)
    }
  }, [generatedAnswerState])

  const handleDownloadDocument = id => {
    const url = new URL(`${config.SERVER_URL}/artifacts/${id}`)
    window.open(url, '_blank')
  }

  const onClickAddNewDocument = () => {
    if (planUtils.hasAccessToDocuments()) return setShowDocumentsModal(true)

    window.open('/documents')
  }

  const formatOptionLabel = ({value, label, consultancy, date}) => {
    return (
      <div className="group flex items-center pointer-events-auto">
        <div className="w-6">
          <div className="group-hover:hidden">
            <FileIcon height="26" width="26" color={mars700} />
          </div>

          <a
            target="_blank"
            className="hidden group-hover:block cursor-pointer"
            onMouseEnter={() => setPreventSelect(true)}
            onMouseLeave={() => setPreventSelect(false)}
            onClick={() => handleDownloadDocument(value)}>
            <DownloadIcon height="30" width="30" color={sky500} />
          </a>
        </div>

        <div className="mx-3">
          <div className="text-sm truncate w-full">{label}</div>
          <div className="flex text-xs text-sideral-400">
            {consultancy && (
              <div>
                {consultancy}
                <span className="mx-1">•</span>
              </div>
            )}
            <div>{date}</div>
          </div>
        </div>
      </div>
    )
  }

  const onOpenEvidencesSelect = () => {
    if (planUtils.hasAccessToDocuments()) {
      dispatch(loading({documents: true}))
      dispatch(fetchDocuments())
    }
  }

  const savedSuccessfully = answersFeedbackState?.[questionGroup.id]
  const answerWasEdited = questionGroup.editedAfterReview

  return (
    <div className="flex items-center border-b">
      <div
        onClick={isQuestionOpen ? null : handleToggleQuestion}
        onKeyDown={handleEnterKeyToggleQuestion}
        tabIndex="0"
        style={{minHeight: '72px'}}
        className={
          `w-full relative flex flex-wrap` +
          (isQuestionOpen
            ? ' font-normal'
            : ' cursor-pointer font-light hover:text-black')
        }>
        <div className="relative w-full flex flex-wrap">
          <div
            className={
              'p-4 ' +
              (isQuestionOpen
                ? 'w-1/2 mt-2 relative'
                : 'w-full flex mt-2 select-none')
            }>
            <div
              className={
                isQuestionOpen ? 'cursor-pointer' : 'w-full truncate pr-4'
              }
              onClick={isQuestionOpen ? handleToggleQuestion : null}>
              <span className="font-medium mr-1">{questionIndex + 1}.</span>
              <span>
                {isQuestionOpen
                  ? getPropByLocale(questionGroup.content.question, userLocale)
                      .split(/\r\n|\n|\r/gm)
                      .map(line => {
                        return (
                          <span key={questionGroup.id}>
                            {line}
                            <br />
                          </span>
                        )
                      })
                  : getPropByLocale(questionGroup.content.question, userLocale)}
              </span>
            </div>

            {isQuestionOpen ? (
              <>
                {questionGroup?.updatedAt ? (
                  <div className="align-text-bottom text-xs absolute bottom-0 mb-4 flex text-gray-600">
                    <images.ClockIcon width="18" color={sideral300} />
                    <div className="py-2 px-1">
                      <Trans
                        i18nKey="questionnaires.updateInfo"
                        values={{
                          updatedAt: moment(questionGroup?.updatedAt).fromNow(),
                          updatedBy: getDisplayName(questionGroup?.updatedBy),
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {!answerWasEdited && questionGroup.review && (
                  <div className="mr-3 shrink-0">
                    <QuestionnaireEvaluationBadge
                      evaluation={questionGroup.review.evaluation}
                    />
                  </div>
                )}

                {savedSuccessfully ? (
                  <CheckIcon
                    width="20"
                    color={savedSuccessfully === 'success' ? green500 : mars300}
                  />
                ) : (
                  <CheckIcon
                    width="20"
                    color={hasAnswer(questionGroup) ? sky500 : gray200}
                  />
                )}
              </>
            )}
          </div>

          {isQuestionOpen ? (
            <div className="w-1/2">
              {questionGroup.review && (
                <div
                  id="review-feedback"
                  className="w-full bg-orange-200 border-l-4 border-orange-500 border text-orange-900 text-sm p-6">
                  <div
                    className={`${
                      answerWasEdited && 'opacity-[.3]'
                    } flex justify-between`}>
                    <div className="shrink-0 mr-10">
                      <div className="font-medium mb-2">
                        {i18next.t(
                          'questionnaires.review.questionBox.answerEvaluation'
                        )}
                      </div>
                      <QuestionnaireEvaluationBadge
                        evaluation={questionGroup.review.evaluation}
                      />
                    </div>
                    {questionGroup.review.comments && (
                      <div className="w-full">
                        <div className="font-medium mb-2">
                          {i18next.t(
                            'questionnaires.review.questionBox.commentsLabel'
                          )}
                        </div>
                        <div>{questionGroup.review.comments}</div>
                      </div>
                    )}
                  </div>

                  {answerWasEdited && (
                    <div className="border-t border-orange-400 mt-4 pt-4">
                      {i18next.t(
                        'questionnaires.review.questionBox.editedAnswer'
                      )}
                    </div>
                  )}
                </div>
              )}

              <div className="bg-gray-100 border-l p-4">
                <div className="flex flex-wrap p-4">
                  <div className="flex items-center justify-between w-full mb-4">
                    <div className="basis-2/3">
                      {!questionnaireRules.canEdit && (
                        <div className="flex items-start">
                          <div>
                            <InfoIcon width="18" height="18" />
                          </div>
                          <div className="text-xs ml-1">
                            {i18next.t('questionnaires.cannotChange')}
                          </div>
                        </div>
                      )}

                      {feedbackMsg.showFeedback && (
                        <div className="flex items-start">
                          <div>
                            <InfoIcon
                              width="18"
                              height="18"
                              color={
                                feedbackMsg.type === 'success'
                                  ? green700
                                  : orange700
                              }
                            />
                          </div>
                          <div
                            className={`text-xs ml-1 select-none font-medium ${
                              feedbackMsg.type === 'success'
                                ? 'text-green-700'
                                : 'text-orange-700'
                            }`}>
                            {feedbackMsg.message}
                          </div>
                        </div>
                      )}
                    </div>

                    {getFeatureSubscription(userState, 'aiQuestionnaires')
                      .hasAccess && (
                      <div className="basis-auto">
                        <PrimaryButton
                          text={i18next.t('questionnaires.generateAnswer')}
                          icon={<MagicWandIcon height="20" width="20" />}
                          onClick={handleGenerateAnswer}
                          loading={isLoading(
                            loadingState.generatedAnswer,
                            false
                          )}
                          disabled={!questionnaireRules.canEdit}
                          size="sm"
                          theme="blue-outline"
                        />
                      </div>
                    )}
                  </div>
                  <form
                    className="flex items-center flex-wrap w-full"
                    onSubmit={submitAnswer}>
                    <div className="w-full">
                      <PrimaryTextArea
                        id="answer"
                        name="answer"
                        text={i18next.t('questionnaires.answer')}
                        onChange={handleInputChange}
                        value={form.answer}
                        rows={getTextAreaRowCount(form.answer)}
                        disabled={
                          loadingState?.answer || !questionnaireRules.canEdit
                        }
                        required
                      />
                    </div>

                    <div className="w-full">
                      <PrimaryTextArea
                        id="considerations"
                        name="considerations"
                        text={i18next.t('questionnaires.considerations')}
                        onChange={handleInputChange}
                        value={form.considerations}
                        rows={getTextAreaRowCount(form.considerations)}
                        margin="0"
                        disabled={
                          loadingState?.answer || !questionnaireRules.canEdit
                        }
                      />
                    </div>

                    {showReferencesLink && (
                      <div className="text-sm mb-2">
                        {i18next.t(
                          'questionnaires.questionReferences.generatedByAI'
                        )}
                        <Link
                          className="text-sky-900 underline ml-1"
                          onClick={() => setShowReferencesModal(true)}>
                          {i18next.t(
                            'questionnaires.questionReferences.openReferences'
                          )}
                        </Link>
                      </div>
                    )}

                    <div className="w-full mt-3">
                      <PrimarySelect
                        text={i18next.t(
                          'questionnaires.evidences.evidencesQty',
                          {
                            count: form.evidences?.length,
                          }
                        )}
                        value={form.evidences}
                        options={getDocumentsList()}
                        onChange={handleSelectEvidenceChange}
                        isLoading={isLoading(loadingState.documents, false)}
                        formatOptionLabel={formatOptionLabel}
                        onMenuOpen={onOpenEvidencesSelect}
                        onMenuClose={() => setPreventSelect(false)}
                        openMenuOnClick={!preventSelect}
                        filterOption={createFilter({
                          matchFrom: 'any',
                          stringify: option =>
                            `${option.label} ${option.data.consultancy}`,
                        })}
                        addNewButton={
                          <SecondaryButton
                            text={i18next.t('misc.addNew')}
                            onClick={onClickAddNewDocument}
                            size="sm"
                          />
                        }
                        disabled={!questionnaireRules.canEdit}
                        isMulti
                      />
                    </div>

                    <div className="w-full flex justify-between pt-4 items-center">
                      <SecondaryButton
                        size="sm"
                        onClick={handleDiscard}
                        loading={isLoading(loadingState.answer, false)}
                        text={i18next.t('questionnaires.discard')}
                        disabled={!questionnaireRules.canEdit}
                      />

                      <div className="flex">
                        <PrimaryButton
                          size="sm"
                          text={i18next.t(
                            'questionnaires.evidences.addDocumentBtn'
                          )}
                          theme="blue-outline"
                          margin="mr-2"
                          iconClass="icofont-plus mr-2"
                          onClick={onClickAddNewDocument}
                          loading={isLoading(loadingState.answer, false)}
                          disabled={!questionnaireRules.canEdit}
                        />

                        <PrimaryButton
                          size="sm"
                          text={i18next.t('questionnaires.save')}
                          type="submit"
                          loading={isLoading(loadingState.answer, false)}
                          disabled={!questionnaireRules.canEdit}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {showUnableToGenerateAnswerModal && (
        <QuestionGenerateAnswerModal
          setShowUnableToGenerateAnswerModal={
            setShowUnableToGenerateAnswerModal
          }
        />
      )}

      {showSaveAlertModal && (
        <QuestionSaveAlertModal
          questionGroup={questionGroup}
          setChangesTracking={setChangesTracking}
          setOpenQuestionId={setOpenQuestionId}
          setShowSaveAlertModal={setShowSaveAlertModal}
        />
      )}

      {showDocumentsModal && (
        <QuestionDocumentsModal setShowDocumentsModal={setShowDocumentsModal} />
      )}

      {showReferencesModal && (
        <QuestionReferencesModal
          questionId={questionGroup.id}
          references={references}
          setShowReferencesModal={setShowReferencesModal}
        />
      )}
    </div>
  )
}

QuestionBox.propTypes = {
  questionnaireId: PropTypes.string,
  questionnaireRules: PropTypes.object,
  questionGroup: PropTypes.object,
  questionIndex: PropTypes.number,
  openQuestionId: PropTypes.string,
  setOpenQuestionId: PropTypes.func,
  setChangesTracking: PropTypes.func,
  questionHasUnsavedChanges: PropTypes.func,
  userLocale: PropTypes.string,
}
