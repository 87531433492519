import React, {useEffect, useRef, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import {
  stopAwsIntegration,
  stopAzureIntegration,
  stopGcpIntegration,
} from 'actions'

import PrimaryButton from 'components/partials/buttons/PrimaryButton'

const RemoveIntegrationModal = ({
  modalVisibility,
  setModalVisibility,
  integrationInfo,
}) => {
  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)
  const disabledState = useSelector(state => state.disabled)

  const dispatch = useDispatch()

  const initialFormState = {hostname: '', enum: true, monitored: true}
  const [form, setForm] = useState(initialFormState)
  const domainInput = useRef()

  const removalInfo = () => {
    let removalInfo

    switch (integrationInfo.provider) {
      case 'aws':
        removalInfo = i18next.t(
          'cloud.aws.removeIntegration.tryingToRemoveAccount'
        )
        break

      case 'azure':
        removalInfo = i18next.t(
          'cloud.azure.removeIntegration.tryingToRemoveTenant'
        )
        break

      case 'gcp':
        removalInfo = i18next.t(
          'cloud.gcp.removeIntegration.tryingToRemoveProject'
        )
        break
    }

    return (
      <p className="pb-2">
        {removalInfo}
        <span className="font-medium">{integrationInfo.integrationName}</span>.
      </p>
    )
  }

  function handleInviteChange(e) {
    const {name} = e.target
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setForm({...form, [name]: value})
  }

  function handleSubmit(e) {
    e.preventDefault() //will be used when typing confirmation

    switch (integrationInfo.provider) {
      case 'aws':
        dispatch(
          stopAwsIntegration({
            integrationId: integrationInfo.integrationId,
            _csrf: csrfState,
          })
        )
        break

      case 'gcp':
        dispatch(
          stopGcpIntegration({
            integrationId: integrationInfo.integrationId,
            _csrf: csrfState,
          })
        )
        break

      case 'azure':
        dispatch(
          stopAzureIntegration({
            integrationId: integrationInfo.integrationId,
            _csrf: csrfState,
          })
        )
        break
    }
  }

  function closeModal(e) {
    const inviteSectionModal = document.querySelector('#add-domain-modal')
    if (e.target === inviteSectionModal) {
      setModalVisibility('hidden')
    }
  }

  useEffect(() => {
    if (modalVisibility === 'hidden') {
      setForm(initialFormState)
      return
    }

    if (domainInput.current) domainInput.current.focus()
  }, [modalVisibility])

  useEffect(() => {
    document.addEventListener('mouseup', e => closeModal(e))
  }, [])

  return (
    <div
      id="add-domain-modal"
      className={`${modalVisibility} modal-bg fixed top-0 left-0 w-screen h-screen text-xs z-50`}>
      <form
        onSubmit={handleSubmit}
        className="fixed bottom-1/2 right-1/2 transform translate-x-1/2 max-w-lg w-full bg-white rounded-md shadow-md p-6">
        <div className="text-sideral-900 leading-none border-b border-sideral-50 text-lg pb-6 mb-6">
          {i18next.t('cloud.removal.modal.title')}
        </div>
        <div className="text-sm mt-4">
          {removalInfo()}

          <p>{i18next.t('cloud.removal.modal.warning')}</p>

          <div className="dm-mono flex items-center mb-6">
            <input
              id={`monitor ${form.hostname}`}
              name="monitored"
              className="switch switch--shadow"
              type="checkbox"
              checked={form.monitored}
              onChange={handleInviteChange}
            />
          </div>
          <div className="flex">
            <div className="w-full">
              <PrimaryButton
                text={i18next.t('cloud.removal.modal.submit')}
                type="submit"
                size="full"
                margin="mr-2"
                loading={disabledState}
                disabled={userState?.role === 'read-only'}
              />
            </div>
            <div className="w-full">
              <PrimaryButton
                text={i18next.t('buttons.cancel')}
                theme="blue-outline"
                type="button"
                size="full"
                margin="ml-2"
                onClick={() => {
                  setModalVisibility('hidden')
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

RemoveIntegrationModal.propTypes = {
  modalVisibility: PropTypes.string,
  setModalVisibility: PropTypes.func,
  history: PropTypes.object,
  integrationInfo: PropTypes.object,
}

export default RemoveIntegrationModal
