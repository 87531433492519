import React from 'react'
import PropTypes from 'prop-types'
import {i18next} from 'translate/i18n'

function GlobalFilter({
  customHandleFilter,
  customPlaceholder,
  filterClass,
  filterWidth,
  globalFilter,
  gotoPage,
  preGlobalFilteredRows,
  setFilterValue,
  setGlobalFilter,
  setLoading,
  totalPages,
  autoFocus,
  value,
}) {
  const defaultPlaceholder = `${i18next.t('tables.filterIn')} ${getRowCount(
    totalPages,
    preGlobalFilteredRows
  )} ${i18next.t('tables.records').toLowerCase()}`

  function defaultHandleFilter(e) {
    gotoPage(0)
    setFilterValue(e.target.value)
    setGlobalFilter(e.target.value || undefined)
    if (setLoading) setLoading(true)
  }

  function getRowCount(totalPages, preGlobalFilteredRows) {
    // Server-side: use totalPages property
    if (totalPages) return totalPages

    //Client-side: use length of preGlobalFilteredRows array
    if (preGlobalFilteredRows && Array.isArray(preGlobalFilteredRows))
      return preGlobalFilteredRows.length

    return 0
  }

  return (
    <div
      className={`relative bg-white border rounded-md border-sideral-100 ${
        filterWidth ? filterWidth : 'w-64'
      }`}>
      <i className="icofont-search-1 text-xs text-sideral-300 absolute top-1/2 transform -translate-y-1/2 ml-3" />
      <input
        autoFocus={autoFocus}
        value={value || globalFilter || ''}
        onChange={e => {
          customHandleFilter ? customHandleFilter(e) : defaultHandleFilter(e)
        }}
        placeholder={customPlaceholder ? customPlaceholder : defaultPlaceholder}
        className={`text-xs text-sideral-900 placeholder:text-gray-500 outline-none border-0 py-[9px] pl-[29px] ml-1 w-11/12 ${filterClass}`}
      />
    </div>
  )
}

GlobalFilter.propTypes = {
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  customHandleFilter: PropTypes.func,
  customPlaceholder: PropTypes.string,
  filterClass: PropTypes.string,
  filterWidth: PropTypes.string,
  globalFilter: PropTypes.string,
  gotoPage: PropTypes.func,
  preGlobalFilteredRows: PropTypes.array,
  setFilterValue: PropTypes.func,
  setGlobalFilter: PropTypes.func,
  setLoading: PropTypes.func,
  totalPages: PropTypes.number,
}

export default GlobalFilter
