import {v4 as uuidv4} from 'uuid'
import ContentBox from 'components/partials/ContentBox'
import GenericFinding from 'components/GenericFinding'
import Moment from 'react-moment'
import NewTabLink from 'components/partials/NewTabLink'
import React, {useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import SeverityChip from 'components/partials/SeverityChipV2'
import WrappedProperty from 'components/partials/WrappedProperty'
import gfm from 'remark-gfm'
import {Link} from 'react-router-dom'
import {allowedStatuses} from 'constant'
import {i18next} from 'translate/i18n'
import {useSelector} from 'react-redux'

import {
  fetchNetworkFinding,
  fetchNetworkFindingActivity,
  updateNetworkFinding,
} from 'actions'

import EyeIcon from 'res/icons/eyeIcon'
import FireIcon from 'res/icons/fireIcon'
import InfoIcon from 'res/icons/infoIcon'
import NetworkIcon from 'res/icons/networkIcon'

import config from 'config'

export default function NetworkFinding(props) {
  const networkFindingState = useSelector(state => state.networkFinding)
  const networkFindingActivityState = useSelector(
    state => state.networkFindingActivity
  )

  const [finding, setFinding] = useState(null)
  const [findingActivity, setFindingActivity] = useState(null)

  useEffect(() => {
    if (networkFindingState) setFinding(networkFindingState)
  }, [networkFindingState])

  useEffect(() => {
    if (networkFindingActivityState)
      setFindingActivity(networkFindingActivityState)
  }, [networkFindingActivityState])

  return (
    <GenericFinding
      {...props}
      allowedStatuses={allowedStatuses}
      fetchFinding={fetchNetworkFinding}
      fetchFindingActivity={fetchNetworkFindingActivity}
      finding={finding}
      findingActivity={findingActivity}
      findingBody={FindingBody}
      findingDetails={FindingDetails}
      findingIcon={FindingIcon}
      findingType="network"
      helpPath="network"
      setFinding={setFinding}
      setFindingActivity={setFindingActivity}
      title={i18next.t('pageTitles.network')}
      updateFinding={updateNetworkFinding}
    />
  )
}

const FindingIcon = () => {
  return (
    <div className="w-20 p-4">
      <NetworkIcon width="60" height="60" color="#90A4AE" />
    </div>
  )
}

const FindingDetails = finding => {
  return (
    <>
      <div className="p-4 md:flex bg-white rounded-lg">
        <div className="flex-1 md:mb-0 mb-6 md:pb-0 pb-6 md:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
            <InfoIcon size="20" height="20" color="#0A50A0" />
          </div>

          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('network.host')}:
            </span>
            <Link
              className="hover:underline"
              to={`${config.assetsPaths.network}/${finding.assetId}`}>
              {finding &&
                (finding.dnsRecordId
                  ? finding.dnsRecordId.replace(/(:A|:CNAME)/, '')
                  : finding.hostName)}
            </Link>
          </div>
          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('network.port')}:
            </span>
            <Link
              className="hover:underline"
              to={`${config.assetsPaths.network}/${finding.assetId}`}>
              {finding && finding.port}
            </Link>
          </div>
          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('network.protocol')}:
            </span>
            <Link
              className="hover:underline"
              to={`${config.assetsPaths.network}/${finding.assetId}`}>
              <span className="uppercase">{finding && finding.protocol}</span>
            </Link>
          </div>
        </div>
        <div className="flex-1 md:mb-0 mb-6 md:pb-0 pb-6 md:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 px-2 ml-1">
            <FireIcon size="20" height="20" color="#0A50A0" />
          </div>

          <div>
            <WrappedProperty
              label={i18next.t('finding.severity')}
              helpPath="score"
              value={<SeverityChip severity={finding && finding.severity} />}
            />
          </div>
          <div>
            {finding?.template?.cvss ? (
              <WrappedProperty
                label="CVSS v3"
                helpPath="cvss-cwe"
                value={finding.template.cvss}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="flex-1 md:mb-2 md:pb-0 pb-6 md:border-none last:border-none border-b">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
            <EyeIcon size="20" height="20" color="#0A50A0" />
          </div>

          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('finding.firstSeen')}
            </span>
            <Moment format="LLL" date={finding && finding.createdAt} />
          </div>
          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('finding.lastSeen')}
            </span>
            <Moment format="LLL" date={finding && finding.checkedAt} />
          </div>
        </div>
      </div>
    </>
  )
}

const FindingBody = finding => {
  const Description = () => {
    return (
      <div className="py-4 rounded-lg break-words overflow-auto">
        <ReactMarkdown>
          {finding && finding.template
            ? finding.template.description
            : finding.description}
        </ReactMarkdown>
      </div>
    )
  }

  const Solution = () => {
    return (
      <div className="py-4 rounded-lg template-markdown break-words overflow-auto">
        <ReactMarkdown>
          {finding && finding.template
            ? finding.template.solution
            : finding.solution}
        </ReactMarkdown>
      </div>
    )
  }

  const References = () => {
    return (
      <div className="py-4 rounded-lg template-markdown overflow-auto">
        {finding.templateId === 'NETWORK_GENERIC_CVE' ? (
          <ul>
            {Object.keys(finding.cves).map(cve => (
              <li key={uuidv4()}>
                <a
                  href={`https://nvd.nist.gov/vuln/detail/${cve}`}
                  target="_blank"
                  rel="noreferrer">
                  https://nvd.nist.gov/vuln/detail/{cve}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <ReactMarkdown remarkPlugins={[gfm]} components={{a: NewTabLink}}>
            {finding && finding.template
              ? finding.template.references
              : finding.references}
          </ReactMarkdown>
        )}
      </div>
    )
  }

  return (
    <>
      <div className="mb-6">
        <ContentBox
          header={i18next.t('titles.about')}
          content={<Description />}
        />
      </div>

      <div className="mb-6">
        <ContentBox
          header={i18next.t('titles.solution')}
          content={<Solution />}
        />
      </div>

      <div className="mb-6">
        <ContentBox
          header={i18next.t('titles.references')}
          content={<References />}
        />
      </div>
    </>
  )
}
