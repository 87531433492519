import React from 'react'
import PropTypes from 'prop-types'
import Badge from 'components/partials/Badge'
import i18next from 'i18next'

import {gray700, green700, red700, sky900} from 'constant'
import ReviewBoxIcon from 'res/icons/ReviewBoxIcon'

export default function QuestionnaireEvaluationBadge({evaluation}) {
  let style, label, iconColor

  switch (evaluation) {
    case 'meets':
      style = 'success'
      label = i18next.t('questionnaires.review.evaluations.meets')
      iconColor = green700
      break

    case 'partiallyMeets':
      style = 'info'
      label = i18next.t('questionnaires.review.evaluations.partiallyMeets')
      iconColor = sky900
      break

    case 'doesNotMeet':
      style = 'error'
      label = i18next.t('questionnaires.review.evaluations.doesNotMeet')
      iconColor = red700
      break

    case 'noEvaluated':
      style = 'dark'
      label = i18next.t('questionnaires.review.evaluations.noEvaluated')
      iconColor = gray700
  }

  return (
    <Badge
      style={style}
      label={label}
      icon={<ReviewBoxIcon color={iconColor} />}
    />
  )
}

QuestionnaireEvaluationBadge.propTypes = {
  evaluation: PropTypes.string,
}
