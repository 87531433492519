import i18next from 'i18next'
import React from 'react'
import PropTypes from 'prop-types'

export default function Footer(props) {
  const padding = props.padding || 'py-6'

  return (
    <div
      className={`flex items-center text-xs text-gray-600 w-full ${padding}`}>
      <div className="hidden md:flex items-center w-full">
        <ul>
          <li className="inline-block mr-4 text-sky-500 hover:text-sky-600">
            <a href="https://www.unxpose.com" className="underline">
              unxpose.com
            </a>
          </li>
          <li className="inline-block mr-4 text-sky-500 hover:text-sky-600">
            <a
              rel="noreferrer"
              href="https://www.unxpose.com/terms"
              target="_blank"
              className="underline">
              {i18next.t('footer.termsOfService')}
            </a>
          </li>
          <li className="inline-block mr-4 text-sky-500 hover:text-sky-600">
            <a
              rel="noreferrer"
              href="https://www.unxpose.com/privacy"
              target="_blank"
              className="underline">
              {i18next.t('footer.privacyPolicy')}
            </a>
          </li>
        </ul>
        <span className="ml-auto">© {new Date().getFullYear()} Unxpose.</span>
      </div>
      <div className="md:hidden w-full">
        <div className="flex items-center">
          <div className="mr-3">
            <a
              rel="noreferrer"
              href="https://www.unxpose.com/terms"
              target="_blank"
              className="underline text-sky-500 hover:text-sky-600">
              Terms of Service
            </a>
          </div>
          <div className="mr-3">
            <a
              rel="noreferrer"
              href="https://www.unxpose.com/privacy"
              target="_blank"
              className="underline text-sky-500 hover:text-sky-600">
              Privacy Policy
            </a>
          </div>
          <div className="ml-auto">© {new Date().getFullYear()} Unxpose.</div>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  padding: PropTypes.string,
}
