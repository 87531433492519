import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useSelector, useDispatch} from 'react-redux'
import {companyRenewal} from 'actions'
import i18next from 'i18next'

import images from 'res'
import {sky500, sky900} from 'constant'
import PlanUtils from 'utils/plan'
import PaperForm from 'components/partials/PaperForm'
import SectionAdvantages from 'components/partials/SectionAdvantages'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import DownloadIcon from 'res/icons/downloadIcon'
import reportExamplePtBr from 'res/reports/report-example-pt-br.pdf'
import reportExampleEn from 'res/reports/report-example-en.pdf'

export default function ReportsEmptyState({handleGenerateReport, loading}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const userState = useSelector(state => state.user)

  const [showPaperForm, setShowPaperform] = useState(false)

  const planUtils = PlanUtils()
  const labels = planUtils.getPlanModalLabels()
  const isFreePlan = !planUtils.userHasPremiumPlan()

  const onClosePaperFormModal = () => {
    setShowPaperform(false)
  }

  const onClickSubscribe = () => {
    setShowPaperform(true)

    dispatch(
      companyRenewal({
        _csrf: csrfState,
        companyId: userState.company.id,
        element: 'talkToSales',
        source: 'generateReportBtn',
      })
    )
  }

  const onClickDownloadExample = () => {
    const url =
      i18next.language === 'pt-br' ? reportExamplePtBr : reportExampleEn

    window.open(url, '_blank')
  }

  useEffect(() => {
    document.addEventListener('mouseup', e => {
      const modal = document.querySelector('#paperformModal')
      if (e.target === modal) onClosePaperFormModal()
    })
  }, [])

  return (
    <>
      <div className="w-full flex-wrap text-center border border-sideral-100 font-medium rounded-md p-4 mt-8">
        <SectionAdvantages
          headerIcon={
            <images.ReportIcon width="50" height="50" color={sky500} />
          }
          headerTitle={i18next.t('reports.emptyStateTitle')}
          title={i18next.t('sales.reports.title')}
          advantagesItems={[
            i18next.t('sales.reports.listItems.first'),
            i18next.t('sales.reports.listItems.second'),
            i18next.t('sales.reports.listItems.third'),
          ]}
          actionButtons={
            <>
              <PrimaryButton
                text={
                  isFreePlan
                    ? i18next.t('sales.reports.subscribeBtn')
                    : i18next.t('buttons.generateReport')
                }
                onClick={isFreePlan ? onClickSubscribe : handleGenerateReport}
                loading={loading}
              />
              <PrimaryButton
                text={i18next.t('sales.reports.exampleFileBtn')}
                theme="blue-outline"
                icon={<DownloadIcon height="24" width="24" color={sky900} />}
                onClick={onClickDownloadExample}
                margin="ml-2"
              />
            </>
          }
          rightSideImage={
            <img className="w-90 mt-6 m-auto" src={images.executiveReport} />
          }
        />
      </div>

      {showPaperForm && (
        <div
          id="paperformModal"
          className="modal-bg fixed top-0 left-0 w-screen h-screen z-50">
          <div
            className={`fixed bg-white border rounded-md bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 max-w-lg w-full p-8`}>
            <PaperForm
              classes="relative"
              title={labels.paperformTitles}
              onClickBack={() => setShowPaperform(false)}
              useCloseIcon
            />
          </div>
        </div>
      )}
    </>
  )
}

ReportsEmptyState.propTypes = {
  handleGenerateReport: PropTypes.func,
  loading: PropTypes.bool,
}
