import React, {useState} from 'react'
import PropTypes from 'prop-types'
import DroneIllustration from 'res/illustrations/DroneIllustration.png'
import PrimaryButton from './partials/buttons/PrimaryButton'
import {useSelector, useDispatch} from 'react-redux'
import CheckIcon from 'res/icons/checkIcon'
import {useNavigate, Link} from 'react-router-dom'
import {i18next} from 'translate/i18n'
import {linkStyle} from 'constant'
import SecondaryButton from './partials/buttons/SecondaryButton'
import {genericError, submitSurvey} from 'actions'
import PrimaryInput from './partials/inputs/PrimaryInput'
import {Page} from './partials/Page'

const PlanExpired = () => {
  const navigate = useNavigate()

  const [userFeedback, setUserFeedback] = useState('pending')

  return (
    <Page pageTitle={i18next.t('pageTitles.planExpired')}>
      <div className="grid grid-cols-12 bg-white border border-sideral-100 rounded-lg py-32">
        <figure className="hidden lg:flex lg:items-center lg:col-span-3 lg:col-start-2 lg:w-48 lg:mx-auto">
          <img src={DroneIllustration} />
        </figure>

        <div className="flex flex-wrap col-span-10 col-start-2 lg:col-span-7 lg:col-start-5 text-sideral-700">
          {/* initial screen */}
          {userFeedback === 'pending' && (
            <Pending setUserFeedback={setUserFeedback} />
          )}
          {/* user wants us to contact them! */}
          {userFeedback === 'isInterested' && (
            <IsInterested navigate={navigate} />
          )}
          {/* user is not interested, let's figure out why  */}
          {userFeedback === 'notInterested' && (
            <NotInterested setUserFeedback={setUserFeedback} />
          )}
          {/* user answered our survey  */}
          {userFeedback === 'surveySubmited' && (
            <SurveySubmited
              setUserFeedback={setUserFeedback}
              navigate={navigate}
            />
          )}
        </div>
      </div>
    </Page>
  )
}

const Pending = ({setUserFeedback}) => {
  const userState = useSelector(state => state.user)

  const name = userState.firstName || userState.company.name

  return (
    <>
      <h1 className="dm-mono w-full text-lg">
        {name}, {i18next.t('warnings.planExpiredHeader')}
      </h1>
      <p className="font-light mt-6">
        {i18next.t('warnings.planExpiredBody1')}
      </p>
      <div className="font-light mt-6">
        <p>{i18next.t('warnings.planExpiredListTitle')}</p>
        <ul className="font-normal">
          <li className="flex items-center pt-1">
            <figure className="pr-1">
              <CheckIcon width="16" />
            </figure>
            {i18next.t('warnings.planExpiredListItem1')}
          </li>
          <li className="flex items-center pt-1">
            <figure className="pr-1">
              <CheckIcon width="16" />
            </figure>
            {i18next.t('warnings.planExpiredListItem2')}
          </li>
          <li className="flex items-center pt-1">
            <figure className="pr-1">
              <CheckIcon width="16" />
            </figure>
            {i18next.t('warnings.planExpiredListItem3')}
          </li>
        </ul>
      </div>
      <ul></ul>
      <p className="font-light  mt-6">
        {i18next.t('warnings.planExpiredBody2')}
      </p>

      <div className="flex w-full mt-6">
        <PrimaryButton
          onClick={() => {
            setUserFeedback('notInterested')
          }}
          theme="blue-outline"
          text={i18next.t('buttons.notInterested')}
        />
        <PrimaryButton
          onClick={() => setUserFeedback('isInterested')}
          value="isInterested"
          text={i18next.t('buttons.letsTalk')}
          margin="ml-4"
        />
      </div>
    </>
  )
}

const IsInterested = ({navigate}) => {
  return (
    <div>
      <h1 className="dm-mono w-full text-lg">{i18next.t('misc.thanks')}!</h1>
      <p className="font-light w-full my-6">
        {i18next.t('warnings.planExpiredSuccess')}.
      </p>
      <p className="font-light w-full my-6">
        {i18next.t('warnings.followUs1')}{' '}
        <Link
          to={{pathname: 'https://www.linkedin.com/company/unxpose'}}
          target="_blank"
          className={linkStyle}>
          LinkedIn
        </Link>{' '}
        {i18next.t('warnings.followUs2')}{' '}
        <Link
          to={{pathname: 'https://www.unxpose.com/blog'}}
          target="_blank"
          className={linkStyle}>
          blog
        </Link>
        . {i18next.t('warnings.followUs3')} 😉
      </p>
      <PrimaryButton
        onClick={() => navigate('/logout')}
        theme="blue-outline"
        text={i18next.t('dropdownMenu.logout')}
      />
    </div>
  )
}

const NotInterested = ({setUserFeedback}) => {
  const dispatch = useDispatch()

  const grades = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const [checkboxCounter, setCheckboxCounter] = useState(0)

  const [form, setForm] = useState({
    budget: false,
    team: false,
    product: false,
    time: false,
    other: false,
    otherReason: '',
    grade: '',
    justification: '',
  })

  function handleInputChange({target}) {
    const {name, value, checked} = target

    if (target.type === 'checkbox') {
      checked
        ? setCheckboxCounter(checkboxCounter + 1)
        : setCheckboxCounter(checkboxCounter - 1)
      setForm({...form, [name]: checked})
      return
    }
    setForm({...form, [name]: value})
  }

  function handleSubmit(event) {
    event.preventDefault()

    if (!checkboxCounter) {
      dispatch(genericError({message: i18next.t('warnings.selectOneOption')}))
      return
    }

    dispatch(submitSurvey(form))
    setUserFeedback('surveySubmited')
  }

  return (
    <>
      <SecondaryButton
        text={i18next.t('buttons.back')}
        direction="left"
        size="sm"
        onClick={() => {
          setUserFeedback('pending')
        }}
        margin="mb-4"
      />
      <h1 className="dm-mono w-full text-lg">{i18next.t('nps.title')}</h1>
      <p className="font-light mt-6">{i18next.t('nps.subtitle')}</p>
      <form onSubmit={handleSubmit}>
        <div className="font-light mt-6">
          <p>{i18next.t('nps.questionOne')}*</p>
          <ul>
            <li className="flex items-center pt-2">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="budget"
                  checked={form.budget}
                  onChange={handleInputChange}
                  className="h-4 w-4"
                />
                <span className="ml-2 text-sm leading-none">
                  {i18next.t('nps.valueIsTooHigh')}
                </span>
              </label>
            </li>
            <li className="flex items-center pt-2">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="time"
                  checked={form.time}
                  className="h-4 w-4"
                  onChange={handleInputChange}
                />
                <span className="ml-2 text-sm leading-none">
                  {i18next.t('nps.couldntTestTheProduct')}
                </span>
              </label>
            </li>
            <li className="flex items-center pt-2">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="product"
                  checked={form.product}
                  className="h-4 w-4"
                  onChange={handleInputChange}
                />
                <span className="ml-2 text-sm leading-none">
                  {i18next.t('nps.productDontMeetMyNeeds')}
                </span>
              </label>
            </li>
            <li className="flex items-center pt-2">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="team"
                  checked={form.team}
                  className="h-4 w-4"
                  onChange={handleInputChange}
                />
                <span className="ml-2 text-sm leading-none">
                  {i18next.t('nps.dontHaveASecurityTeam')}
                </span>
              </label>
            </li>
            <li className="flex flex-wrap items-center pt-2">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="other"
                  checked={form.other}
                  className="h-4 w-4"
                  onChange={handleInputChange}
                />
                <span className="ml-2 text-sm leading-none">
                  {i18next.t('nps.other')}
                </span>
              </label>
            </li>
            {form.other && (
              <PrimaryInput
                type="text"
                name="otherReason"
                text={i18next.t('nps.whatWasMissing')}
                value={form.otherReason}
                onChange={handleInputChange}
                margin="mt-2"
              />
            )}
          </ul>
        </div>
        <ul></ul>
        <div className="flex flex-wrap justify-start mt-6">
          <p className="font-light w-full">{i18next.t('nps.questionTwo')}</p>
          {grades.map((grade, index) => {
            return (
              <div key={index + 'grade'} className="relative w-8 h-8 mr-2 mt-2">
                <input
                  name="grade"
                  value={grade}
                  id={index + 'grade'}
                  type="radio"
                  onChange={handleInputChange}
                  className={`appearance-none radio-custom cursor-pointer w-8 h-8 rounded-md border border-sideral-700 checked:border-sky-500 checked:bg-sky-500 hover:border-sky-500 hover:border-2`}
                />
                <label
                  htmlFor={index + 'grade'}
                  className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer">
                  {grade}
                </label>
              </div>
            )
          })}
        </div>

        <p className="font-light mt-6 mb-2">{i18next.t('nps.questionThree')}</p>
        <textarea
          rows={5}
          name="justification"
          className="w-full resize-none border border-sideral-300 rounded-md p-2"
          onChange={handleInputChange}></textarea>

        <PrimaryButton
          type="submit"
          text={i18next.t('nps.submit')}
          margin="mt-6"
        />
      </form>
    </>
  )
}

const SurveySubmited = ({navigate}) => {
  return (
    <>
      <h1 className="dm-mono w-full text-lg">{i18next.t('misc.thanks')}!</h1>
      <p className="font-light my-6 w-full ">{i18next.t('nps.surveySent')}</p>
      <PrimaryButton
        text={i18next.t('dropdownMenu.logout')}
        theme="blue-outline"
        onClick={() => navigate('/logout')}
      />
    </>
  )
}

Pending.propTypes = {
  setUserFeedback: PropTypes.func,
}

IsInterested.propTypes = {
  navigate: PropTypes.func,
}

NotInterested.propTypes = {
  setUserFeedback: PropTypes.func,
}

SurveySubmited.propTypes = {
  navigate: PropTypes.func,
}

export default PlanExpired
