import i18next from 'i18next'
import React from 'react'

import Footer from './Footer'

export default function CvssCwe() {
  return (
    <>
      <div className="bg-white text-sm w-full py-4 px-8">
        <div className="flex flex-wrap">
          <h1 className="font-medium text-lg my-2">CVSS</h1>
          <p className="mt-4">
            {i18next.t('help.cvssCwe.cvss.firstParagraph')}
          </p>
          <p className="mt-4 font-medium">
            {i18next.t('help.cvssCwe.cvss.benefitsList.title')}
          </p>
          <ul className="ml-4 mt-4">
            <li>{i18next.t('help.cvssCwe.cvss.benefitsList.firstItem')}</li>
            <li className="mt-1">
              {i18next.t('help.cvssCwe.cvss.benefitsList.secondItem')}
            </li>
            <li className="mt-1">
              {i18next.t('help.cvssCwe.cvss.benefitsList.thirdItem')}
            </li>
          </ul>
          <p className="text-xs mt-2">
            {i18next.t('help.cvssCwe.cvss.benefitsList.source')}{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.first.org/cvss/v3.0/user-guide">
              first.org
            </a>
          </p>
        </div>

        <table className="w-full mt-4 leading-8 text-center border border-onyx mx-auto">
          <thead>
            <tr className="bg-onyx text-white">
              <th>{i18next.t('help.cvssCwe.cvss.ratingTable.rating')}</th>
              <th>{i18next.t('help.cvssCwe.cvss.ratingTable.cvssScore')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{i18next.t('help.cvssCwe.cvss.ratingTable.none')}</td>
              <td>0.0</td>
            </tr>
            <tr className="bg-gray-100">
              <td>{i18next.t('help.cvssCwe.cvss.ratingTable.low')}</td>
              <td>0.1 - 3.9</td>
            </tr>
            <tr>
              <td>{i18next.t('help.cvssCwe.cvss.ratingTable.medium')}</td>
              <td>4.0 - 6.9</td>
            </tr>
            <tr className="bg-gray-100">
              <td>{i18next.t('help.cvssCwe.cvss.ratingTable.high')}</td>
              <td>7.0 - 8.9</td>
            </tr>
            <tr>
              <td>{i18next.t('help.cvssCwe.cvss.ratingTable.critical')}</td>
              <td>9.0 - 10.0</td>
            </tr>
          </tbody>
        </table>
        <h1 className="font-medium text-lg mt-6 mb-2">CWE</h1>
        <div className="mt-4">
          {i18next.t('help.cvssCwe.cwe.firstParagraph')}
          <p className="mt-4">
            {i18next.t('help.cvssCwe.cwe.secondParagraph')}
          </p>
          <p className="mt-4 font-medium">
            {i18next.t('help.cvssCwe.cwe.benefitsList.title')}
          </p>
          <ul className="ml-4 mt-4">
            <li>{i18next.t('help.cvssCwe.cwe.benefitsList.firstItem')}</li>
            <li className="mt-1">
              {i18next.t('help.cvssCwe.cwe.benefitsList.secondItem')}
            </li>
            <li className="mt-1">
              {i18next.t('help.cvssCwe.cwe.benefitsList.thirdItem')}
            </li>
            <li className="mt-1">
              {i18next.t('help.cvssCwe.cwe.benefitsList.fourthItem')}
            </li>
            <li className="mt-1">
              {i18next.t('help.cvssCwe.cwe.benefitsList.fifthItem')}
            </li>
          </ul>
        </div>
        <p className="text-xs mt-2">
          {i18next.t('help.cvssCwe.cwe.benefitsList.source')}{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://cwe.mitre.org/about/">
            cwe.mitre.org
          </a>
        </p>
      </div>
      <Footer />
    </>
  )
}
