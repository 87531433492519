import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import {useSelector} from 'react-redux'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import {i18next} from 'translate/i18n'

import {getUrlFromTargetV2} from 'utils'
import {tooltipStyle} from 'constant'
import config from 'config'
import images from 'res'

export default function EntryPointsTable(props) {
  const filterState = useSelector(state => state.filter)
  const webAppState = useSelector(state => state.webApp)

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    //headerGroups,
    prepareRow,
    page,
    setGlobalFilter,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        pageIndex: props.pageNumber,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(() => props.data)
  }, [props.data])

  useEffect(() => {
    if (filterState) {
      setGlobalFilter(filterState)
    }
  }, [filterState, items, setGlobalFilter])

  return (
    <div>
      <div className="tableWrap">
        <table {...getTableProps()} className="w-full">
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  className={
                    'text-sm text-astral-900 border-t border-sideral-50 hover:bg-sideral-50 text-center break-words first:border-0 py-2px'
                  }
                  key={uuidv4()}>
                  {row.cells.map(cell => {
                    if (cell.column.id === 'original')
                      return (
                        <td
                          className={
                            cell.column.classes
                              ? cell.column.classes
                              : 'pl-2 pb-2 text-left break-all'
                          }
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          <div className="mb-2">
                            {cell.row.original.status === 'blocked' ? (
                              <div className="text-red-600 text-center">
                                {i18next.t('web.wafBlocked') + ' '}
                                {config.ipAddresses.join(', ')}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="inline-flex items-baseline">
                            {cell.row.original.status === 'idle' ? (
                              <div className="tooltip cursor-pointer self-center pr-1">
                                <images.AttentionSignal width="16" />
                                <span
                                  className={`${tooltipStyle.default} transform -translate-y-full -mt-4`}>
                                  {i18next.t('web.errors.urlNotResponding')}
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                            {
                              // the initial URL from web the web_service
                              getUrlFromTargetV2(cell.row.original.original)
                            }
                          </div>
                          {cell.row.original.data &&
                            cell.row.original.data.redirects &&
                            cell.row.original.data.redirects.map(
                              (redirect, index) => {
                                if (index === 0)
                                  return <span key={uuidv4()}></span>

                                // arrows and following redirects
                                return (
                                  <span key={uuidv4()}>
                                    <span className="font-bold text-base mx-1 text-sky-800">
                                      &#187;
                                    </span>
                                    {index % 3 === 0 ? <br /> : <></>}
                                    {getUrlFromTargetV2(redirect)}
                                  </span>
                                )
                              }
                            )}
                          <>
                            <span className="font-bold text-base mx-1 text-sky-800">
                              &#187;
                            </span>
                            <span className="font-medium">
                              {
                                // the final URL
                                getUrlFromTargetV2(webAppState.final)
                              }
                            </span>
                          </>
                        </td>
                      )

                    return (
                      <td
                        className={
                          cell.column.classes
                            ? cell.column.classes
                            : 'py-2 pr-2 truncate break-word'
                        }
                        {...cell.getCellProps()}
                        key={uuidv4()}>
                        {cell.value ? cell.render('Cell') : '—'}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

EntryPointsTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  defaultSorted: PropTypes.array,
  headerClasses: PropTypes.string,
  history: PropTypes.object,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  paginationClass: PropTypes.string,
  rowClasses: PropTypes.string,
}
