import AwesomeDebouncePromise from 'awesome-debounce-promise'
import Chart from 'react-apexcharts'
import {i18next} from 'translate/i18n'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import useConstant from 'use-constant'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import images from 'res'

import {
  astral900,
  coral400,
  mars300,
  sideral300,
  sky300,
  sky50,
  summer300,
  summer700,
} from 'constant'

import GenericEmptyState from 'components/partials/GenericEmptyState'
import GlobalFilter from 'components/partials/tables/GlobalFilter'
import Pagination from 'components/partials/tables/Pagination'
import ScoreBadgeSquare from 'components/partials/ScoreBadgeSquare'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import Spinner from 'components/partials/Spinner'

export default function DomainsTable(props) {
  const [items, setItems] = useState([])
  const [filterValue, setFilterValue] = useState('')
  const [firstPageLoad, setFirstPageLoad] = useState(true)
  const [loading, setLoading] = useState(true)

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    preGlobalFilteredRows,
    prepareRow,
    previousPage,
    setGlobalFilter,
    state: {pageIndex},
  } = useTable(
    {
      columns: props.columns,
      data: items,
      defaultColumn,

      manualPagination: true,
      manualSortBy: true,

      autoResetPage: false,
      autoResetSortBy: false,

      pageCount: props.pageCount,

      initialState: {
        pageIndex: props.pageNumber || 0,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  const chartData = {
    options: {
      tooltip: {
        marker: {show: false},
        x: {show: false},
        style: {
          colors: sideral300,
          fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
          fontSize: 12,
        },
      },
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      title: {
        text: i18next.t('titles.unresolvedFindingsBySeverity'),
        offsetX: 10,
        style: {
          fontWeight: 'regular',
          color: astral900,
          fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
        },
      },
      yaxis: {
        show: false,
      },
      colors: [coral400, mars300, summer700, summer300, sky300],
      dataLabels: {enabled: false},
      grid: {
        borderColor: sky50,
      },
    },
  }

  const styles = {
    providerContainer: 'border border-sideral-50 rounded-lg mt-4 p-4',
    idContainer: 'flex border-b text-sm pb-4',
    idText: 'font-light ml-1',
    statsContainer: 'flex flex-wrap md:flex-nowrap items-center',
    statsBoxesContainer:
      'flex md:justify-start justify-around w-full md:w-auto mt-4 md:mt-0 ml-auto',
    statsBox:
      'flex flex-wrap  items-center justify-center rounded-lg border p-2 mr-4',
    statsBoxText: 'w-full text-center text-xs font-light',
    chartContainer: 'w-full -ml-4 mr-6 mt-4 h-32',
  }

  function handleEmptyState() {
    return (
      <GenericEmptyState
        icon={<images.NetworkIcon width="55" color={sideral300} />}
        title={i18next.t('tables.nothingFoundWithSearch')}
        body={i18next.t('tables.searchSomethingElse')}
        margin="m-2"
      />
    )
  }

  const refreshTableData = filterValue => {
    props.fetchData({
      pageIndex: pageIndex,
      pageSize: props.pageSize,
      filterValue: filterValue,
    })
  }

  const debouncedRefreshTable = useConstant(() =>
    AwesomeDebouncePromise(refreshTableData, 1000)
  )

  const handlePrevious = () => {
    setLoading(true)
    props.fetchData({
      filterValue,
      pageIndex: pageIndex - 1,
      pageSize: props.pageSize,
    })
    previousPage()
  }

  const handleNext = () => {
    setLoading(true)
    props.fetchData({
      filterValue,
      pageIndex: pageIndex + 1,
      pageSize: props.pageSize,
    })
    nextPage()
  }

  useEffect(() => {
    setLoading(false)
    if (props.data) setItems(() => props.data)
  }, [props.data])

  useEffect(() => {
    if (!firstPageLoad) debouncedRefreshTable(filterValue)
  }, [debouncedRefreshTable, filterValue])

  useEffect(() => {
    setFirstPageLoad(false)
  }, [])

  return (
    <div>
      <div className="tableWrap relative p-2">
        <div className="flex justify-between items-center pb-2">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={filterValue}
            setFilterValue={setFilterValue}
            setGlobalFilter={setGlobalFilter}
            gotoPage={gotoPage}
            setLoading={setLoading}
            totalPages={props.total}
          />
          <Pagination
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            paginationClass={props.paginationClass}
            pageIndex={pageIndex}
            pageCount={pageCount}
            total={props.total}
          />
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <>
            {!page.length ? (
              handleEmptyState()
            ) : (
              <table {...getTableProps()} className="w-full">
                <thead>
                  {headerGroups.map(headerGroup => {
                    // trick to disable the first headerGroup
                    if (headerGroup.headers.length === 1)
                      return <tr key={uuidv4()}></tr>

                    return (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        key={uuidv4()}
                        className="p-2">
                        {headerGroup.headers.map(column => (
                          <th
                            className="dm-mono text-sideral-300 text-sm"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={uuidv4()}>
                            {column.render('Header').toUpperCase()}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ' ↓'
                                  : ' ↑'
                                : ''}
                            </span>
                          </th>
                        ))}
                      </tr>
                    )
                  })}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map(row => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} key={uuidv4()}>
                        {row.cells.map(cell => {
                          return (
                            <td
                              className={cell.column.classes || ''}
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              <div
                                className={styles.providerContainer + ' group'}>
                                <>
                                  <div className={styles.idContainer}>
                                    <p>
                                      {i18next.t('internetExposure.domain')}:
                                    </p>
                                    <span className={styles.idText}>
                                      {cell.row.original.hostname}
                                    </span>

                                    <SecondaryButton
                                      text={i18next.t(
                                        'buttons.viewAllFindings'
                                      )}
                                      redirect={`/management?global=&sort=severity%3Adesc&status=open%2Creopened%2Cfixed%2Cstale&domainId=${cell.row.original.id}`}
                                      direction="right"
                                      size="sm"
                                      margin="ml-auto"
                                    />
                                  </div>
                                  <div className={styles.statsContainer}>
                                    {!cell.row.original.findingsCount ? (
                                      <div className="text-sm mt-4 md:mt-0">
                                        <h2 className=" text-sky-900">
                                          {i18next.t('titles.goodNews')}!
                                        </h2>
                                        <p className="font-light mr-6">
                                          {i18next.t(
                                            'internetExposure.noFindings'
                                          )}
                                        </p>
                                      </div>
                                    ) : (
                                      <div className={styles.chartContainer}>
                                        {cell.row.original.findingsBreakdown ? (
                                          <Chart
                                            options={chartData.options}
                                            series={[
                                              {
                                                name: i18next.t(
                                                  'severity.critical'
                                                ),
                                                data: [
                                                  cell.row.original
                                                    .findingsBreakdown.critical,
                                                ],
                                              },
                                              {
                                                name: i18next.t(
                                                  'severity.high'
                                                ),
                                                data: [
                                                  cell.row.original
                                                    .findingsBreakdown.high,
                                                ],
                                              },
                                              {
                                                name: i18next.t(
                                                  'severity.medium'
                                                ),
                                                data: [
                                                  cell.row.original
                                                    .findingsBreakdown.medium,
                                                ],
                                              },
                                              {
                                                name: i18next.t('severity.low'),
                                                data: [
                                                  cell.row.original
                                                    .findingsBreakdown.low,
                                                ],
                                              },
                                              {
                                                name: i18next.t(
                                                  'severity.info'
                                                ),
                                                data: [
                                                  cell.row.original
                                                    .findingsBreakdown.info,
                                                ],
                                              },
                                            ]}
                                            height="100%"
                                            width="100%"
                                            type="bar"
                                          />
                                        ) : (
                                          <Spinner />
                                        )}
                                      </div>
                                    )}

                                    <div
                                      className={`${
                                        styles.statsBoxesContainer
                                      } ${
                                        !cell.row.original.findingsCount
                                          ? 'md:pt-4 pt-0'
                                          : ''
                                      }`}>
                                      <div className={styles.statsBox}>
                                        <span className="text-lg">
                                          {cell.row.original.findingsCount}
                                        </span>
                                        <p className={styles.statsBoxText}>
                                          {i18next.t('misc.findings')}
                                        </p>
                                      </div>
                                      <div>
                                        <ScoreBadgeSquare
                                          letter={
                                            cell.row.original.score?.grade
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              </div>
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    </div>
  )
}

DomainsTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  defaultSorted: PropTypes.array,
  fetchData: PropTypes.func,
  headerClasses: PropTypes.string,
  history: PropTypes.object,
  pageCount: PropTypes.number,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  paginationClass: PropTypes.string,
  rowClasses: PropTypes.string,
  total: PropTypes.number,
}
