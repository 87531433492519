import React from 'react'
import PropTypes from 'prop-types'

export default function MagicWandIcon({width, height}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.404 3.82499L13.3214 0.229614L9.72604 2.14706L6.13066 0.229614L8.04811 3.82499L6.13066 7.42036L9.72604 5.50291L13.3214 7.42036L11.404 3.82499ZM18.0886 8.27393C18.7407 7.62181 19.798 7.62181 20.4502 8.27393L23.7096 11.5333C24.3617 12.1855 24.3617 13.2428 23.7096 13.8949L6.13569 31.4688C5.48357 32.1209 4.42627 32.1209 3.77414 31.4688L0.514727 28.2094C-0.137397 27.5572 -0.137396 26.4999 0.514728 25.8478L18.0886 8.27393ZM19.0912 9.66547L22.3753 12.9496L18.6743 16.6507L15.3901 13.3665L19.0912 9.66547ZM32.0256 0.509896L30.1082 4.10527L32.0256 7.70064L28.4302 5.7832L24.8349 7.70064L26.7523 4.10527L24.8349 0.509896L28.4302 2.42734L32.0256 0.509896ZM30.1082 22.2491L32.0256 18.6537L28.4302 20.5711L24.8349 18.6537L26.7523 22.2491L24.8349 25.8444L28.4302 23.927L32.0256 25.8444L30.1082 22.2491Z"
        fill="#0A50A0"
      />
    </svg>
  )
}

MagicWandIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}
