import i18next from 'i18next'
import React from 'react'
import PropTypes from 'prop-types'
import {useLocation, useNavigate} from 'react-router-dom'

import images from 'res'

import Footer from './Footer'

export default function HelpList(props) {
  const navigate = useNavigate()
  const location = useLocation()

  function startGuide() {
    if (location.pathname !== '/') navigate('/')
    document.documentElement.scrollTop = 0
    document.documentElement.classList.add('overflow-hidden')

    setTimeout(() => props.setShowIntro(true), 500)
  }

  return (
    <div className="flex flex-col flex-wrap justify-between h-full">
      <div>
        <div
          onClick={startGuide}
          tabIndex="0"
          className="flex w-5/6 border hover:shadow hover:border-gray-300 items-start mx-auto mt-4 py-3 rounded-md cursor-pointer">
          <i className="icofont-direction-sign self-center bg-pink-200 rounded-md p-2 mx-4 text-5xl"></i>
          <div>
            <p className="text-gray-700 font-bold">
              {i18next.t('help.intro.menu.title')}
            </p>
            <p className="text-xs text-gray-600 pr-2">
              {i18next.t('help.intro.menu.subtitle')}
            </p>
          </div>
        </div>

        <div
          onClick={() => props.handleClickTab('score')}
          className="flex w-5/6 border hover:shadow hover:border-gray-300 items-start mx-auto mt-4 py-3 rounded-md cursor-pointer">
          <i className="icofont-tasks self-center bg-blue-200 rounded-md p-2 mx-4 text-5xl"></i>
          <div>
            <p className="text-gray-700 font-bold">
              {i18next.t('help.unxposeScoring.menu.title')}
            </p>
            <p className="text-xs text-gray-600 pr-2">
              {i18next.t('help.unxposeScoring.menu.subtitle')}
            </p>
          </div>
        </div>

        <div
          onClick={() => props.handleClickTab('network')}
          className="flex w-5/6 border hover:shadow hover:border-gray-300 items-start mx-auto mt-4 py-3 rounded-md cursor-pointer">
          <i className="icofont-network self-center bg-blue-200 rounded-md p-2 mx-4 text-5xl"></i>
          <div>
            <p className="text-gray-700 font-bold">
              {i18next.t('help.network.menu.title')}
            </p>
            <p className="text-xs text-gray-600 pr-2">
              {i18next.t('help.network.menu.subtitle')}
            </p>
          </div>
        </div>

        <div
          onClick={() => props.handleClickTab('people')}
          className="flex w-5/6 border hover:shadow hover:border-gray-300 items-start mx-auto mt-4 py-3 rounded-md cursor-pointer">
          <i className="icofont-people self-center bg-blue-200 rounded-md p-2 mx-4 text-5xl"></i>
          <div>
            <p className="text-gray-700 font-bold">
              {i18next.t('help.people.menu.title')}
            </p>
            <p className="text-xs text-gray-600 pr-2">
              {i18next.t('help.people.menu.subtitle')}
            </p>
          </div>
        </div>

        <div
          onClick={() => props.handleClickTab('questionnaire')}
          className="flex w-5/6 border hover:shadow hover:border-gray-300 items-start mx-auto mt-4 py-3 rounded-md cursor-pointer">
          <i className="icofont-list self-center bg-blue-200 rounded-md p-2 mx-4 text-5xl"></i>
          <div>
            <p className="text-gray-700 font-bold">
              {i18next.t('help.questionnaire.menu.title')}
            </p>
            <p className="text-xs text-gray-600 pr-2">
              {i18next.t('help.questionnaire.menu.subtitle')}
            </p>
          </div>
        </div>

        <div
          onClick={() => props.handleClickTab('executiveReport')}
          className="flex w-5/6 border hover:shadow hover:border-gray-300 ease-in items-start mx-auto mt-4 py-3 rounded-md cursor-pointer">
          <i className="icofont-papers self-center bg-blue-200 rounded-md min-h-full p-2 mx-4 text-5xl"></i>
          <div>
            <p className="text-gray-700 font-bold">
              {i18next.t('help.executiveReport.menu.title')}
            </p>
            <p className="text-xs text-gray-600 pr-2">
              {i18next.t('help.executiveReport.menu.subtitle')}
            </p>
          </div>
        </div>

        <div
          onClick={() => props.handleClickTab('webhooks')}
          className="flex w-5/6 border hover:shadow hover:border-gray-300 ease-in items-start mx-auto mt-4 py-3 rounded-md cursor-pointer">
          <i className="icofont-connection self-center bg-blue-200 rounded-md min-h-full p-2 mx-4 text-5xl"></i>
          <div>
            <p className="text-gray-700 font-bold">
              {i18next.t('help.webhooks.menu.title')}
            </p>
            <p className="text-xs text-gray-600 pr-2">
              {i18next.t('help.webhooks.menu.subtitle')}
            </p>
          </div>
        </div>

        <div
          onClick={() => props.handleClickTab('jira')}
          className="flex w-5/6 border hover:shadow hover:border-gray-300 ease-in items-start mx-auto mt-4 py-3 rounded-md cursor-pointer">
          <img
            className="bg-blue-200 rounded-md min-h-full mx-4 h-16 w-16 p-2"
            src={images.JiraSoftwareLogo}
          />
          <div>
            <p className="text-gray-700 font-bold">
              {i18next.t('help.jira.menu.title')}
            </p>
            <p className="text-xs text-gray-600 pr-2">
              {i18next.t('help.jira.menu.subtitle')}
            </p>
          </div>
        </div>

        <div
          onClick={() => props.handleClickTab('cvss-cwe')}
          className="flex w-5/6 box-border border hover:shadow hover:border-gray-300 ease-in items-start mx-auto mt-4 py-3 rounded-md cursor-pointer">
          <i className="icofont-data self-center bg-blue-200 rounded-md min-h-full p-2 mx-4 text-5xl"></i>
          <div>
            <p className="text-gray-700 font-bold">
              {i18next.t('help.cvssCwe.menu.title')}
            </p>
            <p className="text-xs text-gray-600 pr-2">
              {i18next.t('help.cvssCwe.menu.subtitle')}
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

HelpList.propTypes = {
  history: PropTypes.object,
  handleClickTab: PropTypes.func,
  setShowIntro: PropTypes.func,
}
