import {i18next} from 'translate/i18n'
import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'

import {check2fa, loading, genericError} from 'actions'

import Logo from 'res/brand/logo.svg'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import VerificationCodeInput from '../partials/inputs/VerificationCodeInput'
import {isLoading} from 'utils'

const MFA = () => {
  const initialFormState = {
    code: '',
  }

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [user, setUser] = useState(initialFormState)

  const handleSubmit = event => {
    event.preventDefault()
    dispatch(loading({login: true}))
    dispatch(check2fa({code: user.code, _csrf: csrfState}))
  }

  useEffect(() => {
    if (location.search.indexOf('error') !== -1)
      dispatch(genericError({message: i18next.t('errors.somethingWentWrong')}))
  }, [dispatch, location.search])

  return (
    <div className="fixed right-0 flex flex-col items-center bg-white lg:max-w-xl w-full h-screen ml-auto pt-12 z-50">
      <div className="font-light mb-10 text-center uppercase text-4xl text-gray-600">
        <img
          src={Logo}
          alt="Unxpose"
          className="mx-auto cursor-pointer w-56 mb-24"
        />
      </div>
      <form
        onSubmit={handleSubmit}
        className="relative w-full text-sideral-700 px-6 md:px-24">
        <VerificationCodeInput
          length={6}
          label={i18next.t('labels.authenticationCode')}
          loading={isLoading(loadingState.login, false)}
          onComplete={code => {
            setUser({...user, code})
          }}
        />
        <div className="flex justify-between mt-10">
          <SecondaryButton
            text={i18next.t('buttons.backLogin').toUpperCase()}
            direction="left"
            size="sm"
            onClick={() => navigate('/login')}
          />
          <PrimaryButton
            text={i18next.t('buttons.login').toUpperCase()}
            type="submit"
            loading={isLoading(loadingState.login, false)}
            size="md"
          />
        </div>
      </form>
    </div>
  )
}

MFA.propTypes = {
  location: PropTypes.object,
  search: PropTypes.string,
  history: PropTypes.object,
}

export default MFA
