import i18next from 'i18next'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import images from 'res'
import {sideral300, supportedCloudProviders} from 'constant'
import {hasFeature, isLoading} from 'utils'
import {
  fetchLgpdIntegrations,
  loading,
  redirect404,
  startLgpdMonitoring,
} from 'actions'

import Spinner from 'components/partials/Spinner'
import TableWrapper from 'components/partials/tables/TableWrapper'
import IntegrationsTable from './IntegrationsTable'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {Page} from 'components/partials/Page'
import BackButton from 'components/partials/buttons/BackButton'

export default function Integration() {
  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)

  const lgpdIntegrationsState = useSelector(state => state.lgpdIntegrations)

  const dispatch = useDispatch()

  const [integrations, setIntegrations] = useState([])
  const [checkedIntegrations, setCheckedIntegrations] = useState([])
  const [allIntegrationsAreChecked, setAllIntegrationsAreChecked] =
    useState(null)
  const [allCloudIntegrations, setAllCloudIntegrations] = useState([])

  const integrationsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: (
              <input
                type="checkbox"
                checked={allIntegrationsAreChecked}
                onChange={() => {
                  allIntegrationsAreChecked
                    ? setCheckedIntegrations([])
                    : setCheckedIntegrations(allCloudIntegrations)
                }}
              />
            ),
            width: 20,
            accessor: 'checkbox',
            disableSortBy: true,
            disableGlobalFilter: true,
          },
          {
            Header: i18next.t('compliance.lgpd.tables.provider'),
            accessor: 'providerFullName',
          },
          {
            Header: i18next.t('compliance.lgpd.tables.name'),
            accessor: 'name',
          },
        ],
      },
    ],
    [allIntegrationsAreChecked]
  )

  const hasActiveLgpdIntegration = integrations => {
    if (!integrations) return false

    return integrations.some(integration => integration.monitoring)
  }

  const handleStartMonitoring = () => {
    dispatch(loading({lgpdIntegration: true}))

    dispatch(
      startLgpdMonitoring({
        _csrf: csrfState,
        integrations: checkedIntegrations,
      })
    )
  }

  const handleCheckboxClick = (e, cell) => {
    const newArr = [...checkedIntegrations]
    const index = newArr.map(i => i.integrationId).indexOf(cell.integrationId)

    e.target.checked ? newArr.push(cell) : newArr.splice(index, 1)

    setCheckedIntegrations(newArr)
  }

  useEffect(() => {
    if (!lgpdIntegrationsState || !lgpdIntegrationsState.length) return

    setAllCloudIntegrations(lgpdIntegrationsState)
  }, [lgpdIntegrationsState])

  useEffect(() => {
    if (!allCloudIntegrations.length) return

    checkedIntegrations.sort().join(',') ===
    allCloudIntegrations.sort().join(',')
      ? setAllIntegrationsAreChecked(true)
      : setAllIntegrationsAreChecked(false)
  }, [checkedIntegrations, allCloudIntegrations])

  useEffect(() => {
    if (userState && !hasFeature(userState, 'compliance'))
      dispatch(redirect404())
  }, [userState])

  useEffect(() => {
    dispatch(fetchLgpdIntegrations())
  }, [])

  useEffect(() => {
    if (!lgpdIntegrationsState) return

    const arr = []
    lgpdIntegrationsState.forEach(i => {
      if (i.monitoring) arr.push(i)
    })

    if (arr.length) setCheckedIntegrations(arr)
  }, [lgpdIntegrationsState])

  useEffect(() => {
    if (!lgpdIntegrationsState || !lgpdIntegrationsState.length) return

    setIntegrations(
      lgpdIntegrationsState.map(i => {
        i.providerFullName = supportedCloudProviders[i.provider]
        return i
      })
    )
  }, [lgpdIntegrationsState])

  if (!lgpdIntegrationsState) return <Spinner />

  return (
    <Page pageTitle={i18next.t('pageTitles.compliance')}>
      <BackButton fallbackLink={'/compliance/lgpd'} />

      <div className="bg-white border border-sideral-50 p-6 mt-4">
        <TableWrapper
          title={
            !lgpdIntegrationsState.length
              ? i18next.t('compliance.lgpd.integrationsUnderMonitoring')
              : i18next.t('compliance.lgpd.selectTheIntegrations')
          }
          loaded={lgpdIntegrationsState}
          margin="my-8"
          table={
            <IntegrationsTable
              handleCheckboxClick={handleCheckboxClick}
              checkedIntegrations={checkedIntegrations}
              data={integrations}
              columns={integrationsColumns}
              pageNumber={0}
              pageSize={20}
              pageIndex={0}
              emptyStateWithFilter={
                <GenericEmptyState
                  icon={<images.LgpdIcon width="50" color={sideral300} />}
                  title={i18next.t('tables.nothingFoundWithSearch')}
                  body={i18next.t('tables.searchSomethingElse')}
                  margin={'m-2 mt-4'}
                />
              }
              emptyStateWithoutFilter={
                <GenericEmptyState
                  icon={<images.LgpdIcon width="50" color={sideral300} />}
                  title="Nenhuma integração de Cloud ativa"
                  margin={'m-2 mt-4'}
                />
              }
            />
          }
        />
        <PrimaryButton
          text={
            hasActiveLgpdIntegration(lgpdIntegrationsState)
              ? i18next.t('compliance.lgpd.buttons.saveChanges')
              : i18next.t('compliance.lgpd.buttons.startMonitoring')
          }
          onClick={handleStartMonitoring}
          loading={isLoading(loadingState.lgpdIntegration, false)}
          margin="ml-auto"
        />
      </div>
    </Page>
  )
}
