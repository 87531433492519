import i18next from 'i18next'
import config from 'config'
import {handler} from './handler'

import {
  fetchAwsFindingsDone,
  fetchAwsIntegrationsDone,
  fetchAwsMostCriticalDone,
  fetchAwsStatsDone,
  fetchAwsUsersDone,
  fetchAwsIamPoliciesDone,
  fetchEbsVolumesDone,
  fetchEc2InstancesDone,
  fetchEc2SecurityGroupsDone,
  fetchEc2VpcsDone,
  fetchEfsDone,
  fetchRdsInstancesDone,
  fetchRdsSnapshotsDone,
  fetchS3BucketsDone,
  genericError,
  stopAwsIntegrationDone,
  retryAwsIntegrationDone,
  success,
  fetchAwsFindingActivity,
  fetchAwsFindingActivityDone,
  fetchAwsFindingDone,
  updateAwsFindingDone,
  fetchAwsFindingsByAssetDone,
  fetchRoute53DomainsDone,
  fetchRoute53HostedZonesDone,
  fetchAwsFinding,
  fetchCloudtrailTrailsDone,
  fetchEc2AmisDone,
  fetchKmsKeysDone,
  redirectFeedback,
  fetchCloudIntegrations,
  toggleRestrictionModal,
  loading,
} from 'actions'

export function* handleFetchAwsIamPolicies(action) {
  yield handler({
    handler: fetchAwsIamPolicies,
    error: genericError,
    success: fetchAwsIamPoliciesDone,
    action,
  })
}

const fetchAwsIamPolicies = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/iam/policies`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchCloudtrailTrails(action) {
  yield handler({
    handler: fetchCloudtrailTrails,
    error: genericError,
    success: fetchCloudtrailTrailsDone,
    action,
  })
}

const fetchCloudtrailTrails = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/cloudtrail/trails`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchRdsInstances(action) {
  yield handler({
    handler: fetchRdsInstances,
    error: genericError,
    success: fetchRdsInstancesDone,
    action,
  })
}

const fetchRdsInstances = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/rds/instances`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchRdsSnapshots(action) {
  yield handler({
    handler: fetchRdsSnapshots,
    error: genericError,
    success: fetchRdsSnapshotsDone,
    action,
  })
}

const fetchRdsSnapshots = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/rds/snapshots`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchKmsKeys(action) {
  yield handler({
    handler: fetchKmsKeys,
    error: genericError,
    success: fetchKmsKeysDone,
    action,
  })
}

const fetchKmsKeys = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/kms/keys`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchEc2Amis(action) {
  yield handler({
    handler: fetchEc2Amis,
    error: genericError,
    success: fetchEc2AmisDone,
    action,
  })
}

const fetchEc2Amis = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/ec2/amis`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchEc2Instances(action) {
  yield handler({
    handler: fetchEc2Instances,
    error: genericError,
    success: fetchEc2InstancesDone,
    action,
  })
}

const fetchEc2Instances = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/ec2/instances`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchEc2SecurityGroups(action) {
  yield handler({
    handler: fetchEc2SecurityGroups,
    error: genericError,
    success: fetchEc2SecurityGroupsDone,
    action,
  })
}

const fetchEc2SecurityGroups = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/ec2/securityGroups`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchEc2Vpcs(action) {
  yield handler({
    handler: fetchEc2Vpcs,
    error: genericError,
    success: fetchEc2VpcsDone,
    action,
  })
}

const fetchEc2Vpcs = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/ec2/vpcs`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchEbsVolumes(action) {
  yield handler({
    handler: fetchEbsVolumes,
    error: genericError,
    success: fetchEbsVolumesDone,
    action,
  })
}

const fetchEbsVolumes = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/ebs/volumes`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchEfs(action) {
  yield handler({
    handler: fetchEfs,
    error: genericError,
    success: fetchEfsDone,
    action,
  })
}

const fetchEfs = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/efs/fileSystems`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchS3Buckets(action) {
  yield handler({
    handler: fetchS3Buckets,
    error: genericError,
    success: fetchS3BucketsDone,
    action,
  })
}

const fetchS3Buckets = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/s3/buckets`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchRoute53Domains(action) {
  yield handler({
    handler: _fetchRoute53Domains,
    error: genericError,
    success: fetchRoute53DomainsDone,
    action,
  })
}

const _fetchRoute53Domains = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/route53/domains`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchRoute53HostedZones(action) {
  yield handler({
    handler: _fetchRoute53HostedZones,
    error: genericError,
    success: fetchRoute53HostedZonesDone,
    action,
  })
}

const _fetchRoute53HostedZones = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/route53/hostedZones`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAwsStats(action) {
  yield handler({
    handler: fetchAwsStats,
    error: genericError,
    success: fetchAwsStatsDone,
    action,
  })
}

const fetchAwsStats = function (accountId) {
  const url = accountId
    ? `${config.SERVER_URL}/aws/${accountId}/stats`
    : `${config.SERVER_URL}/aws/stats`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAwsFindings(action) {
  yield handler({
    handler: _fetchAwsFindings,
    error: genericError,
    success: fetchAwsFindingsDone,
    action,
  })
}

const _fetchAwsFindings = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/findings`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAwsMostCritical(action) {
  yield handler({
    handler: fetchAwsMostCritical,
    error: genericError,
    success: fetchAwsMostCriticalDone,
    action,
  })
}

const fetchAwsMostCritical = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/findings/mostCritical`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAwsUsers(action) {
  yield handler({
    handler: fetchAwsUsers,
    error: genericError,
    success: fetchAwsUsersDone,
    action,
  })
}

const fetchAwsUsers = function (accountId) {
  return fetch(`${config.SERVER_URL}/aws/${accountId}/iam/users`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleIntegrateAws(action) {
  yield handler({
    handler: integrateAws,
    error: [
      () => loading({awsIntegration: false}),
      res => {
        let message
        switch (res.code) {
          case 'INVALID_ACCOUNT_ID':
            message = i18next.t(
              'cloud.aws.integration.stepThree.invalidAccountId'
            )
            break
          case 'ID_ALREADY_IN_USE':
            message = i18next.t(
              'cloud.aws.integration.stepThree.idAlreadyInUse'
            )
            break
          case 'SUBSCRIPTION_LIMIT':
            return toggleRestrictionModal({
              contentId: 'cloudIntegrationTutorialBtn',
              showModal: true,
            })
          default:
            message = i18next.t('errors.somethingWentWrong')
        }
        return genericError({message})
      },
    ],
    success: [
      success,
      () => {
        setTimeout(() => {
          window.location.href = '/cloud'
        }, 2000)
      },
    ],
    action,
  })
}

const integrateAws = function (body) {
  return fetch(config.SERVER_URL + '/aws/integrate', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAwsIntegrations(action) {
  yield handler({
    handler: _fetchAwsIntegrations,
    error: genericError,
    success: fetchAwsIntegrationsDone,
    action,
  })
}

const _fetchAwsIntegrations = function () {
  return fetch(config.SERVER_URL + '/aws/integrations', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleStopAwsIntegration(action) {
  yield handler({
    handler: stopAwsIntegration,
    error: genericError,
    success: [
      () => {
        // Avoid unnecessary redirect flag setting to prevent status message display on a different component.
        if (
          window.location.pathname === '/cloud' ||
          window.location.pathname === '/cloud/'
        )
          return success({message: i18next.t('cloud.aws.integrationRemoved')})

        return redirectFeedback({
          path: '/cloud',
          status: 'success',
          message: i18next.t('cloud.aws.integrationRemoved'),
        })
      },
      stopAwsIntegrationDone,
      fetchCloudIntegrations,
    ],
    action,
  })
}

const stopAwsIntegration = function (body) {
  return fetch(`${config.SERVER_URL}/aws/${body.integrationId}`, {
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

const retryAwsIntegration = function (body) {
  return fetch(
    `${config.SERVER_URL}/aws/integrations/${body.integrationId}/retry`,
    {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleRetryAwsIntegration(action) {
  yield handler({
    handler: retryAwsIntegration,
    error: genericError,
    success: [
      () => {
        // Avoid unnecessary redirect flag setting to prevent status message display on a different component.
        if (
          window.location.pathname === '/cloud' ||
          window.location.pathname === '/cloud/'
        )
          return success({message: i18next.t('cloud.aws.integrationRetried')})

        return redirectFeedback({
          path: '/cloud',
          status: 'success',
          message: i18next.t('cloud.aws.integrationRetried'),
        })
      },
      retryAwsIntegrationDone,
      fetchCloudIntegrations,
    ],
    action,
  })
}

export function* handleFetchAwsFindingActivity(action) {
  yield handler({
    handler: _fetchAwsFindingActivity,
    error: genericError,
    success: fetchAwsFindingActivityDone,
    action,
  })
}

const _fetchAwsFindingActivity = findingId => {
  return fetch(`${config.SERVER_URL}/aws/findings/${findingId}/activity`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
}

export function* handleUpdateAwsFinding(action) {
  const {updateStatusMessage, findingId} = action.payload

  yield handler({
    handler: _updateAwsFinding,
    error: genericError,
    success: [
      updateAwsFindingDone,
      () =>
        success({
          message: updateStatusMessage,
        }),
      () => fetchAwsFinding(findingId),
      () => fetchAwsFindingActivity(findingId),
    ],
    action,
  })
}

const _updateAwsFinding = body => {
  return fetch(`${config.SERVER_URL}/aws/findings/${body.findingId}`, {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'PATCH',
  })
}

export function* handleFetchAwsFinding(action) {
  yield handler({
    action,
    error: genericError,
    handler: _fetchAwsFinding,
    success: [fetchAwsFindingDone],
  })
}

const _fetchAwsFinding = findingId => {
  return fetch(`${config.SERVER_URL}/aws/findings/${findingId}`, {
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'GET',
  })
}

export function* handleFetchAwsFindingsByAsset(action) {
  yield handler({
    handler: _fetchAwsFindingsByAsset,
    success: fetchAwsFindingsByAssetDone,
    action,
  })
}

const _fetchAwsFindingsByAsset = ({integrationId, awsAsset}) => {
  const url = `${config.SERVER_URL}/aws/${integrationId}/${awsAsset}/findings`
  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}
