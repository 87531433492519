import config from '../config'
import {handler} from './handler'
import {fetchHostDone, fetchHostFindingsDone, genericError} from 'actions'

export function* handleFetchHost(action) {
  yield handler({
    action,
    error: genericError,
    handler: _fetchHost,
    success: fetchHostDone,
  })
}

const _fetchHost = hostId => {
  return fetch(`${config.SERVER_URL}/network/hosts/${hostId}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
}

export function* handleFetchHostFindings(action) {
  yield handler({
    action,
    error: genericError,
    handler: _fetchHostFindings,
    success: fetchHostFindingsDone,
  })
}

const _fetchHostFindings = hostId => {
  return fetch(`${config.SERVER_URL}/network/hosts/${hostId}/findings`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
}
