import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import {DefaultModal} from 'components/partials/DefaultModal'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import {useDispatch, useSelector} from 'react-redux'
import {loading, updateSupplier} from 'actions'
import {isLoading} from 'utils'

export default function SupplierEditModal({
  supplierDetails,
  setEditModal,
  loadingContent,
}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)

  const initialFormState = {
    id: '',
    name: '',
  }

  const [form, setForm] = useState(initialFormState)

  useEffect(() => {
    if (supplierDetails) {
      const {id, name} = supplierDetails

      setForm({id, name})
    }
  }, [supplierDetails])

  const handleInputChange = e => {
    setForm({...form, name: e.target.value})
  }

  const handleSubmit = e => {
    e.preventDefault()

    dispatch(loading({updateSupplier: true}))
    dispatch(
      updateSupplier({
        supplierId: form.id,
        name: form.name,
        _csrf: csrfState,
      })
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <DefaultModal
        id="editSupplierModal"
        title={i18next.t('suppliers.registeredSuppliers.editModal.title')}
        body={
          <>
            <div className="text-sm text-gray-700 leading-6 mb-8">
              <PrimaryInput
                text={i18next.t(
                  'suppliers.registeredSuppliers.editModal.companyName'
                )}
                name="name"
                type="text"
                value={form.name}
                onChange={handleInputChange}
                required
              />
            </div>
          </>
        }
        primaryBtnText={i18next.t('buttons.save')}
        secondayBtnText={i18next.t('buttons.cancel')}
        onClickSecondaryBtn={() =>
          setEditModal({
            selected: null,
            showModal: false,
          })
        }
        primaryButtonType="submit"
        primaryButtonLoading={isLoading(loadingState.updateSupplier, false)}
        loadingContent={loadingContent}
      />
    </form>
  )
}

SupplierEditModal.propTypes = {
  supplierDetails: PropTypes.object,
  setEditModal: PropTypes.func,
  loadingContent: PropTypes.bool,
}
