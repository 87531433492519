import React from 'react'
import PropTypes from 'prop-types'
import {DefaultModal} from 'components/partials/DefaultModal'
import i18next from 'i18next'

export default function QuestionSaveAlertModal({
  questionGroup,
  setChangesTracking,
  setOpenQuestionId,
  setShowSaveAlertModal,
}) {
  return (
    <DefaultModal
      id="saveAlertModal"
      title={i18next.t('questionnaires.saveAlert.title')}
      body={
        <div className="text-gray-600">
          {i18next.t('questionnaires.saveAlert.body')}
        </div>
      }
      primaryBtnText={i18next.t('questionnaires.saveAlert.continueBtn')}
      onClickPrimaryBtn={() => {
        setChangesTracking({
          isAnswerModified: false,
          isConsiderationsModified: false,
          isEvidencesModified: false,
        })

        setOpenQuestionId(questionGroup.id)

        setShowSaveAlertModal(false)
      }}
      secondayBtnText={i18next.t('buttons.cancel')}
      onClickSecondaryBtn={() => setShowSaveAlertModal(false)}
      onCloseModal={() => setShowSaveAlertModal(false)}
    />
  )
}

QuestionSaveAlertModal.propTypes = {
  questionGroup: PropTypes.array,
  setChangesTracking: PropTypes.func,
  setOpenQuestionId: PropTypes.func,
  setShowSaveAlertModal: PropTypes.func,
}
