import React, {useEffect} from 'react'
import {Navigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import Spinner from 'components/partials/Spinner'

import {logout, clearError, clearSuccess} from 'actions'

export default function Logout() {
  const dispatch = useDispatch()
  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)

  useEffect(() => {
    dispatch(logout({_csrf: csrfState}))
    dispatch(clearError())
    dispatch(clearSuccess())
  }, [])

  return !userState ? (
    <Navigate to="/login" replace />
  ) : (
    <div className="flex items-center justify-center h-screen">
      <Spinner />
    </div>
  )
}
