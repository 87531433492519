import Moment from 'react-moment'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {v4 as uuidv4} from 'uuid'
import {useNavigate, useLocation, Link} from 'react-router-dom'
import i18next from 'i18next'

import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import {
  getFiltersFromUrl,
  getFilterFromUrl,
  getLgpdFilterMap,
  getSortFromUrl,
} from 'utils/filters'

import Pagination from 'components/partials/tables/Pagination'
import {defaultManagementFilters, statusList, tableStyles} from 'constant'
import {getSeverityColor} from 'utils/score'
import Tag from 'components/partials/Tag'
import GlobalFilter from 'components/partials/tables/GlobalFilter'

export default function FindingsTable(props) {
  const location = useLocation()
  const navigate = useNavigate()

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 50, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
      Filter: () => '',
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    rows,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    preGlobalFilteredRows,
    previousPage,
    state: {pageIndex, sortBy, filters, globalFilter},
    setGlobalFilter,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        pageIndex: props.pageNumber,
        pageSize: props.pageSize || 5,
        sortBy: getSortFromUrl(window.location, ''),
        filters: getFiltersFromUrl(location, getLgpdFilterMap).length
          ? getFiltersFromUrl(location, getLgpdFilterMap)
          : defaultManagementFilters,
        globalFilter: getFilterFromUrl('global', location),
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )
  function hasFilterApplied() {
    if (!filters.length && !globalFilter) return false

    const hasFilterValue = filters.some(i => i.value.length)

    if (!hasFilterValue && !globalFilter) return false

    return true
  }

  function getFindingUrl(templateType, findingId) {
    return `/${templateType}/finding/${findingId}`
  }

  function updateUrlPagination(index) {
    const prevUrl = new URLSearchParams(window.location.search)
    const uiPaginationIndex = index + 1 // plus one due to one-base indexing
    prevUrl.set('page', uiPaginationIndex)

    navigate(`?${prevUrl.toString()}`)
  }

  function goToUrlPagination() {
    const params = new URLSearchParams(location.search)
    const tablePaginationIndex = parseInt(params.get('page'), 10) - 1 // less one due to zero-base indexing

    gotoPage(tablePaginationIndex)
  }

  function updateUrlQueryStrings(sort, filters) {
    const prevUrl = new URLSearchParams(window.location.search)

    if (sort.length)
      sort.forEach(i => {
        const direction = i.desc ? 'desc' : 'asc'

        prevUrl.set('sort', `${i.id}:${direction}`)
      })

    if (filters.length) {
      filters.forEach(i => {
        const id = i.id
        const values = i.value.map(i => i.value)

        prevUrl.set(id, values.join(','))
      })
    }

    if (`?${prevUrl.toString()}` !== location.search)
      navigate(`?${prevUrl.toString()}`)
  }

  function updateGlobalFilter(filter) {
    const prevUrl = new URLSearchParams(window.location.search)
    if (!filter) filter = ''

    prevUrl.set('global', filter)

    navigate(`?${prevUrl.toString()}`)
  }

  useEffect(() => {
    updateGlobalFilter(globalFilter)
  }, [1])

  useEffect(() => {
    updateUrlQueryStrings(sortBy, filters)
  }, [sortBy, filters, globalFilter])

  useEffect(() => {
    if (!rows.length) return
    props.setFilteredFindings(rows.map(row => row.original))
  }, [rows])

  useEffect(() => {
    goToUrlPagination()
  }, [pageIndex])

  return (
    <div>
      <div className="tableWrap relative">
        <div className="flex mb-2">
          <div className="w-8/12">
            <div className="mr-2">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                value={globalFilter}
                rows={rows}
                setFilterValue={setGlobalFilter}
                setGlobalFilter={setGlobalFilter}
                gotoPage={gotoPage}
                customPlaceholder={`${i18next.t('tables.filterByTitleIn')} ${
                  rows.length
                } ${
                  rows.length <= 1
                    ? i18next.t('misc.finding').toLowerCase()
                    : i18next.t('misc.findings').toLowerCase()
                }`}
              />
            </div>
          </div>
          <div className="ml-auto">
            <Pagination
              handlePrevious={() => {
                previousPage()
                updateUrlPagination(pageIndex - 1)
              }}
              handleNext={() => {
                nextPage()
                updateUrlPagination(pageIndex + 1)
              }}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              paginationClass={props.paginationClass}
              pageIndex={pageIndex}
              pageCount={pageCount}
              total={rows.length || 0}
            />
          </div>
        </div>
        <div className="flex mb-2">
          {headerGroups &&
            headerGroups.map((headerGroup, index) => {
              if (!index) return <span key={uuidv4()}></span>
              return (
                <span key={uuidv4()} className="w-full">
                  {headerGroup.headers.map(column => {
                    if (!column.canFilter) return <span key={uuidv4()}></span>
                    return (
                      <span key={uuidv4()}>
                        {column.canFilter ? column.render('Filter') : null}
                      </span>
                    )
                  })}
                </span>
              )
            })}
        </div>
        {page && page.length ? (
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map(headerGroup => {
                // trick to disable the first headerGroup
                if (headerGroup.headers.length === 1)
                  return <tr key={uuidv4()}></tr>

                return (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={uuidv4()}
                    className="p-2">
                    {headerGroup.headers.map(column => {
                      if (column.hidden) return <th key={uuidv4()}></th>

                      return (
                        <th
                          className={tableStyles.tableHeader}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={uuidv4()}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' ↓'
                                : ' ↑'
                              : ''}
                          </span>
                        </th>
                      )
                    })}
                  </tr>
                )
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    className={tableStyles.tableRow}
                    key={uuidv4()}>
                    {row.cells.map(cell => {
                      if (cell.column.hidden) return <td key={uuidv4()}></td>

                      if (cell.column.id === 'status')
                        return (
                          <td
                            className={tableStyles.tableData}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {statusList[cell.value]}
                          </td>
                        )

                      if (
                        cell.column.id === 'title' &&
                        cell.row.original.templateId &&
                        cell.row.original.templateId.startsWith('AZURE')
                      )
                        return (
                          <td
                            className={tableStyles.tableData}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <Link
                              to={`/azure/finding/${cell.row.original.id}`}
                              className="flex items-center">
                              <span
                                className={`${getSeverityColor(
                                  cell.row.original.severity
                                )} severity-indicator flex-shrink-0 mr-2`}></span>
                              <p className="truncate">{cell.render('Cell')}</p>
                            </Link>
                          </td>
                        )

                      if (
                        cell.column.id === 'title' &&
                        cell.row.original.templateId &&
                        cell.row.original.templateId.startsWith('GCP')
                      )
                        return (
                          <td
                            className={tableStyles.tableData}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <Link
                              to={`/gcp/finding/${cell.row.original.id}`}
                              className="flex items-center">
                              <span
                                className={`${getSeverityColor(
                                  cell.row.original.severity
                                )} severity-indicator flex-shrink-0 mr-2`}></span>
                              <p className="truncate">{cell.render('Cell')}</p>
                            </Link>
                          </td>
                        )

                      if (
                        cell.column.id === 'title' &&
                        cell.row.original.templateId &&
                        cell.row.original.templateId.startsWith('AWS')
                      )
                        return (
                          <td
                            className={tableStyles.tableData}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <Link
                              to={`/aws/finding/${cell.row.original.id}`}
                              className="flex items-center">
                              <span
                                className={`${getSeverityColor(
                                  cell.row.original.severity
                                )} severity-indicator flex-shrink-0 mr-2`}></span>
                              <p className="truncate">{cell.render('Cell')}</p>
                            </Link>
                          </td>
                        )

                      if (cell.column.id === 'title')
                        return (
                          <td
                            className={tableStyles.tableData}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <Link
                              to={getFindingUrl(
                                cell.row.original.type,
                                cell.row.original.id
                              )}
                              className="flex items-center">
                              <span
                                className={`${getSeverityColor(
                                  cell.row.original.severity
                                )} severity-indicator flex-shrink-0 mr-2`}></span>
                              <p className="truncate">{cell.render('Cell')}</p>
                            </Link>
                          </td>
                        )

                      if (cell.column.id === 'lgpdItems')
                        return (
                          <td
                            className={
                              cell.column.classes
                                ? cell.column.classes
                                : 'text-center break-word py-2 pr-2'
                            }
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {Array.isArray(cell.value)
                              ? cell.value.map((article, index) => (
                                  <Tag
                                    key={`${article.title} ${index}`}
                                    content={article.title}
                                    onClick={() =>
                                      props.toggleModal(article.id)
                                    }
                                  />
                                ))
                              : '-'}
                          </td>
                        )

                      if (
                        cell.column.id === 'bestDate' ||
                        cell.column.id === 'checkedAt' ||
                        cell.column.id === 'createdAt' ||
                        cell.column.id === 'updatedAt'
                      ) {
                        const moment =
                          cell.row.original.checkedAt ||
                          cell.row.original.updatedAt
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={uuidv4()}
                            className={tableStyles.tableData}>
                            <Moment date={new Date(moment)} fromNow />
                          </td>
                        )
                      }

                      return (
                        <td
                          className={
                            cell.column.classes
                              ? cell.column.classes
                              : 'py-2 pr-2 text-center truncate break-word'
                          }
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          {cell.value ? cell.render('Cell') : '—'}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <>
            {hasFilterApplied()
              ? props.emptyStateWithFilter
              : props.emptyStateWithoutFilter}
          </>
        )}
      </div>
    </div>
  )
}

FindingsTable.propTypes = {
  filteredFindings: PropTypes.array,
  setFilteredFindings: PropTypes.func,
  filterValue: PropTypes.array,
  defaultSorted: PropTypes.array,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  paginationClass: PropTypes.string,
  toggleModal: PropTypes.func,
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
}
