import {i18next} from 'translate/i18n'
import {hasFeature} from 'utils'

const tooltipStyleBase = 'tooltip-text p-3 max-w-md rounded roboto font-normal'

export const hoverIndigoStyle =
  'transition duration-200 ease-in hover:bg-indigo-600 hover:text-white'
export const hoverStyle = 'transition duration-200 ease-in'
export const labelStyle =
  'block tracking-wide text-sideral-700 text-xs font-medium mb-1'
export const valueStyle = 'text-sm'
export const tooltipStyle = {
  default: `${tooltipStyleBase} text-sm text-white bg-gray-700`,
  danger: `${tooltipStyleBase} text-xs text-red bg-red-200 font-bold text-left`,
}
export const inputStyle =
  'rounded-sm appearance-none block w-full text-ink border rounded roboto leading-tight focus:outline-none focus:bg-white focus:border-sky-500 p-3 mb-3'
export const btnStyle =
  'rounded-sm border px-4 mx-auto block text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white'
export const linkStyle =
  'underline text-sky-500 hover:text-sky-600 cursor-pointer'
export const linearGradientStyle =
  'bg-gradient-to-r from-violet to-ripeOrange text-transparent bg-clip-text'

export const alertStyle = {
  info: 'border border-blue-400 bg-blue-100 text-blue-700',
  warning: 'border border-orange-400 bg-orange-100 text-orange-700',
  success: 'border border-teal-400 bg-teal-100 text-teal-700',
  error: 'border border-red-400 bg-red-100 text-red-700',
  dark: 'border border-gray-400 bg-gray-200 text-gray-700',
}

export const statusCodes = {
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  307: 'Temporary Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
}

export const infoStatusList = {
  new: {status: i18next.t('status.open'), action: 'Mark as open'},
  open: {status: i18next.t('status.open'), action: 'Mark as open'},
  reopened: {status: i18next.t('status.reopened')},
  stale: {status: i18next.t('status.stale')},
  irrelevant: {
    status: i18next.t('status.irrelevant'),
    action: 'This is not relevant',
  },
  unreachable: {
    status: i18next.t('status.unreachable'),
    action: 'This file can no longer be reached',
  },
  unrelated: {
    status: i18next.t('status.unrelated'),
    action: 'This is unrelated to my company',
  },
  revoked: {
    status: i18next.t('status.revoked'),
    action: 'The credentials were revoked',
  },
}

export const statusList = {
  //the order statusList is sorted has influence in management filters sort
  open: i18next.t('status.open'),
  reopened: i18next.t('status.reopened'),
  falsePositive: i18next.t('status.falsePositive'),
  riskAccepted: i18next.t('status.riskAccepted'),
  stale: i18next.t('status.stale'),
  fixed: i18next.t('status.fixed'),
  new: i18next.t('status.new'),
  closed: i18next.t('status.closed'),
}

export const severityList = {
  //the order severityList is sorted has influence in management filters sort
  critical: i18next.t('severity.critical'),
  high: i18next.t('severity.high'),
  medium: i18next.t('severity.medium'),
  low: i18next.t('severity.low'),
  info: i18next.t('severity.info'),
}

export const userRolesList = [
  {
    value: 'regular',
    label: i18next.t('userRoles.regular'),
  },
  {
    value: 'read-only',
    label: i18next.t('userRoles.readOnly'),
  },
  {
    value: 'questionnaires-only',
    label: i18next.t('userRoles.questionnairesOnly'),
  },
  {
    value: 'suppliers-questionnaires',
    label: i18next.t('userRoles.suppliersQuestionnaires'),
  },
]

export const cloudAccountsList = {
  cloud: 'cloudAccount',
}

export const assignedToList = {
  assignedTo: 'assignedTo',
}

export const assignmentList = {
  assigned: i18next.t('finding.assigned'),
  unassigned: i18next.t('finding.unassigned'),
}

export const domainList = {
  domainId: 'domainId',
}

export const issueCategoryList = {
  //the order issueCategoryList is sorted has influence in management filters sort
  dns: 'DNS',
  network: 'Network',
  web: 'Web',
  cloud: 'Cloud',
  people: 'People',
}

export const introOptions = {
  showProgress: false,
  showStepNumbers: false,
  exitOnOverlayClick: false,
  highlightClass: 'opacity-25',
  overlayOpacity: 0.35,
  scrollToElement: false,
}

export const introSteps = userState =>
  [
    {
      element: '#security-score',
      intro: i18next.t('help.intro.securityScore'),
    },
    {
      element: '#securityScoreOverTime',
      intro: i18next.t('help.intro.scoreOverTime'),
      position: 'right',
    },
    {
      element: '#dns-security-menu',
      position: 'right',
      intro: i18next.t('help.intro.dns'),
    },
    {
      element: '#network-exposure-menu',
      position: 'right',
      intro: i18next.t('help.intro.network'),
    },
    {
      element: '#web-security-menu',
      position: 'right',
      intro: i18next.t('help.intro.web'),
    },
    {
      element: '#cloud-security-menu',
      position: 'right',
      intro: i18next.t('help.intro.cloud'),
    },
    {
      element: '#people-exposure-menu',
      position: 'right',
      intro: i18next.t('help.intro.people'),
    },
    {
      element: hasFeature(userState, 'infoExposure') ? '#info-menu' : null,
      position: 'right',
      intro: i18next.t('help.intro.info'),
    },
    {
      element: '#management-menu',
      position: 'right',
      intro: i18next.t('help.intro.management'),
    },
    {
      element: '#reports-menu',
      position: 'right',
      intro: i18next.t('help.intro.reports'),
    },
    {
      element: '#help-menu',
      position: 'top',
      intro: i18next.t('help.intro.help'),
    },
  ].filter(obj => obj.element)

export const introStepsPeople = hasCompanyEmail => {
  const steps = [
    {
      element: '#accounts-table-wrap',
      intro: i18next.t('help.peopleIntro.accountsTable'),
      position: 'top',
    },
    {
      element: '#new-integration-button',
      intro: i18next.t('help.peopleIntro.newIntegrationButton'),
      position: 'bottom',
    },
    {
      element: '#people-help-icon',
      intro: i18next.t('help.peopleIntro.helpIcon'),
    },
  ]

  const filteredSteps = steps.filter(
    (_, stepIndex) => !(!hasCompanyEmail && stepIndex === 0)
  )

  return filteredSteps.filter(obj => obj.element)
}

export const awsUnxposePolicy = {
  Version: '2012-10-17',
  Statement: [
    {
      Sid: 'AccountReadOnly',
      Action: ['account:GetAlternateContact'],
      Effect: 'Allow',
      Resource: '*',
    },
    {
      Sid: 'CloudWatchReadOnly',
      Effect: 'Allow',
      Action: ['cloudwatch:DescribeAlarms', 'logs:DescribeMetricFilters'],
      Resource: '*',
    },
    {
      Sid: 'CloudtrailReadOnly',
      Effect: 'Allow',
      Action: [
        'cloudtrail:ListTrails',
        'cloudtrail:GetTrailStatus',
        'cloudtrail:GetEventSelectors',
        'cloudtrail:DescribeTrails',
      ],
      Resource: '*',
    },
    {
      Sid: 'ConfigReadOnly',
      Effect: 'Allow',
      Action: [
        'config:DescribeConfigurationRecorders',
        'config:DescribeConfigurationRecorderStatus',
      ],
      Resource: '*',
    },
    {
      Sid: 'Ec2ReadOnly',
      Effect: 'Allow',
      Action: [
        'ec2:DescribeFlowLogs',
        'ec2:DescribeImages',
        'ec2:DescribeInstances',
        'ec2:DescribeRouteTables',
        'ec2:DescribeSecurityGroups',
        'ec2:DescribeVolumes',
        'ec2:DescribeVpcPeeringConnections',
        'ec2:DescribeVpcs',
        'ec2:GetEbsEncryptionByDefault',
      ],
      Resource: '*',
    },
    {
      Sid: 'EfsReadOnly',
      Effect: 'Allow',
      Action: ['elasticfilesystem:DescribeFileSystems'],
      Resource: '*',
    },
    {
      Sid: 'IamReadOnly',
      Effect: 'Allow',
      Action: [
        'iam:ListAttachedUserPolicies',
        'iam:ListUserPolicies',
        'iam:GenerateCredentialReport',
        'iam:GetAccountPasswordPolicy',
        'iam:GetCredentialReport',
        'iam:GetPolicyVersion',
        'iam:ListAccountAliases',
        'iam:ListAttachedRolePolicies',
        'iam:ListEntitiesForPolicy',
        'iam:ListPolicies',
        'iam:ListServerCertificates',
        'iam:ListVirtualMFADevices',
      ],
      Resource: '*',
    },
    {
      Sid: 'KmsReadOnly',
      Action: ['kms:DescribeKey', 'kms:GetKeyRotationStatus', 'kms:ListKeys'],
      Effect: 'Allow',
      Resource: '*',
    },
    {
      Sid: 'RdsReadOnly',
      Effect: 'Allow',
      Action: [
        'rds:DescribeDBClusters',
        'rds:DescribeDBInstances',
        'rds:DescribeDBSnapshots',
      ],
      Resource: '*',
    },
    {
      Sid: 'Route53ReadOnly',
      Effect: 'Allow',
      Action: [
        'route53:GetDNSSEC',
        'route53:GetHostedZone',
        'route53:ListHostedZones',
        'route53:ListResourceRecordSets',
        'route53domains:GetDomainDetail',
        'route53domains:ListDomains',
      ],
      Resource: '*',
    },
    {
      Sid: 'S3ReadOnlyMetadata',
      Effect: 'Allow',
      Action: [
        's3:GetAccountPublicAccessBlock',
        's3:GetBucketAcl',
        's3:GetBucketLocation',
        's3:GetBucketLogging',
        's3:GetBucketPolicy',
        's3:GetBucketPolicyStatus',
        's3:GetBucketPublicAccessBlock',
        's3:GetBucketVersioning',
        's3:GetBucketWebsite',
        's3:GetEncryptionConfiguration',
        's3:GetObjectAcl',
        's3:ListAllMyBuckets',
      ],
      Resource: '*',
    },
    {
      Sid: 'SnsReadOnly',
      Effect: 'Allow',
      Action: ['SNS:ListSubscriptions'],
      Resource: '*',
    },
  ],
}

export const snapshotFindingsByType = [
  {
    type: 'general',
    title:
      'Este número indica a quantidade de falhas de segurança encontradas no diagnóstico.',
    body: 'Pense nelas como pontos em que sua empresa está exposta à ação de intrusos. Essa exposição pode resultar em acessos não autorizados a dados, aplicativos, redes ou computadores',
  },
  {
    type: 'dns',
    title:
      'Este número indica a quantidade de falhas de segurança encontradas na categoria DNS.',
    body: 'Falhas de DNS comumente são vetores para ataques phishing, além de permitir que intrusos enviem e-mails se passando por alguém da sua empresa.',
  },
  {
    type: 'web',
    title:
      'Este número indica a quantidade de falhas de segurança encontradas na categoria Web.',
    body: 'Falhas de Web comumente são vetores para ataques que dão acessos não autorizados a dados, aplicativos, redes ou computadores.',
  },
  {
    type: 'people',
    title:
      'Este número indica a quantidade de credenciais corporativas e/ou informações pessoais de colaboradores encontradas em vazamentos de dados recentes.',
    body: 'É importante monitorar vazamentos de forma contínua para proteger a sua empresa e ficar em compliance com a LGPD. Vazamentos de credenciais podem dar acessos não autorizados a dados, aplicativos, redes ou computadores da sua organização.',
  },
]

export const supportedCloudProviders = {
  aws: 'Amazon Web Services',
  azure: 'Microsoft Azure',
  gcp: 'Google Cloud Platform',
}

export const gcpPermissions = [
  'cloudkms.cryptoKeys.list',
  'cloudkms.keyRings.list',
  'cloudsql.instances.list',
  'compute.instances.list',
  'compute.projects.get',
  'compute.zones.list',
  'iam.serviceAccountKeys.list',
  'iam.serviceAccounts.list',
  'resourcemanager.projects.get',
  'resourcemanager.projects.getIamPolicy',
  'resourcemanager.projects.list',
  'storage.buckets.get',
  'storage.buckets.getIamPolicy',
  'storage.buckets.list',
]

export const awsAccountId = '708205383577'

export const azurePermissions = [
  'Microsoft.Sql/servers/auditingSettings/read',
  'Microsoft.Storage/storageAccounts/read',
  'Microsoft.Insights/eventtypes/values/Read',
  'Microsoft.OperationalInsights/workspaces/query/StorageQueueLogs/read',
  'Microsoft.Storage/storageAccounts/listkeys/action',
  'Microsoft.Storage/storageAccounts/queueServices/queues/read',
  'Microsoft.Storage/storageAccounts/blobServices/containers/read',
  'Microsoft.Compute/diskAccesses/read',
  'Microsoft.Compute/diskEncryptionSets/read',
  'Microsoft.Compute/disks/read',
  'Microsoft.Compute/virtualMachines/read',
  'Microsoft.Network/networkInterfaces/read',
  'Microsoft.Resources/subscriptions/read',
  'Microsoft.Resources/subscriptions/resourceGroups/read',
  'Microsoft.Resources/subscriptions/tagNames/read',
  'Microsoft.Resources/subscriptions/tagNames/tagValues/read',
  'Microsoft.Resources/subscriptions/resourcegroups/resources/read',
  'Microsoft.Storage/storageAccounts/blobServices/read',
  'Microsoft.Storage/storageAccounts/tableServices/read',
  'Microsoft.Sql/servers/read',
  'Microsoft.Sql/servers/databases/read',
  'Microsoft.Sql/servers/databases/transparentDataEncryption/read',
  'Microsoft.DBforPostgreSQL/servers/read',
  'Microsoft.DBforPostgreSQL/servers/configurations/read',
  'Microsoft.DBforPostgreSQL/serversv2/read',
  'Microsoft.DBforMySQL/servers/read',
  'Microsoft.Sql/servers/encryptionProtector/read',
  'Microsoft.Sql/servers/administrators/read',
]

export const languages = [
  {value: 'pt-br', label: 'Português - BR'},
  {value: 'en', label: 'English - US'},
]

export const paginationOptions = [
  {value: 10, label: i18next.t('misc.paginationItems', {qty: 10})},
  {value: 20, label: i18next.t('misc.paginationItems', {qty: 20})},
  {value: 30, label: i18next.t('misc.paginationItems', {qty: 30})},
]

export const documentTypesLabels = [
  {value: 'pentest', label: i18next.t('documents.types.pentest')},
  {
    value: 'pentestSummaryReport',
    label: i18next.t('documents.types.pentestSummaryReport'),
  },
  {
    value: 'infoSecPolicy',
    label: i18next.t('documents.types.securityPolicy'),
  },
  {
    value: 'backupPolicy',
    label: i18next.t('documents.types.backupPolicy'),
  },
  {
    value: 'businessContinuityDRPolicy',
    label: i18next.t('documents.types.businessContinuityDRPolicy'),
  },
  {
    value: 'dataClassificationPolicy',
    label: i18next.t('documents.types.dataClassificationPolicy'),
  },
  {
    value: 'encryptionPolicy',
    label: i18next.t('documents.types.encryptionPolicy'),
  },
  {
    value: 'privacyPolicy',
    label: i18next.t('documents.types.privacyPolicy'),
  },
  {
    value: 'secIncidentMgmtPolicy',
    label: i18next.t('documents.types.secIncidentMgmtPolicy'),
  },
  {
    value: 'vulnerabilityMgmtPolicy',
    label: i18next.t('documents.types.vulnerabilityMgmtPolicy'),
  },
]

export const questionnaireStatus = {
  sent: i18next.t('status.sent'),
  accepted: i18next.t('status.accepted'),
  pending: i18next.t('status.pending'),
  approved: i18next.t('status.approved'),
  submitted: i18next.t('status.submitted'),
  changesRequested: i18next.t('status.changesRequested'),
  awaitingReview: i18next.t('status.awaitingReview'),
  edited: i18next.t('status.edited'),
}

export const supplierInviteStatus = {
  sent: i18next.t('status.sent'),
  declined: i18next.t('status.declined'),
  expired: i18next.t('status.expired'),
}

export const nEmployees = {
  10: i18next.t('settings.company.dropdown.10'),
  50: i18next.t('settings.company.dropdown.50'),
  200: i18next.t('settings.company.dropdown.200'),
  500: i18next.t('settings.company.dropdown.500'),
  1000: i18next.t('settings.company.dropdown.1000'),
  5000: i18next.t('settings.company.dropdown.5000'),
  10000: i18next.t('settings.company.dropdown.10000'),
}

export const newFindingsAlert = [
  // order matters
  {value: 'highOrCritical', label: i18next.t('options.highOrCritical')},
  {value: 'mediumOrHigher', label: i18next.t('options.mediumOrHigher')},
  {value: 'allFindings', label: i18next.t('options.allFindings')},
]

export const testWebhookFeedbackList = [
  {
    status: 'neutral',
    title: i18next.t('titles.testYourWebhook'),
    body: i18next.t('subtitles.testYourWebhook'),
    theme: 'bg-astral-50',
  },
  {
    status: 'loading',
    title: i18next.t('titles.holdOn'),
    body: i18next.t('subtitles.holdOn'),
    theme: 'bg-astral-50',
  },
  {
    status: 'success',
    title: i18next.t('titles.webhookSuccess'),
    body: i18next.t('subtitles.webhookSuccess'),
    theme: 'bg-leaf-50',
  },
  {
    status: 'failed',
    title: i18next.t('titles.somethingWrong'),
    body: i18next.t('subtitles.somethingWrong'),
    theme: 'bg-coral-50',
  },
]

export const breachAllowedStatuses = [
  {
    value: 'open',
    label: i18next.t('status.open'),
  },
  {
    value: 'closed',
    label: i18next.t('status.closed'),
  },
  {
    value: 'falsePositive',
    label: i18next.t('status.falsePositive'),
  },
]

export const allowedStatuses = [
  {
    value: 'open',
    label: i18next.t('status.open'),
  },
  {
    value: 'fixed',
    label: i18next.t('status.fixed'),
  },
  {
    value: 'falsePositive',
    label: i18next.t('status.falsePositive'),
  },
  {
    value: 'riskAccepted',
    label: i18next.t('status.riskAccepted'),
  },
]

export const allowedStatusesInfoExposure = [
  {
    value: 'open',
    label: i18next.t('status.open'),
  },
  {
    value: 'irrelevant',
    label: i18next.t('status.irrelevant'),
  },
  {
    value: 'unreachable',
    label: i18next.t('status.unreachable'),
  },
  {
    value: 'unrelated',
    label: i18next.t('status.unrelated'),
  },
  {
    value: 'revoked',
    label: i18next.t('status.revoked'),
  },
]

export const defaultManagementFilters = [
  {
    id: 'status',
    value: [
      {
        value: 'open',
        label: i18next.t('status.open'),
      },
      {
        value: 'reopened',
        label: i18next.t('status.reopened'),
      },
      {
        value: 'fixed',
        label: i18next.t('status.fixed'),
      },
      {
        value: 'stale',
        label: i18next.t('status.stale'),
      },
    ],
  },
]

export const findingAssetPaths = {
  dns: '/dns/domain',
  network: '/network/services',
  people: '/people/account',
  web: '/web/app',
}

export const activeBtn =
  'bg-white w-full uppercase inline-block border-b-2 border-sky-500 text-sky-500 outline-none py-3 px-6'
export const notActiveBtn =
  'bg-sideral-50 w-full uppercase inline-block border-b-2 border-sideral-50 text-sky-900 hover:text-sky-800 outline-none py-3 px-6'

export const borderStyle = 'rounded-lg border border-sideral-100'

export const skeletonLoadingStyle = 'animate-pulse bg-sideral-50 rounded-md'

export const tableStyles = {
  filterWrapper: 'flex justify-between items-center pb-2',
  tableData: 'text-center pr-2',
  tableHeader: 'dm-mono text-sideral-300 text-sm uppercase',
  tableRow:
    'items-center text-sm text-astral-900 border-t border-sideral-50 hover:bg-sideral-50 text-center py-3',
}

export const integrationStyles = {
  mainTitle: 'font-medium text-sky-900 text-lg',
  secondaryTitle: 'font-medium text-sky-900 my-6',
  image: 'block border border-sideral-50 rounded-sm w-full my-4',
  tooltip: `${tooltipStyle.default} text-xs -mt-6 -ml-12`,
  copyContainer:
    'appearance-none block w-full text-ink border border-sideral-50 rounded rounded-r-none leading-tight bg-astral-50 p-3',
  copyButton:
    'inline-flex items-center rounded rounded-l-none border border-sideral-50 bg-astral-50 text-sideral-400 text-md cursor-pointer tooltip p-3',
  codeTextArea: 'dm-mono text-xs border bg-astral-50 overflow-auto my-2 p-4',
}

export const cloudIntegrationErrors = {
  azure: {
    serviceNames: {
      compute: 'Azure Compute',
      database: 'Azure Database',
      storage: 'Azure Storage',
      subscriptions: 'Azure Subscriptions',
    },
    messages: {
      APPLICATION_NOT_FOUND: i18next.t(
        'cloud.azure.errors.applicationNotFound'
      ),
      ENDPOINT_RESOLUTION_ERROR: i18next.t(
        'cloud.azure.errors.endpointResolutionError'
      ),
      EXPIRED_CLIENT_SECRET: i18next.t(
        'cloud.azure.errors.expiredClientSecret'
      ),
      INVALID_CLIENT_SECRET: i18next.t(
        'cloud.azure.errors.invalidClientSecret'
      ),
      NO_SUBSCRIPTION_FOUND: i18next.t(
        'cloud.azure.errors.noSubscriptionFound'
      ),
      SUBSCRIPTION_TYPE_NOT_PERMITTED: i18next.t(
        'cloud.azure.errors.subscriptionTypeNotPermitted'
      ),
      TENANT_NOT_FOUND: i18next.t('cloud.azure.errors.tenantNotFound'),
    },
  },
  gcp: {
    serviceNames: {
      compute: 'Compute Engine',
      iam: 'Identity and Access Management (IAM)',
      kms: 'Cloud Key Management Service (KMS)',
      storage: 'Cloud Storage',
      sql: 'Cloud SQL',
    },
    messages: {
      BILLING_DISABLED: i18next.t('cloud.gcp.errors.billingDisabled'),
      DISABLED_API: i18next.t(
        'cloud.gcp.errors.disabledApi.failedIntegrationMessage'
      ),
      NOT_AUTHORIZED: i18next.t('cloud.gcp.errors.notAuthorized'),
      INVALID_PROJECT: i18next.t('cloud.gcp.errors.invalidProject'),
      ACCOUNT_DELETED: i18next.t('cloud.gcp.errors.accountDeleted'),
    },
  },
}
export const workspaceErrors = {
  apiNames: {
    reports: 'Reports API',
    activity: 'Google Drive Activity API',
    directoryUsers: 'Directory API (Users)',
    directoryDomains: 'Directory API (Domains)',
    customerId: 'Customer ID',
  },
  messages: {
    INVALID_GRANT: i18next.t('googleWorkspace.errors.invalidGrant'),
    INSUFFICIENT_PERMISSION: i18next.t(
      'googleWorkspace.errors.insufficientPermission'
    ),
    NOT_AUTHORIZED: i18next.t('googleWorkspace.errors.notAuthorized'),
    UNAUTHORIZED_CLIENT: i18next.t('googleWorkspace.errors.unauthorizedClient'),
    UNKNOWN_ERROR: i18next.t('googleWorkspace.errors.unknown'),
    CUSTOMER_ID_ALREADY_IN_USE: i18next.t(
      'googleWorkspace.errors.customerIdAlreadyInUse'
    ),
  },
}

export const baseQuestionnaireId = 'ff4ac722-f8d5-47c2-8051-70d82b2e95c3'

export const unixEpochDateString = '1970-01-01T00:00:00.000Z'

export const astral900 = '#212121'

export const coral400 = '#FF375D' // critic

export const mars300 = '#F26B6F' // high
export const mars700 = '#E20026'

export const sideral100 = '#CFD8DC'
export const sideral300 = '#90A4AE'
export const sideral700 = '#455A64'

export const sky50 = '#E0F4FF'
export const sky200 = '#7ED0FF'
export const sky300 = '#47BEFD' // info
export const sky500 = '#528CD4'
export const sky900 = '#0A50A0'

export const summer300 = '#FFDB66' // low
export const summer700 = '#FFAA38' // medium
export const summer900 = '#FD7F30'

export const orange700 = '#c05621'

export const red700 = '#c53030'

export const blue500 = '#4299e1'

export const gray800 = '#2d3748'
export const gray700 = '#4a5568'
export const gray300 = '#cbd5e0'
export const gray200 = '#edf2f7'

export const green500 = '#48BB78'
export const green700 = '#2f855a'

export const teal700 = '#2c7a7b'

export const whatsImportantFeedLimit = 3

export const reactSelectStyles = {
  control: provided => ({
    ...provided,
    borderColor: gray300,
  }),
  placeholder: provided => ({
    ...provided,
    color: '#a0aec0',
  }),
}

export const reactSelectTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
  },
})

export const assetsApisNamesMap = {
  gcp: {
    principals: 'IAM',
    serviceAccountKeys: 'IAM Credentials',
    kmsKeys: 'KMS Keys',
    sqlInstances: 'Cloud SQL Admin',
    storageBuckets: 'Cloud Resource Manager',
    vmInstances: 'Compute Engine',
  },
}

export const assetsNames = {
  gcp: {
    sqlInstances: 'SQL Instances',
    principals: 'Principals',
    storageBuckets: 'Storage buckets',
    kmsKeys: 'KMS keys',
    vmInstances: 'Compute Engine VM instances',
    serviceAccountKeys: 'Service Account Keys',
  },
}

export const assetServiceMap = {
  gcp: {
    kmsKeys: 'kms',
    principals: 'iam',
    serviceAccountKeys: 'iam',
    sqlInstances: 'sql',
    storageBuckets: 'storage',
    vmInstances: 'compute',
  },
}

export const assetApiUrlMap = {
  gcp: {
    kmsKeys:
      'https://console.cloud.google.com/apis/library/cloudkms.googleapis.com',
    principals:
      'https://console.cloud.google.com/apis/library/iam.googleapis.com',
    serviceAccountKeys:
      'https://console.cloud.google.com/apis/library/iamcredentials.googleapis.com',
    sqlInstances:
      'https://console.cloud.google.com/apis/library/sqladmin.googleapis.com',
    storageBuckets:
      'https://console.cloud.google.com/apis/library/cloudresourcemanager.googleapis.com',
    vmInstances:
      'https://console.cloud.google.com/apis/library/compute.googleapis.com',
  },
}
