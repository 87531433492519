import React from 'react'
import PropTypes from 'prop-types'

export default function CloudIcon({width, height, color, opacity}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path
          d="M16 7.99992C19.4933 7.99992 22.5067 10.4799 23.1867 13.9066L23.5867 15.9066L25.6267 16.0533C27.7067 16.1866 29.3333 17.9333 29.3333 19.9999C29.3333 22.1999 27.5333 23.9999 25.3333 23.9999H8C5.05333 23.9999 2.66667 21.6133 2.66667 18.6666C2.66667 15.9333 4.70667 13.6533 7.41333 13.3733L8.84 13.2266L9.50667 11.9599C10.7733 9.51992 13.2533 7.99992 16 7.99992ZM16 5.33325C12.1467 5.33325 8.8 7.51992 7.13333 10.7199C3.12 11.1466 0 14.5466 0 18.6666C0 23.0799 3.58667 26.6666 8 26.6666H25.3333C29.0133 26.6666 32 23.6799 32 19.9999C32 16.4799 29.2667 13.6266 25.8 13.3866C24.8933 8.78658 20.8533 5.33325 16 5.33325Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

CloudIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
}
