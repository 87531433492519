import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import WebhookLogsTable from './WebhookLogsTable'
import {fetchWebhookLogs} from 'actions'
import {useDispatch, useSelector} from 'react-redux'

export function WebhookLogs(props) {
  const dispatch = useDispatch()
  const pageSize = 5,
    pageIndex = 0
  const [logs, setLogs] = useState([])
  const webhookLogsState = useSelector(state => state.webhookLogs)
  const webhookTestsCountState = useSelector(state => state.webhookTestsCount)

  const fetchData = body => {
    dispatch(
      fetchWebhookLogs({
        id: props.webhook.id,
        ...body,
      })
    )
  }

  const logsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: 'Webhook',
            accessor: 'name',
            width: 100,
          },
          {
            Header: 'URL',
            accessor: 'url',
          },
          {
            Header: 'Status code',
            accessor: 'statusCode',
            width: 50,
          },
          {
            Header: 'Moment',
            accessor: 'createdAt',
            width: 80,
          },
        ],
      },
    ],
    []
  )

  useEffect(() => {
    if (props.webhook)
      dispatch(fetchWebhookLogs({id: props.webhook.id, pageIndex}))
  }, [dispatch, webhookTestsCountState, pageIndex])

  useEffect(() => {
    if (webhookLogsState) setLogs(webhookLogsState)
  }, [webhookLogsState])

  return (
    <>
      {logs && logs.total ? (
        <>
          <div className="dm-mono uppercase rounded-t-md text-sideral-700 bg-sideral-50 p-4">
            Logs
          </div>
          <WebhookLogsTable
            columns={logsColumns}
            data={logs.data}
            fetchData={fetchData}
            pageCount={Math.ceil(logs.total / pageSize)}
            pageIndex={pageIndex}
            pageSize={pageSize}
            total={logs.total}
          />
        </>
      ) : (
        <></>
      )}
    </>
  )
}

WebhookLogs.propTypes = {
  webhook: PropTypes.object,
}
