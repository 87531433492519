import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Chart from 'react-apexcharts'

import {astral900, sideral300, sky50, sky900} from 'constant'
import images from 'res'

import SecondaryButton from 'components/partials/buttons/SecondaryButton'

export default function People() {
  const breachesOverTimeState = useSelector(state => state.breachesOverTime)
  const statsPeopleState = useSelector(state => state.statsPeople)

  const navigate = useNavigate()

  const [years, setYears] = useState([])
  const [count, setCount] = useState([])

  const getChartData = breaches => {
    const data = {}
    let period = 10

    breaches.forEach(breach => {
      const breachCount = parseInt(breach.count, 10)

      if (data[breach.year]) {
        data[breach.year] += breachCount
      } else {
        data[breach.year] = breachCount
      }
    })

    const size = Object.keys(data).length
    if (period > size) period = size

    return [
      Object.keys(data).slice(size - period, size),
      Object.values(data).slice(size - period, size),
    ]
  }

  const data = {
    options: {
      tooltip: {
        marker: {show: false},
        style: {
          colors: sideral300,
          fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
          fontSize: 12,
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      title: {
        text: i18next.t('people.breachesOverTime'),
        offsetX: 10,
        style: {
          fontWeight: 'regular',
          color: astral900,
          fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
        },
      },
      colors: [sky900],

      dataLabels: {enabled: false},
      xaxis: {
        categories: years,
        labels: {
          style: {
            colors: sideral300,
            fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
            fontSize: 14,
          },
        },
      },
      yaxis: {
        labels: {
          formatter: value => {
            return value.toFixed(0)
          },
          style: {
            colors: sideral300,
            fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
            fontSize: 14,
          },
        },
      },
      grid: {
        borderColor: sky50,
      },
    },
    series: [
      {
        name: 'Users',
        data: count,
      },
    ],
  }

  useEffect(() => {
    if (
      breachesOverTimeState &&
      breachesOverTimeState.breaches &&
      breachesOverTimeState.breaches.length
    ) {
      const [years, count] = getChartData(breachesOverTimeState.breaches)
      setYears(years)
      setCount(count)
    }
  }, [breachesOverTimeState])

  return (
    <section className="md:flex items-center p-4">
      {breachesOverTimeState && breachesOverTimeState.affectedUsers ? (
        <div className="sm:flex items-center basis-3/4">
          <div className="w-full h-56">
            <Chart
              options={data.options}
              series={data.series}
              height="100%"
              width="100%"
              type="bar"
            />
          </div>
          <div className="bg-astral-50 rounded-md p-4 my-2 md:mx-8">
            <span className="dm-mono block text-5xl text-sky-900">
              {breachesOverTimeState.affectedUsers}
            </span>
            <p className="block w mb-4">{i18next.t('people.breachesOpen')}</p>
            <SecondaryButton
              direction="right"
              size="sm"
              text={i18next.t('buttons.viewAll')}
              onClick={() => {
                navigate('/people')
              }}
            />
          </div>
        </div>
      ) : (
        <div className="relative xs:flex md:mr-8 mb-4 md:mb-0">
          <img
            src={images.SuccessIllustration}
            width={200}
            className="mx-auto mb-4 xs:mr-10"
          />
          <div className="flex items-center border-b pb-6 xs:border-0 xs:pb-0">
            <div>
              <p className="w-full text-sky-900 font-normal text-lg">
                {i18next.t('titles.goodNews')}!
              </p>
              <p className="w-full text-sideral-300 font-light mt-2">
                {i18next.t('people.noBreachedAccounts')}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="basis-1/4 uppercase md:border-l md:px-8 mx-5 md:mx-0">
        <div className="xs:flex md:block justify-between text-center sm:text-left">
          <div className="py-2">
            <span className="dm-mono text-2xl text-sky-900">
              {statsPeopleState.integrations}
            </span>
            <p className="block text-sideral-300 font-light">
              {i18next.t('people.dashboard.integrations')}
            </p>
          </div>

          <div className="py-2">
            <span className="dm-mono text-2xl text-sky-900">
              {statsPeopleState.totalAccounts}
            </span>
            <p className="block text-sideral-300 font-light">
              {i18next.t('people.dashboard.accountsUnderMonitoring')}
            </p>
          </div>

          <div className="py-2">
            <span className="dm-mono text-2xl text-sky-900">
              {statsPeopleState.totalMfaDisabled}
            </span>
            <p className="block text-sideral-300 font-light">
              {i18next.t('people.dashboard.accountsWithoutMfa')}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
