export function getRequestParams(props) {
  const object = {}

  if (!props) return object

  if (props.format) object.format = props.format

  if (props.pageIndex) object.page = props.pageIndex + 1

  if (props.limit) object.limit = props.limit

  if (props.filterValue) object.filter = props.filterValue || ''

  if (props.filterOptions) {
    if (typeof props.filterOptions !== 'object') return

    const entries = Object.entries(props.filterOptions)

    entries.forEach(([key, value]) => {
      if (!Array.isArray(value)) return

      object[key] = value.join(',')
    })
  }

  if (props.page) object.page = props.page

  if (props.sortBy && props.sortBy[0]) {
    object.sortBy =
      props.sortBy[0].id === 'bestDate' ? 'updatedAt' : props.sortBy[0].id

    object.order = props.sortBy[0].desc ? 'desc' : 'asc'
  }

  return object
}
