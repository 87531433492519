import React from 'react'
import PropTypes from 'prop-types'

export default function WebhookIcon({width, height, color, opacity}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity || 1}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.4866 58.6429H24.4628V62.3273C23.7824 66.1042 20.4103 68.9736 16.3528 68.9736C11.8046 68.9736 8.1176 65.3682 8.1176 60.9208C8.1176 58.1764 9.52152 55.7527 11.6654 54.2989L9.30387 51C6.09811 53.1821 4 56.8118 4 60.9208C4 67.592 9.53054 73 16.3528 73C22.471 73 27.5503 68.6506 28.5335 62.9414H59.32C60.033 64.1408 61.3614 64.9472 62.8824 64.9472C65.1565 64.9472 67 63.1445 67 60.9208C67 58.6971 65.1565 56.8944 62.8824 56.8944C61.4729 56.8944 60.2289 57.5869 59.4866 58.6429Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3779 59.1772C12.4496 59.0011 12.5344 58.8278 12.6327 58.6586C13.3947 57.3466 14.7745 56.6101 16.1953 56.6031L31.7496 29.8245C27.1663 26.065 25.8491 19.4554 28.9211 14.1666C32.3451 8.27171 39.9252 6.25009 45.8518 9.65116C49.4903 11.7392 51.6597 15.3859 52 19.2544L47.8842 19.6131C47.6578 17.0333 46.2115 14.6014 43.7852 13.209C39.8342 10.9416 34.7808 12.2894 32.4981 16.2193C30.2626 20.0679 31.5135 24.9658 35.2771 27.2963L37.5515 28.6015L19.9343 58.9317C20.4349 59.971 20.4889 61.2039 20.0117 62.3164C19.4294 63.883 17.9126 65 16.1332 65C13.8505 65 12 63.1619 12 60.8945C12 60.2814 12.1353 59.6997 12.3779 59.1772Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.8903 24.1085L56.3516 53.9772L58.4311 52.7915L58.4663 52.8518C58.4835 52.8418 58.5007 52.8319 58.518 52.8221C62.4764 50.5651 67.5194 51.8728 69.782 55.743C72.0445 59.6132 70.6697 64.5803 66.7113 66.8373C64.2711 68.2287 61.4186 68.2654 59.0595 67.1821L57.2981 70.8657C60.8336 72.483 65.1049 72.4251 68.7597 70.3411C74.6973 66.9556 76.7594 59.5049 73.3656 53.6996C70.3014 48.4581 63.8371 46.35 58.2401 48.4819L42.7466 21.9793C43.414 20.7774 43.4515 19.2782 42.7121 18.0134C41.5808 16.0783 39.0593 15.4244 37.0801 16.553C35.1009 17.6815 34.4135 20.165 35.5448 22.1001C36.2638 23.33 37.5443 24.0423 38.8903 24.1085Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

WebhookIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
}
