import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'

import {DefaultModal} from 'components/partials/DefaultModal'
import {PrimaryDatePicker} from 'components/partials/inputs/PrimaryDatePicker'
import {updateQuestionnaire} from 'actions'
import {Trans} from 'react-i18next'

export default function QuestionnaireSubmitModal({
  submitQuestionnaireModal,
  setSubmitQuestionnaireModal,
}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)

  const {id, name, companyName, type} =
    submitQuestionnaireModal.selectedQuestionnaire

  const initialFormState = {
    id,
    submittedAt: null,
  }

  const [form, setForm] = useState(initialFormState)

  const isAnUploadedQuestionnaire = type === 'uploaded'

  const handleSubmit = () => {
    dispatch(
      updateQuestionnaire({
        questionnaireId: form.id,
        submittedAt: isAnUploadedQuestionnaire
          ? new Date(form.submittedAt)
          : new Date(),
        isAnUploadedQuestionnaire,
        _csrf: csrfState,
      })
    )

    setSubmitQuestionnaireModal({
      selected: null,
      showModal: false,
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <DefaultModal
        id="submitQuestionnaireModal"
        title={i18next.t('questionnaires.submit.modal.title')}
        body={
          <div className="text-sm text-gray-700 leading-6 mb-8">
            {isAnUploadedQuestionnaire ? (
              <PrimaryDatePicker
                text={i18next.t('questionnaires.submit.modal.submissionDate')}
                value={{
                  startDate: form.submittedAt,
                  endDate: form.submittedAt,
                }}
                setValue={e => {
                  setForm({
                    ...form,
                    submittedAt: e.startDate,
                  })
                }}
              />
            ) : (
              <Trans
                i18nKey="questionnaires.submit.modal.body"
                values={{
                  questionnaireName: name,
                  companyName,
                }}
              />
            )}
          </div>
        }
        primaryBtnText={i18next.t('questionnaires.submit.submitBtn')}
        secondayBtnText={i18next.t('buttons.cancel')}
        onClickSecondaryBtn={() =>
          setSubmitQuestionnaireModal({
            selected: null,
            showModal: false,
          })
        }
        primaryButtonType="submit"
        disablePrimaryBtn={isAnUploadedQuestionnaire && !form.submittedAt}
      />
    </form>
  )
}

QuestionnaireSubmitModal.propTypes = {
  submitQuestionnaireModal: PropTypes.object,
  setSubmitQuestionnaireModal: PropTypes.func,
}
