import React from 'react'
import PropTypes from 'prop-types'

export default function ScoreBadgeSquare(props) {
  let color

  switch (props.letter) {
    case 'A':
      color = 'bg-teal-500'
      break
    case 'B':
      color = 'bg-green-500'
      break
    case 'C':
      color = 'bg-yellow-500'
      break
    case 'D':
      color = 'bg-orange-500'
      break
    case 'E':
      color = 'bg-red-500'
      break
    case 'F':
      color = 'bg-red-700'
      break
    default:
      color = 'bg-gray-400 text-gray-600'
      break
  }

  return (
    <div
      className={
        'flex items-center justify-center self-center rounded-lg text-xl md:text-3xl text-white font-normal w-10 md:w-16 h-10 md:h-16 ' +
        color
      }>
      {props.letter}
    </div>
  )
}

ScoreBadgeSquare.propTypes = {
  letter: PropTypes.string,
}
