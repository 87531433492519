import React, {useState} from 'react'
import i18next from 'i18next'
import {useNavigate} from 'react-router-dom'

import images from 'res'
import {sky500} from 'constant'
import SectionAdvantages from 'components/partials/SectionAdvantages'
import VideoModal from 'components/partials/VideoModal'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'

const PeopleEmptyState = () => {
  const navigate = useNavigate()

  const [showVideoModal, setShowVideoModal] = useState(false)

  return (
    <div className="w-full flex-wrap text-center border border-sideral-100 font-medium rounded-md p-4 my-8">
      <SectionAdvantages
        headerIcon={<images.PeopleIcon width="50" height="50" color={sky500} />}
        headerTitle={i18next.t('people.advantages.headerTitle')}
        customTitle={
          <div className="flex">
            <h2>{i18next.t('people.advantages.title')}</h2>
            <div className="flex items-center ml-6">
              <img src={images.googleIcon} width="25" />
              <img src={images.microsoftIcon} width="25" className="mx-4" />
            </div>
          </div>
        }
        advantagesItems={[
          i18next.t('people.advantages.items.first'),
          i18next.t('people.advantages.items.second'),
          i18next.t('people.advantages.items.third'),
        ]}
        actionButtons={
          <>
            <PrimaryButton
              text={i18next.t('people.advantages.actionBtn')}
              onClick={() => navigate('/people/integrations/new')}
            />
            <PrimaryButton
              text={i18next.t('people.advantages.secondaryBtn')}
              onClick={() => setShowVideoModal(true)}
              theme="blue-outline"
              margin="ml-2"
            />
          </>
        }
        rightSideImage={
          <img className="w-full m-auto" src={images.PeopleCards} />
        }
      />

      {showVideoModal && (
        <VideoModal
          videoUrl="https://www.youtube.com/embed/3DVHY3DGKLs?si=hFgKzY1UUKSmhzhK"
          setShowModal={setShowVideoModal}
        />
      )}
    </div>
  )
}

export default PeopleEmptyState
