import React from 'react'
import PropTypes from 'prop-types'

export default function ClockIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0001 1H10.0001C9.4501 1 9.0001 1.45 9.0001 2C9.0001 2.55 9.4501 3 10.0001 3H14.0001C14.5501 3 15.0001 2.55 15.0001 2C15.0001 1.45 14.5501 1 14.0001 1ZM12.0001 14C12.5501 14 13.0001 13.55 13.0001 13V9C13.0001 8.45 12.5501 8 12.0001 8C11.4501 8 11.0001 8.45 11.0001 9V13C11.0001 13.55 11.4501 14 12.0001 14ZM19.0301 7.39L19.7801 6.64C20.1601 6.26 20.1701 5.63 19.7801 5.24L19.7701 5.23C19.3801 4.84 18.7601 4.85 18.3701 5.23L17.6201 5.98C16.0701 4.74 14.1201 4 12.0001 4C7.2001 4 3.1201 7.96 3.0001 12.76C2.8701 17.84 6.9401 22 12.0001 22C16.9801 22 21.0001 17.97 21.0001 13C21.0001 10.88 20.2601 8.93 19.0301 7.39ZM12.0001 20C8.1301 20 5.0001 16.87 5.0001 13C5.0001 9.13 8.1301 6 12.0001 6C15.8701 6 19.0001 9.13 19.0001 13C19.0001 16.87 15.8701 20 12.0001 20Z"
        fill={color || '#E20026'}
      />
    </svg>
  )
}

ClockIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
