import React from 'react'
import {feedbackIntegration} from '@sentry/react'
import i18next from 'i18next'

export const SentryUserFeedbackButton = () => {
  const feedback = feedbackIntegration({
    useSentryUser: {
      name: 'name',
      email: 'email',
    },

    autoInject: false,
    showName: false,
    showEmail: false,
    showBranding: false,
    enableScreenshot: true,

    colorScheme: 'light',

    // LABELS
    formTitle: i18next.t('sentryFeedbackReport.title'),
    messageLabel: i18next.t('sentryFeedbackReport.description'),
    isRequiredLabel: i18next.t('sentryFeedbackReport.required'),
    messagePlaceholder: i18next.t('sentryFeedbackReport.messagePlaceholder'),
    addScreenshotButtonLabel: i18next.t(
      'sentryFeedbackReport.addScreenshotBtn'
    ),
    removeScreenshotButtonLabel: i18next.t(
      'sentryFeedbackReport.removeScreenshotBtn'
    ),
    submitButtonLabel: i18next.t('sentryFeedbackReport.submitBtn'),
    cancelButtonLabel: i18next.t('buttons.cancel'),
    confirmButtonLabel: i18next.t('buttons.confirm'),
    successMessageText: i18next.t('sentryFeedbackReport.successMessageText'),
  })

  const onClickReportBtn = async () => {
    const form = await feedback.createForm()

    form.appendToDom()
    form.open()
  }

  return (
    <button
      id="sentry-user-feedback-btn"
      className="fixed bottom-[20px] right-[20px] bg-white hover:bg-gray-100 border rounded shadow-md text-sm font-medium px-3 py-2 z-50"
      onClick={onClickReportBtn}>
      <i className="icofont-mega-phone text-lg mr-2" />
      {i18next.t('sentryFeedbackReport.title')}
    </button>
  )
}
