import React from 'react'
import PropTypes from 'prop-types'

export default function ManagementIcon({width, height, color, opacity}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.6667 4H5.33333C3.86667 4 2.66667 5.2 2.66667 6.66667V25.3333C2.66667 26.8 3.86667 28 5.33333 28H26.6667C28.1333 28 29.3333 26.8 29.3333 25.3333V6.66667C29.3333 5.2 28.1333 4 26.6667 4ZM26.6667 25.3333H5.33333V6.66667H26.6667V25.3333Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.88 13.8933L23.9867 12L19.76 16.2267L17.88 14.3333L16 16.2133L19.76 20L25.88 13.8933Z"
          fill={color}
        />
        <path
          d="M13.3333 9.33325H6.66667V11.9999H13.3333V9.33325Z"
          fill={color}
        />
        <path
          d="M13.3333 14.6667H6.66667V17.3334H13.3333V14.6667Z"
          fill={color}
        />
        <path d="M13.3333 20H6.66667V22.6667H13.3333V20Z" fill={color} />
      </g>
    </svg>
  )
}

ManagementIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
}
