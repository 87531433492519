import PropTypes from 'prop-types'
import React from 'react'
import {removeTagFromAsset} from 'actions'
import {useDispatch, useSelector} from 'react-redux'

export default function RemovableTag({
  content,
  margin,
  onClick,
  asset,
  findingId,
  provider,
}) {
  const dispatch = useDispatch()
  const csrfState = useSelector(state => state.csrf)

  function removeTag() {
    dispatch(
      removeTagFromAsset({
        tag: 'notSensitive',
        _csrf: csrfState,
        asset,
        findingId,
        provider,
      })
    )
  }

  return (
    <div
      className={`rounded-full inline-flex items-center justify-center border border-gray-300 bg-gray-100 hover:bg-gray-200 p-px ${
        onClick ? 'cursor-pointer' : ''
      } ${margin ? margin : 'mb-1 mr-1'}`}
      onClick={onClick}>
      <span className="text-xs pl-2">{content}</span>
      <button
        className="icofont-close rounded-full text-xs mx-2"
        onClick={removeTag}
      />
    </div>
  )
}

RemovableTag.propTypes = {
  content: PropTypes.string,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  asset: PropTypes.string,
  provider: PropTypes.string,
  findingId: PropTypes.string,
}
