import React from 'react'
import PropTypes from 'prop-types'

export default function UnexpectedChangeIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.54253 19.9843L5.54246 13.9999L6.91425 15.2981L7.80928 14.4511L5.35425 12.1297C5.11376 11.9001 4.69345 11.9021 4.45087 12.1297L2 14.4491L2.89712 15.302L4.26685 14.0058L4.26692 19.9981C4.26692 21.0965 5.21211 21.991 6.37271 21.991H11.9938L12.0001 20.7759H6.38109C5.91894 20.7759 5.54253 20.4216 5.54253 19.9843Z"
        fill={color}
      />
      <rect
        x="2.75"
        y="2.75"
        width="6.5"
        height="6.5"
        rx="1.25"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M18.4575 4.00669L18.4575 9.99107L17.0857 8.69288L16.1907 9.53985L18.6458 11.8613C18.8862 12.0908 19.3066 12.0889 19.5491 11.8613L22 9.54184L21.1029 8.68892L19.7332 9.98513L19.7331 3.99283C19.7331 2.89447 18.7879 2 17.6273 2L12.0062 2L11.9999 3.21509L17.6189 3.21509C18.0811 3.21509 18.4575 3.56933 18.4575 4.00669Z"
        fill={color}
      />
      <rect
        x="21.25"
        y="21.241"
        width="6.5"
        height="6.5"
        rx="1.25"
        transform="rotate(-180 21.25 21.241)"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  )
}

UnexpectedChangeIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
