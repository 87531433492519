import {
  fetchPartnerFindingDone,
  fetchPartnerFindingsDone,
  genericError,
} from 'actions'
import config from 'config'
import {handler} from './handler'

export function* handleFetchPartnerFinding(action) {
  yield handler({
    handler: _fetchPartnerFinding,
    success: fetchPartnerFindingDone,
    error: genericError,
    action,
  })
}

const _fetchPartnerFinding = findingId => {
  return fetch(`${config.SERVER_URL}/partner/finding/${findingId}`, {
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'GET',
  })
}

export function* handleFetchPartnerFindings(action) {
  yield handler({
    handler: _fetchPartnerFindings,
    success: fetchPartnerFindingsDone,
    action,
  })
}

const _fetchPartnerFindings = () => {
  return fetch(`${config.SERVER_URL}/partner/findings`, {
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'GET',
  })
}
