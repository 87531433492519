import {getRequestParams} from 'utils/pagination'
import config from '../config'
import {handler} from './handler'
import {
  fetchWebhookLogsDone,
  fetchWebhooks,
  fetchWebhooksDone,
  genericError,
  success,
  testWebhookDone,
} from 'actions'

const _addWebhook = body => {
  return fetch(`${config.SERVER_URL}/webhooks`, {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'POST',
  })
}

export function* handleAddWebhook(action) {
  yield handler({
    handler: _addWebhook,
    error: genericError,
    success: [success, fetchWebhooks],
    action,
  })
}

const _fetchWebhooks = () => {
  return fetch(`${config.SERVER_URL}/webhooks`, {
    credentials: 'include',
    method: 'GET',
  })
}

export function* handleFetchWebhooks(action) {
  yield handler({
    action,
    error: genericError,
    handler: _fetchWebhooks,
    success: fetchWebhooksDone,
  })
}

const _toggleWebhook = body => {
  return fetch(`${config.SERVER_URL}/webhook/${body.id}/toggle`, {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'PATCH',
  })
}

export function* handleToggleWebhook(action) {
  yield handler({
    action,
    error: genericError,
    handler: _toggleWebhook,
    success: fetchWebhooks,
  })
}

const _removeWebhook = body => {
  return fetch(`${config.SERVER_URL}/webhook/${body.id}`, {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'DELETE',
  })
}

export function* handleRemoveWebhook(action) {
  yield handler({
    action,
    error: genericError,
    handler: _removeWebhook,
    success: [success, fetchWebhooks],
  })
}

const _testWebhook = body => {
  return fetch(`${config.SERVER_URL}/webhook/test`, {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'POST',
  })
}

export function* handleTestWebhook(action) {
  yield handler({
    action,
    error: genericError,
    handler: _testWebhook,
    success: testWebhookDone,
  })
}

const _updateWebhook = body => {
  return fetch(`${config.SERVER_URL}/webhook/${body.id}`, {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'PATCH',
  })
}

export function* handleUpdateWebhook(action) {
  yield handler({
    handler: _updateWebhook,
    error: genericError,
    success: [success, fetchWebhooks],
    action,
  })
}

const _fetchWebhookLogs = body => {
  const url = new URL(`${config.SERVER_URL}/webhook/${body.id}/logs`)
  url.search = new URLSearchParams(getRequestParams(body)).toString()

  return fetch(url, {
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'GET',
  })
}

export function* handleFetchWebhookLogs(action) {
  yield handler({
    action,
    handler: _fetchWebhookLogs,
    success: fetchWebhookLogsDone,
  })
}
