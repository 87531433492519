import React from 'react'
import {useNavigate} from 'react-router-dom'
import i18next from 'i18next'
import SectionAdvantages from 'components/partials/SectionAdvantages'
import images from 'res'
import {sky500} from 'constant'
import CloudIconProhibited from 'res/icons/cloudIconProhibited'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'

const CloudEmptyState = () => {
  const navigate = useNavigate()
  return (
    <div className="mt-8">
      <SectionAdvantages
        headerIcon={<CloudIconProhibited width="70" color={sky500} />}
        headerTitle={i18next.t('cloud.noResults.title')}
        customTitle={
          <div className="sm:flex">
            <h2>{i18next.t('cloud.noResults.advantages.title')}</h2>
            <div className="flex items-center sm:ml-6 mt-3 sm:mt-0">
              <img src={images.awsIcon} width="36" />
              <img src={images.gcpIcon} width="30" className="mx-4" />
              <img src={images.azureIcon} width="30" />
            </div>
          </div>
        }
        advantagesItems={[
          i18next.t('cloud.noResults.advantages.listItems.first'),
          i18next.t('cloud.noResults.advantages.listItems.second'),
          i18next.t('cloud.noResults.advantages.listItems.third'),
        ]}
        actionButtons={
          <PrimaryButton
            text={i18next.t('buttons.integrateYourAccounts')}
            onClick={() => navigate('/cloud/integration')}
          />
        }
        rightSideVideo={
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/wGWpqP_WMOM?si=iGU0cZVlin_h24SU"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        }
      />
    </div>
  )
}

export default CloudEmptyState
