import React, {useState, useEffect} from 'react'
import {i18next} from 'translate/i18n'
import {useDispatch, useSelector} from 'react-redux'
import {clearError, clearSuccess, fetchInvites} from 'actions'
import Subsection from 'components/partials/headers/Subsection'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import MembersList from './MembersList'
import InvitesList from './InvitesList'
import {isLoading} from 'utils'

export default function Members() {
  const dispatch = useDispatch()

  const invitesState = useSelector(state => state.invites)
  const companyState = useSelector(state => state.company)
  const loadingState = useSelector(state => state.loading)

  const [inviteList, setInviteList] = useState([])
  const [membersList, setMembersList] = useState([])
  const [showInviteModal, setShowInviteModal] = useState(false)

  const onClickInviteMember = () => {
    dispatch(clearError())
    dispatch(clearSuccess())
    setShowInviteModal(true)
  }

  useEffect(() => {
    dispatch(fetchInvites())
  }, [])

  useEffect(() => {
    if (invitesState) {
      let arr = []
      arr = [...invitesState]
      arr.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt))

      setInviteList(arr)
    }
  }, [invitesState])

  useEffect(() => {
    if (companyState && companyState.members) {
      let arr = []
      arr = [...companyState.members]
      arr.sort((a, b) => {
        const result = a.firstName.localeCompare(b.firstName)
        return result !== 0 ? result : a.lastName.localeCompare(b.lastName)
      })

      setMembersList(arr)
    }
  }, [companyState])

  return (
    <>
      <Subsection
        title={i18next.t('titles.sentInvites')}
        content={<InvitesList inviteList={inviteList} />}
        margin="mb-10"
        hide={inviteList.length === 0}
        loading={isLoading(loadingState.invites)}
      />

      <Subsection
        title={i18next.t('titles.teamMembers')}
        button={
          <SecondaryButton
            text={i18next.t('buttons.addNewMember')}
            onClick={onClickInviteMember}
            size="sm"
            margin="ml-auto"
            plusIcon
          />
        }
        content={
          <MembersList
            membersList={membersList}
            showInviteModal={showInviteModal}
            setShowInviteModal={setShowInviteModal}
          />
        }
        loading={isLoading(loadingState.company)}
      />
    </>
  )
}
