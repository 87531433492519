import React from 'react'
import PropTypes from 'prop-types'

const CustomMapLegend = ({
  title,
  legendRange,
  colorFeatureRange,
  disabled = false,
}) =>
  !disabled && (
    <div id="custom-map-legend" className="leaflet-bottom leaflet-left">
      <div className="mb-1">
        <div className="legend mb-2 font-semibold">{title}</div>
        <div className="legend-range flex justify-between">
          {legendRange.map((item, i) => (
            <div key={i++}>{item}</div>
          ))}
        </div>
      </div>
      <div
        className="gradient-color"
        style={{
          backgroundImage: `linear-gradient(to right, ${colorFeatureRange})`,
        }}
      />
    </div>
  )

CustomMapLegend.propTypes = {
  title: PropTypes.string,
  legendRange: PropTypes.array,
  colorFeatureRange: PropTypes.array,
  disabled: PropTypes.bool,
}

export default CustomMapLegend
