import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import {getSeverityString} from 'utils/score'

const SeverityChip = props => {
  const [color, setColor] = useState('bg-gray-500')
  const [severity, setSeverity] = useState({
    value: 0,
    string: 'info',
  })

  useEffect(() => {
    if (!props.severity) return

    setSeverity({
      value: props.severity.toFixed(1),
      string: getSeverityString(props.severity),
    })
  }, [props.severity])

  useEffect(() => {
    let severityColor = null

    switch (severity.string.toLowerCase()) {
      case 'info':
        severityColor = 'bg-sky-300'
        break
      case 'low':
        severityColor = 'bg-summer-300'
        break
      case 'medium':
        severityColor = 'bg-summer-700'
        break
      case 'high':
        severityColor = 'bg-mars-300'
        break
      case 'critical':
        severityColor = 'bg-coral-400'
        break
      default:
        severityColor = 'bg-astral-200'
        break
    }
    setColor(severityColor)
  }, [severity])

  return (
    <span
      className={`rounded-full ${color} uppercase px-2 py-1 text-xs font-medium text-white ${props.class}`}>
      {severity.string} {severity.value}
    </span>
  )
}

SeverityChip.propTypes = {
  class: PropTypes.string,
  severity: PropTypes.number,
}

export default SeverityChip
