import i18next from 'i18next'
import PropTypes from 'prop-types'
import React, {useCallback, useMemo} from 'react'
import {arrayBufferToString} from 'utils'
import {useDropzone} from 'react-dropzone'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

export default function Dropzone(props) {
  const onDrop = useCallback(
    acceptedFiles => {
      if (!acceptedFiles.length) return

      acceptedFiles.forEach(file => {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          props.setFile(arrayBufferToString(reader.result))
        }
        reader.readAsArrayBuffer(file)
      })
    },
    [props]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({onDrop})

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <>
      <div className="">
        {props.file ? (
          <>
            <div>
              <pre className="text-mono text-xs border bg-gray-100 overflow-auto my-2 p-4 max-h-64 w-full">
                {props.file}
              </pre>
            </div>
            <div
              className="text-right text-sky-500 uppercase text-xs cursor-pointer hover:font-medium hover:underline -my-1"
              onClick={() => props.setFile('')}>
              Clear
            </div>
          </>
        ) : (
          <>
            <div {...getRootProps({style})} className="flex-1">
              <input name="logo" {...getInputProps()} />
              <p>
                {i18next.t('cloud.gcp.manualIntegration.stepFour.dragAndDrop')}
              </p>
            </div>
          </>
        )}
      </div>
    </>
  )
}

Dropzone.propTypes = {
  file: PropTypes.string,
  setFile: PropTypes.func,
}
