import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {useNavigate} from 'react-router-dom'
import SecondaryButton from './SecondaryButton'

export default function BackButton({fallbackLink, forceFallbackLink}) {
  const navigate = useNavigate()

  const handleClick = () => {
    if (forceFallbackLink) return navigate(fallbackLink)

    window.history.length > 2
      ? navigate(-1)
      : navigate(fallbackLink, {replace: true})
  }

  return (
    <SecondaryButton
      text={i18next.t('buttons.back')}
      direction="left"
      size="sm"
      onClick={handleClick}
    />
  )
}

BackButton.propTypes = {
  fallbackLink: PropTypes.string,
  forceFallbackLink: PropTypes.bool,
}
