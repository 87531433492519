import React from 'react'
import PropTypes from 'prop-types'

import {borderStyle} from 'constant'
import {Page} from './Page'
import BackButton from './buttons/BackButton'

const Tutorial = ({pageTitle, fallbackLink, children}) => {
  return (
    <Page pageTitle={pageTitle}>
      <BackButton fallbackLink={fallbackLink} />

      <div className={borderStyle + ' bg-white p-6 mt-4'}>
        <div className="lg:w-8/12">{children}</div>
      </div>
    </Page>
  )
}

Tutorial.propTypes = {
  pageTitle: PropTypes.string,
  fallbackLink: PropTypes.string,
  children: PropTypes.array,
}

export default Tutorial
