import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {verifySupplierInvite} from 'actions'
import {useParams} from 'react-router-dom'
import LandingPage from 'components/LandingPage'
import i18next from 'i18next'
import {isLoading} from 'utils'

export default function SuppliersInviteHandler() {
  const dispatch = useDispatch()
  const params = useParams()

  const loadingState = useSelector(state => state.loading)
  const verifySupplierInviteState = useSelector(
    state => state.verifySupplierInvite
  )

  const [content, setContent] = useState({
    title: i18next.t('suppliers.inviteHandler.loading.title'),
    description: i18next.t('suppliers.inviteHandler.loading.description'),
  })

  const setPageContentByCode = code => {
    switch (code) {
      case 'SESSION_INVALID':
        setContent({
          title: i18next.t('suppliers.inviteHandler.invalidSession.title'),
          description: i18next.t(
            'suppliers.inviteHandler.invalidSession.description'
          ),
        })
        break

      case 'SESSION_EXPIRED':
        setContent({
          title: i18next.t('suppliers.inviteHandler.expiredSession.title'),
          description: i18next.t(
            'suppliers.inviteHandler.expiredSession.description'
          ),
        })
        break

      case 'UNKNOWN_ERROR':
        setContent({
          title: i18next.t('suppliers.inviteHandler.unknownError.title'),
          description: i18next.t(
            'suppliers.inviteHandler.unknownError.description'
          ),
        })
        break

      case 'COMPANY_EXISTS':
        setContent({
          title: i18next.t('suppliers.inviteHandler.companyExists.title'),
          description: i18next.t(
            'suppliers.inviteHandler.companyExists.description'
          ),
        })
        break

      default:
        setContent({title: code, description: ''})
    }
  }

  useEffect(() => {
    dispatch(verifySupplierInvite({sessionId: params.id}))
  }, [])

  useEffect(() => {
    if (!verifySupplierInviteState) return

    if (verifySupplierInviteState.redirect) {
      return (window.location.href = verifySupplierInviteState.redirect)
    }

    setPageContentByCode(verifySupplierInviteState.code)
  }, [verifySupplierInviteState])

  return (
    <LandingPage
      content={content}
      loading={isLoading(loadingState.verifySupplierInvite)}
    />
  )
}
