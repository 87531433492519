import React from 'react'
import moment from 'moment'
import {Trans} from 'react-i18next'
import {useSelector} from 'react-redux'

import i18next from 'i18next'
import {getFeatureSubscription} from 'utils'

export default function PlanUtils() {
  const userState = useSelector(state => state.user)

  function getLastMonitoringDate() {
    if (!userState.subscription) {
      return moment(userState.company.createdAt).utc().format('DD/MM/YYYY')
    }

    return moment(userState.subscription.endDate).utc().format('DD/MM/YYYY')
  }

  const getPlanExpirationDays = () => {
    if (!userState.subscription) {
      return 0
    }

    if (!userState.subscription.endDate) {
      return Infinity
    }

    const todayDateTime = moment()
    const expirationDateTime = moment(userState.subscription.endDate)
    const expirationDays = expirationDateTime.diff(todayDateTime, 'days')

    return expirationDays > 0 ? expirationDays : 0
  }

  const userHasPremiumPlan = () => {
    if (!userState) {
      return null
    }

    if (!userState.subscription) {
      return false
    }

    if (!userState.subscription.endDate) {
      return true
    }

    return new Date(userState.subscription.endDate) > new Date()
  }

  const isPlanExpiringSoon = () => {
    if (!userState) {
      return null
    }

    if (!userState.subscription) {
      return true
    }

    if (!userState.subscription.endDate) {
      return false
    }

    if (userState.futureSubscription?.endDate) {
      const end = new Date(userState.futureSubscription.endDate)

      if (end < moment().add(45, 'days')) {
        return true
      }

      return false
    }

    return getPlanExpirationDays() <= 45
  }

  const isAfterPlanStartDay = () => {
    const dayAfterPlanStart = moment(userState.company.createdAt).add(1, 'd')
    return moment().isAfter(dayAfterPlanStart)
  }

  const shouldShowPlanRenewalModal = () => {
    const introWillNotAppear = userState?.guides?.intro

    if (introWillNotAppear) {
      return userHasPremiumPlan() ? isPlanExpiringSoon() : isAfterPlanStartDay()
    }
  }

  const shouldShowPlanInfoMenu = () =>
    userHasPremiumPlan() ? isPlanExpiringSoon() : isAfterPlanStartDay()

  const hasPlanRestriction = () => !userHasPremiumPlan()

  const hasAccessToDocuments = () => {
    return (
      userHasPremiumPlan() ||
      (!userHasPremiumPlan() && userState.company.isSupplierCompany)
    )
  }

  const getPlanMenuLabels = () => {
    if (userHasPremiumPlan()) {
      return {
        title: i18next.t('planInfo.paid.menu.title', {
          username: userState.firstName,
        }),
        body: (
          <Trans
            i18nKey="planInfo.paid.menu.body"
            values={{remainingDays: getPlanExpirationDays()}}
          />
        ),
        subscribeBtn: i18next.t('planInfo.paid.menu.subscribeBtn'),
      }
    } else {
      return {
        title: i18next.t('planInfo.free.menu.title', {
          username: userState.firstName,
        }),
        body: (
          <Trans
            shouldUnescape
            i18nKey="planInfo.free.menu.body"
            values={{
              date: getLastMonitoringDate(),
            }}
          />
        ),
        subscribeBtn: i18next.t('planInfo.free.menu.subscribeBtn'),
      }
    }
  }

  const planHasExpired = () => {
    return new Date(userState.subscription.endDate) < new Date()
  }

  const getPlanModalLabels = () => {
    const deadLine = moment(userState.subscription?.endDate).format('L')

    if (userHasPremiumPlan()) {
      return {
        title: i18next.t('planInfo.paid.modal.title', {
          remainingDays: getPlanExpirationDays(),
        }),
        body: (
          <Trans
            shouldUnescape
            i18nKey="planInfo.paid.modal.body"
            values={{
              deadLine,
            }}
          />
        ),
        primaryBtn: i18next.t('planInfo.paid.modal.primaryBtn'),
        secondaryBtn: i18next.t('planInfo.paid.modal.secondaryBtn'),
        ignoreBtn: i18next.t('planInfo.paid.modal.ignoreBtn'),
        paperformTitles: {
          primary: i18next.t('planInfo.paid.modal.paperform.title'),
          secondary: '',
        },
      }
    } else {
      return {
        title: (
          <Trans
            shouldUnescape
            i18nKey="planInfo.free.modal.title"
            values={{
              date: getLastMonitoringDate(),
            }}
          />
        ),
        body: (
          <Trans
            shouldUnescape
            i18nKey="planInfo.free.modal.body"
            values={{
              deadLine,
            }}
          />
        ),
        primaryBtn: i18next.t('planInfo.free.modal.primaryBtn'),
        secondaryBtn: i18next.t('planInfo.free.modal.secondaryBtn'),
        ignoreBtn: i18next.t('planInfo.free.modal.ignoreBtn'),
        paperformTitles: {
          primary: i18next.t('planInfo.free.modal.paperform.title'),
          secondary: i18next.t('planInfo.free.modal.paperform.subtitle'),
        },
      }
    }
  }

  const getFeatureAdvantagesLabels = contentId => {
    const isPremiumPlan = userHasPremiumPlan()

    switch (contentId) {
      case 'addSupplierBtn':
        return {
          title: i18next.t('suppliers.addSupplierBtn.title'),
          details: isPremiumPlan
            ? i18next.t('suppliers.addSupplierBtn.supplierLimit', {
                count: getFeatureSubscription(userState, 'suppliers').limit,
              })
            : i18next.t('suppliers.addSupplierBtn.details'),
          listItems: [
            i18next.t('suppliers.addSupplierBtn.listItems.first'),
            i18next.t('suppliers.addSupplierBtn.listItems.second'),
            i18next.t('suppliers.addSupplierBtn.listItems.third'),
          ],
        }
      case 'addDomainBtn':
        return {
          title: i18next.t('sales.dns.addDomainBtn.title'),
          details: isPremiumPlan
            ? i18next.t('sales.dns.addDomainBtn.domainLimit', {
                count: getFeatureSubscription(userState, 'domains').limit,
              })
            : i18next.t('sales.dns.addDomainBtn.details'),
          listItems: [
            i18next.t('sales.dns.addDomainBtn.listItems.first'),
            i18next.t('sales.dns.addDomainBtn.listItems.second'),
            i18next.t('sales.dns.addDomainBtn.listItems.third'),
          ],
        }
      case 'addWebAppBtn':
        return {
          title: i18next.t('sales.web.addWebAppBtn.title'),
          details: i18next.t('sales.web.addWebAppBtn.details'),
          listItems: [
            i18next.t('sales.web.addWebAppBtn.listItems.first'),
            i18next.t('sales.web.addWebAppBtn.listItems.second'),
            i18next.t('sales.web.addWebAppBtn.listItems.third'),
          ],
        }
      case 'cloudIntegrationTutorialBtn':
        return {
          title: i18next.t('sales.cloud.cloudIntegrationTutorialBtn.title'),
          details: isPremiumPlan
            ? i18next.t(
                'sales.cloud.cloudIntegrationTutorialBtn.integrationLimit',
                {
                  count: getFeatureSubscription(userState, 'cloudIntegrations')
                    .limit,
                }
              )
            : i18next.t('sales.cloud.cloudIntegrationTutorialBtn.details'),
          listItems: [
            i18next.t(
              'sales.cloud.cloudIntegrationTutorialBtn.listItems.first'
            ),
            i18next.t(
              'sales.cloud.cloudIntegrationTutorialBtn.listItems.second'
            ),
            i18next.t(
              'sales.cloud.cloudIntegrationTutorialBtn.listItems.third'
            ),
          ],
        }
      case 'generateReportBtn':
        return {
          title: i18next.t('sales.reports.title'),
          details: i18next.t('sales.reports.details'),
          listItems: [
            i18next.t('sales.reports.listItems.first'),
            i18next.t('sales.reports.listItems.second'),
            i18next.t('sales.reports.listItems.third'),
          ],
        }
      case 'downloadCsvBtn':
        return {
          title: i18next.t('sales.csv.downloadCsvBtn.title'),
          details: i18next.t('sales.csv.downloadCsvBtn.details'),
          listItems: [
            i18next.t('sales.csv.downloadCsvBtn.listItems.first'),
            i18next.t('sales.csv.downloadCsvBtn.listItems.second'),
            i18next.t('sales.csv.downloadCsvBtn.listItems.third'),
          ],
        }
      case 'addWebhookBtn':
        return {
          title: i18next.t('sales.config.addWebhookBtn.title'),
          details: i18next.t('sales.config.addWebhookBtn.details'),
          listItems: [
            i18next.t('sales.config.addWebhookBtn.listItems.first'),
            i18next.t('sales.config.addWebhookBtn.listItems.second'),
            i18next.t('sales.config.addWebhookBtn.listItems.third'),
          ],
        }
      case 'addGoogleIdPBtn':
        return {
          title: i18next.t('sales.people.addIdPBtn.title'),
          details: isPremiumPlan
            ? i18next.t('sales.people.addIdPBtn.integrationLimit', {
                count: getFeatureSubscription(userState, 'idpIntegrations')
                  .limit,
              })
            : i18next.t('sales.people.addIdPBtn.google.details'),
          listItems: [
            i18next.t('sales.people.addIdPBtn.listItems.first'),
            i18next.t('sales.people.addIdPBtn.listItems.second'),
            i18next.t('sales.people.addIdPBtn.listItems.third'),
          ],
        }
      case 'add365IdPBtn':
        return {
          title: i18next.t('sales.people.addIdPBtn.title'),
          details: i18next.t('sales.people.addIdPBtn.microsoft365.details'),
          listItems: [
            i18next.t('sales.people.addIdPBtn.listItems.first'),
            i18next.t('sales.people.addIdPBtn.listItems.second'),
            i18next.t('sales.people.addIdPBtn.listItems.third'),
          ],
        }
      case 'securityScoreOverTime':
        return {
          title: i18next.t('sales.overview.securityScoreOverTime.title'),
          details: i18next.t('sales.overview.securityScoreOverTime.details'),
          listItems: [
            i18next.t('sales.overview.securityScoreOverTime.listItems.first'),
            i18next.t('sales.overview.securityScoreOverTime.listItems.second'),
            i18next.t('sales.overview.securityScoreOverTime.listItems.third'),
          ],
        }
      case 'findingsTable':
        return {
          title: i18next.t('sales.findings.detailSections.title'),
          details: i18next.t('sales.findings.detailSections.details'),
          listItems: [
            i18next.t('sales.findings.detailSections.listItems.first'),
            i18next.t('sales.findings.detailSections.listItems.second'),
            i18next.t('sales.findings.detailSections.listItems.third'),
          ],
        }
      case 'accordionFindings':
        return {
          title: i18next.t('sales.findings.detailSections.title'),
          details: i18next.t('sales.findings.detailSections.details'),
          listItems: [
            i18next.t('sales.findings.detailSections.listItems.first'),
            i18next.t('sales.findings.detailSections.listItems.second'),
            i18next.t('sales.findings.detailSections.listItems.third'),
          ],
        }
      case 'subdomainsAndDnsRecords':
        return {
          title: i18next.t(
            'sales.dns.detailSections.subdomainsAndDnsRecords.title'
          ),
          details: i18next.t(
            'sales.dns.detailSections.subdomainsAndDnsRecords.details'
          ),
          listItems: [
            i18next.t(
              'sales.dns.detailSections.subdomainsAndDnsRecords.listItems.first'
            ),
            i18next.t(
              'sales.dns.detailSections.subdomainsAndDnsRecords.listItems.second'
            ),
            i18next.t(
              'sales.dns.detailSections.subdomainsAndDnsRecords.listItems.third'
            ),
          ],
        }
      case 'accordionSubdomains':
        return {
          title: i18next.t(
            'sales.dns.detailSections.subdomainsAndDnsRecords.title'
          ),
          details: i18next.t(
            'sales.dns.detailSections.subdomainsAndDnsRecords.details'
          ),
          listItems: [
            i18next.t(
              'sales.dns.detailSections.subdomainsAndDnsRecords.listItems.first'
            ),
            i18next.t(
              'sales.dns.detailSections.subdomainsAndDnsRecords.listItems.second'
            ),
            i18next.t(
              'sales.dns.detailSections.subdomainsAndDnsRecords.listItems.third'
            ),
          ],
        }
      case 'accordionDnsRecords':
        return {
          title: i18next.t(
            'sales.dns.detailSections.subdomainsAndDnsRecords.title'
          ),
          details: i18next.t(
            'sales.dns.detailSections.subdomainsAndDnsRecords.details'
          ),
          listItems: [
            i18next.t(
              'sales.dns.detailSections.subdomainsAndDnsRecords.listItems.first'
            ),
            i18next.t(
              'sales.dns.detailSections.subdomainsAndDnsRecords.listItems.second'
            ),
            i18next.t(
              'sales.dns.detailSections.subdomainsAndDnsRecords.listItems.third'
            ),
          ],
        }

      case 'whatsImportant':
        return {
          title: i18next.t('sales.whatsImportant.title'),
          details: i18next.t('sales.whatsImportant.details'),
          listItems: [
            i18next.t('sales.whatsImportant.listItems.first'),
            i18next.t('sales.whatsImportant.listItems.second'),
            i18next.t('sales.whatsImportant.listItems.third'),
          ],
        }

      case 'totalClosedOverTime':
        return {
          title: i18next.t('sales.overview.totalClosedOverTime.title'),
          details: i18next.t('sales.overview.totalClosedOverTime.details'),
          listItems: [
            i18next.t('sales.overview.totalClosedOverTime.listItems.first'),
            i18next.t('sales.overview.totalClosedOverTime.listItems.second'),
            i18next.t('sales.overview.totalClosedOverTime.listItems.third'),
          ],
        }

      case 'auditLogs':
        return {
          title: i18next.t('sales.config.auditLogs.title'),
          details: i18next.t(i18next.t('sales.config.auditLogs.details')),
          listItems: [
            i18next.t('sales.config.auditLogs.listItems.first'),
            i18next.t('sales.config.auditLogs.listItems.second'),
            i18next.t('sales.config.auditLogs.listItems.third'),
          ],
        }

      case 'generateAnswerQuestionnaire':
        return {
          title: i18next.t('sales.generateAnswerQuestionnaire.title'),
          details: i18next.t(
            i18next.t('sales.generateAnswerQuestionnaire.details')
          ),
          listItems: [
            i18next.t('sales.generateAnswerQuestionnaire.listItems.first'),
            i18next.t('sales.generateAnswerQuestionnaire.listItems.second'),
            i18next.t('sales.generateAnswerQuestionnaire.listItems.third'),
          ],
        }

      default:
        return {
          title: '-----',
          featureName: '--',
          listItems: ['--', '--', '--'],
        }
    }
  }

  return {
    shouldShowPlanRenewalModal,
    shouldShowPlanInfoMenu,
    hasPlanRestriction,
    hasAccessToDocuments,
    planHasExpired,
    getPlanMenuLabels,
    getPlanModalLabels,
    getFeatureAdvantagesLabels,
    userHasPremiumPlan,
  }
}
