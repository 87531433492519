import React from 'react'
import PropTypes from 'prop-types'
import {borderStyle} from 'constant'
import Spinner from '../Spinner'
import PlaceholderContent from '../PlaceholderContent'
import PlanUtils from 'utils/plan'

export default function TableWrapper({
  id,
  button,
  customBorder,
  loaded,
  margin,
  padding,
  table,
  title,
  isPremiumFeature,
}) {
  const showPlaceholderContent =
    isPremiumFeature && PlanUtils().hasPlanRestriction()

  return (
    <div
      id={id}
      className={`${
        customBorder || borderStyle
      } ${margin} w-full bg-astral-50 text-astral-900`}>
      <div className="border-b p-4 flex">
        <div className="flex items-center dm-mono text-sideral-700 uppercase flex-1 font-medium">
          {title}
        </div>
        {button}
      </div>
      <div className="rounded-lg bg-white">
        {showPlaceholderContent ? (
          <PlaceholderContent sectionId={id} />
        ) : (
          <div className={`${padding || 'p-4'}`}>
            {loaded ? <div>{table}</div> : <Spinner />}
          </div>
        )}
      </div>
    </div>
  )
}

TableWrapper.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  customBorder: PropTypes.string,
  button: PropTypes.object,
  loaded: PropTypes.any,
  table: PropTypes.object,
  margin: PropTypes.string,
  padding: PropTypes.string,
  isPremiumFeature: PropTypes.bool,
}
