import React from 'react'
import PropTypes from 'prop-types'
import {i18next} from 'translate/i18n'

const VerticalEmptyState = ({icon}) => {
  return (
    <div className="flex items-center">
      <div className="flex flex-col items-center">
        {icon}
        <p className="text-gray-500 text-lg mt-2 text-sm">
          {i18next.t('tables.nothingFound')}
        </p>
      </div>
    </div>
  )
}

VerticalEmptyState.propTypes = {
  icon: PropTypes.element,
}

export default VerticalEmptyState
