import React from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'

const Alert = ({
  iconContent,
  content,
  btnText,
  btnRedirectLink,
  hideCloseIcon,
  showAlert,
  setShowAlert,
  style,
  contentWidth,
}) => {
  const onCloseAlert = () => {
    setShowAlert(false)
  }
  return (
    showAlert && (
      <div
        className={`flex justify-between text-sm py-2 px-4 mt-16 md:mt-0 md:ml-[80px] lg:ml-[256px] -mb-12 md:mb-0 ${style}`}>
        <div className="sm:flex items-center m-auto">
          <div className="flex items-center">
            {iconContent}
            <div className={`${contentWidth} mr-6`}>{content}</div>
          </div>

          {btnText && (
            <PrimaryButton
              text={btnText}
              theme="blue"
              size="full"
              margin="mt-3 sm:mt-0"
              redirect={btnRedirectLink}
              openNewTab
            />
          )}
        </div>

        {!hideCloseIcon && (
          <button onClick={onCloseAlert}>
            <i className="icofont-close text-xl ml-4 mr-2" />
          </button>
        )}
      </div>
    )
  )
}

Alert.propTypes = {
  iconContent: PropTypes.object,
  content: PropTypes.object,
  btnText: PropTypes.string,
  btnRedirectLink: PropTypes.string,
  hideCloseIcon: PropTypes.bool,
  showAlert: PropTypes.bool,
  setShowAlert: PropTypes.func,
  style: PropTypes.string,
  contentWidth: PropTypes.string,
}

export default Alert
