import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'

import {gcpPermissions, integrationStyles, borderStyle} from 'constant'
import config from 'config'
import images from 'res'

import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import Spinner from 'components/partials/Spinner'

import {fetchGcpIntegration, updateGcpIntegrationVersion} from 'actions'
import {Page} from 'components/partials/Page'
import BackButton from 'components/partials/buttons/BackButton'

export default function UpdateIntegration() {
  const csrfState = useSelector(state => state.csrf)
  const gcpIntegrationState = useSelector(state => state.gcpIntegration)

  const dispatch = useDispatch()
  const params = useParams()

  const [buttonIsLoading, setButtonAsLoading] = useState(null)

  const handleMainButton = () => {
    setButtonAsLoading(true)
    dispatch(
      updateGcpIntegrationVersion({
        integrationId: params.id,
        _csrf: csrfState,
      })
    )
  }

  useEffect(() => {
    dispatch(fetchGcpIntegration(params.id))
  }, [])

  useEffect(() => {
    if (!gcpIntegrationState) return

    if (
      gcpIntegrationState.id === params.id &&
      gcpIntegrationState.version === config.gcpIntegrationVersion
    )
      window.location.href = `/cloud/gcp/${gcpIntegrationState.projectId}`
  }, [gcpIntegrationState])

  //TODO: translate this component

  return (
    <Page pageTitle={i18next.t('pageTitles.cloud')}>
      {gcpIntegrationState ? (
        <div className="text-sideral-700 text-sm">
          <BackButton fallbackLink={'/cloud'} />

          <div className={`${borderStyle} bg-white p-6 mt-4`}>
            <div className="lg:w-8/12">
              <div className={integrationStyles.mainTitle}>
                {i18next.t('cloud.gcp.updateIntegration.updateYourIntegration')}
              </div>
              <div className="mt-6">
                <p className="mb-4 mt-2">
                  {i18next.t('cloud.gcp.updateIntegration.sinceLastUpdate')}
                </p>
                <ul className="list-disc ml-8 font-medium">
                  <li className="mb-1">cloudsql.instances.list</li>
                </ul>
              </div>
              <div className={integrationStyles.secondaryTitle}>
                {i18next.t('cloud.gcp.updateIntegration.whyDoingThis')}
              </div>
              <div className="">
                <p className="mb-4">
                  {i18next.t('cloud.gcp.updateIntegration.alwaysAddingNew')}
                  <a
                    href="mailto:support@unxpose.com"
                    className="text-sky-500 font-medium ml-1">
                    {i18next.t(
                      'cloud.gcp.manualIntegration.intro.supportEmail'
                    )}
                  </a>
                </p>
                <p className="mb-4">
                  {i18next.t('cloud.gcp.updateIntegration.stepByStep.title')}
                </p>
                <div className="mb-4">
                  <ul className="list-disc ml-8">
                    <li className="mb-1">
                      {i18next.t(
                        'cloud.gcp.updateIntegration.stepByStep.stepOne.title'
                      )}
                    </li>
                    <li className="mb-1">
                      {i18next.t(
                        'cloud.gcp.updateIntegration.stepByStep.stepTwo.title'
                      )}
                    </li>
                    <li className="mb-1">
                      {i18next.t(
                        'cloud.gcp.updateIntegration.stepByStep.stepThree.title'
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              {/* STEP ONE */}
              <div className={integrationStyles.secondaryTitle}>
                {i18next.t(
                  'cloud.gcp.updateIntegration.stepByStep.stepOne.title'
                )}
              </div>
              <div className="">
                <p>
                  {i18next.t(
                    'cloud.gcp.updateIntegration.stepByStep.stepOne.accessPortal'
                  )}
                  <a
                    className="text-sky-500 font-medium ml-1"
                    href="https://console.cloud.google.com"
                    target="_blank"
                    rel="noreferrer">
                    https://console.cloud.google.com
                  </a>
                  {i18next.t(
                    'cloud.gcp.updateIntegration.stepByStep.stepOne.clickDropDown'
                  )}
                </p>
                <figure>
                  <img src={images.gcp1} className={integrationStyles.image} />
                </figure>
                <p>
                  {i18next.t(
                    'cloud.gcp.updateIntegration.stepByStep.stepOne.afterDropDown'
                  )}
                </p>
                <figure>
                  <img src={images.gcp2} className={integrationStyles.image} />
                </figure>
              </div>
              {/* STEP TWO */}
              <div className={integrationStyles.secondaryTitle}>
                {i18next.t(
                  'cloud.gcp.updateIntegration.stepByStep.stepTwo.title'
                )}
              </div>
              <div className="">
                <p className="mb-4">
                  {i18next.t(
                    'cloud.gcp.manualIntegration.stepOne.onceYouSelected'
                  )}
                  <b className="mx-1">
                    {i18next.t(
                      'cloud.gcp.manualIntegration.stepOne.highlights.iamAdmin'
                    )}
                  </b>
                  {i18next.t('cloud.gcp.manualIntegration.stepOne.andThen')}
                  <b className="ml-1">
                    {i18next.t(
                      'cloud.gcp.manualIntegration.stepOne.highlights.roles'
                    )}
                    .
                  </b>
                </p>
                <p className="mb-4">
                  {i18next.t(
                    'cloud.gcp.updateIntegration.stepByStep.stepTwo.lookForRole'
                  )}
                  <b className="ml-1">Unxpose</b>.
                </p>
                <p className="mb-4">
                  {i18next.t(
                    'cloud.gcp.updateIntegration.stepByStep.stepTwo.accessRole'
                  )}
                  <b className="ml-1">
                    {i18next.t(
                      'cloud.gcp.updateIntegration.stepByStep.stepTwo.editRole'
                    )}
                  </b>
                  .
                </p>
                <p className="mb-4">
                  {i18next.t(
                    'cloud.gcp.updateIntegration.stepByStep.stepTwo.editionScreenClick'
                  )}
                  <b className="ml-1">
                    {i18next.t(
                      'cloud.gcp.updateIntegration.stepByStep.stepTwo.addPermissions'
                    )}
                  </b>{' '}
                  {i18next.t(
                    'cloud.gcp.updateIntegration.stepByStep.stepTwo.demonstration'
                  )}
                </p>
                <figure>
                  <img src={images.gcp4} className={integrationStyles.image} />
                </figure>
              </div>
              {i18next.t(
                'cloud.gcp.updateIntegration.stepByStep.stepTwo.verifyPermissions'
              )}
              <pre className={integrationStyles.codeTextArea}>
                {gcpPermissions.map(permission => {
                  return <div key={permission}>{permission}</div>
                })}
              </pre>
              {i18next.t(
                'cloud.gcp.updateIntegration.stepByStep.stepTwo.toAdd'
              )}
              <b>
                {i18next.t(
                  'cloud.gcp.updateIntegration.stepByStep.stepTwo.addButton'
                )}
              </b>
              .
              <figure>
                <img src={images.gcp5} className={integrationStyles.image} />
              </figure>
              {i18next.t(
                'cloud.gcp.updateIntegration.stepByStep.stepTwo.toFinish'
              )}
              <b>
                {i18next.t(
                  'cloud.gcp.updateIntegration.stepByStep.stepTwo.updateButton'
                )}
              </b>
              .
            </div>

            {/* STEP THREE */}
            <div className={integrationStyles.secondaryTitle}>
              {i18next.t(
                'cloud.gcp.updateIntegration.stepByStep.stepThree.title'
              )}
            </div>

            <p>
              {i18next.t('cloud.gcp.manualIntegration.theEnd.congratulations')}
            </p>
            <div className="w-full mt-10 md:w-64 md:ml-auto">
              <PrimaryButton
                loading={buttonIsLoading}
                onClick={handleMainButton}
                size="full"
                text={'Finalizei a atualização'}
                theme="blue"
                type="submit"
              />
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </Page>
  )
}

UpdateIntegration.propTypes = {
  history: PropTypes.object,
}
