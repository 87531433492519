import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {i18next} from 'translate/i18n'
import {Trans} from 'react-i18next'
import PropTypes from 'prop-types'
import {useParams} from 'react-router-dom'
import moment from 'moment'

import {fetchInviteInfo, acceptInvite, loading} from 'actions'

import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import Spinner from 'components/partials/Spinner'
import {Page} from 'components/partials/Page'
import Logo from 'res/brand/logo.svg'
import {isLoading} from 'utils'

const AcceptInvite = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const [validInvite, setInviteValidity] = useState()
  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const errorState = useSelector(state => state.error)
  const inviteInfoState = useSelector(state => state.inviteInfo)

  const invitationId = params.id

  useEffect(() => {
    dispatch(loading({inviteInfo: true}))
    dispatch(fetchInviteInfo({invitationId}))
  }, [])

  useEffect(() => {
    if (inviteInfoState) setInviteValidity(true)
  }, [inviteInfoState])

  const handleAcceptClick = () => {
    dispatch(loading({acceptInvite: true}))
    dispatch(acceptInvite({_csrf: csrfState, invitationId}))
  }

  const getRemainingDays = expirationDate => {
    const todayDateTime = moment()
    const expirationDateTime = moment(expirationDate)

    return expirationDateTime.diff(todayDateTime, 'days') + 1
  }

  return (
    <Page pageTitle={i18next.t('invitationPage.pageTitle')} hideHeader>
      <div className="roboto fixed right-0 flex flex-col items-center bg-white text-sideral-700 lg:max-w-xl w-full h-full py-12 overflow-auto z-50 md:px-24">
        <div className="font-light mb-10 text-center uppercase text-4xl text-gray-600">
          <img
            src={Logo}
            alt="Unxpose"
            className="mx-auto cursor-pointer w-56"
          />
        </div>

        <div className="w-full">
          {!isLoading(loadingState.inviteInfo) || errorState ? (
            validInvite ? (
              <>
                <div className="header">
                  <div className="dm-mono text-sideral-700 font-bold text-center">
                    {i18next.t('invitationPage.title')}
                  </div>
                </div>

                <div className="body my-5">
                  <Trans
                    i18nKey="invitationPage.body"
                    values={{
                      userName: inviteInfoState.invitedBy,
                      companyName: inviteInfoState.invitedTo,
                    }}
                  />

                  <div className="expiration-info mt-3">
                    <Trans
                      i18nKey="invitationPage.expirationInfo"
                      values={{
                        remainingDays: getRemainingDays(
                          inviteInfoState.expiresAt
                        ),
                      }}
                    />
                  </div>
                </div>

                <PrimaryButton
                  text={i18next.t('invitationPage.acceptInvite')}
                  margin="mt-8"
                  size="full"
                  onClick={handleAcceptClick}
                  loading={isLoading(loadingState.acceptInvite, false)}
                />
              </>
            ) : (
              <div className="text-center">
                {i18next.t('errors.invalidOrExpiredInvite')}
              </div>
            )
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </Page>
  )
}

AcceptInvite.propTypes = {
  history: PropTypes.object,
}

export default AcceptInvite
