import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {i18next} from 'translate/i18n'
import {v4 as uuidv4} from 'uuid'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'

import {
  fetchBreaches,
  fetchCompanyStats,
  fetchBreachedAccounts,
  loading,
} from 'actions'
import {sideral300, tooltipStyle} from 'constant'
import questionMark from 'res/icons/questionMark.svg'
import images from 'res'

import Spinner from 'components/partials/Spinner'
import BackButton from 'components/partials/buttons/BackButton'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import {Page} from 'components/partials/Page'
import {isLoading} from 'utils'

export default function Breach() {
  const userState = useSelector(state => state.user)
  const loadingState = useSelector(state => state.loading)
  const breachesState = useSelector(state => state.breaches)
  const breachedAccountsState = useSelector(state => state.breachedAccounts)

  const dispatch = useDispatch()
  const params = useParams()

  const [accounts, setAccounts] = useState(null)
  const [breach, setBreach] = useState(null)
  const [logoError, setLogoError] = useState(false)

  useEffect(() => {
    if (userState && userState.company) {
      dispatch(fetchBreaches())
      dispatch(loading({breachedAccounts: true}))
      dispatch(fetchBreachedAccounts(params.id))
      dispatch(
        fetchCompanyStats({companyId: userState.company.id, type: 'people'})
      )
    }
  }, [dispatch, userState, params.id])

  useEffect(() => {
    if (!breachesState) return () => {}

    breachesState.forEach(breach => {
      if (breach.breachId === params.id) setBreach(breach)
    })

    setAccounts(breachedAccountsState)
  }, [breachesState, breachedAccountsState, params.id])

  if (!accounts) return <Spinner />

  return (
    <Page pageTitle={i18next.t('pageTitles.people')} helpPath="people">
      {!breach ||
      (breachedAccountsState &&
        breachedAccountsState[0] &&
        breachedAccountsState[0].breachId !== params.id) ? (
        <div className="p-4">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="border border-sideral-100 rounded-lg bg-white text-sideral-700 p-8 mt-6">
            <div className="flex">
              {breach.logo ? (
                <figure
                  style={{maxWidth: '128px'}}
                  className="flex items-center">
                  {logoError ? (
                    <i className="icofont-database text-5xl text-sideral-300 mx-3" />
                  ) : (
                    <img
                      src={breach.logo}
                      style={{
                        maxHeight: '60px',
                      }}
                      alt={breach.name}
                      className="w-full"
                      onError={() => setLogoError(true)}
                    />
                  )}
                </figure>
              ) : (
                <div className="text-center">
                  <i className="icofont-database text-6xl text-gray-300 mx-3"></i>
                </div>
              )}
              <div className="pl-6">
                <BackButton fallbackLink="/people" />
                <div className="flex flex-wrap items-center pt-2">
                  <h1 className="dm-mono text-lg mr-4">
                    {breach.fullName} ({breach.year})
                  </h1>
                  {breach.data &&
                    breach.data.map(feature => {
                      return (
                        <div
                          key={uuidv4()}
                          className="inline-flex lowercase rounded-full text-sm text-sideral-400 font-light border border-sideral-100 mr-4 px-4">
                          {feature}
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
            <div className="text-sideral-700 mt-10">
              <h2 className="dm-mono">{i18next.t('subtitles.description')}</h2>
            </div>
            <div
              className="breach-description font-light text-sideral-700 mt-4"
              dangerouslySetInnerHTML={{
                __html: breach.description,
              }}></div>
          </div>

          <div className="rounded-lg border border-sideral-100 bg-astral-50 text-astral-900 mt-8">
            <div className="border-b border-sideral-100 p-4 flex">
              <div className="flex items-center">
                <span className="dm-mono text-sideral-700 uppercase font-semibold">
                  {i18next.t('people.usersInBreach')}
                </span>
                <span className="tooltip">
                  <img src={questionMark} className="w-4 h-4 opacity-75 ml-2" />
                  <span className={`${tooltipStyle.default} ml-8 -mt-5`}>
                    {i18next.t('labels.usersNotShown')}
                  </span>
                </span>
              </div>
            </div>
            <div className="rounded-lg bg-white p-4">
              {accounts ? (
                <table className="w-full text-sm">
                  <tbody className="rounded-lg">
                    {accounts.length &&
                    !isLoading(loadingState.breachedAccounts) ? (
                      accounts.map(email => {
                        return (
                          <tr
                            key={uuidv4()}
                            className="border-b last:border-b-0 border-sideral-50 p-2">
                            <td className=" hover:bg-sideral-50 py-2 px-4">
                              {email.email}
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td>
                          <GenericEmptyState
                            icon={
                              <images.WarningIcon
                                width="55"
                                color={sideral300}
                              />
                            }
                            title={i18next.t(
                              'warnings.insufficientInformation'
                            )}
                            body={i18next.t('subtitles.unknownBreachedEmails')}
                            margin="m-2"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </>
      )}
    </Page>
  )
}

Breach.propTypes = {
  history: PropTypes.object,
}
