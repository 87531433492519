import {
  issueCategoryList,
  severityList,
  statusList,
  cloudAccountsList,
  assignedToList,
  assignmentList,
  domainList,
} from 'constant'

export function formatFindingsFilterOptionsFromUrl(
  location,
  usersList,
  domains
) {
  const filtersFromUrl = getFiltersFromUrl(
    location,
    getManagementFiltersMap,
    usersList,
    domains
  )

  const transformedObject = {}

  if (!filtersFromUrl) {
    return transformedObject
  }

  filtersFromUrl.forEach(item => {
    if (Array.isArray(item.value)) {
      transformedObject[item.id] = item.value.map(value => value.value)
      return
    }

    if (typeof item.value === 'string') {
      transformedObject[item.id] = [item.value]
      return
    }

    transformedObject[item.id] = [item.value.value]
  })

  return transformedObject
}

export function getFiltersFromUrl(location, getFilterMap, usersList, domains) {
  const params = new URLSearchParams(location.search)
  const result = []

  for (const [filterId, value] of params.entries()) {
    if (
      !value ||
      filterId === 'sort' ||
      filterId === 'limit' ||
      filterId === 'page' ||
      filterId === 'global'
    ) {
      continue
    }

    const filterValue = {}

    const filterMap = getFilterMap(filterId)

    if (filterMap) {
      filterValue.id = filterId
      filterValue.value = getSelectInputValues(
        value,
        filterMap,
        filterId,
        usersList,
        domains
      )
    } else return

    result.push(filterValue)
  }

  return result
}

export function getPageFromUrl(url) {
  const params = new URLSearchParams(url.search)
  const result = params.has('page') ? parseInt(params.get('page'), 10) : 0

  return result
}

export function getFilterFromUrl(filterName, url) {
  const params = new URLSearchParams(url.search)
  const result = params.has(filterName) ? params.get(filterName) : ''

  return result
}

export function getSortFromUrl(url, defaultSort) {
  const params = new URLSearchParams(url.search)
  const sort = []
  if (!params.has('sort')) {
    sort.push(getSortValue(defaultSort))
    return sort
  }

  sort.push(getSortValue(params.get('sort')))

  return sort
}

export function getManagementFiltersMap(filterType) {
  let filterMap = null

  switch (filterType) {
    case 'status':
      filterMap = statusList
      break
    case 'type':
      filterMap = issueCategoryList
      break
    case 'severity':
      filterMap = severityList
      break
    case 'cloudAccount':
      filterMap = cloudAccountsList
      break
    case 'assignedTo':
      filterMap = assignedToList
      break
    case 'assignment':
      filterMap = assignmentList
      break
    case 'domainId':
      filterMap = domainList
      break
    default:
      filterMap = {}
  }

  return filterMap
}

export function getLgpdFilterMap(filterType) {
  let filterMap = null

  switch (filterType) {
    case 'status':
      filterMap = statusList
      break
    default:
      filterMap = null
  }

  return filterMap
}

function getSelectInputValues(value, filterMap, filterId, usersList, domains) {
  if (!value || !value.length) return

  if (filterId === 'assignment') {
    const singleSelectValue = {value, label: filterMap[value]}
    return singleSelectValue
  }

  const arr = value.split(',')

  const selectInputValues = arr.map(item => {
    if (filterId === 'assignedTo') {
      const output = {value: item}

      if (!Array.isArray(usersList)) return output

      const user = usersList.find(user => user.id === item)

      if (user) {
        output.label = `${user.firstName} ${user.lastName}`
      }

      return output
    }

    if (filterId === 'domainId') {
      const output = {value: item}
      if (!Array.isArray(domains)) return output

      const domain = domains.find(domain => domain.id === item)

      if (domain) {
        output.label = domain.hostname
      }

      return output
    }

    if (filterMap.cloud) return {value: item, label: item}
    return {value: item, label: filterMap[item]}
  })

  return selectInputValues
}

function getSortValue(value) {
  const [id, direction] = value.split(':')
  const bool = direction === 'desc' ? true : false
  return {id, desc: bool}
}
