import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import Moment from 'react-moment'
import {sky500} from 'constant'

import {fetchQuestionnaireDetails, toggleRestrictionModal} from 'actions'
import {
  generateQuestionnaireCSV,
  getQuestionnaireSupplierRules,
} from './questionnairesUtils'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import QuestionnaireEditModal from './modals/questionnaire/QuestionnaireEditModal'
import QuestionnaireRemoveModal from './modals/questionnaire/QuestionnaireRemoveModal'
import EditIcon from 'res/icons/editIcon'
import TrashIcon from 'res/icons/trashIcon'
import Spinner from 'components/partials/Spinner'
import QuestionnaireSubmitModal from './modals/questionnaire/QuestionnaireSubmitModal'
import DownloadIcon from 'res/icons/downloadIcon'
import DropdownButton from 'components/partials/buttons/DropdownButton'
import EllipsisIcon from 'res/icons/ellipsisIcon'
import QuestionnaireStatusBadge from './QuestionnaireStatusBadge'
import PlanUtils from 'utils/plan'

export default function QuestionnairesList() {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const questionnairesState = useSelector(state => state.questionnaires)

  const [submitQuestionnaireModal, setSubmitQuestionnaireModal] = useState({
    selectedQuestionnaire: null,
    showModal: false,
  })

  const [editQuestionnaireModal, setEditQuestionnaireModal] = useState({
    selectedQuestionnaire: null,
    showModal: false,
  })

  const [removeQuestionnaireModal, setRemoveQuestionnaireModal] = useState({
    selectedQuestionnaire: null,
    showModal: false,
  })

  const planUtils = PlanUtils()

  const handleGenerateCSV = questionnaireId => {
    if (planUtils.hasPlanRestriction()) {
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )
    }

    dispatch(
      fetchQuestionnaireDetails({
        questionnaireId,
        onSuccess: data => generateQuestionnaireCSV(userState, data),
      })
    )
  }

  const handleDisplaySubmitModal = ({id, name, companyName, type}) => {
    setSubmitQuestionnaireModal({
      selectedQuestionnaire: {id, name, companyName, type},
      showModal: true,
    })
  }

  const handleDisplayEditModal = ({id, name, description, submittedAt}) => {
    setEditQuestionnaireModal({
      selectedQuestionnaire: {id, name, description, submittedAt},
      showModal: true,
    })
  }

  const handleDisplayRemovalModal = ({id, name}) => {
    setRemoveQuestionnaireModal({
      selectedQuestionnaire: {id, name},
      showModal: true,
    })
  }

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: i18next.t('questionnaires.tables.name'),
        minSize: 250,
        meta: {
          align: 'left',
        },
        cell: cell => (
          <>
            <div>{cell.getValue()}</div>
            {cell.row.original.type === 'received' && (
              <div className="text-xs text-sideral-400">
                {i18next.t('questionnaires.tables.sentBy', {
                  companyName: cell.row.original.companyName,
                })}
              </div>
            )}
          </>
        ),
      },

      {
        id: 'questionsCount',
        accessorKey: 'questionsCount',
        header: i18next.t('questionnaires.tables.questionsNumber'),
        disableGlobalFilter: true,
      },

      {
        id: 'progress',
        accessorKey: 'progress',
        header: i18next.t('questionnaires.tables.progress'),
        cell: cell => (
          <div className="m-auto w-20">
            {cell.getValue()}
            <div className="h-2 bg-gray-500 rounded-sm mt-1">
              <div
                className="h-2 bg-sky-500"
                style={{width: cell.getValue()}}
              />
            </div>
          </div>
        ),
        disableGlobalFilter: true,
      },

      {
        id: 'latestAnswer',
        accessorKey: 'latestAnswer',
        header: i18next.t('questionnaires.tables.modifiedAt'),
        sortingFn: (a, b) =>
          new Date(a.original.latestAnswer) - new Date(b.original.latestAnswer),
        cell: cell => {
          const firstName = cell.row.original.updatedBy.firstName
          const lastName = cell.row.original.updatedBy.lastName

          const fullName = `${firstName} ${lastName || ''}`

          return (
            <>
              <div>
                <Moment fromNow>{cell.getValue()}</Moment>
              </div>
              <div className="text-xs text-sideral-300">
                {i18next.t('questionnaires.tables.modifiedBy', {
                  username: fullName,
                })}
              </div>
            </>
          )
        },
        disableGlobalFilter: true,
      },

      {
        id: 'status',
        accessorKey: 'status',
        header: i18next.t('questionnaires.tables.status'),
        cell: cell => (
          <QuestionnaireStatusBadge
            status={cell.getValue()}
            changedAt={cell.row.original.statusChangedAt}
            isAnUploadedQuestionnaire={cell.row.original.type === 'uploaded'}
            isFromSupplier
          />
        ),
      },

      {
        id: 'actionButtons',
        accessorKey: 'id',
        header: '',
        size: 5,
        cell: cell => {
          const questionnaireRules = getQuestionnaireSupplierRules(
            cell.row.original.status
          )

          return (
            <DropdownButton
              label={<EllipsisIcon width="20" />}
              buttonTheme="icon-only"
              items={[
                {
                  name: i18next.t('questionnaires.submit.submitBtn'),
                  icon: <i className="icofont-arrow-up text-sky-500 text-lg" />,
                  disabled:
                    cell.row.original.progress !== '100%' ||
                    !questionnaireRules.showSubmitBtn,
                  onClick: () =>
                    handleDisplaySubmitModal({
                      id: cell.row.original.id,
                      name: cell.row.original.name,
                      companyName: cell.row.original.companyName,
                      type: cell.row.original.type,
                    }),
                },
                {
                  name: i18next.t('questionnaires.edit.title'),
                  icon: <EditIcon height="20" width="20" color={sky500} />,
                  disabled: cell.row.original.type === 'received',
                  onClick: () => {
                    const {name, description, status, statusChangedAt} =
                      cell.row.original

                    handleDisplayEditModal({
                      id: cell.getValue(),
                      name,
                      description,
                      submittedAt:
                        status === 'submitted' ? statusChangedAt : null,
                    })
                  },
                },
                {
                  name: i18next.t('buttons.downloadCSV'),
                  icon: <DownloadIcon height="22" width="22" color={sky500} />,
                  onClick: () => {
                    handleGenerateCSV(cell.getValue())
                  },
                },
                {
                  name: i18next.t('misc.remove'),
                  icon: <TrashIcon height="20" width="20" color={sky500} />,
                  disabled: cell.row.original.type === 'received',
                  onClick: () => {
                    handleDisplayRemovalModal({
                      id: cell.getValue(),
                      name: cell.row.original.name,
                    })
                  },
                },
              ]}
            />
          )
        },
        disableGlobalFilter: true,
        isButton: true,
      },
    ],
    []
  )

  const getRowStyle = row => {
    if (row.original.status === 'changesRequested')
      return 'bg-orange-100 hover:!bg-orange-200'
  }

  if (!questionnairesState) return <Spinner />

  return (
    <div className="p-6">
      <PrimaryTableV8
        columns={columns}
        data={questionnairesState}
        detailPath={row => `/questionnaires/${row.original.id}`}
        getRowStyle={getRowStyle}
        emptyStateWithFilter={
          <GenericEmptyState
            icon={<i className={`icofont-list text-6xl text-sideral-300`} />}
            title={i18next.t('tables.nothingFoundWithSearch')}
            body={i18next.t('tables.searchSomethingElse')}
          />
        }
        emptyStateWithoutFilter={
          <GenericEmptyState
            icon={<i className={`icofont-list text-6xl text-sideral-300`} />}
            title={i18next.t('questionnaires.emptyState.title')}
            body={i18next.t('questionnaires.emptyState.body')}
          />
        }
      />

      {submitQuestionnaireModal.showModal && (
        <QuestionnaireSubmitModal
          submitQuestionnaireModal={submitQuestionnaireModal}
          setSubmitQuestionnaireModal={setSubmitQuestionnaireModal}
        />
      )}

      {editQuestionnaireModal.showModal && (
        <QuestionnaireEditModal
          editQuestionnaireModal={editQuestionnaireModal}
          setEditQuestionnaireModal={setEditQuestionnaireModal}
        />
      )}

      {removeQuestionnaireModal.showModal && (
        <QuestionnaireRemoveModal
          removeQuestionnaireModal={removeQuestionnaireModal}
          setRemoveQuestionnaireModal={setRemoveQuestionnaireModal}
        />
      )}
    </div>
  )
}
