import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {newFindingsAlert, tooltipStyle} from 'constant'
import {addWebhook, updateNotificationSettings, fetchWebhooks} from 'actions'
import {useSelector, useDispatch} from 'react-redux'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import WarningIcon from 'res/icons/warningIcon'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {i18next} from 'translate/i18n'
import PlanUtils from 'utils/plan'
import images from 'res'
import Subsection from 'components/partials/headers/Subsection'

const Notifications = () => {
  const planUtils = PlanUtils()

  const dispatch = useDispatch()
  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)
  const webhooksState = useSelector(state => state.webhooks)

  const [slackForm, setSlackForm] = useState({
    condition: 'highOrCritical',
  })
  const [prev, setPrev] = useState(null)
  const [slackNotification, setSlackNotification] = useState(false)
  const [notifications, setNotifications] = useState({
    weekly: false,
    email: {
      condition: '',
      enabled: false,
    },
  })

  const handleSubmit = e => {
    e.preventDefault()

    if (userState?.role === 'read-only') return

    dispatch(
      addWebhook({
        ...slackForm,
        name: 'Slack Notification',
        _csrf: csrfState,
      })
    )
    setSlackForm({})
    document.documentElement.scrollTop = 0
  }

  const handleSelectChange = (options, event) => {
    const {value} = options
    const {name} = event

    if (name === 'email.condition')
      setNotifications({
        ...notifications,
        email: {enabled: true, condition: value},
      })

    if (name === 'condition') setSlackForm({...slackForm, [name]: value})
  }

  const handleSelectDefaultValue = () => {
    const index = newFindingsAlert.findIndex(item => {
      return userState.notifications[userState.companyId] &&
        userState.notifications[userState.companyId].email
        ? item.value ===
            userState.notifications[userState.companyId].email
              .condition
        : item.value === 'highOrCritical'
    })

    return newFindingsAlert[index]
  }

  const handleSlackNotificationInputChange = e => {
    const {name, value} = e.target
    setSlackForm({...slackForm, [name]: value})
  }

  const handleInputChange = e => {
    const {name, checked, value} = e.target

    if (name.match(/\w+\.\w+/)) {
      const [key, prop] = name.split('.')
      const object = {...notifications}

      if (name === 'email.enabled')
        object.email = {
          enabled: checked,
          condition: 'highOrCritical',
        }
      else object[key][prop] = checked || value

      setNotifications(object)
      return
    }

    if (e.target.type === 'checkbox') {
      setNotifications({...notifications, [name]: checked})
      return
    }
  }

  useEffect(() => {
    if (prev && JSON.stringify(prev) !== JSON.stringify(notifications)) {
      dispatch(updateNotificationSettings({...notifications, _csrf: csrfState}))
      setPrev({
        ...notifications,
        email: {...notifications.email},
      })
    }
  }, [dispatch, notifications])

  useEffect(() => {
    if (!userState || prev) return

    const userNotifications =
      userState.notifications[userState.companyId] || {}

    const object = {
      weekly: !!userNotifications.weekly,
      email: {
        condition:
          (userNotifications.email && userNotifications.email.condition) ||
          false,
        enabled:
          (userNotifications.email && userNotifications.email.condition) ||
          false,
      },
    }
    setPrev({
      ...object,
      email: {...object.email},
    })
    setNotifications({
      ...object,
      email: {...object.email},
    })
  }, [userState])

  useEffect(() => {
    if (webhooksState) {
      const notification = webhooksState.find(
        x => x.name === 'Slack Notification'
      )

      if (notification) setSlackNotification(notification)
    }
  }, [webhooksState])

  useEffect(() => {
    if (!planUtils.hasPlanRestriction()) {
      dispatch(fetchWebhooks())
    }
  }, [dispatch])

  return (
    <>
      <Subsection
        title="Email"
        content={
          <div className="flex border-b border-sideral-50 p-8 px-6">
            <div className="hidden sm:block w-4/12">
              <p className="text-sm text-sideral-900">
                {i18next.t('titles.setUpPreferences')}
              </p>
              <p className="text-light text-xs text-sideral-300 pt-1">
                {i18next.t('subtitles.setUpPreferences')}
              </p>
            </div>

            <div className="flex flex-wrap w-full lg:w-5/12 ml-0 md:ml-8 lg:ml-0">
              <div className="flex w-full border border-sideral-100 rounded-md p-4">
                <div className="tooltip">
                  <input
                    name="weekly"
                    id="notifications-weekly"
                    className="switch switch--shadow"
                    type="checkbox"
                    checked={notifications.weekly}
                    onChange={handleInputChange}
                    disabled={
                      planUtils.hasPlanRestriction() ||
                      userState?.role === 'read-only'
                    }
                  />
                  <label htmlFor="notifications-weekly"></label>
                  {planUtils.hasPlanRestriction() && (
                    <span
                      className={`${tooltipStyle.default} ml-5 -mt-12 text-xs`}>
                      {i18next.t('sales.notAvailable')}
                    </span>
                  )}
                </div>
                <div className="w-10/12 text-sideral-700 px-6">
                  <p className="font-normal leading-none">
                    {i18next.t('labels.weeklyReport')}
                  </p>
                  <p className="text-xs font-light pt-2">
                    {i18next.t('checkboxes.weeklyReport')}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap w-full border border-sideral-100 rounded-md p-4 mt-8">
                <div className="flex flex-nowrap">
                  <div className="tooltip">
                    <input
                      name="email.enabled"
                      id="notifications-email-high"
                      type="checkbox"
                      className="switch switch--shadow flex flex-shrink-0"
                      checked={
                        notifications.email && notifications.email.enabled
                      }
                      onChange={handleInputChange}
                      disabled={
                        planUtils.hasPlanRestriction() ||
                        userState?.role === 'read-only'
                      }
                    />
                    <label htmlFor="notifications-email-high"></label>
                    {planUtils.hasPlanRestriction() && (
                      <span
                        className={`${tooltipStyle.default} ml-5 -mt-12 text-xs`}>
                        {i18next.t('sales.notAvailable')}
                      </span>
                    )}
                  </div>
                  <div className="w-10/12 text-sideral-700 px-6">
                    {i18next.t('labels.newFindings')}

                    <p className="text-xs font-light pt-2">
                      {i18next.t('checkboxes.newFindings')}
                    </p>
                  </div>
                </div>

                {notifications.email.enabled ? (
                  <div className="w-full mt-4">
                    <PrimarySelect
                      onChange={handleSelectChange}
                      defaultValue={handleSelectDefaultValue()}
                      name="email.condition"
                      placeholder={i18next.t(
                        'settings.notifications.placeholders.select'
                      )}
                      options={newFindingsAlert}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        }
      />

      <Subsection
        title="Slack"
        margin="mt-10"
        content={
          <div className="flex p-8 px-6">
            <div className="hidden sm:block w-4/12">
              <p className="text-sm text-sideral-900">
                {i18next.t('titles.setUpSlack')}
              </p>
              <p className="text-light text-xs text-sideral-300 pt-1">
                {i18next.t('subtitles.setUpSlack')}
              </p>
            </div>
            {!slackNotification ? (
              <div className="flex flex-wrap text-sideral-700 font-light w-full lg:w-8/12 ml-0 md:ml-8 lg:ml-0">
                <p className="text-sm">
                  {i18next.t('settings.notifications.slack.notifications')}
                </p>
                <ol>
                  <li className="flex items-center border-b border-sideral-50 py-8">
                    <div className="flex items-center justify-center w-16 h-16 rounded-md dm-mono font-normal text-4xl text-sky-900 bg-sky-50">
                      1
                    </div>
                    <div className="w-11/12 ml-6">
                      <h3 className="font-medium text-sky-900">
                        {i18next.t(
                          'settings.notifications.slack.stepOneHeader'
                        )}
                      </h3>
                      <p className="text-sm">
                        {i18next.t('settings.notifications.slack.stepOneBody')}
                      </p>
                    </div>
                  </li>
                  <li className="flex items-center border-b border-sideral-50 py-8">
                    <div className="flex items-center justify-center w-16 h-16 rounded-md dm-mono font-normal text-4xl text-sky-900 bg-sky-50">
                      2
                    </div>
                    <div className="w-11/12 ml-6">
                      <h3 className="font-medium text-sky-900">
                        {i18next.t(
                          'settings.notifications.slack.stepTwoHeader'
                        )}
                      </h3>
                      <p className="text-sm">
                        {i18next.t('settings.notifications.slack.stepTwoBody')}
                      </p>
                    </div>
                  </li>
                  <li className="flex flex-wrap items-center border-b border-sideral-50 py-8">
                    <div className="flex flex-nowrap">
                      <div className="flex items-center justify-center w-16 h-16 rounded-md dm-mono font-normal text-4xl text-sky-900 bg-sky-50">
                        3
                      </div>
                      <div className="w-11/12 ml-6">
                        <h3 className="font-medium text-sky-900">
                          {i18next.t(
                            'settings.notifications.slack.stepThreeHeader'
                          )}
                        </h3>
                        <p className="text-sm">
                          {i18next.t(
                            'settings.notifications.slack.stepThreeBody'
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="flex text-sm w-full border border-mars-700 rounded-md bg-coral-50 p-4 mt-8">
                      <figure className="flex flex-shrink-0">
                        <WarningIcon width="40" color="#E20026" />
                      </figure>
                      <div className="ml-4">
                        <h3 className="uppercase dm-mono font-normal text-mars-700 pb-2">
                          {i18next.t('warnings.warning')}
                        </h3>
                        <p>{i18next.t('warnings.keepWebhookSecret')}</p>
                      </div>
                    </div>
                  </li>
                  <li className="flex items-center py-8">
                    <div className="flex items-center justify-center w-16 h-16 rounded-md dm-mono font-normal text-4xl text-sky-900 bg-sky-50">
                      4
                    </div>
                    <div className="w-11/12 ml-6">
                      <h3 className="font-medium text-sky-900">
                        {i18next.t(
                          'settings.notifications.slack.stepFourHeader'
                        )}
                      </h3>
                      <p className="text-sm">
                        {i18next.t('settings.notifications.slack.stepFourBody')}
                      </p>
                    </div>
                  </li>
                  <form
                    onSubmit={handleSubmit}
                    className="w-11/12 ml-auto px-6">
                    <PrimaryInput
                      text="URL"
                      value={slackForm.url || ''}
                      name="url"
                      placeholder="https://hooks.slack.com/services/T00000/B00000/XXXXX"
                      type="text"
                      pattern="https:\/\/hooks.slack.com\/services\/.+"
                      required={true}
                      onChange={handleSlackNotificationInputChange}
                      margin="mb-8"
                      disabled={
                        planUtils.hasPlanRestriction() ||
                        userState?.role === 'read-only'
                      }
                    />

                    <PrimarySelect
                      text={i18next.t('labels.condition')}
                      onChange={handleSelectChange}
                      defaultValue={newFindingsAlert.find(item => {
                        return item.value === slackForm.condition
                      })}
                      name="condition"
                      options={newFindingsAlert}
                      disabled={
                        planUtils.hasPlanRestriction() ||
                        userState?.role === 'read-only'
                      }
                    />
                    <div className="flex items-start self-end mt-6">
                      {planUtils.hasPlanRestriction() && (
                        <div className="flex items-center">
                          <images.InfoIcon width="20" />
                          <p className="text-xs font-medium ml-1">
                            {i18next.t('sales.notAvailable')}
                          </p>
                        </div>
                      )}

                      <PrimaryButton
                        text={i18next.t('buttons.slackNotifications')}
                        type="submit"
                        margin="ml-auto"
                        disabled={
                          planUtils.hasPlanRestriction() ||
                          userState?.role === 'read-only'
                        }
                      />
                    </div>
                  </form>
                </ol>
              </div>
            ) : (
              <div className="w-9/12">
                <p className="text-sm text-center">
                  {i18next.t(
                    'settings.notifications.slack.webhookAlreadyConfigured1'
                  )}
                  <Link
                    to="/settings/settings"
                    className="text-indigo-600 mx-1">
                    webhook
                  </Link>
                  {i18next.t(
                    'settings.notifications.slack.webhookAlreadyConfigured2'
                  )}
                </p>

                <p className="text-sm text-center pt-8">
                  {i18next.t('settings.notifications.slack.toDisableSlack1')}
                  <a
                    href="mailto:support@unxpose.com"
                    className="ml-1 text-indigo-600">
                    support@unxpose.com
                  </a>
                  {i18next.t('settings.notifications.slack.toDisableSlack2')}
                </p>
              </div>
            )}
          </div>
        }
      />
    </>
  )
}

export default Notifications
