import PropTypes from 'prop-types'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {v4 as uuidv4} from 'uuid'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

export default function HostsByAsOrgs(props) {
  const navigate = useNavigate()

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const {getTableProps, getTableBodyProps, headerGroups, prepareRow, page} =
    useTable(
      {
        columns: props.columns,
        data: props.data,
        defaultColumn,
        initialState: {
          pageIndex: props.pageNumber,
          pageSize: props.pageSize || 5,
          sortBy: props.defaultSorted || [],
        },
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      useFlexLayout,
      usePagination
    )

  const handleRowClick = asOrg => {
    navigate(`/network?asOrg=${asOrg}`)
  }

  return (
    <div>
      <div className=" max-h-[200px] menu-scrollbar overflow-y-auto tableWrap relative">
        {page && page.length ? (
          <table {...getTableProps()} className="text-sm w-full">
            <thead>
              {headerGroups.map(headerGroup => {
                // trick to disable the first headerGroup
                if (headerGroup.headers.length === 1)
                  return <tr key={uuidv4()}></tr>

                return (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={uuidv4()}
                    className="py-2">
                    {headerGroup.headers.map(column => (
                      <th
                        className={
                          column.headerClassName
                            ? column.headerClassName
                            : 'dm-mono text-sideral-300 text-sm'
                        }
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={uuidv4()}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' ↓'
                              : ' ↑'
                            : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                )
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => handleRowClick(row.original.asOrg)}
                    className="text-astral-900 border-t border-sideral-50 hover:bg-sideral-50 text-center py-2 cursor-pointer"
                    key={uuidv4()}>
                    {row.cells.map(cell => {
                      if (cell.column.id === 'asOrg') {
                        return (
                          <td
                            className={'text-left ml-8'}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {cell.render('Cell')}
                          </td>
                        )
                      }

                      return (
                        <td
                          className={cell.column.classes || ''}
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <>{props.emptyStateWithoutFilter}</>
        )}
      </div>
    </div>
  )
}

HostsByAsOrgs.propTypes = {
  defaultSorted: PropTypes.array,
  emptyStateWithFilter: PropTypes.func,
  emptyStateWithoutFilter: PropTypes.func,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  paginationClass: PropTypes.string,
  history: PropTypes.object,
}
