import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {i18next} from 'translate/i18n'

import {useSelector, useDispatch} from 'react-redux'
import {
  fetchInfoExposureFindings,
  fetchCompanyStats,
  clearFilters,
  redirect404,
} from 'actions'
import {sideral300} from 'constant'

import Table from './Table'
import StatsBox from 'components/partials/StatsBox'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import FindingIcon from 'res/icons/findingIcon'
import TableWrapper from 'components/partials/tables/TableWrapper'
import {Page} from 'components/partials/Page'
import PlanUtils from 'utils/plan'
import AccessibilityAlert from 'components/partials/AccessibilityAlert'
import {hasFeature} from 'utils'

export default function Information() {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const statsInfoState = useSelector(state => state.statsInfo)
  const infoExposureFindingsState = useSelector(
    state => state.infoExposureFindings
  )

  const [data, setData] = useState([])
  const [repoCount, setRepoCount] = useState(0)
  const [ownerCount, setOwnerCount] = useState(0)

  const planUtils = PlanUtils()

  const columns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.type'),
            accessor: 'title',
            width: 80,
          },
          {
            Header: i18next.t('infoExposure.repository'),
            accessor: 'repository.full_name',
          },
          {
            Header: i18next.t('infoExposure.filename'),
            accessor: 'filename',
          },
          {
            Header: 'Commit',
            accessor: 'html_url',
          },
          {
            Header: 'Status',
            accessor: 'status',
            width: 50,
          },
        ],
      },
    ],
    []
  )

  useEffect(() => {
    if (!hasFeature(userState, 'infoExposure')) return dispatch(redirect404())

    if (!planUtils.hasPlanRestriction()) dispatch(fetchInfoExposureFindings())

    if (userState && userState.company) {
      dispatch(
        fetchCompanyStats({companyId: userState.company.id, type: 'info'})
      )
    }
  }, [dispatch, userState])

  useEffect(() => {
    if (infoExposureFindingsState) {
      const repos = new Set()
      const owners = new Set()
      infoExposureFindingsState.forEach(x => {
        repos.add(x.repository.full_name)
        owners.add(x.owner.id)
      })
      setRepoCount(repos.size)
      setOwnerCount(owners.size)
      setData(infoExposureFindingsState)
    }
  }, [infoExposureFindingsState])

  useEffect(() => {
    dispatch(clearFilters({except: 'information'}))
  }, [])

  return (
    <Page pageTitle={i18next.t('pageTitles.information')}>
      <div className="grid lg:grid-cols-2 gap-4 w-full">
        <StatsBox
          slots={2}
          title={'/' + i18next.t('misc.files')}
          label={i18next.t('infoExposure.sensitiveInformation')}
          data={data.length || 0}
          secondaryLabel={i18next.t('infoExposure.processed')}
          secondaryData={statsInfoState && statsInfoState.processedFiles}
          size="lg"
        />
        <StatsBox
          slots={2}
          title="/GITHUB"
          label={i18next.t('misc.users')}
          data={ownerCount}
          secondaryLabel={i18next.t('infoExposure.repositories')}
          secondaryData={repoCount}
          size="lg"
        />
      </div>

      <TableWrapper
        id="findingsTable"
        title={i18next.t('misc.findings')}
        loaded={data}
        margin="mt-8"
        table={
          <Table
            columns={columns}
            data={data}
            pageNumber={0}
            pageSize={12}
            emptyStateWithFilter={
              <GenericEmptyState
                icon={<FindingIcon width="55" color={sideral300} />}
                title={i18next.t('tables.nothingFoundWithSearch')}
                body={i18next.t('tables.searchSomethingElse')}
                margin="m-2 mt-4"
              />
            }
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<FindingIcon width="55" color={sideral300} />}
                body={i18next.t('infoExposure.noFindings')}
                buttonText={i18next.t('buttons.viewAllFindings')}
                linkPath={'/management'}
                margin="m-2 mt-4"
              />
            }
          />
        }
        isPremiumFeature
      />

      <AccessibilityAlert />
    </Page>
  )
}

Information.propTypes = {
  history: PropTypes.object,
}
