import React from 'react'
import PropTypes from 'prop-types'

export default function CloudIconProhibited({width, height, color, opacity}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path
          d="M22.239 3.7065C27.0945 3.7065 31.2829 7.15352 32.2281 11.9164L32.784 14.6963L35.6195 14.9002C38.5106 15.0855 40.7714 17.5133 40.7714 20.3857C40.7714 23.4436 38.2696 25.9455 35.2117 25.9455H11.1195C7.02381 25.9455 3.7065 22.6282 3.7065 18.5325C3.7065 14.7334 6.54198 11.5643 10.3041 11.1752L12.287 10.9713L13.2137 9.21063C14.9742 5.81921 18.4213 3.7065 22.239 3.7065ZM22.239 0C16.8831 0 12.2314 3.03933 9.91488 7.48711C4.3366 8.08019 0 12.806 0 18.5325C0 24.6667 4.98525 29.652 11.1195 29.652H35.2117C40.3267 29.652 44.478 25.5007 44.478 20.3857C44.478 15.4931 40.6789 11.5272 35.8604 11.1936C34.6001 4.79991 28.9848 0 22.239 0Z"
          fill={color}
        />
        <path
          d="M8.25146 32L36.2263 4.02516"
          stroke={color}
          strokeWidth="3.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  )
}

CloudIconProhibited.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
}
