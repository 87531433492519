import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {useParams, useNavigate} from 'react-router-dom'

import {loading, forgotPassword, clearError, clearSuccess} from 'actions'

import Logo from 'res/brand/logo.svg'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import {Page} from 'components/partials/Page'
import {isLoading} from 'utils'

const ForgotPassword = () => {
  const params = useParams()
  const navigate = useNavigate()

  const initialFormState = {
    initial: true,
    email: '',
    id: params.id || false,
  }

  const ReCaptchaRef = React.createRef()
  const dispatch = useDispatch()

  const successState = useSelector(state => state.success)
  const errorState = useSelector(state => state.error)
  const loadingState = useSelector(state => state.loading)

  const [form, setForm] = useState(initialFormState)

  const handleInputChange = event => {
    const {name, value} = event.target
    setForm({...form, [name]: value})
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (!form.email) return

    dispatch(loading({forgotPassword: true}))

    if (process.env.REACT_APP_ENV === 'development') {
      dispatch(forgotPassword(form))
      setForm({...form, initial: false})
      return
    }

    ReCaptchaRef.current.execute()
    setForm({...form, initial: false})
  }

  const handleCaptchaChange = token => {
    setForm({...form, captcha: token})
  }

  useEffect(() => {
    if (successState && !form.initial) {
      navigate('/login')
      setForm(initialFormState)
    }
  }, [dispatch, successState])

  useEffect(() => {
    dispatch(clearError())
    dispatch(clearSuccess())
  }, [dispatch])

  useEffect(() => {
    if (form.captcha) {
      dispatch(forgotPassword(form))

      // clear captcha after submit to avoid resend
      setForm({...form, captcha: null})
    }
  }, [dispatch, form.captcha])

  useEffect(() => {
    if (errorState) ReCaptchaRef.current.reset()
  }, [errorState])

  return (
    <Page pageTitle={i18next.t('pageTitles.forgotPassword')} hideHeader>
      <div className="dm-mono fixed right-0 flex flex-col items-center bg-white lg:max-w-xl w-full h-screen ml-auto pt-12 z-50">
        <img
          src={Logo}
          alt="Unxpose"
          className="mx-auto cursor-pointer w-56 mb-24"
        />
        <form
          onSubmit={handleSubmit}
          className="relative w-full text-sideral-700 px-6 md:px-24">
          <div>
            <PrimaryInput
              testId="forgot-email-input"
              text={i18next.t('labels.email')}
              name="email"
              type="email"
              value={form.email}
              onChange={handleInputChange}
              required={true}
              placeholder={i18next.t('placeholders.email')}
              margin="mb-8"
            />
            <div className="flex justify-between items-center mt-6">
              <SecondaryButton
                text={i18next.t('buttons.backLogin').toUpperCase()}
                direction="left"
                size="sm"
                onClick={() => navigate('/login')}
              />
              <PrimaryButton
                testId="recover-access-btn"
                text={i18next.t('buttons.recoverAccess')}
                type="submit"
                size="lg"
                loading={isLoading(loadingState.forgotPassword, false)}
              />
            </div>
          </div>
          <ReCAPTCHA
            ref={ReCaptchaRef}
            sitekey="6LeqSV8aAAAAAHHZEv5J9NqKNy_D_uNiwEJQjsLO"
            size="invisible"
            onChange={handleCaptchaChange}
          />
        </form>
      </div>
    </Page>
  )
}

ForgotPassword.propTypes = {
  history: PropTypes.object,
}

export default ForgotPassword
