import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import moment from 'moment'

import {loading, uploadDocument} from 'actions'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import PrimaryDropFile from 'components/partials/inputs/PrimaryDropFile'
import {PrimaryDatePicker} from 'components/partials/inputs/PrimaryDatePicker'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import {documentTypesLabels} from 'constant'
import {isLoading} from 'utils'

export default function DocumentForm({classes}) {
  const initialFormState = {
    documentType: '',
    consultancyCompany: '',
    startDatePentestPeriod: null,
    endDatePentestPeriod: null,
    startDatePolicy: null,
    file: [],
  }

  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)

  const [form, setForm] = useState(initialFormState)
  const [file, setFile] = useState([])

  const handleDocumentChange = e => {
    setForm({...form, documentType: e.value})
  }

  const handleInputChange = e => {
    const {name} = e.target
    setForm({...form, [name]: e.target.value})
  }

  const handleSubmit = e => {
    e.preventDefault()

    const formData = new FormData()

    formData.append('_csrf', csrfState)
    formData.append('artifactType', form.documentType)
    formData.append('artifact', file[0])

    switch (form.documentType) {
      case 'pentest':
      case 'pentestSummaryReport':
        formData.append('consultancy', form.consultancyCompany)
        formData.append(
          'startDate',
          moment(form.startDatePentestPeriod).format('YYYY-MM-DD')
        )
        formData.append(
          'endDate',
          moment(form.endDatePentestPeriod).format('YYYY-MM-DD')
        )
        break
      default:
        formData.append(
          'documentDate',
          moment(form.startDatePolicy).format('YYYY-MM-DD')
        )
        break
    }

    dispatch(loading({uploadDocument: true}))
    dispatch(uploadDocument(formData))
  }

  const canSubmitForm = () => {
    switch (form.documentType) {
      case 'pentest':
      case 'pentestSummaryReport':
        return (
          form.consultancyCompany &&
          form.startDatePentestPeriod &&
          form.endDatePentestPeriod &&
          file.length
        )
      default:
        return form.startDatePolicy && file.length
    }
  }

  const getInputsByDocumentType = type => {
    switch (type) {
      case 'pentest':
      case 'pentestSummaryReport':
        return (
          <>
            <div className="md:flex border-t border-sideral-50 pt-8 pb-6">
              <div className="basis-1/3 mr-8">
                <p className="text-sm text-sideral-900">
                  {i18next.t('documents.addNew.consultancyCompany')}*
                </p>
                <p className="text-light text-xs text-sideral-400 pt-1">
                  {i18next.t('documents.addNew.consultancyCompanyDetail')}
                </p>
              </div>
              <div className="w-full sm:basis-2/3">
                <PrimaryInput
                  name="consultancyCompany"
                  type="text"
                  value={form.consultancyCompany}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="md:flex border-y border-sideral-50 py-8">
              <div className="basis-1/3 mr-8">
                <p className="text-sm text-sideral-900">
                  {i18next.t('documents.addNew.executionRange')}*
                </p>
                <p className="text-light text-xs text-sideral-400 pt-1">
                  {i18next.t('documents.addNew.executionRangeDetail')}
                </p>
              </div>
              <div className="w-full sm:basis-2/3">
                <PrimaryDatePicker
                  value={{
                    startDate: form.startDatePentestPeriod,
                    endDate: form.endDatePentestPeriod,
                  }}
                  setValue={e => {
                    setForm({
                      ...form,
                      startDatePentestPeriod: e.startDate,
                      endDatePentestPeriod: e.endDate,
                    })
                  }}
                  asSingle={false}
                />
              </div>
            </div>
          </>
        )

      default:
        return (
          <div className="md:flex border-y border-sideral-50 pt-8 pb-8">
            <div className="basis-1/3 mr-8">
              <p className="text-sm text-sideral-900">
                {i18next.t('documents.addNew.generationDate')}*
              </p>
              <p className="text-light text-xs text-sideral-400 pt-1">
                {i18next.t('documents.addNew.generationDateDetail')}
              </p>
            </div>
            <div className="w-full sm:basis-2/3">
              <PrimaryDatePicker
                value={{
                  startDate: form.startDatePolicy,
                  endDate: form.startDatePolicy,
                }}
                setValue={e => {
                  setForm({
                    ...form,
                    startDatePolicy: e.startDate,
                  })
                }}
              />
            </div>
          </div>
        )
    }
  }

  return (
    <>
      <form
        className={classes || 'xl:w-10/12 py-4 px-6'}
        onSubmit={handleSubmit}>
        <div className="md:flex pt-8 pb-6">
          <div className="basis-1/3 mr-8">
            <p className="text-sm text-sideral-900">
              {i18next.t('documents.addNew.documentType')}*
            </p>
            <p className="text-light text-xs text-sideral-400 pt-1">
              {i18next.t('documents.addNew.documentTypeDetail')}
            </p>
          </div>
          <div id="document-type-select" className="w-full sm:basis-2/3">
            <PrimarySelect
              name="documentType"
              options={documentTypesLabels}
              onChange={handleDocumentChange}
              isSearchable={false}
              required
            />
          </div>
        </div>

        {form.documentType && (
          <>
            {getInputsByDocumentType(form.documentType)}

            <div id="about-file-container" className="pt-8 pb-6">
              <div>
                <p className="text-sm text-sideral-900">
                  {i18next.t('documents.addNew.fileTitle')}
                </p>
              </div>

              <PrimaryDropFile
                file={file}
                setFile={setFile}
                acceptedFileType={{
                  'application/pdf': ['.pdf'],
                  'image/jpeg': [],
                  'image/png': [],
                }}
                dragAndDropError={i18next.t(
                  'documents.addNew.messages.dragAndDropError'
                )}
                footerText={i18next.t('documents.addNew.maxFileSize')}
              />
            </div>

            <div className="flex justify-end mt-2 mb-4">
              <PrimaryButton
                text={i18next.t('documents.addNew.onSubmitBtn')}
                type="submit"
                disabled={!canSubmitForm()}
                loading={isLoading(loadingState.uploadDocument, false)}
              />
            </div>
          </>
        )}
      </form>
    </>
  )
}

DocumentForm.propTypes = {
  classes: PropTypes.string,
}
