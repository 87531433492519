import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {i18next} from 'translate/i18n'
import {useNavigate} from 'react-router-dom'

import {acceptTerms} from 'actions'
import PrimaryButton from './partials/buttons/PrimaryButton'
import infoIcon from '../res/icons/infoIcon.svg'

const Terms = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)
  const termsAcceptedState = useSelector(state => state.termsAccepted)

  const handleAcceptTerms = e => {
    e.preventDefault()
    dispatch(acceptTerms({_csrf: csrfState}))
  }

  useEffect(() => {
    if (userState && !props.shouldShowTerms) window.location.href = '/'
  }, [userState])

  useEffect(() => {
    if (termsAcceptedState) {
      window.location.href = '/'
    }
  }, [termsAcceptedState])

  return (
    <>
      <span className="fixed h-screen w-screen bg-astral-900 opacity-75 z-0"></span>
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-6xl flex flex-wrap items-center justify-center rounded shadow-md bg-white p-6 z-30">
        <div className="inline-flex flex-wrap items-center roboto w-full font-medium bg-sky-50 border border-sky-900 text-sideral-700 rounded text-xs px-4 py-3">
          <img src={infoIcon} alt="Info" className="h-5 w-5 mr-2" />
          {i18next.t('warnings.updateTerms')}
        </div>
        <div className="w-full">
          <iframe
            src="https://www.unxpose.com/terms"
            className="w-full mt-10"
            height="450px"></iframe>

          <div className="pt-8 flex justify-end">
            <div className="pr-6">
              <PrimaryButton
                text={i18next.t('buttons.declineTerms').toUpperCase()}
                theme="blue-outline"
                onClick={() => navigate('/logout')}
              />
            </div>

            <PrimaryButton
              text={i18next.t('buttons.acceptTerms').toUpperCase()}
              theme="blue"
              onClick={handleAcceptTerms}
            />
          </div>
        </div>
      </div>
    </>
  )
}

Terms.propTypes = {
  history: PropTypes.object,
  shouldShowTerms: PropTypes.bool,
}

export default Terms
