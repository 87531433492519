import React, {useState} from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {useDispatch, useSelector} from 'react-redux'
import {deleteMember} from 'actions'
import i18next from 'i18next'
import {Trans} from 'react-i18next'

const RemoveUserModal = ({removeUserModal, setRemoveUserModal}) => {
  const dispatch = useDispatch()
  const csrfState = useSelector(state => state.csrf)

  const [selectedOption, setSelectedOption] = useState('remove-single')

  const hasMultipleCompanies = () =>
    removeUserModal.member.commonCompanies.length > 1

  const handleRemoveOptionChange = event =>
    setSelectedOption(event.target.value)

  const onClickRemove = () => {
    if (selectedOption === 'remove-single')
      dispatch(
        deleteMember({
          userId: removeUserModal.member.id,
          multipleCompanyRemoval: false,
          _csrf: csrfState,
        })
      )
    else
      dispatch(
        deleteMember({
          userId: removeUserModal.member.id,
          multipleCompanyRemoval: true,
          _csrf: csrfState,
        })
      )

    setRemoveUserModal({showModal: false})
  }

  return (
    <div
      id="removeUserModal"
      className="modal-bg fixed top-0 left-0 w-screen h-screen z-50">
      <div className="fixed bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 max-w-lg w-full bg-white rounded-md shadow-md p-6">
        <div className="relative">
          <div id="modal-header">
            <div className="font-medium text-sideral-90 text-lg mb-6 border-b border-sideral-50 pb-3">
              {i18next.t('settings.members.removeUserModal.title')}
            </div>
          </div>

          <div id="modal-body" className="text-sm text-gray-700 leading-6 mb-6">
            {hasMultipleCompanies() ? (
              <MultipleRemoval
                member={removeUserModal.member}
                selectedOption={selectedOption}
                handleRemoveOptionChange={handleRemoveOptionChange}
              />
            ) : (
              <SingleRemoval member={removeUserModal.member} />
            )}
          </div>

          <div id="modal-footer">
            <div className="flex">
              <div className="w-full">
                <PrimaryButton
                  text={i18next.t(
                    'settings.members.removeUserModal.actionButtons.remove'
                  )}
                  type="button"
                  size="full"
                  margin="mr-2"
                  theme="red"
                  onClick={onClickRemove}
                />
              </div>
              <div className="w-full">
                <PrimaryButton
                  text={i18next.t(
                    'settings.members.removeUserModal.actionButtons.cancel'
                  )}
                  type="button"
                  size="full"
                  margin="ml-2"
                  theme="blue-outline"
                  onClick={() => {
                    setRemoveUserModal({showModal: false})
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SingleRemoval = ({member}) => (
  <div id="single-removal">
    <Trans
      i18nKey="settings.members.removeUserModal.bodySingle"
      values={{
        userName: `${member.firstName} ${member.lastName}`,
      }}
    />
  </div>
)

const MultipleRemoval = ({
  member,
  selectedOption,
  handleRemoveOptionChange,
}) => {
  const companyState = useSelector(state => state.company)

  return (
    <div id="multiple-removal">
      <div>
        <Trans
          i18nKey="settings.members.removeUserModal.bodyMultiple"
          values={{
            userName: `${member.firstName} ${member.lastName}`,
          }}
        />
      </div>

      <ul className="list-disc ml-8 my-4">
        {member.commonCompanies.map(company => (
          <li key={company.id}>{company.name}</li>
        ))}
      </ul>

      <div className="mb-1">
        <input
          id="remove-single"
          name="remove-single"
          className="accent-sky-900 mr-2"
          value="remove-single"
          type="radio"
          checked={selectedOption === 'remove-single'}
          onChange={handleRemoveOptionChange}
        />
        <label htmlFor="remove-single">
          {i18next.t('settings.members.removeUserModal.options.removeSingle', {
            currentCompany: companyState.name,
          })}
        </label>
      </div>

      <div>
        <input
          id="remove-multiple"
          name="remove-multiple"
          className="accent-sky-900 mr-2"
          value="remove-multiple"
          type="radio"
          checked={selectedOption === 'remove-multiple'}
          onChange={handleRemoveOptionChange}
        />
        <label htmlFor="remove-multiple">
          {i18next.t('settings.members.removeUserModal.options.removeMultiple')}
        </label>
      </div>
    </div>
  )
}

RemoveUserModal.propTypes = {
  removeUserModal: PropTypes.object,
  setRemoveUserModal: PropTypes.func,
}

SingleRemoval.propTypes = {
  member: PropTypes.object,
}

MultipleRemoval.propTypes = {
  member: PropTypes.object,
  selectedOption: PropTypes.string,
  handleRemoveOptionChange: PropTypes.func,
}

export default RemoveUserModal
