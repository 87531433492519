import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'

import images from 'res'
import {assetServiceMap} from 'constant'

import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import Spinner from 'components/partials/Spinner'
import IntegrationHeader from './IntegrationHeader'
import StatsItem from './StatsItem'
import {ProviderPermissionAlertBtn} from './ProviderPermissionAlertBtn'
import {toggleRestrictionModal} from 'actions'
import PlanUtils from 'utils/plan'
import {useNavigate} from 'react-router-dom'

export default function ProviderIntegration({
  integration,
  styles,
  chartData,
  IntegrationStarted,
  IntegrationFailed,
  providersProperties,
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const awsStatsState = useSelector(state => state.awsStats)
  const gcpStatsState = useSelector(state => state.gcpStats)
  const azureStatsState = useSelector(state => state.azureStats)

  let providerIcon, integrationStats, redirectUrl

  const planUtils = PlanUtils()

  const onClickIntegrationDetails = redirectPath => {
    planUtils.hasPlanRestriction()
      ? dispatch(
          toggleRestrictionModal({
            contentId: 'cloudIntegrationTutorialBtn',
            showModal: true,
          })
        )
      : navigate(redirectPath)
  }

  const anyServiceFailedTest = logs => {
    const servicesToCheck = [...new Set(Object.values(assetServiceMap.gcp))]
    return servicesToCheck.some(key => logs[key]?.code !== 'ok')
  }

  const shouldDisplayProviderPermissionAlertBtn = () => {
    switch (integration.provider) {
      case 'aws':
        return integrationStats?.isPolicyObsolete
      case 'gcp':
        return integration?.logs
          ? anyServiceFailedTest(integration.logs)
          : false
    }
  }

  switch (integration.provider) {
    case 'aws':
      providerIcon = images.awsIcon
      integrationStats = awsStatsState?.[integration.integrationName]
      redirectUrl = '/cloud/aws'
      break

    case 'gcp':
      providerIcon = images.gcpIcon
      integrationStats = gcpStatsState?.[integration.integrationName]
      redirectUrl = '/cloud/gcp'
      break

    case 'azure':
      providerIcon = images.azureIcon
      integrationStats = azureStatsState?.[integration.integrationName]
      redirectUrl = '/cloud/azure'
      break
  }

  if (integration.status === 'removed') return

  if (integration.status === 'failed')
    return (
      <div key={`${integration.provider}Integration ${integration.id}`}>
        <IntegrationFailed
          hideIntegrationOptions={planUtils.hasPlanRestriction()}
          provider={integration.provider}
          providersProperties={providersProperties}
          integration={integration}
          icon={providerIcon}
          styles={styles}
        />
      </div>
    )

  if (integration.status === 'started')
    return (
      <div key={`${integration.provider}Integration ${integration.id}`}>
        <IntegrationStarted
          hideIntegrationOptions={true}
          provider={integration.provider}
          providersProperties={providersProperties}
          integration={integration}
          icon={providerIcon}
          styles={styles}
        />
      </div>
    )

  if (integration.status === 'active') {
    return (
      <div
        key={`${integration.provider}Integration ${integration.id}`}
        className={styles.providerContainer + ' group'}>
        {integrationStats ? (
          <>
            <IntegrationHeader
              hideIntegrationOptions={planUtils.hasPlanRestriction()}
              provider={integration.provider}
              providersProperties={providersProperties}
              integration={integration}
              icon={providerIcon}
              styles={styles}
              rightSideContent={
                <div className="flex items-center">
                  {!planUtils.hasPlanRestriction() &&
                    shouldDisplayProviderPermissionAlertBtn() && (
                      <ProviderPermissionAlertBtn
                        provider={integration.provider}
                        integration={integration}
                      />
                    )}

                  <SecondaryButton
                    text={i18next.t('buttons.details')}
                    size="sm"
                    onClick={() =>
                      onClickIntegrationDetails(
                        `${redirectUrl}/${integration.integrationName}`
                      )
                    }
                  />
                </div>
              }
            />

            <div className={styles.providerBody}>
              {!integrationStats.findings ? (
                <div className="text-sm mt-4 md:mt-0">
                  <h2 className=" text-sky-900">
                    {i18next.t('titles.goodNews')}!
                  </h2>
                  <p className="font-light mr-6">
                    {i18next.t('cloud.noFindingsInIntegration')}
                  </p>
                </div>
              ) : (
                <div className={styles.chartContainer}>
                  {integrationStats.severityBreakdown ? (
                    <Chart
                      options={chartData.options}
                      series={[
                        {
                          name: i18next.t('severity.critical'),
                          data: [integrationStats.severityBreakdown.critical],
                        },
                        {
                          name: i18next.t('severity.high'),
                          data: [integrationStats.severityBreakdown.high],
                        },
                        {
                          name: i18next.t('severity.medium'),
                          data: [integrationStats.severityBreakdown.medium],
                        },
                        {
                          name: i18next.t('severity.low'),
                          data: [integrationStats.severityBreakdown.low],
                        },
                        {
                          name: i18next.t('severity.info'),
                          data: [integrationStats.severityBreakdown.info],
                        },
                      ]}
                      height="100%"
                      width="100%"
                      type="bar"
                    />
                  ) : (
                    <Spinner />
                  )}
                </div>
              )}

              <StatsItem
                assetsQty={integrationStats.totalResources}
                findingsQty={integrationStats.findings}
                score={integrationStats.score.grade}
                styles={styles}
              />
            </div>
          </>
        ) : (
          <div className={`${styles.providerBody} my-8`}>
            <Spinner />
          </div>
        )}
      </div>
    )
  }
}

ProviderIntegration.propTypes = {
  integration: PropTypes.object,
  styles: PropTypes.object,
  chartData: PropTypes.object,
  providersProperties: PropTypes.object,
  IntegrationStarted: PropTypes.func,
  IntegrationFailed: PropTypes.func,
}
