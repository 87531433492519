import i18next from 'i18next'

import config from 'config'
import {handler} from './handler'
import {
  fetchAzureBlobContainersDone,
  fetchAzureDisksDone,
  fetchAzureFinding,
  fetchAzureFindingActivity,
  fetchAzureFindingActivityDone,
  fetchAzureFindingDone,
  fetchAzureFindingsByResourceDone,
  fetchAzureFindingsByServiceDone,
  fetchAzureFindingsDone,
  fetchAzureIntegrationsDone,
  fetchAzureMostCriticalDone,
  fetchAzureMysqlServersDone,
  fetchAzurePostgresqlServersDone,
  fetchAzureResourceGroupsDone,
  fetchAzureSqlDatabasesDone,
  fetchAzureSqlServersDone,
  fetchAzureStatsDone,
  fetchAzureStorageAccountsDone,
  fetchAzureSubscriptionsDone,
  fetchAzureVmInstancesDone,
  fetchCloudIntegrations,
  genericError,
  integrateAzureDone,
  loading,
  redirectFeedback,
  stopAzureIntegrationDone,
  success,
  toggleRestrictionModal,
} from 'actions'

export function* handleIntegrateAzure(action) {
  yield handler({
    handler: integrateAzure,
    error: [
      () => loading({azureIntegration: false}),
      res => {
        let message

        switch (res.code) {
          case 'GENERIC_AZURE_ERROR':
            message = i18next.t('cloud.azure.integration.stepFour.genericError')
            break
          case 'INVALID_CONFIGURATION':
            message = i18next.t(
              'cloud.azure.integration.stepFour.invalidConfiguration'
            )
            break
          case 'DIRECTORY_ID_ALREADY_IN_USE':
            message = i18next.t(
              'cloud.azure.integration.stepFour.directoryIdAlreadyInUse'
            )
            break
          case 'SUBSCRIPTION_LIMIT':
            return toggleRestrictionModal({
              contentId: 'cloudIntegrationTutorialBtn',
              showModal: true,
            })
          default:
            message = i18next.t('errors.somethingWentWrong')
        }
        return genericError({message})
      },
    ],
    success: [success, integrateAzureDone],
    action,
  })
}

const integrateAzure = function (body) {
  return fetch(config.SERVER_URL + '/azure/integrate', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleStopAzureIntegration(action) {
  yield handler({
    handler: stopAzureIntegration,
    error: genericError,
    success: [
      () => {
        // Avoid unnecessary redirect flag setting to prevent status message display on a different component.
        if (
          window.location.pathname === '/cloud' ||
          window.location.pathname === '/cloud/'
        )
          return success({message: i18next.t('cloud.azure.integrationRemoved')})

        return redirectFeedback({
          path: '/cloud',
          status: 'success',
          message: i18next.t('cloud.azure.integrationRemoved'),
        })
      },
      stopAzureIntegrationDone,
      fetchCloudIntegrations,
    ],
    action,
  })
}

const stopAzureIntegration = function (body) {
  return fetch(`${config.SERVER_URL}/azure/${body.integrationId}`, {
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureIntegrations(action) {
  yield handler({
    handler: _fetchAzureIntegrations,
    error: genericError,
    success: fetchAzureIntegrationsDone,
    action,
  })
}

const _fetchAzureIntegrations = function () {
  return fetch(config.SERVER_URL + '/azure/integrations', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureStats(action) {
  yield handler({
    handler: fetchAzureStats,
    error: genericError,
    success: fetchAzureStatsDone,
    action,
  })
}

const fetchAzureStats = function (tenantId) {
  const url = tenantId
    ? `${config.SERVER_URL}/azure/tenants/${tenantId}/stats`
    : `${config.SERVER_URL}/azure/stats`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureMostCritical(action) {
  yield handler({
    handler: fetchAzureMostCritical,
    error: genericError,
    success: fetchAzureMostCriticalDone,
    action,
  })
}

const fetchAzureMostCritical = function (tenantId) {
  const url = tenantId
    ? `${config.SERVER_URL}/azure/tenants/${tenantId}/findings/mostCritical`
    : `${config.SERVER_URL}/azure/findings/mostCritical`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureSubscriptions(action) {
  yield handler({
    handler: fetchAzureSubscriptions,
    error: genericError,
    success: fetchAzureSubscriptionsDone,
    action,
  })
}

const fetchAzureSubscriptions = function () {
  return fetch(config.SERVER_URL + '/azure/resources/subscriptions', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureBlobContainers(action) {
  yield handler({
    handler: fetchAzureBlobContainers,
    error: genericError,
    success: fetchAzureBlobContainersDone,
    action,
  })
}

const fetchAzureBlobContainers = function (tenantId) {
  const url = tenantId
    ? `${config.SERVER_URL}/azure/tenants/${tenantId}/resources/blobContainers`
    : `${config.SERVER_URL}/azure/resources/blobContainers`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const fetchAzureDisks = function (tenantId) {
  const url = tenantId
    ? `${config.SERVER_URL}/azure/tenants/${tenantId}/resources/disks`
    : `${config.SERVER_URL}/azure/resources/disks`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureDisks(action) {
  yield handler({
    handler: fetchAzureDisks,
    error: genericError,
    success: fetchAzureDisksDone,
    action,
  })
}

export function* handleFetchAzureMysqlServers(action) {
  yield handler({
    handler: _fetchAzureMysqlServers,
    error: genericError,
    success: fetchAzureMysqlServersDone,
    action,
  })
}

const _fetchAzureMysqlServers = function (tenantId) {
  const url = tenantId
    ? `${config.SERVER_URL}/azure/tenants/${tenantId}/resources/mysqlServers`
    : `${config.SERVER_URL}/azure/resources/mysqlServers`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzurePostgresqlServers(action) {
  yield handler({
    handler: _fetchAzurePostgresqlServers,
    error: genericError,
    success: fetchAzurePostgresqlServersDone,
    action,
  })
}

const _fetchAzurePostgresqlServers = function (tenantId) {
  const url = tenantId
    ? `${config.SERVER_URL}/azure/tenants/${tenantId}/resources/postgresqlServers`
    : `${config.SERVER_URL}/azure/resources/postgresqlServers`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureSqlServers(action) {
  yield handler({
    handler: fetchAzureSqlServers,
    error: genericError,
    success: fetchAzureSqlServersDone,
    action,
  })
}

const fetchAzureSqlServers = function (tenantId) {
  const url = tenantId
    ? `${config.SERVER_URL}/azure/tenants/${tenantId}/resources/sqlServers`
    : `${config.SERVER_URL}/azure/resources/sqlServers`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureSqlDatabases(action) {
  yield handler({
    handler: fetchAzureSqlDatabases,
    error: genericError,
    success: fetchAzureSqlDatabasesDone,
    action,
  })
}

const fetchAzureSqlDatabases = function (tenantId) {
  const url = tenantId
    ? `${config.SERVER_URL}/azure/tenants/${tenantId}/resources/sqlDatabases`
    : `${config.SERVER_URL}/azure/resources/sqlDatabases`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const fetchAzureStorageAccounts = function (tenantId) {
  const url = tenantId
    ? `${config.SERVER_URL}/azure/tenants/${tenantId}/resources/storageAccounts`
    : `${config.SERVER_URL}/azure/resources/storageAccounts`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureStorageAccounts(action) {
  yield handler({
    handler: fetchAzureStorageAccounts,
    error: genericError,
    success: fetchAzureStorageAccountsDone,
    action,
  })
}

const fetchAzureResourceGroups = function () {
  return fetch(config.SERVER_URL + '/azure/resources/resourceGroups', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureResourceGroups(action) {
  yield handler({
    handler: fetchAzureResourceGroups,
    error: genericError,
    success: fetchAzureResourceGroupsDone,
    action,
  })
}

const fetchAzureVmInstances = function (tenantId) {
  const url = tenantId
    ? `${config.SERVER_URL}/azure/tenants/${tenantId}/resources/vms`
    : `${config.SERVER_URL}/azure/resources/vms`

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureVmInstances(action) {
  yield handler({
    handler: fetchAzureVmInstances,
    error: genericError,
    success: fetchAzureVmInstancesDone,
    action,
  })
}

export function* handleFetchAzureFindings(action) {
  yield handler({
    handler: fetchAzureFindings,
    error: genericError,
    success: fetchAzureFindingsDone,
    action,
  })
}

const fetchAzureFindings = function () {
  return fetch(config.SERVER_URL + '/azure/findings', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureFindingsByResource(action) {
  yield handler({
    handler: _fetchAzureFindingsByResource,
    success: fetchAzureFindingsByResourceDone,
    action,
  })
}

const _fetchAzureFindingsByResource = ({resourceName, tenantId}) => {
  const url = tenantId
    ? `${config.SERVER_URL}/azure/tenants/${tenantId}/resources/${resourceName}/findings`
    : `${config.SERVER_URL}/azure/resources/${resourceName}/findings`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureFindingsByService(action) {
  yield handler({
    handler: _fetchAzureFindingsByService,
    success: fetchAzureFindingsByServiceDone,
    action,
  })
}

const _fetchAzureFindingsByService = ({azureService}) => {
  const url = `${config.SERVER_URL}/azure/${azureService}/findings`
  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const _fetchAzureFinding = findingId => {
  return fetch(`${config.SERVER_URL}/azure/finding/${findingId}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
}

export function* handleFetchAzureFinding(action) {
  yield handler({
    handler: _fetchAzureFinding,
    error: genericError,
    success: fetchAzureFindingDone,
    action,
  })
}

const _updateAzureFindingStatus = change => {
  return fetch(
    `${config.SERVER_URL}/azure/finding/${change.findingId}/${change.status}`,
    {
      body: JSON.stringify(change),
      credentials: 'include',
      headers: {'Content-Type': 'application/json'},
      method: 'PATCH',
    }
  )
}

export function* handleUpdateAzureFindingStatus(action) {
  const {updateStatusMessage, findingId} = action.payload

  yield handler({
    handler: _updateAzureFindingStatus,
    success: [
      () =>
        success({
          message: updateStatusMessage,
        }),
      () => fetchAzureFinding(findingId),
      () => fetchAzureFindingActivity(findingId),
    ],
    error: genericError,
    action,
  })
}

const _fetchAzureFindingActivity = findingId => {
  const url = `${config.SERVER_URL}/azure/finding/${findingId}/activity`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAzureFindingActivity(action) {
  yield handler({
    handler: _fetchAzureFindingActivity,
    success: fetchAzureFindingActivityDone,
    action,
  })
}
