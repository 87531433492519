import React from 'react'
import i18next from 'i18next'

import {Page} from 'components/partials/Page'
import BackButton from 'components/partials/buttons/BackButton'
import Subsection from 'components/partials/headers/Subsection'
import DocumentForm from './DocumentForm'

export default function DocumentsNew() {
  return (
    <Page pageTitle={i18next.t('pageTitles.documents')}>
      <BackButton fallbackLink="/documents" />
      <div className="mt-4">
        <Subsection
          title={i18next.t('documents.addNew.addNewDocument')}
          content={<DocumentForm />}
        />
      </div>
    </Page>
  )
}
