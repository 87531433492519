import i18next from 'i18next'
import React from 'react'

import Footer from './Footer'

export default function Questionnaire() {
  return (
    <>
      <div className="bg-white text-sm w-full overflow-auto py-4 px-8">
        <div className="flex">
          <div className="w-full text-sm">
            <div>
              <h1 className="text-lg font-medium my-2">
                {i18next.t('help.questionnaire.firstParagraph.title')}
              </h1>

              <p className="my-4">
                {i18next.t('help.questionnaire.firstParagraph.firstItem')}
              </p>
            </div>

            <div>
              <h1 className="text-lg font-medium my-1">
                {i18next.t('help.questionnaire.secondParagraph.title')}
              </h1>

              <p className="my-4">
                {i18next.t('help.questionnaire.secondParagraph.firstItem')}
              </p>

              <p className="my-4">
                {i18next.t('help.questionnaire.secondParagraph.secondItem')}
              </p>
            </div>

            <div>
              <h1 className="text-lg font-medium my-1">
                {i18next.t('help.questionnaire.thirdParagraph.title')}
              </h1>

              <p className="my-4">
                {i18next.t('help.questionnaire.thirdParagraph.firstItem')}
              </p>

              <p className="my-4">
                {i18next.t('help.questionnaire.thirdParagraph.secondItem')}
              </p>
            </div>

            <div>
              <h1 className="text-lg font-medium my-1">
                {i18next.t('help.questionnaire.fourthParagraph.title')}
              </h1>

              <p className="my-4">
                {i18next.t('help.questionnaire.fourthParagraph.firstItem')}
              </p>

              <p className="my-4">
                {i18next.t('help.questionnaire.fourthParagraph.secondItem')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
