import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import moment from 'moment'

import {DefaultModal} from 'components/partials/DefaultModal'
import {PrimaryDatePicker} from 'components/partials/inputs/PrimaryDatePicker'
import {getPropByLocale, getUserLocale} from 'utils'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import {updateQuestionnaire} from 'actions'
import PrimaryTextArea from 'components/partials/inputs/PrimaryTextArea'

const QuestionnaireEditModal = ({
  editQuestionnaireModal,
  setEditQuestionnaireModal,
}) => {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)

  const {id, name, description, submittedAt} =
    editQuestionnaireModal.selectedQuestionnaire

  const initialFormState = {
    id,
    name,
    description,
    submittedAt: submittedAt
      ? moment.utc(submittedAt).format('YYYY-MM-DD')
      : null,
  }

  const userLocale = getUserLocale(userState)

  const [form, setForm] = useState(initialFormState)

  const handleInputChange = e => {
    const {name, value} = e.target

    if (name === 'description') {
      setForm({...form, [name]: {[userLocale]: value}})
    } else setForm({...form, [name]: value})
  }

  const handleSubmit = () => {
    dispatch(
      updateQuestionnaire({
        questionnaireId: form.id,
        name: form.name,
        description: form.description[userLocale],
        submittedAt: form.submittedAt ? new Date(form.submittedAt) : null,
        isAnUploadedQuestionnaire: true,
        _csrf: csrfState,
      })
    )

    setEditQuestionnaireModal({
      selectedQuestionnaire: null,
      showModal: false,
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <DefaultModal
        id="editQuestionnaireModal"
        title={i18next.t('questionnaires.edit.modal.title')}
        body={
          <div className="text-sm text-gray-700 leading-6 mb-8">
            <PrimaryInput
              text={i18next.t('questionnaires.edit.modal.name')}
              name="name"
              type="name"
              value={form.name}
              onChange={handleInputChange}
              required
            />

            <PrimaryTextArea
              id="description"
              name="description"
              text={i18next.t('questionnaires.edit.modal.description')}
              value={getPropByLocale(form.description, userLocale)}
              onChange={handleInputChange}
              margin="mb-2"
            />

            {submittedAt && (
              <PrimaryDatePicker
                text={i18next.t('questionnaires.edit.modal.submissionDate')}
                value={{
                  startDate: form.submittedAt,
                  endDate: form.submittedAt,
                }}
                setValue={e => {
                  setForm({
                    ...form,
                    submittedAt: e.startDate,
                  })
                }}
              />
            )}
          </div>
        }
        primaryBtnText={i18next.t('buttons.save')}
        secondayBtnText={i18next.t('buttons.cancel')}
        onClickSecondaryBtn={() =>
          setEditQuestionnaireModal({
            selectedQuestionnaire: null,
            showModal: false,
          })
        }
        primaryButtonType="submit"
      />
    </form>
  )
}

export default QuestionnaireEditModal

QuestionnaireEditModal.propTypes = {
  editQuestionnaireModal: PropTypes.object,
  setEditQuestionnaireModal: PropTypes.func,
}
