import React from 'react'
import PropTypes from 'prop-types'

export default function NetworkIcon({width, height, color, opacity}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path
          d="M25.3333 20V25.3333H6.66667V20H25.3333ZM26.6667 17.3333H5.33333C4.6 17.3333 4 17.9333 4 18.6667V26.6667C4 27.4 4.6 28 5.33333 28H26.6667C27.4 28 28 27.4 28 26.6667V18.6667C28 17.9333 27.4 17.3333 26.6667 17.3333ZM9.33333 24.6667C8.24 24.6667 7.33333 23.7733 7.33333 22.6667C7.33333 21.56 8.24 20.6667 9.33333 20.6667C10.4267 20.6667 11.3333 21.56 11.3333 22.6667C11.3333 23.7733 10.44 24.6667 9.33333 24.6667ZM25.3333 6.66667V12H6.66667V6.66667H25.3333ZM26.6667 4H5.33333C4.6 4 4 4.6 4 5.33333V13.3333C4 14.0667 4.6 14.6667 5.33333 14.6667H26.6667C27.4 14.6667 28 14.0667 28 13.3333V5.33333C28 4.6 27.4 4 26.6667 4ZM9.33333 11.3333C8.24 11.3333 7.33333 10.44 7.33333 9.33333C7.33333 8.22667 8.24 7.33333 9.33333 7.33333C10.4267 7.33333 11.3333 8.24 11.3333 9.33333C11.3333 10.4267 10.44 11.3333 9.33333 11.3333Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

NetworkIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
}
