import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {DefaultModal} from 'components/partials/DefaultModal'
import {cancelInvite} from 'actions'
import {Trans} from 'react-i18next'

export default function RemoveInviteModal({
  removeInviteModal,
  setRemoveInviteModal,
}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const successState = useSelector(state => state.success)

  const onCloseModal = () => {
    setRemoveInviteModal({invite: null, showModal: false})
  }

  useEffect(() => {
    if (successState) onCloseModal()
  }, [successState])

  return (
    <DefaultModal
      id="remove-member-invite"
      title={i18next.t('settings.members.removeInviteModal.title')}
      body={
        <div className="text-sm text-gray-700 leading-6">
          <Trans
            i18nKey="settings.members.removeInviteModal.body"
            values={{
              email: removeInviteModal.inviteEmail,
            }}
          />
        </div>
      }
      primaryBtnText={i18next.t('buttons.remove')}
      primaryButtonTheme="red"
      onClickPrimaryBtn={() =>
        dispatch(
          cancelInvite({
            _csrf: csrfState,
            inviteId: removeInviteModal.inviteId,
          })
        )
      }
      secondayBtnText={i18next.t('buttons.cancel')}
      onClickSecondaryBtn={onCloseModal}
      onCloseModal={onCloseModal}
    />
  )
}

RemoveInviteModal.propTypes = {
  removeInviteModal: PropTypes.object,
  setRemoveInviteModal: PropTypes.func,
}
