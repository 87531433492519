import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import {v4 as uuidv4} from 'uuid'
import {Link} from 'react-router-dom'
import {getSeverityColor, getSeverityLabel} from '../../utils/score'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import {statusList, tableStyles} from 'constant'

import GlobalFilter from 'components/partials/tables/GlobalFilter'
import Pagination from 'components/partials/tables/Pagination'

export default function Table(props) {
  const filtersState = useSelector(state => state.filters)

  const [filterValue, setFilterValue] = useState(null)
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
      Filter: () => '',
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    rows,

    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex},
    setGlobalFilter,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        pageIndex: props.pageNumber,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  function hasFilterApplied() {
    if (!filtersState || !filtersState.partner) return false

    const values = Object.values(filtersState.partner)

    return values.find(value => !!value)
  }

  useEffect(() => {
    props.setFilteredFindings(rows.map(row => row.original))
  }, [rows])

  return (
    <div>
      <div className="tableWrap">
        <div className="grid gap-2 grid-cols-3 grid-rows-2 my-4">
          <div className="flex justify-between items-center col-span-4">
            <GlobalFilter
              rows={rows}
              globalFilter={filterValue}
              setFilterValue={setFilterValue}
              setGlobalFilter={setGlobalFilter}
              gotoPage={gotoPage}
              customPlaceholder={`${i18next.t('tables.filterByTitleIn')} ${
                rows.length
              } ${
                rows.length <= 1
                  ? i18next.t('misc.finding').toLowerCase()
                  : i18next.t('misc.findings').toLowerCase()
              }`}
            />
            <Pagination
              handlePrevious={previousPage}
              handleNext={nextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              paginationClass={props.paginationClass}
              pageIndex={pageIndex}
              pageCount={pageCount}
              total={rows.length || 0}
            />
          </div>
          <div className="col-span-3">
            {headerGroups &&
              headerGroups.map((headerGroup, index) => {
                if (!index) return <span key={uuidv4()}></span>
                return (
                  <span key={uuidv4()} className="flex w-full">
                    {headerGroup.headers.map(column => {
                      if (!column.canFilter) return <span key={uuidv4()}></span>
                      return (
                        <span key={uuidv4()} className="w-full">
                          {column.canFilter ? column.render('Filter') : null}
                        </span>
                      )
                    })}
                  </span>
                )
              })}
          </div>
        </div>
        {page && page.length ? (
          <>
            <table {...getTableProps()} className="w-full">
              <thead>
                {headerGroups.map(headerGroup => {
                  // trick to disable the first headerGroup
                  if (headerGroup.headers.length === 1)
                    return <tr key={uuidv4()}></tr>

                  return (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={uuidv4()}
                      className="p-2">
                      {headerGroup.headers.map(column => {
                        if (column.hidden) return <th key={uuidv4()}></th>
                        return (
                          <th
                            className={tableStyles.tableHeader}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={uuidv4()}>
                            {column.render('Header')}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ' ↓'
                                  : ' ↑'
                                : ''}
                            </span>
                          </th>
                        )
                      })}
                    </tr>
                  )
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={tableStyles.tableRow}
                      key={uuidv4()}>
                      {row.cells.map(cell => {
                        if (cell.column.hidden) return <td key={uuidv4()}></td>

                        if (cell.column.id === 'status')
                          return (
                            <td
                              className={tableStyles.tableData}
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              {statusList[cell.value]}
                            </td>
                          )

                        if (cell.column.Header === i18next.t('tables.title')) {
                          return (
                            <td
                              className={`${tableStyles.tableData} hover:underline`}
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              <Link
                                to={`/partner/finding/${cell.row.original.id}`}
                                className="flex items-center">
                                <span
                                  className={`${getSeverityColor(
                                    cell.row.values.severity
                                  )} severity-indicator flex-shrink-0 mr-2`}></span>
                                <p className="truncate">
                                  {cell.render('Cell')}
                                </p>
                              </Link>
                            </td>
                          )
                        }

                        if (
                          cell.column.Header === i18next.t('tables.severity')
                        ) {
                          return (
                            <td
                              className={`${tableStyles.tableData} capitalize-first`}
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              {getSeverityLabel(cell.row.values.severity)}
                            </td>
                          )
                        }

                        if (
                          cell.column.id === 'bestDate' ||
                          cell.column.id === 'updatedAt'
                        ) {
                          const moment =
                            cell.row.original.checkedAt ||
                            cell.row.original.updatedAt
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={uuidv4()}
                              className={tableStyles.tableData}>
                              <Moment date={new Date(moment)} fromNow />
                            </td>
                          )
                        }

                        return (
                          <td
                            className={tableStyles.tableData}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {cell.value ? cell.render('Cell') : '—'}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        ) : (
          <>
            {hasFilterApplied()
              ? props.emptyStateWithFilter
              : props.emptyStateWithoutFilter}
          </>
        )}
      </div>
    </div>
  )
}

Table.propTypes = {
  filterValue: PropTypes.array,
  defaultSorted: PropTypes.array,
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  paginationClass: PropTypes.string,
  setFilteredFindings: PropTypes.func,
}
