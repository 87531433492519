import React from 'react'
import PropTypes from 'prop-types'

export default function CrownIcon({width, height}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 62 62"
      fill="#FFB743"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M6.31086 16.8683C7.80349 18.0042 9.25071 19.122 10.7126 20.22C12.1893 21.3291 13.6814 22.4172 15.1649 23.5173C16.4777 24.4908 18.1557 24.0287 19.002 22.7552C22.1755 17.9807 25.3925 13.2347 28.5287 8.43524C29.6159 6.77169 31.5587 6.6467 32.7142 7.58616C33.2047 7.98495 33.5467 8.58246 33.9075 9.12213C36.911 13.6151 39.9157 18.1076 42.8921 22.6191C43.9284 24.1897 45.5528 24.5171 47.1119 23.3477C50.8087 20.5747 54.4914 17.7832 58.1784 14.9971C58.8452 14.4933 59.5182 14.0437 60.4235 14.3324C61.6947 14.7379 62.2223 15.875 61.9159 17.331C61.6526 18.5823 61.504 19.8578 61.3079 21.123C61.07 22.6586 60.8352 24.1948 60.5995 25.7308C60.4052 26.9972 60.2132 28.264 60.017 29.5303C59.7792 31.0656 59.5367 32.6002 59.2991 34.1355C59.1064 35.3811 58.9179 36.6273 58.7276 37.8733C58.5597 38.972 58.392 40.0706 58.2213 41.1887H3.77407C3.60188 40.114 3.42569 39.0422 3.25921 37.9688C3.02096 36.4328 2.79208 34.8953 2.55491 33.3592C2.36264 32.1139 2.16414 30.8697 1.96821 29.625C1.72013 28.0489 1.46511 26.4738 1.22581 24.8964C1.03342 23.6283 0.865945 22.3563 0.676409 21.0878C0.490266 19.842 0.258045 18.6021 0.108292 17.3521C0.0172871 16.5925 -0.179449 15.8175 0.385881 15.0929C0.987061 14.3224 2.29582 13.9546 3.11572 14.5564C4.16267 15.3247 5.21903 16.0802 6.31021 16.8681L6.31086 16.8683Z" />
      <path d="M4.18271 53.4261C4.02486 52.9744 3.79881 52.5619 3.79173 52.1456C3.75519 49.9975 3.77477 47.8486 3.77477 45.6505H58.2117C58.2117 47.7283 58.2011 49.8061 58.2166 51.8836C58.2232 52.76 57.966 53.5433 57.2694 54.0526C56.803 54.3935 56.1883 54.7297 55.6393 54.7309C39.1999 54.7664 22.7604 54.7628 6.32102 54.7519C5.39098 54.7512 4.68598 54.2712 4.18238 53.426L4.18271 53.4261Z" />
    </svg>
  )
}

CrownIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}
