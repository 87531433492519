import React from 'react'
import PropTypes from 'prop-types'

export default function PrimaryTextArea({
  id,
  name,
  text,
  value,
  onChange,
  margin,
  rows,
  maxLength,
  disabled,
  required,
}) {
  return (
    <div className={margin || 'mb-3'}>
      <label
        htmlFor={id}
        className="block tracking-wide text-sideral-700 text-xs font-medium mb-1">
        <div className="text-xs">{text}</div>
      </label>

      <textarea
        id={id}
        name={name}
        className="text-ink border border-sideral-100 focus:border-sky-500 rounded py-2 px-3 w-full"
        value={value}
        rows={rows || 2}
        maxLength={maxLength || '1000'}
        onChange={onChange}
        disabled={disabled}
        required={required}
      />
    </div>
  )
}

PrimaryTextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  margin: PropTypes.string,
  rows: PropTypes.string,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
}
