import i18next from 'i18next'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import Chart from 'react-apexcharts'

import {
  astral900,
  coral400,
  mars300,
  sky300,
  summer300,
  summer700,
} from 'constant'
import Spinner from 'components/partials/Spinner'

export default function FindingsBySeverityChart() {
  const statsState = useSelector(state => state.stats)

  const [dataSeries, setDataSeries] = useState([0, 0, 0, 0, 0])

  useEffect(() => {
    if (
      !statsState ||
      !statsState.findings ||
      !statsState.findings.severityBreakdown
    )
      return

    const prop = statsState.findings.severityBreakdown
    const arr = [prop.critical, prop.high, prop.medium, prop.low, prop.info]

    setDataSeries([...arr])
  }, [statsState])

  const findingsBySeverityData = {
    series: dataSeries,

    options: {
      plotOptions: {
        pie: {
          donut: {
            size: '65%',

            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total',
                fontSize: '16px',
                color: astral900,
                fontWeight: 600,
              },
            },
          },
        },
      },
      chart: {
        fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
      },
      legend: {
        position: 'bottom',
        formatter: (name, opts) => {
          return [name, `(${opts.w.globals.series[opts.seriesIndex]})`]
        },

        markers: {
          width: 16,
          height: 16,
          radius: 3,
        },
      },
      colors: [coral400, mars300, summer700, summer300, sky300],
      labels: [
        i18next.t('severity.critical'),
        i18next.t('severity.high'),
        i18next.t('severity.medium'),
        i18next.t('severity.low'),
        i18next.t('severity.info'),
      ],
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  }

  {
    return (
      <div className="flex items-center justify-center h-full pb-16">
        {statsState &&
        statsState.findings &&
        statsState.findings.severityBreakdown ? (
          <Chart
            className="mx-auto md:w-full"
            type="donut"
            options={findingsBySeverityData.options}
            series={findingsBySeverityData.series}
            height={240 + Math.floor(Math.random() * 2) + 1} // trick to avoid a bug triggered by window re-render
          />
        ) : (
          <Spinner />
        )}
      </div>
    )
  }
}
