import Chart from 'react-apexcharts'
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import moment from 'moment'
import i18next from 'i18next'

import {sideral300, sky200, sky900} from 'constant'
import {updateUserPreferences} from 'utils'

export default function ScoreOvertimeChart() {
  const scoreOverTimeState = useSelector(state => state.scoreOverTime)

  const [activeRange, setActiveRange] = React.useState(15)

  const progressList = scoreOverTimeState && scoreOverTimeState.progressList

  const scoreOvertimeChart = {
    series: [
      {
        name: 'Score',
        type: 'area',
        data:
          (scoreOverTimeState && scoreOverTimeState.transformedScores) || [],
      },
    ],
    options: {
      chart: {
        fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
        toolbar: {
          show: false,
          tools: {
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            reset: false,
            download: false,
          },
        },
        events: {
          beforeZoom: function (ctx) {
            ctx.w.config.xaxis.range = undefined
          },
        },
      },
      fill: {
        colors: [sky200],
      },
      colors: [sky900],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        tickAmount: 15,
        type: 'numeric',
        categories: (scoreOverTimeState && scoreOverTimeState.dates) || [],
        range:
          moment().valueOf() - moment().subtract(activeRange, 'days').valueOf(),
        tooltip: {
          enabled: false,
        },
        labels: {
          style: {
            colors: sideral300,
            fontSize: 14,
            fontWeight: 400,
          },
          formatter: x => {
            return moment(x).utc().format('DD/MMM')
          },
        },
      },
      yaxis: {
        tickAmount: 5,
        min: 48999,
        max: 100000,
        labels: {
          style: {
            colors: sideral300,
            fontSize: 14,
            fontWeight: 600,
          },
          formatter: score => {
            if (score <= 100000 && score >= 99000) {
              return 'A'
            }
            if (score < 99000 && score >= 89000) {
              return 'B'
            }
            if (score < 89000 && score >= 79000) {
              return 'C'
            }
            if (score < 79000 && score >= 69000) {
              return 'D'
            }
            if (score < 69000 && score >= 49000) {
              return 'E'
            }
            if (score < 49000) {
              return 'F'
            }
          },
        },
      },
      tooltip: {
        style: {
          colors: sideral300,
          fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
          fontSize: 12,
        },
        marker: {show: false},
        x: {
          format: 'dd/MM/yy',
        },
        y: {
          formatter: score => {
            if (!Array.isArray(progressList)) {
              return i18next.t('misc.calculating')
            }

            const scoreObj = progressList.find(x => x.value === score)

            if (!scoreObj?.progress) {
              return i18next.t('misc.calculating')
            }

            if (score <= 100000 && score >= 99000) {
              return 'A' + ` (${scoreObj.progress}%)`
            }
            if (score < 99000 && score >= 89000) {
              return 'B' + ` (${scoreObj.progress}%)`
            }
            if (score < 89000 && score >= 79000) {
              return 'C' + ` (${scoreObj.progress}%)`
            }
            if (score < 79000 && score >= 69000) {
              return 'D' + ` (${scoreObj.progress}%)`
            }
            if (score < 69000 && score >= 49000) {
              return 'E' + ` (${scoreObj.progress}%)`
            }
            if (score < 49000) {
              return 'F' + ` (${scoreObj.progress}%)`
            }
          },
        },
      },
    },
  }

  const handleTimeRange = range => {
    const parsedRange = parseInt(range, 10)

    setActiveRange(parsedRange)
    updateUserPreferences({scoreOverTimeRange: parsedRange})
  }

  const getButtonClass = range =>
    range === activeRange
      ? 'mx-2 text-sideral-700 cursor'
      : 'mx-2 text-sky-500 underline'

  const isButtonDisabled = buttonRange => {
    if (!scoreOverTimeState || !scoreOverTimeState.dates) return true

    return buttonRange > scoreOverTimeState.dates.length
  }

  useEffect(() => {
    const userPreferences = localStorage.getItem('userPreferences')
    const rangeOptions = [15, 90, 180]

    try {
      const parsedPreferences = JSON.parse(userPreferences)

      if (
        parsedPreferences.scoreOverTimeRange &&
        rangeOptions.includes(parsedPreferences.scoreOverTimeRange)
      )
        setActiveRange(parsedPreferences.scoreOverTimeRange)
    } catch (e) {
      console.trace(e)
    }
  }, [])

  return (
    <div className="md:flex w-full">
      <div className="absolute top-0 right-0 mt-4 mr-2 text-sm text-sideral-100 word-spacing">
        <button
          onClick={e => handleTimeRange(e.target.value)}
          value={180}
          disabled={isButtonDisabled(180)}
          className={`${getButtonClass(180)} ${
            isButtonDisabled(180) ? 'opacity-25 cursor' : ''
          }`}>
          6m
        </button>
        <span className="cursor">|</span>
        <button
          onClick={e => handleTimeRange(e.target.value)}
          value={90}
          disabled={isButtonDisabled(90)}
          className={`${getButtonClass(90)} ${
            isButtonDisabled(90) ? 'opacity-25 cursor' : ''
          }`}>
          {' '}
          3m
        </button>
        <span className="cursor">|</span>
        <button
          onClick={e => handleTimeRange(e.target.value)}
          value={15}
          disabled={isButtonDisabled(15)}
          className={getButtonClass(15)}>
          {' '}
          15d
        </button>
      </div>
      <div className="w-full bg-white rounded-md p-2">
        <Chart
          className="mx-auto"
          options={scoreOvertimeChart.options}
          series={scoreOvertimeChart.series}
          type="area"
          width="100%"
          height="280"
        />
      </div>
    </div>
  )
}
