import PropTypes from 'prop-types'
import {i18next} from 'translate/i18n'
import React, {useEffect, useState} from 'react'
import Moment from 'react-moment'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate, useLocation, Link} from 'react-router-dom'

import {
  fetchNetworkStats,
  fetchHosts,
  fetchServices,
  fetchNetworkImportantFindings,
  fetchHostsByAsOrgs,
  fetchHostsByCountry,
  toggleRestrictionModal,
} from 'actions'

import {viewFindingsByCategory} from 'utils'
import {getFilterFromUrl} from 'utils/filters'
import PlanUtils from 'utils/plan'
import {sideral300, sideral700, sky500, whatsImportantFeedLimit} from 'constant'
import {generateCSV} from 'utils/csv'
import {getRequestParams} from 'utils/pagination'
import config from 'config'
import images from 'res'

import Spinner from '../partials/Spinner'
import TableServerSide from './tables/TableServerSide'
import PlaceholderContent from 'components/partials/PlaceholderContent'
import ScoreBox from 'components/partials/ScoreBox'
import StatsBox from 'components/partials/StatsBox'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import TableWrapper from 'components/partials/tables/TableWrapper'
import {Page} from 'components/partials/Page'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'

export default function Network() {
  const dispatch = useDispatch()
  const location = useLocation()

  const userState = useSelector(state => state.user)
  const networkImportantFindingsState = useSelector(
    state => state.networkImportantFindings
  )
  const hostsState = useSelector(state => state.hosts)
  const servicesState = useSelector(state => state.services)
  const hostsByAsOrgsState = useSelector(state => state.hostsByAsOrgs)
  const networkStatsState = useSelector(state => state.networkStats)
  const hostsByCountryState = useSelector(state => state.hostsByCountry)

  const [feed, setFeed] = useState([])
  const [fetching, setFetching] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [hosts, setHosts] = useState([])
  const [services, setServices] = useState([])
  const [filterOptions, setFilterOptions] = useState({})

  const planUtils = PlanUtils()

  const pageSize = 10
  const pageIndex = 0

  const handleGenerateHostsCSV = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )

    const url = new URL(`${config.SERVER_URL}/network/hosts`)

    url.search = new URLSearchParams(
      getRequestParams({
        format: 'csv',
        pageIndex: 0,
        filterOptions,
        filterValue: filterOptions.filterValue,
      })
    ).toString()

    window.location.href = url
  }

  const handleGenerateBreakdownCSV = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )

    const companyName = encodeURIComponent(userState.company.name.split(' ')[0])

    const csvData = services.map(service => {
      const row = {}
      row.protocol = service.protocol
      row.port = service.port
      row.servicesCount = service.servicesCount
      row.hostsCount = service.hostsCount
      return row
    })

    generateCSV({
      columns: ['protocol', 'port', 'servicesCount', 'hostsCount'],
      columnNames: [
        i18next.t('network.protocol'),
        i18next.t('network.port'),
        i18next.t('network.csvNservices'),
        i18next.t('network.csvNhosts'),
      ],
      filename: `${companyName}-${new Date().getTime()}-breakdown.csv`,
      data: csvData,
    })
  }

  const hostsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: 'HOST',
            accessor: 'name',
            width: 120,
          },
          {
            Header: i18next.t('network.relatedRecords').toUpperCase(),
            accessor: 'dnsRecords',
            disableSortBy: true,
          },
          {
            Header: i18next.t('network.servicesDetected').toUpperCase(),
            accessor: 'services',
            disableSortBy: true,
          },
        ],
      },
    ],
    []
  )

  const servicesColumns = React.useMemo(
    () => [
      {
        header: i18next.t('network.protocol'),
        accessorKey: 'protocol',
        size: 120,
        cell: cell => <div className="uppercase">{cell.getValue()}</div>,
      },
      {
        header: i18next.t('network.port'),
        accessorKey: 'port',
      },
      {
        header: i18next.t('network.nServices'),
        accessorKey: 'servicesCount',
        size: 100,
      },
      {
        header: i18next.t('network.nHosts'),
        accessorKey: 'hostsCount',
        size: 100,
      },
    ],

    []
  )

  useEffect(() => {
    dispatch(fetchHostsByAsOrgs())
    dispatch(fetchHostsByCountry())
    dispatch(fetchNetworkStats())

    if (!planUtils.hasPlanRestriction()) {
      dispatch(fetchNetworkImportantFindings())
    }

    dispatch(fetchServices())
    setFetching(true)
  }, [dispatch])

  useEffect(() => {
    if (Array.isArray(networkImportantFindingsState)) {
      const arr = [...networkImportantFindingsState]

      setFeed(arr)
      setFetching(false)
    }
  }, [networkImportantFindingsState])

  useEffect(() => {
    if (hostsState && hostsState.data && !firstLoad) {
      const hosts = hostsState

      setHosts(() => hosts)
    }
  }, [hostsState])

  useEffect(() => {
    if (servicesState) {
      const services = servicesState
      setServices(() => services)
    }
  }, [servicesState])

  useEffect(() => {
    const hostFilter = getFilterFromUrl('host', location)
    const asOrgFilter = getFilterFromUrl('asOrg', location)
    const countryFilter = getFilterFromUrl('country', location)

    dispatch(
      fetchHosts({
        filterValue: hostFilter,
        filterOptions: {
          asOrgFilter: [btoa(encodeURI(asOrgFilter))],
          countryFilter: [countryFilter],
        },
        pageIndex,
        limit: pageSize,
      })
    )

    setFirstLoad(false)
  }, [])

  useEffect(() => {
    if (!hosts || !services || !hostsByAsOrgsState) return

    const params = new URLSearchParams(window.location.search)

    if (params.has('asOrg') || params.has('country')) {
      document.getElementById('hosts-table').scrollIntoView()
    }
  }, [hosts, services, hostsByAsOrgsState])

  return (
    <Page pageTitle={i18next.t('pageTitles.network')} helpPath="network">
      {/* FIRST SECTION */}

      {networkStatsState && (
        <div className="grid md:grid-cols-2 xl:grid-cols-7 gap-4 w-full">
          <StatsBox
            classContainer="md:col-span-2 xl:col-span-3"
            slots={2}
            title={`/${i18next.t('misc.assets')}`}
            label="Hosts"
            data={networkStatsState.hosts || 0}
            secondaryLabel={i18next.t('network.services')}
            secondaryData={networkStatsState.services || 0}
          />

          <StatsBox
            classContainer="xl:col-span-2"
            slots={1}
            title={`/${i18next.t('misc.findings')}`}
            data={networkStatsState.findings || 0}
            link={{
              text: i18next.t('buttons.viewAll'),
              onClick: () => viewFindingsByCategory('network'),
            }}
            size="xs"
          />

          <ScoreBox
            classContainer="xl:col-span-2"
            score={(networkStatsState && networkStatsState.scores) || 0}
          />
        </div>
      )}

      <WhatsImportant fetching={fetching} feed={feed} />

      <TableWrapper
        title={i18next.t('network.servicesBreakdown')}
        id="services-breakdown-table"
        button={
          <button
            className="ml-auto"
            onClick={handleGenerateBreakdownCSV}
            title={i18next.t('buttons.downloadCSV')}>
            <images.DownloadIcon height="24" width="24" color={sky500} />
          </button>
        }
        loaded={Array.isArray(servicesState)}
        margin="mt-8"
        table={
          <PrimaryTableV8
            columns={servicesColumns}
            data={servicesState}
            pageSize={5}
            defaultSorting={[
              {
                id: 'hostsCount',
                desc: true,
              },
            ]}
            detailPath={row =>
              `/network/services/${row.original.protocol}/ports/${row.original.port}`
            }
            emptyStateWithFilter={
              <GenericEmptyState
                icon={<images.NetworkIcon width="55" color={sideral300} />}
                title={i18next.t('tables.nothingFoundWithSearch')}
                body={i18next.t('tables.searchSomethingElse')}
                margin="m-2 mt-4"
              />
            }
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<images.NetworkIcon width="55" color={sideral300} />}
                title={i18next.t('tables.nothingFound')}
                body={i18next.t('tables.waitAFewMinutes')}
                margin="m-2 mt-4"
              />
            }
          />
        }
      />

      <TableWrapper
        title={i18next.t('network.hostsFound')}
        id="hosts-table"
        button={
          <button
            className="ml-auto"
            onClick={handleGenerateHostsCSV}
            title={i18next.t('buttons.downloadCSV')}>
            <images.DownloadIcon height="24" width="24" color={sky500} />
          </button>
        }
        loaded={hosts && hosts.data}
        margin="mt-8"
        table={
          <TableServerSide
            columns={hostsColumns}
            setFilterOptions={setFilterOptions}
            asOrgOptions={hostsByAsOrgsState}
            countryFilterOptions={hostsByCountryState}
            data={hosts.data}
            fetchData={props =>
              dispatch(
                fetchHosts({
                  ...props,
                  limit: pageSize,
                })
              )
            }
            pageIndex={pageIndex}
            pageSize={pageSize}
            total={hosts.total}
            pageCount={hosts.pages}
            emptyStateWithFilter={
              <GenericEmptyState
                icon={<images.NetworkIcon width="55" color={sideral300} />}
                title={i18next.t('tables.nothingFoundWithSearch')}
                body={i18next.t('tables.searchSomethingElse')}
                margin="m-2 mt-4"
              />
            }
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<images.NetworkIcon width="55" color={sideral300} />}
                title={i18next.t('tables.nothingFound')}
                body={i18next.t('tables.waitAFewMinutes')}
                margin="m-2 mt-4"
              />
            }
          />
        }
      />
    </Page>
  )
}

const WhatsImportant = ({feed, fetching}) => {
  const navigate = useNavigate()

  const planUtils = PlanUtils()

  return (
    <div className="my-8 bg-white border rounded-md border-sky-900">
      <div className="dm-mono text-lg bg-sky-900 rounded-t text-white p-4">
        {i18next.t('titles.whatsImportant')}
      </div>
      <div className="bg-white rounded-b-md">
        {planUtils.hasPlanRestriction() ? (
          <PlaceholderContent sectionId="whatsImportant" />
        ) : !fetching ? (
          <div className="p-6 pt-0">
            {feed.slice(0, whatsImportantFeedLimit).map((finding, index) => {
              return (
                <div key={index}>
                  <div className="rounded-md border border-sideral-50 text-sm mt-6">
                    <div className="flex flex-wrap items-start leading-none w-full p-4">
                      <figure className="hidden lg:flex">
                        <images.FindingIcon height="20" width="20" />
                      </figure>
                      <div className="flex flex-wrap">
                        <Link
                          className="w-full text-sky-900 pl-2 leading-5"
                          to={`/network/finding/${finding.id}`}>
                          {finding.title}
                        </Link>
                        <span className="w-full text-astral-900 text-xs font-light pl-2 pt-2">
                          {finding.asset}
                        </span>
                      </div>
                    </div>
                    <div className="flex leading-6 font-light text-sideral-200 text-sm border-t border-sideral-50 p-4">
                      <div className="flex">
                        <images.ClockIcon width="20" color={sideral700} />
                        <Moment
                          date={finding.checkedAt}
                          fromNow
                          className="ml-2"
                        />
                      </div>
                      <SecondaryButton
                        text={i18next.t('buttons.details')}
                        direction="right"
                        size="sm"
                        onClick={() => {
                          navigate(`/network/finding/${finding.id}`)
                        }}
                        margin="ml-auto"
                      />
                    </div>
                  </div>
                </div>
              )
            })}
            {feed.length > whatsImportantFeedLimit && (
              <SecondaryButton
                text={i18next.t('buttons.viewAll')}
                margin="mt-4 text-center"
                size="sm"
                direction="right"
                redirect={viewFindingsByCategory(
                  'network',
                  '&sort=severity:desc'
                )}
              />
            )}
            {!feed.length && (
              <GenericEmptyState
                icon={<images.FindingIcon width="55" color="#90A4AE" />}
                body={i18next.t('web.noImportantFindings')}
                buttonText={i18next.t('buttons.viewAllFindings')}
                linkPath={viewFindingsByCategory('network')}
                margin="mt-6"
              />
            )}
          </div>
        ) : (
          <div className="pt-4">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
}

WhatsImportant.propTypes = {
  feed: PropTypes.array,
  fetching: PropTypes.bool,
}
