import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import Chevron from 'res/icons/chevron'
import {sideral300} from 'constant'
import {useDispatch, useSelector} from 'react-redux'
import {addTagToAsset} from 'actions'
import i18next from 'i18next'

export default function MarkNotSensitiveButton({
  resource,
  provider,
  findingId,
}) {
  const csrfState = useSelector(state => state.csrf)

  const dispatch = useDispatch()

  const [dropdownVisibility, setDropdownVisibility] = useState('hidden')

  function markNotSensitive() {
    dispatch(
      addTagToAsset({
        tagName: 'notSensitive',
        _csrf: csrfState,
        resource,
        provider,
        findingId,
      })
    )
  }

  function handleChevronClick() {
    dropdownVisibility === 'hidden'
      ? setDropdownVisibility('block')
      : setDropdownVisibility('hidden')
  }

  const closeFilterMenu = e => {
    const buttonContainer = document.querySelector('#button-container')

    if (!buttonContainer.contains(e.target)) setDropdownVisibility('hidden')
  }

  useEffect(() => {
    document.addEventListener('mouseup', closeFilterMenu)
    return () => document.removeEventListener('mouseup', closeFilterMenu)
  }, [])

  return (
    <div
      id="button-container"
      className="relative flex items-center cursor-pointer"
      onClick={handleChevronClick}>
      <div
        className={`${dropdownVisibility} absolute text-ink rounded-md top-0 left-0 ml-2 mt-5`}>
        <button
          id="button-element"
          className="font-light text-sm border rounded-md p-1 bg-gray-100 hover:bg-gray-100 w-56 -mt-2 py-2"
          onClick={markNotSensitive}>
          {i18next.t('cloud.markAsNonPersonal')}
        </button>
      </div>
      <div className="ml-2">
        <Chevron width="12" color={sideral300} direction="down" />
      </div>
    </div>
  )
}

MarkNotSensitiveButton.propTypes = {
  resource: PropTypes.object,
  isHovered: PropTypes.bool,
  provider: PropTypes.string,
  findingId: PropTypes.string,
}
