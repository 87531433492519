import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import Spinner from 'components/partials/Spinner'
import InfoIcon from 'res/icons/infoIcon.js'
import {i18next} from 'translate/i18n'
import Subsection from 'components/partials/headers/Subsection'

import {
  clearQrCode,
  disable2fa,
  enable2fa,
  generate2fa,
  loading,
  updatePassword,
} from 'actions'
import {isLoading} from 'utils'

const Password = () => {
  const csrfState = useSelector(state => state.csrf)
  const successState = useSelector(state => state.success)

  const dispatch = useDispatch()

  const [user, setUser] = useState({
    password: '',
    passwordConfirmation: '',
    currentPassword: '',
  })

  useEffect(() => {
    dispatch(loading({qrCode: true}))
  }, [dispatch])

  useEffect(() => {
    if (successState)
      setUser({
        password: '',
        passwordConfirmation: '',
        currentPassword: '',
      })
  }, [successState])

  return (
    <>
      <ChangePassword user={user} setUser={setUser} _csrf={csrfState} />
      <TwoFactorAuthentication />
    </>
  )
}

const ChangePassword = ({user, setUser, _csrf}) => {
  const dispatch = useDispatch()

  const [passwordConfirmationMatches, setPasswordConfirmationMatches] =
    useState(true)

  const handleInputChange = event => {
    setPasswordConfirmationMatches(true)

    var {name, value} = event.target
    setUser({...user, [name]: value})
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (user.password !== user.passwordConfirmation)
      return setPasswordConfirmationMatches(false)

    dispatch(updatePassword({...user, _csrf}))
  }
  const handlePasswdChange = () => {
    if (user.password !== user.passwordConfirmation) return
  }

  return (
    <Subsection
      title={i18next.t('titles.password')}
      content={
        <form onSubmit={handleSubmit}>
          <div className="flex border-b border-sideral-50 p-8 px-6">
            <div className="hidden sm:block w-4/12">
              <p className="text-sm text-sideral-900">
                {i18next.t('titles.changePassword')}
              </p>
              <p className="text-light text-xs text-sideral-300 pt-1">
                {i18next.t('subtitles.changePassword1')}
              </p>
              <p className="text-light text-xs text-sideral-300">
                {i18next.t('subtitles.changePassword2')}
              </p>
            </div>

            <div className="w-full sm:w-5/12">
              <PrimaryInput
                text={i18next.t('labels.currentPassword') + '*'}
                name="currentPassword"
                placeholder={i18next.t('placeholders.currentPassword')}
                type="password"
                value={user.currentPassword || ''}
                minLength={10}
                required={true}
                onChange={handleInputChange}
                margin="mb-8"
              />

              <PrimaryInput
                text={i18next.t('labels.newPassword') + '*'}
                name="password"
                placeholder={i18next.t('placeholders.minCharacters')}
                type="password"
                value={user.password || ''}
                minLength={10}
                required={true}
                onChange={handleInputChange}
                autoComplete="new-password"
                margin="mb-8"
              />

              <PrimaryInput
                text={i18next.t('labels.passwordConfirmation') + '*'}
                name="passwordConfirmation"
                placeholder={i18next.t('placeholders.newPasswordConfirmation')}
                type="password"
                value={user.passwordConfirmation || ''}
                minLength={10}
                required={true}
                hasError={!passwordConfirmationMatches}
                errorMsg={i18next.t('warnings.passwordConfirmationDontMatch')}
                onChange={handleInputChange}
                autoComplete="new-password"
                margin="mb-8"
              />

              <div className="flex justify-end">
                <PrimaryButton
                  text={i18next.t('buttons.saveChanges')}
                  onClick={handlePasswdChange}
                  theme="blue"
                  size="md"
                  type="submit"
                />
              </div>
            </div>
          </div>
        </form>
      }
    />
  )
}

const TwoFactorAuthentication = () => {
  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)
  const qrCodeState = useSelector(state => state.qrCode)
  const loadingState = useSelector(state => state.loading)

  const dispatch = useDispatch()

  const [form, setForm] = useState({
    code: '',
    password: '',
  })

  const handleDisable2fa = event => {
    event.preventDefault()
    dispatch(disable2fa({password: form.password, _csrf: csrfState}))
    setForm({...form, code: ''})
    dispatch(clearQrCode())
    dispatch(loading({qrCode: true}))
  }

  const handleGenerateQrCode = () => {
    dispatch(generate2fa({_csrf: csrfState}))
  }

  const handleSubmit2fa = event => {
    event.preventDefault()
    dispatch(enable2fa({code: form.code, _csrf: csrfState}))
  }

  const handleInputChange = event => {
    const {name, value} = event.target
    setForm({...form, [name]: value})
  }

  return (
    <Subsection
      title={i18next.t('titles.twoFactorAuthentication')}
      margin="mt-10"
      content={
        <div className="flex flex-wrap border-b border-sideral-50 px-6 py-8">
          <div className="hidden sm:block w-4/12">
            <p className="text-sm text-sideral-900">
              {i18next.t('titles.twoFactorAuthentication')}
            </p>
            <p className="text-light text-xs text-sideral-300 pt-1">
              {i18next.t('subtitles.twoFactorAuthentication')}
            </p>
          </div>
          <div className="flex items-center w-full sm:w-8/12 text-sm font-light">
            <div className="bg-sky-50 rounded inline-block p-1 mr-2">
              <InfoIcon size="24" height="24" color="#0A50A0" />
            </div>
            {i18next.t('help.twoFactorAuthentication')}
          </div>
          {userState && !userState.twoFactorEnabled ? (
            <div className="w-full sm:w-8/12 text-sm pt-8 pl-0 sm:pl-10 ml-0 sm:ml-auto">
              {qrCodeState ? (
                <>
                  <div className="flex flex-wrap justify-center md:justify-start">
                    <div className="text-center">
                      <div className="border inline-block p-2 w-48">
                        {isLoading(loadingState.qrCode) && <Spinner />}
                        <img
                          src={qrCodeState.qr}
                          onLoad={e => {
                            e.target.classList.remove('hidden')
                            dispatch(loading({qrCode: false}))
                          }}
                          alt="QR Code"
                          className="mx-auto"
                        />
                      </div>
                    </div>
                    <div className="xs:w-full sm:w-56 sm:ml-10 ml-0 mt-4 md:mt-0">
                      <form onSubmit={handleSubmit2fa}>
                        <PrimaryInput
                          text={i18next.t('labels.authenticationCode') + '*'}
                          name="code"
                          type="number"
                          value={form.code || ''}
                          required={true}
                          onChange={handleInputChange}
                          margin=""
                        />
                        <PrimaryButton
                          text={i18next.t(
                            'buttons.enableTwoFactorAuthentication'
                          )}
                          theme="blue"
                          size="full"
                          type="submit"
                        />
                      </form>
                    </div>
                  </div>
                </>
              ) : (
                <SecondaryButton
                  text={i18next.t('buttons.generateQrCode')}
                  direction="right"
                  size="sm"
                  onClick={handleGenerateQrCode}
                />
              )}
            </div>
          ) : (
            <div className="w-full sm:w-8/12 text-sm  pt-8 pl-0 sm:pl-10 ml-0 sm:ml-auto">
              <div className="pb-8 font-light">
                {i18next.t('warnings.twoFactorAuthenticationEnabled')}
              </div>
              <form onSubmit={handleDisable2fa} className="w-8/12">
                <PrimaryInput
                  text={i18next.t('labels.currentPassword') + '*'}
                  placeholder={i18next.t('placeholders.currentPassword')}
                  name="password"
                  value={form.password || ''}
                  required={true}
                  type="password"
                  onChange={handleInputChange}
                  margin="mb-8"
                />

                <div className="float-right">
                  <PrimaryButton
                    text={i18next.t('buttons.disableTwoFactorAuthentication')}
                    type="submit"
                    margin="ml-auto"
                  />
                </div>
              </form>
            </div>
          )}
        </div>
      }
    />
  )
}

ChangePassword.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  match: PropTypes.object,
  _csrf: PropTypes.string,
}

Password.propTypes = {
  match: PropTypes.object,
}

export default Password
