import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {useNavigate} from 'react-router-dom'

import {MapContainer, GeoJSON} from 'react-leaflet'
import {CRS} from 'leaflet'
import {scaleQuantize} from 'd3-scale'
import {color} from 'd3-color'
import {getDataUtils} from './dataUtils'
import CustomMapLegend from './CustomMapLegend'
import CustomMapTooltip from './CustomMapTooltip'
import geoData from './src/features.json'

import 'leaflet/dist/leaflet.css'
import './style.css'

const COLOR_FEATURE_BORDER = '#fff'
const COLOR_FEATURE_DISABLED = '#C9D7E5'
const COLOR_FEATURE_RANGE = [
  '#67c1f1',
  '#4faee7',
  '#369bdd',
  '#1e88d2',
  '#0375c6',
]

const isFeatureDisabled = featureColor =>
  featureColor === COLOR_FEATURE_DISABLED

const getFeatureStyle = featureColor => ({
  fillColor: featureColor,
  fillOpacity: 1,
  weight: 0.1,
  color: COLOR_FEATURE_BORDER,
  className: isFeatureDisabled(featureColor) ? 'disabled-feature' : '',
})

const highlightFeature = (e, featureColor) => {
  if (!isFeatureDisabled(featureColor)) {
    e.target.setStyle({
      fillColor: color(featureColor).brighter(0.3),
    })
  }
}

const resetHighlight = (e, featureColor) => {
  e.target.setStyle(getFeatureStyle(featureColor))
}

const openURL = (e, featureColor, navigate) => {
  const countryId = e.target.feature.properties.id

  if (!isFeatureDisabled(featureColor))
    navigate({
      pathname: '/network',
      search: `?country=${countryId}`,
    })
}

const onEachFeature = (_, layer, featureColor, history) => {
  layer.on({
    mouseover: e => highlightFeature(e, featureColor),
    mouseout: e => resetHighlight(e, featureColor),
    click: e => openURL(e, featureColor, history),
  })
}

const ChoroplethMap = ({data}) => {
  const navigate = useNavigate()
  const dataUtils = getDataUtils(data)

  const getColorScale = scaleQuantize()
    .domain([dataUtils.minCount, dataUtils.maxCount])
    .range(COLOR_FEATURE_RANGE)

  return (
    <MapContainer
      id="map-container"
      center={[15, 0]}
      minZoom={0}
      zoom={1}
      crs={CRS.EPSG4326}>
      {geoData.features.map(feature => {
        const featureItem = data.find(
          item => item.code === feature.properties.id
        )

        const featureColor = featureItem
          ? getColorScale(featureItem.count)
          : COLOR_FEATURE_DISABLED

        return (
          <GeoJSON
            key={feature.properties.id}
            data={feature}
            style={getFeatureStyle(featureColor)}
            onEachFeature={(_, layer) =>
              onEachFeature(_, layer, featureColor, navigate)
            }>
            {featureItem && (
              <CustomMapTooltip
                featureName={featureItem.name}
                featureCount={featureItem.count}
                featureColor={featureColor}
                sumCount={dataUtils.sumCount}
              />
            )}
          </GeoJSON>
        )
      })}
      <CustomMapLegend
        title={i18next.t('internetExposure.hostsByCountry.ipCount')}
        legendRange={dataUtils.legendRange}
        colorFeatureRange={COLOR_FEATURE_RANGE}
        disabled
      />
    </MapContainer>
  )
}

ChoroplethMap.propTypes = {
  data: PropTypes.array,
}

export default ChoroplethMap
