import React from 'react'
import PropTypes from 'prop-types'

export default function LgpdIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.8"
        d="M23.1867 8.57337C22.5067 5.14667 19.4933 2.66667 16 2.66667C13.2533 2.66667 10.7733 4.18667 9.50667 6.62667L8.84 7.89337L7.41333 8.03997C4.70667 8.31997 2.66667 10.6 2.66667 13.3334C2.66667 16.28 5.05333 18.6667 8 18.6667V21.3334C3.58667 21.3334 0 17.7467 0 13.3334C0 9.21337 3.12 5.81337 7.13333 5.38667C8.8 2.18667 12.1467 0 16 0C20.8533 0 24.8933 3.45334 25.8 8.05337C29.2667 8.29337 32 11.1467 32 14.6667C32 18.3467 29.0133 21.3334 25.3333 21.3334V18.6667C27.5333 18.6667 29.3333 16.8667 29.3333 14.6667C29.3333 12.6 27.7067 10.8534 25.6267 10.72L23.5867 10.5734L23.1867 8.57337Z"
        fill={color}
      />
      {/* opcao 1 */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6392 15.0063C23.8377 14.8106 23.8379 14.4904 23.6396 14.2946L21.6686 12.3472C21.4738 12.1548 21.1605 12.1548 20.9658 12.3472L15.3279 17.9174L13.0154 15.6163C12.8208 15.4227 12.5066 15.4221 12.3113 15.6151L10.3587 17.5442C10.1611 17.7394 10.1605 18.0584 10.3575 18.2543L13.9238 21.8028C14.7014 22.5766 15.9573 22.5795 16.7385 21.8093L23.6392 15.0063Z"
        fill={color}
      />
    </svg>
  )
}

LgpdIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
