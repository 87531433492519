import {call, put} from 'redux-saga/effects'
import {genericError} from 'actions'
import {isJsonString} from 'utils'

export function* handler({handler, action, success, error}) {
  try {
    const res = yield call(handler, action ? action.payload : null)

    if (!res.text) {
      yield put(
        genericError({
          error: true,
          code: res.payload?.action?.payload?.code,
        })
      )
      return
    }

    const text = yield res.text()

    const payload = isJsonString(text) ? JSON.parse(text) : {}

    if (res.status === 200 && success) {
      if (Array.isArray(success)) {
        for (const action of success) {
          yield put(action(payload))
        }
        return
      }

      yield put(success(payload))
      return
    }

    if (error) {
      if (Array.isArray(error)) {
        for (const action of error) {
          yield put(action(payload))
        }
        return
      }

      yield put(error(payload))
      return
    }
  } catch (e) {
    console.trace(e)
  }
}
