import React from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'

import {DefaultModal} from 'components/partials/DefaultModal'
import {removeQuestionnaireTemplate} from 'actions'
import {Trans} from 'react-i18next'

export default function QuestionnaireTemplateRemoveModal({
  removeModal,
  setRemoveModal,
}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)

  const handleRemoveQuestionnaire = questionnaireId => {
    dispatch(
      removeQuestionnaireTemplate({
        questionnaireId,
        _csrf: csrfState,
      })
    )

    setRemoveModal({
      selected: null,
      showModal: false,
    })
  }

  return (
    <DefaultModal
      id="removeModal"
      title={i18next.t('questionnaires.templates.remove.modal.title')}
      primaryButtonTheme="red"
      primaryBtnText={i18next.t('buttons.remove')}
      secondayBtnText={i18next.t('buttons.cancel')}
      body={
        <div className="text-sm text-gray-700 leading-6">
          <Trans
            i18nKey="questionnaires.templates.remove.modal.body"
            values={{
              templateName: removeModal.selected.name,
            }}
          />
        </div>
      }
      onClickPrimaryBtn={() =>
        handleRemoveQuestionnaire(removeModal.selected.id)
      }
      onClickSecondaryBtn={() => {
        setRemoveModal({
          selected: null,
          showModal: false,
        })
      }}
    />
  )
}

QuestionnaireTemplateRemoveModal.propTypes = {
  removeModal: PropTypes.object,
  setRemoveModal: PropTypes.func,
}
