import i18next from 'i18next'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useLocation} from 'react-router-dom'

import {
  fetchGoogleWorkspaceIntegrations,
  fetchMicrosoft365Integrations,
  genericError,
  loading,
  success,
  toggleRestrictionModal,
} from 'actions'

import {shouldRenderProvider, hasPeopleIntegration} from './peopleUtils'
import GoogleWorkspaceIntegration from './workspace/GoogleWorkspaceIntegration'
import Microsoft365Integration from './microsoft365/Microsoft365Integration'
import Spinner from 'components/partials/Spinner'
import Subsection from 'components/partials/headers/Subsection'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import images from 'res'
import {Page} from 'components/partials/Page'
import BackButton from 'components/partials/buttons/BackButton'
import PlanUtils from 'utils/plan'
import {isLoading} from 'utils'

export default function ManagePeopleIntegrations() {
  const loadingState = useSelector(state => state.loading)
  const googleWorkspaceIntegrationsState = useSelector(
    state => state.googleWorkspaceIntegrations
  )
  const microsoft365IntegrationsState = useSelector(
    state => state.microsoft365Integrations
  )

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const planUtils = PlanUtils()

  const goToNewIntegration = () => {
    navigate('/people/integrations/new')
  }

  useEffect(() => {
    dispatch(loading({googleWorkspaceIntegrations: true}))
    dispatch(loading({microsoft365Integrations: true}))

    dispatch(fetchGoogleWorkspaceIntegrations())
    dispatch(fetchMicrosoft365Integrations())
  }, [])

  useEffect(() => {
    if (!googleWorkspaceIntegrationsState || !microsoft365IntegrationsState)
      return

    if (
      !hasPeopleIntegration(
        googleWorkspaceIntegrationsState,
        microsoft365IntegrationsState
      )
    )
      navigate('/people/integrations/new')
  }, [googleWorkspaceIntegrationsState, microsoft365IntegrationsState])

  useEffect(() => {
    if (!location || !location.search) return

    const searchParamsIncludes = arg => location.search.indexOf(arg) !== -1

    if (searchParamsIncludes('integrationInProgress')) {
      dispatch(
        success({message: i18next.t('googleWorkspace.integrationInProgress')})
      )
      return
    }

    if (searchParamsIncludes('integrationFailed')) {
      dispatch(
        genericError({
          message: i18next.t('googleWorkspace.integrationFailed'),
        })
      )
      return
    }

    if (searchParamsIncludes('directoryIdAlreadyInUse')) {
      dispatch(
        genericError({
          message: i18next.t('microsoft365.directoryIdAlreadyInUse'),
        })
      )
      return
    }

    if (searchParamsIncludes('accessDenied')) {
      dispatch(
        genericError({
          message: i18next.t('googleWorkspace.accessDenied'),
        })
      )
      return
    }

    if (searchParamsIncludes('refreshTokenMissing')) {
      dispatch(
        genericError({
          message: i18next.t('googleWorkspace.refreshTokenMissing'),
        })
      )
      return
    }

    if (searchParamsIncludes('integrationNotFound')) {
      dispatch(
        genericError({
          message: i18next.t('googleWorkspace.integrationNotFound'),
        })
      )
      return
    }

    if (searchParamsIncludes('subscriptionLimit')) {
      dispatch(
        toggleRestrictionModal({
          contentId: 'addGoogleIdPBtn',
          showModal: true,
        })
      )
      return
    }

    if (searchParamsIncludes('error')) {
      dispatch(genericError({}))
      return
    }
  }, [location.search])

  if (!googleWorkspaceIntegrationsState || !microsoft365IntegrationsState)
    return <Spinner />

  return (
    <Page pageTitle={i18next.t('pageTitles.people')} helpPath="people">
      <BackButton fallbackLink={'/people'} forceFallbackLink />

      {isLoading(loadingState.googleWorkspaceIntegrations, false) ||
      isLoading(loadingState.microsoft365Integrations, false) ? (
        <Spinner />
      ) : (
        <>
          <Subsection
            title={i18next.t('googleWorkspace.integrations')}
            button={
              <SecondaryButton
                text={i18next.t('buttons.newIntegration')}
                size="sm"
                margin="ml-auto"
                onClick={goToNewIntegration}
                crownIcon={planUtils.hasPlanRestriction()}
                plusIcon
              />
            }
            content={<Providers />}
            margin="mt-4"
          />
        </>
      )}
    </Page>
  )
}

const Providers = () => {
  const googleWorkspaceIntegrationsState = useSelector(
    state => state.googleWorkspaceIntegrations
  )
  const microsoft365IntegrationsState = useSelector(
    state => state.microsoft365Integrations
  )

  return (
    <>
      {(!googleWorkspaceIntegrationsState ||
        !Array.isArray(googleWorkspaceIntegrationsState)) && <Spinner />}
      {shouldRenderProvider(googleWorkspaceIntegrationsState) ? (
        <>
          <div className="flex items-center ml-6 mt-4 mb-4">
            <figure className={'w-10 mr-4'}>
              <img className="w-full" src={images.googleIcon} />
            </figure>
            <h2>Google Workspace</h2>
          </div>{' '}
          {googleWorkspaceIntegrationsState.map(integration => (
            <GoogleWorkspaceIntegration
              integration={integration}
              key={integration.id}
            />
          ))}
        </>
      ) : (
        <></>
      )}
      {shouldRenderProvider(microsoft365IntegrationsState) ? (
        <>
          <div className="flex items-center ml-6 mt-4 mb-4">
            <figure className={'w-10 mr-4'}>
              <img className="w-full" src={images.microsoftIcon} />
            </figure>
            <h2>Microsoft 365</h2>
          </div>
          {microsoft365IntegrationsState.map(integration => (
            <Microsoft365Integration
              integration={integration}
              key={integration.id}
            />
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

ManagePeopleIntegrations.propTypes = {
  location: PropTypes.object,
}
