import React, {useEffect, useState} from 'react'
import {batch, useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'

import {
  clearFilters,
  fetchPartnerFindings,
  redirect404,
  toggleRestrictionModal,
} from 'actions'
import {sideral300, sky500, statusList} from 'constant'
import {hasFeature} from 'utils'
import images from 'res'
import {generateCSV, truncate} from 'utils/csv'
import PlanUtils from 'utils/plan'
import {
  matchesSeverityMultiSelect,
  SeverityFilter,
  StatusFilter,
} from './Filters'

import Spinner from 'components/partials/Spinner'
import Table from './Table'
import TableWrapper from 'components/partials/tables/TableWrapper'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import {getSeverityLabel} from 'utils/score'
import {Page} from 'components/partials/Page'

export default function PartnerDashboard() {
  const userState = useSelector(state => state.user)
  const partnerFindingsState = useSelector(state => state.partnerFindings)

  const dispatch = useDispatch()
  const [filteredFindings, setFilteredFindings] = useState([])

  const planUtils = PlanUtils()

  const columns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.title'),
            accessor: 'title',
            disableFilters: true,
          },
          {
            Header: i18next.t('tables.asset'),
            accessor: 'asset',
            width: 120,
            disableFilters: true,
          },
          {
            Header: i18next.t('tables.severity'),
            accessor: 'severity',
            Filter: SeverityFilter,
            filter: matchesSeverityMultiSelect,
            width: 30,
          },
          {
            Header: 'Status',
            accessor: 'status',
            Filter: StatusFilter,
            filter: matchesSeverityMultiSelect,
            defaultValue: [
              {
                value: 'open',
                label: i18next.t('status.open'),
              },
              {
                value: 'reopened',
                label: i18next.t('status.reopened'),
              },
            ],
            width: 30,
          },
          {
            Header: i18next.t('tables.updatedAt'),
            disableFilters: true,
            accessor: 'updatedAt',
            width: 50,
            sortType: (a, b) => {
              return (
                new Date(a.original.updatedAt) - new Date(b.original.updatedAt)
              )
            },
          },
        ],
      },
    ],
    []
  )
  const handleGenerateCSV = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )

    const companyName = encodeURIComponent(userState.company.name.split(' ')[0])

    const csvData = filteredFindings.map(finding => {
      const row = {}

      row.asset = finding.asset
      row.severity = getSeverityLabel(finding.severity)
      row.title = truncate(finding.title.replace(/,/g, ''))
      row.status = statusList[finding.status]
      row.updatedAt = finding.updatedAt

      return row
    })

    generateCSV({
      columns: ['asset', 'title', 'severity', 'status', 'updatedAt'],
      columnNames: [
        i18next.t('tables.asset'),
        i18next.t('tables.finding'),
        i18next.t('tables.severity'),
        i18next.t('tables.status'),
        i18next.t('tables.updatedAt'),
      ],
      filename: `${companyName}-${new Date().getTime()}-findings.csv`,
      data: csvData,
    })
  }

  useEffect(() => {
    if (userState && !hasFeature(userState, 'partner')) dispatch(redirect404())
  }, [userState])

  useEffect(() => {
    batch(() => {
      dispatch(fetchPartnerFindings())
      dispatch(clearFilters({except: 'partner'}))
    })
  }, [])

  return (
    <Page pageTitle={i18next.t('pageTitles.wiser')}>
      {partnerFindingsState ? (
        <TableWrapper
          title={`${i18next.t('titles.allFindingsFor')} ${
            userState.company?.name
          }`}
          button={
            <button
              className="ml-auto"
              onClick={handleGenerateCSV}
              title={i18next.t('buttons.downloadCSV')}>
              <images.DownloadIcon height="24" width="24" color={sky500} />
            </button>
          }
          loaded={partnerFindingsState}
          margin="mt-10"
          table={
            <Table
              columns={columns}
              data={partnerFindingsState}
              pageNumber={0}
              pageSize={10}
              setFilteredFindings={setFilteredFindings}
              emptyStateWithFilter={
                <GenericEmptyState
                  icon={<images.ManagementIcon width="55" color={sideral300} />}
                  title={i18next.t('tables.nothingFoundWithSearch')}
                  body={i18next.t('tables.searchSomethingElse')}
                  margin={'mt-6 mb-2'}
                />
              }
              emptyStateWithoutFilter={
                <GenericEmptyState
                  icon={<images.ManagementIcon width="55" color={sideral300} />}
                  title={i18next.t('titles.goodNews')}
                  body={i18next.t('management.noFindings')}
                  margin={'mt-6 mb-2'}
                />
              }
            />
          }
        />
      ) : (
        <>
          <Spinner />
        </>
      )}
    </Page>
  )
}
