import React from 'react'
import PropTypes from 'prop-types'

export default function WarningIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.94024 34.9999H35.0602C38.1402 34.9999 40.0602 31.6599 38.5202 28.9999L23.4602 2.97986C21.9202 0.319863 18.0802 0.319863 16.5402 2.97986L1.48024 28.9999C-0.0597575 31.6599 1.86024 34.9999 4.94024 34.9999ZM20.0002 20.9999C18.9002 20.9999 18.0002 20.0999 18.0002 18.9999V14.9999C18.0002 13.8999 18.9002 12.9999 20.0002 12.9999C21.1002 12.9999 22.0002 13.8999 22.0002 14.9999V18.9999C22.0002 20.0999 21.1002 20.9999 20.0002 20.9999ZM22.0002 28.9999H18.0002V24.9999H22.0002V28.9999Z"
        fill={color}
      />
    </svg>
  )
}

WarningIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
