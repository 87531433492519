import React, {useState} from 'react'
import moment from 'moment'
import {i18next} from 'translate/i18n'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import config from 'config'
import {v4 as uuidv4} from 'uuid'

import {clearSuccess, inviteMember} from 'actions'

import Badge from 'components/partials/Badge'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import MemberItem from './MemberItem'
import RemoveInviteModal from './RemoveInviteModal'
import {userRolesList} from 'constant'

export default function InvitesList({inviteList}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)

  const [removeInviteModal, setRemoveInviteModal] = useState({
    inviteId: null,
    inviteEmail: '',
    showModal: false,
  })

  // Get expirationDate from a invite or figure it out using config
  const getExpirationDate = invite => {
    const creationDate = new Date(invite.createdAt)
    return (
      new Date(invite.expiresAt).getTime() ||
      creationDate.setDate(creationDate.getDate() + config.invite.daysToExpire)
    )
  }

  const isExpired = invite => {
    const expirationDate = getExpirationDate(invite)
    return expirationDate < new Date().getTime()
  }

  const handleResendInvite = expiredInvite => {
    expiredInvite.isLoading = true

    dispatch(
      inviteMember({
        _csrf: csrfState,
        email: expiredInvite.email,
        name: expiredInvite.name,
      })
    )
  }

  return (
    <>
      {inviteList.map(
        invite =>
          invite.status !== 'accepted' && (
            <MemberItem
              key={uuidv4()}
              name={invite.name}
              email={invite.email}
              role={
                userRolesList.find(role => role.value === invite.role).label
              }
              onDelete={() => {
                dispatch(clearSuccess())
                setRemoveInviteModal({
                  inviteId: invite.id,
                  inviteEmail: invite.email,
                  showModal: true,
                })
              }}
              rightSideContent={
                isExpired(invite) ? (
                  <>
                    <Badge
                      label={i18next.t('labels.inviteExpired')}
                      style="error"
                    />

                    <SecondaryButton
                      text={
                        invite.isLoading
                          ? i18next.t('buttons.resending')
                          : i18next.t('buttons.resendInvite')
                      }
                      disabled={invite.isLoading}
                      onClick={() => handleResendInvite(invite)}
                      size="sm"
                      margin="ml-4"
                    />
                  </>
                ) : (
                  <Badge
                    label={i18next.t('labels.inviteExpires', {
                      datetime: moment(getExpirationDate(invite))
                        .locale(i18next.language)
                        .fromNow(),
                    })}
                    style="warning"
                  />
                )
              }
            />
          )
      )}

      {removeInviteModal.showModal && (
        <RemoveInviteModal
          removeInviteModal={removeInviteModal}
          setRemoveInviteModal={setRemoveInviteModal}
        />
      )}
    </>
  )
}

InvitesList.propTypes = {
  inviteList: PropTypes.array,
}
