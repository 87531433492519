import React from 'react'
import PropTypes from 'prop-types'

export default function RetryIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={color || '#E20026'}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0711 7.99552C15.6179 8.07328 15.9981 8.57957 15.9204 9.12636C15.6826 10.7983 14.9218 12.3522 13.747 13.5654C12.5721 14.7785 11.0435 15.5888 9.37999 15.8801C7.7165 16.1714 6.00349 15.9288 4.48631 15.187C3.77335 14.8385 3.12082 14.3881 2.5472 13.8537L1.70711 14.6938C1.07714 15.3238 0 14.8776 0 13.9867V9.99998H3.98673C4.87763 9.99998 5.3238 11.0771 4.69383 11.7071L3.9626 12.4383C4.38006 12.8181 4.85153 13.1394 5.36475 13.3903C6.50264 13.9466 7.78739 14.1285 9.03501 13.9101C10.2826 13.6916 11.4291 13.0839 12.3102 12.174C13.1914 11.2641 13.762 10.0988 13.9403 8.84476C14.0181 8.29798 14.5244 7.91776 15.0711 7.99552ZM11.5137 0.812976C12.2279 1.16215 12.8814 1.61349 13.4558 2.14905L14.2929 1.31193C14.9229 0.681961 16 1.12813 16 2.01904V6.00001H12.019C11.1281 6.00001 10.6819 4.92287 11.3119 4.29291L12.0404 3.56441C11.6222 3.18346 11.1497 2.86125 10.6353 2.60973C9.49736 2.05342 8.21261 1.87146 6.96499 2.08994C5.71737 2.30841 4.57089 2.91611 3.68976 3.82599C2.80862 4.73586 2.23802 5.90125 2.05969 7.15524C1.98193 7.70202 1.47564 8.08224 0.928858 8.00448C0.382075 7.92672 0.00185585 7.42043 0.0796146 6.87364C0.31739 5.20166 1.07818 3.64782 2.25303 2.43465C3.42788 1.22148 4.95652 0.411217 6.62001 0.119916C8.2835 -0.171384 9.99651 0.071218 11.5137 0.812976Z" />
    </svg>
  )
}

RetryIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
