import i18next from 'i18next'
import React from 'react'
import PropTypes from 'prop-types'

export default function ErrorHandling() {
  return (
    <div className="text-sm mt-4">
      <p className="text-mars-700">{i18next.t('people.failedIntegration')}</p>
      <p className="font-light">
        {i18next.t('warnings.contactUs')} support@unxpose.com
      </p>
    </div>
  )
}

ErrorHandling.propTypes = {
  currentIntegration: PropTypes.object,
}
