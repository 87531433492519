import React from 'react'
import PropTypes from 'prop-types'

export default function SecondaryCheckbox(props) {
  return (
    <div
      className={`flex ${props.fullWidth && 'w-full'} ${
        props.margin ? props.margin : ''
      }`}>
      <input
        className="appearance-none"
        type="checkbox"
        name={props.name}
        id={props.id}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        required={props.required || null}
        value={props.value}
        onChange={props.onChange || null}
        disabled={props.disabled || null}
      />
      <label
        htmlFor={props.id}
        className={`${
          props.checked
            ? 'border-sky-900 bg-sky-900 text-white'
            : 'bg-white text-sideral-300 border-sideral-100'
        } ${props.borderRadius || 'rounded-lg'} ${
          props.fullWidth && 'grow self-stretch content-center'
        } ${
          !props.disabled && 'cursor-pointer'
        } hover:shadow-sm border select-none text-sm text-center p-2`}>
        {props.text}
      </label>
    </div>
  )
}

SecondaryCheckbox.propTypes = {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.any,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  borderRadius: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  text: PropTypes.string,
  value: PropTypes.any,
}
