const getDataUtils = data => {
  const LEGEND_RANGE_LENGTH = 5
  const getRoundedNumber = number => Math.ceil(number / 10) * 10

  const countValues = data.map(item => item.count)

  const minCount = Math.min(...countValues)
  const maxCount = Math.max(...countValues)
  const sumCount = countValues.reduce((sumCount, count) => sumCount + count, 0)

  let sum = 0
  const legendRange = []
  for (let i = 1; i <= LEGEND_RANGE_LENGTH; i++) {
    if (i === 1) legendRange.push(getRoundedNumber(minCount))
    else if (i === LEGEND_RANGE_LENGTH)
      legendRange.push(getRoundedNumber(maxCount))
    else {
      sum += (maxCount - minCount) / (LEGEND_RANGE_LENGTH - 1)
      legendRange.push(getRoundedNumber(sum))
    }
  }

  return {minCount, maxCount, sumCount, legendRange}
}

export {getDataUtils}
