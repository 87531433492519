import i18next from 'i18next'
import React from 'react'

import images from 'res'

import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import BackButton from 'components/partials/buttons/BackButton'
import {Page} from 'components/partials/Page'
import PlanUtils from 'utils/plan'
import {useDispatch, useSelector} from 'react-redux'
import {
  fetchGoogleWorkspaceAuthorization,
  fetchMicrosoft365Authorization,
  toggleRestrictionModal,
} from 'actions'
import {getFeatureSubscription} from 'utils'

export default function AddIntegration() {
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)

  const figureContainerStyle = 'flex justify-center mb-8 mt-4'
  const textcontainerStyle = 'text-astral-900 text-base'

  const planUtils = PlanUtils()

  const hasAccessToFeature = () =>
    planUtils.userHasPremiumPlan() &&
    getFeatureSubscription(userState, 'idpIntegrations').hasAccess

  const handleGoogle = e => {
    e.preventDefault()

    if (!hasAccessToFeature())
      return dispatch(
        toggleRestrictionModal({contentId: 'addGoogleIdPBtn', showModal: true})
      )

    dispatch(fetchGoogleWorkspaceAuthorization({_csrf: csrfState}))
  }

  function handleMicrosoft(e) {
    e.preventDefault()

    if (!hasAccessToFeature())
      return dispatch(
        toggleRestrictionModal({contentId: 'add365IdPBtn', showModal: true})
      )

    dispatch(fetchMicrosoft365Authorization({_csrf: csrfState}))
  }

  return (
    <Page pageTitle={i18next.t('pageTitles.people')} helpPath="people">
      <>
        <BackButton fallbackLink="/people" forceFallbackLink />

        <div
          className={`flex flex-wrap justify-center border border-sideral-100 bg-white rounded-lg p-2 py-16 mt-4`}>
          <div className="w-full text-center text-astral-700">
            <p className="text-base md:text-lg">
              {i18next.t('people.addIntegration.title1')}
              <br />
              {i18next.t('people.addIntegration.title2')}
            </p>
            <p className="text-sm font-light mt-4">
              {i18next.t('people.addIntegration.subtitle')}
            </p>
          </div>

          <div className="flex flex-wrap justify-around">
            <div>
              <div className="relative flex flex-col items-center text-center text-sm border border-sideral-100 rounded-md h-80 w-72 pt-6 mt-16 px-2 mr-20">
                <div className={textcontainerStyle}>
                  <figure className={figureContainerStyle}>
                    <images.GoogleWorkspaceLogo height="24" />
                  </figure>

                  <div className="flex flex-wrap items-center justify-center text-sm text-center text-astral-700 px-2 pb-6 font-light">
                    <p>{i18next.t('googleWorkspace.permissionsNeeded')}</p>
                    <ul className="text-left pt-1 ml-4 font-normal">
                      <li>• Domain Settings</li>
                      <li>• Domain Management</li>
                      <li>• Reports</li>
                      <li>• Users (read)</li>
                    </ul>
                  </div>
                </div>
                <div className="absolute bottom-0 mb-6">
                  <SecondaryButton
                    text={i18next.t('buttons.startMonitoring')}
                    onClick={handleGoogle}
                    direction="right"
                    size="sm"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap justify-around">
            <div>
              <div className="relative flex flex-col items-center text-center text-sm border border-sideral-100 rounded-md w-72 h-80 pt-6 mt-16 px-2">
                <div className={textcontainerStyle}>
                  <figure className={figureContainerStyle}>
                    <img src={images.Microsoft365Logo} className="h-8" />
                  </figure>
                  <div className="flex flex-wrap items-center justify-center text-sm text-center text-astral-700 px-2 pb-6 font-light">
                    <p>{i18next.t('microsoft365.adminAccessNeeded')}</p>
                  </div>
                </div>
                <div className="absolute bottom-0 mb-6">
                  <SecondaryButton
                    text={i18next.t('buttons.startMonitoring')}
                    onClick={handleMicrosoft}
                    direction="right"
                    size="sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Page>
  )
}
