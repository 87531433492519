import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import GlobalFilter from 'components/partials/tables/GlobalFilter'
import Pagination from 'components/partials/tables/Pagination'
import images from 'res'

export default function IntegrationsTable(props) {
  const [filterValue, setFilterValue] = useState('')
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    rows,

    canPreviousPage,
    canNextPage,

    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    state: {pageIndex},
    setGlobalFilter,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        pageIndex: props.pageNumber,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  return (
    <div>
      <div className="tableWrap relative">
        <div className="flex justify-between items-center pb-2">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            value={filterValue}
            setFilterValue={setFilterValue}
            setGlobalFilter={setGlobalFilter}
            gotoPage={gotoPage}
          />
          <Pagination
            handlePrevious={previousPage}
            handleNext={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            paginationClass={props.paginationClass}
            pageIndex={pageIndex}
            pageCount={pageCount}
            total={rows.length || 0}
          />
        </div>
        {page && page.length ? (
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map(headerGroup => {
                // trick to disable the first headerGroup
                if (headerGroup.headers.length === 1)
                  return <tr key={uuidv4()}></tr>

                return (
                  <tr key={uuidv4()} className="flex p-2">
                    {headerGroup.headers.map(column => (
                      <th
                        className="dm-mono text-left text-sideral-300 text-sm"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={uuidv4()}>
                        {typeof column.Header === 'string'
                          ? column.render('Header').toUpperCase()
                          : column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' ↓'
                              : ' ↑'
                            : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                )
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    className="text-astral-900 border-t border-sideral-50 hover:bg-sideral-50 py-2 text-sm"
                    key={uuidv4()}>
                    {row.cells.map(cell => {
                      if (cell.column.id === 'checkbox') {
                        const checkedIntegrationsIds =
                          props.checkedIntegrations.map(i => i.integrationId)

                        return (
                          <td className="w-10 ml-2 pt-2" key={uuidv4()}>
                            <input
                              checked={checkedIntegrationsIds.includes(
                                cell.row.original.integrationId
                              )}
                              type="checkbox"
                              className="bg-white cursor-pointer"
                              name={cell.row.original.integrationId}
                              onChange={e =>
                                props.handleCheckboxClick(e, cell.row.original)
                              }
                            />
                          </td>
                        )
                      }

                      if (cell.column.id === 'providerFullName')
                        return (
                          <td {...cell.getCellProps()} key={uuidv4()}>
                            {cell.value === 'Amazon Web Services' && (
                              <div className="flex items-center ml-12 h-8">
                                <img
                                  src={images.awsIcon}
                                  width="26"
                                  className="mr-2"
                                />
                                <p>{cell.render('Cell')}</p>
                              </div>
                            )}
                            {cell.value === 'Google Cloud Platform' && (
                              <div className="flex items-center ml-12 h-8">
                                <img
                                  src={images.gcpIcon}
                                  width="26"
                                  className="mr-2"
                                />
                                <p>{cell.render('Cell')}</p>
                              </div>
                            )}
                            {cell.value === 'Microsoft Azure' && (
                              <div className="flex items-center ml-12 h-8">
                                <img
                                  src={images.azureIcon}
                                  width="24"
                                  className="mr-2"
                                />
                                <p>{cell.render('Cell')}</p>
                              </div>
                            )}
                          </td>
                        )

                      return (
                        <td
                          {...cell.getCellProps()}
                          key={uuidv4()}
                          className="mt-2">
                          {cell.value ? cell.render('Cell') : '—'}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <>
            {filterValue
              ? props.emptyStateWithFilter
              : props.emptyStateWithoutFilter}
          </>
        )}
      </div>
    </div>
  )
}

IntegrationsTable.propTypes = {
  defaultSorted: PropTypes.array,
  pageNumber: PropTypes.number,
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  paginationClass: PropTypes.string,
  history: PropTypes.object,
  checkedIntegrations: PropTypes.array,
  handleCheckboxClick: PropTypes.func,
}
