import {workspaceErrors} from 'constant'
import i18next from 'i18next'
import React from 'react'
import PropTypes from 'prop-types'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import {useDispatch, useSelector} from 'react-redux'
import {updateGoogleWorkspaceIntegration} from 'actions'

export default function ErrorHandling({currentIntegration}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)

  if (!currentIntegration.logs || typeof currentIntegration.logs !== 'object')
    return (
      <div className="text-sm mt-4">
        <p className="text-mars-700">{i18next.t('people.failedIntegration')}</p>
        <p className="font-light">
          {i18next.t('warnings.contactUs')} support@unxpose.com
        </p>
      </div>
    )

  const getErrorTemplate = (errorArr, errorType) => {
    if (!workspaceErrors.messages[errorType]) return

    return (
      <div className="mt-4">
        <span className="block font-light">
          {workspaceErrors.messages[errorType]}
        </span>
        <ul className="list-disc">
          {errorArr.map((item, index) => {
            const [apiName] = item

            if (workspaceErrors.apiNames[apiName] !== 'Customer ID')
              return (
                <li key={`error ${index}`} className="font-medium ml-8">
                  {workspaceErrors.apiNames[apiName]}
                </li>
              )
          })}
        </ul>
      </div>
    )
  }

  const getErrorMessages = logs => {
    const entries = Object.entries(logs)
    const invalidGrant = []
    const notAuthorizedErrors = []
    const insufficientPermissionErrors = []
    const unauthorizedClient = []
    const customerIdAlreadyInUse = []
    const unknownErrors = []

    entries.forEach(entry => {
      const [, value] = entry

      if (!value || !value.code) return

      switch (value.code) {
        case 'NOT_AUTHORIZED': {
          notAuthorizedErrors.push(entry)
          break
        }
        case 'INSUFFICIENT_PERMISSION': {
          insufficientPermissionErrors.push(entry)
          break
        }
        case 'INVALID_GRANT': {
          invalidGrant.push(entry)
          break
        }
        case 'UNAUTHORIZED_CLIENT': {
          unauthorizedClient.push(entry)
          break
        }
        case 'CUSTOMER_ID_ALREADY_IN_USE': {
          customerIdAlreadyInUse.push(entry)
          break
        }
        case 'UNKNOWN_ERROR': {
          unknownErrors.push(entry)
          break
        }
      }
    })

    return (
      <>
        {notAuthorizedErrors.length ? (
          getErrorTemplate(notAuthorizedErrors, 'NOT_AUTHORIZED')
        ) : (
          <></>
        )}
        {insufficientPermissionErrors.length ? (
          getErrorTemplate(
            insufficientPermissionErrors,
            'INSUFFICIENT_PERMISSION'
          )
        ) : (
          <></>
        )}
        {invalidGrant.length ? (
          getErrorTemplate(insufficientPermissionErrors, 'INVALID_GRANT')
        ) : (
          <></>
        )}
        {unauthorizedClient.length ? (
          getErrorTemplate(unauthorizedClient, 'UNAUTHORIZED_CLIENT')
        ) : (
          <></>
        )}
        {customerIdAlreadyInUse.length ? (
          getErrorTemplate(customerIdAlreadyInUse, 'CUSTOMER_ID_ALREADY_IN_USE')
        ) : (
          <></>
        )}
        {unknownErrors.length ? (
          getErrorTemplate(unknownErrors, 'UNKNOWN_ERROR')
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <div className="flex text-sm mt-4 flex-col">
      <div className="border-b">
        {currentIntegration.status === 'active' ? (
          <p className="text-mars-700">
            {i18next.t('googleWorkspace.updateRequired')}
          </p>
        ) : (
          <p className="text-mars-700">
            {i18next.t('cloud.failedIntegration')}
          </p>
        )}
        <div className="mb-4">{getErrorMessages(currentIntegration.logs)}</div>
        {currentIntegration.status === 'active' ? (
          <SecondaryButton
            text={i18next.t('googleWorkspace.clickHereToUpdate')}
            onClick={() => {
              dispatch(
                updateGoogleWorkspaceIntegration({
                  integrationId: currentIntegration.id,
                  _csrf: csrfState,
                })
              )
            }}
            size="sm"
            margin="mb-4"
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

ErrorHandling.propTypes = {
  currentIntegration: PropTypes.object,
}
