import {reactSelectStyles, reactSelectTheme} from 'constant'
import i18next from 'i18next'
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

export function RegistrarFilter(props) {
  const {filterValue, setFilter} = props.column

  const [options, setOptions] = useState([])

  function getOptions(data) {
    const registrars = new Set()
    let unknownOption = false
    const options = []

    data.map(domain => {
      if (domain.registrar) return registrars.add(domain.registrar)
      unknownOption = true
    })

    for (const registrar of registrars) {
      options.push({
        label: registrar,
        value: registrar,
      })
    }

    const sortedOptions = [...options]

    if (unknownOption)
      sortedOptions.push({
        label: i18next.t('internetExposure.registrarNotDetected'),
        value: 'unknown',
      })

    return sortedOptions
  }

  function handleChange(selectedOption) {
    setFilter(selectedOption)
    window.history.pushState(null, null, window.location.pathname)
  }

  useEffect(() => {
    setOptions(getOptions(props.data))
  }, [])

  useEffect(() => {
    if (!options || !options.length) {
      return
    }

    const params = new URLSearchParams(window.location.search)

    if (!params.has('registrar')) {
      return
    }

    if (
      !filterValue ||
      (filterValue &&
        filterValue.value &&
        filterValue[0] !== params.get('registrar'))
    ) {
      const selectedOption = options.find(
        option => option.value === params.get('registrar')
      )

      if (selectedOption) {
        setFilter([selectedOption])
        return
      }
    }
  }, [options])

  return (
    <Select
      isMulti
      placeholder={i18next.t('dns.filterByRegistrar.placeholder')}
      styles={reactSelectStyles}
      options={options}
      theme={reactSelectTheme}
      value={filterValue}
      onChange={handleChange}
    />
  )
}

RegistrarFilter.propTypes = {
  column: PropTypes.object,
  data: PropTypes.array,
}
