import React from 'react'
import PropTypes from 'prop-types'
import SectionHeader from './headers/SectionHeader'
import {usePageTitle} from 'utils'

export const Page = ({
  pageTitle,
  helpPath,
  button,
  secondButton,
  children,
  hideHeader = false,
}) => {
  usePageTitle(pageTitle)

  return (
    <>
      {!hideHeader && (
        <SectionHeader
          title={pageTitle}
          helpPath={helpPath}
          button={button}
          secondButton={secondButton}
        />
      )}
      <div id={`${pageTitle}-content`}>{children}</div>
    </>
  )
}

Page.propTypes = {
  pageTitle: PropTypes.string,
  helpPath: PropTypes.string,
  button: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  secondButton: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hideHeader: PropTypes.bool,
}
