import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types'

export default function VerificationCodeInput({
  length,
  label,
  loading,
  onComplete,
}) {
  const [code, setCode] = useState([...Array(length)].map(() => ''))
  const inputs = useRef([])

  const processInput = (e, slot) => {
    const num = e.target.value
    if (/[^0-9]/.test(num)) return
    const newCode = [...code]
    newCode[slot] = num
    setCode(newCode)
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus()
    }
    if (newCode.every(num => num !== '')) {
      onComplete(newCode.join(''))
    }
  }

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code]
      newCode[slot - 1] = ''
      setCode(newCode)
      inputs.current[slot - 1].focus()
    }
  }

  return (
    <div className="flex flex-wrap justify-between w-full">
      <label className="block tracking-wide text-sideral-700 text-xs font-medium mb-1 w-full">
        {label}
      </label>
      {code.map((num, idx) => {
        return (
          <input
            key={idx}
            type="text"
            inputMode="numeric"
            maxLength={1}
            value={num}
            autoFocus={!code[0].length && idx === 0}
            readOnly={loading}
            onChange={e => processInput(e, idx)}
            onKeyUp={e => onKeyUp(e, idx)}
            ref={ref => inputs.current.push(ref)}
            className="text-center border border-sideral-300 rounded focus:border-opacity-0 h-10 w-10 mt-4"
          />
        )
      })}
    </div>
  )
}

VerificationCodeInput.propTypes = {
  length: PropTypes.number,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onComplete: PropTypes.func,
}
