import i18next from 'i18next'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import {fetchDnsRecordsLogs} from 'actions'
import images from 'res'
import {sideral300} from 'constant'

import BackButton from 'components/partials/buttons/BackButton'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import GenericLogsTable from 'components/partials/GenericLogsTable'
import TableWrapper from 'components/partials/tables/TableWrapper'
import Spinner from 'components/partials/Spinner'
import {TypeFilter, matchesMultiSelect} from './filters/TypeFilter'
import {Page} from 'components/partials/Page'

export default function DnsRecordsLogs() {
  const dispatch = useDispatch()
  const dnsRecordsLogsState = useSelector(state => state.dnsRecordsLogs)
  const params = useParams()

  const [firstPageLoad, setFirstPageLoad] = useState(true)
  const [recordsLogs, setRecordsLogs] = useState({})

  const domainId = params.id
  const pageSize = 20
  const pageIndex = 0

  const columns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.name'),
            accessor: 'name',
            width: 80,
            disableFilters: true,
          },
          {
            Header: i18next.t('tables.type'),
            accessor: 'type',
            Filter: TypeFilter,
            filter: matchesMultiSelect,
            width: 50,
          },
          {
            Header: i18next.t('tables.value'),
            accessor: 'data',
            disableFilters: true,
          },
          {
            Header: i18next.t('tables.firstSeen'),
            accessor: 'createdAt',
            width: 90,
            disableFilters: true,
          },
          {
            Header: i18next.t('tables.lastSeen'),
            accessor: 'lastSeen',
            width: 90,
            disableFilters: true,
          },
        ],
      },
    ],
    []
  )

  useEffect(() => {
    dispatch(fetchDnsRecordsLogs({domainId, pagination: {limit: pageSize}}))
    setFirstPageLoad(false)
  }, [])

  useEffect(() => {
    if (dnsRecordsLogsState && dnsRecordsLogsState.data && !firstPageLoad) {
      const dnsRecordsLogs = dnsRecordsLogsState

      setRecordsLogs(dnsRecordsLogs)
    }
  }, [dnsRecordsLogsState])

  if (!dnsRecordsLogsState) return <Spinner />

  return (
    <Page pageTitle={i18next.t('pageTitles.logs')}>
      <BackButton fallbackLink="/dns" />

      <TableWrapper
        title={
          dnsRecordsLogsState &&
          dnsRecordsLogsState.data &&
          dnsRecordsLogsState.data.domain &&
          dnsRecordsLogsState.data.domain.hostname
            ? `DNS records logs for ${dnsRecordsLogsState.data.domain.hostname}`
            : 'DNS records logs'
        }
        loaded={recordsLogs.data}
        margin="mt-4"
        table={
          <GenericLogsTable
            columns={columns}
            data={(recordsLogs.data && recordsLogs.data.logs) || []}
            filterPlaceholder={i18next.t('tables.filterStartingWith')}
            pageCount={recordsLogs.pages}
            pageSize={pageSize}
            pageIndex={pageIndex}
            total={recordsLogs.total}
            fetchData={props => {
              dispatch(
                fetchDnsRecordsLogs({
                  domainId,
                  pagination: props,
                })
              )
            }}
            emptyStateWithFilter={
              <GenericEmptyState
                icon={<images.DnsIcon width="55" color={sideral300} />}
                title={i18next.t('tables.nothingFoundWithSearch')}
                body={i18next.t('tables.searchSomethingElse')}
                margin="m-2 mt-4"
              />
            }
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<images.DnsIcon width="55" color={sideral300} />}
                title={i18next.t('tables.nothingFound')}
                body={i18next.t('tables.waitAFewMinutes')}
                margin="m-2 mt-4"
              />
            }
          />
        }
      />
    </Page>
  )
}
