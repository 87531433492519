import React from 'react'
import PropTypes from 'prop-types'
import {i18next} from 'translate/i18n'
import SecondaryButton from './buttons/SecondaryButton'
import {Link} from 'react-router-dom'

export default function GenericEmptyState({
  icon,
  title,
  body,
  buttonText,
  linkPath,
  bgColor,
  margin,
}) {
  return (
    <div
      className={`flex items-center ${
        !bgColor ? 'bg-white' : bgColor
      } ${margin}`}>
      {icon}
      <div className="flex flex-wrap pl-4">
        <p className="w-full leading-none text-lg text-sky-900">
          {title || i18next.t('titles.goodNews')}
        </p>
        <div className="w-full text-sm font-light mt-2 mb-1">{body}</div>
        {buttonText && (
          <Link to={linkPath}>
            <SecondaryButton text={buttonText} direction="right" size="sm" />
          </Link>
        )}
      </div>
    </div>
  )
}

GenericEmptyState.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  body: PropTypes.string,
  buttonText: PropTypes.string,
  linkPath: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  margin: PropTypes.string,
  bgColor: PropTypes.string,
}
