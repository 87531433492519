import i18next from 'i18next'
import React from 'react'

import {getLetter} from 'utils/score'

import Score from '../partials/ScoreBadge'
import Footer from './Footer'

export default function ScoreInfo() {
  return (
    <>
      <div className="text-sm bg-white py-4 px-8">
        <div className="w-full">
          <h1 className="text-lg font-medium my-2">
            {i18next.t('help.unxposeScoring.unxposeScoring.title')}
          </h1>
          <p className="my-4">
            {i18next.t('help.unxposeScoring.unxposeScoring.definition')}
          </p>

          <div>
            <h1 className="font-medium my-1">
              {' '}
              {i18next.t(
                'help.unxposeScoring.unxposeScoring.securityScoreGrades'
              )}
            </h1>
            <div className="py-2 text-sm">
              <div className="my-2">
                <Score letter={getLetter(1)} />
                {i18next.t('help.unxposeScoring.unxposeScoring.grades.A')}
              </div>
              <div className="my-2">
                <Score letter={getLetter(2000)} />
                {i18next.t('help.unxposeScoring.unxposeScoring.grades.B')}
              </div>
              <div className="my-2">
                <Score letter={getLetter(12000)} />
                {i18next.t('help.unxposeScoring.unxposeScoring.grades.C')}
              </div>
              <div className="my-2">
                <Score letter={getLetter(22000)} />
                {i18next.t('help.unxposeScoring.unxposeScoring.grades.D')}
              </div>
              <div className="my-2">
                <Score letter={getLetter(32000)} />
                {i18next.t('help.unxposeScoring.unxposeScoring.grades.E')}
              </div>
              <div className="my-2">
                <Score letter={getLetter(52000)} />
                {i18next.t('help.unxposeScoring.unxposeScoring.grades.F')}
              </div>
            </div>
          </div>

          <h1 className="text-lg font-medium my-2">
            {i18next.t('help.unxposeScoring.progressBar.title')}
          </h1>
          <p className="mb-4">
            {i18next.t('help.unxposeScoring.progressBar.definition')}
          </p>
          <h1 className="text-lg font-medium my-2">
            {i18next.t('help.unxposeScoring.companysExposureScore.title')}
          </h1>
          <p className="mb-4">
            {i18next.t('help.unxposeScoring.companysExposureScore.definition')}
          </p>

          <h1 className="text-lg font-medium my-2">
            {i18next.t('help.unxposeScoring.categoryScore.title')}
          </h1>
          <div className="mb-4">
            {i18next.t('help.unxposeScoring.categoryScore.definition')}
          </div>

          <h1 className="text-lg font-medium my-2">
            {i18next.t('help.unxposeScoring.assetScore.title')}
          </h1>
          <p className="mb-4">
            {i18next.t('help.unxposeScoring.assetScore.definition')}
          </p>

          <h1 className="text-lg font-medium my-2">
            {i18next.t('help.unxposeScoring.findingScore.title')}
          </h1>
          <p className="mb-4">
            {i18next.t('help.unxposeScoring.findingScore.definition')}
          </p>

          <div className="mb-4">
            <ul className="list-disc ml-10 mt-2">
              <li>
                <strong>
                  {i18next.t('help.unxposeScoring.findingScore.cvss.title')}{' '}
                </strong>
                {i18next.t('help.unxposeScoring.findingScore.cvss.definition')}
              </li>
              <li>
                <strong>
                  {i18next.t(
                    'help.unxposeScoring.findingScore.exploitationLikelihood.title'
                  )}{' '}
                </strong>
                {i18next.t(
                  'help.unxposeScoring.findingScore.exploitationLikelihood.definition'
                )}
              </li>
            </ul>
          </div>

          <div className="mb-4">
            {i18next.t('help.unxposeScoring.peopleScore.intro')}
            <ul className="list-disc ml-10 mt-2">
              <li>
                <strong>
                  {i18next.t(
                    'help.unxposeScoring.peopleScore.breachRelevance.title'
                  )}{' '}
                </strong>
                {i18next.t(
                  'help.unxposeScoring.peopleScore.breachRelevance.definition'
                )}
              </li>
              <li>
                <strong>
                  {i18next.t(
                    'help.unxposeScoring.peopleScore.employeesAccess.title'
                  )}{' '}
                </strong>
                {i18next.t(
                  'help.unxposeScoring.peopleScore.employeesAccess.definition'
                )}
              </li>
            </ul>
          </div>

          <h2 className="text-lg font-medium my-2">
            {i18next.t('help.unxposeScoring.informationScore.title')}
          </h2>
          <p className="mb-4">
            {i18next.t('help.unxposeScoring.informationScore.definition')}
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}
