import {i18next} from 'translate/i18n'

export function getGrade(score) {
  if (typeof score === 'undefined') return '-'
  return getLetter(score)
}

export function getLetter(score) {
  if (score === null) {
    return '-'
  }
  if (score <= 1000) {
    // outstanding
    return 'A'
  }
  if (score > 1000 && score <= 11000) {
    // excellent
    return 'B'
  }
  if (score > 11000 && score <= 21000) {
    // good
    return 'C'
  }
  if (score > 21000 && score <= 31000) {
    // regular
    return 'D'
  }
  if (score > 31000 && score <= 51000) {
    // bad
    return 'E'
  }
  if (score > 51000) {
    // critical
    return 'F'
  }

  return 'A'
}

export function getSeverityString(severity) {
  if (severity < 2) return 'info'
  else if (severity >= 2 && severity < 4) return 'low'
  else if (severity >= 4 && severity < 7) return 'medium'
  else if (severity >= 7 && severity < 8) return 'high'
  else if (severity >= 8) return 'critical'

  return '-'
}

export function getSeverityLabel(severity) {
  if (severity === 'info') return i18next.t('severity.info')
  else if (severity === 'low') return i18next.t('severity.low')
  else if (severity === 'medium') return i18next.t('severity.medium')
  else if (severity === 'high') return i18next.t('severity.high')
  else if (severity === 'critical') return i18next.t('severity.critical')

  return '-'
}

export function getSeverityColor(severity) {
  if (typeof severity !== 'number' && !severity) return ''

  if (typeof severity === 'number') severity = getSeverityString(severity)

  switch (severity.toLowerCase()) {
    case 'info':
      return 'bg-sky-300'
    case 'low':
      return 'bg-summer-300'
    case 'medium':
      return 'bg-summer-700'
    case 'high':
      return 'bg-mars-300'
    case 'critical':
      return 'bg-coral-400'
    default:
      return 'bg-astral-200'
  }
}

export function getGradeVariation(arr) {
  const variationArr = []

  arr &&
    arr.forEach(item => {
      if (item <= 1000) {
        variationArr.push(5)
      }
      if (item > 1000 && item <= 11000) {
        variationArr.push(4)
      }
      if (item > 11000 && item <= 21000) {
        variationArr.push(3)
      }
      if (item > 21000 && item <= 31000) {
        variationArr.push(2)
      }
      if (item > 31000 && item <= 51000) {
        variationArr.push(1)
      }
      if (item > 51000) {
        variationArr.push(0)
      }
    })
  return variationArr
}

export function getScoreColor(letter) {
  switch (letter) {
    case 'A':
      return 'bg-teal-500 hover:bg-teal-600'
    case 'B':
      return 'bg-green-500 hover:bg-green-600'
    case 'C':
      return 'bg-yellow-500 hover:bg-yellow-600'
    case 'D':
      return 'bg-orange-500 hover:bg-orange-600'
    case 'E':
      return 'bg-red-500 hover:bg-red-600'
    case 'F':
      return 'bg-red-700 hover:bg-red-800'
    default:
      return 'bg-teal-500 hover:bg-teal-600'
  }
}
