import i18next from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'

import ProgressBar from 'components/overview/ProgressBar'

const DashboardScore = ({overallScore, percentage}) => {
  let color

  switch (overallScore) {
    case 'A':
      color = 'bg-teal-500'
      break
    case 'B':
      color = 'bg-green-500'
      break
    case 'C':
      color = 'bg-yellow-500'
      break
    case 'D':
      color = 'bg-orange-500'
      break
    case 'E':
      color = 'bg-red-500'
      break
    case 'F':
      color = 'bg-red-700'
      break
    default:
      color = 'bg-gray-400 text-gray-600'
      break
  }
  return (
    <div className="h-full">
      <div
        id="security-score"
        className={`relative flex items-center justify-between h-full lg:w-8/12 min-w-13 rounded-lg font-normal text-white mx-auto p-4 ${color}`}>
        <p className="text-lg mb-6">
          /{i18next.t('titles.securityScore').toUpperCase()}
        </p>
        <span className="text-5xl mb-6">{overallScore}</span>
        <ProgressBar
          completed={percentage ? percentage : 0}
          overallScore={overallScore}
          padding="px-2"
          margin="mb-4"
        />
      </div>
    </div>
  )
}

DashboardScore.propTypes = {
  overallScore: PropTypes.string,
  percentage: PropTypes.number,
}

export default DashboardScore
