import config from '../config'
import {handler} from './handler'

import {
  fetchInfoExposureFinding,
  fetchInfoExposureFindingActivity,
  fetchInfoExposureFindingActivityDone,
  fetchInfoExposureFindingDone,
  fetchInfoExposureFindingsDone,
  genericError,
  success,
  updateInfoExposureFindingDone,
} from 'actions'

const _fetchInfoExposureFindings = function () {
  return fetch(config.SERVER_URL + '/info', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchInfoExposureFindings(action) {
  yield handler({
    handler: _fetchInfoExposureFindings,
    success: fetchInfoExposureFindingsDone,
    action,
  })
}

const _fetchInfoExposureFinding = function (findingId) {
  return fetch(config.SERVER_URL + '/info/' + findingId, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchInfoExposureFinding(action) {
  yield handler({
    handler: _fetchInfoExposureFinding,
    success: fetchInfoExposureFindingDone,
    error: genericError,
    action,
  })
}

const _updateInfoExposureFinding = function (body) {
  return fetch(config.SERVER_URL + `/info/${body.findingId}/${body.status}`, {
    credentials: 'include',
    method: 'POST',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateInfoExposureFinding(action) {
  const {updateStatusMessage, findingId} = action.payload

  yield handler({
    handler: _updateInfoExposureFinding,
    success: [
      () => updateInfoExposureFindingDone(action.payload.status),
      () =>
        success({
          message: updateStatusMessage,
        }),
      () => fetchInfoExposureFinding(findingId),
      () => fetchInfoExposureFindingActivity(findingId),
    ],
    action,
  })
}

const _fetchInfoExposureFindingActivity = function (findingId) {
  return fetch(config.SERVER_URL + `/info/${findingId}/activity`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchInfoExposureFindingActivity(action) {
  yield handler({
    handler: _fetchInfoExposureFindingActivity,
    success: fetchInfoExposureFindingActivityDone,
    action,
  })
}
