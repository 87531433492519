import i18next from 'i18next'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {
  fetchSupplierInvites,
  fetchQuestionnaires,
  loading,
  fetchQuestionnaireInvites,
} from 'actions'

import Subsection from 'components/partials/headers/Subsection'
import {Page} from 'components/partials/Page'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import {QuestionnaireBase} from './QuestionnaireBase'
import QuestionnairesList from './QuestionnairesList'
import SupplierInvites from '../suppliers/SupplierInvites'
import QuestionnaireInvites from './QuestionnaireInvites'
import {isLoading} from 'utils'

export default function QuestionnaireOverview() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loadingState = useSelector(state => state.loading)
  const supplierInvitesState = useSelector(state => state.supplierInvites)
  const questionnaireInvitesState = useSelector(
    state => state.questionnaireInvites
  )

  const [displaySupplierInvites, setDisplaySupplierInvites] = useState(false)
  const [displayQuestionnaireInvites, setDisplayQuestionnaireInvites] =
    useState(false)

  useEffect(() => {
    dispatch(fetchQuestionnaires())

    dispatch(loading({questionnaireInvites: true}))
    dispatch(fetchSupplierInvites())
    dispatch(fetchQuestionnaireInvites())
  }, [])

  useEffect(() => {
    setDisplaySupplierInvites(supplierInvitesState?.length > 0)
    setDisplayQuestionnaireInvites(questionnaireInvitesState?.length > 0)
  }, [supplierInvitesState, questionnaireInvitesState])

  return (
    <Page
      pageTitle={i18next.t('pageTitles.questionnaires')}
      helpPath="questionnaire"
      button={
        <SecondaryButton
          text={i18next.t('buttons.addQuestionnaire')}
          onClick={() => navigate('/questionnaires/new')}
          plusIcon
        />
      }>
      <QuestionnaireBase />

      {(displaySupplierInvites || displayQuestionnaireInvites) && (
        <Subsection
          title={i18next.t('suppliers.invites.title')}
          content={
            <>
              {displaySupplierInvites && (
                <SupplierInvites invites={supplierInvitesState} />
              )}

              {displayQuestionnaireInvites && (
                <div className={displaySupplierInvites ? 'border-t' : ''}>
                  <QuestionnaireInvites invites={questionnaireInvitesState} />
                </div>
              )}
            </>
          }
          margin="mb-10"
          loading={isLoading(loadingState.supplierInvites)}
        />
      )}

      <Subsection
        title={i18next.t('questionnaires.tables.questionnairesAvailable')}
        content={<QuestionnairesList />}
      />
    </Page>
  )
}
