import React from 'react'
import PropTypes from 'prop-types'

export default function FireIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-1 -2 17 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.48 11.3501C13.91 7.27014 8.32 7.05014 9.67 1.12014C9.77 0.68014 9.3 0.34014 8.92 0.57014C5.29 2.71014 2.68 7.00014 4.87 12.6201C5.05 13.0801 4.51 13.5101 4.12 13.2101C2.31 11.8401 2.12 9.87014 2.28 8.46014C2.34 7.94014 1.66 7.69014 1.37 8.12014C0.69 9.16014 0 10.8401 0 13.3701C0.38 18.9701 5.11 20.6901 6.81 20.9101C9.24 21.2201 11.87 20.7701 13.76 19.0401C15.84 17.1101 16.6 14.0301 15.48 11.3501ZM6.2 16.3801C7.64 16.0301 8.38 14.9901 8.58 14.0701C8.91 12.6401 7.62 11.2401 8.49 8.98014C8.82 10.8501 11.76 12.0201 11.76 14.0601C11.84 16.5901 9.1 18.7601 6.2 16.3801Z"
        fill={color || '#0A50A0'}
      />
    </svg>
  )
}

FireIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
