import i18next from 'i18next'
import React from 'react'
import Pig from 'res/illustrations/pig-404.svg'
import SecondaryButton from './partials/buttons/SecondaryButton'
import {getLocationToRedirect} from 'utils'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

const PageNotFound = () => {
  const navigate = useNavigate()

  const userState = useSelector(state => state.user)

  return (
    <div className="w-full mx-auto mt-40 text-center text-gray-800">
      <p className="relative z-10 text-7xl font-bold medium:font-extrabold leading-none">
        404
      </p>
      <p className="z-10 text-xl font-bold relative">
        {i18next.t('notFound.title')}
      </p>
      <div className="flex items-center justify-center pb-16">
        <img
          src={Pig}
          alt="Pig"
          className="absolute opacity-25 -mt-10"
          width="500px"
        />
      </div>
      <div className="text-center font-medium text-lg mt-24">
        <p>{i18next.t('notFound.reaction')}</p>
      </div>
      <div className="text-md my-4">
        <p>{i18next.t('notFound.body')}</p>
      </div>
      <div className="flex justify-center">
        <SecondaryButton
          text={i18next.t('notFound.goToHomeBtn')}
          onClick={() => navigate(getLocationToRedirect(userState.role))}
          size="sm"
        />
      </div>
    </div>
  )
}

export default PageNotFound
