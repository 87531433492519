import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import hljs from 'highlight.js'
import {useSelector, useDispatch} from 'react-redux'
import {v4 as uuidv4} from 'uuid'

import {integrateAws, loading} from 'actions'
import images from 'res'
import config from 'config'
import {
  awsUnxposePolicy,
  awsAccountId,
  borderStyle,
  integrationStyles,
} from 'constant'

import i18next from 'i18next'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import {Page} from 'components/partials/Page'
import BackButton from 'components/partials/buttons/BackButton'
import CopyToClipboard from 'components/partials/CopyToClipboard'
import {isLoading} from 'utils'

const AwsManualIntegration = () => {
  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)

  const dispatch = useDispatch()

  const [form, setForm] = useState({accountId: '', externalId: uuidv4()})

  const handleInputChange = event => {
    setForm({...form, accountId: event.target.value})
  }

  const handleSubmit = event => {
    event.preventDefault()

    dispatch(loading({awsIntegration: true}, {_csrf: csrfState}))
    dispatch(
      integrateAws({
        ...form,
        method: 'manual',
        version: config.awsIntegrationVersion,
        _csrf: csrfState,
      })
    )
  }

  const copyToClipboard = arg => {
    switch (arg) {
      case 'unxpose-policy-button': {
        return navigator.clipboard.writeText(
          JSON.stringify(awsUnxposePolicy, null, 2)
        )
      }
      case 'external-id-button': {
        return navigator.clipboard.writeText(form.externalId)
      }
      case 'unxpose-account-id-button': {
        return navigator.clipboard.writeText(awsAccountId)
      }
    }
  }

  useEffect(() => {
    hljs.highlightAll()
  }, [])

  return (
    <Page pageTitle={i18next.t('pageTitles.cloud')}>
      <BackButton fallbackLink={'/cloud'} />

      <div className={borderStyle + ' bg-white p-6 mt-4'}>
        <div className="lg:w-8/12">
          <div className={integrationStyles.mainTitle}>
            {i18next.t('cloud.aws.integration.title')}
          </div>

          <div className="mb-4 text-sm">
            <div className={integrationStyles.secondaryTitle}>
              {i18next.t('cloud.aws.integration.stepOne.title')}
            </div>
            <div>
              {i18next.t('cloud.aws.integration.stepOne.inOrderToMonitor')}{' '}
              <b>
                {i18next.t(
                  'cloud.aws.integration.stepOne.highlights.mantainingTheContent'
                )}
              </b>
            </div>
            <div className="ordered-list ml-4 mt-4">
              <ol>
                <li className="py-2">
                  {i18next.t(
                    'cloud.aws.integration.stepOne.toCreateThisPolicy'
                  )}{' '}
                  <b>
                    {i18next.t(
                      'cloud.aws.integration.stepOne.highlights.policies'
                    )}
                  </b>
                </li>
                <figure>
                  <img
                    src={images.policiesAWS}
                    alt="Select Policies"
                    className={integrationStyles.image}
                  />
                </figure>
                <li className="py-2">
                  {i18next.t('cloud.aws.integration.stepOne.nowClickOn')}{' '}
                  <b>
                    {' '}
                    {i18next.t(
                      'cloud.aws.integration.stepOne.highlights.createPolicy'
                    )}{' '}
                  </b>
                  {i18next.t('cloud.aws.integration.stepOne.andOpenThe')}
                  <b>
                    {' '}
                    {i18next.t('cloud.aws.integration.stepOne.highlights.json')}
                  </b>
                  {i18next.t('cloud.aws.integration.stepOne.tab')}.
                </li>
                <li className="py-2">
                  <div className="tooltip inline cursor-pointer text-sky-500 hover:text-sky-600"></div>{' '}
                  {i18next.t(
                    'cloud.aws.integration.stepOne.theFollowingConfiguration'
                  )}
                </li>
                <CopyToClipboard
                  content={
                    <pre>
                      <code>{JSON.stringify(awsUnxposePolicy, null, 2)}</code>
                    </pre>
                  }
                  onClick={() => copyToClipboard('unxpose-policy-button')}
                />

                <li className="py-2">
                  {i18next.t('cloud.aws.integration.stepOne.select')}{' '}
                  <b>
                    {i18next.t(
                      'cloud.aws.integration.stepOne.highlights.createPolicy'
                    )}
                  </b>
                  . {i18next.t('cloud.aws.integration.stepOne.addSomeTags')}{' '}
                  <b>
                    {' '}
                    {i18next.t(
                      'cloud.aws.integration.stepOne.highlights.nextReview'
                    )}
                  </b>
                  .
                </li>
                <li className="py-2">
                  {i18next.t('cloud.aws.integration.stepOne.finallyFill')}
                  <b>
                    {' '}
                    {i18next.t('cloud.aws.integration.stepOne.highlights.name')}
                  </b>{' '}
                  {i18next.t('cloud.aws.integration.stepOne.fieldWith')}
                  <b>
                    {' '}
                    {i18next.t(
                      'cloud.aws.integration.stepOne.highlights.unxposePolicy'
                    )}
                  </b>{' '}
                  {i18next.t('cloud.aws.integration.stepOne.andClickOn')}{' '}
                  <b>
                    {i18next.t(
                      'cloud.aws.integration.stepOne.highlights.createPolicy'
                    )}
                  </b>
                  . {i18next.t('cloud.aws.integration.stepOne.nowLetsMove')}
                </li>
                <li className="py-2">
                  <b>
                    {' '}
                    {i18next.t(
                      'cloud.aws.integration.stepOne.highlights.attention'
                    )}
                  </b>{' '}
                  {i18next.t('cloud.aws.integration.stepOne.thePolicyNameMust')}
                  <b>
                    {' '}
                    {i18next.t(
                      'cloud.aws.integration.stepOne.highlights.unxposePolicy'
                    )}
                  </b>
                  .
                </li>
                <figure>
                  <img
                    src={images.policyNameAWS}
                    alt="Select the policy name"
                    className={integrationStyles.image}
                  />
                </figure>
              </ol>
            </div>
          </div>
          <div className="mb-4"></div>
          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.aws.integration.stepTwo.title')}
          </div>
          <div className="text-sm"></div>
          <div className="ordered-list ml-4 text-sm">
            <ol>
              <li className="py-2">
                {i18next.t('cloud.aws.integration.stepTwo.goToTheIam')}{' '}
                <b>
                  {i18next.t('cloud.aws.integration.stepTwo.highlights.roles')}
                </b>
                .
              </li>
              <figure>
                <img
                  src={images.rolesAWS}
                  alt="Select Roles"
                  className={integrationStyles.image}
                />
              </figure>
              <li className="py-2">
                {i18next.t('cloud.aws.integration.stepTwo.clickOnThe')}{' '}
                <b>
                  {i18next.t(
                    'cloud.aws.integration.stepTwo.highlights.createRole'
                  )}
                </b>
                {i18next.t('cloud.aws.integration.stepTwo.button')}.
              </li>
              <figure>
                <img
                  src={images.createRoleAWS}
                  alt="Click on Create Role"
                  className={integrationStyles.image}
                />
              </figure>
              <li className="py-2">
                {i18next.t('cloud.aws.integration.stepTwo.select')}{' '}
                <b>
                  {i18next.t(
                    'cloud.aws.integration.stepTwo.highlights.awsAccount'
                  )}{' '}
                </b>
                {i18next.t('cloud.aws.integration.stepTwo.andThen')}{' '}
                <b>
                  {i18next.t(
                    'cloud.aws.integration.stepTwo.highlights.anotherAwsAccount'
                  )}
                </b>
                .
              </li>
              <figure>
                <img
                  src={images.anotherAccountAWS}
                  alt="Select Another AWS account"
                  className={integrationStyles.image}
                />
              </figure>
              <li className="py-2">
                {i18next.t('cloud.aws.integration.stepTwo.fill')}{' '}
                <b>
                  {i18next.t(
                    'cloud.aws.integration.stepTwo.highlights.accountId'
                  )}
                </b>{' '}
                {i18next.t('cloud.aws.integration.stepTwo.fieldWithOur')}.
                <CopyToClipboard
                  content={<pre>{awsAccountId}</pre>}
                  onClick={() => copyToClipboard('unxpose-account-id-button')}
                  classes="mt-3"
                />
              </li>
              <li className="py-2">
                {i18next.t('cloud.aws.integration.stepTwo.selectThe')}{' '}
                <b>
                  {i18next.t(
                    'cloud.aws.integration.stepTwo.highlights.requireExternalId'
                  )}
                </b>
                {i18next.t('cloud.aws.integration.stepTwo.checkbox')}.
              </li>
              <li className="py-2">
                {i18next.t('cloud.aws.integration.stepTwo.fillThe')}{' '}
                <b>
                  {i18next.t(
                    'cloud.aws.integration.stepTwo.highlights.externalId'
                  )}
                </b>{' '}
                {i18next.t(
                  'cloud.aws.integration.stepTwo.fieldWithTheFollowing'
                )}
                <b>
                  {i18next.t(
                    'cloud.aws.integration.stepTwo.highlights.important'
                  )}{' '}
                </b>
                {i18next.t('cloud.aws.integration.stepTwo.theValueFor')}{' '}
                <b>
                  {i18next.t(
                    'cloud.aws.integration.stepTwo.highlights.externalId'
                  )}{' '}
                </b>
                {i18next.t('cloud.aws.integration.stepTwo.updatedTo')}
                <CopyToClipboard
                  content={<pre>{form.externalId}</pre>}
                  onClick={() => copyToClipboard('external-id-button')}
                  classes="mt-3"
                />
                <figure>
                  <img
                    src={images.externalIdAWS}
                    alt="Fill the External ID field"
                    className={integrationStyles.image}
                  />
                </figure>
                {i18next.t('cloud.aws.integration.stepTwo.click')}{' '}
                <b>
                  {i18next.t('cloud.aws.integration.stepTwo.highlights.next')}
                </b>
                .
              </li>
              <li className="py-2">
                {i18next.t('cloud.aws.integration.stepTwo.selectTheFollowing')}
                <ul className="list-disc ml-10 my-2">
                  <li>
                    {i18next.t(
                      'cloud.aws.integration.stepTwo.highlights.unxposePolicy'
                    )}
                  </li>
                </ul>
              </li>
              <li className="py-2">
                {i18next.t('cloud.aws.integration.stepTwo.optionalAddTags')}{' '}
                <b>
                  {i18next.t('cloud.aws.integration.stepTwo.highlights.next')}
                </b>
                .
              </li>
              <li className="py-2">
                {i18next.t('cloud.aws.integration.stepTwo.fillThe')}{' '}
                <b>
                  {i18next.t(
                    'cloud.aws.integration.stepTwo.highlights.roleName'
                  )}
                </b>{' '}
                {i18next.t('cloud.aws.integration.stepTwo.fieldWith')}{' '}
                <b>
                  {i18next.t(
                    'cloud.aws.integration.stepTwo.highlights.unxposeRole'
                  )}
                </b>{' '}
                ({i18next.t('cloud.aws.integration.stepTwo.theRoleNameMust')}{' '}
                <b>
                  {i18next.t(
                    'cloud.aws.integration.stepTwo.highlights.unxposeRole'
                  )}
                </b>
                ). {i18next.t('cloud.aws.integration.stepTwo.youCanAlsoAdd')}
              </li>
              <li className="py-2">
                {i18next.t(
                  'cloud.aws.integration.stepTwo.reviewTheInformation'
                )}{' '}
                <b>
                  {i18next.t(
                    'cloud.aws.integration.stepTwo.highlights.createRole'
                  )}
                </b>
                .
              </li>
            </ol>
          </div>
          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.aws.integration.stepThree.title')}
          </div>
          <div className="text-sm">
            {i18next.t('cloud.aws.integration.stepThree.fillTheField')}
          </div>
          <div className="text-xs mb-2">
            <a
              className="text-sky-500"
              href="https://docs.aws.amazon.com/general/latest/gr/acct-identifiers.html"
              rel="noopener noreferrer"
              target="_blank">
              {i18next.t('cloud.aws.integration.stepThree.clickHere')}
            </a>
            {i18next.t('cloud.aws.integration.stepThree.ifYouNeedHelp')}
          </div>
          <div className="my-1">
            <form onSubmit={handleSubmit}>
              <PrimaryInput
                placeholder={i18next.t(
                  'cloud.aws.integration.stepThree.amazonAccountId'
                )}
                type="text"
                name="accountId"
                value={form.accountId}
                onChange={handleInputChange}
                required={true}
                margin="mb-10"
              />
              <div className="flex justify-end">
                <PrimaryButton
                  text={i18next.t(
                    'cloud.aws.integration.stepThree.monitorMyAccount'
                  )}
                  type="submit"
                  theme="blue"
                  loading={isLoading(loadingState.awsIntegration, false)}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Page>
  )
}

AwsManualIntegration.propTypes = {
  history: PropTypes.object,
}

export default AwsManualIntegration
