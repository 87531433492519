function filterRowColumns(row, indexes) {
  const values = []
  indexes.forEach(index => {
    values.push(row[Object.keys(row)[index]])
  })

  return values.join(',')
}

function getColumnIndexes({columns, data}) {
  const indexes = []
  columns.forEach(columnName => {
    indexes.push(Object.keys(data[0]).indexOf(columnName))
  })

  return indexes
}

function download(csvContent, filename) {
  const link = document.createElement('a')

  const universalBOM = '\uFEFF'

  link.setAttribute(
    'href',
    'data:text/csv; charset=utf-8,' +
      encodeURIComponent(universalBOM + csvContent)
  )

  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}

export function generateCSV({data, filename, columns, columnNames}) {
  if (!data || !data.length || !filename)
    return console.log('Missing parameters')

  const indexes = getColumnIndexes({columns, columnNames, data})

  let csvContent = ''

  csvContent += columnNames + '\r\n'
  data.forEach(row => {
    row = filterRowColumns(row, indexes)
    csvContent += row + '\r\n'
  })

  download(csvContent, filename)
}

export function truncate(string, size) {
  size = size || 80

  if (string.length < size) return string

  return string.slice(0, size) + '...'
}
