import React from 'react'

const Terms = () => {
  return (
    <div className="">
      <div className="flex">
        <div className="w-full px-4">
          <iframe
            src="https://www.unxpose.com/terms"
            className="w-full" height="550px"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Terms
