import React, {useEffect, useState} from 'react'
import {fetchAuditLogs, toggleRestrictionModal} from 'actions'
import {useSelector, useDispatch} from 'react-redux'
import {sky500} from 'constant'
import images from 'res'
import TableWrapper from 'components/partials/tables/TableWrapper'
import AuditLogsTable from './AuditLogsTable'
import i18next from 'i18next'
import config from 'config'
import {getRequestParams} from 'utils/pagination'
import PlanUtils from 'utils/plan'
import AccessibilityAlert from 'components/partials/AccessibilityAlert'

const PAGE_SIZE = 10

const AuditLogs = () => {
  const auditLogsState = useSelector(state => state.auditLogs)

  const [filterValue, setFilterValue] = useState('')
  const [sortOptions, setSortOptions] = useState([])

  const planUtils = PlanUtils()

  const dispatch = useDispatch()

  const handleGenerateCSV = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )

    const url = new URL(`${config.SERVER_URL}/companies/logs`)

    url.search = new URLSearchParams(
      getRequestParams({
        format: 'csv',
        filterValue,
        pageIndex: 0,
        sortBy: sortOptions,
      })
    ).toString()

    window.location.href = url
  }

  useEffect(() => {
    !planUtils.hasPlanRestriction() &&
      dispatch(
        fetchAuditLogs({
          limit: PAGE_SIZE,
        })
      )
  }, [])

  return (
    <>
      <TableWrapper
        id="auditLogs"
        title={i18next.t('settings.auditLogs.title')}
        loaded={auditLogsState}
        button={
          <button
            title={i18next.t('buttons.downloadCSV')}
            className="ml-auto"
            onClick={handleGenerateCSV}>
            <images.DownloadIcon height="24" width="24" color={sky500} />
          </button>
        }
        table={
          <AuditLogsTable
            fetchData={props => {
              dispatch(
                fetchAuditLogs({
                  ...props,
                })
              )
            }}
            data={auditLogsState?.data}
            pages={auditLogsState?.pages}
            total={auditLogsState?.total}
            pageSize={PAGE_SIZE}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            setSortOptions={setSortOptions}
          />
        }
        isPremiumFeature
      />

      <AccessibilityAlert />
    </>
  )
}

export default AuditLogs
