import images from 'res'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'

import Logo from 'res/brand/logo.svg'
import config from 'config'
import {
  authenticate,
  clearError,
  fetchUser,
  genericError,
  loading,
} from 'actions'

import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import {i18next} from 'translate/i18n'
import {isLoading} from 'utils'

const Login = () => {
  const initialFormState = {
    email: '',
    password: '',
  }

  const location = useLocation()
  const navigate = useNavigate()

  const ReCaptchaRef = React.createRef()
  const dispatch = useDispatch()

  const userState = useSelector(state => state.user)
  const errorState = useSelector(state => state.error)
  const loadingState = useSelector(state => state.loading)

  const [form, setForm] = useState(initialFormState)

  const handleInputChange = event => {
    const {name, value} = event.target
    setForm({...form, [name]: value})
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (!form.email || !form.password) return

    dispatch(loading({login: true}))

    if (process.env.REACT_APP_ENV === 'development') {
      dispatch(authenticate(form))
      return
    }

    ReCaptchaRef.current.execute()
    setForm({...form})
  }

  const handleCaptchaChange = token => {
    setForm({...form, captcha: token})
  }

  const handleGoogle = e => {
    e.preventDefault()
    window.location.href = `${config.SERVER_URL}/auth/google`
  }

  const handleMicrosoft = e => {
    e.preventDefault()
    window.location.href = `${config.SERVER_URL}/auth/microsoft`
  }

  useEffect(() => {
    dispatch(fetchUser())
  }, [])

  useEffect(() => {
    if (userState && Object.keys(userState).length) navigate('/')
  }, [userState])

  useEffect(() => {
    if (form.captcha) {
      dispatch(authenticate(form))

      // clear captcha after submit to avoid resend
      setForm({...form, captcha: null})
    }
  }, [dispatch, form.captcha])

  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  useEffect(() => {
    if (errorState) ReCaptchaRef.current.reset()
  }, [errorState])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const messageParam = searchParams.get('message')

    let message = ''

    if (messageParam) {
      switch (messageParam) {
        case 'googleNotConnected':
          message = i18next.t('auth.errors.googleAccountNotConnected')
          break

        case 'microsoftNotConnected':
          message = i18next.t('auth.errors.microsoftAccountNotConnected')
          break

        case 'emailNotFound':
          message = i18next.t('auth.errors.emailNotFound')
          break

        case 'expiredSubscription':
          message = i18next.t('auth.errors.planExpired')
          break

        case 'companyExists':
          message = i18next.t('auth.errors.companyExists')
          break

        case 'notReady':
          message = i18next.t('auth.errors.notReady')
          break

        case 'invalidLink':
          message = i18next.t('auth.errors.invalidLink')
          break

        case 'authenticationRequired':
          message = i18next.t('auth.errors.authenticationRequired')
          break

        default:
          message = i18next.t('auth.errors.unexpectedError')
      }

      dispatch(
        genericError({
          message,
        })
      )

      const redirect = searchParams.get('redirect')

      if (redirect && /^\/[a-zA-Z-]{0,20}$/.test(redirect)) {
        localStorage.setItem('redirect', redirect)
      }

      // clear message from URL to avoid problems
      navigate({search: ''})
    }
  }, [dispatch, location.search])

  return (
    <div className="dm-mono">
      <div className="fixed right-0 flex flex-col justify-around items-center bg-white lg:max-w-xl w-full h-screen ml-auto z-50">
        <div className="w-full">
          <div className="font-light text-center uppercase text-4xl text-gray-600 mb-12">
            <img
              src={Logo}
              alt="Unxpose"
              className="mx-auto cursor-pointer w-56"
            />
          </div>
          <form
            onSubmit={handleSubmit}
            className="relative w-full text-sideral-700 px-6 md:px-24 pt-8">
            <div className="mb-6">
              <PrimaryInput
                testId="email-input"
                text={i18next.t('labels.email')}
                name="email"
                type="email"
                value={form.email}
                onChange={handleInputChange}
                required={true}
                placeholder={i18next.t('placeholders.email')}
              />
            </div>
            <div className="mb-6">
              <PrimaryInput
                testId="password-input"
                text={i18next.t('labels.password')}
                name="password"
                type="password"
                value={form.password}
                onChange={handleInputChange}
                required={true}
                placeholder={i18next.t('placeholders.password')}
              />
            </div>
            <div className="flex items-center mt-12">
              <SecondaryButton
                testId="forgot-password-btn"
                text={i18next.t('buttons.forgotPassword')}
                direction="right"
                size="sm"
                onClick={() => navigate('/forgot')}
              />

              <div className="ml-auto">
                <PrimaryButton
                  testId="login-btn"
                  text={i18next.t('buttons.login').toUpperCase()}
                  type="submit"
                  loading={isLoading(loadingState.login, false)}
                  size="md"
                />
              </div>
            </div>

            <button
              onClick={handleGoogle}
              className="flex items-center justify-center uppercase w-full rounded-sm text-sm text-sideral-900 border border-sideral-300 mt-12 py-2">
              <img src={images.googleIcon} alt="Google" className="w-5 mr-4" />
              {i18next.t('buttons.googleSignIn')}
            </button>

            <button
              onClick={handleMicrosoft}
              className="flex items-center justify-center uppercase w-full rounded-sm text-sm text-sideral-900 border border-sideral-300 mt-4 py-2">
              <img
                src={images.microsoftIcon}
                alt="Microsoft"
                className="w-5 mr-4"
              />
              {i18next.t('buttons.microsoftSignin')}
            </button>

            <div className="flex items-end justify-center mt-4">
              <div className="roboto mr-2 text-sm">
                {i18next.t('labels.dontHaveAnAccount')}
              </div>
              <SecondaryButton
                testId="create-account-btn"
                text={i18next.t('labels.createAccount')}
                size="sm"
                margin="0"
                direction="right"
                onClick={() => navigate('/signup')}
              />
            </div>

            <ReCAPTCHA
              ref={ReCaptchaRef}
              sitekey="6LeqSV8aAAAAAHHZEv5J9NqKNy_D_uNiwEJQjsLO"
              size="invisible"
              onChange={handleCaptchaChange}
            />
          </form>
        </div>
        <div></div>
      </div>
    </div>
  )
}

Login.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  search: PropTypes.string,
}

export default Login
