import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {DefaultModal} from 'components/partials/DefaultModal'
import {removeDomain} from 'actions'
import {Trans} from 'react-i18next'

export default function RemoveDomainModal({modal, setModal}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const successState = useSelector(state => state.success)

  const onCloseModal = () => {
    setModal({domainId: '', hostname: '', showModal: false})
  }

  const handleRemoveDomain = () => {
    dispatch(
      removeDomain({
        domainId: modal.domainId,
        hostname: modal.hostname,
        _csrf: csrfState,
      })
    )
  }

  useEffect(() => {
    if (successState) onCloseModal()
  }, [successState])

  return (
    <DefaultModal
      id="remove-domain-modal"
      title={i18next.t('dns.removeDomain')}
      body={
        <div className="text-sm text-gray-700 leading-6">
          <Trans
            i18nKey="warnings.domainRemoval"
            values={{
              hostname: modal.hostname,
            }}
          />
        </div>
      }
      primaryBtnText={i18next.t('buttons.remove')}
      primaryButtonTheme="red"
      onClickPrimaryBtn={handleRemoveDomain}
      secondayBtnText={i18next.t('buttons.cancel')}
      onClickSecondaryBtn={onCloseModal}
      onCloseModal={onCloseModal}
    />
  )
}

RemoveDomainModal.propTypes = {
  modal: PropTypes.object,
  setModal: PropTypes.func,
}
