import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import ScoreBadgeSquare from 'components/partials/ScoreBadgeSquare'

const StatsItem = ({assetsQty, findingsQty, score, styles}) => {
  return (
    <div className={styles.statsBoxesContainer}>
      <div className={styles.statsBox}>
        <span className="text-lg">{assetsQty || '--'}</span>
        <p className={styles.statsBoxText}>{i18next.t('misc.assets')}</p>
      </div>
      <div className={styles.statsBox}>
        <span className="text-lg">{findingsQty || '--'}</span>
        <p className={styles.statsBoxText}>{i18next.t('misc.findings')}</p>
      </div>
      <div>
        <ScoreBadgeSquare letter={score || '-'} />
      </div>
    </div>
  )
}

export default StatsItem

StatsItem.propTypes = {
  assetsQty: PropTypes.number,
  findingsQty: PropTypes.number,
  score: PropTypes.string,
  styles: PropTypes.object,
}
