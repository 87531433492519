import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useNavigate, useLocation} from 'react-router-dom'
import i18next from 'i18next'
import {clearAction} from 'actions'
import {useDispatch} from 'react-redux'
import {Page} from 'components/partials/Page'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import CheckIcon from 'res/icons/checkIcon'
import {gray300, sky900} from 'constant'

const BulkChange = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  if (!location.state) return navigate('/management')

  const [currentStep, setCurrentStep] = useState(1)
  const [processCompleted, setProcessCompleted] = useState(false)
  const [bulkForm, setBulkForm] = useState({})

  const {allRowsSelected, selectedRowIds, unselectedRowIds, findingsCount} =
    location.state

  const getTotalSelectedFindings = () => {
    if (allRowsSelected) {
      return unselectedRowIds
        ? findingsCount - unselectedRowIds.length
        : findingsCount
    } else return selectedRowIds.length
  }

  const totalSelectedFindings = getTotalSelectedFindings()

  const stepsHeaderStyle = {
    stepOne: {
      opacity: 'opacity-50',
      width: 'w-0',
    },
    stepTwo: {
      opacity: 'opacity-50',
      width: 'w-0',
    },
    stepThree: {
      opacity: 'opacity-50',
      width: 'w-0',
    },
  }

  const getCurrentStepContent = () => {
    switch (currentStep) {
      case 1:
        stepsHeaderStyle.stepOne.opacity = 'opacity-100'
        stepsHeaderStyle.stepOne.width = 'w-full'

        return (
          <StepOne
            bulkForm={bulkForm}
            setBulkForm={setBulkForm}
            totalSelectedFindings={totalSelectedFindings}
            setCurrentStep={setCurrentStep}
          />
        )

      case 2:
        stepsHeaderStyle.stepOne.opacity = 'opacity-100'
        stepsHeaderStyle.stepOne.width = 'w-full'

        stepsHeaderStyle.stepTwo.opacity = 'opacity-100'
        stepsHeaderStyle.stepTwo.width = 'w-full'

        return <StepTwo bulkForm={bulkForm} setCurrentStep={setCurrentStep} />

      case 3:
        stepsHeaderStyle.stepOne.opacity = 'opacity-100'
        stepsHeaderStyle.stepOne.width = 'w-full'

        stepsHeaderStyle.stepTwo.opacity = 'opacity-100'
        stepsHeaderStyle.stepTwo.width = 'w-full'

        stepsHeaderStyle.stepThree.opacity = 'opacity-100'
        stepsHeaderStyle.stepThree.width = 'w-full'

        return (
          <StepThree
            bulkForm={bulkForm}
            setProcessCompleted={setProcessCompleted}
          />
        )
    }
  }

  const currentStepContent = getCurrentStepContent()

  useEffect(() => {
    dispatch(clearAction('bulkFindings'))
    dispatch(clearAction('bulkFindingsResult'))
  }, [])

  return (
    <Page pageTitle={i18next.t('pageTitles.findingsBulkChange')}>
      <div className="bg-white border rounded-lg">
        <div id="header" className="flex">
          <StepsHeader
            id="step-1"
            title={i18next.t('management.bulkChange.stepOne.stepTitle')}
            description={i18next.t(
              'management.bulkChange.stepOne.stepDescription'
            )}
            opacity={stepsHeaderStyle.stepOne.opacity}
            width={stepsHeaderStyle.stepOne.width}
            complete={currentStep > 1}
          />

          <StepsHeader
            id="step-2"
            title={i18next.t('management.bulkChange.stepTwo.stepTitle')}
            description={i18next.t(
              'management.bulkChange.stepTwo.stepDescription'
            )}
            opacity={stepsHeaderStyle.stepTwo.opacity}
            width={stepsHeaderStyle.stepTwo.width}
            classes="border-x"
            complete={currentStep > 2}
          />

          <StepsHeader
            id="step-3"
            title={i18next.t('management.bulkChange.stepThree.stepTitle')}
            description={i18next.t(
              'management.bulkChange.stepThree.stepDescription'
            )}
            opacity={stepsHeaderStyle.stepThree.opacity}
            width={stepsHeaderStyle.stepThree.width}
            complete={processCompleted}
          />
        </div>

        <div id="body" className="pb-8">
          {currentStepContent}
        </div>
      </div>
    </Page>
  )
}

const StepsHeader = ({
  id,
  title,
  description,
  opacity,
  width,
  classes,
  complete,
}) => (
  <div id={id} className={`w-full border-b text-center ${classes}`}>
    <div className={`${opacity} transition-all duration-300 ease-out`}>
      <div className="bg-sky-900/50 w-full h-2">
        <div className={`bg-sky-900 h-2 duration-300 ease-out ${width}`} />
      </div>

      <div className="py-4">
        <div className="flex items-center justify-center">
          <div className="mr-1">
            <CheckIcon width="18" color={complete ? sky900 : gray300} />
          </div>
          <div className="text-lg font-medium">{title}</div>
        </div>
        <div className="text-xs text-sideral-300">{description}</div>
      </div>
    </div>
  </div>
)

StepsHeader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  opacity: PropTypes.string,
  width: PropTypes.string,
  classes: PropTypes.string,
  complete: PropTypes.bool,
}

export default BulkChange
