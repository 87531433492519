import React, {useEffect, useRef} from 'react'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import {useLocation} from 'react-router-dom'

import images from 'res'

import CvssCwe from './CvssCwe'
import ExecutiveReport from './ExecutiveReport'
import HelpList from './HelpList'
import Jira from './Jira'
import Network from './Network'
import People from './People'
import Score from 'components/help/Score'
import Webhooks from 'components/help/Webhooks'
import Questionnaire from './Questionnaire'

export default function HelpMenu(props) {
  const helpRef = useRef()
  const location = useLocation()

  const menuIsOpen = props.helpMenu === 'translate-x-full'

  const TabContent = props => {
    switch (props.tab) {
      case 'list':
        return <HelpList {...props} />
      case 'score':
        return <Score />
      case 'network':
        return <Network />
      case 'people':
        return <People />
      case 'questionnaire':
        return <Questionnaire />
      case 'executiveReport':
        return <ExecutiveReport />
      case 'webhooks':
        return <Webhooks />
      case 'jira':
        return <Jira />
      case 'cvss-cwe':
        return <CvssCwe />
    }
  }

  const hashIncludesHelp = () => {
    return location && location.hash && location.hash.includes('#/help')
  }

  const handleClickTab = name => {
    props.setTab(name)
    if (
      document.location.pathname[document.location.pathname.length - 1] !== '/'
    )
      window.history.replaceState(null, null, document.location.pathname + '/')

    window.history.replaceState(null, null, `#/help/${name}`)
  }

  useEffect(() => {
    if (hashIncludesHelp()) {
      const section = location.hash.split('/').pop()
      props.setTab(section)
    }
  }, [location])

  useEffect(() => {
    if (hashIncludesHelp())
      !menuIsOpen ? props.setHelpMenu(props.showHelpMenu) : ''
  }, [props.tab, location])

  useEffect(() => {
    if (props.showIntro) props.setHelpMenu(props.hideHelpMenu)
  }, [props.showIntro])

  useEffect(() => {
    if (helpRef.current) {
      helpRef.current.scrollTop = 0
    }
  }, [props])

  return (
    <section>
      <div
        className={`fixed hidden md:flex md:flex-col md:justify-between z-50 w-120 left-0 top-0 shadow-md bg-white duration-500 transform ease-out ${props.helpMenu}`}>
        <div>
          <div className="flex text-ink mx-auto border-b border-gray-300 w-120 h-16">
            {props.tab !== 'list' ? (
              <button
                className="flex items-center font-medium text-lg uppercase leading-none p-4"
                onClick={() => handleClickTab('list')}>
                <i className="icofont-simple-left text-xl pr-2"></i>
                {i18next.t('help.sectionTitle')}
              </button>
            ) : (
              <div className="flex flex-wrap pl-4">
                <div className="flex items-center">
                  <images.HelpIcon width="20" height="20" color="#1a202c" />
                </div>
                <div className="items-center p-3 text-gray-700">
                  <div className="font-semibold uppercase tracking-wide">
                    {i18next.t('help.sectionTitle')}
                  </div>
                  <div className="text-sm">Home</div>
                </div>
              </div>
            )}
            <button
              onClick={() => {
                props.setHelpMenu(props.hideHelpMenu)
                window.history.replaceState(
                  null,
                  null,
                  window.location.pathname
                )
              }}
              className="flex items-center cursor-pointer ml-auto p-4">
              <i className="icofont-close text-xl"></i>
            </button>
          </div>
          <div ref={helpRef} className="overflow-auto menu-scrollbar h-screen">
            <TabContent
              handleClickTab={handleClickTab}
              tab={props.tab}
              showIntro={props.showIntro}
              setShowIntro={props.setShowIntro}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

HelpMenu.propTypes = {
  showIntro: PropTypes.bool,
  setShowIntro: PropTypes.func,
  tab: PropTypes.string,
  setTab: PropTypes.func,
  closeHelp: PropTypes.func,
  setHelpMenu: PropTypes.func,
  helpMenu: PropTypes.string,
  helpButton: PropTypes.object,
  hideHelpMenu: PropTypes.string,
  showHelpMenu: PropTypes.string,
  handleClickTab: PropTypes.func,
}
