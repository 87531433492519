import PropTypes from 'prop-types'
import React from 'react'

export default function NewTabLink(props) {
  return (
    <a href={props.href} rel="noopener noreferrer" target="_blank">
      {props.children}
    </a>
  )
}

NewTabLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}
