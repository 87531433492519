import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'

import {redirectFeedback, updateQuestionnaire} from 'actions'
import {DefaultModal} from 'components/partials/DefaultModal'
import {PrimaryDatePicker} from 'components/partials/inputs/PrimaryDatePicker'

export const QuestionnaireAnsweredModal = ({questionnaireId}) => {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)

  const initialDatePickerState = {
    startDate: null,
    endDate: null,
  }
  const [showStepTwoContent, setShowStepTwoContent] = useState(false)
  const [value, setValue] = useState(initialDatePickerState)

  return !showStepTwoContent ? (
    <DefaultModal
      id="answeredQuestionnaireModal"
      title={i18next.t('questionnaires.new.modal.firstContent.title')}
      body={
        <div className="text-gray-600">
          {i18next.t('questionnaires.new.modal.firstContent.body')}
        </div>
      }
      primaryBtnText={i18next.t(
        'questionnaires.new.modal.firstContent.primaryBtn'
      )}
      secondayBtnText={i18next.t(
        'questionnaires.new.modal.firstContent.secondaryBtn'
      )}
      onClickPrimaryBtn={() => {
        setValue(initialDatePickerState)
        setShowStepTwoContent(true)
      }}
      onClickSecondaryBtn={() =>
        dispatch(
          redirectFeedback({
            path: '/questionnaires',
            status: 'success',
            message: i18next.t('questionnaires.new.messages.success'),
          })
        )
      }
    />
  ) : (
    <DefaultModal
      id="answeredQuestionnaireModal"
      title={i18next.t('questionnaires.new.modal.secondContent.title')}
      body={
        <>
          <div className="text-gray-600 mb-4">
            {i18next.t('questionnaires.new.modal.secondContent.body')}
          </div>

          <PrimaryDatePicker value={value} setValue={setValue} />
        </>
      }
      primaryBtnText={i18next.t(
        'questionnaires.new.modal.secondContent.primaryBtn'
      )}
      secondayBtnText={i18next.t(
        'questionnaires.new.modal.secondContent.secondaryBtn'
      )}
      onClickPrimaryBtn={() => {
        dispatch(
          updateQuestionnaire({
            _csrf: csrfState,
            questionnaireId,
            submittedAt: new Date(value.startDate),
            isAnUploadedQuestionnaire: true,
          })
        )
      }}
      onClickSecondaryBtn={() => setShowStepTwoContent(false)}
      disablePrimaryBtn={value.startDate === null}
    />
  )
}

QuestionnaireAnsweredModal.propTypes = {
  questionnaireId: PropTypes.string,
}
