import React from 'react'
import PropTypes from 'prop-types'

export default function TerraformIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.06 26.151v11.473L48 31.891V20.406l-9.94 5.745z"
        fill="#4040B2"
      />
      <path
        d="m27.03 20.406 9.94 5.745v11.473l-9.94-5.74V20.407zM16 14v11.479l9.94 5.74v-11.48L16 14zm11.03 30.624 9.94 5.74v-11.48l-9.94-5.739v11.48z"
        fill={color ? color : '#5C4EE5'}
      />
    </svg>
  )
}

TerraformIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
