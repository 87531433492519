import React from 'react'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router-dom'
import questionMark from 'res/icons/questionMark.svg'

export default function SectionHeader(props) {
  const navigate = useNavigate()

  return (
    <div className="w-full border-b border-sideral-10 pb-4 mb-8 mt-16 md:mt-0">
      <div className="relative flex items-center mt-3">
        <span className="dm-mono text-xl text-astral-900 top-0 mr-2 uppercase">
          {props.title}
        </span>

        {props.helpPath ? (
          <div
            id={`${props.helpPath}-help-icon`}
            onClick={() => navigate(`#/help/${props.helpPath}`)}
            className="cursor-pointer">
            <img src={questionMark} className="w-4 h-4 opacity-75" />
          </div>
        ) : (
          ''
        )}
        <div className="ml-auto">
          {props.button && props.button}

          {props.secondButton && props.secondButton}
        </div>
      </div>
    </div>
  )
}

SectionHeader.propTypes = {
  button: PropTypes.object,
  secondButton: PropTypes.object,
  title: PropTypes.string,
  helpPath: PropTypes.string,
}
