import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {sky500} from 'constant'
import PropTypes from 'prop-types'

import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import images from 'res'
import Spinner from 'components/partials/Spinner'
import {fetchSupplierSentQuestionnaires, loading} from 'actions'
import config from 'config'
import QuestionnaireStatusBadge from 'components/questionnaires/QuestionnaireStatusBadge'

export default function SupplierQuestionnairesList({supplierId}) {
  const dispatch = useDispatch()

  const supplierSentQuestionnairesState = useSelector(
    state => state.supplierSentQuestionnaires
  )

  const handleDownload = questionnaireId => {
    const url = new URL(
      `${config.SERVER_URL}/suppliers/${supplierId}/questionnaires/${questionnaireId}/download`
    )
    window.open(url, '_blank')
  }

  useEffect(() => {
    dispatch(loading({supplierSentQuestionnaires: true}))
    dispatch(fetchSupplierSentQuestionnaires({supplierId}))
  }, [])

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: i18next.t(
          'suppliers.registeredSuppliers.detail.sentQuestionnaires.table.name'
        ),
        minSize: 250,
        meta: {
          align: 'left',
        },
      },

      {
        id: 'questionsCount',
        accessorKey: 'questionsCount',
        header: i18next.t(
          'suppliers.registeredSuppliers.detail.sentQuestionnaires.table.questions'
        ),
        maxSize: 30,
      },

      {
        id: 'status',
        accessorKey: 'status',
        header: 'Status',
        cell: cell => (
          <QuestionnaireStatusBadge
            status={cell.getValue()}
            changedAt={cell.row.original.statusChangedAt}
          />
        ),
      },

      {
        id: 'download',
        accessorKey: 'id',
        header: '',
        maxSize: 10,
        cell: cell => (
          <div className="z-100">
            <button
              onClick={() => {
                handleDownload(cell.getValue())
              }}
              title={i18next.t('buttons.download')}
              disabled={cell.row.original.status !== 'submitted'}>
              <images.DownloadIcon height="28" width="28" color={sky500} />
            </button>
          </div>
        ),
        disableGlobalFilter: true,
        isButton: true,
      },
    ],
    []
  )

  const getRowStyle = row => {
    if (row.original.status === 'submitted')
      return 'bg-orange-100 hover:!bg-orange-200'
  }

  if (!supplierSentQuestionnairesState) return <Spinner />

  return (
    <div className="p-6">
      <PrimaryTableV8
        columns={columns}
        data={supplierSentQuestionnairesState}
        detailPath={row =>
          `/suppliers/${supplierId}/questionnaire/${row.original.id}`
        }
        getRowStyle={getRowStyle}
        emptyStateWithFilter={
          <GenericEmptyState
            icon={<i className={`icofont-list text-6xl text-sideral-300`} />}
            title={i18next.t('tables.nothingFoundWithSearch')}
            body={i18next.t('tables.searchSomethingElse')}
          />
        }
        emptyStateWithoutFilter={
          <GenericEmptyState
            icon={<i className={`icofont-list text-6xl text-sideral-300`} />}
            title={i18next.t('tables.noResultsFound')}
            body={i18next.t(
              'suppliers.registeredSuppliers.noSentQuestionnaires'
            )}
          />
        }
      />
    </div>
  )
}

SupplierQuestionnairesList.propTypes = {
  supplierId: PropTypes.string,
}
