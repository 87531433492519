import React from 'react'
import PropTypes from 'prop-types'

export default function FileIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 29"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color || '#0A50A0'}
        d="M5.75 23.2H17.25V20.3H5.75V23.2ZM5.75 17.4H17.25V14.5H5.75V17.4ZM2.875 29C2.08437 29 1.40755 28.716 0.844531 28.1481C0.28151 27.5802 0 26.8975 0 26.1V2.9C0 2.1025 0.28151 1.41979 0.844531 0.851875C1.40755 0.283958 2.08437 0 2.875 0H14.375L23 8.7V26.1C23 26.8975 22.7185 27.5802 22.1555 28.1481C21.5924 28.716 20.9156 29 20.125 29H2.875ZM12.9375 10.15H20.125L12.9375 2.9V10.15Z"
      />
    </svg>
  )
}

FileIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
