/*
 * This saga will be deprecated with Web V2
 */
import config from '../config'
import {handler} from './handler'
import {
  fetchWebApps,
  fetchWebStats,
  genericError,
  mergeWebAppsDone,
  success,
  unmergeWebAppDone,
} from 'actions'

export function* handleUnmergeWebApp(action) {
  yield handler({
    handler: _unmergeWebApp,
    success: [unmergeWebAppDone, fetchWebApps],
    action,
  })
}

const _unmergeWebApp = body => {
  return fetch(`${config.SERVER_URL}/webapp/unmerge`, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleMergeWebApps(action) {
  yield handler({
    handler: _mergeWebApps,
    success: [fetchWebApps, mergeWebAppsDone, fetchWebStats],
    genericError,
    action,
  })
}

const _mergeWebApps = body => {
  return fetch(`${config.SERVER_URL}/webapp/merge`, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateWebApp(action) {
  yield handler({
    handler: _updateWebApp,
    success: [fetchWebApps, fetchWebStats, success],
    genericError,
    action,
  })
}

const _updateWebApp = body => {
  return fetch(`${config.SERVER_URL}/webapp/${body.webappId}`, {
    body: JSON.stringify(body),
    method: 'PATCH',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}
