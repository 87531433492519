import React from 'react'
import PropTypes from 'prop-types'

export default function InternetExposureIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color || '#FFFFFF'}
        d="M22.912 8.758l-9.786 4.207c-0.177 0.090-0.32 0.233-0.411 0.411l-4.483 9.038c-0.184 0.36-0.117 0.798 0.166 1.087 0.181 0.184 0.426 0.282 0.673 0.282 0.139 0 0.279-0.030 0.41-0.094l9.554-3.968c0.175-0.084 0.32-0.221 0.413-0.391l4.715-9.278c0.201-0.363 0.141-0.813-0.145-1.111-0.288-0.298-0.737-0.371-1.105-0.184zM11.183 20.776l2.719-5.553 2.746 3.437zM18.272 17.488l-2.775-3.473 5.525-2.18zM16 0c-8.836 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 30.032c-7.72 0-14-6.312-14-14.032s6.28-14 14-14 14 6.28 14 14-6.28 14.032-14 14.032z"></path>
    </svg>
  )
}

InternetExposureIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
