import i18next from 'i18next'

import config from '../config'
import {
  fetchAwsFinding,
  fetchAzureFinding,
  fetchComplianceStatsDone,
  fetchGcpFinding,
  fetchLgpdDetailsDone,
  fetchLgpdFindingsDone,
  fetchLgpdIntegrationsDone,
  fetchLgpdItemDetailsDone,
  genericError,
  redirectFeedback,
  startLgpdMonitoringDone,
  success,
} from 'actions'
import {handler} from './handler'

export function* handleRemoveTagFromAsset(action) {
  yield handler({
    handler: _removeTagFromAsset,
    error: genericError,
    success: [
      () =>
        success({message: i18next.t('cloud.success.notSensitiveTagRemoved')}),
      () => {
        switch (action.payload.provider) {
          case 'aws':
            return fetchAwsFinding(action.payload.findingId)
          case 'azure':
            return fetchAzureFinding(action.payload.findingId)
          case 'gcp':
            return fetchGcpFinding(action.payload.findingId)
        }
      },
    ],
    action,
  })
}

const _removeTagFromAsset = function (body) {
  return fetch(`${config.SERVER_URL}/compliance/lgpd/tags`, {
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleAddTagToAsset(action) {
  yield handler({
    handler: _addTagToAsset,
    error: genericError,
    success: [
      () => success({message: i18next.t('cloud.success.notSensitiveTagAdded')}),
      () => {
        switch (action.payload.provider) {
          case 'aws':
            return fetchAwsFinding(action.payload.findingId)
          case 'azure':
            return fetchAzureFinding(action.payload.findingId)
          case 'gcp':
            return fetchGcpFinding(action.payload.findingId)
        }
      },
    ],
    action,
  })
}

const _addTagToAsset = function (body) {
  return fetch(config.SERVER_URL + '/compliance/lgpd/tags', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchComplianceStats(action) {
  yield handler({
    handler: _fetchComplianceStats,
    error: genericError,
    success: fetchComplianceStatsDone,
    action,
  })
}

const _fetchComplianceStats = function () {
  return fetch(config.SERVER_URL + '/compliance/', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchLgpdIntegrations(action) {
  yield handler({
    handler: _fetchLgpdIntegrations,
    error: genericError,
    success: fetchLgpdIntegrationsDone,
    action,
  })
}

const _fetchLgpdIntegrations = function () {
  return fetch(config.SERVER_URL + '/compliance/lgpd/integrations/list', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchLgpdDetails(action) {
  yield handler({
    handler: _fetchLgpdDetails,
    error: genericError,
    success: fetchLgpdDetailsDone,
    action,
  })
}

const _fetchLgpdDetails = function () {
  return fetch(config.SERVER_URL + '/compliance/lgpd/integrations/details', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchLgpdItemDetails(action) {
  yield handler({
    handler: _fetchLgpdItemDetails,
    error: genericError,
    success: fetchLgpdItemDetailsDone,
    action,
  })
}

const _fetchLgpdItemDetails = function (itemId) {
  return fetch(config.SERVER_URL + `/compliance/items/${itemId}`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchLgpdFindings(action) {
  yield handler({
    handler: _fetchLgpdFindings,
    error: genericError,
    success: fetchLgpdFindingsDone,
    action,
  })
}

const _fetchLgpdFindings = function () {
  return fetch(config.SERVER_URL + '/compliance/lgpd/findings', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleStartLgpdMonitoring(action) {
  const path = action.payload.integrations.length
    ? '/compliance/lgpd'
    : '/compliance'

  yield handler({
    handler: _startLgpdMonitoring,
    error: () => genericError({loading: {lgpdIntegration: false}}),
    success: [
      () =>
        redirectFeedback({
          path,
          message: i18next.t('compliance.lgpd.success.updateIntegration'),
          status: 'success',
        }),
      startLgpdMonitoringDone,
    ],
    action,
  })
}

const _startLgpdMonitoring = function (body) {
  return fetch(config.SERVER_URL + '/compliance/lgpd/integrations', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}
