import React, {useState, useMemo, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {i18next} from 'translate/i18n'
import {v4 as uuidv4} from 'uuid'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import images from 'res'
import {mars700, sky500, tooltipStyle} from 'constant'
import {
  fetchAzureBlobContainers,
  fetchAzureDisks,
  fetchAzureStorageAccounts,
  fetchAzureVmInstances,
  fetchAzureFindingsByResource,
  fetchAzureSqlDatabases,
  fetchAzureSqlServers,
  fetchAzurePostgresqlServers,
  fetchAzureMysqlServers,
} from 'actions'
import {
  blobContainersColumns,
  disksColumns,
  findingsColumns,
  mysqlServersColumns,
  postgresqlServersColumns,
  resourceGroupsColumns,
  sqlDatabasesColumns,
  sqlServersColumns,
  storageAccountsColumns,
  vmInstancesColumns,
} from './columns'
import {extractResourceGroup} from 'utils'

import Spinner from 'components/partials/Spinner'
import InnerTable from './InnerTable'

export default function AssetsTable(props) {
  const azureStatsState = useSelector(state => state.azureStats)
  const azureFindingsByResourceState = useSelector(
    state => state.azureFindingsByResource
  )
  const azureBlobContainersState = useSelector(
    state => state.azureBlobContainers
  )
  const azureDisksState = useSelector(state => state.azureDisks)
  const azureMysqlServersState = useSelector(state => state.azureMysqlServers)
  const azurePostgresqlServersState = useSelector(
    state => state.azurePostgresqlServers
  )
  const azureResourceGroupsState = useSelector(
    state => state.azureResourceGroups
  )
  const azureSqlDatabasesState = useSelector(state => state.azureSqlDatabases)
  const azureSqlServersState = useSelector(state => state.azureSqlServers)
  const azureStorageAccountsState = useSelector(
    state => state.azureStorageAccounts
  )
  const azureVmInstancesState = useSelector(state => state.azureVmInstances)

  const dispatch = useDispatch()

  const [openAccordion, setOpenAccordion] = useState('')
  const [tabs, setTabs] = useState({})

  const [blobContainers, setBlobContainers] = useState([])
  const [mysqlServers, setMysqlServers] = useState([])
  const [postgresqlServers, setPostgresqlServers] = useState([])
  const [sqlDatabases, setSqlDatabases] = useState([])
  const [sqlServers, setSqlServers] = useState([])
  const [storageAccounts, setStorageAccounts] = useState([])
  const [vmInstances, setVmInstances] = useState([])

  const toggleAccordion = resourceName => {
    openAccordion === resourceName
      ? setOpenAccordion('')
      : setOpenAccordion(resourceName)
  }

  const handleTab = (eventTarget, resourceName) => {
    if (eventTarget === tabs[resourceName]) return

    setTabs({...tabs, [resourceName]: eventTarget})
  }

  const latestCheckFailed = resourceName => {
    let service

    switch (resourceName) {
      case 'vms':
      case 'disks':
        service = 'compute'
        break
      case 'storageAccounts':
      case 'blobContainers':
        service = 'storage'
        break
      case 'sqlServers':
      case 'sqlDatabases':
      case 'mysqlServers':
      case 'postgresqlServers':
        service = 'database'
        break
    }

    return (
      azureStatsState &&
      azureStatsState.latestCheck &&
      azureStatsState.latestCheck.logs &&
      azureStatsState.latestCheck.logs[service] &&
      azureStatsState.latestCheck.logs[service] !== 'ok'
    )
  }

  const resourceIsLoading = resourceName => {
    return (
      !azureFindingsByResourceState ||
      !azureFindingsByResourceState[resourceName]
    )
  }

  const defaultColumn = useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {getTableProps, getTableBodyProps, prepareRow, page} = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        pageIndex: props.pageNumber,
        pageSize: props.pageSize || 10,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )
  function fetchAssets(resourceName) {
    switch (resourceName) {
      case 'blobContainers':
        !azureBlobContainersState &&
          dispatch(fetchAzureBlobContainers(props.tenantId))
        break
      case 'disks':
        !azureDisksState && dispatch(fetchAzureDisks(props.tenantId))
        break
      case 'mysqlServers':
        !azureMysqlServersState &&
          dispatch(fetchAzureMysqlServers(props.tenantId))
        break
      case 'postgresqlServers':
        !azurePostgresqlServersState &&
          dispatch(fetchAzurePostgresqlServers(props.tenantId))
        break
      case 'sqlDatabases':
        !azureSqlDatabasesState &&
          dispatch(fetchAzureSqlDatabases(props.tenantId))
        break
      case 'sqlServers':
        !azureSqlServersState && dispatch(fetchAzureSqlServers(props.tenantId))
        break
      case 'storageAccounts':
        !azureStorageAccountsState &&
          dispatch(fetchAzureStorageAccounts(props.tenantId))
        break
      case 'vms':
        !azureVmInstancesState &&
          dispatch(fetchAzureVmInstances(props.tenantId))
        break
      default:
        return
    }
  }

  function fetchFindings(resourceName) {
    if (
      !azureFindingsByResourceState ||
      (azureFindingsByResourceState &&
        !azureFindingsByResourceState[resourceName])
    ) {
      dispatch(
        fetchAzureFindingsByResource({
          resourceName: resourceName,
          tenantId: props.tenantId,
        })
      )
    }
  }

  function handleRowClick(resourceName) {
    fetchFindings(resourceName)
    fetchAssets(resourceName)
    handleTab('findings', resourceName)
    toggleAccordion(resourceName)
  }

  function getFindingsData(resourceName) {
    return (
      azureFindingsByResourceState && azureFindingsByResourceState[resourceName]
    )
  }

  const innerTableProps = {
    blobContainers: {
      data: blobContainers,
      columns: useMemo(() => blobContainersColumns, []),
    },
    disks: {
      data: azureDisksState,
      columns: useMemo(() => disksColumns, []),
    },
    mysqlServers: {
      data: mysqlServers,
      columns: useMemo(() => mysqlServersColumns, []),
    },
    postgresqlServers: {
      data: postgresqlServers,
      columns: useMemo(() => postgresqlServersColumns, []),
    },
    resourceGroups: {
      data: azureResourceGroupsState,
      columns: useMemo(() => resourceGroupsColumns, []),
    },
    sqlDatabases: {
      data: sqlDatabases,
      columns: useMemo(() => sqlDatabasesColumns, []),
    },
    sqlServers: {
      data: sqlServers,
      columns: useMemo(() => sqlServersColumns, []),
    },
    storageAccounts: {
      data: storageAccounts,
      columns: useMemo(() => storageAccountsColumns, []),
    },
    vms: {
      data: vmInstances,
      columns: useMemo(() => vmInstancesColumns, []),
    },
    findings: {columns: useMemo(() => findingsColumns, [])},
  }

  useEffect(() => {
    if (!azureBlobContainersState) return

    const arr = azureBlobContainersState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setBlobContainers(arr)
  }, [azureBlobContainersState])

  useEffect(() => {
    if (!azurePostgresqlServersState) return

    const arr = azurePostgresqlServersState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setPostgresqlServers(arr)
  }, [azurePostgresqlServersState])

  useEffect(() => {
    if (!azureMysqlServersState) return

    const arr = azureMysqlServersState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setMysqlServers(arr)
  }, [azureMysqlServersState])

  useEffect(() => {
    if (!azureSqlDatabasesState) return

    const arr = azureSqlDatabasesState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setSqlDatabases(arr)
  }, [azureSqlDatabasesState])

  useEffect(() => {
    if (!azureSqlServersState) return

    const arr = azureSqlServersState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setSqlServers(arr)
  }, [azureSqlServersState])

  useEffect(() => {
    if (!azureStorageAccountsState) return

    const arr = azureStorageAccountsState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setStorageAccounts(arr)
  }, [azureStorageAccountsState])

  useEffect(() => {
    if (!azureVmInstancesState) return

    const arr = azureVmInstancesState
    arr.map(
      item => (item.resourceGroup = extractResourceGroup(item.resourceGroupId))
    )

    setVmInstances(arr)
  }, [azureVmInstancesState])

  return (
    <div>
      <div className="tableWrap relative">
        {page && page.length ? (
          <table {...getTableProps()} className="w-full">
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr
                    onClick={() => {
                      handleRowClick(row.original.resourceName)
                    }}
                    {...row.getRowProps()}
                    className="flex flex-wrap w-full rounded-lg border-2 border-sideral-50 my-2"
                    key={uuidv4()}>
                    {row.cells.map(cell => {
                      if (cell.column.id === 'name') {
                        return (
                          <td
                            className="uppercase flex cursor-pointer text-left text-sideral-700 font-medium text-sm items-center p-4"
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {cell.value ? cell.render('Cell') : '-'}
                          </td>
                        )
                      }
                      if (cell.column.id === 'findings') {
                        return (
                          <td
                            className="flex cursor-pointer items-center p-4 text-sm"
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <figure className="pr-1">
                              <images.FindingIcon width="20" />
                            </figure>
                            {typeof cell.row.original.findings === 'number'
                              ? cell.row.original.findings
                              : '-'}{' '}
                            {cell.value && cell.row.original.findings > 1
                              ? i18next.t('misc.findings').toLowerCase()
                              : i18next.t('misc.finding').toLowerCase()}
                          </td>
                        )
                      }
                      if (cell.column.id === 'chevron')
                        return (
                          <td
                            className="flex cursor-pointer items-center w-20 p-4"
                            key={uuidv4()}>
                            {latestCheckFailed(row.original.resourceName) ? (
                              <figure className="mr-4">
                                <images.WarningIcon
                                  width="20"
                                  color={mars700}
                                />
                              </figure>
                            ) : (
                              <></>
                            )}
                            <figure className="ml-auto">
                              <images.Chevron
                                width="12"
                                color={sky500}
                                direction={
                                  openAccordion === row.original.resourceName
                                    ? 'up'
                                    : 'down'
                                }
                              />
                            </figure>
                          </td>
                        )
                      return (
                        <td
                          className={cell.column.classes || ''}
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          {cell.value ? cell.render('Cell') : '—'}
                        </td>
                      )
                    })}

                    {openAccordion === row.original.resourceName ? (
                      <>
                        {resourceIsLoading(row.original.resourceName) ? (
                          <td className="w-full -mt-4">
                            <Spinner />
                          </td>
                        ) : (
                          <td
                            className="relative bg-astral-50 border-t-2 border-sideral-50 w-full p-4"
                            onClick={e => e.stopPropagation()}>
                            <div className="flex text-sky-500 text-sm mb-4">
                              <Tab
                                value="findings"
                                tabs={tabs}
                                handleTab={handleTab}
                                text={i18next.t('misc.findings')}
                                row={row}
                              />
                              <Tab
                                value="assets"
                                tabs={tabs}
                                handleTab={handleTab}
                                text={i18next.t('misc.assets')}
                                row={row}
                              />
                            </div>
                            <div className="flex flex-wrap w-full">
                              {latestCheckFailed(row.original.resourceName) && (
                                <CheckFailed />
                              )}
                              {tabs[row.original.resourceName] ===
                                'findings' && (
                                <InnerTable
                                  data={getFindingsData(
                                    row.original.resourceName
                                  )}
                                  columns={innerTableProps.findings.columns}
                                  tab={tabs[row.original.resourceName]}
                                  rowId={row.original.resourceName}
                                />
                              )}
                              {tabs[row.original.resourceName] === 'assets' && (
                                <InnerTable
                                  data={
                                    innerTableProps[row.original.resourceName]
                                      .data
                                  }
                                  columns={
                                    innerTableProps[row.original.resourceName]
                                      .columns
                                  }
                                  tab={tabs[row.original.resourceName]}
                                  rowId={row.original.resourceName}
                                />
                              )}
                            </div>
                          </td>
                        )}
                      </>
                    ) : null}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

const Tab = ({tabs, handleTab, value, text, row}) => {
  return (
    <button
      value={value}
      className={`${
        tabs[row.original.resourceName] === value
          ? ' border-sky-500 text-sky-900'
          : 'border-transparent'
      } border-b-2 uppercase cursor-pointer outline-none mr-5 pb-2`}
      onClick={e => {
        handleTab(e.target.value, row.original.resourceName)
      }}>
      {text}
    </button>
  )
}

const CheckFailed = () => {
  return (
    <Link
      to="/cloud/azure/integration"
      className="tooltip flex items-center absolute top-0 right-0 rounded font-light text-xs mt-4 mr-4 z-20">
      <images.WarningIcon width="20" color={mars700} />
      <span className="underline text-mars-700 ml-1">
        {i18next.t('errors.latestCheckFailed')}
      </span>
      <span
        className={`${tooltipStyle.danger} flex items-center top-0 left-0 mt-6 h-12`}>
        {i18next.t('errors.checkPermissions')}
      </span>
    </Link>
  )
}

AssetsTable.propTypes = {
  defaultSorted: PropTypes.array,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  tenantId: PropTypes.string,
}

Tab.propTypes = {
  tabs: PropTypes.object,
  handleTab: PropTypes.func,
  text: PropTypes.string,
  value: PropTypes.string,
  row: PropTypes.object,
}
