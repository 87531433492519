import React from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import i18next from 'i18next'
import {integrationStyles} from 'constant'
import {validate} from 'uuid'
import images from 'res'
import {anchorLinkMarkdown} from 'utils'
import {useNavigate} from 'react-router-dom'

import Tutorial from 'components/partials/Tutorial'
import ReactMarkdown from 'react-markdown'
import CopyToClipboard from 'components/partials/CopyToClipboard'

const AwsCloudFormationUpdateTutorial = () => {
  const navigate = useNavigate()

  const templateUrl =
    'https://s3.amazonaws.com/frontend-app.unxpose.com/cloud/cloudformation.template'

  let cloudFormationStackLink = ''

  const redirectToCloudHome = () => navigate('/cloud')

  const copyToClipboard = () => {
    navigator.clipboard.writeText(templateUrl)
  }

  const params = new URLSearchParams(window.location.search)
  const externalId = params.get('externalId')

  if (externalId && validate(externalId))
    cloudFormationStackLink = `https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?templateURL=${config.cloud.cloudFormation.templateUrl}&stackName=UnxposeIntegration&param_ExternalId=${externalId}`
  else redirectToCloudHome()

  const markdownSteps = `
  
  ${i18next.t('cloud.aws.integrationUpdate.cloudFormation.steps.one')}
  
  ${i18next.t('cloud.aws.integrationUpdate.cloudFormation.steps.two')}
  ![findStack](${images.findStack})

  ${i18next.t('cloud.aws.integrationUpdate.cloudFormation.steps.three')}
  ![updateStack](${images.updateStack})

  ${i18next.t('cloud.aws.integrationUpdate.cloudFormation.steps.four')}

  ${i18next.t('cloud.aws.integrationUpdate.cloudFormation.steps.five')}
  ${`~~~pre
  ~~~`}
  ![specifyTemplate](${images.specifyTemplate})

  ${i18next.t('cloud.aws.integrationUpdate.cloudFormation.steps.six')}

  ${i18next.t('cloud.aws.integrationUpdate.cloudFormation.steps.seven')}
  ![capabilitiesCheckbox](${images.capabilitiesCheckbox})

  ${i18next.t('cloud.aws.integrationUpdate.cloudFormation.steps.eight')}`

  const markdownStepsError = `
  
  ${i18next.t('cloud.aws.integrationUpdate.cloudFormation.errorCase.steps.one')}
  ![deleteStack](${images.deleteStack})
  
  ${i18next.t(
    'cloud.aws.integrationUpdate.cloudFormation.errorCase.steps.two',
    {stack_link: cloudFormationStackLink}
  )}
  
  ${i18next.t(
    'cloud.aws.integrationUpdate.cloudFormation.errorCase.conclusion'
  )}
  `

  return (
    <Tutorial pageTitle="Cloud" falbackLink="/cloud">
      <div className={integrationStyles.mainTitle}>
        {i18next.t('cloud.aws.integrationUpdate.cloudFormation.title')}
      </div>

      <div className={integrationStyles.secondaryTitle}>
        {i18next.t('cloud.aws.integrationUpdate.cloudFormation.description')}
      </div>

      <div className="template-markdown break-words">
        <ReactMarkdown
          components={{
            a: anchorLinkMarkdown,
            pre: () => (
              <CopyToClipboard
                content={templateUrl}
                classes="-mt-4"
                onClick={copyToClipboard}
              />
            ),
          }}>
          {markdownSteps}
        </ReactMarkdown>
      </div>

      <div id="error-case">
        <div className={integrationStyles.mainTitle}>
          {i18next.t(
            'cloud.aws.integrationUpdate.cloudFormation.errorCase.title'
          )}
        </div>

        <div className={integrationStyles.secondaryTitle}>
          {i18next.t(
            'cloud.aws.integrationUpdate.cloudFormation.errorCase.description'
          )}
        </div>

        <div className="template-markdown break-words">
          <ReactMarkdown
            components={{
              a: anchorLinkMarkdown,
            }}>
            {markdownStepsError}
          </ReactMarkdown>
        </div>
      </div>
    </Tutorial>
  )
}

AwsCloudFormationUpdateTutorial.propTypes = {
  history: PropTypes.object,
}

export default AwsCloudFormationUpdateTutorial
