import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import Datepicker from 'react-tailwindcss-datepicker'

export const PrimaryDatePicker = ({
  text,
  value,
  setValue,
  disabled,
  asSingle = true,
}) => (
  <>
    <label className="block tracking-wide text-sideral-700 text-xs font-medium mb-1">
      {text}
    </label>
    <Datepicker
      primaryColor="blue"
      inputClassName="w-full text-ink border border-sideral-100 focus:border-sky-500 rounded roboto leading-tight focus:outline-none focus:bg-white p-3"
      toggleClassName="absolute bg-sky-900 rounded-r text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
      value={value}
      onChange={setValue}
      useRange={false}
      maxDate={new Date()}
      i18n={i18next.language}
      placeholder={
        asSingle
          ? i18next.t('misc.datePickerPlaceholder')
          : i18next.t('misc.datePickerMultiplePlaceholder')
      }
      displayFormat={i18next.language === 'pt-br' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
      asSingle={asSingle}
      disabled={disabled}
    />
  </>
)

PrimaryDatePicker.propTypes = {
  text: PropTypes.string,
  value: PropTypes.object,
  setValue: PropTypes.func,
  asSingle: PropTypes.bool,
  disabled: PropTypes.bool,
}
