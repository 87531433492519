import React from 'react'
import PropTypes from 'prop-types'
import PlanUtils from 'utils/plan'
import PlaceholderContent from './PlaceholderContent'

export default function ContentBox({
  id,
  margin,
  header,
  content,
  isPremiumFeature,
  padding = 'px-4',
}) {
  const showPlaceholderContent =
    isPremiumFeature && PlanUtils().hasPlanRestriction()

  return (
    <div
      className={`rounded-lg border border-sideral-100 ${
        margin ? margin : ''
      }`}>
      <div className="border-b border-sideral-100 p-4 dm-mono uppercase">
        {header}
      </div>
      <div className="rounded-lg bg-white">
        {showPlaceholderContent ? (
          <PlaceholderContent sectionId={id} />
        ) : (
          <div className={padding}>{content}</div>
        )}
      </div>
    </div>
  )
}

ContentBox.propTypes = {
  id: PropTypes.string,
  content: PropTypes.any,
  header: PropTypes.any,
  margin: PropTypes.string,
  padding: PropTypes.string,
  isPremiumFeature: PropTypes.bool,
}
