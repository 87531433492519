import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router-dom'
import images from 'res'

import SectionAdvantages from 'components/partials/SectionAdvantages'
import PlanUtils from 'utils/plan'
import PaperForm from 'components/partials/PaperForm'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'

export default function DocumentsEmptyState({hasAccessToDocuments}) {
  const navigate = useNavigate()

  const [showPaperForm, setShowPaperform] = useState(false)

  const planUtils = PlanUtils()

  const labels = planUtils.getPlanModalLabels()

  useEffect(() => {
    document.addEventListener('mouseup', e => {
      const modal = document.querySelector('#documentsSalesModal')
      if (e.target === modal) setShowPaperform(false)
    })
  }, [])

  return (
    <>
      <div className="w-full flex-wrap bg-white text-center border border-sideral-100 font-medium rounded-md p-4 my-8">
        <SectionAdvantages
          headerIcon={<i className="icofont-ui-folder text-5xl text-sky-500" />}
          headerTitle={
            hasAccessToDocuments
              ? i18next.t('documents.advantages.noResults')
              : i18next.t('documents.advantages.documentsUnavailable')
          }
          title={i18next.t('documents.advantages.title')}
          advantagesItems={[
            i18next.t('documents.advantages.items.first'),
            i18next.t('documents.advantages.items.second'),
            i18next.t('documents.advantages.items.third'),
          ]}
          actionButtons={
            <PrimaryButton
              text={
                hasAccessToDocuments
                  ? i18next.t('documents.advantages.addNewBtn')
                  : i18next.t('documents.advantages.subscribeBtn')
              }
              onClick={() => {
                if (hasAccessToDocuments) return navigate('/documents/new')
                else setShowPaperform(true)
              }}
            />
          }
          rightSideImage={
            <img className="w-full m-auto" src={images.documentsPlaceholder} />
          }
        />
      </div>

      {showPaperForm && (
        <div
          id="documentsSalesModal"
          className="modal-bg fixed top-0 left-0 w-screen h-screen z-50">
          <div
            className={`fixed bg-white border rounded-md bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 max-w-lg w-full p-8`}>
            <PaperForm
              classes="relative"
              title={labels.paperformTitles}
              onClickBack={() => setShowPaperform(false)}
              useCloseIcon
            />
          </div>
        </div>
      )}
    </>
  )
}

DocumentsEmptyState.propTypes = {
  hasAccessToDocuments: PropTypes.bool,
}
