import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import i18next from 'i18next'
import {Link} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'

import {
  fetchBreachesOverTime,
  fetchCompanyScores,
  fetchCompanyStats,
  fetchDnsStats,
  fetchNetworkStats,
  fetchNextSteps,
  fetchScoreOverTime,
  fetchTotalVsClosedIssues,
  fetchWebStats,
  loading,
} from 'actions'

import CloudIcon from 'res/icons/cloudIcon'
import PlanUtils from 'utils/plan'
import images from 'res'
import {sky900, tooltipStyle} from 'constant'

import DashboardScore from 'components/partials/DashboardScore'
import ScoreBadgeSquare from 'components/partials/ScoreBadgeSquare'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import Subsection from 'components/partials/headers/Subsection'

import People from './People'
import Discovery from './Discovery'
import FindingsBySeverityChart from './FindingsBySeverityChart'
import NextSteps from './NextSteps'
import ScoreOvertimeChart from './ScoreOverTimeChart'
import TotalVsClosedChart from './TotalVsClosedChart'
import {Page} from 'components/partials/Page'
import {isLoading} from 'utils'

export default function Dashboard() {
  const userState = useSelector(state => state.user)
  const loadingState = useSelector(state => state.loading)
  const scoresState = useSelector(state => state.scores)
  const statsPeopleState = useSelector(state => state.statsPeople)

  const dispatch = useDispatch()

  const planUtils = PlanUtils()

  useEffect(() => {
    if (userState && userState.company) {
      dispatch(loading({scores: true, nextSteps: true}))
      dispatch(fetchCompanyScores())
      dispatch(fetchCompanyStats())
      dispatch(
        fetchCompanyStats({companyId: userState.company.id, type: 'people'})
      )
      dispatch(fetchDnsStats())
      dispatch(fetchNetworkStats())
      dispatch(fetchWebStats())
      dispatch(fetchNextSteps())

      if (!planUtils.hasPlanRestriction()) {
        dispatch(fetchTotalVsClosedIssues())
        dispatch(fetchScoreOverTime())

        dispatch(fetchBreachesOverTime())
      }
    }
  }, [dispatch, userState])

  return (
    <Page pageTitle={i18next.t('pageTitles.overview')}>
      <Subsection
        title={i18next.t('score')}
        helpPath={'score'}
        content={
          <SecurityScoreSection
            overallScore={
              scoresState && scoresState.general && scoresState.general.grade
            }
          />
        }
        loading={isLoading(loadingState.scores)}
      />
      <div className="flex flex-wrap xl:flex-nowrap mt-10">
        <Subsection
          id="nextSteps"
          title={i18next.t('titles.nextSteps')}
          content={<NextSteps />}
          width="w-full xl:w-4/12"
          margin="xl:mr-4 mb-10 xl:mb-0"
          loadingMargin="-mt-16 py-10"
          loading={isLoading(loadingState.nextSteps)}
        />

        <Subsection
          id="securityScoreOverTime"
          title={i18next.t('titles.securityScoreOverTime')}
          content={<ScoreOvertimeChart />}
          width="relative grow"
          isPremiumFeature
        />
      </div>

      <div className="flex flex-wrap lg:flex-nowrap justify-between mt-10">
        <Subsection
          id="totalClosedOverTime"
          title={i18next.t('titles.totalVsClosed')}
          content={<TotalVsClosedChart />}
          width="w-full lg:w-8/12"
          margin="lg:mr-4"
          isPremiumFeature
        />
        <Subsection
          title={
            <div className="flex">
              <p>{i18next.t('titles.unresolvedFindingsBySeverity')}</p>
              <div className="tooltip ml-2">
                <images.InfoIcon width="20" />
                <div
                  className={`${tooltipStyle.default} normal-case text-center leading-5 right-0 top-0 transform -translate-y-full`}
                  dangerouslySetInnerHTML={{
                    __html: i18next.t('help.unresolvedFindingsBySeverity'),
                  }}
                />
              </div>
            </div>
          }
          content={<FindingsBySeverityChart />}
          width="w-full lg:w-4/12"
          margin="mt-10 lg:mt-0"
        />
      </div>
      <div className="flex flex-wrap md:flex-nowrap mt-10">
        {statsPeopleState && (
          <Subsection title={'People'} content={<People />} width="w-full" />
        )}
      </div>
      <Subsection
        title={i18next.t('titles.assetDiscovery')}
        content={<Discovery />}
        margin="mt-10"
      />
    </Page>
  )
}

const SecurityScoreSection = ({overallScore}) => {
  const scoresState = useSelector(state => state.scores)

  const sectionGrade = section =>
    scoresState && scoresState[section] && scoresState[section].grade
      ? scoresState[section].grade
      : '-'

  const categories = [
    {
      name: 'dns',
      score: sectionGrade('dns'),
      isActive: true,
    },
    {
      name: 'network',
      score: sectionGrade('network'),
      isActive: true,
    },
    {
      name: 'web',
      score: sectionGrade('web'),
      isActive: true,
    },
    {
      name: 'cloud',
      score: sectionGrade('cloud'),
      isActive: scoresState?.cloud?.value !== null,
    },
    {
      name: 'people',
      score: sectionGrade('people'),
      isActive: true,
    },
  ]

  return (
    <>
      <div className="grid grid-cols-12 dm-mono text-sideral-900 p-4">
        <div className="col-span-12 lg:col-span-9 order-last sm:order-first">
          <h1 className="font-medium">
            /{i18next.t('titles.scoreByCategory').toUpperCase()}
          </h1>
          <div className="sm:flex sm:flex-wrap sm:justify-start mt-4">
            {categories.map((category, index) => {
              if (category.isActive)
                return (
                  <span
                    key={`${category.name + index}`}
                    className="flex sm:flex-grow-2 sm:flex-wrap items-center justify-between sm:justify-center sm:border-r border-sideral-100 last:border-0 my-3 xl:mb-0 px-1 sm:px-0">
                    <Link to={`/${category.name}`}>
                      <ScoreBadgeSquare letter={category.score} />
                    </Link>
                    <p className="mt-2 text-center sm:w-full order-first sm:order-last">
                      {category.name.toUpperCase()}
                    </p>
                  </span>
                )

              if (category.name === 'cloud' && !category.isActive)
                return (
                  <span
                    key={`${category.name + index}`}
                    className="flex flex-grow-2 sm:flex-wrap items-center justify-between sm:justify-center sm:border-r border-sideral-100 mb-3 sm:mb-3">
                    <p className="mt-2 sm:text-center sm:w-full sm:order-last">
                      {category.name.toUpperCase()}
                    </p>

                    <div className="flex items-center sm:block">
                      <figure className="sm:flex justify-center items-center sm:w-full sm:mb-2 mr-3 sm:mr-0">
                        <CloudIcon width="36" color={sky900} />
                      </figure>
                      <Link to={'/cloud/integration'}>
                        <SecondaryButton
                          text={i18next.t('buttons.setUpNow')}
                          direction="right"
                          size="sm"
                        />
                      </Link>
                    </div>
                  </span>
                )
            })}
          </div>
        </div>
        <div className="lg:col-span-3 col-span-12 sm:mt-4 mb-8 sm:mb-0 lg:mt-0">
          <DashboardScore
            overallScore={overallScore}
            percentage={
              scoresState && scoresState.general && scoresState.general.progress
            }
          />
        </div>
      </div>
    </>
  )
}

SecurityScoreSection.propTypes = {
  overallScore: PropTypes.string,
}
