import React from 'react'
import {Link} from 'react-router-dom'
import clockIllustration from 'res/illustrations/clock.svg'

export default function EmptyState() {
  return (
    <section className="relative text-center bg-white mt-4 h-100 py-72 mx-auto">
      <div className="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img
          src={clockIllustration}
          alt="Clock"
          className="w-32 h-32 mx-auto"
        />
        <div className="w-full text-onyx mt-10">
          <h1 className="text-xl font-medium">
            Please wait while we are scanning your cloud resources.
          </h1>
          <p className="pb-4">Come back in a few minutes!</p>
          <Link to="/cloud" className="text-blue-500 font-medium underline">
            Head back to the Cloud overview
          </Link>
        </div>
      </div>
    </section>
  )
}
