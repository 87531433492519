import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {useNavigate} from 'react-router-dom'
import SecondaryCheckbox from 'components/partials/inputs/SecondaryCheckbox'
import {statusList} from 'constant'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'

const StepOne = ({
  bulkForm,
  setBulkForm,
  totalSelectedFindings,
  setCurrentStep,
}) => {
  const navigate = useNavigate()

  const checkboxStatusList = [
    {id: 'open', label: statusList.open},
    {id: 'fixed', label: statusList.fixed},
    {id: 'falsePositive', label: statusList.falsePositive},
    {id: 'riskAccepted', label: statusList.riskAccepted},
  ]

  const handleBulkFormChange = e => {
    const {name, value} = e.target

    setBulkForm({...bulkForm, [name]: value})
  }

  return (
    <div id="body">
      <div className="font-medium text-xl bg-astral-50 border-b px-8 py-8 mb-10">
        {i18next.t('management.bulkChange.stepOne.title', {
          qty: totalSelectedFindings,
        })}
      </div>

      <div className="px-8">
        <div className="mb-4 pb-6 border-b">
          <div>
            {i18next.t('management.bulkChange.stepOne.chooseActionLabel')}
          </div>
          <div>
            <SecondaryCheckbox
              id="edit-status"
              name="edit-status"
              value="edit-status"
              text={i18next.t('management.bulkChange.stepOne.changeStatus')}
              onChange={() => {}}
              margin="mr-3 mt-4"
              checked
            />
          </div>
        </div>

        <div>
          <div>{i18next.t('management.bulkChange.stepOne.newStatusLabel')}</div>
          <div className="flex items-stretch w-full">
            {checkboxStatusList.map(item => (
              <SecondaryCheckbox
                key={item.id}
                id={item.id}
                value={item.id}
                name="newStatus"
                text={item.label}
                onChange={handleBulkFormChange}
                margin="mr-3 mt-4"
                checked={bulkForm.newStatus === item.id}
              />
            ))}
          </div>

          {bulkForm.newStatus === 'riskAccepted' && (
            <div className="mt-10">
              <div className="font-medium mb-2">
                {i18next.t('finding.modal.label')}
              </div>
              <div className="text-sm text-gray-600">
                {i18next.t('finding.modal.description')}
              </div>
              <textarea
                id="acceptRiskJustification"
                name="acceptRiskJustification"
                className="border p-2 w-full mt-4"
                value={bulkForm.acceptRiskJustification}
                rows="5"
                maxLength="1000"
                onChange={handleBulkFormChange}
                autoFocus
              />
            </div>
          )}
        </div>

        <div id="footer" className="flex justify-end mt-8">
          <PrimaryButton
            text={i18next.t('management.bulkChange.backBtn')}
            margin="mr-2"
            theme="blue-outline"
            onClick={() => navigate(-1)}
          />
          <PrimaryButton
            text={i18next.t('management.bulkChange.nextStepBtn')}
            onClick={() => setCurrentStep(prev => prev + 1)}
            disabled={Object.keys(bulkForm).length === 0}
          />
        </div>
      </div>
    </div>
  )
}

export default StepOne

StepOne.propTypes = {
  bulkForm: PropTypes.object,
  setBulkForm: PropTypes.func,
  totalSelectedFindings: PropTypes.number,
  setCurrentStep: PropTypes.func,
}
