import i18next from 'i18next'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {getFeatureSubscription} from 'utils'
import {fetchQuestionnaireTemplates, fetchSuppliers} from 'actions'
import Subsection from 'components/partials/headers/Subsection'
import {Page} from 'components/partials/Page'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import SuppliersList from './SuppliersList'
import QuestionnaireTemplatesList from './questionnaire/templates/QuestionnaireTemplatesList'

export default function SuppliersOverview() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userState = useSelector(state => state.user)

  useEffect(() => {
    if (!getFeatureSubscription(userState, 'suppliers').hasAccess)
      navigate('/404', {replace: true})
  }, [])

  useEffect(() => {
    dispatch(fetchSuppliers())
    dispatch(fetchQuestionnaireTemplates())
  }, [])

  return (
    <Page pageTitle={i18next.t('pageTitles.suppliers')}>
      <Subsection
        title={i18next.t('suppliers.registeredSuppliers.title')}
        content={<SuppliersList />}
        button={
          <SecondaryButton
            text={i18next.t('suppliers.addNewBtn')}
            size="sm"
            margin="ml-auto"
            redirect="/suppliers/new"
            plusIcon
          />
        }
        margin="mb-10"
      />
      <Subsection
        title={i18next.t('questionnaires.templates.title')}
        content={<QuestionnaireTemplatesList />}
        button={
          <SecondaryButton
            text={i18next.t('suppliers.addNewBtn')}
            size="sm"
            margin="ml-auto"
            redirect="/questionnaires/templates/new"
            plusIcon
          />
        }
      />
    </Page>
  )
}
