import config from '../config'
import {handler} from './handler'
import {
  fetchBreachedAccountsDone,
  fetchBreachesDone,
  fetchPeopleAccountDone,
  fetchPeopleAccountFindingsDone,
  fetchPeopleFinding,
  fetchPeopleFindingDone,
  fetchPeopleFindingActivity,
  fetchPeopleFindingActivityDone,
  genericError,
  loading,
  success,
} from 'actions'

export function* handleFetchBreaches(action) {
  yield handler({
    handler: _fetchBreaches,
    error: genericError,
    success: fetchBreachesDone,
    action,
  })
}

const _fetchBreaches = () => {
  return fetch(`${config.SERVER_URL}/breaches`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchBreachedAccounts(action) {
  yield handler({
    handler: _fetchBreachedAccounts,
    error: () =>
      genericError({
        loading: {
          breachedAccounts: false,
        },
      }),
    success: fetchBreachedAccountsDone,
    action,
  })
}

const _fetchBreachedAccounts = breachId => {
  return fetch(`${config.SERVER_URL}/breach/${breachId}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchPeopleAccount(action) {
  yield handler({
    handler: _fetchPeopleAccount,
    success: fetchPeopleAccountDone,
    error: genericError,
    action,
  })
}

const _fetchPeopleAccount = accountId => {
  return fetch(`${config.SERVER_URL}/people/accounts/${accountId}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchPeopleAccountFindings(action) {
  yield handler({
    handler: _fetchPeopleAccountFindings,
    success: fetchPeopleAccountFindingsDone,
    error: genericError,
    action,
  })
}

const _fetchPeopleAccountFindings = emailId => {
  return fetch(`${config.SERVER_URL}/people/accounts/${emailId}/findings`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchPeopleFinding(action) {
  yield handler({
    handler: _fetchPeopleFinding,
    error: genericError,
    success: fetchPeopleFindingDone,
    action,
  })
}

const _fetchPeopleFinding = findingId => {
  return fetch(`${config.SERVER_URL}/people/findings/${findingId}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
}

const _updatePeopleFindingStatus = change => {
  return fetch(
    `${config.SERVER_URL}/people/findings/${change.findingId}/${change.status}`,
    {
      method: 'PATCH',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(change),
    }
  )
}

export function* handleUpdatePeopleFindingStatus(action) {
  const {updateStatusMessage, findingId} = action.payload

  yield handler({
    handler: _updatePeopleFindingStatus,
    success: [
      () =>
        success({
          message: updateStatusMessage,
        }),
      () => loading({updatePeopleFinding: false}),
      () => fetchPeopleFinding(findingId),
      () => fetchPeopleFindingActivity(findingId),
    ],
    error: genericError,
    action,
  })
}

const _fetchPeopleFindingActivity = findingId => {
  const url = `${config.SERVER_URL}/people/findings/${findingId}/activity`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchPeopleFindingActivity(action) {
  yield handler({
    handler: _fetchPeopleFindingActivity,
    success: fetchPeopleFindingActivityDone,
    action,
  })
}
