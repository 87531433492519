import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
import {messages} from './languages'

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    defaultNS: ['translations'],
    ns: ['translations'],
    fallbackLng: 'en',
    resources: messages,
  })

export {i18next}
