import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import {useSelector, useDispatch, batch} from 'react-redux'
import {useParams, useNavigate, Link} from 'react-router-dom'

import {
  clearAzureServicesData,
  fetchAzureIntegrations,
  fetchAzureStats,
  fetchAzureMostCritical,
} from 'actions'
import config from 'config'

import IntegrationStats from '../IntegrationStats'
import WhatsImportant from '../WhatsImportant'
import TableWrapper from 'components/partials/tables/TableWrapper'
import AssetsTable from './AssetsTable'
import EmptyState from '../EmptyState'
import Spinner from 'components/partials/Spinner'
import {Page} from 'components/partials/Page'
import PlanUtils from 'utils/plan'

export default function AzureDashboard() {
  const azureIntegrationsState = useSelector(state => state.azureIntegrations)
  const azureStatsState = useSelector(state => state.azureStats)
  const azureMostCriticalState = useSelector(state => state.azureMostCritical)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const params = useParams()
  const planUtils = PlanUtils()

  const [currentIntegration, setCurrentIntegration] = useState({})
  const [findingsByResource, setFindingsByResource] = useState([])
  const [fetchingFeed, setFetchingFeed] = useState(true)

  const [visibility, setVisibility] = useState('')

  const hasAtLeastOneResource = () => {
    return (
      azureStatsState && Object.values(azureStatsState).find(value => !!value)
    )
  }

  const resourcesNames = {
    blobContainers: 'Blob containers',
    disks: 'Disks',
    mysqlServers: 'MySQL servers',
    postgresqlServers: 'PostgreSQL servers',
    sqlDatabases: 'SQL databases',
    sqlServers: 'SQL servers',
    storageAccounts: 'Storage accounts',
    vms: 'Virtual machines',
  }

  const mapFindingsByResource = azureFindingsByResource => {
    const resources = Object.entries(azureFindingsByResource).map(entry => {
      const [resourceName, {findings}] = entry
      return {resourceName, findings, name: resourcesNames[resourceName]}
    })

    return resources.sort((a, b) => a.name.localeCompare(b.name))
  }

  const assetsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            accessor: 'name',
          },
          {
            accessor: 'findings',
          },
          {
            accessor: 'chevron',
          },
        ],
      },
    ],
    []
  )

  useEffect(() => {
    if (azureIntegrationsState) {
      const account = azureIntegrationsState.find(
        integration => integration.tenantId === params.id
      )
      setCurrentIntegration(account)
    }
  }, [azureIntegrationsState])

  const configsAreOutdated =
    currentIntegration &&
    currentIntegration.version < Math.floor(config.azureIntegrationVersion)

  useEffect(() => {
    if (azureMostCriticalState && fetchingFeed) {
      setFetchingFeed(false)
    }
  }, [azureMostCriticalState])

  useEffect(() => {
    if (currentIntegration && currentIntegration.tenantId) {
      batch(() => {
        dispatch(fetchAzureStats(currentIntegration.tenantId))

        if (!planUtils.hasPlanRestriction()) {
          dispatch(fetchAzureMostCritical(currentIntegration.tenantId))
        }
      })
    }
  }, [currentIntegration])

  useEffect(() => {
    batch(() => {
      dispatch(clearAzureServicesData())
      dispatch(fetchAzureIntegrations())
    })
  }, [])

  useEffect(() => {
    if (
      !azureStatsState ||
      !azureStatsState[currentIntegration.tenantId] ||
      !azureStatsState[currentIntegration.tenantId].services
    )
      return

    const arr = mapFindingsByResource(
      azureStatsState[currentIntegration.tenantId].services
    )

    setFindingsByResource(arr)
  }, [azureStatsState, currentIntegration])

  return (
    <Page pageTitle={i18next.t('pageTitles.cloud')}>
      {
        // Has active integration but is outdated
        currentIntegration &&
        currentIntegration.status === 'active' &&
        configsAreOutdated ? (
          <div className={visibility}>
            <div
              className="bg-purple-100 border border-purple-400 text-purple-700 px-4 py-3 mb-6 rounded relative text-xs"
              role="alert">
              <Link to="/cloud/azure/integration">
                <strong className="font-bold">
                  {i18next.t('cloud.updateAvailable')}
                </strong>
                &nbsp;
                <span className="block sm:inline">
                  {i18next.t('cloud.followOurGuide')}
                </span>
              </Link>
              <span
                className="absolute top-0 bottom-0 right-0 p-3"
                onClick={() => {
                  setVisibility('hidden')
                }}>
                <svg
                  className="fill-current h-5 w-5 text-purple-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          </div>
        ) : (
          <></>
        )
      }

      {!currentIntegration ||
      !currentIntegration.tenantId ||
      !azureStatsState ? (
        <Spinner />
      ) : (
        <>
          {currentIntegration &&
          currentIntegration.status === 'active' &&
          hasAtLeastOneResource(currentIntegration.tenantId) ? (
            <>
              {azureStatsState &&
                azureStatsState[currentIntegration.tenantId] && (
                  <IntegrationStats
                    provider="azure"
                    integration={currentIntegration}
                    nAssets={
                      azureStatsState[currentIntegration.tenantId]
                        .totalResources || 0
                    }
                    nFindings={
                      azureStatsState[currentIntegration.tenantId].findings || 0
                    }
                    score={
                      azureStatsState[currentIntegration.tenantId].score
                        .grade || '-'
                    }
                  />
                )}
              <WhatsImportant
                provider="azure"
                feed={azureMostCriticalState || []}
                cloudAccount={currentIntegration.tenantId}
                fetching={fetchingFeed}
              />
              <TableWrapper
                title={i18next.t('misc.assets')}
                loaded={findingsByResource}
                table={
                  <AssetsTable
                    tenantId={currentIntegration.tenantId}
                    data={findingsByResource}
                    columns={assetsColumns}
                    pageNumber={0}
                  />
                }
              />
            </>
          ) : (
            <>
              <div>
                <div className="bg-white rounded-sm pt-2 pl-2 pb-1 relative">
                  <i className="icofont-clouds ml-2 mt-2 text-2xl"></i>
                  <span className="text-xs absolute top-0 mt-3 mx-4 uppercase">
                    Microsoft Azure
                  </span>
                  <button
                    className="icofont-gears absolute outline-none right-0 mr-4 text-2xl"
                    onClick={() => {
                      navigate('/cloud/azure/integration')
                    }}
                  />
                </div>
              </div>
              <EmptyState />
            </>
          )}
        </>
      )}
    </Page>
  )
}
