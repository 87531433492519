import React from 'react'
import PropTypes from 'prop-types'

export default function ArrowIcon({width, height, color, direction}) {
  return (
    <div
      className={`${direction === 'left' && 'transform rotate-180'} ${
        direction === 'down' && 'transform rotate-90'
      }`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          className="arrow-icon"
          d="M0 9L12.17 9L6.58 14.59L8 16L16 8L8 0L6.59 1.41L12.17 7L0 7L0 9Z"
          fill={color}
        />
      </svg>
    </div>
  )
}

ArrowIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right', 'down']),
}
