import config from '../config'
import {handler} from './handler'
import {
  fetchActivity,
  fetchActivityDone,
  fetchEvidenceDone,
  fetchFeedDone,
  fetchIssuesDone,
  fetchTotalVsClosedIssuesDone,
  genericError,
} from 'actions'

const _fetchIssues = query => {
  let url

  if (query.serviceId) {
    url = `${config.SERVER_URL}/service/${query.serviceId}/issues`
  } else if (query.hostId) {
    url = `${config.SERVER_URL}/host/${query.hostId}/issues`
  } else if (query.type) {
    url = `${config.SERVER_URL}/issues/${query.type}`
  } else {
    url = `${config.SERVER_URL}/issues`
  }

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
}

export function* handleFetchIssues(action) {
  yield handler({
    handler: _fetchIssues,
    error: genericError,
    success: fetchIssuesDone,
    action,
  })
}

const _fetchTotalVsClosedIssues = () => {
  return fetch(`${config.SERVER_URL}/issues/chart`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
}

export function* handleFetchTotalVsClosedIssues(action) {
  yield handler({
    handler: _fetchTotalVsClosedIssues,
    success: fetchTotalVsClosedIssuesDone,
    error: genericError,
    action,
  })
}

const _updateIssueStatus = change => {
  return fetch(
    `${config.SERVER_URL}/issue/${change.issueId}/${change.status}`,
    {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
    }
  )
}

export function* handleUpdateIssueStatus(action) {
  yield handler({
    handler: _updateIssueStatus,
    success: () => fetchActivity(action.payload.issueId),
    error: genericError,
    action,
  })
}

export function* handleFetchFeed(action) {
  yield handler({
    handler: fetchFeed,
    success: fetchFeedDone,
    action,
  })
}

const fetchFeed = options => {
  let url
  if (options.type) url = `${config.SERVER_URL}/issues/feed/${options.type}`
  else url = `${config.SERVER_URL}/issues/feed`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchActivity(action) {
  yield handler({
    handler: _fetchActivity,
    success: fetchActivityDone,
    action,
  })
}

const _fetchActivity = issueId => {
  const url = `${config.SERVER_URL}/issue/${issueId}/activity`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchEvidence(action) {
  yield handler({
    handler: _fetchEvidence,
    success: fetchEvidenceDone,
    action,
  })
}

const _fetchEvidence = issueId => {
  const url = `${config.SERVER_URL}/issue/${issueId}/evidence`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}
