import {getRequestParams} from 'utils/pagination'
import config from '../config'
import {handler} from './handler'
import {
  fetchFindingsByNetworkServiceDone,
  fetchHostsDone,
  fetchHostsError,
  fetchNetworkFindingActivity,
  fetchNetworkFindingActivityDone,
  fetchNetworkFindingDone,
  fetchNetworkImportantFindingsDone,
  fetchNetworkStatsDone,
  fetchServiceDone,
  fetchServicesDone,
  updateNetworkFindingDone,
  fetchHostsByNetworkServiceDone,
  success,
  fetchNetworkFinding,
  fetchHostsByAsOrgsDone,
  fetchHostsByCountryDone,
  fetchServiceDetailsDone,
  genericError,
  fetchServiceDetailsFindingsDone,
} from 'actions'

const _fetchHosts = params => {
  const url = new URL(`${config.SERVER_URL}/network/hosts`)
  url.search = new URLSearchParams(getRequestParams(params)).toString()

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchHosts(action) {
  yield handler({
    handler: _fetchHosts,
    error: fetchHostsError,
    success: fetchHostsDone,
    action,
  })
}

export function* handleFetchHostsByAsOrgs(action) {
  yield handler({
    handler: _fetchHostsByAsOrgs,
    success: fetchHostsByAsOrgsDone,
    action,
  })
}

const _fetchHostsByAsOrgs = () => {
  return fetch(`${config.SERVER_URL}/network/asorgs`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchHostsByCountry(action) {
  yield handler({
    handler: _fetchHostsByCountry,
    success: fetchHostsByCountryDone,
    action,
  })
}

const _fetchHostsByCountry = () => {
  return fetch(`${config.SERVER_URL}/network/hosts/count/country`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchNetworkFinding(action) {
  yield handler({
    handler: _fetchNetworkFinding,
    error: genericError,
    success: fetchNetworkFindingDone,
    action,
  })
}

const _fetchNetworkFinding = findingId => {
  return fetch(`${config.SERVER_URL}/network/finding/${findingId}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchNetworkImportantFindings(action) {
  yield handler({
    handler: _fetchNetworkImportantFindings,
    success: fetchNetworkImportantFindingsDone,
    action,
  })
}

const _fetchNetworkImportantFindings = () => {
  return fetch(`${config.SERVER_URL}/network/important`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchNetworkFindingActivity(action) {
  yield handler({
    handler: _fetchNetworkFindingActivity,
    success: fetchNetworkFindingActivityDone,
    action,
  })
}

const _fetchNetworkFindingActivity = findingId => {
  return fetch(`${config.SERVER_URL}/network/finding/${findingId}/activity`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateNetworkFinding(action) {
  const {updateStatusMessage, findingId} = action.payload

  yield handler({
    handler: _updateNetworkFinding,
    success: [
      updateNetworkFindingDone,
      () =>
        success({
          message: updateStatusMessage,
        }),
      () => fetchNetworkFinding(findingId),
      () => fetchNetworkFindingActivity(findingId),
    ],
    error: genericError,
    action,
  })
}

const _updateNetworkFinding = body => {
  return fetch(`${config.SERVER_URL}/network/finding/${body.findingId}`, {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'PATCH',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchFindingsByNetworkService(action) {
  yield handler({
    handler: _fetchFindingsByNetworkService,
    success: fetchFindingsByNetworkServiceDone,
    action,
  })
}

export function* handleFetchNetworkStats(action) {
  yield handler({
    handler: _fetchNetworkStats,
    success: [fetchNetworkStatsDone],
    action,
  })
}

const _fetchNetworkStats = () => {
  return fetch(`${config.SERVER_URL}/network/stats`, {
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'GET',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchService(action) {
  yield handler({
    handler: _fetchService,
    success: fetchServiceDone,
    action,
  })
}

const _fetchService = protocol => {
  return fetch(`${config.SERVER_URL}/network/service/${protocol}/hosts`, {
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'GET',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchServiceDetails(action) {
  yield handler({
    action,
    error: genericError,
    handler: _fetchServiceDetails,
    success: fetchServiceDetailsDone,
  })
}

const _fetchServiceDetails = serviceId => {
  return fetch(`${config.SERVER_URL}/network/services/${serviceId}`, {
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'GET',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchServiceDetailsFindings(action) {
  yield handler({
    action,
    error: genericError,
    handler: _fetchServiceDetailsFindings,
    success: fetchServiceDetailsFindingsDone,
  })
}

const _fetchServiceDetailsFindings = serviceId => {
  return fetch(`${config.SERVER_URL}/network/services/${serviceId}/findings`, {
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'GET',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchServices(action) {
  yield handler({
    handler: _fetchServices,
    success: fetchServicesDone,
    action,
  })
}

const _fetchServices = () => {
  return fetch(`${config.SERVER_URL}/network/services`, {
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'GET',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchHostsByNetworkService(action) {
  yield handler({
    handler: _fetchHostsByNetworkService,
    success: fetchHostsByNetworkServiceDone,
    action,
  })
}

const _fetchHostsByNetworkService = service => {
  return fetch(
    `${config.SERVER_URL}/network/services/${service.protocol}/ports/${service.port}/hosts`,
    {
      credentials: 'include',
      headers: {'Content-Type': 'application/json'},
      method: 'GET',
    }
  ).catch(err => {
    console.trace(err)
  })
}

const _fetchFindingsByNetworkService = service => {
  return fetch(
    `${config.SERVER_URL}/network/services/${service.protocol}/ports/${service.port}/findings`,
    {
      credentials: 'include',
      headers: {'Content-Type': 'application/json'},
      method: 'GET',
    }
  ).catch(err => {
    console.trace(err)
  })
}
