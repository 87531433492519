import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Trans} from 'react-i18next'
import i18next from 'i18next'

import {loading, uploadQuestionnaireTemplate} from 'actions'
import {Page} from 'components/partials/Page'
import BackButton from 'components/partials/buttons/BackButton'
import Subsection from 'components/partials/headers/Subsection'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import PrimaryDropFile from 'components/partials/inputs/PrimaryDropFile'
import csvTemplateIllustration from 'res/illustrations/csv-template-illustration.png'
import infoIcon from 'res/icons/infoIcon.svg'

import csvQuestionnaireTemplateExamplePtBr from 'res/questionnaires/csv-questionnaire-template-example-pt-br.csv'
import csvQuestionnaireTemplateExampleEn from 'res/questionnaires/csv-questionnaire-template-example-en.csv'
import {isLoading} from 'utils'

export default function QuestionnaireTemplateNew() {
  return (
    <Page pageTitle={i18next.t('pageTitles.questionnaires')}>
      <BackButton fallbackLink="/suppliers" />
      <div className="mt-4">
        <Subsection
          title={i18next.t('questionnaires.templates.new.title')}
          content={<QuestionnaireTemplateForm />}
        />
      </div>
    </Page>
  )
}

const QuestionnaireTemplateForm = () => {
  const initialFormState = {
    templateName: '',
    templateDescription: '',
  }

  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)

  const [form, setForm] = useState(initialFormState)
  const [file, setFile] = useState([])

  const handleInputChange = e => {
    const {name} = e.target
    setForm({...form, [name]: e.target.value})
  }

  const handleSubmit = e => {
    e.preventDefault()

    const formData = new FormData()

    formData.append('_csrf', csrfState)
    formData.append('name', form.templateName)
    formData.append('description', form.templateDescription)
    formData.append('type', 'template')
    formData.append('questions', file[0])

    dispatch(loading({uploadQuestionnaireTemplate: true}))
    dispatch(uploadQuestionnaireTemplate(formData))
  }

  const exampleFile =
    i18next.language === 'pt-br'
      ? {
          download: {
            fileName: 'csv-questionnaire-example-pt-br.csv',
            href: csvQuestionnaireTemplateExamplePtBr,
          },
          online: {
            href: 'https://docs.google.com/spreadsheets/d/1tHibnhGPnGqs19bc4zXHnOXk7c2jLc700Rcq-eIteBk',
          },
        }
      : {
          download: {
            fileName: 'csv-questionnaire-example-en.csv',
            href: csvQuestionnaireTemplateExampleEn,
          },
          online: {
            href: 'https://docs.google.com/spreadsheets/d/1EWM89w6n-VSKDzOPO0GYU3JFHTvu5cPcsuIpgliYmEg',
          },
        }

  return (
    <>
      <form className="xl:w-10/12 py-4 px-6" onSubmit={handleSubmit}>
        <div id="inputs-container">
          <div className="md:flex border-b border-sideral-50 pt-8 pb-6">
            <div className="basis-1/3 mr-8">
              <p className="text-sm text-sideral-900">
                {i18next.t('questionnaires.templates.new.templateName')}*
              </p>
              <p className="text-light text-xs text-sideral-400 pt-1">
                {i18next.t('questionnaires.templates.new.templateNameHelper')}
              </p>
            </div>
            <div className="w-full sm:basis-2/3">
              <PrimaryInput
                name="templateName"
                type="text"
                value={form.templateName}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="md:flex border-b border-sideral-50 pt-8 pb-6">
            <div className="basis-1/3 mr-8">
              <p className="text-sm text-sideral-900">
                {i18next.t('questionnaires.templates.new.templateDescription')}
              </p>
              <p className="text-light text-xs text-sideral-400 pt-1">
                {i18next.t(
                  'questionnaires.templates.new.templateDescriptionHelper'
                )}
              </p>
            </div>
            <div className="w-full sm:basis-2/3">
              <textarea
                rows="3"
                name="templateDescription"
                onChange={handleInputChange}
                value={form.templateDescription}
                className="w-full text-sm border border-sideral-100 rounded-md mt-2 p-2"
              />
            </div>
          </div>
        </div>

        <div id="about-file-container" className="pt-8 pb-6">
          <div>
            <p className="text-sm text-sideral-900">
              {i18next.t('questionnaires.new.learnAboutFile.title')}
            </p>
          </div>
          <div className="xl:flex mt-6">
            <div className="xl:basis-4/6 xl:mr-8">
              <ul className="list-inside list-decimal leading-6">
                <li className="text-light text-sideral-700 text-sm mb-3">
                  <span className="mr-2">
                    <Trans i18nKey="questionnaires.templates.new.learnAboutFile.steps.one" />
                  </span>
                </li>
                <li className="text-light text-sideral-700 text-sm mb-3">
                  <Trans i18nKey="questionnaires.templates.new.learnAboutFile.steps.two" />
                </li>
                <li className="text-light text-sideral-700 text-sm mb-3">
                  <Trans i18nKey="questionnaires.templates.new.learnAboutFile.steps.three" />
                </li>

                <div className="inline-block bg-astral-100 border border-astral-300 mt-1 px-4 py-3 rounded-md text-xs">
                  <div className="flex font-medium -ml-1">
                    <img src={infoIcon} alt="Info" className="h-5 w-5 mr-2" />
                    {i18next.t(
                      'questionnaires.new.learnAboutFile.sampleFile.doubts'
                    )}
                  </div>

                  <ul className="list-disc list-inside leading-5">
                    <li>
                      <Trans
                        i18nKey="questionnaires.new.learnAboutFile.sampleFile.download"
                        components={{
                          a: (
                            <a
                              href={exampleFile.download.href}
                              download={exampleFile.download.fileName}
                              className="text-sky-900 hover:text-sky-800 hover:cursor-pointer underline"
                              rel="noreferrer"
                            />
                          ),
                        }}
                      />
                    </li>
                    <li>
                      <Trans
                        i18nKey="questionnaires.new.learnAboutFile.sampleFile.onlineTemplate"
                        components={{
                          a: (
                            <a
                              href={exampleFile.online.href}
                              className="text-sky-900 hover:text-sky-800 hover:cursor-pointer underline"
                              target="_blank"
                              rel="noreferrer"
                            />
                          ),
                        }}
                      />
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
            <div className="xl:basis-2/4 mt-8 xl:mt-0">
              <img
                src={csvTemplateIllustration}
                alt="CSV Illustration"
                className="m-auto"
              />
            </div>
          </div>

          <PrimaryDropFile
            file={file}
            setFile={setFile}
            acceptedFileType={{'text/csv': ['.csv']}}
            formatName="CSV"
            dragAndDropError={i18next.t(
              'questionnaires.new.messages.dragAndDropError'
            )}
            footerText={i18next.t('questionnaires.new.maxFileSize')}
          />
        </div>

        <div className="flex justify-end mt-2 mb-4">
          <PrimaryButton
            text={i18next.t('questionnaires.new.onSubmitBtn')}
            type="submit"
            disabled={!file.length}
            loading={isLoading(loadingState.uploadQuestionnaireTemplate, false)}
          />
        </div>
      </form>
    </>
  )
}
