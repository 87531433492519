import i18next from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'

import AlternateActionButton from 'components/partials/buttons/AlternateActionButton'
import IntegrationHeader from './IntegrationHeader'
import StatsItem from './StatsItem'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'

import images from 'res'
import {sky900} from 'constant'

export default function UnknownIntegrationError({
  styles,
  integration,
  undoIntegration,
  retryIntegration,
  provider,
  providersProperties,
  icon,
  hideIntegrationOptions = false,
}) {
  return (
    <div className={styles.providerContainer + ' group'}>
      <IntegrationHeader
        hideIntegrationOptions={hideIntegrationOptions}
        provider={provider}
        providersProperties={providersProperties}
        integration={integration}
        icon={icon}
        styles={styles}
        rightSideContent={
          !hideIntegrationOptions && (
            <div className="flex items-center justify-center">
              {provider === 'gcp' && (
                <SecondaryButton
                  icon={
                    <images.retryIcon width="14" height="14" color={sky900} />
                  }
                  onClick={() => retryIntegration(integration)}
                  text={i18next.t('buttons.retry')}
                  classContainer="flex"
                  size="sm"
                  margin="mr-4"
                />
              )}
              <AlternateActionButton
                text={i18next.t('buttons.removeAndTry')}
                trashIcon
                onClick={() => undoIntegration(integration, provider)}
                margin="ml-auto"
                size="sm"
              />
            </div>
          )
        }
      />

      <div className={`${styles.providerBody} py-10`}>
        <div className="text-sm">
          <p className="text-mars-700">
            {i18next.t('cloud.failedIntegration')}
          </p>
          <p className="font-light">
            {i18next.t('warnings.contactUs')} support@unxpose.com
          </p>
        </div>
        <StatsItem styles={styles} />
      </div>
    </div>
  )
}

UnknownIntegrationError.propTypes = {
  integration: PropTypes.object,
  provider: PropTypes.string,
  providersProperties: PropTypes.object,
  styles: PropTypes.object,
  getIntegrationId: PropTypes.func,
  undoIntegration: PropTypes.func,
  retryIntegration: PropTypes.func,
  icon: PropTypes.string,
  hideIntegrationOptions: PropTypes.bool,
}
