import React, {useState} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import SecondaryButton from './buttons/SecondaryButton'
import images from 'res'
import {sky900} from 'constant'

export default function CopyToClipboard({content, classes, onClick}) {
  const hiddenMessage = 'translate-y-full'
  const visibleMessage = 'translate-y-0'

  const [successMessage, setSuccessMessage] = useState(hiddenMessage)

  const showClipboardSuccess = () => {
    if (successMessage === hiddenMessage) {
      setSuccessMessage(visibleMessage)

      setTimeout(() => {
        setSuccessMessage(hiddenMessage)
      }, 1500)
    }
  }

  return (
    <>
      <div className={classes}>
        <div className="flex justify-end bg-astral-100 px-4 py-2 border rounded-t-md">
          <SecondaryButton
            text={i18next.t('buttons.copy')}
            icon={<images.copyIcon width="18" height="18" color={sky900} />}
            onClick={() => {
              onClick()
              showClipboardSuccess()
            }}
            size="sm"
            classContainer="inline-flex"
          />
        </div>
        <div
          className={
            'dm-mono text-xs border-x border-b rounded-b-md bg-astral-50 overflow-auto p-4 max-h-64'
          }>
          {content}
        </div>
      </div>

      <div
        className={`${successMessage} z-50 text-center font-medium text-white bg-green-600 bottom-0 left-0 fixed py-2 transform duration-300 ease-out block w-full shadow-md`}>
        {i18next.t('misc.copiedToClipboard')}
      </div>
    </>
  )
}

CopyToClipboard.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classes: PropTypes.string,
  onClick: PropTypes.func,
}
