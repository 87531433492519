import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import Moment from 'react-moment'
import {useNavigate, Link} from 'react-router-dom'

import images from 'res'
import {sideral700, whatsImportantFeedLimit} from 'constant'
import {viewFindingsByCategory} from 'utils'
import PlanUtils from 'utils/plan'

import FindingIcon from 'res/icons/findingIcon'

import GenericEmptyState from 'components/partials/GenericEmptyState'
import PlaceholderContent from 'components/partials/PlaceholderContent'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import Spinner from 'components/partials/Spinner'

export default function WhatsImportant({feed, fetching}) {
  const navigate = useNavigate()

  const planUtils = PlanUtils()

  return (
    <div className="w-full bg-white border rounded-md border-sky-900 my-8">
      <div className="dm-mono text-lg bg-sky-900 rounded-t text-white p-4">
        {i18next.t('titles.whatsImportant')}
      </div>
      <div className="bg-white rounded-b-md">
        {planUtils.hasPlanRestriction() ? (
          <PlaceholderContent sectionId="whatsImportant" />
        ) : !fetching ? (
          <div className="p-6 pt-0">
            {feed.slice(0, whatsImportantFeedLimit).map((finding, index) => {
              return (
                <div key={index}>
                  <div className="rounded-md border border-sideral-50 text-sm mt-6">
                    <div className="flex flex-wrap items-start leading-none w-full p-4">
                      <figure className="hidden lg:flex">
                        <FindingIcon height="20" width="20" />
                      </figure>
                      <div className="flex flex-wrap">
                        <Link
                          className="w-full text-sky-900 pl-2"
                          to={`/dns/finding/${finding.id}`}>
                          {finding.title}
                        </Link>
                        <span className="w-full text-astral-900 text-xs font-light pl-2 pt-2">
                          {finding.hostname}
                        </span>
                      </div>
                    </div>
                    <div className="flex leading-6 font-light text-sideral-200 text-sm border-t border-sideral-50 p-4">
                      <div className="flex">
                        <images.ClockIcon width="20" color={sideral700} />
                        <Moment
                          date={finding.checkedAt}
                          fromNow
                          className="ml-2"
                        />
                      </div>
                      <SecondaryButton
                        text={i18next.t('buttons.details')}
                        direction="right"
                        size="sm"
                        onClick={() => {
                          navigate(`/dns/finding/${finding.id}`)
                        }}
                        margin="ml-auto"
                      />
                    </div>
                  </div>
                </div>
              )
            })}
            {feed.length > whatsImportantFeedLimit && (
              <SecondaryButton
                text={i18next.t('buttons.viewAll')}
                margin="mt-4 text-center"
                size="sm"
                direction="right"
                redirect={viewFindingsByCategory('dns', '&sort=severity:desc')}
              />
            )}
            {!feed.length && (
              <GenericEmptyState
                icon={<FindingIcon width="55" color="#90A4AE" />}
                body={i18next.t('dns.noImportantFindings')}
                buttonText={i18next.t('buttons.viewAllFindings')}
                linkPath={viewFindingsByCategory('dns')}
                margin="mt-6"
              />
            )}
          </div>
        ) : (
          <div className="pt-4">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
}

WhatsImportant.propTypes = {
  feed: PropTypes.array,
  fetching: PropTypes.bool,
}
