import React from 'react'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import {tooltipStyle} from 'constant'
import {getAwsIntegrationUpdatePath} from './cloudUtils'

import SecondaryButton from 'components/partials/buttons/SecondaryButton'

export const ProviderPermissionAlertBtn = ({
  provider,
  integration,
  tooltipClass = 'top-0 left-0 mt-6',
  isTooltipRelativeToBtn = true,
}) => {
  if (!integration) {
    return <></>
  }

  let message, redirectPath

  switch (provider) {
    case 'aws':
      message = i18next.t('errors.checkPermissionsAws')
      redirectPath = getAwsIntegrationUpdatePath(
        integration.method,
        integration.externalId
      )
      break
    case 'gcp':
      message = i18next.t('errors.checkPermissionsGcp')
      redirectPath = '/cloud/gcp/integration/manual'
      break
  }

  return (
    <SecondaryButton
      text={i18next.t('buttons.checkPermissions')}
      iconClass="icofont-warning"
      color="text-mars-700"
      size="sm"
      margin="mr-5 z-50"
      tooltip={
        <span
          className={`${tooltipStyle.danger} ${tooltipClass} absolute w-[200px]`}>
          {message}
        </span>
      }
      isTooltipRelativeToBtn={isTooltipRelativeToBtn}
      redirect={redirectPath}
    />
  )
}

ProviderPermissionAlertBtn.propTypes = {
  provider: PropTypes.string,
  integration: PropTypes.object,
  method: PropTypes.string,
  externalId: PropTypes.string,
  tooltipClass: PropTypes.string,
  isTooltipRelativeToBtn: PropTypes.bool,
}
