import React from 'react'
import PropTypes from 'prop-types'
import {alertStyle, tooltipStyle} from 'constant'

export default function Badge({style, label, icon, info}) {
  let styleClasses

  switch (style) {
    case 'success':
      styleClasses = alertStyle.success
      break

    case 'error':
      styleClasses = alertStyle.error
      break

    case 'warning':
      styleClasses = alertStyle.warning
      break

    case 'dark':
      styleClasses = alertStyle.dark
      break

    default:
      styleClasses = alertStyle.info
  }

  return (
    <div
      className={`tooltip inline-flex items-center rounded-md px-2 h-[1.7rem] text-sm ${styleClasses}`}>
      {icon && <div className="w-5 mr-1">{icon}</div>}
      {label}
      {info && (
        <div className={`${tooltipStyle.default} mt-[3.6rem] -ml-2 text-xs`}>
          {info}
        </div>
      )}
    </div>
  )
}

Badge.propTypes = {
  style: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.object,
  info: PropTypes.string,
}
