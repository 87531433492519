import PropTypes from 'prop-types'
import React from 'react'
import i18next from 'i18next'
import {useDispatch, useSelector} from 'react-redux'
import images from 'res'

import AlternateActionButton from 'components/partials/buttons/AlternateActionButton'
import IntegrationHeader from './IntegrationHeader'
import StatsItem from './StatsItem'
import UnknownIntegrationError from './UnknownIntegrationError'

import {
  stopAwsIntegration,
  stopAzureIntegration,
  stopGcpIntegration,
  retryAwsIntegration,
  retryGcpIntegration,
} from 'actions'

import {cloudIntegrationErrors, sky900} from 'constant'
import {mapAzureErrors, mapGcpErrors} from './cloudUtils'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'

export default function IntegrationFailed({
  provider,
  providersProperties,
  integration,
  icon,
  styles,
  hideIntegrationOptions = false,
}) {
  const csrfState = useSelector(state => state.csrf)

  const dispatch = useDispatch()

  function groupServicesByCodeErr(errorsEntries) {
    return errorsEntries.reduce((acc, curr) => {
      const [serviceName, {code}] = curr
      if (!acc[code]) acc[code] = []

      acc[code].push(serviceName)
      return acc
    }, {})
  }

  const getAwsErrorTemplate = (provider, integration) => {
    return (
      <div className={styles.providerContainer + ' group'}>
        <IntegrationHeader
          hideIntegrationOptions={hideIntegrationOptions}
          provider={provider}
          providersProperties={providersProperties}
          integration={integration}
          icon={icon}
          styles={styles}
          rightSideContent={
            !hideIntegrationOptions && (
              <div className="flex items-center justify-center">
                <>
                  <SecondaryButton
                    icon={
                      <images.retryIcon width="14" height="14" color={sky900} />
                    }
                    onClick={() => retryIntegration(integration)}
                    text={i18next.t('buttons.retry')}
                    classContainer="flex"
                    size="sm"
                    margin="mr-4"
                  />
                  <AlternateActionButton
                    text={i18next.t('buttons.removeAndTry')}
                    trashIcon
                    onClick={() => undoIntegration(integration, provider)}
                    margin="ml-auto"
                    size="sm"
                  />
                </>
              </div>
            )
          }
        />

        <div className={styles.providerBody}>
          <div className="text-sm mr-2">
            <p className="text-mars-700">
              {i18next.t('cloud.failedIntegration')}
            </p>
            <div>
              <span className="block font-light">
                {i18next.t('cloud.aws.errors.accessDenied.explanation')}
              </span>
            </div>
            <ul className="list-disc">
              <li className="ml-8">
                {i18next.t('cloud.aws.errors.accessDenied.listItem1')}
              </li>
              <li className="ml-8">
                {i18next.t('cloud.aws.errors.accessDenied.listItem2')}
              </li>
              <li className="ml-8">
                {i18next.t('cloud.aws.errors.accessDenied.listItem3', {
                  externalId: integration.externalId,
                })}
              </li>
            </ul>
          </div>
          <StatsItem styles={styles} />
        </div>
      </div>
    )
  }

  const getErrorTemplate = (provider, errorsEntries) => {
    const servicesByError = groupServicesByCodeErr(errorsEntries)

    return (
      <div className={styles.providerContainer + ' group'}>
        <IntegrationHeader
          provider={provider}
          providersProperties={providersProperties}
          integration={integration}
          icon={icon}
          styles={styles}
          rightSideContent={
            !hideIntegrationOptions && (
              <div className="flex items-center justify-center">
                {provider === 'gcp' && (
                  <SecondaryButton
                    icon={
                      <images.retryIcon width="14" height="14" color={sky900} />
                    }
                    onClick={() => retryIntegration(integration)}
                    text={i18next.t('buttons.retry')}
                    classContainer="flex"
                    size="sm"
                    margin="mr-4"
                  />
                )}
                <AlternateActionButton
                  text={i18next.t('buttons.removeAndTry')}
                  trashIcon
                  onClick={() => undoIntegration(integration, provider)}
                  margin="ml-auto"
                  size="sm"
                />
              </div>
            )
          }
        />
        <div className={styles.providerBody}>
          <div className="text-sm mr-2">
            <p className="text-mars-700">
              {i18next.t('cloud.failedIntegration')}
            </p>
            {Object.entries(servicesByError).map((error, index) => {
              const [code, services] = error
              return (
                <div key={`${code} ${index}`} className={index && 'mt-4'}>
                  <span className="block font-light">
                    {cloudIntegrationErrors[provider].messages[code]}
                  </span>
                  {provider !== 'azure' ? (
                    <ul className="list-disc">
                      {services.map((service, index) => {
                        if (
                          cloudIntegrationErrors[provider].serviceNames[service]
                        )
                          return (
                            <li
                              key={`service ${index}`}
                              className="font-medium ml-8">
                              {
                                cloudIntegrationErrors[provider].serviceNames[
                                  service
                                ]
                              }
                            </li>
                          )
                      })}
                    </ul>
                  ) : (
                    <></>
                  )}
                </div>
              )
            })}
          </div>
          <StatsItem styles={styles} />
        </div>
      </div>
    )
  }

  const getErrorMessages = (integration, provider) => {
    if (provider === 'aws') return getAwsErrorTemplate(provider, integration)

    if (!integration.logs) {
      return (
        <UnknownIntegrationError
          hideIntegrationOptions={hideIntegrationOptions}
          integration={integration}
          undoIntegration={undoIntegration}
          retryIntegration={retryIntegration}
          provider={provider}
          providersProperties={providersProperties}
          icon={icon}
          styles={styles}
        />
      )
    }

    const entries = Object.entries(integration.logs)
    let result

    switch (provider) {
      case 'azure': {
        result = mapAzureErrors(entries)
        break
      }
      case 'gcp': {
        result = mapGcpErrors(entries)
        break
      }
    }

    if (
      !result ||
      !result.errors ||
      !result.errors.length ||
      result.unknownError
    ) {
      return (
        <UnknownIntegrationError
          hideIntegrationOptions={hideIntegrationOptions}
          integration={integration}
          undoIntegration={undoIntegration}
          retryIntegration={retryIntegration}
          provider={provider}
          providersProperties={providersProperties}
          icon={icon}
          styles={styles}
        />
      )
    }

    return getErrorTemplate(provider, result.errors)
  }

  function undoIntegration(integration, provider) {
    const {id} = integration

    switch (provider) {
      case 'aws':
        dispatch(stopAwsIntegration({integrationId: id, _csrf: csrfState}))
        break

      case 'gcp':
        dispatch(stopGcpIntegration({integrationId: id, _csrf: csrfState}))
        break

      case 'azure':
        dispatch(stopAzureIntegration({integrationId: id, _csrf: csrfState}))
        break
    }
  }

  function retryIntegration(integration) {
    const {id, provider} = integration

    switch (provider) {
      case 'aws':
        dispatch(retryAwsIntegration({integrationId: id, _csrf: csrfState}))
        break

      case 'gcp':
        dispatch(retryGcpIntegration({integrationId: id, _csrf: csrfState}))
        break
    }
  }

  return getErrorMessages(integration, provider)
}

IntegrationFailed.propTypes = {
  integration: PropTypes.object,
  provider: PropTypes.string,
  providersProperties: PropTypes.object,
  styles: PropTypes.object,
}
