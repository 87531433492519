import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Trans} from 'react-i18next'
import i18next from 'i18next'
import bgOrangePurple from 'res/backgrounds/bgOrangePurple.svg'
import Logo from 'res/brand/logo.svg'
import MiniLogo from 'res/brand/logo-mini.svg'

export default function LandingPage({content, loading = true}) {
  const [loadingClass, setLoadingClass] = useState('loading-animation')

  useEffect(() => {
    if (loading === false) setLoadingClass('loaded-animation')
  }, [loading])

  return (
    <div id="container" className="relative">
      <div
        id="border-top"
        className={`absolute h-3 ${loadingClass}`}
        style={{
          backgroundImage: `url(${bgOrangePurple})`,
        }}
      />

      <div className="flex flex-col h-screen w-full">
        <div className="flex items-center justify-center m-auto h-full p-5">
          <div className="text-center">
            <img
              src={MiniLogo}
              alt="Unxpose mini logo"
              className="mb-12 m-auto w-12"
            />
            <div
              id="main-title"
              className="font-medium text-3xl mb-3 text-black">
              {content.title}
            </div>

            <div id="description" className="text-gray-600 leading-6">
              {content.description}
            </div>
          </div>
        </div>

        <footer
          id="footer"
          className="flex items-center justify-between bg-astral-100 border-t border-t-astral-300 p-5 px-10 mt-auto">
          <div>
            <Trans
              i18nKey="unxpose.support"
              components={{
                a: (
                  <a
                    href="mailto: support@unxpose.com"
                    className="text-sky-900 font-medium"
                    rel="noreferrer"
                  />
                ),
              }}
            />

            <div id="slogan" className="text-xs mt-1">
              {i18next.t('unxpose.slogan')}
            </div>
          </div>
          <div>
            <img
              id="footer-logo"
              className="w-40"
              src={Logo}
              alt="Unxpose logo"
            />
          </div>
        </footer>
      </div>
    </div>
  )
}

LandingPage.propTypes = {
  content: PropTypes.object,
  loading: PropTypes.bool,
}
