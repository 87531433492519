const images = {
  // AWS resources
  anotherAccountAWS: require('./aws-tutorial/anotherAccountAWS.png'),
  awsCloudFormationCheckbox: require('./aws-tutorial/aws-cloud-formation-checkbox.png'),
  awsCloudFormationLogo: require('./aws-tutorial/aws-cloud-formation-logo.png'),
  awsCloudFormationStackCreation: require('./aws-tutorial/aws-cloud-formation-stack-creation.png'),
  awsIcon: require('res/icons/awsIcon.svg').default,
  createRoleAWS: require('./aws-tutorial/createRoleAWS.png'),
  externalIdAWS: require('./aws-tutorial/externalIdAWS.png'),
  policyNameAWS: require('./aws-tutorial/policyNameAWS.png'),
  policiesAWS: require('./aws-tutorial/policiesAWS.png'),
  rolesAWS: require('./aws-tutorial/rolesAWS.png'),
  policiesMenuAWS: require('./aws-update-tutorial/policiesMenuAWS.png'),
  permissionsTabAWS: require('./aws-update-tutorial/permissionsTabAWS.png'),
  policyEditorAWS: require('./aws-update-tutorial/policyEditorAWS.png'),
  findStack: require('./aws-cloud-formation-update-tutorial/findStack.png'),
  updateStack: require('./aws-cloud-formation-update-tutorial/updateStack.png'),
  specifyTemplate: require('./aws-cloud-formation-update-tutorial/specifyTemplate.png'),
  capabilitiesCheckbox: require('./aws-cloud-formation-update-tutorial/capabilitiesCheckbox.png'),
  deleteStack: require('./aws-cloud-formation-update-tutorial/deleteStack.png'),

  // Azure resources
  accountTypes: require('res/azure-tutorial/account-types.png'),
  addRoleAssignment: require('res/azure-tutorial/add-role-assignment.png'),
  appRegistration: require('res/azure-tutorial/app-registration.png'),
  appRegistrations: require('res/azure-tutorial/app-registrations.png'),
  azureIcon: require('res/icons/azure.svg').default,
  customRole: require('res/azure-tutorial/custom-role.png'),
  findingIds: require('res/azure-tutorial/finding-ids.png'),
  iamMenu: require('res/azure-tutorial/iam-menu.png'),
  jsonTab: require('res/azure-tutorial/json-tab.png'),
  newClientSecret: require('res/azure-tutorial/new-secret.png'),
  permissionsJson: require('res/azure-tutorial/permissions-json.png'),
  secretValue: require('res/azure-tutorial/secret-value.png'),
  subscriptionsPage: require('res/azure-tutorial/subscriptions.png'),

  // GCP resources
  gcpIcon: require('res/icons/gcpIcon.png'),
  gcp1: require('./gcp-tutorial/1.png'),
  gcp2: require('./gcp-tutorial/2.png'),
  gcp3: require('./gcp-tutorial/3.png'),
  gcp4: require('./gcp-tutorial/4.png'),
  gcp5: require('./gcp-tutorial/5.png'),
  gcp6: require('./gcp-tutorial/6.png'),
  gcp7: require('./gcp-tutorial/7.png'),
  gcp8: require('./gcp-tutorial/8.png'),
  gcp9: require('./gcp-tutorial/9.png'),
  gcp10: require('./gcp-tutorial/10.png'),
  gcp11: require('./gcp-tutorial/11.png'),
  gcp12: require('./gcp-tutorial/12.png'),
  gcp13: require('./gcp-tutorial/13.png'),
  gcpTerraformProjectIds: require('./gcp-tutorial/terraform-project-ids.png'),

  // Compliance
  complianceCards: require('res/compliance/complianceCards.png'),

  //Google
  googleIcon: require('res/icons/googleIcon.svg').default,
  GoogleWorkspaceLogo: require('res/google/GoogleWorkspaceLogo.js').default,

  //Jira
  JiraSoftwareLogo: require('res/jira/jira-software-logo.svg').default,

  //Microsoft
  microsoftIcon: require('res/icons/microsoft.svg').default,
  Microsoft365Logo: require('res/microsoft/microsoft365Logo.png'),

  //People
  PeopleCards: require('res/people/people-cards.png'),

  // Brand
  WhiteLogo: require('res/brand/logo-white.svg').default,
  BlackLogo: require('res/brand/logo.svg').default,
  Favicon: require('res/brand/favicon-blue.svg').default,

  // Icons

  ArrowIcon: require('./icons/ArrowIcon.js').default,
  AttentionSignal: require('./icons/attentionSignal.js').default,
  Chevron: require('./icons/chevron.js').default,
  ClockIcon: require('./icons/clockIcon.js').default,
  CloudIcon: require('./icons/cloudIcon.js').default,
  CloseIcon: require('./icons/closeIcon.js').default,
  DashboardIcon: require('./icons/dashboardIcon.js').default,
  DnsIcon: require('./icons/dnsIcon.js').default,
  DownloadIcon: require('./icons/downloadIcon.js').default,
  EyeIcon: require('./icons/eyeIcon.js').default,
  FilterIcon: require('./icons/FilterIcon.js').default,
  FindingIcon: require('./icons/findingIcon.js').default,
  FireIcon: require('./icons/fireIcon.js').default,
  HelpIcon: require('./icons/helpIcon.js').default,
  InfoIcon: require('./icons/infoIcon.js').default, // 'i' icon
  InformationIcon: require('./icons/informationIcon.js').default, // Information exposure icon
  InternetExposureIcon: require('./icons/InternetExposureIcon.js').default,
  LgpdIcon: require('./icons/LgpdIcon.js').default,
  ManagementIcon: require('./icons/managementIcon.js').default,
  NetworkIcon: require('./icons/networkIcon.js').default,
  PeopleIcon: require('./icons/peopleIcon.js').default,
  PlusIcon: require('./icons/plusIcon.js').default,
  ReportIcon: require('./icons/reportIcon.js').default,
  SearchIcon: require('res/icons/searchIcon.svg').default,
  SecurityShield: require('./icons/securityShield.js').default,
  SecurityShieldQuestion: require('./icons/securityShieldQuestion.js').default,
  SecurityShieldBad: require('./icons/securityShieldBad.js').default,
  TerraformIcon: require('./icons/TerraformIcon.js').default,
  UnexpectedChangeIcon: require('res/icons/unexpectedChangeIcon.js').default,
  WarningIcon: require('./icons/warningIcon.js').default,
  WebIcon: require('./icons/webIcon.js').default,
  crownIcon: require('./icons/crownIcon.js').default,
  checkIcon: require('./icons/checkIcon.svg').default,
  notificationIcon: require('./icons/notificationIcon.png'),
  retryIcon: require('res/icons/retryIcon.js').default,
  magicWandIcon: require('res/icons/magicWandIcon.js').default,
  giftIcon: require('./icons/giftIcon.svg').default,
  copyIcon: require('res/icons/copyIcon.js').default,

  //Illustrations
  ClockIllustration: require('res/illustrations/clock.svg').default,
  EmailReceived: require('res/illustrations/email-received.svg').default,
  SuccessIllustration: require('res/illustrations/success-illustration.svg')
    .default,
  BaseQuestionnaire: require('res/illustrations/base-questionnaire.svg')
    .default,
  // Source: https://undraw.co/

  // Report
  executiveReport: require('res/reports/executiveReport.png'),

  // Partners resources
  LogoWiser: require('res/partners/LogoWiser.js').default,

  // Placeholder Content
  securityScoreOverTime: require('res/placeholders/security-score-placeholder.png'),
  findingsTable: require('res/placeholders/findings-table.png'),
  subdomainsAndDnsRecords: require('res/placeholders/dns-records-table.png'),
  whatsImportant: require('res/placeholders/whats-important-cloud.png'),
  totalClosedOverTime: require('res/placeholders/total-closed-over-time.png'),
  auditLogs: require('res/placeholders/audit-logs.png'),
  accordionFindings: require('res/placeholders/accordion-findings.png'),
  accordionSubdomains: require('res/placeholders/accordion-subdomains.png'),
  accordionDnsRecords: require('res/placeholders/accordion-dns-records.png'),

  // WhyUnxpose Page
  enumerator: require('res/why-unxpose-page/enumerator.svg').default,
  denis: require('res/why-unxpose-page/denis.png'),
  cristiano: require('res/why-unxpose-page/cristiano.png'),
  mateus: require('res/why-unxpose-page/mateus.png'),
  cafLogo: require('res/why-unxpose-page/caf-logo.png'),
  seedzLogo: require('res/why-unxpose-page/seedz-logo.png'),
  tempestLogo: require('res/why-unxpose-page/tempest-logo.png'),

  // Documents
  documentsPlaceholder: require('res/documents/empty-state-placeholder.png'),

  // SUPPLIERS
  SuppliersIcon: require('res/icons/SuppliersIcon.js').default,
}

export default images
