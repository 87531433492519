import React from 'react'
import {useSelector} from 'react-redux'

import {i18next} from 'translate/i18n'

import images from 'res'
import {sideral300} from 'constant'

import GenericEmptyState from 'components/partials/GenericEmptyState'
import TableWrapper from 'components/partials/tables/TableWrapper'

import TableBreaches from './TableBreaches'

export default function BreachesSummary() {
  const breachesState = useSelector(state => state.breaches)

  const breachCol = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: '',
            accessor: 'logo',
            width: 10,
            maxWidth: 30,
            disableSortBy: true,
            disableGlobalFilter: true,
          },
          {
            Header: i18next.t('tables.name'),
            accessor: 'fullName',
            width: 80,
            classes: 'pt-6 pb-2 px-4',
          },
          {
            Header: i18next.t('people.dataLeaked'),
            accessor: 'data',
            classes: 'pt-6 pb-2 pr-2 break-word',
          },
          {
            Header: i18next.t('tables.year'),
            accessor: 'year',
            width: 50,
            classes: 'pt-6 pb-2 pr-2 break-word',
          },
          {
            Header: i18next.t('people.affectedAccounts'),
            accessor: 'count',
            width: 50,
            classes: 'pt-6 pb-2 pr-2 break-word',
          },
        ],
      },
    ],
    []
  )

  return (
    <TableWrapper
      title={i18next.t('people.breaches')}
      loaded={breachesState}
      margin={'mt-8'}
      table={
        <TableBreaches
          columns={breachCol}
          data={breachesState || []}
          pageNumber={0}
          history={history}
          emptyStateWithFilter={
            <GenericEmptyState
              icon={<images.FindingIcon width="55" color={sideral300} />}
              title={i18next.t('tables.nothingFoundWithSearch')}
              body={i18next.t('tables.searchSomethingElse')}
              margin={'m-2 mt-4'}
            />
          }
          emptyStateWithoutFilter={
            <GenericEmptyState
              icon={<images.PeopleIcon width="55" color="#90A4AE" />}
              body={i18next.t('subtitles.noBreachedEmails')}
              margin="m-2"
            />
          }
        />
      }
    />
  )
}
