import React from 'react'
import PropTypes from 'prop-types'

export default function ReportIcon({width, height, color, opacity}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path
          d="M6.66667 6.66658H9.33333V10.6666H22.6667V6.66658H25.3333V13.3333H28V6.66658C28 5.19992 26.8 3.99992 25.3333 3.99992H19.76C19.2 2.45325 17.7333 1.33325 16 1.33325C14.2667 1.33325 12.8 2.45325 12.24 3.99992H6.66667C5.2 3.99992 4 5.19992 4 6.66658V25.3333C4 26.7999 5.2 27.9999 6.66667 27.9999H9V25.3333H6.66667V6.66658ZM16 3.99992C16.7333 3.99992 17.3333 4.59992 17.3333 5.33325C17.3333 6.06659 16.7333 6.66658 16 6.66658C15.2667 6.66658 14.6667 6.06659 14.6667 5.33325C14.6667 4.59992 15.2667 3.99992 16 3.99992Z"
          fill={color}
        />
        <path
          d="M26 19.42V22H28V16H22V18H24.58L20.12 22.46C19.73 22.85 19.1 22.85 18.71 22.46L17.54 21.29C16.37 20.12 14.47 20.12 13.3 21.29L10 24.5L11.5 26L14.7 22.71C15.09 22.32 15.72 22.32 16.11 22.71L17.28 23.88C18.45 25.05 20.35 25.05 21.52 23.88L26 19.42Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

ReportIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
}
