import i18next from 'i18next'
import React from 'react'

import Footer from './Footer'

export default function ExecutiveReport() {
  return (
    <>
      <div className="py-4 px-8">
        <div className="flex">
          <div className="w-full text-sm">
            <h1 className="font-medium mt-2 mb-4 text-lg">
              {i18next.t('help.executiveReport.menu.title')}
            </h1>
            <p className="mb-4">
              {i18next.t('help.executiveReport.paragraph1')}
            </p>
            <p className="mb-4">
              {i18next.t('help.executiveReport.paragraph2')}
            </p>

            <h2 className="font-medium mt-2 mb-4 text-normal">
              {i18next.t('help.executiveReport.subtitle')}
            </h2>
            <p className="mb-4">
              {i18next.t('help.executiveReport.paragraph3')}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
