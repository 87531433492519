import React from 'react'

import PropTypes from 'prop-types'

import TrashIcon from 'res/icons/trashIcon'
import {sky500} from 'constant'

export default function MemberItem({
  name,
  email,
  role,
  rightSideContent,
  disabledDelete,
  onDelete,
}) {
  return (
    <div className="flex justify-between items-center flex-wrap border-b border-sideral-50 last:border-none rounded-md p-6">
      <div id="left-side">
        <div className="text-sm">
          {name && (
            <div className="flex items-center text-sideral-700 font-medium mr-1">
              <p className="mr-1">{name}</p>
              {role && <p>({role})</p>}
            </div>
          )}
          <p className="text-sideral-300">{email}</p>
        </div>
      </div>
      <div id="right-side" className="flex items-center">
        {rightSideContent}
        <button className="ml-4" onClick={onDelete} disabled={disabledDelete}>
          <TrashIcon color={sky500} height="22" width="22" />
        </button>
      </div>
    </div>
  )
}

MemberItem.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
  rightSideContent: PropTypes.object,
  disabledDelete: PropTypes.bool,
  onDelete: PropTypes.func,
}
