import i18next from 'i18next'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {v4 as uuidv4} from 'uuid'

import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import GlobalFilter from 'components/partials/tables/GlobalFilter'
import Pagination from 'components/partials/tables/Pagination'
import images from 'res'
import {tableStyles} from 'constant'

export default function ActiveIntegrationsTable(props) {
  const [filterValue, setFilterValue] = useState('')
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    rows,

    canPreviousPage,
    canNextPage,

    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    state: {pageIndex},
    setGlobalFilter,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        pageIndex: props.pageNumber,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
        hiddenColumns: ['accountAlias'],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  const awsIntegrationDisplayName = integration => {
    const {name, accountAlias} = integration
    return accountAlias ? `${name} (${accountAlias})` : name
  }

  return (
    <div>
      <div className="tableWrap relative">
        <div className="flex justify-between items-center pb-2">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            value={filterValue}
            setFilterValue={setFilterValue}
            setGlobalFilter={setGlobalFilter}
            gotoPage={gotoPage}
          />
          <Pagination
            handlePrevious={previousPage}
            handleNext={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            paginationClass={props.paginationClass}
            pageIndex={pageIndex}
            pageCount={pageCount}
            total={rows.length || 0}
          />
        </div>
        {page && page.length ? (
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map(headerGroup => {
                // trick to disable the first headerGroup
                if (headerGroup.headers.length === 1)
                  return <tr key={uuidv4()}></tr>

                return (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={uuidv4()}
                    className="p-2">
                    {headerGroup.headers.map(column => (
                      <th
                        className="dm-mono text-sideral-300 text-sm"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={uuidv4()}>
                        {column.render('Header').toUpperCase()}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' ↓'
                              : ' ↑'
                            : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                )
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    className={tableStyles.tableRow}
                    key={uuidv4()}>
                    {row.cells.map(cell => {
                      if (cell.column.id === 'percentage')
                        return (
                          <td
                            className={cell.column.classes || ''}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {typeof cell.value === 'number'
                              ? `${cell.value}%`
                              : '—'}
                          </td>
                        )

                      if (cell.column.id === 'name')
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={uuidv4()}
                            className="flex items-center justify-center">
                            {cell.row.original.provider === 'aws' && (
                              <div className="flex items-center justify-center">
                                <img
                                  src={images.awsIcon}
                                  width="26"
                                  className="mr-2 mt-1"
                                />

                                <p>
                                  {i18next.t('cloud.accountId')}:{' '}
                                  <span className="font-light">
                                    {awsIntegrationDisplayName(
                                      cell.row.original
                                    )}
                                  </span>
                                </p>
                              </div>
                            )}
                            {cell.row.original.provider === 'gcp' && (
                              <div className="flex items-center justify-center">
                                <img
                                  src={images.gcpIcon}
                                  width="26"
                                  className="mr-2"
                                />
                                <p>
                                  {i18next.t('cloud.projectId')}:{' '}
                                  <span className="font-light">
                                    {cell.render('Cell')}
                                  </span>
                                </p>
                              </div>
                            )}
                            {cell.row.original.provider === 'azure' && (
                              <div className="flex items-center justify-center">
                                <img
                                  src={images.azureIcon}
                                  width="26"
                                  className="mr-2"
                                />
                                <p>
                                  {i18next.t('cloud.directoryId')}:{' '}
                                  <span className="font-light">
                                    {cell.render('Cell')}
                                  </span>
                                </p>
                              </div>
                            )}
                          </td>
                        )
                      return (
                        <td
                          className={cell.column.classes || ''}
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          {typeof cell.value !== 'undefined'
                            ? cell.render('Cell')
                            : '—'}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <>
            {filterValue
              ? props.emptyStateWithFilter
              : props.emptyStateWithoutFilter}
          </>
        )}
      </div>
    </div>
  )
}

ActiveIntegrationsTable.propTypes = {
  defaultSorted: PropTypes.array,
  pageNumber: PropTypes.number,
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  paginationClass: PropTypes.string,
  history: PropTypes.object,
}
