import PropTypes from 'prop-types'
import React from 'react'

export default function ScoreBadge(props) {
  let colors

  switch (props.letter) {
    case 'A':
      colors = 'bg-teal-500'
      break
    case 'B':
      colors = 'bg-green-500'
      break
    case 'C':
      colors = 'bg-yellow-500'
      break
    case 'D':
      colors = 'bg-orange-500'
      break
    case 'E':
      colors = 'bg-red-500'
      break
    case 'F':
      colors = 'bg-red-700'
      break
    default:
      colors = 'bg-gray-400 text-gray-600'
      break
  }

  return (
    <div
      className={
        'rounded text-white font-semibold w-5 text-center float-left mr-2 ' +
        colors
      }>
      {props.letter}
    </div>
  )
}

ScoreBadge.propTypes = {
  letter: PropTypes.string,
}
