import i18next from 'i18next'
import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import images from 'res'
import {sideral300} from 'constant'

import BackButton from 'components/partials/buttons/BackButton'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import TableWrapper from 'components/partials/tables/TableWrapper'
import Spinner from 'components/partials/Spinner'
import {clearFingerprintDetails, fetchFingerprintDetails} from 'actions'
import WebAppsTable from './WebAppsTable'
import {Page} from 'components/partials/Page'

const PAGE_SIZE = 10
const PAGE_INDEX = 0

export default function FingerprintDetails() {
  const dispatch = useDispatch()
  const fingerprintDetails = useSelector(state => state.fingerprintDetails)
  const params = useParams()

  const [filterValue, setFilterValue] = useState('')
  const [sortOptions, setSortOptions] = useState([])

  useEffect(() => {
    if (params.id)
      dispatch(
        fetchFingerprintDetails({
          fingerprintId: params.id,
          sortBy: sortOptions,
          limit: PAGE_SIZE,
        })
      )

    return () => dispatch(clearFingerprintDetails())
  }, [])

  const webAppsColumns = useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.name').toUpperCase(),
            accessor: 'final',
            width: 100,
          },
          {
            Header: i18next.t('web.tables.version').toUpperCase(),
            accessor: 'version',
            width: 40,
          },
          {
            Header: i18next.t('misc.findings').toUpperCase(),
            accessor: 'findingsCount',
            width: 40,
          },
          {
            Header: 'Score'.toUpperCase(),
            accessor: 'score',
            width: 40,
          },
        ],
      },
    ],
    []
  )

  if (!fingerprintDetails) return <Spinner />

  return (
    <Page pageTitle={i18next.t('pageTitles.web')}>
      <div className="flex w-full rounded-lg border border-sideral-100 bg-white font-medium text-sideral-700 p-4">
        <images.WebIcon width="50" color="#90A4AE" />
        <div className="flex flex-col justify-between flex-wrap pl-4">
          <BackButton fallbackLink={'/web'} />
          <p className="w-full">{fingerprintDetails.title}</p>
        </div>
      </div>

      <TableWrapper
        title={`${i18next.t('web.tables.webAppsWith')} ${
          fingerprintDetails.title
        }`}
        loaded={fingerprintDetails && fingerprintDetails.data}
        table={
          <WebAppsTable
            fetchData={filterOptions =>
              dispatch(
                fetchFingerprintDetails({
                  ...filterOptions,
                  fingerprintId: params.id,
                  limit: PAGE_SIZE,
                })
              )
            }
            columns={webAppsColumns}
            data={fingerprintDetails.data}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            setSortOptions={setSortOptions}
            pageSize={PAGE_SIZE}
            pageIndex={PAGE_INDEX}
            pageCount={fingerprintDetails.pages}
            total={fingerprintDetails.total}
            emptyStateWithFilter={
              <GenericEmptyState
                icon={<images.LgpdIcon width="50" color={sideral300} />}
                title={i18next.t('tables.nothingFoundWithSearch')}
                body={i18next.t('tables.searchSomethingElse')}
                margin={'m-2 mt-4'}
              />
            }
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<images.LgpdIcon width="50" color={sideral300} />}
                title={i18next.t('tables.nothingFound')}
                body={i18next.t('tables.waitAFewMinutes')}
                margin={'m-2 mt-4'}
              />
            }
          />
        }
        margin="mt-4"
      />
    </Page>
  )
}
