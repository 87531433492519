import {getRequestParams} from 'utils/pagination'
import {handler} from './handler'
import config from 'config'
import {
  fetchAllFindingsDone,
  fetchAllFindingsCountDone,
  genericError,
  success,
  fetchBulkFindingsDone,
  updateFindingsBulkDone,
  fetchFindingsBulkResultDone,
} from 'actions'

export function* handleFetchAllFindingsCount(action) {
  yield handler({
    handler: fetchAllFindingsCount,
    error: genericError,
    success: fetchAllFindingsCountDone,
    action,
  })
}

const fetchAllFindingsCount = function () {
  const url = new URL(`${config.SERVER_URL}/findings/count`)

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAllFindings(action) {
  yield handler({
    handler: fetchAllFindings,
    error: genericError,
    success: fetchAllFindingsDone,
    action,
  })
}

const fetchAllFindings = function (params) {
  const url = new URL(`${config.SERVER_URL}/findings`)
  url.search = new URLSearchParams(getRequestParams(params)).toString()

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchBulkFindings(action) {
  yield handler({
    handler: fetchBulkFindings,
    error: genericError,
    success: fetchBulkFindingsDone,
    action,
  })
}

const fetchBulkFindings = function (body) {
  const url = new URL(`${config.SERVER_URL}/findings/listSelected`)

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(body),
    headers: {'Content-Type': 'application/json'},
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateFindingsBulk(action) {
  yield handler({
    handler: updateFindingsBulk,
    error: genericError,
    success: updateFindingsBulkDone,
    action,
  })
}

const updateFindingsBulk = function (body) {
  const url = new URL(`${config.SERVER_URL}/findings/bulkChange`)

  return fetch(url, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify(body),
    headers: {'Content-Type': 'application/json'},
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchFindingsBulkResult(action) {
  yield handler({
    handler: fetchFindingsBulkResult,
    error: genericError,
    success: fetchFindingsBulkResultDone,
    action,
  })
}

const fetchFindingsBulkResult = function (params) {
  const url = new URL(
    `${config.SERVER_URL}/findings/bulkChange/${params.jobId}/result`
  )

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateFindingAssignee(action) {
  const fetchFinding = action.payload.fetchFinding
  const fetchFindingActivity = action.payload.fetchFindingActivity
  const findingId = action.payload.findingId

  yield handler({
    handler: updateFindingAssignee,
    error: genericError,
    success: [
      () => fetchFinding(findingId),
      () => fetchFindingActivity(findingId),
      () => success({message: action.payload.successMessage}),
    ],
    action,
  })
}

const updateFindingAssignee = function (body) {
  let url

  switch (body.findingType) {
    case 'dns':
      url = `${config.SERVER_URL}/issue/${body.findingId}`
      break

    case 'aws':
    case 'people':
      url = `${config.SERVER_URL}/${body.findingType}/findings/${body.findingId}`
      break

    default:
      url = `${config.SERVER_URL}/${body.findingType}/finding/${body.findingId}`
      break
  }

  return fetch(url, {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'PATCH',
  }).catch(err => {
    console.trace(err)
  })
}
