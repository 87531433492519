import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {v4 as uuidv4} from 'uuid'
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import {sideral300, tableStyles} from 'constant'

import images from 'res'

import GenericEmptyState from 'components/partials/GenericEmptyState'
import GlobalFilter from 'components/partials/tables/GlobalFilter'
import Pagination from 'components/partials/tables/Pagination'

import ServicesCell from './ServicesCell'
import {i18next} from 'translate/i18n'

export default function PrimaryTable(props) {
  const [filterValue, setFilterValue] = useState('')
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    rows,

    canPreviousPage,
    canNextPage,

    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    state: {pageIndex},
    setGlobalFilter,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        pageIndex: props.pageNumber,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  function handleEmptyState() {
    return (
      <GenericEmptyState
        icon={<images.NetworkIcon width="55" color={sideral300} />}
        title={i18next.t('tables.nothingFoundWithSearch')}
        body={i18next.t('tables.searchSomethingElse')}
        margin="m-2"
      />
    )
  }

  return (
    <div>
      <div className="tableWrap relative">
        <div className="flex justify-between items-center pb-2">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={filterValue}
            setFilterValue={setFilterValue}
            setGlobalFilter={setGlobalFilter}
            gotoPage={gotoPage}
          />
          <Pagination
            handlePrevious={previousPage}
            handleNext={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            paginationClass={props.paginationClass}
            pageIndex={pageIndex}
            pageCount={pageCount}
            total={rows.length || 0}
          />
        </div>

        {!page.length ? (
          handleEmptyState()
        ) : (
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map(headerGroup => {
                // trick to disable the first headerGroup
                if (headerGroup.headers.length === 1)
                  return <tr key={uuidv4()}></tr>

                return (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={uuidv4()}
                    className="p-2">
                    {headerGroup.headers.map(column => (
                      <th
                        className="dm-mono text-sideral-300 text-sm"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={uuidv4()}>
                        {column.render('Header').toUpperCase()}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' ↓'
                              : ' ↑'
                            : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                )
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    className={tableStyles.tableRow}
                    key={uuidv4()}>
                    {row.cells.map(cell => {
                      if (cell.column.id === 'dnsRecords') {
                        const value =
                          cell.row.original.dnsRecords &&
                          cell.row.original.dnsRecords.length
                            ? cell.row.original.dnsRecords
                                .map(x => x)
                                .join(', ')
                            : '—'

                        return (
                          <td
                            className="py-2 pr-2 text-center"
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {value}
                          </td>
                        )
                      }

                      if (cell.column.id === 'services') {
                        return (
                          <ServicesCell cell={cell} row={row} key={uuidv4()} />
                        )
                      }

                      return (
                        <td
                          className={cell.column.classes || ''}
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          <Link to={`/network/host/${row.original.id}`}>
                            {cell.value ? cell.render('Cell') : '—'}
                          </Link>
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

PrimaryTable.propTypes = {
  defaultSorted: PropTypes.array,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  paginationClass: PropTypes.string,
  history: PropTypes.object,
}
