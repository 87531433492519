import PropTypes from 'prop-types'
import React from 'react'
import i18next from 'i18next'

import IntegrationHeader from './IntegrationHeader'
import StatsItem from './StatsItem'

export default function IntegrationStarted({
  provider,
  providersProperties,
  integration,
  icon,
  styles,
  hideIntegrationOptions,
}) {
  return (
    <div className={styles.providerContainer}>
      <IntegrationHeader
        hideIntegrationOptions={hideIntegrationOptions}
        provider={provider}
        providersProperties={providersProperties}
        integration={integration}
        icon={icon}
        styles={styles}
      />
      <div className={styles.providerBody}>
        <div className="text-sm">
          <p>{i18next.t('cloud.connectingToProvider')}</p>
          <p className="font-light">
            {i18next.t('warnings.comeBackInMinutes')}!
          </p>
        </div>
        <StatsItem styles={styles} />
      </div>
    </div>
  )
}

IntegrationStarted.propTypes = {
  integration: PropTypes.object,
  provider: PropTypes.string,
  providersProperties: PropTypes.object,
  icon: PropTypes.string,
  styles: PropTypes.object,
  hideIntegrationOptions: PropTypes.bool,
}
