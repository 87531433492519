import InnerTable from './InnerTable'
import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import {i18next} from 'translate/i18n'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {v4 as uuidv4} from 'uuid'

import {formatDnsRecords} from './utils'
import PlanUtils from 'utils/plan'
import {sky500, reactSelectTheme, reactSelectStyles, sky900} from 'constant'

import Chevron from 'res/icons/chevron'
import FindingIcon from 'res/icons/findingIcon'
import GlobalFilter from 'components/partials/tables/GlobalFilter'
import Pagination from 'components/partials/tables/Pagination'
import PlaceholderContent from 'components/partials/PlaceholderContent'
import ScoreBadge from 'components/partials/ScoreBadge'
import Spinner from 'components/partials/Spinner'
import TrashIcon from 'res/icons/trashIcon'

import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

import {
  clearSuccess,
  fetchDomainFindings,
  fetchDomainRecords,
  fetchDomainSubdomains,
} from 'actions'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import RemoveDomainModal from './RemoveDomainModal'
import {isLoading} from 'utils'

export default function DomainsTable(props) {
  const loadingState = useSelector(state => state.loading)
  const domainSubdomainsState = useSelector(state => state.domainSubdomains)
  const domainRecordsState = useSelector(state => state.domainRecords)
  const domainFindingsState = useSelector(state => state.domainFindings)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const planUtils = PlanUtils()

  const [filterValue, setFilterValue] = useState('')
  const [openRows, setOpenRows] = useState([])
  const [sorting, setSorting] = useState([
    {
      value: 'alphabetical',
      label: i18next.t('sortSelect.options.alphabetical'),
    },
  ])

  const [tabs, setTabs] = useState({})

  const [findings, setFindings] = useState([])
  const [records, setRecords] = useState([])
  const [subdomains, setSubdomains] = useState([])
  const [loadedRowsIds, setLoadedRowsIds] = useState([])
  const [removeDomainModal, setRemoveDomainModal] = useState({
    domainId: '',
    hostname: '',
    showModal: false,
  })

  const shouldFetchFindings = rowId =>
    !loadedRowsIds.find(loaded => loaded === rowId)
  const shouldFetchSubdomains = rowId =>
    !domainSubdomainsState ||
    (domainSubdomainsState && !domainSubdomainsState[rowId])
  const shouldFetchRecords = rowId =>
    !domainRecordsState || (domainRecordsState && !domainRecordsState[rowId])
  const domainIsLoading = domainId =>
    isLoading(loadingState.domainRecords?.[domainId]) ||
    isLoading(loadingState.domainSubdomains?.[domainId]) ||
    isLoading(loadingState.domainFindings?.[domainId])

  const toggleAccordion = rowId => {
    const arr = [...openRows]

    if (arr.includes(rowId)) {
      const index = arr.indexOf(rowId)
      arr.splice(index, 1)
    } else {
      arr.push(rowId)
    }

    setOpenRows([...arr])
  }

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    rows,
    setSortBy,

    canPreviousPage,
    canNextPage,

    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    state: {pageIndex},
    setGlobalFilter,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        hiddenColumns: ['score'],
        pageIndex: props.pageNumber,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  const findingsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.title'),
            accessor: 'title',
          },

          {
            Header: 'Status',
            accessor: 'status',
            width: 60,
          },
          {
            Header: i18next.t('tables.severity'),
            accessor: 'severity',
            width: 50,
          },
          {
            Header: i18next.t('tables.seen'),
            accessor: 'checkedAt',
            width: 50,
            sortType: (a, b) => {
              return (
                new Date(a.original.updatedAt) - new Date(b.original.updatedAt)
              )
            },
          },
        ],
      },
    ],
    []
  )

  const subdomainColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.name'),
            accessor: 'name',
          },
          {
            Header: i18next.t('tables.value'),
            accessor: 'data',
            clickable: true,
          },
          {
            Header: i18next.t('tables.seen'),
            accessor: 'updatedAt',
            sortType: (a, b) => {
              return (
                new Date(a.original.updatedAt) - new Date(b.original.updatedAt)
              )
            },
          },
        ],
      },
    ],
    []
  )

  const recordsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.name'),
            accessor: 'name',
          },
          {
            Header: i18next.t('tables.type'),
            accessor: 'type',
            width: 50,
          },
          {
            Header: i18next.t('tables.value'),
            accessor: 'data',
          },
          {
            Header: i18next.t('tables.seen'),
            accessor: 'updatedAt',
            width: 50,
            sortType: (a, b) => {
              return (
                new Date(a.original.updatedAt) - new Date(b.original.updatedAt)
              )
            },
          },
        ],
      },
    ],
    []
  )

  function handleChangeSort(item) {
    setSorting(item)

    switch (item.value) {
      case 'highestScore':
        setSortBy([
          {
            id: 'score',
            desc: true,
          },
        ])
        break

      case 'lowestScore':
        setSortBy([
          {
            id: 'score',
            desc: false,
          },
        ])
        break

      case 'alphabetical':
        setSortBy([
          {
            id: 'hostname',
            desc: false,
          },
        ])
        break

      default:
        break
    }
  }

  function handleTab(eventTarget, rowId) {
    setTabs({...tabs, [rowId]: eventTarget})
  }

  function handleRowClick(rowId) {
    setLoadedRowsIds([...loadedRowsIds, rowId])

    if (shouldFetchFindings(rowId))
      dispatch(
        fetchDomainFindings({
          domainId: rowId,
          pagination: {pageIndex, pageSize: props.pageSize},
        })
      )
    if (shouldFetchSubdomains(rowId))
      dispatch(
        fetchDomainSubdomains({
          domainId: rowId,
          pagination: {pageIndex, pageSize: props.pageSize},
        })
      )
    if (shouldFetchRecords(rowId))
      dispatch(
        fetchDomainRecords({
          domainId: rowId,
          pagination: {pageIndex, pageSize: props.pageSize},
        })
      )

    // set 'findings' as the initial tab by default
    if (!Object.keys(tabs).includes(rowId)) handleTab('findings', rowId)

    toggleAccordion(rowId)
  }

  const handleRemoveDomain = (domainId, hostname) => {
    dispatch(clearSuccess())
    setRemoveDomainModal({
      domainId,
      hostname,
      showModal: true,
    })
  }

  useEffect(() => {
    if (domainFindingsState) setFindings(domainFindingsState)
  }, [domainFindingsState])

  useEffect(() => {
    if (domainSubdomainsState) setSubdomains(domainSubdomainsState)
  }, [domainSubdomainsState])

  useEffect(() => {
    if (!domainRecordsState) return

    try {
      const currentRecords = JSON.parse(JSON.stringify(domainRecordsState))

      for (const key in currentRecords) {
        currentRecords[key].data.records = formatDnsRecords(
          currentRecords[key].data.records
        )
      }

      setRecords(currentRecords)
    } catch (err) {
      setRecords({})
    }
  }, [domainRecordsState])

  useEffect(() => {
    if (!rows.length) return
    props.setFilteredDomains(rows.map(row => row.original))
  }, [rows])

  return (
    <div>
      <div className="tableWrap relative">
        <div
          id="filters"
          className="flex flex-wrap xl:flex-nowrap justify-end xl:justify-between items-center pb-2">
          <div className="flex flex-wrap sm:flex-nowrap items-center gap-2 order-last xl:order-none w-full xl:w-3/4">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={filterValue}
              setFilterValue={setFilterValue}
              setGlobalFilter={setGlobalFilter}
              gotoPage={gotoPage}
              filterWidth="w-full"
            />

            <div className="w-full">
              {headerGroups.map((headerGroup, index) => {
                if (!index) return <span key={uuidv4()}></span>
                return (
                  <div
                    key={uuidv4()}
                    className="flex items-center justify-center text-xs w-full">
                    {headerGroup.headers.map(column => {
                      if (!column.canFilter) return <span key={uuidv4()}></span>

                      return (
                        <div key={uuidv4()} className="w-full">
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>

            <div className="w-full text-xs">
              <Select
                placeholder={i18next.t('sortSelect.placeholder')}
                styles={reactSelectStyles}
                options={[
                  {
                    value: 'highestScore',
                    label: i18next.t('sortSelect.options.highestScore'),
                  },
                  {
                    value: 'lowestScore',
                    label: i18next.t('sortSelect.options.lowestScore'),
                  },
                  {
                    value: 'alphabetical',
                    label: i18next.t('sortSelect.options.alphabetical'),
                  },
                ]}
                theme={reactSelectTheme}
                value={sorting}
                onChange={handleChangeSort}
              />
            </div>
          </div>

          <div className="mb-2 xl:mb-0">
            <Pagination
              handlePrevious={previousPage}
              handleNext={nextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              paginationClass={props.paginationClass}
              pageIndex={pageIndex}
              pageCount={pageCount}
              total={rows.length || 0}
            />
          </div>
        </div>
        {page && page.length ? (
          <table {...getTableProps()} className="w-full">
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)

                return (
                  <tr
                    onClick={() => handleRowClick(row.original.id)}
                    {...row.getRowProps()}
                    className="flex flex-wrap w-full rounded-lg border-2 border-sideral-50 my-2"
                    key={uuidv4()}>
                    {row.cells.map(cell => {
                      if (cell.column.hidden) return <td key={uuidv4()}></td>

                      if (cell.column.id === 'hostname') {
                        return (
                          <td
                            className="flex cursor-pointer text-left text-sideral-700 font-medium text-sm items-center p-4"
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <ScoreBadge letter={cell.row.original.score} />
                            {cell.value ? cell.render('Cell') : '-'}
                          </td>
                        )
                      }
                      if (cell.column.id === 'findingsCount') {
                        return (
                          <td
                            className="flex cursor-pointer items-center p-4 text-sm"
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <figure className="pr-1">
                              <FindingIcon width="20" />
                            </figure>
                            {cell.value ? cell.row.original.findingsCount : '0'}{' '}
                            {cell.row.original.findingsCount > 1 ||
                            !cell.row.original.findingsCount
                              ? i18next.t('misc.findings').toLowerCase()
                              : i18next.t('misc.finding').toLowerCase()}
                          </td>
                        )
                      }
                      if (cell.column.id === 'actionButtons') {
                        return (
                          <td
                            className="flex cursor-pointer items-center justify-end p-4 text-sm"
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <SecondaryButton
                              text={i18next.t('buttons.details')}
                              onClick={e => {
                                e.stopPropagation()

                                navigate(`/dns/domain/${cell.row.original.id}`)
                              }}
                              size="sm"
                            />

                            <div className="flex ml-4 pr-5 border-r">
                              <button
                                onClick={e => {
                                  e.stopPropagation()

                                  handleRemoveDomain(
                                    cell.row.original.id,
                                    cell.row.original.hostname
                                  )
                                }}>
                                <TrashIcon
                                  color={sky900}
                                  height="20"
                                  width="20"
                                />
                              </button>
                            </div>
                          </td>
                        )
                      }

                      if (cell.column.id === 'chevron')
                        return (
                          <td
                            className="flex cursor-pointer items-center p-4 pl-2"
                            key={uuidv4()}>
                            <Chevron
                              width="12"
                              color={sky500}
                              direction={
                                openRows.includes(row.original.id)
                                  ? 'up'
                                  : 'down'
                              }
                            />
                          </td>
                        )
                      return (
                        <td
                          className={cell.column.classes || ''}
                          {...cell.getCellProps()}
                          key={uuidv4()}>
                          {cell.value ? cell.render('Cell') : '—'}
                        </td>
                      )
                    })}

                    {openRows.includes(row.original.id) ? (
                      <>
                        {domainIsLoading(row.original.id) &&
                        !planUtils.hasPlanRestriction() ? (
                          <td className="w-full -mt-4">
                            <Spinner />
                          </td>
                        ) : (
                          <td
                            className="bg-astral-50 border-t-2 border-sideral-50 w-full p-4"
                            onClick={e => e.stopPropagation()}>
                            <div className="flex text-sky-500 text-sm mb-4">
                              <button
                                value="findings"
                                className={`${
                                  tabs[row.original.id] === 'findings' ||
                                  !tabs[row.original.id]
                                    ? ' border-sky-500 text-sky-900'
                                    : 'border-transparent'
                                } border-b-2 uppercase cursor-pointer outline-none mr-5 pb-2`}
                                onClick={e => {
                                  handleTab(e.target.value, row.original.id)
                                }}>
                                {i18next.t('misc.findings')}
                              </button>
                              <button
                                value="subdomains"
                                className={`${
                                  tabs[row.original.id] === 'subdomains'
                                    ? ' border-sky-500 text-sky-900'
                                    : 'border-transparent'
                                } border-b-2 uppercase cursor-pointer outline-none mr-5 pb-2`}
                                onClick={e => {
                                  handleTab(e.target.value, row.original.id)
                                }}>
                                {i18next.t('dns.subdomains')}
                              </button>
                              <button
                                value="records"
                                className={`${
                                  tabs[row.original.id] === 'records'
                                    ? ' border-sky-500 text-sky-900'
                                    : 'border-transparent'
                                } border-b-2 uppercase cursor-pointer outline-none pb-2`}
                                onClick={e => {
                                  handleTab(e.target.value, row.original.id)
                                }}>
                                {i18next.t('dns.dnsRecords')}
                              </button>
                            </div>
                            <div className="flex flex-wrap w-full">
                              {tabs[row.original.id] === 'subdomains' && (
                                <>
                                  {planUtils.hasPlanRestriction() ? (
                                    <PlaceholderContent sectionId="accordionSubdomains" />
                                  ) : (
                                    <InnerTable
                                      data={subdomains[
                                        row.original.id
                                      ].data.subdomains.filter(
                                        subdomain =>
                                          subdomain.domainId === row.original.id
                                      )}
                                      columns={subdomainColumns}
                                      tab={tabs[row.original.id]}
                                      rowId={row.original.id}
                                    />
                                  )}
                                </>
                              )}{' '}
                              {tabs[row.original.id] === 'records' && (
                                <>
                                  {planUtils.hasPlanRestriction() ? (
                                    <PlaceholderContent sectionId="accordionDnsRecords" />
                                  ) : (
                                    <InnerTable
                                      data={records[
                                        row.original.id
                                      ].data.records.filter(
                                        record =>
                                          record.domainId === row.original.id
                                      )}
                                      columns={recordsColumns}
                                      tab={tabs[row.original.id]}
                                      rowId={row.original.id}
                                    />
                                  )}
                                </>
                              )}
                              {tabs[row.original.id] === 'findings' && (
                                <div className="w-full">
                                  {planUtils.hasPlanRestriction() ? (
                                    <PlaceholderContent sectionId="accordionFindings" />
                                  ) : (
                                    <InnerTable
                                      data={findings[row.original.id] || []}
                                      columns={findingsColumns}
                                      tab={tabs[row.original.id]}
                                      rowId={row.original.id}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </td>
                        )}
                      </>
                    ) : null}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <>
            {filterValue
              ? props.emptyStateWithFilter
              : props.emptyStateWithoutFilter}
          </>
        )}
      </div>

      {removeDomainModal.showModal && (
        <RemoveDomainModal
          modal={removeDomainModal}
          setModal={setRemoveDomainModal}
        />
      )}
    </div>
  )
}

DomainsTable.propTypes = {
  defaultSorted: PropTypes.array,
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  paginationClass: PropTypes.string,
  records: PropTypes.array,
  setSubdomains: PropTypes.func,
  subdomains: PropTypes.array,
  subdomainsTable: PropTypes.object,
  history: PropTypes.object,
  setFilteredDomains: PropTypes.func,
}
