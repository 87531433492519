import PropTypes from 'prop-types'
import i18next from 'i18next'
import React, {useEffect, useState} from 'react'
import Chevron from 'res/icons/chevron'
import {sideral300} from 'constant'
import RemoveIntegrationModal from 'components/cloud/aws/RemoveIntegrationModal'

export default function RemoveCloudIntegrationButton({
  provider,
  integration,
  isHovered,
}) {
  const [dropdownVisibility, setDropdownVisibility] = useState('hidden')
  const [modalVisibility, setModalVisibility] = useState('hidden')

  const integrationInfo = {
    provider,
    integrationId: integration.id,
    integrationName: integration.integrationName || null,
  }

  function handleEllipsisClick() {
    dropdownVisibility === 'hidden'
      ? setDropdownVisibility('block')
      : setDropdownVisibility('hidden')
  }

  const toggleModal = () => {
    modalVisibility === 'hidden'
      ? setModalVisibility('block')
      : setModalVisibility('hidden')
  }

  const closeFilterMenu = e => {
    const buttonContainer = document.querySelector('#button-container')

    if (!buttonContainer.contains(e.target)) setDropdownVisibility('hidden')
  }

  useEffect(() => {
    document.addEventListener('mouseup', closeFilterMenu)
    return () => document.removeEventListener('mouseup', closeFilterMenu)
  }, [])

  return (
    <div
      id="button-container"
      className="relative flex items-center justify-center cursor-pointer"
      onClick={handleEllipsisClick}>
      <RemoveIntegrationModal
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
        history={history}
        integrationInfo={integrationInfo}
      />
      <div
        className={`${dropdownVisibility} absolute text-ink rounded-md top-0 left-0 ml-2 mt-5`}>
        <button
          id="button-element"
          className="font-light border w-40 rounded-md p-1 bg-gray-100 hover:bg-gray-100 py-2"
          onClick={toggleModal}>
          {i18next.t('cloud.removal.dropdownButton')}
        </button>
      </div>
      <div
        className={
          isHovered || dropdownVisibility !== 'hidden' ? 'block' : 'hidden'
        }>
        <div className="ml-2">
          <Chevron width="12" color={sideral300} direction="down" />
        </div>
      </div>
    </div>
  )
}

RemoveCloudIntegrationButton.propTypes = {
  integration: PropTypes.object,
  isHovered: PropTypes.bool,
  provider: PropTypes.string,
}
