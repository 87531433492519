import config from 'config'
import {
  answerFeedback,
  fetchQuestionnaireDetails,
  fetchQuestionnaireDetailsDone,
  fetchQuestionnaires,
  fetchQuestionnairesDone,
  genericError,
  loading,
  redirectFeedback,
  uploadQuestionnaireDone,
  success,
  generateAnswerDone,
  fetchQuestionnaireBaseDetailsDone,
  fetchQuestionnaireTemplatesDone,
  fetchQuestionnaireTemplates,
  fetchQuestionnaireInvitesDone,
  fetchQuestionnaireInvites,
  fetchSupplierQuestionnaireDone,
  fetchSupplierQuestionnaire,
  answersReviewFeedback,
  questionnaireReviewDone,
} from 'actions'
import {handler} from './handler'
import i18next from 'i18next'
import {baseQuestionnaireId} from 'constant'

export function* handleFetchQuestionnaireDetails(action) {
  yield handler({
    handler: _fetchQuestionnaireDetails,
    error: genericError,
    success: data => {
      if (action.payload.onSuccess) action.payload.onSuccess(data)

      return action.payload.questionnaireId === baseQuestionnaireId
        ? fetchQuestionnaireBaseDetailsDone(data)
        : fetchQuestionnaireDetailsDone(data)
    },
    action,
  })
}

const _fetchQuestionnaireDetails = function (body) {
  return fetch(config.SERVER_URL + `/questionnaires/${body.questionnaireId}`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchQuestionnaires(action) {
  yield handler({
    handler: _fetchQuestionnaires,
    error: genericError,
    success: fetchQuestionnairesDone,
    action,
  })
}

const _fetchQuestionnaires = function () {
  return fetch(config.SERVER_URL + '/questionnaires', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateQuestionnaireAnswer(action) {
  yield handler({
    handler: _updateQuestionnaireAnswer,
    error: [
      genericError,
      () => loading({answer: false}),
      () =>
        answerFeedback({
          status: 'error',
          ...action.payload,
        }),
    ],
    success: [
      () =>
        fetchQuestionnaireDetails({
          questionnaireId: action.payload.questionnaireId,
        }),
      () => loading({answer: false}),
      () =>
        answerFeedback({
          status: 'success',
          ...action.payload,
        }),
    ],
    action,
  })
}

const _updateQuestionnaireAnswer = function (body) {
  return fetch(
    `${config.SERVER_URL}/questionnaires/questions/${body.questionId}`,
    {
      method: 'PATCH',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleUploadQuestionnaire(action) {
  yield handler({
    handler: _uploadQuestionnaire,
    error: res => {
      let message

      switch (res.code) {
        case 'CSV_INVALID':
          message = i18next.t('questionnaires.new.messages.error.invalidCsv')
          break
        case 'INVALID_QUESTIONNAIRE_NAME':
          message = i18next.t(
            'questionnaires.new.messages.error.invalidQuestionnaireName'
          )
          break
        case 'INVALID_QUESTIONNAIRE_DESCRIPTION':
          message = i18next.t(
            'questionnaires.new.messages.error.invalidQuestionnaireDescription'
          )
          break
        case 'MISSING_QUESTIONNAIRE_FILE':
          message = i18next.t(
            'questionnaires.new.messages.error.missingQuestionnaireFile'
          )
          break
        case 'FORBIDDEN':
          message = i18next.t('errors.forbiddenError')
          break
        default:
          message = i18next.t('errors.unexpectedError')
          break
      }
      return genericError({loading: {uploadQuestionnaire: false}, message})
    },
    success: [
      () => loading({uploadQuestionnaire: false}),
      res => {
        return res.gotAnswers
          ? uploadQuestionnaireDone(res)
          : redirectFeedback({
              path: '/questionnaires',
              status: 'success',
              message: i18next.t('questionnaires.new.messages.success'),
            })
      },
    ],
    action,
  })
}

const _uploadQuestionnaire = function (body) {
  return fetch(`${config.SERVER_URL}/questionnaires`, {
    method: 'POST',
    credentials: 'include',
    body,
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateQuestionnaire(action) {
  yield handler({
    handler: action.payload.isAnUploadedQuestionnaire
      ? _updateQuestionnaire
      : _updateReceivedQuestionnaire,
    error: genericError,
    success: [
      () => loading({uploadQuestionnaire: false}),
      () => {
        if (
          window.location.pathname === '/questionnaires' ||
          window.location.pathname === '/questionnaires/'
        )
          return success({
            message: i18next.t('questionnaires.updateSuccess'),
          })

        return redirectFeedback({
          path: '/questionnaires',
          status: 'success',
          message: i18next.t('questionnaires.updateSuccess'),
        })
      },
      fetchQuestionnaires,
    ],
    action,
  })
}

const _updateQuestionnaire = function (body) {
  return fetch(`${config.SERVER_URL}/questionnaires/${body.questionnaireId}`, {
    method: 'PATCH',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

const _updateReceivedQuestionnaire = function (body) {
  return fetch(
    `${config.SERVER_URL}/questionnaires/${body.questionnaireId}/submit`,
    {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleRemoveQuestionnaire(action) {
  yield handler({
    handler: _removeQuestionnaire,
    error: genericError,
    success: [
      () => {
        if (
          window.location.pathname === '/questionnaires' ||
          window.location.pathname === '/questionnaires/'
        )
          return success({message: i18next.t('questionnaires.remove.success')})

        return redirectFeedback({
          path: '/questionnaires',
          status: 'success',
          message: i18next.t('questionnaires.remove.success'),
        })
      },
      fetchQuestionnaires,
    ],
    action,
  })
}

const _removeQuestionnaire = function (body) {
  return fetch(`${config.SERVER_URL}/questionnaires/${body.questionnaireId}`, {
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleGenerateAnswer(action) {
  yield handler({
    handler: _generateAnswer,
    error: () =>
      genericError({
        loading: {generatedAnswer: false},
        message: i18next.t('errors.unexpectedError'),
      }),
    success: generateAnswerDone,
    action,
  })
}

const _generateAnswer = function (body) {
  return fetch(
    `${config.SERVER_URL}/questionnaires/questions/${body.questionId}/generated-answers`,
    {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchQuestionReferences(action) {
  yield handler({
    handler: _fetchQuestionReferences,
    error: genericError,
    success: fetchQuestionnaireInvitesDone,
    action,
  })
}

const _fetchQuestionReferences = function (body) {
  return fetch(
    `${config.SERVER_URL}/questionnaires/questions/${body.questionId}/references`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

// TEMPLATES

export function* handleFetchQuestionnaireTemplates(action) {
  yield handler({
    handler: _fetchQuestionnaireTemplates,
    error: genericError,
    success: fetchQuestionnaireTemplatesDone,
    action,
  })
}

const _fetchQuestionnaireTemplates = function () {
  return fetch(config.SERVER_URL + '/questionnaires/templates', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUploadQuestionnaireTemplate(action) {
  yield handler({
    handler: _uploadQuestionnaire,
    error: res => {
      let message

      switch (res.code) {
        case 'CSV_INVALID':
          message = i18next.t('questionnaires.new.messages.error.invalidCsv')
          break
        case 'INVALID_QUESTIONNAIRE_NAME':
          message = i18next.t(
            'questionnaires.new.messages.error.invalidQuestionnaireName'
          )
          break
        case 'INVALID_QUESTIONNAIRE_DESCRIPTION':
          message = i18next.t(
            'questionnaires.new.messages.error.invalidQuestionnaireDescription'
          )
          break
        case 'MISSING_QUESTIONNAIRE_FILE':
          message = i18next.t(
            'questionnaires.new.messages.error.missingQuestionnaireFile'
          )
          break
        case 'FORBIDDEN':
          message = i18next.t('errors.forbiddenError')
          break
        default:
          message = i18next.t('errors.unexpectedError')
          break
      }
      return genericError({
        loading: {uploadQuestionnaireTemplate: false},
        message,
      })
    },
    success: [
      () => loading({uploadQuestionnaireTemplate: false}),
      () =>
        redirectFeedback({
          path: '/suppliers',
          status: 'success',
          message: i18next.t('questionnaires.templates.upload.success'),
        }),
    ],
    action,
  })
}

export function* handleUpdateQuestionnaireTemplate(action) {
  yield handler({
    handler: _updateQuestionnaire,
    error: genericError,
    success: [
      () =>
        success({
          message: i18next.t('questionnaires.templates.edit.success'),
        }),
      fetchQuestionnaireTemplates,
    ],
    action,
  })
}

export function* handleRemoveQuestionnaireTemplate(action) {
  yield handler({
    handler: _removeQuestionnaire,
    error: genericError,
    success: [
      () =>
        success({
          message: i18next.t('questionnaires.templates.remove.success'),
        }),
      fetchQuestionnaireTemplates,
    ],
    action,
  })
}

export function* handleSendQuestionnaire(action) {
  yield handler({
    handler: _sendQuestionnaire,
    error: res => {
      let message

      switch (res.code) {
        case 'QUESTIONNAIRE_ALREADY_SENT':
          message = i18next.t(
            'questionnaires.sendQuestionnaire.alreadySentError'
          )
          break
        default:
          message = i18next.t('errors.unexpectedError')
          break
      }
      return genericError({
        message,
        loading: {sendQuestionnaire: false},
      })
    },
    success: [
      () => loading({sendQuestionnaire: false}),
      () =>
        redirectFeedback({
          path: `/suppliers/${action.payload.supplierId}`,
          status: 'success',
          message: i18next.t('questionnaires.sendQuestionnaire.success'),
        }),
    ],
    action,
  })
}

const _sendQuestionnaire = function (body) {
  return fetch(
    `${config.SERVER_URL}/questionnaires/${body.questionnaireId}/send`,
    {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchQuestionnaireInvites(action) {
  yield handler({
    handler: _fetchQuestionnaireInvites,
    error: genericError,
    success: fetchQuestionnaireInvitesDone,
    action,
  })
}

const _fetchQuestionnaireInvites = function () {
  return fetch(config.SERVER_URL + '/questionnaires/requests', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleRespondQuestionnaireInvite(action) {
  yield handler({
    handler: _respondQuestionnaireInvite,
    error: genericError,
    success: [
      () =>
        success({
          message: i18next.t('questionnaires.invites.successAccepted'),
        }),
      fetchQuestionnaireInvites,
      fetchQuestionnaires,
    ],
    action,
  })
}

const _respondQuestionnaireInvite = function (body) {
  return fetch(`${config.SERVER_URL}/questionnaires/requests`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchSupplierQuestionnaire(action) {
  yield handler({
    handler: _fetchSupplierQuestionnaire,
    error: genericError,
    success: fetchSupplierQuestionnaireDone,
    action,
  })
}

const _fetchSupplierQuestionnaire = function (body) {
  return fetch(
    `${config.SERVER_URL}/suppliers/${body.supplierId}/questionnaires/${body.questionnaireId}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleAnswerReview(action) {
  yield handler({
    handler: _answerReview,
    error: genericError,
    success: [
      () => {
        const {supplierId, questionnaireId} = action.payload

        return fetchSupplierQuestionnaire({
          supplierId,
          questionnaireId,
        })
      },
      () =>
        answersReviewFeedback({
          status: 'success',
          ...action.payload,
        }),
    ],
    action,
  })
}

const _answerReview = function (body) {
  return fetch(
    `${config.SERVER_URL}/suppliers/${body.supplierId}/answers/${body.answerId}/review`,
    {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleQuestionnaireReview(action) {
  yield handler({
    handler: _questionnaireReview,
    error: genericError,
    success: [
      () => {
        let message

        switch (action.payload.decision) {
          case 'approved':
            message = i18next.t(
              'questionnaires.review.messages.approvedSuccess'
            )
            break

          case 'changesRequested':
            message = i18next.t(
              'questionnaires.review.messages.changesRequestedSuccess'
            )
            break
        }

        return success({message})
      },
      questionnaireReviewDone,
      () => {
        const {supplierId, questionnaireId} = action.payload

        return fetchSupplierQuestionnaire({
          supplierId,
          questionnaireId,
        })
      },
    ],
    action,
  })
}

const _questionnaireReview = function (body) {
  return fetch(
    `${config.SERVER_URL}/suppliers/${body.supplierId}/questionnaires/${body.questionnaireId}/review`,
    {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}
