import i18next from 'i18next'

export const findingsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.title'),
        accessor: 'title',
      },
      {
        Header: 'ARN',
        accessor: 'arn',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 60,
      },
      {
        Header: i18next.t('tables.severity'),
        accessor: 'severity',
        width: 50,
      },
      {
        Header: i18next.t('tables.seen'),
        accessor: 'bestDate',
        classes: 'invisible lg:visible',
        width: 50,
        sortType: (a, b) => {
          return new Date(a.original.updatedAt) - new Date(b.original.updatedAt)
        },
      },
    ],
  },
]

export const cloudtrailTrailsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.name'),
        accessor: 'data.Name',
      },
      {
        Header: i18next.t('Trail ARN'),
        accessor: 'data.TrailARN',
      },
      {
        Header: i18next.t('Home region'),
        accessor: 'data.HomeRegion',
      },
    ],
  },
]

export const iamPoliciesColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.name'),
        accessor: 'data.PolicyName',
      },
      {
        Header: i18next.t('tables.attachmentCount'),
        accessor: 'data.AttachmentCount',
      },
      {
        Header: i18next.t('tables.dateCreated'),
        accessor: 'data.CreateDate',
      },
    ],
  },
]

export const iamUsersColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.username'),
        accessor: 'data.user',
      },
      {
        Header: 'MFA',
        accessor: 'data.mfa_active',
      },
      {
        Header: i18next.t('tables.accessKeyAge'),
        accessor: 'accessKeyAge',
      },
      {
        Header: i18next.t('tables.passwordAge'),
        accessor: 'data.password_last_changed',
      },
      {
        Header: i18next.t('tables.lastActivity'),
        accessor: 'lastActivity',
      },
      {
        Header: i18next.t('tables.dateCreated'),
        accessor: 'data.user_creation_time',
      },
    ],
  },
]

export const s3Columns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.bucketName'),
        accessor: 'data.Name',
      },
      {
        Header: i18next.t('tables.access'),
        accessor: 'data.access',
      },
      {
        Header: i18next.t('tables.region'),
        accessor: 'data.LocationConstraint',
      },
      {
        Header: i18next.t('tables.dateCreated'),
        accessor: 'data.CreationDate',
        sortType: (a, b) => {
          return (
            new Date(a.values.creationDate) - new Date(b.values.creationDate)
          )
        },
      },
    ],
  },
]

export const rdsInstancesColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.instanceId'),
        accessor: 'data.DBInstanceIdentifier',
      },
      {
        Header: i18next.t('tables.dbName'),
        accessor: 'data.DBName',
      },
      {
        Header: i18next.t('tables.instanceClass'),
        accessor: 'data.DBInstanceClass',
      },
      {
        Header: i18next.t('tables.instanceStatus'),
        accessor: 'data.DBInstanceStatus',
      },
      {
        Header: i18next.t('tables.dateCreated'),
        accessor: 'data.InstanceCreateTime',
      },
    ],
  },
]

export const rdsSnapshotsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('ARN'),
        accessor: 'data.DBSnapshotArn',
      },
      {
        Header: i18next.t('Port'),
        accessor: 'data.Port',
      },
      {
        Header: i18next.t('Engine'),
        accessor: 'data.Engine',
      },
      {
        Header: i18next.t('Encrypted'),
        accessor: 'data.Encrypted',
      },
      {
        Header: i18next.t('tables.dateCreated'),
        accessor: 'data.SnapshotCreateTime',
      },
    ],
  },
]

export const kmsKeysColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Key ID',
        accessor: 'data.KeyId',
      },
      {
        Header: 'Key ARN',
        accessor: 'data.KeyArn',
      },
    ],
  },
]

export const ec2AmisColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.name'),
        accessor: 'data.Name',
      },

      {
        Header: 'Image ID',
        accessor: 'data.ImageId',
      },
      {
        Header: 'Public',
        accessor: 'data.Public',
      },
      {
        Header: 'Platform details',
        accessor: 'data.PlatformDetails',
      },
    ],
  },
]

export const ec2InstancesColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.instanceId'),
        accessor: 'instanceId',
      },
      {
        Header: i18next.t('tables.name'),
        accessor: 'name',
      },
      {
        Header: i18next.t('tables.publicDns'),
        accessor: 'publicDnsName',
      },
      {
        Header: i18next.t('tables.status'),
        accessor: 'machineState',
      },
      {
        Header: i18next.t('tables.instanceType'),
        accessor: 'instanceType',
      },
      {
        Header: i18next.t('tables.launchTime'),
        accessor: 'launchTime',
      },
    ],
  },
]

export const ec2SecurityGroupsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Group name',
        accessor: 'data.GroupName',
      },
      {
        Header: 'Group ID',
        accessor: 'data.GroupId',
      },
      {
        Header: 'Owner ID',
        accessor: 'data.OwnerId',
      },
      {
        Header: 'Description',
        accessor: 'data.Description',
      },
    ],
  },
]

export const ec2VpcsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'Name',
        accessor: 'data.name',
      },
      {
        Header: 'VPC ID',
        accessor: 'data.VpcId',
      },
      {
        Header: 'CIDR block',
        accessor: 'data.CidrBlock',
      },
      {
        Header: 'Is default',
        accessor: 'data.IsDefault',
      },
    ],
  },
]

export const ebsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.volumeId'),
        accessor: 'data.VolumeId',
      },
      {
        Header: i18next.t('tables.encrypted'),
        accessor: 'data.Encrypted',
      },
      {
        Header: i18next.t('tables.volumeType'),
        accessor: 'data.VolumeType',
      },
      {
        Header: i18next.t('tables.createTime'),
        accessor: 'data.CreateTime',
      },
    ],
  },
]

export const efsColumns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.name'),
        accessor: 'data.Name',
      },
      {
        Header: i18next.t('tables.encrypted'),
        accessor: 'data.Encrypted',
      },
      {
        Header: i18next.t('File System ID'),
        accessor: 'data.FileSystemId',
      },
    ],
  },
]

export const route53Columns = [
  {
    id: 1,
    columns: [
      {
        Header: i18next.t('tables.domainName'),
        accessor: 'data.DomainName',
      },
      {
        Header: i18next.t('tables.autoRenew'),
        accessor: 'data.AutoRenew',
      },
      {
        Header: i18next.t('tables.transferLock'),
        accessor: 'data.TransferLock',
      },
      {
        Header: i18next.t('tables.expiry'),
        accessor: 'data.Expiry',
      },
    ],
  },
]

export const route53HostedZonesColumns = [
  {
    id: 1,
    columns: [
      {
        Header: 'ID',
        accessor: 'data.Id',
      },
      {
        Header: i18next.t('tables.name'),
        accessor: 'data.Name',
      },
      {
        Header: i18next.t('tables.privateZone'),
        accessor: 'data.Config.PrivateZone',
      },
      {
        Header: i18next.t('tables.resourceRecordSetCount'),
        accessor: 'data.ResourceRecordSetCount',
      },
    ],
  },
]
