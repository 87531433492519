import PropTypes from 'prop-types'
import React, {useState} from 'react'
import i18next from 'i18next'
import {v4 as uuidv4} from 'uuid'

import images from 'res'
import {sideral300, tooltipStyle} from 'constant'

export default function ServicesCell({cell, row}) {
  const [expanded, setExpanded] = useState({})

  const handleViewMore = row => {
    expanded[row.id] = true
    setExpanded({...expanded})
  }

  if (!cell.value || !cell.value.length)
    return (
      <td
        className="py-2 pr-2 text-center"
        {...cell.getCellProps()}
        key={uuidv4()}>
        {'—'}
      </td>
    )

  // set to duplicated port/protocol pairs
  const set = new Set()

  const services = cell.value
    .filter(x => {
      if (!x) {
        return false
      }

      if (set.has(x.port + x.protocol)) {
        return false
      }

      set.add(x.port + x.protocol)

      return true
    })
    .map(service => {
      // if there is at least one service live we will not show the icon
      service.status = cell.value.find(
        s =>
          s.port === service.port &&
          s.protocol === service.protocol &&
          s.status === 'live'
      )
        ? 'live'
        : 'idle'
      return service
    })
    .sort((a, b) => {
      return a.port - b.port
    })

  return (
    <td
      className="py-2 pr-2 text-center break-all"
      {...cell.getCellProps()}
      key={uuidv4()}>
      <>
        {services.map((service, index) => {
          const limit = 3

          if (index === limit && !expanded[row.id])
            return (
              <div
                key={uuidv4()}
                className="text-indigo-600 cursor-pointer mr-1"
                onClick={() => handleViewMore(row)}>
                view more
              </div>
            )

          if (index > limit && !expanded[row.id]) {
            return ''
          }

          return (
            <div key={uuidv4()} className="justify-center flex mr-1">
              {service.port}/{service.protocol}
              {index % limit === 0 ? <br /> : <></>}
              <div
                className={`${
                  service.status === 'idle' ? 'block' : 'hidden'
                } tooltip cursor-pointer mb-px`}>
                <figure className="flex items-center justify-center ml-px mt-px pt-px">
                  <images.InfoIcon color={sideral300} width="18" />
                </figure>
                <span
                  className={`${tooltipStyle.default} text-xs break-keep transform -translate-x-1/2 -translate-y-full -mt-5`}>
                  {i18next.t('network.errors.serviceNotResponding')}
                </span>
              </div>
            </div>
          )
        })}
      </>
    </td>
  )
}

ServicesCell.propTypes = {
  cell: PropTypes.object,
  row: PropTypes.object,
}
