const common = {
  awsIntegrationVersion: 5.0,
  azureIntegrationVersion: 3.0,
  gcpIntegrationVersion: 3.0,

  invite: {
    daysToExpire: 7,
  },

  landingPage: 'https://www.unxpose.com',

  ipAddresses: [
    '44.219.60.35',
    '52.21.63.121',
    '54.235.221.227',
    '98.82.65.164',
  ],

  assetsPaths: {
    network: '/network/services',
  },

  findingsPaths: {
    dns: '/dns/finding',
    network: '/network/finding',
    web: '/web/finding',
    azure: '/azure/finding',
    aws: '/aws/finding',
    gcp: '/gcp/finding',
    people: '/people/findings',
  },
}

const configuration = {
  development: {
    ...common,
    cloud: {
      cloudFormation: {
        templateUrl:
          'https://s3.amazonaws.com/unxpose-public-staging/cloudformation.template',
      },
    },
    env: 'development',
    CLIENT_URL: 'http://localhost:3000',
    SERVER_URL: 'http://localhost:3001',
  },
  staging: {
    ...common,
    cloud: {
      cloudFormation: {
        templateUrl:
          'https://s3.amazonaws.com/unxpose-public-staging/cloudformation.template',
      },
    },
    env: 'staging',
    CLIENT_URL: 'https://app.unxpose.qa',
    SERVER_URL: 'https://api.unxpose.qa',
  },
  production: {
    ...common,
    cloud: {
      cloudFormation: {
        templateUrl:
          'https://s3.amazonaws.com/unxpose-public/cloudformation.template',
      },
    },
    env: 'production',
    CLIENT_URL: 'https://app.unxpose.com',
    SERVER_URL: 'https://api.unxpose.com',
  },
}

let config

if (process.env.REACT_APP_ENV) {
  config = configuration[process.env.REACT_APP_ENV]
} else {
  config = configuration[process.env.NODE_ENV || 'development']
}

module.exports = config
