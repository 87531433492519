import i18next from 'i18next'
import React from 'react'

import Footer from './Footer'

export default function People() {
  return (
    <>
      <div className="bg-white text-sm w-full overflow-auto py-4 px-8">
        <div className="flex">
          <div className="w-full text-sm">
            <h1 className="font-medium my-2 text-lg">People</h1>

            <p className="my-4">{i18next.t('help.people.firstParagraph')}</p>

            <p className="mb-4">
              <p className="my-4">{i18next.t('help.people.secondParagraph')}</p>
            </p>

            <p className="mb-4">
              <p className="my-4">{i18next.t('help.people.thirdParagraph')}</p>
            </p>

            <p className="mb-4">
              <p className="my-4">{i18next.t('help.people.fourthParagraph')}</p>
            </p>

            <p className="mb-4">
              <p className="my-4">{i18next.t('help.people.fifthParagraph')}</p>
            </p>

            <p className="mb-4">
              <p className="my-4">
                {i18next.t('help.people.scoreAlgorithmList.intro')}
              </p>
            </p>
            <ul className="mb-4 ml-4">
              <li>{i18next.t('help.people.scoreAlgorithmList.firstItem')}</li>
              <li>{i18next.t('help.people.scoreAlgorithmList.secondItem')}</li>
              <li>{i18next.t('help.people.scoreAlgorithmList.thirdItem')}</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
