export function formatDnsRecords(records) {
  if (!Array.isArray(records)) return []

  return records.map(record => {
    if (typeof record.type !== 'string') {
      return {}
    }

    switch (record.type.toLowerCase()) {
      case 'a':
        return formatARecord(record)
      case 'caa':
        return formatCaaRecord(record)
      case 'mx':
        return formatMxRecord(record)
      case 'ns':
        return formatNsRecord(record)
      case 'soa':
        return formatSoaRecord(record)
      case 'txt':
        return formatTxtRecord(record)
      default:
        return record
    }
  })
}

const formatARecord = record => {
  if (!record.data) return record

  const formattedData = JSON.stringify(record.data, null, 2)
    .replace(/[\\[\]{}"]/g, '')
    .replace(/,/g, ', ')

  return {...record, data: formattedData}
}

const formatCaaRecord = record => {
  if (typeof record.data !== 'string') return record

  try {
    const parsedData = JSON.parse(record.data)

    if (!Array.isArray(parsedData)) {
      throw new Error('Record data is not an array')
    }

    const formattedData = parsedData.map(item => {
      const {flags = 0, tag = '', value = ''} = item.data
      return `${flags} ${tag} "${value}"`
    })

    return {
      ...record,
      data: formattedData,
    }
  } catch (e) {
    return record
  }
}

const formatMxRecord = record => {
  if (typeof record.data !== 'string') return record

  try {
    const parsedData = JSON.parse(record.data)

    if (!Array.isArray(parsedData)) {
      throw new Error('Record data is not an array')
    }

    const formattedData = parsedData.map(item => {
      const {priority = 0, exchange = ''} = item
      return `${priority} ${exchange}`
    })

    return {...record, data: formattedData}
  } catch (e) {
    return record
  }
}

const formatNsRecord = record => {
  if (typeof record.data !== 'string') return record

  try {
    const parsedData = JSON.parse(record.data)

    return {...record, data: parsedData}
  } catch (e) {
    return record
  }
}

const formatSoaRecord = record => {
  if (typeof record.data !== 'string') return record

  try {
    const parsedData = JSON.parse(record.data)

    if (Array.isArray(parsedData)) {
      throw new Error('Record data is not an object')
    }

    const {nsname = '', hostmaster = ''} = parsedData
    const formattedData = `${nsname} ${hostmaster}`

    return {...record, data: formattedData}
  } catch (e) {
    return record
  }
}

const formatTxtRecord = record => {
  if (typeof record.data !== 'string') return record

  try {
    const parsedData = JSON.parse(record.data)

    const formattedData = parsedData.map(item => {
      return item[0]
    })

    return {...record, data: formattedData}
  } catch (e) {
    return record
  }
}

export function parseAndJoin(data) {
  try {
    const parsed = JSON.parse(data)
    if (Array.isArray(parsed)) {
      return parsed.join(', ')
    }
    return data
  } catch (err) {
    return data
  }
}
