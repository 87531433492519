import React, {useState} from 'react'
import i18next from 'i18next'

import config from 'config'

import Footer from './Footer'

export default function Webhooks() {
  const getExamples = () => {
    const examples = {}

    const baseObject = {
      id: 'b5cc327c-b468-4efa-81e5-0bf7bb0dd914',

      severity: i18next.t('severity.high').toUpperCase(),
      createdAt: new Date().toISOString(),

      description: i18next.t('help.webhooks.webhookExample.description'),
      solution: i18next.t('help.webhooks.webhookExample.solution'),
      references: i18next.t('help.webhooks.webhookExample.references'),

      text: i18next.t('help.webhooks.webhookExample.text'),
    }

    const details = {
      aws: {
        category: 'aws',
        asset: 'arn:aws:iam::000000000000:732647b5-35c0-45db-8ea5-d9aa5e44da93',
        assetDetails: {
          aws: {
            arn: 'arn:aws:iam::000000000000:732647b5-35c0-45db-8ea5-d9aa5e44da93',
            accountId: '123456789999',
          },
        },
        severity: i18next.t('severity.high').toUpperCase(),
        findingUrl: `${config.CLIENT_URL}/aws/finding/b5cc327c-b468-4efa-81e5-0bf7bb0dd914`,
        title: 'MFA For IAM Users With Console Password',
      },
      azure: {
        category: 'azure',
        asset: '32433bde-1dda-4a17-954d-f1ccb1934c01',
        assetDetails: {
          azure: {
            resourceId:
              '/subscriptions/12345678-90ab-cdef-ghij-klmnopqrstuv/resourceGroups/myResourceGroup/providers/Microsoft.Compute/virtualMachines/myVM',
            resourceGroupId:
              '/subscriptions/12345678-90ab-cdef-ghij-klmnopqrstuv/resourceGroups/myResourceGroup',
            subscriptionId: '12345678-90ab-cdef-ghij-klmnopqrstuv',
          },
        },
        findingUrl: `${config.CLIENT_URL}/azure/finding/b5cc327c-b468-4efa-81e5-0bf7bb0dd914`,
        title: 'VM Instance with public IP address',
      },
      dns: {
        category: 'dns',
        asset: 'acme.com',
        assetDetails: {
          dns: {
            domain: 'acme.com',
          },
        },

        findingUrl: `${config.CLIENT_URL}/dns/finding/b5cc327c-b468-4efa-81e5-0bf7bb0dd914`,
        title: 'DMARC not configured',
      },
      gcp: {
        category: 'gcp',
        asset: 'instance-1',
        assetDetails: {
          gcp: {
            projectId: 'myproject-123456',
            name: 'instance-1',
          },
        },
        findingUrl: `${config.CLIENT_URL}/gcp/finding/b5cc327c-b468-4efa-81e5-0bf7bb0dd914`,
        title: 'User-managed keys in service account',
      },
      network: {
        category: 'network',
        asset: 'https://acme.com',
        assetDetails: {
          network: {
            host: 'acme.com',
            port: '',
            protocol: 'https',
          },
        },
        severity: i18next.t('severity.high').toUpperCase(),
        findingUrl: `${config.CLIENT_URL}/network/finding/b5cc327c-b468-4efa-81e5-0bf7bb0dd914`,
        title: 'TLS 1.1 Deprecated Protocol',
      },
      people: {
        category: 'people',
        asset: 'johndoe@acme.com',
        assetDetails: {
          people: {
            email: 'johndoe@acme.com',
          },
        },

        title: 'Found in Adobe breach from 2013',
        findingUrl: `${config.CLIENT_URL}/people/finding/b5cc327c-b468-4efa-81e5-0bf7bb0dd914`,
      },
      web: {
        category: 'web',
        asset: 'https://acme.com',
        assetDetails: {
          web: {
            host: 'acme.com',
            path: '',
            protocol: 'https',
            port: 443,
            query: '',
          },
        },
        findingUrl: `${config.CLIENT_URL}/web/finding/b5cc327c-b468-4efa-81e5-0bf7bb0dd914`,
        title: 'Directory listing enabled',
      },
    }

    for (const key in details) {
      examples[key] = {...baseObject, ...details[key]}
    }

    return examples
  }

  const [selectedExample, setSelectedExample] = useState('aws')

  const renderExamplesList = examples => {
    const uppercase = ['aws', 'dns', 'gcp']

    return Object.keys(examples).map((key, index) => {
      const isSelected = selectedExample === key

      const textTransformation = uppercase.includes(key)
        ? 'uppercase'
        : 'capitalize'

      return (
        <div
          onClick={() => setSelectedExample(key)}
          className={`w-full cursor-pointer text-sm border-b-2 py-1 px-1  ${
            isSelected
              ? 'text-sky-500 border-sky-500 bg-white'
              : 'text-sky-900 border-sideral-50 bg-sideral-50 hover:text-sky-800'
          } ${textTransformation} ${index ? 'ml-2' : ''}`}
          key={key}>
          {key}
        </div>
      )
    })
  }

  return (
    <>
      <div className="py-4 px-8">
        <div className="flex">
          <div className="w-full text-sm">
            <h1 className="font-medium mt-2 mb-4 text-lg">
              {i18next.t('help.webhooks.menu.title')}
            </h1>
            <p className="mb-4">
              {i18next.t('help.webhooks.unxposeWebhooks.firstParagraph')}
            </p>
            <div className="mb-2">
              {i18next.t('help.webhooks.unxposeWebhooks.secondParagraph')}
            </div>
            <div className="flex text-center bg-sideral-50">
              {renderExamplesList(getExamples())}
            </div>
            <pre className="text-mono overflow-auto text-xs border p-2 bg-gray-100">
              {JSON.stringify(getExamples()[selectedExample], null, 2)}
            </pre>

            <h1 className="font-medium text-lg mt-2 mb-4">
              {i18next.t('help.webhooks.securityConsiderations.title')}
            </h1>
            <h2 className="font-medium my-2 text-normal">
              {i18next.t(
                'help.webhooks.securityConsiderations.encryption.title'
              )}
            </h2>
            <p className="mb-4">
              {i18next.t(
                'help.webhooks.securityConsiderations.encryption.firstParagraph'
              )}
            </p>
            <div className="mb-4">
              {i18next.t(
                'help.webhooks.securityConsiderations.encryption.secondParagraph'
              )}
              <pre className="text-mono overflow-auto text-xs border p-2 bg-gray-100 mt-2">
                {JSON.stringify(
                  {
                    iv: 'e10003a0d6e719c37379b2a1765e31e7',
                    encryptedData:
                      'e9c87618ad1b7503fd195e551a1e7c21c09545a194c3beee0a2046aa372367c29d65ac57c9ba110eeed82c79d3cc51fd0e50c32b91a770d465750c4e29a64e66c543fd3f3ed9c1a6c9a677e682ecb1d21d4aac1bc7466d2f9257916ca73d8a51de94b2d5c41067575b3f6f653ef189d5cf4057942cac2d5e9af1ccf28e6c83aa386714d24da19fa14430bdd2c23ccb2ddcef11aa059d3726fdab216dfe14124be425fa66cdf7a1d671a504fc269789f8d4af29c3ff7d5766cc37907cacc74a0cbde465684f42ce30fca2dd1ca8e67bda96ebf88688e135877d7140de133270fe71ce1d14fbf11a881fcb1c4515679926f5675680fcd74f191a596b4df9545d1df62118ebb3edda73d68ebd8205ea63433a065129ca28d55016373d142ad4c07c8fce42b6f3d7797d66a4f872cad5967c29c996c105bdfa23c8265ebe5d94e769e1580d469d6a1fb98e45c4d7ceb9200125b7a51f810ea97bd5ed21f3f7d825bea7c2fed86736c75efa6c47cbc2933cc02bb1e7a4e6c71fb8308d4ab07f77784a3f3a8c99e5c2db17543cf5dd5c299e795983ea999c6343f6297e310106ff208ad1a7093d6758cb083f1c62c653d92c318e524d407530ab99f758605c5ea1c7023209d06b0f761a6110cfffd669b7d19c17e7941369ff1fdbd06755c581f1fe72',
                  },
                  null,
                  2
                )}
              </pre>
            </div>
            <h2 className="font-medium my-2 text-normal">
              {i18next.t(
                'help.webhooks.securityConsiderations.validatingOrigin.title'
              )}
            </h2>
            <p className="mb-4">
              {i18next.t(
                'help.webhooks.securityConsiderations.validatingOrigin.firstParagraph'
              )}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
