import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {skeletonLoadingStyle} from 'constant'

import SecondaryButton from './buttons/SecondaryButton'

export default function StatsBox({
  classContainer,
  title,
  data,
  secondaryData,
  label,
  secondaryLabel,
  slots,
  link,
}) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (data !== null && data !== undefined) setLoading(false)
  }, [data])

  switch (slots) {
    case 1:
      return (
        <div
          className={`${classContainer} relative flex flex-wrap w-full md:mb-0`}>
          {link ? (
            <Link to={link.onClick()} className="absolute top-0 right-0 m-4">
              <SecondaryButton text={link.text} direction="right" size="sm" />
            </Link>
          ) : (
            ''
          )}
          <div className="flex flex-wrap rounded-lg border-2 border-ink bg-white w-full p-4">
            <h2 className="w-full text-astral-900 text-lg font-medium">
              {title.toUpperCase()}
            </h2>
            <div className="mt-4 w-full">
              {loading ? (
                <span
                  className={`${skeletonLoadingStyle} block w-16 h-12`}></span>
              ) : (
                <span className="text-sideral-900 w-full text-5xl font-medium">
                  {data ? data : 0}
                </span>
              )}
            </div>
          </div>
        </div>
      )
    case 2:
      return (
        <div className={`${classContainer} relative flex flex-wrap lg:mb-0`}>
          <div className="flex flex-wrap rounded-lg border-2 border-ink bg-white w-full p-4">
            {title && (
              <h2 className="w-full text-astral-900 font-medium text-lg">
                {title.toUpperCase()}
              </h2>
            )}

            <>
              <div className="mt-4 w-1/2">
                <span className="text-sideral-900 w-full text-5xl font-medium">
                  {loading ? (
                    <div className="mt-4 w-10 h-16">
                      <span
                        className={`${skeletonLoadingStyle} block w-16 h-12`}
                      />
                    </div>
                  ) : data ? (
                    data
                  ) : (
                    0
                  )}
                </span>
                <p className="text-sideral-300 font-light text-lg">
                  {label.toUpperCase()}
                </p>
              </div>
              <span className="absolute left-1/2 bottom-0 w-2px h-1/2 bg-sideral-50 mb-6"></span>
              <div className="mt-4 w-1/2 pl-10">
                {loading ? (
                  <div className="mt-4 w-10 h-16">
                    <span
                      className={`${skeletonLoadingStyle} block w-16 h-12`}
                    />
                  </div>
                ) : (
                  <span className="text-sideral-900 w-full text-5xl font-medium">
                    {secondaryData ? secondaryData : 0}
                  </span>
                )}
                <p className="text-sideral-300 font-light text-lg">
                  {secondaryLabel.toUpperCase()}
                </p>
              </div>
            </>
          </div>
        </div>
      )
  }
}

StatsBox.propTypes = {
  classContainer: PropTypes.string,
  slots: PropTypes.oneOf([1, 2]),
  title: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondaryData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  secondaryLabel: PropTypes.string,
  link: PropTypes.object,
}
