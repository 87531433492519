import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import Moment from 'react-moment'
import {sky500} from 'constant'

import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import EditIcon from 'res/icons/editIcon'
import TrashIcon from 'res/icons/trashIcon'
import Spinner from 'components/partials/Spinner'
import SupplierEditModal from './SupplierEditModal'
import SupplierRemoveModal from './SupplierRemoveModal'
import {fetchSupplierDetails, loading} from 'actions'
import {isLoading} from 'utils'

export default function SuppliersList() {
  const dispatch = useDispatch()

  const suppliersState = useSelector(state => state.suppliers)
  const supplierDetailsState = useSelector(state => state.supplierDetails)
  const loadingState = useSelector(state => state.loading)
  const updateSupplierDoneState = useSelector(state => state.updateSupplierDone)

  const [editModal, setEditModal] = useState({
    selected: null,
    showModal: false,
  })

  const [removeModal, setRemoveModal] = useState({
    selected: null,
    showModal: false,
  })

  const handleDisplayEditModal = ({id}) => {
    dispatch(loading({supplierDetails: true}))
    dispatch(fetchSupplierDetails({supplierId: id}))

    setEditModal({
      showModal: true,
    })
  }

  const handleDisplayRemoveModal = ({id, name}) => {
    setRemoveModal({
      selected: {
        id,
        name,
      },
      showModal: true,
    })
  }

  useEffect(() => {
    if (updateSupplierDoneState)
      setEditModal({
        selected: null,
        showModal: false,
      })
  }, [updateSupplierDoneState])

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: i18next.t('suppliers.registeredSuppliers.table.supplier'),
        minSize: 250,
        meta: {
          align: 'left',
        },
      },

      {
        id: 'questionnairesCount',
        accessorKey: 'questionnairesCount',
        header: i18next.t('suppliers.registeredSuppliers.table.questionnaires'),
      },

      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: i18next.t('suppliers.registeredSuppliers.table.created'),
        cell: cell => <Moment fromNow>{cell.getValue()}</Moment>,
      },

      {
        id: 'actionButtons',
        accessorKey: 'id',
        header: '',
        maxSize: 10,
        cell: cell => (
          <div className="flex items-center justify-end z-100">
            <div className="mx-2">
              <button
                title={i18next.t('misc.edit')}
                onClick={() => {
                  handleDisplayEditModal({
                    id: cell.getValue(),
                    name: cell.row.original.name,
                  })
                }}>
                <EditIcon height="22" width="22" color={sky500} />
              </button>
            </div>

            <div className="mx-2">
              <button
                title={i18next.t('misc.remove')}
                onClick={() => {
                  handleDisplayRemoveModal({
                    id: cell.getValue(),
                    name: cell.row.original.name,
                  })
                }}>
                <TrashIcon height="22" width="22" color={sky500} />
              </button>
            </div>
          </div>
        ),
        disableGlobalFilter: true,
        isButton: true,
      },
    ],
    []
  )

  if (!suppliersState) return <Spinner />

  return (
    <div className="p-6">
      <PrimaryTableV8
        columns={columns}
        data={suppliersState}
        detailPath={row => `/suppliers/${row.original.id}`}
        emptyStateWithFilter={
          <GenericEmptyState
            icon={<i className={`icofont-list text-6xl text-sideral-300`} />}
            title={i18next.t('tables.nothingFoundWithSearch')}
            body={i18next.t('tables.searchSomethingElse')}
          />
        }
        emptyStateWithoutFilter={
          <GenericEmptyState
            icon={<i className={`icofont-list text-6xl text-sideral-300`} />}
            title={i18next.t('tables.noResultsFound')}
            body={i18next.t('suppliers.registeredSuppliers.noSuppliers')}
          />
        }
      />

      {editModal.showModal && (
        <SupplierEditModal
          supplierDetails={supplierDetailsState}
          setEditModal={setEditModal}
          loadingContent={isLoading(loadingState.supplierDetails)}
        />
      )}

      {removeModal.showModal && (
        <SupplierRemoveModal
          supplierDetails={removeModal.selected}
          setRemoveModal={setRemoveModal}
        />
      )}
    </div>
  )
}
