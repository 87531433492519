import React from 'react'
import PropTypes from 'prop-types'

export default function InformationIcon({width, height, color, opacity}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path
          d="M10.36 9.01331L8.30667 7.30664L1.09334 16L8.30667 24.6933L10.36 22.9866L4.56 16L10.36 9.01331ZM23.6933 7.30664L21.64 9.01331L27.44 16L21.64 22.9866L23.6933 24.6933L30.9067 16L23.6933 7.30664Z"
          fill={color}
        />
        <line
          x1="19.452"
          y1="2.37644"
          x2="12.452"
          y2="29.3764"
          stroke={color}
          strokeWidth="3"
        />
      </g>
    </svg>
  )
}

InformationIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
}
