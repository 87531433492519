import React from 'react'
import PropTypes from 'prop-types'

export default function HelpIcon({width, height, color, opacity}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path
          d="M14.6667 24.0001H17.3333V21.3334H14.6667V24.0001ZM16 2.66675C8.63999 2.66675 2.66666 8.64008 2.66666 16.0001C2.66666 23.3601 8.63999 29.3334 16 29.3334C23.36 29.3334 29.3333 23.3601 29.3333 16.0001C29.3333 8.64008 23.36 2.66675 16 2.66675ZM16 26.6667C10.12 26.6667 5.33332 21.8801 5.33332 16.0001C5.33332 10.1201 10.12 5.33341 16 5.33341C21.88 5.33341 26.6667 10.1201 26.6667 16.0001C26.6667 21.8801 21.88 26.6667 16 26.6667ZM16 8.00008C13.0533 8.00008 10.6667 10.3867 10.6667 13.3334H13.3333C13.3333 11.8667 14.5333 10.6667 16 10.6667C17.4667 10.6667 18.6667 11.8667 18.6667 13.3334C18.6667 16.0001 14.6667 15.6667 14.6667 20.0001H17.3333C17.3333 17.0001 21.3333 16.6667 21.3333 13.3334C21.3333 10.3867 18.9467 8.00008 16 8.00008Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

HelpIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
}
