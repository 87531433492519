import React from 'react'
import PublicViewBg from './partials/PublicViewBg'
import {Outlet} from 'react-router-dom'

export const FullPageLayout = () => (
  <main id="full-page-layout">
    <Outlet />
  </main>
)

export const BgImagePageLayout = () => (
  <main id="bg-image-page-layout">
    <PublicViewBg />
    <Outlet />
  </main>
)
