import PropTypes from 'prop-types'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {v4 as uuidv4} from 'uuid'
import {toggleRestrictionModal, toggleWebhook} from 'actions'
import {useDispatch, useSelector} from 'react-redux'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {i18next} from 'translate/i18n'
import PlusIcon from 'res/icons/plusIcon'
import CrownIcon from 'res/icons/crownIcon'
import PlanUtils from 'utils/plan'

export function WebhookList(props) {
  const csrfState = useSelector(state => state.csrf)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const planUtils = PlanUtils()

  const handleSelectWebhook = webhook => {
    props.selectWebhook(webhook)
  }

  const handleToggleEnable = webhook => {
    dispatch(
      toggleWebhook({
        ...webhook,
        _csrf: csrfState,
      })
    )
  }

  const handleAdd = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'addWebhookBtn', showModal: true})
      )

    props.showAddScreen(true)
  }

  return (
    <>
      {props.webhooks.length ? (
        <>
          <div className="w-full text-astral-900">
            <div className="flex justify-between dm-mono text-sideral-300 font-medium border-b border-sideral-50 text-xs uppercase p-3">
              <div className="w-2/12">{i18next.t('labels.name')}</div>
              <div className="w-6/12 text-center">Url</div>
              <div className="w-2/12 text-center">
                {i18next.t('labels.lastStatus')}
              </div>
              <div className="w-2/12 text-center">Status</div>
            </div>

            <div className="text-astral-900">
              {props.webhooks.map(webhook => {
                return (
                  <div
                    className="flex border-b border-sideral-50 hover:bg-astral-50 p-3"
                    key={uuidv4()}>
                    <div
                      className="w-2/12"
                      onClick={() => handleSelectWebhook(webhook)}>
                      <span className="cursor-pointer hover:underline">
                        {webhook.name}
                      </span>
                    </div>
                    <div
                      className="w-6/12 text-center truncate"
                      onClick={() => handleSelectWebhook(webhook)}>
                      <span className="cursor-pointer hover:underline">
                        {webhook.url}
                      </span>
                    </div>
                    <div
                      className="w-2/12 text-center"
                      onClick={() => handleSelectWebhook(webhook)}>
                      {webhook.lastStatus ? (
                        <span
                          className={
                            'cursor-pointer hover:underline uppercase px-2 py-1 ' +
                            (webhook.lastStatus === '200'
                              ? 'text-leaf-800'
                              : 'text-mars-700')
                          }>
                          {webhook.lastStatus}
                        </span>
                      ) : (
                        '-'
                      )}
                    </div>

                    <div className="w-2/12 text-center">
                      <span
                        onClick={() => handleToggleEnable(webhook)}
                        className={
                          'cursor-pointer rounded-full uppercase text-xs hover:underline px-3 py-1 ' +
                          (webhook.enabled
                            ? 'bg-spring-50  text-leaf-800'
                            : 'bg-coral-50 text-mars-700')
                        }>
                        {webhook.enabled
                          ? i18next.t('labels.enabled')
                          : i18next.t('labels.disabled')}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="flex justify-end mt-6">
              <PrimaryButton
                text={i18next.t('buttons.addWebhook')}
                onClick={handleAdd}
                icon={
                  planUtils.hasPlanRestriction() ? (
                    <CrownIcon width="20" height="20" />
                  ) : (
                    <PlusIcon width="20" height="20" color="white" />
                  )
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="text-sideral-700">
            <div className="text-sky-900">{i18next.t('titles.noWebhooks')}</div>
            <div className="text-sm">
              {i18next.t('subtitles.noWebhooks')}
              <SecondaryButton
                text={i18next.t('buttons.learnMore')}
                direction="right"
                size="sm"
                onClick={() => navigate('#/help/webhooks')}
                margin="ml-2 mt-2"
              />
            </div>

            <PrimaryButton
              text={i18next.t('buttons.createFirstWebhook')}
              onClick={handleAdd}
              margin="mt-6"
              icon={
                planUtils.hasPlanRestriction() ? (
                  <CrownIcon width="20" height="20" />
                ) : null
              }
            />
          </div>
        </>
      )}
    </>
  )
}

WebhookList.propTypes = {
  selectWebhook: PropTypes.func,
  showAddScreen: PropTypes.func,
  webhooks: PropTypes.array,
}
