import React from 'react'
import PropTypes from 'prop-types'

export default function PeopleIcon({width, height, color, opacity}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path d="M24 16H18.6667V18H24V16Z" fill={color} />
        <path d="M24 20H18.6667V22H24V20Z" fill={color} />
        <path
          d="M26.6667 9.33342H20V5.33341C20 3.86675 18.8 2.66675 17.3333 2.66675H14.6667C13.2 2.66675 12 3.86675 12 5.33341V9.33342H5.33333C3.86667 9.33342 2.66667 10.5334 2.66667 12.0001V26.6667C2.66667 28.1334 3.86667 29.3334 5.33333 29.3334H26.6667C28.1333 29.3334 29.3333 28.1334 29.3333 26.6667V12.0001C29.3333 10.5334 28.1333 9.33342 26.6667 9.33342ZM14.6667 9.33342V5.33341H17.3333V9.33342V12.0001H14.6667V9.33342ZM26.6667 26.6667H5.33333V12.0001H12C12 13.4667 13.2 14.6667 14.6667 14.6667H17.3333C18.8 14.6667 20 13.4667 20 12.0001H26.6667V26.6667Z"
          fill={color}
        />
        <path
          d="M12 20C13.1046 20 14 19.1046 14 18C14 16.8954 13.1046 16 12 16C10.8954 16 10 16.8954 10 18C10 19.1046 10.8954 20 12 20Z"
          fill={color}
        />
        <path
          d="M14.7733 21.5733C13.92 21.2 12.9867 21 12 21C11.0133 21 10.08 21.2 9.22667 21.5733C8.48 21.8933 8 22.6133 8 23.4267V24H16V23.4267C16 22.6133 15.52 21.8933 14.7733 21.5733Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

PeopleIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
}
