import i18next from 'i18next'
import React from 'react'

export default function Footer() {
  return (
    <div className="flex flex-wrap w-full relative self-end items-center text-xs border-t border-gray-300 mt-4 pb-24 pt-8 px-10">
      <div>
        <i className="icofont-ui-message text-xl text-white bg-sky-900 rounded-full p-2 mr-4"></i>
      </div>
      <div>
        <span className="w-full block">{i18next.t('help.footer.title')}</span>
        <a
          href="mailto:support@unxpose.com"
          className="text-sky-500 underline text-sm mx-auto">
          {i18next.t('help.footer.contactUs')}
        </a>
      </div>
    </div>
  )
}
