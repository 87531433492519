import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {Trans, useTranslation} from 'react-i18next'
import i18next from 'i18next'

import {clearError, clearSuccess, loading, signUp} from 'actions'

import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {getDefaultLanguage, isLoading} from 'utils'
import {languages} from 'constant'

import Logo from 'res/brand/logo.svg'
import bgOrangePurple from 'res/backgrounds/bgOrangePurple.svg'

const SignUp = () => {
  const initialFormState = {
    email: '',
    locale: getDefaultLanguage('i18nextLng').value,
    privacyPolicyCheckbox: false,
  }

  const loadingState = useSelector(state => state.loading)
  const successState = useSelector(state => state.success)

  const dispatch = useDispatch()

  const {i18n} = useTranslation()

  const [form, setForm] = useState(initialFormState)

  const handleInputChange = e => {
    const {name} = e.target

    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value

    setForm({...form, [name]: value})
  }

  const handleLocaleChange = e => {
    i18n.changeLanguage(e.value)
    setForm({...form, locale: e.value})
  }

  const handleSubmit = e => {
    e.preventDefault()

    dispatch(loading({signUp: true}))
    dispatch(signUp(form))
  }

  useEffect(() => {
    dispatch(clearError())
    dispatch(clearSuccess())
  }, [])

  useEffect(() => {
    if (successState) {
      setForm(initialFormState)
      window.dataLayer?.push({event: 'selfSignUpEmailSubmit'})
    }
  }, [successState])

  return (
    <div className="relative flex h-screen bg-white">
      <div
        id="left-content"
        className="flex flex-col justify-between basis-full sm:basis-1/2 text-sm p-8">
        <div id="header">
          <div className="font-medium">{i18next.t('signUp.learnMore')}</div>
          <Trans
            i18nKey="signUp.goToSite"
            components={{
              a: getNewTabAnchorTag('https://www.unxpose.com'),
            }}
          />
        </div>

        <div id="middle-left-content" className="hidden lg:block max-w-[40%]">
          <div className="text-2xl mb-3 w-80">
            <Trans
              i18nKey="signUp.callToAction"
              components={{
                span: <span className="text-sky-900" />,
              }}
            />
          </div>
          <div>{i18next.t('signUp.subtitle')}</div>
        </div>

        <div
          id="form-content"
          className="lg:absolute left-1/2 top-1/2 transform lg:-translate-x-1/2 lg:-translate-y-1/2 min-w-full sm:min-w-[450px] my-4 lg:my-0">
          <div className="bg-white border rounded-md p-10">
            <div id="logo">
              <img src={Logo} alt="Unxpose" className="mx-auto w-56" />
            </div>

            <form onSubmit={handleSubmit} className="my-4">
              <PrimaryInput
                testId="email-input"
                text={i18next.t('labels.email')}
                name="email"
                type="email"
                value={form.email}
                onChange={handleInputChange}
                required={true}
                placeholder={i18next.t('placeholders.email')}
              />

              <label htmlFor="policy-privacy" className="flex mb-6">
                <input
                  data-testid="privacy-policy-checkbox"
                  id="policy-privacy"
                  name="privacyPolicyCheckbox"
                  className="accent-sky-900 mr-2"
                  type="checkbox"
                  checked={form.privacyPolicyCheckbox}
                  onChange={handleInputChange}
                  required
                />

                <div className="text-xs">
                  <Trans
                    i18nKey="signUp.policy"
                    components={{
                      a: getNewTabAnchorTag('https://www.unxpose.com/privacy'),
                    }}
                  />
                </div>
              </label>

              <PrimaryButton
                testId="create-account-submit-btn"
                text={i18next.t('signUp.primaryBtn')}
                type="submit"
                size="full"
                loading={isLoading(loadingState.signUp, false)}
              />
            </form>

            <div className="flex items-end justify-center">
              <div className="mr-2 text-sm">
                {i18next.t('signUp.loginCall')}
              </div>
              <Link
                to="/login"
                className="text-sky-900 underline"
                onClick={() => {
                  dispatch(clearError())
                  dispatch(clearSuccess())
                }}>
                {i18next.t('signUp.login')}
              </Link>
            </div>
          </div>
        </div>

        <div id="footer" className="xl:flex justify-between items-end">
          <div className="lg:flex mb-4 xl:mb-0">
            <PrimarySelect
              name="locale"
              defaultValue={getDefaultLanguage('i18nextLng')}
              options={languages}
              marginBottom="0"
              onChange={handleLocaleChange}
              menuPlacement="top"
              isSearchable={false}
            />
          </div>
          <div className="font-light text-sm xs:text-center xl:text-right">
            <Trans
              i18nKey="unxpose.support"
              components={{
                a: (
                  <a
                    href="mailto: support@unxpose.com"
                    className="text-sky-900 font-medium"
                    rel="noreferrer"
                  />
                ),
              }}
            />
            <div className="mt-4 sm:mt-0">{i18next.t('unxpose.slogan')}</div>
          </div>
        </div>
      </div>
      <div
        id="right-content"
        className="hidden sm:block basis-1/2 bg-cover"
        style={{backgroundImage: `url(${bgOrangePurple})`}}
      />
    </div>
  )
}

const getNewTabAnchorTag = url => (
  <a
    href={url}
    target="_blank"
    className="text-sky-900 hover:text-sky-800 underline"
    rel="noreferrer"
  />
)

export default SignUp
