import i18next from 'i18next'
import Moment from 'react-moment'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {useParams} from 'react-router-dom'

import {fetchPeopleAccount, fetchPeopleAccountFindings} from 'actions'
import {sideral300, tooltipStyle, unixEpochDateString} from 'constant'
import {mappedSourcesNames} from './peopleUtils'
import images from 'res'

import ContentBox from 'components/partials/ContentBox'
import TableWrapper from 'components/partials/tables/TableWrapper'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import BackButton from 'components/partials/buttons/BackButton'
import Spinner from 'components/partials/Spinner'
import TableFindings from './TableFindings'
import {Page} from 'components/partials/Page'

export default function AccountDetails() {
  const peopleAccountState = useSelector(state => state.peopleAccount)
  const peopleAccountFindingsState = useSelector(
    state => state.peopleAccountFindings
  )

  const dispatch = useDispatch()
  const params = useParams()

  const [account, setAccount] = useState(null)

  const findingsColumns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('tables.title'),
            accessor: 'title',
            disableSortBy: true,
          },
          {
            Header: i18next.t('labels.email'),
            accessor: 'email',
            width: 80,
            classes: 'pt-6 pb-2 px-4',
          },
          {
            Header: i18next.t('finding.severity'),
            accessor: 'severity',
            width: 80,
            classes: 'pt-6 pb-2 px-4',
          },
          {
            Header: i18next.t('tables.status'),
            accessor: 'status',
            width: 80,
            classes: 'pt-6 pb-2 px-4',
          },
        ],
      },
    ],
    []
  )

  const styles = {
    detailsContainer: 'p-4',
    fieldContainer: 'flex w-full py-1',
    fieldName: 'font-semibold mr-1',
    subtitle: 'text-xs font-normal w-full',
  }

  useEffect(() => {
    dispatch(fetchPeopleAccount(params.id))
    dispatch(fetchPeopleAccountFindings(params.id))
  }, [])

  useEffect(() => {
    if (peopleAccountState) setAccount(peopleAccountState)
  }, [peopleAccountState, peopleAccountFindingsState])

  if (!account || account.id !== params.id) return <Spinner />

  if (!peopleAccountFindingsState) return <Spinner />

  return (
    <Page pageTitle={i18next.t('pageTitles.people')} helpPath="people">
      <BackButton fallbackLink="/people" />
      <ContentBox
        header={i18next.t('people.account')}
        content={<Account styles={styles} account={account} />}
        margin="mt-4"
      />
      {account.microsoft365 ? (
        <ContentBox
          header={'Microsoft 365'}
          content={<Microsoft365 styles={styles} account={account} />}
          margin="mt-6"
        />
      ) : (
        <></>
      )}
      {account.workspaceInfo ? (
        <ContentBox
          header={'Google Workspace'}
          content={<GoogleWorkspace styles={styles} account={account} />}
          margin="mt-6"
        />
      ) : (
        <></>
      )}
      {account.unxposeInfo ? (
        <>
          {account.unxposeInfo.map(unxposeInfo => (
            <ContentBox
              header={'Unxpose'}
              content={<Unxpose styles={styles} unxposeInfo={unxposeInfo} />}
              margin="mt-6"
              key={unxposeInfo.email}
            />
          ))}
        </>
      ) : (
        <></>
      )}

      {peopleAccountFindingsState && peopleAccountFindingsState.data ? (
        <TableWrapper
          id="findingsTable"
          title={i18next.t('misc.findings')}
          loaded={findingsColumns}
          margin={'mt-6'}
          table={
            <TableFindings
              columns={findingsColumns}
              data={
                peopleAccountFindingsState && peopleAccountFindingsState.data
              }
              pageNumber={0}
              history={history}
              emptyStateWithFilter={
                <GenericEmptyState
                  icon={<images.FindingIcon width="55" color={sideral300} />}
                  title={i18next.t('tables.nothingFoundWithSearch')}
                  body={i18next.t('tables.searchSomethingElse')}
                  margin={'m-2 mt-4'}
                />
              }
              emptyStateWithoutFilter={
                <GenericEmptyState
                  icon={<images.PeopleIcon width="55" color={sideral300} />}
                  body={i18next.t('people.noFindings')}
                  margin="m-2"
                />
              }
            />
          }
          isPremiumFeature
        />
      ) : (
        <Spinner />
      )}
    </Page>
  )
}

const Account = ({styles, account}) => {
  return (
    <div className={styles.detailsContainer}>
      {account.name ? (
        <div className={styles.fieldContainer}>
          <span className={styles.fieldName}>
            {i18next.t('people.labels.name')}:
          </span>
          <span>{account.name}</span>
        </div>
      ) : (
        <></>
      )}
      {account.email ? (
        <div className={styles.fieldContainer}>
          <span className={styles.fieldName}>
            {i18next.t('people.labels.email')}:
          </span>
          <span>{account.email}</span>
        </div>
      ) : (
        <></>
      )}
      {account.aliases && account.aliases.length ? (
        <div className={styles.fieldContainer}>
          <span className={styles.fieldName}>
            {i18next.t('people.labels.alias')}:
          </span>
          <span>{account.aliases.join(', ')}</span>
        </div>
      ) : (
        <></>
      )}
      {account.sources && account.sources.length ? (
        <div className={styles.fieldContainer}>
          <span className={styles.fieldName}>
            {i18next.t('people.labels.emailSources')}:
          </span>
          <span>
            {account.sources
              .map(source => mappedSourcesNames[source])
              .join(', ')}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const Microsoft365 = ({styles, account}) => {
  const microsoft365Info = account.microsoft365

  return (
    <div className="flex">
      <div className={styles.detailsContainer + ' w-1/2'}>
        <div className={styles.fieldContainer}>
          <span className={styles.fieldName}>
            {i18next.t('people.labels.name')}:
          </span>
          <span>
            {microsoft365Info.userDisplayName
              ? microsoft365Info.userDisplayName
              : '-'}
          </span>
        </div>

        <div className={styles.fieldContainer}>
          <span className={styles.fieldName}>
            {i18next.t('people.labels.email')}:
          </span>
          <span> {account.email ? account.email : '-'}</span>
        </div>

        {typeof microsoft365Info.admin === 'boolean' ? (
          <div className={styles.fieldContainer}>
            <span className={styles.fieldName}>
              {i18next.t('people.labels.admin')}:
            </span>
            <span>
              {microsoft365Info.isAdmin
                ? i18next.t('misc.yes')
                : i18next.t('misc.no')}
            </span>
          </div>
        ) : (
          <div className={styles.fieldContainer}>
            <span className={styles.fieldName}>
              {i18next.t('people.labels.admin')}:
            </span>
            <span>-</span>
          </div>
        )}

        {typeof microsoft365Info.isMfaCapable === 'boolean' &&
        typeof microsoft365Info.isMfaRegistered === 'boolean' ? (
          <div className={styles.fieldContainer}>
            <span className={styles.fieldName}>
              {i18next.t('people.labels.mfa')}:
            </span>
            <span>
              {!microsoft365Info.isMfaCapable ||
              !microsoft365Info.isMfaRegistered ? (
                <images.AttentionSignal height="20" width="20" />
              ) : (
                <images.SecurityShield height="20" width="20" />
              )}
            </span>
          </div>
        ) : (
          <div className={styles.fieldContainer}>
            <span className={styles.fieldName}>
              {i18next.t('people.labels.mfa')}:
            </span>
            <span>-</span>
          </div>
        )}
      </div>
    </div>
  )
}

const GoogleWorkspace = ({styles, account}) => {
  const imageWithToolTip = (image, message) => (
    <div className="tooltip">
      {image}
      <span className={`${tooltipStyle.default} ml-8 -mt-6 text-xs`}>
        {message}
      </span>
    </div>
  )

  const getBadge = (type, value) => {
    const shield = <images.SecurityShield height="24" width="24" />
    const attention = <images.AttentionSignal height="20" width="20" />
    const securityShieldQuestion = (
      <images.SecurityShieldQuestion height="24" width="24" />
    )

    let image, message

    if (typeof value !== 'boolean' && !value) {
      image = securityShieldQuestion
      message = i18next.t(
        'googleWorkspace.badgesTooltipMessages.informationMissing'
      )

      return imageWithToolTip(image, message)
    }

    if (type === 'mfa') {
      switch (value) {
        case true:
          image = shield
          message = i18next.t(
            'googleWorkspace.badgesTooltipMessages.mfaEnabled'
          )
          break
        case false:
          image = attention
          message = i18next.t(
            'googleWorkspace.badgesTooltipMessages.mfaDisabled'
          )
          break
        default:
          image = securityShieldQuestion
          message = i18next.t(
            'googleWorkspace.badgesTooltipMessages.informationMissing'
          )
      }

      return imageWithToolTip(image, message)
    }

    if (type === 'lessSecureApps') {
      switch (value) {
        case false:
          image = shield
          message = i18next.t(
            'googleWorkspace.badgesTooltipMessages.lessSecureAppsDisabled'
          )
          break
        case true:
          image = attention
          message = i18next.t(
            'googleWorkspace.badgesTooltipMessages.lessSecureAppsEnabled'
          )
          break
        default:
          image = securityShieldQuestion
          message = i18next.t(
            'googleWorkspace.badgesTooltipMessages.informationMissing'
          )
      }

      return imageWithToolTip(image, message)
    }

    if (type === 'passwordStrength') {
      switch (value && value.toLowerCase()) {
        case 'strong':
          image = shield
          message = i18next.t(
            'googleWorkspace.badgesTooltipMessages.strongPassword'
          )
          break

        case 'unknown':
          image = securityShieldQuestion
          message = i18next.t(
            'googleWorkspace.badgesTooltipMessages.informationMissing'
          )
          break

        default:
          image = attention
          message = i18next.t(
            'googleWorkspace.badgesTooltipMessages.weakPassword'
          )
      }

      return imageWithToolTip(image, message)
    }

    if (type === 'passwordCompliant') {
      switch (value && value.toLowerCase()) {
        case 'compliant':
          image = shield
          message = i18next.t(
            'googleWorkspace.badgesTooltipMessages.compliantPassword'
          )
          break

        case 'unknown':
          image = securityShieldQuestion
          message = i18next.t(
            'googleWorkspace.badgesTooltipMessages.informationMissing'
          )
          break

        default:
          image = attention
          message = i18next.t(
            'googleWorkspace.badgesTooltipMessages.notCompliantPassword'
          )
      }

      return imageWithToolTip(image, message)
    }
  }

  return (
    <>
      <div className="p-4 flex flex-wrap">
        <p className="w-full pb-2">{i18next.t('people.general')}</p>
        <div className="flex border rounded-md w-full">
          <div className={styles.detailsContainer + ' w-1/2'}>
            {account.workspaceInfo.name ? (
              <div className={styles.fieldContainer}>
                <span className={styles.fieldName}>
                  {i18next.t('people.labels.name')}:
                </span>
                <span>{account.workspaceInfo.name}</span>
              </div>
            ) : (
              <></>
            )}
            {account.workspaceInfo.email ? (
              <div className={styles.fieldContainer}>
                <span className={styles.fieldName}>
                  {i18next.t('people.labels.email')}:
                </span>
                <span>{account.workspaceInfo.email}</span>
              </div>
            ) : (
              <></>
            )}
            {account.workspaceInfo.aliases &&
            account.workspaceInfo.aliases.length ? (
              <div className={styles.fieldContainer}>
                <span className={styles.fieldName}>
                  {i18next.t('people.labels.alias')}:
                </span>
                <span>
                  <span>{account.workspaceInfo.aliases.join(', ')}</span>
                </span>
              </div>
            ) : (
              <></>
            )}
            {typeof account.workspaceInfo.admin === 'boolean' ? (
              <div className={styles.fieldContainer}>
                <span className={styles.fieldName}>
                  {i18next.t('people.labels.admin')}:
                </span>
                <span>
                  {account.workspaceInfo.admin
                    ? i18next.t('misc.yes')
                    : i18next.t('misc.no')}
                </span>
              </div>
            ) : (
              <></>
            )}
            <div className={styles.fieldContainer + ' flex-wrap'}>
              <span className={styles.fieldName}>
                {i18next.t('people.labels.suspended')}
              </span>
              {account.workspaceInfo.suspended ? (
                <span>{i18next.t('misc.yes')}</span>
              ) : (
                <span>{i18next.t('misc.no')}</span>
              )}
            </div>
            {account.workspaceInfo.suspended &&
            account.workspaceInfo.suspensionReason ? (
              <div className={styles.fieldContainer + ' flex-wrap'}>
                <span className={styles.fieldName}>
                  {i18next.t('people.labels.suspensionReason')}
                </span>
                <span className="lowercase capitalize-first">
                  {account.workspaceInfo.suspensionReason}
                </span>
              </div>
            ) : (
              <></>
            )}
            {account.workspaceInfo.lastLogin ? (
              <div className={styles.fieldContainer}>
                <span className={styles.fieldName}>
                  {i18next.t('people.labels.lastLogin')}:
                </span>
                <span>
                  {account.workspaceInfo.lastLogin === unixEpochDateString ? (
                    i18next.t('tables.never')
                  ) : (
                    <Moment date={account.workspaceInfo.lastLogin} fromNow />
                  )}
                </span>
              </div>
            ) : (
              <></>
            )}
            {account.workspaceInfo.creationTime ? (
              <div className={styles.fieldContainer}>
                <span className={styles.fieldName}>
                  {i18next.t('people.labels.creationTime')}:
                </span>
                <span>
                  {account.workspaceInfo.creationTime ===
                  unixEpochDateString ? (
                    '-'
                  ) : (
                    <Moment date={account.workspaceInfo.creationTime} fromNow />
                  )}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className={styles.detailsContainer}>
            <div className={styles.fieldContainer}>
              <span className={styles.fieldName}>
                {i18next.t('people.labels.mfa')}:
              </span>
              <span>{getBadge('mfa', account.workspaceInfo.mfa)}</span>
            </div>

            <div className={styles.fieldContainer}>
              <span className={styles.fieldName}>
                {i18next.t('people.labels.lessSecureApps')}:
              </span>
              <span>
                {getBadge(
                  'lessSecureApps',
                  account.workspaceInfo.lessSecureApps
                )}
              </span>
            </div>

            <div className={styles.fieldContainer}>
              <span className={styles.fieldName}>
                {i18next.t('people.labels.passwordStrength')}:
              </span>
              <span>
                {getBadge(
                  'passwordStrength',
                  account.workspaceInfo.passwordStrength
                )}
              </span>
            </div>

            <div className={styles.fieldContainer}>
              <span className={styles.fieldName}>
                {i18next.t('people.labels.passwordCompliant')}:
              </span>
              <span>
                {getBadge(
                  'passwordCompliant',
                  account.workspaceInfo.passwordCompliant
                )}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap p-4">
        <span className="flex items-center w-full">
          <p>Google Drive</p>
          <figure className="cursor-pointer tooltip pl-1">
            <p className={`${tooltipStyle.default} ml-6 -mt-10`}>
              {i18next.t('people.subtitles.googleDrive1')}

              <br />
              {i18next.t('people.subtitles.googleDrive2')}
            </p>
            <images.InfoIcon height="20" />
          </figure>
        </span>
        <div
          className={
            styles.detailsContainer + ' border rounded-md mt-2  w-full'
          }>
          <div className={styles.fieldContainer + ' flex-wrap'}>
            <span className={styles.fieldName}>
              {i18next.t('people.labels.filesAnyoneWithLink')}:
            </span>
            <span>{account.workspaceInfo.peopleWithLink || 0}</span>
            <p className={styles.subtitle}>
              {i18next.t('people.subtitles.anyoneWithLink')}
            </p>
          </div>
          <div className={styles.fieldContainer + ' flex-wrap'}>
            <span className={styles.fieldName}>
              {i18next.t('people.labels.publicFiles')}:
            </span>
            <span>{account.workspaceInfo.publicOnTheWeb || 0}</span>
            <p className={styles.subtitle}>
              {i18next.t('people.subtitles.publicOnTheWeb')}
            </p>
          </div>

          <div className={styles.fieldContainer + ' flex-wrap'}>
            <span className={styles.fieldName}>
              {i18next.t('people.labels.filesSharedExternally')}:
            </span>
            <span>{account.workspaceInfo.sharedExternally || 0}</span>
            <p className={styles.subtitle}>
              {i18next.t('people.subtitles.sharedExternally')}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

const Unxpose = ({styles, unxposeInfo}) => {
  return (
    <div className="flex">
      <div className={styles.detailsContainer + ' w-1/2'}>
        {unxposeInfo.name ? (
          <div className={styles.fieldContainer}>
            <span className={styles.fieldName}>
              {i18next.t('people.labels.name')}:
            </span>
            <span>{unxposeInfo.name}</span>
          </div>
        ) : (
          <></>
        )}
        {unxposeInfo.email ? (
          <div className={styles.fieldContainer}>
            <span className={styles.fieldName}>
              {i18next.t('people.labels.email')}:
            </span>
            <span>{unxposeInfo.email}</span>
          </div>
        ) : (
          <></>
        )}
        {unxposeInfo.role ? (
          <div className={styles.fieldContainer}>
            <span className={styles.fieldName}>
              {i18next.t('people.labels.role')}:
            </span>
            <span className="capitalize">{unxposeInfo.role}</span>
          </div>
        ) : (
          <></>
        )}

        {unxposeInfo.lastLogin ? (
          <div className={styles.fieldContainer}>
            <span className={styles.fieldName}>
              {i18next.t('people.labels.lastLogin')}:
            </span>
            <span>
              <Moment date={unxposeInfo.lastLogin} fromNow />
            </span>
          </div>
        ) : (
          <>
            <div className={styles.fieldContainer}>
              <span className={styles.fieldName}>
                {i18next.t('people.labels.lastLogin')}:
              </span>
              <span>-</span>
            </div>
          </>
        )}
      </div>

      <div className={styles.detailsContainer}>
        <div className={styles.fieldContainer}>
          <span className={styles.fieldName}>
            {i18next.t('people.labels.mfa')}:
          </span>
          {typeof unxposeInfo.mfa === 'boolean' ? (
            <span>
              {unxposeInfo.mfa ? (
                <images.SecurityShield height="20" width="20" />
              ) : (
                <images.AttentionSignal height="20" width="20" />
              )}
            </span>
          ) : (
            <>
              <span>
                <images.AttentionSignal height="20" width="20" />
              </span>
            </>
          )}
        </div>

        <div className={styles.fieldContainer}>
          <span className={styles.fieldName}>{i18next.t('Google Login')}:</span>
          {unxposeInfo.googleLogin ||
          typeof unxposeInfo.googleLogin === 'boolean' ? (
            <span>
              {unxposeInfo.googleLogin
                ? i18next.t('people.googleLoginEnabled')
                : i18next.t('people.googleLoginDisabled')}
            </span>
          ) : (
            <>
              <span>{i18next.t('people.googleLoginDisabled')}</span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

Account.propTypes = {
  account: PropTypes.object,
  styles: PropTypes.object,
}

Microsoft365.propTypes = {
  account: PropTypes.object,
  styles: PropTypes.object,
}

GoogleWorkspace.propTypes = {
  account: PropTypes.object,
  styles: PropTypes.object,
}

Unxpose.propTypes = {
  unxposeInfo: PropTypes.object,
  styles: PropTypes.object,
}
