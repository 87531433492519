import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import FeatureAdvantages from './FeatureAdvantages'
import {toggleRestrictionModal} from 'actions'

const PlanRestrictionModal = () => {
  const dispatch = useDispatch()

  const toggleRestrictionModalState = useSelector(
    state => state.toggleRestrictionModal
  )

  const onCloseModal = () => {
    dispatch(
      toggleRestrictionModal({
        contentId: '',
        showModal: false,
      })
    )
  }

  const closeModal = e => {
    const modal = document.querySelector('#toggleRestrictionModal')
    if (e.target === modal) onCloseModal()
  }

  useEffect(() => {
    document.addEventListener('mouseup', closeModal)

    return () => document.removeEventListener('mouseup', closeModal)
  }, [])

  return (
    toggleRestrictionModalState?.showModal && (
      <div
        id="toggleRestrictionModal"
        className="modal-bg fixed top-0 left-0 w-screen h-screen bg-white z-50">
        <div className="relative">
          <FeatureAdvantages
            contentId={toggleRestrictionModalState.contentId}
            classes="fixed bg-white border rounded-md bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2"
            closeModal={onCloseModal}
          />
        </div>
      </div>
    )
  )
}

export default PlanRestrictionModal
