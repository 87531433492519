import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import {i18next} from 'translate/i18n'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'

import config from 'config'
import {languages, linkStyle} from 'constant'
import images from 'res'
import {
  disconnectGoogle,
  disconnectMicrosoft,
  genericError,
  saveProfile,
  success,
} from 'actions'

import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import Subsection from 'components/partials/headers/Subsection'

const Profile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)

  const [user, setUser] = useState({
    email: '',
    firstName: '',
    googleId: '',
    lastName: '',
    locale: '',
    position: '',
  })

  const defaultLanguage = locale => {
    if (locale) {
      return languages.find(language => language.value === locale)
    }
    return
  }

  const handleGoogle = e => {
    e.preventDefault()
    window.location.href = config.SERVER_URL + '/auth/google/connect'
  }

  const handleMicrosoft = e => {
    e.preventDefault()
    window.location.href = config.SERVER_URL + '/auth/microsoft/connect'
  }

  const handleDisconnect = () => {
    dispatch(disconnectGoogle({_csrf: csrfState}))
  }

  const handleDisconnectMicrosoft = () => {
    dispatch(disconnectMicrosoft({_csrf: csrfState}))
  }

  const handleInputChange = event => {
    const {name, value} = event.target
    setUser({...user, [name]: value})
  }

  const handleSelectChange = event => {
    const {value} = event
    setUser({...user, locale: value})
  }

  const handleSubmit = event => {
    event.preventDefault()
    const shouldReload = user.locale !== userState.locale
    dispatch(saveProfile({user, shouldReload, _csrf: csrfState}))
  }

  useEffect(() => {
    if (userState) {
      Object.keys(userState).forEach(key => {
        if (!userState[key]) userState[key] = ''
      })
      setUser(() => userState)
    }
  }, [userState])

  useEffect(() => {
    if (location.search.match(/googleConnected/)) {
      dispatch(
        success({
          message: i18next.t('settings.oauth.googleConnected'),
        })
      )
    }

    if (location.search.match(/microsoftConnected/)) {
      dispatch(
        success({
          message: i18next.t('settings.oauth.microsoftConnected'),
        })
      )
    }

    if (location.search.match(/microsoftDisconnected/)) {
      dispatch(
        success({
          message: i18next.t('settings.oauth.microsoftDisconnected'),
        })
      )
    }

    if (location.search.match(/googleDisconnected/)) {
      dispatch(
        success({
          message: i18next.t('settings.oauth.googleDisconnected'),
        })
      )
    }

    if (location.search.match(/emailNotFound/)) {
      dispatch(
        genericError({
          message: i18next.t('settings.oauth.emailNotFound'),
        })
      )
    }

    if (location.search.match(/emailMismatch/)) {
      dispatch(
        genericError({
          message: i18next.t('settings.oauth.emailMismatch'),
        })
      )
    }

    if (location.search.match(/message/)) {
      // clear message from URL to avoid problems
      navigate({search: ''})
    }
  }, [])

  return (
    <>
      <Subsection
        title={i18next.t('settings.tabs.profile')}
        content={
          <form onSubmit={handleSubmit}>
            <div className="flex border-b border-sideral-50 pt-8 pb-6 px-6">
              <div className="hidden sm:block w-4/12">
                <p className="text-sm text-sideral-900">
                  {i18next.t('titles.personalInformation')}
                </p>
                <p className="text-light text-xs text-sideral-300 pt-1">
                  {i18next.t('subtitles.personalInformation')}
                </p>
              </div>
              <div className="w-full sm:w-5/12">
                <PrimaryInput
                  text={i18next.t('labels.firstName') + '*'}
                  name="firstName"
                  placeholder="John"
                  type="text"
                  value={user.firstName || ''}
                  required={true}
                  onChange={handleInputChange}
                  margin="mb-8"
                />
                <PrimaryInput
                  text={i18next.t('labels.lastName') + '*'}
                  name="lastName"
                  placeholder="Doe"
                  type="text"
                  value={user.lastName || ''}
                  required={true}
                  onChange={handleInputChange}
                  margin="mb-8"
                />
                <div className="mb-8">
                  <PrimarySelect
                    text={i18next.t('labels.language')}
                    name="locale"
                    options={languages}
                    defaultValue={defaultLanguage(userState.locale)}
                    onChange={handleSelectChange}
                  />
                </div>
                <PrimaryInput
                  text={i18next.t('labels.jobTitle') + '*'}
                  name="position"
                  placeholder="CEO, CSO, SysAdmin..."
                  type="text"
                  value={user.position || ''}
                  required={true}
                  onChange={handleInputChange}
                  margin="mb-0"
                />
              </div>
            </div>

            <div className="flex border-b border-sideral-50 pt-8 pb-6 px-6">
              <div className="hidden sm:block w-4/12">
                <p className="text-sm text-sideral-900">
                  {i18next.t('titles.contact')}
                </p>
                <p className="text-light text-xs text-sideral-300 pt-1">
                  {i18next.t('subtitles.contact')}
                </p>
              </div>
              <div className="w-full sm:w-5/12">
                <PrimaryInput
                  text="Email"
                  placeholder="Your email address"
                  value={user.email || ''}
                  name="email"
                  type="email"
                  disabled={true}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="flex border-b border-sideral-50 pt-8 pb-6 px-6">
              <div className="hidden sm:block w-4/12">
                <p className="text-sm text-sideral-900">
                  {i18next.t('titles.googleAccount')}
                </p>
                <p className="text-light text-xs text-sideral-300 pt-1">
                  {i18next.t('subtitles.googleAccount')}
                </p>
              </div>
              <div className="w-full md:w-5/12">
                <div className="flex">
                  <figure className="hidden lg:flex flex-shrink-0 lg:items-center mr-6">
                    <img
                      src={images.googleIcon}
                      width="52"
                      alt="Google account"
                    />
                  </figure>
                  {userState.googleId ? (
                    <div className="font-light text-sm leading-snug">
                      <p className="text-base font-normal uppercase text-sideral-700">
                        {i18next.t('titles.googleAccount')}
                      </p>
                      <p className="pt-2">
                        {i18next.t('titles.googleAccountConnected')}
                      </p>
                      <span onClick={handleDisconnect} className={linkStyle}>
                        {i18next.t('buttons.disconnectGoogleAccount')}
                      </span>
                    </div>
                  ) : (
                    <div className="font-light text-sm">
                      <p className="dm-mono text-base font-normal uppercase text-sideral-700">
                        {i18next.t('titles.googleAccount')}
                      </p>
                      <p className="pt-2">
                        {i18next.t('warnings.googleAccountNotConnected1')}
                      </p>
                      <p>
                        {i18next.t('warnings.googleAccountNotConnected2')}{' '}
                        <span onClick={handleGoogle} className={linkStyle}>
                          {i18next.t('warnings.googleAccountNotConnected3')}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex border-b border-sideral-50 pt-8 pb-6 px-6">
              <div className="hidden sm:block w-4/12">
                <p className="text-sm text-sideral-900">
                  {i18next.t('titles.microsoftAccount')}
                </p>
                <p className="text-light text-xs text-sideral-300 pt-1">
                  {i18next.t('subtitles.microsoftAccount')}
                </p>
              </div>
              <div className="w-full md:w-5/12">
                <div className="flex">
                  <figure className="hidden lg:flex flex-shrink-0 lg:items-center mr-6">
                    <img
                      src={images.microsoftIcon}
                      width="52"
                      alt="Microsoft account"
                    />
                  </figure>
                  {userState.microsoft_signin ? (
                    <div className="font-light text-sm leading-snug">
                      <p className="text-base font-normal uppercase text-sideral-700">
                        {i18next.t('titles.microsoftAccount')}
                      </p>
                      <p className="pt-2">
                        {i18next.t('titles.microsoftAccountConnected')}
                      </p>
                      <span
                        onClick={handleDisconnectMicrosoft}
                        className={linkStyle}>
                        {i18next.t('buttons.disconnectMicrosoftAccount')}
                      </span>
                    </div>
                  ) : (
                    <div className="font-light text-sm">
                      <p className="text-base font-normal uppercase text-sideral-700">
                        {i18next.t('titles.microsoftAccount')}
                      </p>
                      <p className="pt-2">
                        {i18next.t('warnings.microsoftAccountNotConnected1')}
                      </p>
                      <p>
                        {i18next.t('warnings.microsoftAccountNotConnected2')}{' '}
                        <span onClick={handleMicrosoft} className={linkStyle}>
                          {i18next.t('warnings.microsoftAccountNotConnected3')}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        }
      />

      <div className="flex justify-end pt-6">
        <PrimaryButton
          text={i18next.t('buttons.saveChanges')}
          onClick={handleSubmit}
          theme="blue"
          size="md"
          type="submit"
        />
      </div>
    </>
  )
}

Profile.propTypes = {
  location: PropTypes.object,
  search: PropTypes.string,
}

export default Profile
