import React from 'react'
import PropTypes from 'prop-types'

export default function FilterIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      style={{fill: color || 'black'}}
      viewBox="0 0 64 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M61.8665 52.1393H39.0252C37.847 52.1393 36.8917 51.1844 36.8917 50.0058C36.8917 48.8276 37.8466 47.8723 39.0252 47.8723H61.8665C63.0447 47.8723 64 48.8272 64 50.0058C64 51.184 63.0451 52.1393 61.8665 52.1393Z" />
      <path d="M38.4812 10.7493H2.1335C0.955321 10.7493 0 9.79438 0 8.6158C0 7.43762 0.954921 6.4823 2.1335 6.4823H38.4812C39.6594 6.4823 40.6147 7.43722 40.6147 8.6158C40.6142 9.79432 39.6593 10.7493 38.4812 10.7493Z" />
      <path d="M61.8665 31.4444H22.1131C20.9349 31.4444 19.9796 30.4894 19.9796 29.3109C19.9796 28.1327 20.9345 27.1774 22.1131 27.1774H61.8665C63.0447 27.1774 64 28.1323 64 29.3109C64 30.489 63.0451 31.4444 61.8665 31.4444Z" />
      <path d="M25.5188 52.1393H2.1335C0.955321 52.1393 0 51.1844 0 50.0058C0 48.8276 0.954921 47.8723 2.1335 47.8723H25.5188C26.697 47.8723 27.6523 48.8272 27.6523 50.0058C27.6519 51.184 26.6969 52.1393 25.5188 52.1393Z" />
      <path d="M9.15071 31.4444H2.13375C0.955565 31.4444 0.000244141 30.4894 0.000244141 29.3109C0.000244141 28.1327 0.955165 27.1774 2.13375 27.1774H9.15117C10.3293 27.1774 11.2847 28.1323 11.2847 29.3109C11.2842 30.489 10.3284 31.4444 9.15071 31.4444Z" />
      <path d="M61.8665 10.7493H51.5704C50.3922 10.7493 49.4369 9.79438 49.4369 8.6158C49.4369 7.43762 50.3918 6.4823 51.5704 6.4823L61.8665 6.48275C63.0447 6.48275 64 7.43767 64 8.61625C64 9.79443 63.0451 10.7493 61.8665 10.7493Z" />
      <path d="M38.4812 58.6205H25.5177C24.3395 58.6205 23.3842 57.6656 23.3842 56.487V43.5235C23.3842 42.3453 24.3391 41.39 25.5177 41.39H38.4812C39.6594 41.39 40.6147 42.3449 40.6147 43.5235V56.487C40.6142 57.666 39.6593 58.621 38.4812 58.621V58.6205ZM27.6512 54.354H36.3472L36.3477 45.657H27.6511L27.6512 54.354Z" />
      <path d="M22.1131 37.9255H9.14955C7.97137 37.9255 7.01605 36.9706 7.01605 35.792L7.0165 22.8297C7.0165 21.6515 7.97142 20.6962 9.15 20.6962H22.1135C23.2917 20.6962 24.247 21.6511 24.247 22.8297V35.7932C24.2466 36.9714 23.2912 37.9262 22.1131 37.9262L22.1131 37.9255ZM11.2835 33.659H19.9796V24.9625H11.2835V33.659Z" />
      <path d="M51.4447 17.2305H38.4812C37.303 17.2305 36.3477 16.2756 36.3477 15.097V2.1335C36.3477 0.955321 37.3026 0 38.4812 0H51.4447C52.6229 0 53.5782 0.954921 53.5782 2.1335V15.097C53.5786 16.2752 52.6233 17.231 51.4451 17.231L51.4447 17.2305ZM40.6142 12.9635H49.3103L49.3107 4.26654H40.6142L40.6142 12.9635Z" />
    </svg>
  )
}

FilterIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
