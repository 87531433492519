import config from '../config'
import {deleteUrlDone, success} from 'actions'
import {handler} from './handler'

const _forceCheck = body => {
  return fetch(`${config.SERVER_URL}/service/${body.serviceId}/check`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  })
}

export function* handleForceCheck(action) {
  yield handler({
    handler: _forceCheck,
    action,
  })
}

export function* handleDeleteUrl(action) {
  yield handler({
    handler: _deleteUrl,
    success: [success, deleteUrlDone],
    action,
  })
}

const _deleteUrl = body => {
  const endpoint = body.serviceId ? 'service' : 'httpservice'
  const id = body.serviceId ? body.serviceId : body.httpServiceId

  return fetch(`${config.SERVER_URL}/${endpoint}/${id}`, {
    body: JSON.stringify(body),
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}
