import ContentBox from 'components/partials/ContentBox'
import GenericFinding from 'components/GenericFinding'
import Moment from 'react-moment'
import React, {useEffect, useState} from 'react'
import {allowedStatusesInfoExposure, linkStyle} from 'constant'
import {i18next} from 'translate/i18n'
import {useDispatch, useSelector} from 'react-redux'
import {
  fetchInfoExposureFinding,
  fetchInfoExposureFindingActivity,
  updateInfoExposureFinding,
} from 'actions'

import EyeIcon from 'res/icons/eyeIcon'
import InfoIcon from 'res/icons/infoIcon'
import InfoExposureIcon from 'res/icons/informationIcon'

export default function InfoExposureFinding(props) {
  const userState = useSelector(state => state.user)
  const infoExposureFindingState = useSelector(
    state => state.infoExposureFinding
  )
  const infoExposureFindingActivityState = useSelector(
    state => state.infoExposureFindingActivity
  )

  const dispatch = useDispatch()

  const [finding, setFinding] = useState(null)
  const [findingActivity, setFindingActivity] = useState(null)

  useEffect(() => {
    if (
      userState &&
      userState.company &&
      userState.company.features &&
      !userState.company.features.infoExposure
    ) {
      window.location.href = '/404'
      return
    }
  }, [dispatch, userState])

  useEffect(() => {
    if (!infoExposureFindingState) return

    const obj = infoExposureFindingState
    const newTitle = infoExposureFindingState.tags.join(', ')

    obj.title = newTitle

    setFinding(obj)
  }, [infoExposureFindingState])

  useEffect(() => {
    if (infoExposureFindingActivityState)
      setFindingActivity(infoExposureFindingActivityState)
  }, [infoExposureFindingActivityState])

  return (
    <GenericFinding
      {...props}
      allowedStatuses={allowedStatusesInfoExposure}
      fallbackLink={'/info'}
      fetchFinding={fetchInfoExposureFinding}
      fetchFindingActivity={fetchInfoExposureFindingActivity}
      finding={finding}
      findingActivity={findingActivity}
      findingBody={FindingBody}
      findingDetails={FindingDetails}
      findingIcon={FindingIcon}
      findingType="info"
      //helpPath={'infoExposure'}
      setFinding={setFinding}
      setFindingActivity={setFindingActivity}
      title={i18next.t('pageTitles.information')}
      updateFinding={updateInfoExposureFinding}
    />
  )
}

const FindingIcon = () => {
  return (
    <div className="w-20 p-4">
      <InfoExposureIcon width="60" height="60" color="#90A4AE" />
    </div>
  )
}

const FindingDetails = finding => {
  const getCommitHash = githubUrl => {
    try {
      const url = new URL(githubUrl)
      const arr = url.pathname.split('/')
      const commitIndex = arr.indexOf('blob') + 1
      const commit = arr[commitIndex].slice(0, 7)

      return commit
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <div className="p-4 md:flex bg-white rounded-lg">
        <div className="flex-1 md:mb-0 mb-6 md:pb-0 pb-6 md:border-none border-b text-sm">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
            <InfoIcon size="20" height="20" color="#0A50A0" />
          </div>
          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('infoExposure.repository')}:
            </span>
            <span>
              Github -{' '}
              {finding && finding.repository && finding.repository.full_name}
            </span>
          </div>
          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('infoExposure.filename')}:
            </span>
            <a
              href={finding.html_url}
              target="_blank"
              rel="noreferrer"
              className={linkStyle}>
              {finding && finding.filename}
            </a>
          </div>
          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('infoExposure.fullPath')}:
            </span>
            {finding && finding.path}
          </div>
          {finding && finding.html_url && getCommitHash(finding.html_url) ? (
            <div className="py-1 px-2">
              <span className="font-semibold mr-2">Commit:</span>
              <a
                href={finding.html_url}
                target="_blank"
                rel="noreferrer"
                className={linkStyle}>
                {getCommitHash(finding.html_url)}
              </a>
            </div>
          ) : (
            <></>
          )}
          {finding && finding.repository && finding.repository.description ? (
            <div className="py-1 px-2">
              <span className="font-semibold mr-2">
                {i18next.t('infoExposure.repositoryDescription')}
              </span>
              <span>{finding.repository.description}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="flex-1 md:mb-2 md:pb-0 pb-6 md:border-none last:border-none border-b text-sm">
          <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
            <EyeIcon size="20" height="20" color="#0A50A0" />
          </div>

          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('finding.firstSeen')}
            </span>
            <Moment format="LLL" date={finding && finding.createdAt} />
          </div>
          <div className="py-1 px-2">
            <span className="font-semibold mr-2">
              {i18next.t('finding.lastSeen')}
            </span>
            <Moment format="LLL" date={finding && finding.checkedAt} />
          </div>
        </div>
      </div>
    </>
  )
}

const FindingBody = () => {
  const Description = () => {
    return (
      <div className="py-2 rounded-lg template-markdown">
        <p>
          It was detected what looks like a credential belonging to your company
          in a public Github repository or Gist.
        </p>
      </div>
    )
  }

  const Solution = () => {
    return (
      <div className="py-2 rounded-lg template-markdown">
        <p>
          Confirm if this is a credential. If this is real finding follow the
          steps below:
        </p>
        <ol className="list-inside list-decimal ml-2 ">
          <li>Revoke the credential in all your systems;</li>
          <li>
            If the repository should not be public, make the referred repository
            private or remove it;
          </li>
          <li>
            Remove the credential from the source code and use a tool to clean
            the Git history.
          </li>
        </ol>
      </div>
    )
  }

  return (
    <>
      <div className="mb-6">
        <ContentBox
          header={i18next.t('titles.about')}
          content={<Description />}
        />
      </div>

      <div className="mb-6">
        <ContentBox
          header={i18next.t('titles.solution')}
          content={<Solution />}
        />
      </div>
    </>
  )
}
