import config from 'config'
import {
  fetchDocuments,
  fetchDocumentsDone,
  genericError,
  loading,
  redirectFeedback,
  uploadDocumentDone,
  success,
  removeDocumentFeedback,
} from 'actions'
import {handler} from './handler'
import i18next from 'i18next'

export function* handleFetchDocuments(action) {
  yield handler({
    handler: _fetchDocuments,
    error: res =>
      genericError({
        loading: {documents: false},
        message: res.message,
      }),
    success: fetchDocumentsDone,
    action,
  })
}

const _fetchDocuments = function () {
  return fetch(config.SERVER_URL + '/artifacts', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUploadDocument(action) {
  yield handler({
    handler: _uploadDocument,
    error: res =>
      genericError({
        loading: {uploadDocument: false},
        message: res.message,
      }),
    success: [
      () => loading({uploadDocument: false}),
      () => {
        if (window.location.pathname.match(/\/documents/)) {
          return redirectFeedback({
            path: '/documents',
            status: 'success',
            message: i18next.t('documents.addNew.messages.success'),
          })
        }

        return success({
          message: i18next.t('questionnaires.evidences.uploadSuccess'),
        })
      },
      uploadDocumentDone,
    ],
    action,
  })
}

const _uploadDocument = function (body) {
  return fetch(`${config.SERVER_URL}/artifacts`, {
    method: 'POST',
    credentials: 'include',
    body,
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleRemoveDocument(action) {
  yield handler({
    handler: _removeDocument,
    error: res => {
      switch (res.code) {
        case 'ARTIFACT_IN_USE':
          return removeDocumentFeedback(res)
        default:
          return genericError({
            message: i18next.t('errors.unexpectedError'),
          })
      }
    },
    success: [
      () => success({message: i18next.t('documents.modal.remove.success')}),
      fetchDocuments,
    ],
    action,
  })
}

const _removeDocument = function (body) {
  return fetch(`${config.SERVER_URL}/artifacts/${body.artifactId}`, {
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}
