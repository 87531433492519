import i18next from 'i18next'
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {v4 as uuidv4} from 'uuid'

import {integrateAws, loading} from 'actions'
import config from 'config'
import {borderStyle, integrationStyles} from 'constant'
import images from 'res'

import BackButton from 'components/partials/buttons/BackButton'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import {Page} from 'components/partials/Page'
import {isLoading} from 'utils'

export default function AwsCloudFormationIntegration() {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)

  const [form, setForm] = useState({accountId: '', externalId: uuidv4()})

  const cloudFormationStackLink = `https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?templateURL=${config.cloud.cloudFormation.templateUrl}&stackName=UnxposeIntegration&param_ExternalId=${form.externalId}`

  const handleInputChange = event => {
    setForm({...form, accountId: event.target.value})
  }

  const handleSubmit = event => {
    event.preventDefault()

    dispatch(loading({awsIntegration: true}, {_csrf: csrfState}))
    dispatch(
      integrateAws({
        ...form,
        method: 'cloudFormation',
        version: config.awsIntegrationVersion,
        _csrf: csrfState,
      })
    )
  }

  return (
    <Page pageTitle={i18next.t('pageTitles.cloud')}>
      <BackButton fallbackLink="/cloud/aws/integration/options" />
      <div className={`${borderStyle} bg-white p-6 mt-4`}>
        <div className="lg:w-8/12">
          <div className={integrationStyles.mainTitle}>
            {i18next.t('cloud.aws.cloudFormationIntegration.title')}
          </div>

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.aws.cloudFormationIntegration.intro.title')}
          </div>
          <div className="text-sm">
            {i18next.t('cloud.aws.cloudFormationIntegration.intro.body')}
          </div>

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.aws.cloudFormationIntegration.stepOne.title')}
          </div>
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: i18next.t(
                'cloud.aws.cloudFormationIntegration.stepOne.body',
                {
                  click_here: `<a
                  class="text-sky-500"
                  href="${cloudFormationStackLink}"
                  rel="noopener noreferrer"
                  target="_blank">
                  ${i18next.t('misc.clickHere')}
                </a>`,
                  create_stack: `<b>${i18next.t(
                    'cloud.aws.cloudFormationIntegration.stepOne.createStack'
                  )}</b>`,
                  interpolation: {
                    escapeValue: false,
                  },
                }
              ),
            }}
          />
          <figure>
            <img
              src={images.awsCloudFormationCheckbox}
              className={integrationStyles.image}
            />
          </figure>

          <div className="text-sm">
            {i18next.t('cloud.aws.cloudFormationIntegration.stepOne.attention')}
          </div>
          <figure>
            <img
              src={images.awsCloudFormationStackCreation}
              className={integrationStyles.image}
            />
          </figure>

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.aws.cloudFormationIntegration.stepTwo.title')}
          </div>
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: i18next.t(
                'cloud.aws.cloudFormationIntegration.stepTwo.body',
                {
                  monitor_my_account: `
                  <b>${i18next.t(
                    'cloud.aws.cloudFormationIntegration.stepTwo.monitorMyAccount'
                  )}</b>`,
                  interpolation: {
                    escapeValue: false,
                  },
                }
              ),
            }}
          />
          <div
            className="text-xs mt-4"
            dangerouslySetInnerHTML={{
              __html: i18next.t(
                'cloud.aws.cloudFormationIntegration.stepTwo.accountIdHelp',
                {
                  click_here: `<a
                  class="text-sky-500"
                  href="https://docs.aws.amazon.com/general/latest/gr/acct-identifiers.html"
                  rel="noopener noreferrer"
                  target="_blank">
                  ${i18next.t('misc.clickHere')}
                </a>`,
                  interpolation: {
                    escapeValue: false,
                  },
                }
              ),
            }}
          />
          <div className="my-1">
            <form onSubmit={handleSubmit}>
              <PrimaryInput
                placeholder={i18next.t(
                  'cloud.aws.cloudFormationIntegration.stepTwo.amazonAccountId'
                )}
                type="text"
                name="accountId"
                value={form.accountId}
                onChange={handleInputChange}
                required={true}
                margin="mb-10"
              />
              <div className="w-full md:w-56 md:ml-auto">
                <PrimaryButton
                  text={i18next.t(
                    'cloud.aws.cloudFormationIntegration.stepTwo.monitorMyAccount'
                  )}
                  type="submit"
                  theme="blue"
                  loading={isLoading(loadingState.awsIntegration, false)}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Page>
  )
}
