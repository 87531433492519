import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useGoogleMaps} from 'react-hook-google-maps'

import PropTypes from 'prop-types'

export default function Map(props) {
  const hostState = useSelector(state => state.host)

  const [lat, setLat] = useState(0)
  const [lng, setLng] = useState(0)

  const {ref, map, google} = useGoogleMaps(
    'AIzaSyBLta32Bz_uT09XHalBKA1qCcASyUXi8_Q',
    {
      center: {lat, lng},
      zoom: 7,
    }
  )

  if (map) {
    new google.maps.Marker({position: {lat, lng}, map})
    map.setCenter({lat, lng})
  }

  useEffect(() => {
    if (hostState) {
      setLat(parseFloat(hostState[props.hostId].geolocation.split(',')[0]))
      setLng(parseFloat(hostState[props.hostId].geolocation.split(',')[1]))
    }
  }, [hostState])

  return <div ref={ref} style={{width: '100%', height: '100%'}} />
}

Map.propTypes = {
  hostId: PropTypes.string,
}
