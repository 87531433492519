import React, {useState} from 'react'
import Spinner from './Spinner'
import i18next from 'i18next'
import bgOrangePurple from 'res/backgrounds/bgOrangePurple.svg'
import PlanUtils from 'utils/plan'
import {useSelector} from 'react-redux'
import {SentryUserFeedbackButton} from './SentryUserFeedbackButton'

const PAPERFORM_EN_ID = 'olsmvllj'
const PAPERFORM_PT_BR_ID = 'orym3gsu'

export const SurveyModal = () => {
  const userState = useSelector(state => state.user)

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)

  const planUtils = PlanUtils()

  const surveyHasBeenDisplayed = JSON.parse(
    localStorage.getItem('surveyHasBeenDisplayed')
  )

  const paperformId =
    i18next.language === 'pt-br' ? PAPERFORM_PT_BR_ID : PAPERFORM_EN_ID

  const onClose = () => {
    setShowModal(false)
    setLoading(true)

    localStorage.setItem('surveyHasBeenDisplayed', true)
  }

  useState(() => {
    if (
      !surveyHasBeenDisplayed &&
      !userState?.surveys?.feb2024 &&
      planUtils.userHasPremiumPlan()
    )
      setShowModal(true)
  }, [])

  if (userState.surveys?.feb2024) return <SentryUserFeedbackButton />

  if (!planUtils.userHasPremiumPlan()) return

  if (!showModal)
    return (
      <button
        className="fixed bottom-[20px] right-[20px] bg-white hover:bg-gray-100 border rounded shadow-md text-sm font-medium px-3 py-2 z-50"
        onClick={() => setShowModal(true)}>
        <span className="mr-2">🚀</span>
        {i18next.t('survey.openBtn')}
      </button>
    )

  return (
    <div id="survey-modal" className="fixed z-50 ">
      <div
        className={
          'fixed bottom-[20px] right-[20px] bg-white border rounded-md shadow-md w-full max-w-lg'
        }>
        <div className="p-6 pb-0">
          <div className="relative">
            <div className="absolute right-0 z-50">
              <button
                data-testid="survey-modal-close-btn"
                type="button"
                onClick={onClose}>
                <i className="icofont-close text-xl" />
              </button>
            </div>

            <div
              id="modal-header"
              className="text-sideral-900 leading-none border-b border-sideral-50 text-lg pb-6 mb-6 font-medium">
              {i18next.t('survey.title')}
            </div>

            {loading && (
              <div className="pt-20 pb-24">
                <Spinner />
              </div>
            )}

            <iframe
              className={`${loading ? 'hidden' : 'block'}`}
              width="100%"
              height="400px"
              onLoad={() => setLoading(false)}
              src={`https://${paperformId}.paperform.co?surveyId=${userState.surveyId}`}
            />
          </div>
        </div>

        <div
          style={{
            backgroundImage: `url(${bgOrangePurple})`,
          }}
          className="h-2 rounded-b-md"
        />
      </div>
    </div>
  )
}
