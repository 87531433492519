import PropTypes from 'prop-types'
import React from 'react'
import {i18next} from 'translate/i18n'

export function CustomHeaders(props) {
  const handleHeadersChange = (value, fieldName, index) => {
    const headers = [...props.customHeaders]
    if (headers[index]) {
      headers[index][fieldName] = value
      if (!headers[index].name && !headers[index].value)
        headers.splice(index, 1)
    } else {
      const object = {}
      object[fieldName] = value
      headers.push(object)
    }

    props.setWebhookTested(false)
    props.setCustomHeaders(headers)
  }

  const headerInputStyle =
    'appearance-none block w-full text-ink border border-sideral-100 rounded roboto leading-tight focus:outline-none focus:bg-white p-3'
  return (
    <>
      {props.customHeaders &&
        [...Array(props.customHeaders.length + 1).keys()].map(
          (headerRow, index) => {
            return (
              <div className="flex mt-2 relative" key={`customHeader-${index}`}>
                <div className="w-1/2">
                  <input
                    className={headerInputStyle}
                    type="text"
                    placeholder={i18next.t('placeholders.key')}
                    name="customHeaders[index].name"
                    value={
                      (props.customHeaders[index] &&
                        props.customHeaders[index].name) ||
                      ''
                    }
                    onChange={e =>
                      handleHeadersChange(e.target.value, 'name', index)
                    }
                  />
                </div>
                <div className="w-1/2 ml-2">
                  <input
                    className={headerInputStyle}
                    type="text"
                    placeholder={i18next.t('placeholders.value')}
                    name="customHeaders[index].value"
                    value={
                      (props.customHeaders[index] &&
                        props.customHeaders[index].value) ||
                      ''
                    }
                    onChange={e =>
                      handleHeadersChange(e.target.value, 'value', index)
                    }
                  />
                </div>
              </div>
            )
          }
        )}
    </>
  )
}

CustomHeaders.propTypes = {
  customHeaders: PropTypes.array,
  setCustomHeaders: PropTypes.func,
  setWebhookTested: PropTypes.func,
}
