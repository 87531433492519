import i18next from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'

import ProgressBar from 'components/overview/ProgressBar'

const CleanScoreBox = ({overallScore, percentage, title, titleLink = '#'}) => {
  let backgroundColor

  switch (overallScore) {
    case 'A':
      backgroundColor = 'bg-teal-500'
      break
    case 'B':
      backgroundColor = 'bg-green-500'
      break
    case 'C':
      backgroundColor = 'bg-yellow-500'
      break
    case 'D':
      backgroundColor = 'bg-orange-500'
      break
    case 'E':
      backgroundColor = 'bg-red-500'
      break
    case 'F':
      backgroundColor = 'bg-red-700'
      break
    default:
      backgroundColor = 'bg-gray-400 text-gray-600'
      break
  }

  return (
    <div className="w-full lg:max-w-none max-w-[450px]">
      <div
        className={`relative flex justify-between items-center rounded-lg font-normal mx-auto border text-white h-24 p-4 ${backgroundColor}`}>
        <div className="text-lg font-thin mb-6 hover:underline hover:cursor-pointer">
          <a href={titleLink}>
            {title ? title : i18next.t('titles.categoryScore')}
          </a>
        </div>
        <span className="text-3xl font-bold mb-6">{overallScore}</span>
        <ProgressBar
          completed={percentage ? percentage : 0}
          overallScore={overallScore}
          padding="px-3"
          margin="mb-4"
        />
      </div>
    </div>
  )
}

CleanScoreBox.propTypes = {
  overallScore: PropTypes.string,
  percentage: PropTypes.number,
  title: PropTypes.string,
  titleLink: PropTypes.string,
}

export default CleanScoreBox
