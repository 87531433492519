import React, {useState, useEffect} from 'react'
import {setUser as setSentryUser} from '@sentry/react'
import {Steps} from 'intro.js-react'
import {useDispatch, useSelector} from 'react-redux'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Moment from 'react-moment'
import 'moment/locale/pt-br'
import i18next from 'i18next'
import ToastMessage from './partials/ToastMessage'

import {FullPageLayout, BgImagePageLayout} from './PageLayouts'

import ProtectedRoutes from './ProtectedRoutes'

import {isLoading, isPublicView} from 'utils'

import {clearError, completeGuide, fetchCsrf, fetchUser} from 'actions'

import {introOptions, introSteps} from 'constant'

/* Main components */
import Dashboard from './overview/Dashboard'

/* Account */
import ForgotPassword from './account/ForgotPassword'
import Login from './account/Login'
import Logout from './account/Logout'
import MFA from './account/MFA'
import Register from './account/Register'
import ResetPassword from './account/ResetPassword'
import AcceptInvite from './account/AcceptInvite'
import SignUp from './account/SignUp'
import Settings from './settings/Settings'

/* Dashboards */
import BreachDetail from './people/breaches/BreachDetail'
import Management from './management/Dashboard'
import Network from './network/Dashboard'
import People from './people/Dashboard'

/* WEB */
import FingerprintDetails from './web/FingerprintDetails'
import WebAppDetailV2 from './web/WebAppDetail'
import WebAppFindingV2 from './web/Finding'
import WebV2 from './web/Dashboard'

/* DNS */
import DnsOverview from './dns/Dashboard'
import DnsFinding from './dns/Finding'
import DomainDetails from './dns/DomainDetails'
import DnsRecordsLogs from './dns/DnsRecordsLogs'

/* CLOUD */
import CloudDashboard from './cloud/CloudDashboard'
import Integration from './cloud/Integration'

/* AWS */
import AwsCloudFormationIntegration from './cloud/aws/AwsCloudFormationIntegration'
import AwsDashboard from './cloud/aws/Dashboard'
import AwsFinding from './cloud/aws/Finding'
import AwsIntegrationOptions from './cloud/aws/AwsIntegrationOptions'
import AwsManualIntegration from './cloud/aws/AwsManualIntegration'
import AwsManualUpdateTutorial from './cloud/aws/AwsManualUpdateTutorial'
import AwsCloudFormationUpdateTutorial from './cloud/aws/AwsCloudFormationUpdateTutorial'

/* GCP */
import GcpDashboard from './cloud/gcp/Dashboard'
import GcpFinding from './cloud/gcp/Finding'
import GcpIntegrationOptions from './cloud/gcp/GcpIntegrationOptions'
import GcpManualIntegration from './cloud/gcp/GcpManualIntegration'
import GcpTerraformIntegration from './cloud/gcp/GcpTerraformIntegration'
import GcpUpdateIntegration from './cloud/gcp/UpdateIntegration'

/* Azure */
import AzureDashboard from './cloud/azure/Dashboard'
import AzureFinding from './cloud/azure/Finding'
import AzureIntegration from './cloud/azure/Integration'

// People
import AccountDetails from './people/AccountDetails'
import AddIntegration from './people/AddIntegration'
import ManagePeopleIntegrations from './people/ManagePeopleIntegrations'
import BreachFinding from './people/breaches/BreachFinding'

// Suppliers
import SuppliersOverview from './suppliers'
import SupplierDetails from './suppliers/SupplierDetails'
import SuppliersNew from './suppliers/SuppliersNew'
import SupplierSendQuestionnaire from './suppliers/questionnaire/SupplierSendQuestionnaire'
import SuppliersInviteHandler from './suppliers/SuppliersInviteHandler'
import SupplierQuestionnaireDetail from './suppliers/questionnaire/SupplierQuestionnaireDetail'

// Questionnaires
import QuestionnaireDetails from './questionnaires/QuestionnaireDetails'
import QuestionnaireNew from './questionnaires/QuestionnaireNew'
import QuestionnaireOverview from './questionnaires/QuestionnaireOverview'
import QuestionnaireTemplateNew from './suppliers/questionnaire/templates/QuestionnaireTemplateNew'

// Documents
import DocumentsOverview from './documents/DocumentsOverview'
import DocumentsNew from './documents/DocumentsNew'

/* Google Workspace */
import GoogleWorkspaceFinding from './people/workspace/Finding'

/* Info Exposure */
import Info from './info/Dashboard'
import InfoFinding from './info/Finding'

/* Compliance */
import ComplianceIntegrations from './compliance/Integrations'
import LgpdDashboard from './compliance/lgpd/Dashboard'
import LgpdIntegration from './compliance/lgpd/Integration'

/* Other */
import Host from './network/Host'
import InternetExposureDashboard from './internetExposure/Dashboard'
import NameServerDetails from './dns/nameServerDetails/NameServerDetails'
import NetworkFinding from './network/Finding'
import PageNotFound from './PageNotFound'
import Terms from './Terms'
import TermsForm from './TermsForm'
import Service from './network/Service'
import ServiceDetails from './network/ServiceDetails'
import PlanExpired from './PlanExpired'
import PartnerDashboard from './partner/Dashboard'
import PartnerFinding from './partner/PartnerFinding'
import Reports from './reports/Reports'
import SwitchCompany from './SwitchCompany'
import LoginRedirect from './LoginRedirect'
import WhyUnxpose from './WhyUnxpose'
import BulkChange from './management/bulkChange/BulkChange'

const App = props => {
  const dispatch = useDispatch()
  const userState = useSelector(state => state.user)
  const loadingState = useSelector(state => state.loading)
  const csrfState = useSelector(state => state.csrf)

  const [dropdown, setDropdown] = useState('hidden')
  const [showIntro, setShowIntro] = useState(false)

  function handleStateOnExit() {
    document.documentElement.classList.remove('overflow-hidden')

    dispatch(completeGuide({_csrf: csrfState, endpoint: 'intro'}))

    setShowIntro(false)
  }

  function toggleDropdown(e) {
    e.preventDefault()
    e.persist()
    setTimeout(() => {
      e.target.focus()
      e.target.blur()
    }, 20)
    dropdown === '' ? setDropdown('hidden') : setDropdown('')
    dispatch(clearError())
  }

  function hideDropdown() {
    if (dropdown === '') setDropdown('hidden')
  }

  function termsOutdatedOrNotDefined() {
    return (
      !userState.terms.latestAccepted ||
      (userState.terms.latestAccepted &&
        userState.terms.latestAccepted < userState.currentTermsVersion)
    )
  }

  function shouldShowTerms() {
    const termsNotDefined =
      userState && (!userState.terms || !Object.keys(userState.terms).length)

    const termsOutdated =
      userState && userState.terms && termsOutdatedOrNotDefined()

    return termsNotDefined || termsOutdated
  }

  function shouldRedirectToTerms() {
    const exclude = ['/terms-update']
    return shouldShowTerms() && !exclude.includes(window.location.pathname)
  }

  props = Object.assign({}, props, {
    showIntro,
    setShowIntro,
    dropdown,
    toggleDropdown,
    hideDropdown,
  })

  useEffect(() => {
    if (
      (process.env.REACT_APP_ENV === 'staging' ||
        process.env.REACT_APP_ENV === 'production') &&
      userState
    ) {
      setSentryUser({
        companyId: userState.company.id,
        email: userState.email,
        name: userState.firstName,
        userId: userState.id,
      })
    }
  }, [userState])

  useEffect(() => {
    if (!userState && !isPublicView()) dispatch(fetchUser())
  }, [dispatch, userState])

  useEffect(() => {
    if (userState && userState.locale) {
      i18next.changeLanguage(userState.locale, err => {
        if (err) return console.trace(err)
      })
    }
  }, [userState])

  useEffect(() => {
    if (userState && userState.id && shouldRedirectToTerms()) {
      window.location.href = '/terms-update'
    }

    if (userState && userState.locale) Moment.globalLocale = userState.locale
  }, [userState])

  useEffect(() => {
    const screenIsBigEnough = window.innerWidth > 800
    const isDashboard = window.location.pathname === '/'

    if (userState) {
      const userDidNotComplete =
        (userState.guides && !userState.guides.intro) || !userState.guides

      const scoreSectionIsMounted = !isLoading(loadingState.scores)

      if (
        !userDidNotComplete ||
        !isDashboard ||
        !screenIsBigEnough ||
        !scoreSectionIsMounted
      )
        return

      document.documentElement.scrollTop = 0
      document.documentElement.classList.add('overflow-hidden')

      setShowIntro(true)
    }
  }, [userState, loadingState.scores])

  useEffect(() => {
    dispatch(fetchCsrf())
  }, [])

  return (
    <div onClick={hideDropdown} className="relative">
      <Steps
        enabled={showIntro}
        steps={introSteps(userState)}
        initialStep={0}
        options={introOptions}
        onExit={handleStateOnExit}
      />

      <ToastMessage />

      <BrowserRouter>
        <Routes>
          {/* FULL PAGE LAYOUT */}
          <Route element={<FullPageLayout />}>
            <Route element={<BgImagePageLayout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/mfa" element={<MFA />} />
              <Route path="/register/:id" element={<Register />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot/:id" element={<ResetPassword />} />
              <Route path="/accept/:id" element={<AcceptInvite />} />
              <Route path="/forgot" element={<ForgotPassword />} />
            </Route>

            <Route path="/redirect" element={<LoginRedirect />} />

            <Route path="/signup" element={<SignUp />} />
            <Route path="/signup/:status" element={<SignUp />} />

            <Route
              path="/supplier-invite/:id"
              element={<SuppliersInviteHandler />}
            />

            <Route
              path="/terms-update"
              element={<TermsForm shouldShowTerms={shouldShowTerms()} />}
            />

            <Route path="/switch-company" element={<SwitchCompany />} />

            <Route path="/logout" element={<Logout />} />
          </Route>
          {/* FULL PAGE LAYOUT */}

          {/* DEFAULT PAGE LAYOUT */}
          <Route element={<ProtectedRoutes menuProps={{...props}} />}>
            <Route path="/" element={<Dashboard />} />

            <Route
              path="/internet-exposure"
              element={<InternetExposureDashboard />}
            />

            {/* DNS */}
            <Route path="/dns" element={<DnsOverview />} />
            <Route path="/dns/domain/:id" element={<DomainDetails />} />
            <Route path="/dns/domain/:id/logs" element={<DnsRecordsLogs />} />
            <Route path="/dns/finding/:id" element={<DnsFinding />} />
            <Route path="/dns/nameserver/:id" element={<NameServerDetails />} />
            {/* DNS */}

            {/* Network */}
            <Route path="/network" element={<Network />} />
            <Route path="/network/finding/:id" element={<NetworkFinding />} />
            <Route path="/network/host/:id" element={<Host />} />
            <Route path="/network/services/:id" element={<ServiceDetails />} />
            <Route
              path="/network/services/:protocol/ports/:port"
              element={<Service />}
            />
            {/* Network */}

            {/* WEB */}
            <Route path="/web" element={<WebV2 />} />
            <Route path="/web/app/:id" element={<WebAppDetailV2 />} />
            <Route path="/web/finding/:id" element={<WebAppFindingV2 />} />
            <Route
              path="/web/fingerprint/:id"
              element={<FingerprintDetails />}
            />
            {/* WEB */}

            {/* Cloud */}
            <Route path="/cloud" element={<CloudDashboard />} />
            <Route path="/cloud/integration" element={<Integration />} />

            <Route path="/cloud/aws/:id" element={<AwsDashboard />} />
            <Route
              path="/cloud/aws/integration/cloudformation"
              element={<AwsCloudFormationIntegration />}
            />
            <Route
              path="/cloud/aws/integration/options"
              element={<AwsIntegrationOptions />}
            />
            <Route
              path="/cloud/aws/integration/manual"
              element={<AwsManualIntegration />}
            />
            <Route
              path="/cloud/aws/integration/update/manual"
              element={<AwsManualUpdateTutorial />}
            />
            <Route
              path="/cloud/aws/integration/update/cloudformation"
              element={<AwsCloudFormationUpdateTutorial />}
            />

            <Route path="/cloud/gcp/:id" element={<GcpDashboard />} />
            <Route
              path="/cloud/gcp/:id/update"
              element={<GcpUpdateIntegration />}
            />
            <Route
              path="/cloud/gcp/integration/options"
              element={<GcpIntegrationOptions />}
            />
            <Route
              path="/cloud/gcp/integration/manual"
              element={<GcpManualIntegration />}
            />
            <Route
              path="/cloud/gcp/integration/terraform"
              element={<GcpTerraformIntegration />}
            />

            <Route path="/cloud/azure/:id" element={<AzureDashboard />} />
            <Route
              path="/cloud/azure/integration"
              element={<AzureIntegration />}
            />

            <Route path="/aws/finding/:id" element={<AwsFinding />} />
            <Route path="/gcp/finding/:id" element={<GcpFinding />} />
            <Route path="/azure/finding/:id" element={<AzureFinding />} />
            {/* Cloud */}

            {/* Compliance */}
            <Route path="/compliance" element={<ComplianceIntegrations />} />
            <Route path="/compliance/lgpd" element={<LgpdDashboard />} />
            <Route
              path="/compliance/lgpd/integration"
              element={<LgpdIntegration />}
            />
            {/* Compliance */}

            {/* People */}
            <Route path="/people" element={<People />} />
            <Route path="/people/:id" element={<BreachDetail />} />
            <Route path="/people/account/:id" element={<AccountDetails />} />
            <Route
              path="/people/integrations"
              element={<ManagePeopleIntegrations />}
            />
            <Route
              path="/people/integrations/new"
              element={<AddIntegration />}
            />
            <Route path="/people/findings/:id" element={<BreachFinding />} />
            <Route path="/people/finding/:id" element={<BreachFinding />} />
            {/* People */}

            {/* Info */}
            <Route path="/info" element={<Info />} />
            <Route path="/info/finding/:id" element={<InfoFinding />} />
            {/* Info */}

            {/* Findings */}
            <Route path="/management" element={<Management />} />
            <Route path="/management/bulkChange" element={<BulkChange />} />
            {/* Findings */}

            <Route path="/reports" element={<Reports />} />

            {/* Suppliers */}
            <Route path="/suppliers" element={<SuppliersOverview />} />
            <Route path="/suppliers/new" element={<SuppliersNew />} />
            <Route path="/suppliers/:id" element={<SupplierDetails />} />
            <Route
              path="/suppliers/:id/sendQuestionnaire"
              element={<SupplierSendQuestionnaire />}
            />
            <Route
              path="/suppliers/:supplierId/questionnaire/:questionnaireId"
              element={<SupplierQuestionnaireDetail />}
            />
            {/* Suppliers */}

            {/* Questionnaires */}
            <Route path="/questionnaires" element={<QuestionnaireOverview />} />
            <Route path="/questionnaires/new" element={<QuestionnaireNew />} />
            <Route
              path="/questionnaires/templates/new"
              element={<QuestionnaireTemplateNew />}
            />
            <Route
              path="/questionnaires/:id"
              element={<QuestionnaireDetails />}
            />
            {/* Questionnaires */}

            {/* Documents */}
            <Route path="/documents" element={<DocumentsOverview />} />
            <Route path="/documents/new" element={<DocumentsNew />} />
            {/* Documents */}

            {/* Partner */}
            <Route path="/partner" element={<PartnerDashboard />} />
            <Route path="/partner/finding/:id" element={<PartnerFinding />} />
            {/* Partner */}

            <Route
              path="/workspace/finding/:id"
              element={<GoogleWorkspaceFinding />}
            />

            <Route path="/settings/:tab" element={<Settings />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/plan-expired" element={<PlanExpired />} />
            <Route path="/why-unxpose" element={<WhyUnxpose />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
          {/* DEFAULT PAGE LAYOUT */}
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
