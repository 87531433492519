import {
  fetchCloudFeedDone,
  fetchActiveCloudIntegrationsDone,
  genericError,
} from 'actions'
import {handler} from './handler'
import config from 'config'

export function* handleFetchCloudFeed(action) {
  yield handler({
    handler: _fetchCloudFeed,
    error: genericError,
    success: fetchCloudFeedDone,
    action,
  })
}

const _fetchCloudFeed = function (page) {
  return fetch(config.SERVER_URL + `/cloud/feed?page=${page}`, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchActiveCloudIntegrations(action) {
  yield handler({
    handler: activeCloudIntegrations,
    success: fetchActiveCloudIntegrationsDone,
    action,
  })
}

const activeCloudIntegrations = () => {
  return fetch(`${config.SERVER_URL}/cloud/integrations/ids`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}
