import PropTypes from 'prop-types'
import React from 'react'

export default function Tag({content, margin, onClick}) {
  return (
    <div
      className={`rounded-full inline-flex justify-center border border-gray-300 bg-gray-100 hover:bg-gray-200 p-px ${
        onClick ? 'cursor-pointer' : ''
      } ${margin ? margin : 'mb-1 mr-1'}`}
      onClick={onClick}>
      <span className="text-xs px-2">{content}</span>
    </div>
  )
}

Tag.propTypes = {
  content: PropTypes.string,
  margin: PropTypes.string,
  onClick: PropTypes.func,
}
