import i18next from 'i18next'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {loading, fetchCompanyEmails, toggleRestrictionModal} from 'actions'
import {sideral300, sky500} from 'constant'
import images from 'res'

import config from 'config'
import {getRequestParams} from 'utils/pagination'

import TableAccounts from './TableAccounts'
import GenericEmptyState from 'components/partials/GenericEmptyState'
import TableWrapper from 'components/partials/tables/TableWrapper'
import PlanUtils from 'utils/plan'

export default function Accounts() {
  const dispatch = useDispatch()
  const googleWorkspaceIntegrationsState = useSelector(
    state => state.googleWorkspaceIntegrations
  )
  const microsoft365IntegrationsState = useSelector(
    state => state.microsoft365Integrations
  )
  const companyEmailsState = useSelector(state => state.companyEmails)

  const pageSize = 10

  const initialFormState = {
    googleWorkspaceFilters: {
      activeAccountsOnly: false,
      adminOnly: false,
      mfaDisabledOnly: false,
      suspendedAccountsOnly: false,
    },
    microsoft365Filters: {
      adminOnly: false,
      mfaDisabledOnly: false,
    },
    source: {
      workspace: false,
      microsoft365: false,
      googleSignIn: false,
      registration: false,
      web: false,
    },
  }

  const planUtils = PlanUtils()

  const [companyEmails, setCompanyEmails] = useState([])
  const [filterForm, setFilterForm] = useState(initialFormState)
  const [filterValue, setFilterValue] = useState('')
  const [appliedFilters, setAppliedFilters] = useState(initialFormState)
  const [sortOptions, setSortOptions] = useState([])
  const [storageWasRetrieved, setStorageWasRetrieved] = useState(false)

  const columns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: i18next.t('labels.name'),
            accessor: 'name',
            width: 190,
            sortType: 'alphanumeric',
          },
          {
            Header: i18next.t('labels.email'),
            accessor: 'email',
            width: 190,
            sortType: 'alphanumeric',
          },
          {
            Header: i18next.t('people.sources'),
            accessor: 'sources',
            width: 170,
            sortType: 'alphanumeric',
          },
          {
            Header: i18next.t('tables.admin'),
            accessor: 'isAdmin',
            width: 100,
            disableSortBy: true, // TODO: implement sorting
          },
          {
            Header: 'MFA',
            accessor: 'hasMfa',
            width: 100,
            disableSortBy: true, // TODO: implement sorting
          },
          {
            Header: i18next.t('people.breachCount'),
            accessor: 'numberOfBreaches',
            width: 150,
            sortType: (a, b) => {
              return (
                parseInt(a.original.numberOfBreaches, 10) -
                parseInt(b.original.numberOfBreaches, 10)
              )
            },
          },
        ],
      },
    ],
    []
  )

  function countActiveIntegrations() {
    if (!googleWorkspaceIntegrationsState) return

    const workspaceIntegrations =
      googleWorkspaceIntegrationsState.filter(
        item => item.status === 'active'
      ) || []

    const microsoft365Integrations =
      microsoft365IntegrationsState.filter(item => item.status === 'active') ||
      []

    return workspaceIntegrations.length + microsoft365Integrations.length
  }

  function prepareAppliedFilters(obj) {
    const appliedFilters = {}
    for (const key in obj) {
      const keyProps = Object.entries(obj[key])
        .filter(([, subValue]) => subValue === true)
        .map(([subKey]) => subKey)

      if (keyProps.length) appliedFilters[key] = keyProps
    }
    return appliedFilters
  }

  // This function compares the filters stored in local storage with the initialFormState.
  // If the keys of both objects are equal, the filters are considered valid.
  // If new filters are introduced and the storage contains outdated filters, the function returns false to prevent app crash

  function hasValidKeys(initialFormState, storedFilters) {
    const keysA = Object.keys(initialFormState)
    const keysB = Object.keys(storedFilters)

    if (keysA.length !== keysB.length) return false

    for (let i = 0; i < keysA.length; i++) {
      const currentKey = keysA[i]
      if (!keysB.includes(currentKey)) return false

      const valueA = initialFormState[currentKey]
      const valueB = storedFilters[currentKey]

      if (typeof valueA === 'object' && typeof valueB === 'object')
        if (!hasValidKeys(valueA, valueB)) return false
    }
    return true
  }

  const orderEmailSources = sources => {
    const desiredOrder = [
      'workspace',
      'microsoft365',
      'googleSignIn',
      'registration',
      'web',
      'manual',
    ]
    const output = []

    for (const source of desiredOrder) {
      if (sources.includes(source)) output.push(source)
    }

    return output
  }

  const getSourceHasMfa = (email, orderedSources) => {
    const output = []

    for (const source of orderedSources) {
      let hasMfa = null
      if (source === 'workspace' && email.googleWorkspace)
        hasMfa = email.googleWorkspace.mfa

      if (source === 'microsoft365' && email.microsoft365)
        hasMfa =
          typeof email.microsoft365.mfaCapable === 'boolean' &&
          typeof email.microsoft365.mfaRegistered === 'boolean'
            ? email.microsoft365.mfaCapable && email.microsoft365.mfaRegistered
            : null

      if (source === 'registration' && email.unxpose) hasMfa = email.unxpose.mfa

      output.push(hasMfa)
    }

    return output
  }

  const getSourceIsAdmin = (email, orderedSources) => {
    const output = []

    for (const source of orderedSources) {
      let isAdmin = null
      if (source === 'workspace' && email.googleWorkspace)
        isAdmin = email.googleWorkspace.admin

      if (source === 'microsoft365' && email.microsoft365)
        isAdmin = email.microsoft365.admin

      output.push(isAdmin)
    }

    return output
  }

  const formatEmails = emails => {
    const formattedEmails = emails.map(email => {
      const output = {}

      if (!email.sources) email.sources = []

      const orderedSources = orderEmailSources(email.sources)

      output.id = email.id
      output.name = email.name || '-'
      output.email = email.email || '-'
      output.numberOfBreaches = email.numberOfBreaches
      output.sources = orderedSources
      output.hasMfa = getSourceHasMfa(email, orderedSources)
      output.isAdmin = getSourceIsAdmin(email, orderedSources)

      return output
    })

    return formattedEmails
  }

  useEffect(() => {
    try {
      const storedFilters = localStorage.getItem('googleWorkspaceFilters')

      if (!storedFilters) {
        dispatch(loading({companyEmails: true}))
        dispatch(fetchCompanyEmails({limit: pageSize}))
        return
      }

      const parsedFilters = JSON.parse(storedFilters)

      const filterShouldBeUpdated =
        hasValidKeys(parsedFilters, initialFormState) &&
        JSON.stringify(parsedFilters) !== JSON.stringify(initialFormState)

      if (filterShouldBeUpdated) {
        dispatch(loading({companyEmails: true}))

        dispatch(
          fetchCompanyEmails({
            filterOptions: prepareAppliedFilters(parsedFilters),
            limit: pageSize,
          })
        )

        setFilterForm(parsedFilters)
        setAppliedFilters(parsedFilters)
        return
      }
      dispatch(fetchCompanyEmails({limit: pageSize}))
    } catch (e) {
      dispatch(fetchCompanyEmails({limit: pageSize}))
      console.trace(e)
    } finally {
      setStorageWasRetrieved(true)
    }
  }, [])

  useEffect(() => {
    if (!companyEmailsState || !companyEmailsState.data) return

    setCompanyEmails(formatEmails(companyEmailsState.data))
  }, [companyEmailsState])

  useEffect(() => {
    if (!storageWasRetrieved || !filterForm) return

    localStorage.setItem('googleWorkspaceFilters', JSON.stringify(filterForm))
  }, [filterForm])

  const handleGenerateCSV = () => {
    if (planUtils.hasPlanRestriction())
      return dispatch(
        toggleRestrictionModal({contentId: 'downloadCsvBtn', showModal: true})
      )

    const url = new URL(`${config.SERVER_URL}/people/accounts`)

    url.search = new URLSearchParams(
      getRequestParams({
        format: 'csv',
        filterValue: filterValue,
        filterOptions: prepareAppliedFilters(filterForm),
        sortBy: sortOptions,
      })
    ).toString()

    window.location.href = url
  }

  return (
    <>
      <TableWrapper
        id="accounts-table-wrap"
        title={i18next.t('people.accounts')}
        loaded={companyEmailsState}
        button={
          companyEmails && companyEmails.length ? (
            <button
              className="ml-auto"
              onClick={handleGenerateCSV}
              title={i18next.t('buttons.downloadCSV')}>
              <images.DownloadIcon height="24" width="24" color={sky500} />
            </button>
          ) : null
        }
        customBorder="rounded-lg rounded-b-none border border-b-0 border-sideral-100"
        padding="p-4 pb-2"
        table={
          <TableAccounts
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            filterForm={filterForm}
            setFilterForm={setFilterForm}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            setSortOptions={setSortOptions}
            initialFormState={initialFormState}
            fetchData={paginationProps =>
              dispatch(fetchCompanyEmails(paginationProps))
            }
            data={companyEmails || []}
            columns={columns}
            total={companyEmailsState && companyEmailsState.total}
            pageCount={companyEmailsState && companyEmailsState.pages}
            pageNumber={0}
            pageSize={pageSize}
            defaultSorted={[
              {
                id: 'numberOfBreaches',
                desc: true,
              },
            ]}
            emptyStateWithFilter={
              <GenericEmptyState
                icon={<images.PeopleIcon width="55" color={sideral300} />}
                title={i18next.t('tables.noMatchWithSelectedFilters')}
                body={i18next.t('tables.tryDifferentFilters')}
                margin={'m-2 mt-4'}
              />
            }
            emptyStateWithoutFilter={
              <GenericEmptyState
                icon={<images.PeopleIcon width="55" color={sideral300} />}
                title={i18next.t('googleWorkspace.noUsers')}
                body={i18next.t('googleWorkspace.workspaceEmpty')}
                margin={'m-2 mt-4'}
              />
            }
          />
        }
      />
      <div className="bg-astral-50 border border-sideral-100 uppercase rounded-b-lg text-xs">
        <div className="flex justify-end items-center pr-4 py-3">
          <span>{i18next.t('googleWorkspace.activeIntegrations')}:</span>
          <span className="font-medium ml-1">{countActiveIntegrations()}</span>
        </div>
      </div>
    </>
  )
}
