import React from 'react'
import PropTypes from 'prop-types'
import TrashIcon from 'res/icons/trashIcon'

export default function AlternateActionButton(props) {
  function setSize(size) {
    switch (size) {
      case 'sm':
        return {text: 'text-xs', trash: '16'}
      case 'md':
        return {text: 'text-sm md:text-base', trash: '20'}
      default:
        return {text: 'text-sm md:text-base', trash: '20'}
    }
  }

  return (
    <button
      disabled={props.disabled}
      type={props.type || 'button'}
      className={`${props.margin} dm-mono inline-flex items-center outline-none text-mars-700 hover:underline`}
      onClick={props.onClick}>
      {props.trashIcon && (
        <span className="pr-1">
          <TrashIcon
            size={setSize(props.size).trash}
            height={setSize(props.size).trash}
            direction="left"
          />
        </span>
      )}
      <span className={`${setSize(props.size).text} uppercase`}>
        {props.text}
      </span>
    </button>
  )
}

AlternateActionButton.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  trashIcon: PropTypes.bool,
  onClick: PropTypes.func,
  margin: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md']),
  disabled: PropTypes.bool,
}
