import React from 'react'
import PropTypes from 'prop-types'

export default function Spinner({color, visible, height}) {
  return (
    <svg
      style={{height: height ? height + 'px' : null}}
      className={'spinner-container ' + (visible ? '' : 'hidden')}
      viewBox="0 0 44 44">
      <circle
        className="path"
        cx="22"
        cy="22"
        r="20"
        fill="none"
        stroke={color || '#0A50A0'}
        strokeWidth="4"></circle>
    </svg>
  )
}

Spinner.propTypes = {
  color: PropTypes.string,
  visible: PropTypes.any,
  height: PropTypes.any,
}
