import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import PrimaryButton from './PrimaryButton'

export default function DropdownButton({label, buttonTheme, items}) {
  const [isOpen, setIsOpen] = useState(false)
  const [openDirection, setOpenDirection] = useState('up')

  const dropdownRef = useRef(null)

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  const onClickDropdownBtn = () => {
    const dropdownRect = dropdownRef.current.getBoundingClientRect()
    const viewportHeight = window.innerHeight

    dropdownRect.bottom + 230 > viewportHeight
      ? setOpenDirection('up')
      : setOpenDirection('down')

    setIsOpen(!isOpen)
  }

  const onClickDropdownItem = item => {
    item.onClick()
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside)
    return () => document.removeEventListener('mouseup', handleClickOutside)
  }, [dropdownRef])

  return (
    <div className="relative" ref={dropdownRef}>
      <PrimaryButton
        text={label}
        theme={buttonTheme}
        onClick={onClickDropdownBtn}
      />

      {isOpen && (
        <ul
          className={`absolute right-[30px] w-40 max-w-56 bg-white text-left border border-sideral-100 rounded shadow-lg py-1 z-50 ${
            openDirection === 'up' ? '-top-[140px]' : 'top-[20px]'
          }`}>
          {items.map((item, i) => (
            <li
              key={i}
              className={`px-4 py-2 ${
                item.disabled
                  ? 'opacity-50 pointer-events-none'
                  : 'hover:bg-lightBlue cursor-pointer'
              }`}
              onClick={() => onClickDropdownItem(item)}>
              <div className="flex items-center">
                {item.icon && <div className="mr-2">{item.icon}</div>}
                <div>{item.name}</div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

DropdownButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonTheme: PropTypes.string,
  items: PropTypes.array,
  position: PropTypes.string,
}
