import React, {useEffect} from 'react'
import i18next from 'i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
  fetchAwsIntegrations,
  fetchAwsStats,
  fetchAzureIntegrations,
  fetchAzureStats,
  fetchGcpIntegrations,
  fetchGcpStats,
  loading,
} from 'actions'
import {hasCloudIntegration} from 'utils'

import Spinner from 'components/partials/Spinner'
import awsIcon from 'res/icons/awsIcon.svg'
import azureIcon from 'res/icons/azure.svg'
import gcpIcon from 'res/icons/gcpIcon.png'
import CloudEmptyState from './CloudEmptyState'
import PlanUtils from 'utils/plan'

const integrationsCountPerStatus = (
  integrations,
  provider,
  hasPlanRestriction
) => {
  const accountIdStyle = 'w-full text-left text-sm font-thin bg-astral-50 p-2'

  const integrationNames = {
    aws: {singular: 'account', plural: 'accounts'},
    azure: {singular: 'directory', plural: 'directories'},
    gcp: {singular: 'project', plural: 'projects'},
  }

  const {singular, plural} = integrationNames[provider] || {}

  const countActive = countIntegrations(integrations, 'active')
  const countFailed = countIntegrations(integrations, 'failed')

  if (hasPlanRestriction) {
    return (
      <div className={accountIdStyle}>
        {i18next.t('cloud.noIntegrationsBeingMonitored')}
      </div>
    )
  }

  return (
    <div className={accountIdStyle}>
      <span className="font-medium">{countActive} </span>
      {countActive === 1
        ? i18next.t(`cloud.${singular}BeingMonitored`).toLowerCase() + '.'
        : i18next.t(`cloud.${plural}BeingMonitored`).toLowerCase() + '.'}

      {countFailed > 0 && (
        <>
          <span className="font-medium"> {countFailed} </span>
          {countFailed > 1
            ? ' ' + i18next.t(`cloud.${plural}Failing`).toLowerCase() + '.'
            : ' ' + i18next.t(`cloud.${singular}Failing`).toLowerCase() + '.'}
        </>
      )}
    </div>
  )
}

const countIntegrations = (providerIntegrations, status) => {
  if (!providerIntegrations || !Array.isArray(providerIntegrations)) return 0

  const integrations = providerIntegrations.filter(
    integration => integration.status === status
  )

  return integrations.length
}

export default function Discovery() {
  const loadingState = useSelector(state => state.loading)
  const awsIntegrationsState = useSelector(state => state.awsIntegrations)
  const statsDnsState = useSelector(state => state.statsDns)
  const networkStatsState = useSelector(state => state.networkStats)
  const statsWebState = useSelector(state => state.statsWeb)
  const awsStatsState = useSelector(state => state.awsStats)
  const gcpIntegrationsState = useSelector(state => state.gcpIntegrations)
  const gcpStatsState = useSelector(state => state.gcpStats)
  const azureIntegrationsState = useSelector(state => state.azureIntegrations)
  const azureStatsState = useSelector(state => state.azureStats)

  const hasPlanRestriction = PlanUtils().hasPlanRestriction()

  const dispatch = useDispatch()

  const containerStyle =
    'relative w-full flex flex-wrap text-center items-center border border-sideral-100 font-medium rounded-md p-4'
  const innerContainerStyle = 'flex-1 flex-nowrap p-4'
  const spinnerContainer =
    'flex items-center border border-sideral-100 rounded-md w-full md:w-4/12'
  const titleStyle = 'w-full text-left text-lg'
  const customBorderStyle = 'h-24 w-2px bg-sideral-100'
  const dataStyle = 'text-5xl text-sky-900'
  const labelStyle = 'text-sideral-300 uppercase font-light'

  //   cloud-exclusive styles
  const providerWrapperStyle = 'w-full flex flex-wrap text-center items-center'

  useEffect(() => {
    dispatch(
      loading({
        awsIntegrations: {initialState: true},
        gcpIntegrations: {initialState: true},
        azureIntegrations: {initialState: true},
        awsStats: true,
        gcpStats: true,
        azureStats: true,
      })
    )

    dispatch(fetchAwsIntegrations())
    dispatch(fetchAwsStats())

    dispatch(fetchAzureIntegrations())
    dispatch(fetchAzureStats())

    dispatch(fetchGcpIntegrations())
    dispatch(fetchGcpStats())
  }, [])

  const shouldDisplayAwsDiscovery = () => {
    return (
      awsIntegrationsState &&
      awsIntegrationsState.length &&
      awsStatsState &&
      awsStatsState.general
    )
  }

  const shouldDisplayGcpDiscovery = () => {
    return (
      gcpIntegrationsState &&
      gcpIntegrationsState.length &&
      gcpStatsState &&
      gcpStatsState.general
    )
  }

  const shouldDisplayAzureDiscovery = () => {
    return (
      azureIntegrationsState &&
      azureIntegrationsState.length &&
      azureStatsState &&
      azureStatsState.general
    )
  }

  const isCloudIntegrationsLoaded = !(
    loadingState?.awsIntegrations?.initialState ||
    loadingState?.gcpIntegrations?.initialState ||
    loadingState?.azureIntegrations?.initialState
  )

  const isCloudStatsLoaded = !(
    loadingState?.awsStats ||
    loadingState?.gcpStats ||
    loadingState?.azureStats
  )

  const AwsDiscovery = () => {
    if (shouldDisplayAwsDiscovery()) {
      return (
        <>
          <div className="flex flex-wrap items-center w-full py-4 mt-4">
            <img src={awsIcon} alt="AWS" className="w-12 mr-4" />{' '}
            <span className="font-medium">Amazon Web Services</span>
          </div>
          <div className={providerWrapperStyle}>
            {integrationsCountPerStatus(
              awsIntegrationsState,
              'aws',
              hasPlanRestriction
            )}
            <div className="sm:flex items-center w-full">
              <div className={innerContainerStyle}>
                <div className={dataStyle}>{awsStatsState.general.users}</div>
                <p className={labelStyle}>IAM Users</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {awsStatsState.general.iamPolicies}
                </div>
                <p className={labelStyle}>IAM Policies</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>{awsStatsState.general.buckets}</div>
                <p className={labelStyle}>S3 Buckets</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {awsStatsState.general.ebsVolumes}
                </div>
                <p className={labelStyle}>EBS Volumes</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>{awsStatsState.general.kmsKeys}</div>
                <p className={labelStyle}>KMS keys</p>
              </div>
            </div>

            <div className="sm:flex items-center w-full">
              <div className={innerContainerStyle}>
                <div className={dataStyle}>{awsStatsState.general.ec2Amis}</div>
                <p className={labelStyle}>EC2 AMIs</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {awsStatsState.general.ec2Instances}
                </div>
                <p className={labelStyle}>EC2 Instances</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {awsStatsState.general.ec2SecurityGroups}
                </div>
                <p className={labelStyle}>EC2 Security Groups</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>{awsStatsState.general.ec2Vpcs}</div>
                <p className={labelStyle}>EC2 VPCs</p>
              </div>
            </div>

            <div className="sm:flex items-center w-full">
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {awsStatsState.general.rdsInstances}
                </div>
                <p className={labelStyle}>RDS Instances</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {awsStatsState.general.rdsSnapshots}
                </div>
                <p className={labelStyle}>RDS snapshots</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {awsStatsState.general.route53domains}
                </div>
                <p className={labelStyle}>Route53 Domains</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {awsStatsState.general.route53HostedZones}
                </div>
                <p className={labelStyle}>Route53 Hosted Zones</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {awsStatsState.general.cloudtrailTrails}
                </div>
                <p className={labelStyle}>CloudTrail trails</p>
              </div>
            </div>
          </div>
        </>
      )
    }
  }

  const GcpDiscovery = () => {
    if (shouldDisplayGcpDiscovery()) {
      return (
        <>
          <div className="flex flex-wrap items-center w-full py-4 mt-4">
            <img src={gcpIcon} alt="GCP" className="w-10 mr-4" />{' '}
            <span className="font-medium">Google Cloud Platform</span>
          </div>

          <div className={providerWrapperStyle}>
            {integrationsCountPerStatus(
              gcpIntegrationsState,
              'gcp',
              hasPlanRestriction
            )}
            <div className="sm:flex items-center w-full">
              <div className={`${innerContainerStyle}`}>
                <div className={dataStyle}>{gcpStatsState.general.buckets}</div>
                <p className={labelStyle}>Buckets</p>
              </div>
              <span className={customBorderStyle} />
              <div className={`${innerContainerStyle}`}>
                <div className={dataStyle}>
                  {gcpStatsState.general.principals}
                </div>
                <p className={labelStyle}>Principals</p>
              </div>
              <span className={customBorderStyle} />
              <div className={`${innerContainerStyle}`}>
                <div className={dataStyle}>
                  {gcpStatsState.general.serviceAccountKeys}
                </div>
                <p className={labelStyle}>Service Account Keys</p>
              </div>
            </div>
            <div className="sm:flex items-center w-full">
              <div className={`${innerContainerStyle}`}>
                <div className={dataStyle}>{gcpStatsState.general.kmsKeys}</div>
                <p className={labelStyle}>KMS Keys</p>
              </div>
              <span className={customBorderStyle} />
              <div className={`${innerContainerStyle}`}>
                <div className={dataStyle}>
                  {gcpStatsState.general.sqlInstances}
                </div>
                <p className={labelStyle}>SQL instances</p>
              </div>
              <span className={customBorderStyle} />
              <div className={`${innerContainerStyle}`}>
                <div className={dataStyle}>
                  {gcpStatsState.general.instances}
                </div>
                <p className={labelStyle}>VM instances</p>
              </div>
            </div>
          </div>
        </>
      )
    }
  }

  const AzureDiscovery = () => {
    if (shouldDisplayAzureDiscovery()) {
      return (
        <>
          <div className="flex flex-wrap items-center w-full py-4 mt-4">
            <img src={azureIcon} alt="Azure" className="w-10 mr-4" />{' '}
            <span className="font-medium">Microsoft Azure</span>
          </div>
          <div className={providerWrapperStyle}>
            {integrationsCountPerStatus(
              azureIntegrationsState,
              'azure',
              hasPlanRestriction
            )}
            <div className="sm:flex items-center w-full">
              <div className={innerContainerStyle}>
                <div className={dataStyle}>{azureStatsState.general.disks}</div>
                <p className={labelStyle}>Disks</p>
              </div>
              <span className={customBorderStyle} />

              <div className={innerContainerStyle}>
                <div className={dataStyle}>{azureStatsState.general.vms}</div>
                <p className={labelStyle}>Virtual machines</p>
              </div>

              <span className={`${customBorderStyle} lg:block`} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {azureStatsState.general.storageAccounts}
                </div>
                <p className={labelStyle}>Storage accounts</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {azureStatsState.general.blobContainers}
                </div>
                <p className={labelStyle}>Blob containers</p>
              </div>
            </div>
            <div className="sm:flex items-center w-full">
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {azureStatsState.general.sqlDatabases}
                </div>
                <p className={labelStyle}>SQL Databases</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {azureStatsState.general.sqlServers}
                </div>
                <p className={labelStyle}>SQL Servers</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {azureStatsState.general.mysqlServers}
                </div>
                <p className={labelStyle}>Mysql Servers</p>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>
                  {azureStatsState.general.postgresqlServers}
                </div>
                <p className={labelStyle}>Postgresql Servers</p>
              </div>
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <>
      <section className="p-4">
        <div className="flex flex-wrap lg:flex-nowrap text-astral-900 ">
          {statsDnsState ? (
            <div className={`${containerStyle} lg:w-4/12 min-w-18`}>
              <h1 className={titleStyle}>/DNS</h1>
              <div className={innerContainerStyle}>
                <div className={dataStyle}>{statsDnsState.domains}</div>
                <label className={labelStyle}>{i18next.t('dns.domains')}</label>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>{statsDnsState.subdomains}</div>
                <label className={labelStyle}>
                  {i18next.t('dns.subdomains')}
                </label>
              </div>
            </div>
          ) : (
            <div className={spinnerContainer}>
              <Spinner />
            </div>
          )}
          {networkStatsState ? (
            <div
              className={`${containerStyle} lg:w-4/12 min-w-18 mt-4 lg:mt-0 lg:ml-4`}>
              <h1 className={titleStyle}>/NETWORK</h1>
              <div className={innerContainerStyle}>
                <div className={dataStyle}>{networkStatsState.hosts}</div>
                <label className={labelStyle}>Hosts</label>
              </div>
              <span className={customBorderStyle} />
              <div className={innerContainerStyle}>
                <div className={dataStyle}>{networkStatsState.services}</div>
                <label className={labelStyle}>
                  {i18next.t('network.services')}
                </label>
              </div>
            </div>
          ) : (
            <div className={spinnerContainer}>
              <Spinner />
            </div>
          )}

          {statsWebState ? (
            <div className={`${containerStyle} lg:w-4/12 mt-4 lg:mt-0 lg:ml-4`}>
              <h1 className={titleStyle}>/WEB</h1>
              <div className={innerContainerStyle}>
                <div className={dataStyle}>{statsWebState.webAppsCount}</div>
                <label className={labelStyle}>
                  {i18next.t('web.webApplications')}
                </label>
              </div>
            </div>
          ) : (
            <div className={spinnerContainer}>
              <Spinner />
            </div>
          )}
        </div>

        <div className="flex flex-wrap md:flex-nowrap mt-4">
          <div className={containerStyle}>
            <h1 className={titleStyle}>/CLOUD</h1>

            {!isCloudStatsLoaded || !isCloudIntegrationsLoaded ? (
              <Spinner />
            ) : !hasCloudIntegration(
                awsStatsState,
                gcpStatsState,
                azureStatsState
              ) ? (
              <CloudEmptyState />
            ) : (
              <>
                <AwsDiscovery />
                <GcpDiscovery />
                <AzureDiscovery />
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}
