import React from 'react'
import PropTypes from 'prop-types'

export default function ReviewBoxIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 -960 960 960"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm283-310 117-71 117 71-31-133 104-90-137-11-53-126-53 126-137 11 104 90-31 133Z"
        fill={color}
      />
    </svg>
  )
}

ReviewBoxIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
