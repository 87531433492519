import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

export default function VideoModal({videoUrl, setShowModal}) {
  useEffect(() => {
    document.addEventListener('mouseup', e => {
      const toggleRestrictionModal = document.querySelector('#videoModal')
      if (e.target === toggleRestrictionModal) setShowModal(false)
    })
  }, [])

  return (
    <div
      id="videoModal"
      className="modal-bg fixed top-0 left-0 w-screen h-screen z-50">
      <div className="fixed bg-white border rounded-md text-left bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 max-w-2xl w-full h-[450px] pt-6 pb-24 px-12">
        <div className="mb-6">{i18next.t('titles.watchDemo')}</div>
        <div className="relative group basis-2/5 h-full">
          <div className="z-50 absolute top-0 left-0 w-full h-full">
            <iframe
              width="100%"
              height="100%"
              src={videoUrl}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
          <div className="absolute -bottom-[10px] -right-[10px] w-4/5 h-4/5 group-hover:w-full group-hover:h-full z-40 transition-all ease-in-out duration-200 bg-gradient-to-r from-violet to-ripeOrange" />
        </div>

        <button
          className="absolute top-0 right-0 p-6"
          onClick={() => setShowModal(false)}>
          <i className="icofont-close text-xl" />
        </button>
      </div>
    </div>
  )
}

VideoModal.propTypes = {
  videoUrl: PropTypes.string,
  setShowModal: PropTypes.func,
}
