import i18next from 'i18next'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'

import images from 'res'
import {updateFindingAssignee} from 'actions'
import {useDispatch, useSelector} from 'react-redux'

const FindingAssignment = ({
  currentAssignee,
  setCurrentAssignee,
  fetchFinding,
  fetchFindingActivity,
  finding,
  findingType,
  params,
}) => {
  const dispatch = useDispatch()
  const csrfState = useSelector(state => state.csrf)
  const companyMembersState = useSelector(state => state.companyMembers)

  const filterInput = React.useRef()
  const membersMenu = React.useRef()
  const membersButton = React.useRef()

  const [assignFindingVisibility, setAssignFindingVisibility] =
    useState('hidden')
  const [searchQuery, setSearchQuery] = useState('')
  const [membersList, setMembersList] = useState([])
  const filteredMembers = membersList.filter(member =>
    member.fullName.toLowerCase().includes(searchQuery)
  )

  const clearSearchQuery = () => setSearchQuery('')

  const closeMenu = event => {
    if (!membersMenu.current || !membersButton.current) return

    if (membersButton.current.contains(event.target)) return

    if (!membersMenu.current.contains(event.target))
      setAssignFindingVisibility('hidden')
  }

  const handleFilter = e => {
    const inputValue = e.target.value.toLowerCase()

    setSearchQuery(inputValue)
  }

  const handleAssignFindingVibisiblity = () => {
    assignFindingVisibility === 'hidden'
      ? setAssignFindingVisibility('flex')
      : setAssignFindingVisibility('hidden')
  }

  const formatCompanyMembers = (companyMembers, currentAssignee) => {
    if (!companyMembers || !Array.isArray(companyMembers)) return []

    const formattedCompanyMembers = companyMembers.map(member => {
      const fullName = `${member.firstName || ''} ${member.lastName || ''}`
      const initials = `${(member.firstName && member.firstName[0]) || ''}${
        (member.lastName && member.lastName[0]) || ''
      }`

      return {id: member.id, email: member.email, fullName, initials}
    })

    const assigneeIndex = formattedCompanyMembers.findIndex(
      member => member.id === currentAssignee.id
    )

    if (assigneeIndex !== -1) {
      const targetMember = formattedCompanyMembers.splice(assigneeIndex, 1)[0]
      formattedCompanyMembers.unshift(targetMember)
    }

    return formattedCompanyMembers
  }
  const updateAssignee = member => {
    dispatch(
      updateFindingAssignee({
        assignTo: member.id,
        findingType,
        findingId: params.id,
        fetchFinding,
        fetchFindingActivity,
        successMessage: i18next.t('finding.findingAssignedTo', {
          assignee: member.fullName,
        }),
        _csrf: csrfState,
      })
    )

    clearSearchQuery()
    if (assignFindingVisibility !== 'hidden')
      setAssignFindingVisibility('hidden')
  }

  const removeAssignee = () => {
    dispatch(
      updateFindingAssignee({
        assignTo: null,
        findingType,
        findingId: params.id,
        fetchFinding,
        fetchFindingActivity,
        successMessage: i18next.t('finding.findingUnassigned'),
        _csrf: csrfState,
      })
    )

    clearSearchQuery()
    if (assignFindingVisibility !== 'hidden')
      setAssignFindingVisibility('hidden')
  }

  useEffect(() => {
    if (!finding.assignedTo && currentAssignee.id) return setCurrentAssignee({})

    const assignee = membersList.find(
      member => member.id === finding.assignedTo
    )

    if (!assignee) return

    if (currentAssignee && assignee && currentAssignee.id !== assignee.id)
      setCurrentAssignee(assignee || {})
  }, [finding, membersList])

  useEffect(() => {
    if (filterInput.current && assignFindingVisibility === 'flex') {
      filterInput.current.focus()
    }
  }, [assignFindingVisibility])

  useEffect(() => {
    if (!companyMembersState) return

    const formattedCompanyMembers = formatCompanyMembers(
      [...companyMembersState],
      currentAssignee
    )

    setMembersList(formattedCompanyMembers)
  }, [companyMembersState, currentAssignee])

  useEffect(() => {
    addEventListener('mouseup', closeMenu)

    return () => removeEventListener('mouseup', closeMenu)
  }, [])

  return (
    <div className="relative mr-8">
      <div
        ref={membersButton}
        onClick={handleAssignFindingVibisiblity}
        className="flex flex-col items-center group cursor-pointer">
        <p className="text-xs text-sideral-700 font-medium">
          {i18next.t('finding.assignment')}
        </p>
        <span className="tooltip select-none inline-flex items-center justify-center w-10 h-10 rounded-full tracking-wider border group-hover:border-sky-500 border-sideral-100 text-ink p-3 bg-white ml-1 mt-1">
          {currentAssignee && currentAssignee.id ? (
            currentAssignee.initials
          ) : (
            <i className="icofont-user text-sideral-100 text-xl" />
          )}
          {currentAssignee && currentAssignee.id ? (
            <div
              style={{marginTop: '-100px'}}
              className="tooltip-text relative text-xs bg-white border rounded-md shadow-md flex flex-wrap transform translate-x-1/2 mr-10">
              <div className="absolute bottom-0 left-0">
                <div className="w-2 h-2 absolute bottom-0 ml-4 border-b border-r bg-white border-sideral-100 transform rotate-45 -mr-1 -mb-1"></div>
              </div>

              <span className="w-full truncate tracking-normal text-left font-medium text-sideral-700 px-1 pt-1">
                {currentAssignee.fullName}
              </span>
              <span className="w-full truncate tracking-normal text-left font-thin text-sideral-400 text-xs px-1 pb-1">
                {currentAssignee.email}
              </span>
            </div>
          ) : (
            <></>
          )}
        </span>
      </div>

      <div
        id="members-menu"
        ref={membersMenu}
        style={{maxHeight: '324px'}}
        className={`${assignFindingVisibility} overflow-y-auto menu-scrollbar shadow-md rounded-md border flex-wrap bg-white absolute bottom-0 transform translate-y-full py-2 w-64`}>
        <div className="relative flex flex-wrap w-full border-b border-sideral-50">
          <i className="absolute icofont-search-1 text-xs text-gray-500 ml-5 h-5 top-1/2 transform -translate-y-1/2" />
          <input
            ref={filterInput}
            type="text"
            placeholder={i18next.t('finding.placeholderSearch')}
            className="text-sm placeholder:text-xs placeholder:text-gray-500 rounded-md border py-1 pl-8 pr-3 w-full mb-2 mx-2"
            onChange={handleFilter}
            value={searchQuery}
          />
        </div>

        {filteredMembers && filteredMembers.length ? (
          filteredMembers.map((member, index) => {
            if (member.id === currentAssignee.id)
              return (
                <span
                  className={`truncate flex flex-wrap cursor-auto justify-center items-center text-white bg-mediumBlue select-none text-sm font-normal w-full p-2`}
                  key={index}>
                  <span className="group">
                    <p>{member.fullName}</p>
                    <span className="w-full text-xs">{member.email}</span>
                  </span>
                  <button
                    onClick={removeAssignee}
                    className="hover:scale-105  ml-auto border border-white rounded-full p-px">
                    <images.CloseIcon width="14" color="white" />
                  </button>
                </span>
              )

            return (
              <span
                onClick={() => updateAssignee(member)}
                className={`truncate cursor-pointer hover:bg-lightBlue hover:border-lightBlue select-none text-sm font-normal p-2 w-full`}
                key={index}>
                <span>{member.fullName}</span>
                <span className="text-xs block text-sideral-400">
                  {member.email}
                </span>
              </span>
            )
          })
        ) : (
          <div className="text-center text-sm text-sideral-400 p-4 w-full">
            <p className="font-medium">{i18next.t('tables.noResultsFound')}</p>
            <p className="font-thin text-xs">
              {i18next.t('tables.tryOtherTerms')}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default FindingAssignment

FindingAssignment.propTypes = {
  currentAssignee: PropTypes.object,
  fetchFinding: PropTypes.func,
  fetchFindingActivity: PropTypes.func,
  finding: PropTypes.object,
  findingType: PropTypes.string,
  params: PropTypes.object,
  setCurrentAssignee: PropTypes.func,
}
