import React from 'react'
import {useDispatch} from 'react-redux'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import images from 'res'
import SecondaryButton from './buttons/SecondaryButton'
import {toggleRestrictionModal} from 'actions'
import {useScreenSize} from 'utils'

const TextContent = ({sectionId}) => {
  const dispatch = useDispatch()

  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:w-auto bg-white py-6 px-8 rounded-md border text-center">
      <div className="inline-block m-auto">
        <images.crownIcon width="32" height="32" />
      </div>
      <div>{i18next.t('planInfo.free.section.title')}</div>

      <span className="text-xs font-light">
        {i18next.t('planInfo.free.section.subtitle')}
      </span>

      <SecondaryButton
        text={i18next.t('planInfo.free.section.button')}
        size="sm"
        margin="mt-2"
        onClick={() =>
          dispatch(
            toggleRestrictionModal({contentId: sectionId, showModal: true})
          )
        }
      />
    </div>
  )
}

const SmallScreenTextContent = ({sectionId}) => {
  const dispatch = useDispatch()

  return (
    <div className="flex mt-6">
      <div className="inline-block mr-4">
        <images.crownIcon width="36" height="36" />
      </div>
      <div>
        <div className="text-sm">
          {i18next.t('planInfo.free.section.title')}
        </div>
        <span className="text-xs font-light">
          {i18next.t('planInfo.free.section.subtitle')}
        </span>
        <SecondaryButton
          text={i18next.t('planInfo.free.section.button')}
          size="sm"
          onClick={() =>
            dispatch(
              toggleRestrictionModal({contentId: sectionId, showModal: true})
            )
          }
        />
      </div>
    </div>
  )
}

const PlaceholderContent = ({sectionId}) => {
  const screenSize = useScreenSize()

  const isBigScreen = screenSize.width > 640

  return (
    <div className="relative p-6 w-full h-full">
      <img src={images[sectionId]} className="blur-[2px] min-h-[150px]" />
      {isBigScreen ? (
        <TextContent sectionId={sectionId} />
      ) : (
        <SmallScreenTextContent sectionId={sectionId} />
      )}
    </div>
  )
}

export default PlaceholderContent

PlaceholderContent.propTypes = {
  sectionId: PropTypes.string,
}

TextContent.propTypes = {
  sectionId: PropTypes.string,
}

SmallScreenTextContent.propTypes = {
  sectionId: PropTypes.string,
}
