import i18next from 'i18next'
import PropTypes from 'prop-types'
import React, {useEffect, useRef, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {loading} from 'actions'

import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'

const ChangeFindingStatusModal = ({
  modalVisibility,
  setModalVisibility,
  finding,
  updateFinding,
  newFindingStatus,
}) => {
  const userState = useSelector(state => state.user)
  const csrfState = useSelector(state => state.csrf)
  const disabledState = useSelector(state => state.disabled)

  const dispatch = useDispatch()

  const initialFormState = {reason: ''}
  const [form, setForm] = useState(initialFormState)
  const reasonInput = useRef()

  const [selectedOption, setSelectedOption] = useState('')

  function handleDescriptionChange(e) {
    const {name} = e.target
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setForm({...form, [name]: value})
  }

  const handleReasonOptionChange = event => {
    setSelectedOption(event.target.value)
  }

  const getUpdateStatusMessage = (prev, current) => {
    return `${i18next.t('finding.statusUpdatedFrom')} ${i18next.t(
      `status.${prev}`
    )} ${i18next.t('misc.to')} ${i18next.t(`status.${current}`)}`
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (userState?.role === 'read-only')
      return console.debug(`You don't have permission for this action`)

    let description, option

    switch (newFindingStatus) {
      case 'falsePositive':
        description = 'falsePositive'
        option = 'falsePositive'
        break
      case 'closed':
        description = selectedOption === 'other' ? form.reason : selectedOption
        option = selectedOption
        break
      case 'open':
        description = 'open'
        option = 'open'
        break
    }

    dispatch(loading({updatePeopleFinding: true}))

    dispatch(
      updateFinding({
        findingId: finding.id,
        updateStatusMessage: getUpdateStatusMessage(
          finding.status,
          newFindingStatus
        ),
        reason: {
          option,
          description,
        },
        status: newFindingStatus,
        _csrf: csrfState,
      })
    )
  }

  function closeModal(e) {
    const findingStatusModal = document.querySelector('#finding-modal')
    if (e.target === findingStatusModal) {
      setModalVisibility('hidden')
      setSelectedOption('')
    }
  }

  useEffect(() => {
    if (modalVisibility === 'hidden') {
      setForm(initialFormState)
      setSelectedOption('')
      return
    }

    if (reasonInput.current) reasonInput.current.focus()
  }, [modalVisibility])

  useEffect(() => {
    document.addEventListener('mouseup', e => closeModal(e))
  }, [])

  return (
    <div
      id="finding-modal"
      className={`${modalVisibility} modal-bg fixed top-0 left-0 w-screen h-screen text-sm z-50 p-6`}>
      <form
        onSubmit={handleSubmit}
        className="fixed bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/3 max-w-lg w-full bg-white rounded-md shadow-md p-6">
        <div className="text-sideral-900 font-medium leading-none border-b border-sideral-50 text-lg pb-6 mb-6">
          {i18next.t('titles.changeFindingStatus.title')}
        </div>
        {newFindingStatus !== 'falsePositive' && (
          <div
            className="pb-4"
            dangerouslySetInnerHTML={{
              __html: i18next.t('titles.changeFindingStatus.text', {
                status: `<b>${i18next.t(`status.${finding.status}`)}</b>`,
                newStatus: `<b>${i18next.t(`status.${newFindingStatus}`)}</b>`,
                interpolation: {
                  escapeValue: false,
                },
              }),
            }}
          />
        )}
        <div>
          {newFindingStatus === 'closed' ? (
            <div className="flex flex-wrap">
              <div className="w-full">
                <label>
                  <input
                    type="radio"
                    name="reason"
                    required
                    value="mitigated"
                    checked={selectedOption === 'mitigated'}
                    onChange={handleReasonOptionChange}
                  />{' '}
                  {i18next.t(
                    'people.changeBreachFindingStatus.closed.mitigated'
                  )}
                </label>
              </div>

              <div className="w-full">
                <label>
                  <input
                    className="mt-3"
                    type="radio"
                    name="reason"
                    value="removed"
                    checked={selectedOption === 'removed'}
                    onChange={handleReasonOptionChange}
                  />{' '}
                  {i18next.t('people.changeBreachFindingStatus.closed.removed')}
                </label>
              </div>

              <div className="w-full">
                <label>
                  <input
                    className="mt-3"
                    type="radio"
                    name="reason"
                    value="other"
                    checked={selectedOption === 'other'}
                    onChange={handleReasonOptionChange}
                  />{' '}
                  {i18next.t('people.changeBreachFindingStatus.closed.other')}
                </label>
              </div>

              {selectedOption === 'other' && (
                <PrimaryInput
                  margin="w-full mb-6"
                  name="reason"
                  maxLength={500}
                  required={true}
                  type="text"
                  value={form.reason || ''}
                  reference={reasonInput}
                  onChange={handleDescriptionChange}
                />
              )}
            </div>
          ) : (
            <></>
          )}
          {newFindingStatus === 'falsePositive' ? (
            <p className="pb-2">
              {i18next.t('people.changeBreachFindingStatus.falsePositive')}
            </p>
          ) : (
            <></>
          )}
          <div className="flex mt-6">
            <div className="w-full">
              <PrimaryButton
                text={i18next.t('buttons.confirm')}
                type="submit"
                size="full"
                margin="mr-2"
                loading={disabledState}
                disabled={userState?.role === 'read-only'}
              />
            </div>

            <div className="w-full">
              <PrimaryButton
                text={i18next.t('buttons.cancel')}
                theme="blue-outline"
                type="button"
                size="full"
                margin="ml-2"
                onClick={() => {
                  setModalVisibility('hidden')
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

ChangeFindingStatusModal.propTypes = {
  modalVisibility: PropTypes.string,
  setModalVisibility: PropTypes.func,
  history: PropTypes.object,
  finding: PropTypes.object,
  updateFinding: PropTypes.func,
  newFindingStatus: PropTypes.string,
}

export default ChangeFindingStatusModal
