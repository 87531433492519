import i18next from 'i18next'
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {tooltipStyle} from 'constant'

const ProgressBar = ({completed, overallScore, margin, padding}) => {
  let backgroundColor
  let fillColor

  const [fillWidth, setFillWidth] = useState(0)

  const progressStyle = {
    width: `${fillWidth}%`,
    transition: 'width 0.5s ease-in-out',
  }

  const getHoverMessage = (completed, overallScore) => {
    let message

    if (completed === 100 && overallScore === 'A') {
      message = i18next.t('scores.progressBar.perfectScore')
    } else if (completed < 20)
      message = i18next.t('scores.progressBar.lowPercentage')
    else if (completed > 90)
      message = `${100 - completed}% ${i18next.t(
        'scores.progressBar.highPercentage'
      )}`
    else message = i18next.t('scores.progressBar.mediumPercentage')

    return message
  }

  switch (overallScore) {
    case 'A':
      backgroundColor = 'bg-blue-600'
      fillColor = 'bg-blue-400'
      break
    case 'B':
      backgroundColor = 'bg-green-600'
      fillColor = 'bg-green-400'
      break
    case 'C':
      backgroundColor = 'bg-yellow-700'
      fillColor = 'bg-yellow-600'
      break
    case 'D':
      backgroundColor = 'bg-orange-600'
      fillColor = 'bg-orange-400'
      break
    case 'E':
      backgroundColor = 'bg-red-600'
      fillColor = 'bg-red-400'
      break
    case 'F':
      backgroundColor = 'bg-red-800'
      fillColor = 'bg-red-600'
      break
    default:
      backgroundColor = 'bg-gray-500'
      fillColor = 'bg-gray-300'
      break
  }

  useEffect(() => {
    if (completed && completed < 4) completed = 4 // set 4% as minimum width to avoid visual glitch

    setFillWidth(completed)
  }, [completed])

  return (
    <div
      className={`absolute rounded-lg bottom-0 left-0 flex items-center cursor w-full h-6 ${
        padding ? padding : ''
      } ${margin ? margin : ''}`}>
      <div
        className={`${backgroundColor}
         tooltip relative h-4 w-full rounded-lg mx-auto mt-2`}>
        <span
          style={progressStyle}
          className={`${fillColor} 
           h-full rounded-lg absolute top-0 left-0`}
        />
        <span className="roboto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-1 text-white font-bold text-xs">{`${completed}%`}</span>
        <div className={`${tooltipStyle.default} text-xs mt-6`}>
          {getHoverMessage(completed, overallScore)}
        </div>
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  completed: PropTypes.number,
  overallScore: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
}

export default ProgressBar
