import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import ItemModal from './ItemModal'

const ModalControl = ({lgpdItem, modalVisibility, setModalVisibility}) => {
  const [currItem, setCurrItem] = useState(null)

  useEffect(() => {
    if (!lgpdItem) return

    setCurrItem(lgpdItem)
  }, [lgpdItem])

  useEffect(() => {
    if (modalVisibility !== 'hidden' && !currItem) return

    setCurrItem(null)
  }, [modalVisibility])

  return (
    <ItemModal
      modalVisibility={modalVisibility}
      setModalVisibility={setModalVisibility}
      lgpdItem={currItem}
    />
  )
}

export default ModalControl

ModalControl.propTypes = {
  lgpdItem: PropTypes.object,
  modalVisibility: PropTypes.string,
  setModalVisibility: PropTypes.func,
}
