import React from 'react'
import PropTypes from 'prop-types'

export default function AttentionSignal({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 52 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26 5.97315L46.08 40.6665H5.92L26 5.97315ZM1.30666 37.9998C-0.746669 41.5465 1.81333 45.9998 5.92 45.9998H46.08C50.1867 45.9998 52.7467 41.5465 50.6933 37.9998L30.6133 3.30648C28.56 -0.240182 23.44 -0.240182 21.3867 3.30648L1.30666 37.9998ZM23.3333 19.3332V24.6665C23.3333 26.1332 24.5333 27.3332 26 27.3332C27.4667 27.3332 28.6667 26.1332 28.6667 24.6665V19.3332C28.6667 17.8665 27.4667 16.6665 26 16.6665C24.5333 16.6665 23.3333 17.8665 23.3333 19.3332ZM23.3333 32.6665H28.6667V37.9998H23.3333V32.6665Z"
        fill={color || '#FD7F30'}
      />
    </svg>
  )
}

AttentionSignal.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
