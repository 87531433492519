import React from 'react'
import PropTypes from 'prop-types'
import {Tooltip} from 'react-leaflet'

const getCountryTotalPctg = (countryCount, sumCount) =>
  `${((countryCount / sumCount) * 100).toFixed(2)}%`

const CustomMapTooltip = ({
  featureName,
  featureCount,
  featureColor,
  sumCount,
}) => (
  <Tooltip id="custom-map-tooltip" opacity={1} sticky>
    <div className="header font-medium">{featureName}</div>
    <div className="body">
      <div className="flex items-center">
        <div className="legend-color" style={{backgroundColor: featureColor}} />
        <div className="pl-2 pr-1">Hosts:</div>
        <div className="font-semibold">
          {`${featureCount} (${getCountryTotalPctg(featureCount, sumCount)})`}
        </div>
      </div>
    </div>
  </Tooltip>
)

CustomMapTooltip.propTypes = {
  featureName: PropTypes.string,
  featureCount: PropTypes.number,
  featureColor: PropTypes.string,
  sumCount: PropTypes.number,
}

export default CustomMapTooltip
