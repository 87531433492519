import React from 'react'
import PropTypes from 'prop-types'

export default function FindingIcon({width, height, color}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 3.33329L8 0.666626L2 3.33329V7.33329C2 11.0333 4.56 14.4933 8 15.3333C9.53333 14.96 10.8867 14.0666 11.92 12.86L9.84 10.78C8.54667 11.64 6.78667 11.4933 5.64667 10.3533C4.34667 9.05329 4.34667 6.93996 5.64667 5.63996C6.94667 4.33996 9.06 4.33996 10.36 5.63996C11.5 6.77996 11.64 8.53996 10.7867 9.83329L12.72 11.7666C13.5267 10.46 14 8.91996 14 7.33329V3.33329Z"
        fill={color || '#FD7F30'}
      />
      <path
        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
        fill={color || '#FD7F30'}
      />
    </svg>
  )
}

FindingIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
