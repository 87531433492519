import React, {useEffect, useRef, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {addDomain, loading} from 'actions'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import {isLoading} from 'utils'

const AddDomainModal = ({modalVisibility, setModalVisibility}) => {
  const csrfState = useSelector(state => state.csrf)
  const userState = useSelector(state => state.user)
  const successState = useSelector(state => state.success)
  const loadingState = useSelector(state => state.loading)

  const dispatch = useDispatch()

  const initialFormState = {hostname: '', enum: true, monitored: true}
  const [form, setForm] = useState(initialFormState)
  const domainInput = useRef()

  function handleInviteChange(e) {
    const {name} = e.target
    setForm({...form, [name]: e.target.value})
  }

  function handleSubmit(e) {
    e.preventDefault()

    dispatch(loading({addDomain: true}))
    dispatch(addDomain({...form, _csrf: csrfState}))
  }

  function closeModal(e) {
    const inviteSectionModal = document.querySelector('#add-domain-modal')
    if (e.target === inviteSectionModal) {
      setModalVisibility('hidden')
    }
  }

  useEffect(() => {
    if (modalVisibility === 'hidden') {
      setForm(initialFormState)
      return
    }

    if (domainInput.current) domainInput.current.focus()
  }, [modalVisibility])

  useEffect(() => {
    document.addEventListener('mouseup', e => closeModal(e))
  }, [])

  useEffect(() => {
    if (successState && !isLoading(loadingState.addDomain)) {
      setModalVisibility('hidden')
    }
  }, [loadingState.addDomain])

  return (
    <div
      id="add-domain-modal"
      className={`${modalVisibility} modal-bg fixed top-0 left-0 w-screen h-screen z-50`}>
      <form
        onSubmit={handleSubmit}
        className="fixed bottom-1/2 right-1/2 transform translate-x-1/2 max-w-lg w-full bg-white rounded-md shadow-md p-6">
        <div className="text-sideral-900 leading-none border-b border-sideral-50 text-lg pb-6 mb-6 font-medium">
          {i18next.t('titles.addDomain')}
        </div>
        <div>
          <PrimaryInput
            testId="add-domain-input"
            text={i18next.t('misc.domain')}
            type="text"
            name="hostname"
            required={true}
            value={form.hostname || ''}
            onChange={handleInviteChange}
            margin="mb-6"
            size="sm"
            reference={domainInput}
          />

          <div className="flex">
            <div className="w-full">
              <PrimaryButton
                testId="add-domain-submit-btn"
                text={i18next.t('dns.addDomainBtn.submit')}
                type="submit"
                size="full"
                margin="mr-2"
                loading={isLoading(loadingState.addDomain, false)}
                disabled={userState?.role === 'read-only'}
              />
            </div>
            <div className="w-full">
              <PrimaryButton
                text={i18next.t('dns.addDomainBtn.cancel')}
                theme="blue-outline"
                type="button"
                size="full"
                margin="ml-2"
                onClick={() => {
                  setModalVisibility('hidden')
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

AddDomainModal.propTypes = {
  modalVisibility: PropTypes.string,
  setModalVisibility: PropTypes.func,
}

export default AddDomainModal
