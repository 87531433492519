import AwesomeDebouncePromise from 'awesome-debounce-promise'
import Moment from 'react-moment'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import useConstant from 'use-constant'
import {tableStyles} from 'constant'
import {v4 as uuidv4} from 'uuid'

import GlobalFilter from 'components/partials/tables/GlobalFilter'
import Pagination from 'components/partials/tables/Pagination'
import Spinner from 'components/partials/Spinner'

import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

function Table(props) {
  const [filterValue, setFilterValue] = useState('')
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [shouldRefresh, setShouldRefresh] = useState(true)

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex, sortBy},
    setGlobalFilter,
  } = useTable(
    {
      columns: props.columns,
      data: items,
      defaultColumn,

      manualPagination: true,
      manualSortBy: true,

      autoResetPage: false,
      autoResetSortBy: false,

      pageCount: props.pageCount,

      initialState: {
        pageIndex: props.pageIndex || 0,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  const handlePrevious = () => {
    props.fetchData({
      filterValue,
      pageIndex: pageIndex - 1,
      pageSize: props.pageSize,
      sortBy: sortBy,
    })
    previousPage()
  }

  const handleNext = () => {
    props.fetchData({
      filterValue,
      pageIndex: pageIndex + 1,
      pageSize: props.pageSize,
      sortBy: sortBy,
    })
    nextPage()
  }

  const refreshTableData = (filterValue, sortBy) => {
    props.fetchData({
      pageIndex: pageIndex,
      pageSize: props.pageSize,
      filterValue: filterValue,
      sortBy: sortBy,
    })
  }

  const debouncedRefreshTable = useConstant(() => {
    return AwesomeDebouncePromise(refreshTableData, 1000)
  })

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false)
      refreshTableData(filterValue, sortBy)
    }
  }, [sortBy, shouldRefresh])

  useEffect(() => {
    setLoading(false)
    if (props.data) setItems(() => props.data)
  }, [props.data])

  useEffect(() => {
    setShouldRefresh(true)
  }, [sortBy, filterValue])

  useEffect(() => {
    debouncedRefreshTable(filterValue)
  }, [debouncedRefreshTable, filterValue])

  useEffect(() => {
    if (!props.csvFilteringProps) return

    let sortValue
    let orderValue

    if (sortBy && sortBy.length) {
      sortValue = sortBy[0].id
      orderValue = sortBy[0].desc

      orderValue ? (orderValue = 'desc') : (orderValue = 'asc')
    }

    props.setCsvFilteringProps({
      ...props.csvFilteringProps,
      filter: filterValue,
      order: orderValue,
      sortBy: sortValue,
    })
  }, [sortBy, filterValue])

  return (
    <div>
      <div className="tableWrap relative">
        <div className={tableStyles.filterWrapper}>
          <GlobalFilter
            globalFilter={filterValue}
            gotoPage={gotoPage}
            setFilterValue={setFilterValue}
            setGlobalFilter={setGlobalFilter}
            setLoading={setLoading}
            totalPages={props.total}
          />
          <Pagination
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            paginationClass={props.paginationClass}
            pageIndex={pageIndex}
            pageCount={pageCount}
            total={props.total}
          />
        </div>
        {props.data && props.data.length && !loading ? (
          <>
            <table {...getTableProps()} className="w-full">
              <thead>
                {headerGroups.map(headerGroup => {
                  // trick to disable the first headerGroup
                  if (headerGroup.headers.length === 1)
                    return <tr key={uuidv4()}></tr>

                  return (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={uuidv4()}
                      className="p-2">
                      {headerGroup.headers.map(column => (
                        <th
                          className={tableStyles.tableHeader}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={uuidv4()}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' ↓'
                                : ' ↑'
                              : ''}
                          </span>
                        </th>
                      ))}
                    </tr>
                  )
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={tableStyles.tableRow}
                      key={uuidv4()}>
                      {row.cells.map(cell => {
                        if (
                          cell.column.id === 'data' &&
                          typeof cell.value === 'object'
                        ) {
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={uuidv4()}
                              className="py-2 pr-2 text-center">
                              {Array.isArray(cell.value)
                                ? cell.value.map(value => {
                                    return (
                                      <span className="block" key={uuidv4()}>
                                        {typeof value === 'string'
                                          ? value
                                          : JSON.stringify(value)}
                                      </span>
                                    )
                                  })
                                : JSON.stringify(cell.value)}
                            </td>
                          )
                        }

                        if (
                          cell.column.id === 'type' &&
                          cell.row.original.alias
                        )
                          return (
                            <td
                              className={
                                cell.column.classes
                                  ? cell.column.classes
                                  : 'py-2 pr-2 break-word text-center'
                              }
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              A (AWS alias)
                            </td>
                          )

                        if (cell.column.id === 'lastResolution')
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={uuidv4()}
                              className={cell.column.classes || ''}>
                              {cell.value ? (
                                <Moment date={new Date(cell.value)} fromNow />
                              ) : (
                                '–'
                              )}
                            </td>
                          )

                        return (
                          <td
                            className={
                              cell.column.classes
                                ? cell.column.classes
                                : 'py-2 pr-2 break-word text-center'
                            }
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            {cell.value ? cell.render('Cell') : '—'}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        ) : (
          <>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {filterValue
                  ? props.emptyStateWithFilter
                  : props.emptyStateWithoutFilter}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.array,
  csvFilteringProps: PropTypes.object,
  data: PropTypes.array,
  defaultSorted: PropTypes.array,
  fetchData: PropTypes.func,
  headerClasses: PropTypes.string,
  history: PropTypes.object,
  onSort: PropTypes.func,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  paginationClass: PropTypes.string,
  rowClasses: PropTypes.string,
  setCsvFilteringProps: PropTypes.func,
  total: PropTypes.number,
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
}

export default React.memo(Table)
