import {fetchDomainsBreakdownDone, genericError} from 'actions'
import {handler} from './handler'
import config from 'config'
import {getRequestParams} from 'utils/pagination'

export function* handleFetchDomainsBreakdown(action) {
  yield handler({
    handler: fetchDomainsBreakdown,
    error: genericError,
    success: fetchDomainsBreakdownDone,
    action,
  })
}

const fetchDomainsBreakdown = function (params) {
  const url = new URL(`${config.SERVER_URL}/dns/domains/breakdown`)
  url.search = new URLSearchParams(getRequestParams(params)).toString()

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}
