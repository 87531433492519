import i18next from 'i18next'
import React from 'react'

import Footer from './Footer'

export default function Network() {
  return (
    <>
      <div className="py-4 px-8">
        <div className="flex">
          <div className="w-full text-sm">
            <h1 className="font-medium mt-2 mb-4 text-lg">Network</h1>
            <h2 className="font-medium mt-2 mb-4 text-normal">
              {i18next.t('help.network.assetDiscovery.title')}
            </h2>
            <p className="mb-4">
              {i18next.t('help.network.assetDiscovery.firstParagraph')}
            </p>
            <p className="mb-4">
              {i18next.t('help.network.assetDiscovery.secondParagraph')}
            </p>
            <p className="mb-4">
              {i18next.t('help.network.assetDiscovery.thirdParagraph')}
            </p>
            <p className="mb-4">
              {i18next.t('help.network.assetDiscovery.fourthParagraph')}
            </p>

            <h2 className="font-medium mt-2 mb-4 text-normal">
              {i18next.t('help.network.networkVulnerabilities.title')}
            </h2>
            <p className="mb-4">
              {i18next.t('help.network.networkVulnerabilities.firstParagraph')}
            </p>
            <p className="mb-4">
              {i18next.t('help.network.networkVulnerabilities.secondParagraph')}
            </p>
            <h2 className="font-medium mt-2 mb-4 text-normal">
              {i18next.t('help.network.visualizingExposures.title')}
            </h2>
            <p className="mb-4">
              {i18next.t('help.network.visualizingExposures.firstParagraph')}
            </p>

            <h2 className="font-medium mt-2 mb-4 text-normal">
              {i18next.t('help.network.smartPrioritization.title')}
            </h2>
            <p className="mb-4">
              {i18next.t('help.network.visualizingExposures.firstParagraph')}
            </p>

            <h2 className="font-medium mt-2 mb-4 text-normal">
              {i18next.t('help.network.fixingExposures.title')}
            </h2>
            <p className="mb-4">
              {i18next.t('help.network.fixingExposures.firstParagraph')}
            </p>
            <p className="mb-4">
              {i18next.t('help.network.fixingExposures.secondParagraph')}
            </p>
            <p className="mb-4">
              {i18next.t('help.network.fixingExposures.thirdParagraph')}
            </p>
            <p className="mb-4">
              {i18next.t('help.network.fixingExposures.fourthParagraph')}
            </p>
            <p className="mb-4">
              {i18next.t('help.network.fixingExposures.fifthParagraph')}
            </p>
            <p>{i18next.t('help.network.fixingExposures.sixthParagraph')}</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
