import React from 'react'
import PropTypes from 'prop-types'
import {questionnaireStatus} from 'constant'
import Badge from 'components/partials/Badge'
import moment from 'moment'
import i18next from 'i18next'

const getSubmittedStatusInfo = (
  isAnUploadedQuestionnaire,
  isFromSupplier,
  timestamp
) => {
  if (isAnUploadedQuestionnaire) return timestamp

  return isFromSupplier
    ? i18next.t('status.awaitingEvaluation', {
        timestamp,
      })
    : i18next.t('status.awaitingReview', {
        timestamp,
      })
}

export default function QuestionnaireStatusBadge({
  status,
  changedAt,
  isFromSupplier,
  hideTooltip,
  isAnUploadedQuestionnaire,
}) {
  let style, label, info

  const timestamp = moment(changedAt).locale(i18next.language).fromNow()

  switch (status) {
    case 'accepted':
      style = 'info'
      label = questionnaireStatus.accepted
      info = timestamp
      break

    case 'sent':
      style = 'info'
      label = questionnaireStatus.sent
      info = timestamp
      break

    case 'pending':
      style = 'warning'
      label = questionnaireStatus.pending
      info = timestamp
      break

    case 'approved':
      style = 'success'
      label = questionnaireStatus.approved
      info = timestamp
      break

    case 'submitted':
      style = isFromSupplier ? 'info' : 'warning'
      label = questionnaireStatus.submitted
      info = getSubmittedStatusInfo(
        isAnUploadedQuestionnaire,
        isFromSupplier,
        timestamp
      )
      break

    case 'changesRequested':
      style = 'warning'
      label = questionnaireStatus.changesRequested
      info = timestamp
      break

    case 'awaitingReview':
      style = 'info'
      label = questionnaireStatus.awaitingReview
      info = timestamp
      break

    case 'edited':
      style = 'warning'
      label = questionnaireStatus.edited
      info = timestamp
      break

    default:
      style = 'info'
      label = status
      info = timestamp
  }

  return <Badge style={style} label={label} info={!hideTooltip ? info : null} />
}

QuestionnaireStatusBadge.propTypes = {
  status: PropTypes.string,
  changedAt: PropTypes.string,
  isFromSupplier: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  isAnUploadedQuestionnaire: PropTypes.bool,
}
