import i18next from 'i18next'
import {call, put} from 'redux-saga/effects'

import config from '../config'
import {handler} from './handler'
import {
  acceptTermsDone,
  fetchBreachesOverTimeDone,
  fetchCloudIntegrationsDone,
  fetchCloudStatsDone,
  fetchCompany,
  fetchCompanyDone,
  fetchCompanyMembersDone,
  fetchCompaniesDonePagination,
  fetchCompanyScoresDone,
  fetchCompanyStatsDone,
  fetchEventsDone,
  fetchHasCloudIntegrationDone,
  fetchInvites,
  fetchInvitesDone,
  fetchReports,
  fetchReportsDone,
  fetchScoreOverTimeDone,
  generateReportDone,
  genericError,
  success,
  fetchInviteInfoDone,
  companyRenewalDone,
  fetchAuditLogsDone,
} from 'actions'
import {getRequestParams} from 'utils/pagination'

const _updateCompany = function (body) {
  return fetch(`${config.SERVER_URL}/companies`, {
    method: 'PATCH',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

const deleteMember = function (body) {
  return fetch(`${config.SERVER_URL}/users/${body.userId}`, {
    body: JSON.stringify(body),
    headers: {'Content-Type': 'application/json'},
    method: 'DELETE',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleDeleteMember(action) {
  yield handler({
    handler: deleteMember,
    error: genericError,
    success: [success, () => fetchCompany(action.payload.companyId)],
    action,
  })
}

const _fetchInvites = function function_name() {
  return fetch(`${config.SERVER_URL}/invites`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
    .then(res => {
      if (res.status === 200) return res.json()
    })
    .catch(err => {
      console.trace(err)
    })
}

export function* handleInviteInfo(action) {
  yield handler({
    handler: _fetchInviteInfo,
    success: fetchInviteInfoDone,
    error: res =>
      res.code === 'ACCESS_DENIED'
        ? (window.location.href = '/login?message=authenticationRequired')
        : genericError({code: res.code, message: res.message}),
    action,
  })
}

const _fetchInviteInfo = params => {
  return fetch(`${config.SERVER_URL}/invites/${params.invitationId}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const cloudIntegrations = params => {
  const url = new URL(`${config.SERVER_URL}/cloud/integrations`)
  url.search = new URLSearchParams(getRequestParams(params)).toString()

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const cloudStats = () => {
  return fetch(`${config.SERVER_URL}/cloud/stats`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const companyScores = () => {
  return fetch(`${config.SERVER_URL}/scores`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const companyStats = options => {
  const endpoint =
    options && options.type
      ? `${config.SERVER_URL}/${options.type}/stats`
      : `${config.SERVER_URL}/companies/stats`

  return fetch(endpoint, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const _fetchCompany = () => {
  return fetch(`${config.SERVER_URL}/companies/self`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchCompany(action) {
  yield handler({
    handler: _fetchCompany,
    success: fetchCompanyDone,
    action,
  })
}

const _fetchCompanyMembers = () => {
  return fetch(`${config.SERVER_URL}/companies/members`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchCompanyMembers(action) {
  yield handler({
    handler: _fetchCompanyMembers,
    success: fetchCompanyMembersDone,
    error: genericError,
    action,
  })
}

export function* handleFetchCompaniesPagination(action) {
  yield handler({
    handler: _fetchCompaniesPagination,
    success: fetchCompaniesDonePagination,
    action,
  })
}

const _fetchCompaniesPagination = params => {
  const url = new URL(`${config.SERVER_URL}/companies/v2`)
  url.search = new URLSearchParams(getRequestParams(params)).toString()

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const _companyRenewal = body => {
  return fetch(`${config.SERVER_URL}/companies/${body.companyId}/renewal`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleCompanyRenewal(action) {
  yield handler({
    handler: _companyRenewal,
    success: companyRenewalDone,
    error: genericError,
    action,
  })
}

export function* handleFetchHasCloudIntegration(action) {
  yield handler({
    handler: _fetchHasCloudIntegration,
    success: fetchHasCloudIntegrationDone,
    action,
  })
}

const _fetchHasCloudIntegration = () => {
  return fetch(`${config.SERVER_URL}/cloud`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const _fetchReports = () => {
  return fetch(`${config.SERVER_URL}/reports`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchReports(action) {
  yield handler({
    handler: _fetchReports,
    success: fetchReportsDone,
    action,
  })
}

const _generateReport = body => {
  return fetch(`${config.SERVER_URL}/report`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleGenerateReport(action) {
  yield handler({
    handler: _generateReport,
    success: [fetchReports, generateReportDone],
    error: () =>
      genericError({
        message: i18next.t('reports.errors.errorGeneratingReport'),
        loading: {generateReport: false},
      }),
    action,
  })
}

export function* handleFetchCloudIntegrations(action) {
  yield handler({
    handler: cloudIntegrations,
    success: fetchCloudIntegrationsDone,
    action,
  })
}

export function* handleFetchCloudStats(action) {
  yield handler({
    handler: cloudStats,
    success: fetchCloudStatsDone,
    action,
  })
}

export function* handleFetchCompanyStats(action) {
  yield handler({
    handler: companyStats,
    success: fetchCompanyStatsDone,
    action,
  })
}

export function* handleFetchCompanyScores(action) {
  yield handler({
    handler: companyScores,
    success: fetchCompanyScoresDone,
    action,
  })
}

export function* handleUpdateCompany(action) {
  yield handler({
    handler: _updateCompany,
    error: genericError,
    success: [success, () => fetchCompany(action.payload.id)],
    action,
  })
}

const inviteMember = function (body) {
  return fetch(`${config.SERVER_URL}/invites`, {
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
    method: 'POST',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleInviteMember(action) {
  yield handler({
    handler: inviteMember,
    error: res => {
      let message

      switch (res.code) {
        case 'USER_ALREADY_REGISTERED':
          message = i18next.t(
            'settings.errors.userAlreadyRegisteredInCompany',
            {
              email: action.payload.email,
              company: action.payload.companyName,
            }
          )
          break
        case 'USER_ALREADY_INVITED':
          message = i18next.t('settings.errors.userAlreadyInvited', {
            email: action.payload.email,
          })
          break
      }
      return genericError({code: res.code, message})
    },
    success: [
      () =>
        success({
          message: i18next.t('settings.success.inviteSent', {
            email: action.payload.email,
          }),
        }),
      fetchInvites,
    ],
    action,
  })
}

export function* handleAcceptInvite(action) {
  yield handler({
    handler: _acceptInvite,
    error: res =>
      genericError({
        loading: {acceptInvite: false},
        code: res.code,
        message: res.message,
      }),
    success: () => (window.location.href = '/redirect'),
    action,
  })
}

const _acceptInvite = function (body) {
  const path = `${config.SERVER_URL}/invites/${body.invitationId}/accept`
  return fetch(path, {
    method: 'POST',
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {'Content-Type': 'application/json'},
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchInvites(action) {
  const result = yield call(_fetchInvites, action.payload)
  yield put(fetchInvitesDone(result))
}

export function* handleAcceptTerms(action) {
  yield handler({
    handler: _handleAcceptTerms,
    error: genericError,
    success: acceptTermsDone,
    action,
  })
}

const _handleAcceptTerms = body => {
  return fetch(`${config.SERVER_URL}/user/acceptTerms`, {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const cancelInvite = function (body) {
  return fetch(`${config.SERVER_URL}/invites/${body.inviteId}`, {
    body: JSON.stringify(body),
    method: 'DELETE',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleCancelInvite(action) {
  yield handler({
    handler: cancelInvite,
    error: genericError,
    success: [success, fetchInvites],
    action,
  })
}

export function* handleSubmitSurvey(action) {
  yield handler({
    handler: submitSurvey,
    error: genericError,
    success,
    action,
  })
}

const submitSurvey = form => {
  return fetch(`${config.SERVER_URL}/survey/leaving`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(form),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleChangeCompany(action) {
  yield handler({
    handler: _changeCompany,
    error: genericError,
    success: () => (window.location.href = '/redirect'),
    action,
  })
}

const _changeCompany = form => {
  return fetch(`${config.SERVER_URL}/user/company`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(form),
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleToggleCompanyMonitoring(action) {
  yield handler({
    handler: _toggleCompanyMonitoring,
    action,
  })
}

const _toggleCompanyMonitoring = companyId => {
  return fetch(`${config.SERVER_URL}/companies/${companyId}/monitor`, {
    method: 'PATCH',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchEvents(action) {
  yield handler({
    handler: _fetchEvents,
    success: fetchEventsDone,
    action,
  })
}

const _fetchEvents = () => {
  return fetch(`${config.SERVER_URL}/events`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleSendLead(action) {
  yield handler({
    handler: _sendLead,
    success,
    action,
  })
}

const _sendLead = leadId => {
  return fetch(`${config.SERVER_URL}/lead/${leadId}/start`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchScoreOverTime(action) {
  yield handler({
    handler: _fetchScoreOverTime,
    success: fetchScoreOverTimeDone,
    action,
  })
}

const _fetchScoreOverTime = () => {
  return fetch(`${config.SERVER_URL}/scores/chart`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchBreachesOverTime(action) {
  yield handler({
    handler: _fetchBreachesOverTime,
    success: fetchBreachesOverTimeDone,
    action,
  })
}

const _fetchBreachesOverTime = () => {
  return fetch(`${config.SERVER_URL}/breaches/chart`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchAuditLogs(action) {
  yield handler({
    handler: _fetchAuditLogs,
    error: genericError,
    success: fetchAuditLogsDone,
    action,
  })
}

const _fetchAuditLogs = params => {
  const url = new URL(`${config.SERVER_URL}/companies/logs`)
  url.search = new URLSearchParams(getRequestParams(params)).toString()

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}
