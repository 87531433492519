import config from '../config'
import {handler} from './handler'
import {
  fetchExploitationDetails,
  fetchExploitationDetailsDone,
  genericError,
  startExploitationDone,
} from 'actions'

export function* handleStartExploitation(action) {
  yield handler({
    handler: _startExploitation,
    success: [
      () => fetchExploitationDetails(action.payload.findingId),
      startExploitationDone,
    ],
    error: err => genericError(err),
    action,
  })
}

const _startExploitation = body => {
  const url = `${config.SERVER_URL}/exploit`

  return fetch(url, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
    body: JSON.stringify(body),
  })
}

export function* handleFetchExploitationDetails(action) {
  yield handler({
    handler: _fetchExploitationDetails,
    success: fetchExploitationDetailsDone,
    action,
  })
}

const _fetchExploitationDetails = findingId => {
  const url = `${config.SERVER_URL}/exploit/${findingId}`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
}
