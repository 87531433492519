import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'

import {fetchSupplierDetails, loading, resendSupplierInvite} from 'actions'

import BackButton from 'components/partials/buttons/BackButton'
import Spinner from 'components/partials/Spinner'
import {Page} from 'components/partials/Page'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import Subsection from 'components/partials/headers/Subsection'
import EditIcon from 'res/icons/editIcon'
import {alertStyle, sky500, supplierInviteStatus} from 'constant'
import TrashIcon from 'res/icons/trashIcon'
import SupplierEditModal from './SupplierEditModal'
import SupplierRemoveModal from './SupplierRemoveModal'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import SupplierQuestionnairesList from './questionnaire/SupplierQuestionnairesList'
import {isLoading} from 'utils'

export default function SupplierDetails() {
  const supplierDetailsState = useSelector(state => state.supplierDetails)
  const updateSupplierDoneState = useSelector(state => state.updateSupplierDone)
  const loadingState = useSelector(state => state.loading)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const [editModal, setEditModal] = useState({
    selected: null,
    showModal: false,
  })

  const [removeModal, setRemoveModal] = useState({
    selected: null,
    showModal: false,
  })

  const handleDisplayEditModal = () => {
    setEditModal({
      showModal: true,
    })
  }

  const handleDisplayRemoveModal = () => {
    setRemoveModal({
      selected: {
        id: supplierDetailsState.id,
        name: supplierDetailsState.name,
      },
      showModal: true,
    })
  }

  useEffect(() => {
    dispatch(loading({supplierDetails: true}))
    dispatch(fetchSupplierDetails({supplierId: params.id}))
  }, [])

  useEffect(() => {
    if (updateSupplierDoneState)
      setEditModal({
        selected: null,
        showModal: false,
      })
  }, [updateSupplierDoneState])

  if (isLoading(loadingState.supplierDetails)) return <Spinner />

  return (
    <>
      <Page pageTitle={i18next.t('pageTitles.suppliers')}>
        <BackButton forceFallbackLink fallbackLink="/suppliers" />

        <div className="bg-white border rounded-lg mt-4">
          <div
            id="header"
            className="flex items-center justify-between p-6 border-b">
            <div className="flex items-center basis-1/2">
              <div className="pr-3">
                <div className="font-medium text-lg">
                  {supplierDetailsState.name}
                </div>
                <div className="text-sideral-400 text-sm">
                  {supplierDetailsState.invite.email}
                </div>
              </div>
              <SupplierInviteStatus
                supplierId={params.id}
                invite={supplierDetailsState.invite}
              />
            </div>

            <div className="flex items-center">
              <div className="border-r pr-6">
                <PrimaryButton
                  text={i18next.t(
                    'suppliers.registeredSuppliers.detail.sendQuestionnaireBtn'
                  )}
                  size="sm"
                  onClick={() =>
                    navigate(`/suppliers/${params.id}/sendQuestionnaire`)
                  }
                />
              </div>

              <button
                onClick={handleDisplayEditModal}
                title={i18next.t('misc.edit')}
                className="pl-4 pr-2">
                <EditIcon height="22" width="22" color={sky500} />
              </button>

              <button
                onClick={handleDisplayRemoveModal}
                title={i18next.t('misc.remove')}>
                <TrashIcon height="22" width="22" color={sky500} />
              </button>
            </div>
          </div>

          <div className="p-6">
            <Subsection
              title={i18next.t(
                'suppliers.registeredSuppliers.detail.submittedQuestionnaires'
              )}
              content={<SupplierQuestionnairesList supplierId={params.id} />}
            />
          </div>
        </div>

        {editModal.showModal && (
          <SupplierEditModal
            supplierDetails={supplierDetailsState}
            setEditModal={setEditModal}
          />
        )}

        {removeModal.showModal && (
          <SupplierRemoveModal
            supplierDetails={supplierDetailsState}
            setRemoveModal={setRemoveModal}
          />
        )}
      </Page>
    </>
  )
}

const SupplierInviteStatus = ({supplierId, invite}) => {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)

  const handleResendBtn = () => {
    dispatch(resendSupplierInvite({supplierId, _csrf: csrfState}))
  }

  if (invite.status === 'accepted') return

  let status, styleClasses, showResendBtn
  switch (invite.status) {
    case 'active':
    case 'used':
      status = supplierInviteStatus.sent
      styleClasses = alertStyle.warning
      showResendBtn = false
      break

    case 'rejected':
      status = supplierInviteStatus.declined
      styleClasses = alertStyle.error
      showResendBtn = true
      break

    case 'expired':
      status = supplierInviteStatus.expired
      styleClasses = alertStyle.error
      showResendBtn = true
      break

    default:
      status = invite.status
      styleClasses = alertStyle.info
      showResendBtn = false
  }

  return (
    <div className="ml-5 border-l pl-8">
      <div className="font-medium text-sm mb-1">
        {i18next.t('suppliers.registeredSuppliers.detail.invitationStatus')}
      </div>
      <div className="flex items-center">
        <div className={`inline text-sm rounded-md px-2 ${styleClasses}`}>
          {status}
        </div>
        {showResendBtn && (
          <div className="ml-3">
            {
              <SecondaryButton
                text={i18next.t(
                  'suppliers.registeredSuppliers.detail.resendInvitationBtn'
                )}
                size="sm"
                onClick={handleResendBtn}
              />
            }
          </div>
        )}
      </div>
    </div>
  )
}

SupplierInviteStatus.propTypes = {
  supplierId: PropTypes.string,
  invite: PropTypes.object,
}
