import i18next from 'i18next'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {fetchDocuments} from 'actions'

import {Page} from 'components/partials/Page'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import DocumentsList from './DocumentsList'
import PlanUtils from 'utils/plan'
import DocumentsEmptyState from './DocumentsEmptyState'
import Spinner from 'components/partials/Spinner'
import Subsection from 'components/partials/headers/Subsection'

const DocumentsOverview = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const documentsState = useSelector(state => state.documents)

  const hasAccessToDocuments = PlanUtils().hasAccessToDocuments()

  useEffect(() => {
    if (hasAccessToDocuments) dispatch(fetchDocuments())
  }, [])

  return (
    <Page
      pageTitle={i18next.t('pageTitles.documents')}
      button={
        <SecondaryButton
          testId="add-document-btn"
          text={i18next.t('documents.addNew.addNewDocument')}
          onClick={() => navigate('/documents/new')}
          disabled={!hasAccessToDocuments}
          plusIcon
        />
      }>
      {hasAccessToDocuments ? (
        documentsState ? (
          documentsState?.length > 0 ? (
            <Subsection
              title={i18next.t('documents.availableDocuments')}
              content={<DocumentsList data={documentsState} />}
            />
          ) : (
            <DocumentsEmptyState hasAccessToDocuments={hasAccessToDocuments} />
          )
        ) : (
          <Spinner />
        )
      ) : (
        <DocumentsEmptyState hasAccessToDocuments={hasAccessToDocuments} />
      )}
    </Page>
  )
}

export default DocumentsOverview
