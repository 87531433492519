import React from 'react'
import PropTypes from 'prop-types'
import {DefaultModal} from 'components/partials/DefaultModal'
import {Link} from 'react-router-dom'
import i18next from 'i18next'

export default function QuestionGenerateAnswerModal({
  setShowUnableToGenerateAnswerModal,
}) {
  return (
    <DefaultModal
      id="unableToGenerateAnswerModalModal"
      title={i18next.t('questionnaires.unableToGenerateModal.title')}
      body={
        <div className="text-gray-600">
          {i18next.t('questionnaires.unableToGenerateModal.body')}
          <Link
            to="#/help/questionnaire"
            className="text-sky-900 underline"
            onClick={() => {
              setShowUnableToGenerateAnswerModal(false)
            }}>
            {i18next.t('questionnaires.unableToGenerateModal.learnMore')}
          </Link>
        </div>
      }
      primaryBtnText={i18next.t(
        'questionnaires.unableToGenerateModal.primaryBtn'
      )}
      onClickPrimaryBtn={() => setShowUnableToGenerateAnswerModal(false)}
    />
  )
}

QuestionGenerateAnswerModal.propTypes = {
  setShowUnableToGenerateAnswerModal: PropTypes.func,
}
