import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useSelector, useDispatch} from 'react-redux'
import i18next from 'i18next'
import {memberGetMemberModal} from 'actions'
import {linearGradientStyle} from 'constant'
import images from 'res'

export default function MemberGetMemberModal() {
  const dispatch = useDispatch()

  const memberGetMemberModalState = useSelector(
    state => state.memberGetMemberModal
  )

  const onCloseModal = () => {
    dispatch(memberGetMemberModal({showModal: false}))
  }

  useEffect(() => {
    document.addEventListener('mouseup', e => {
      const memberGetMemberModal = document.querySelector(
        '#memberGetMemberModal'
      )
      if (e.target === memberGetMemberModal) onCloseModal()
    })
  }, [])

  return (
    memberGetMemberModalState?.showModal && (
      <div
        id="memberGetMemberModal"
        className="modal-bg fixed top-0 left-0 w-screen h-screen bg-white z-50">
        <div className="fixed bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 max-w-2xl w-full bg-white rounded-md shadow-md p-6">
          <div className="relative">
            <div>
              <div id="modal-header" className="text-center">
                <div id="icon" className="inline-block m-auto mt-8 mb-2">
                  <img src={images.giftIcon} className="w-12" />
                </div>
                <div
                  id="title"
                  className="font-medium text-sideral-90 text-xl mb-6">
                  {i18next.t('memberGetMember.modal.title')}
                </div>
              </div>
              <div id="modal-body">
                <div className="description text-gray-600 leading-7 mb-6">
                  {i18next.t('memberGetMember.modal.description')}
                </div>

                <div className="flex">
                  <CardItem
                    title={i18next.t('memberGetMember.modal.steps.first.title')}
                    body={i18next.t('memberGetMember.modal.steps.first.body')}
                    step="1"
                  />
                  <CardItem
                    title={i18next.t(
                      'memberGetMember.modal.steps.second.title'
                    )}
                    body={i18next.t('memberGetMember.modal.steps.second.body')}
                    step="2"
                    classes="mx-3"
                  />
                  <CardItem
                    title={i18next.t('memberGetMember.modal.steps.third.title')}
                    body={i18next.t('memberGetMember.modal.steps.third.body')}
                    step="3"
                  />
                </div>

                <div className="text-xs mt-3">
                  {i18next.t('memberGetMember.modal.conditions')}
                </div>
              </div>
            </div>

            <button className="absolute top-0 right-0" onClick={onCloseModal}>
              <i className="icofont-close text-xl" />
            </button>
          </div>
        </div>
      </div>
    )
  )
}

const CardItem = ({title, body, step, classes}) => (
  <div
    className={`relative basis-1/3 border border-violet/[.2] p-4 rounded-md bg-gradient-to-r from-violet/[.08] to-ripeOrange/[.08] ${classes}`}>
    <div className={`font-medium text-lg mb-2 ${linearGradientStyle}`}>
      {title}
    </div>
    <div className="text-sm">{body}</div>
    <div
      className={`absolute right-0 bottom-0 text-9xl font-medium leading-none opacity-[.08] ${linearGradientStyle}`}>
      {step}
    </div>
  </div>
)

CardItem.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  step: PropTypes.string,
  classes: PropTypes.string,
}
