import React from 'react'
import i18next from 'i18next'
import {alertStyle} from 'constant'

export default function AccessibilityAlert() {
  return (
    <div
      className={`md:hidden rounded-md p-4 mt-4 text-center ${alertStyle.warning}`}>
      {i18next.t('warnings.accessUsingDesktop')}
    </div>
  )
}
