import i18next from 'i18next'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import ReactMarkdown from 'react-markdown'

import Spinner from 'components/partials/Spinner'

const ItemModal = ({lgpdItem, modalVisibility, setModalVisibility}) => {
  const styles = {
    modalBg: 'modal-bg fixed top-0 left-0 w-screen h-screen z-50',
    modalContainer:
      'fixed bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 max-h-xl max-w-2xl w-full overflow-auto menu-scrollbar bg-white rounded-md shadow-md px-6 py-10',
    sectionBorder: 'border-t border-sideral-50 pt-6',
    title:
      'text-sideral-900 font-medium leading-none text-base capitalize-first mb-6',
  }

  function closeModal(e) {
    const inviteSectionModal = document.querySelector('#add-domain-modal')

    if (e.target === inviteSectionModal) setModalVisibility('hidden')
  }

  useEffect(() => {
    document.addEventListener('mouseup', e => closeModal(e))
  }, [])

  return (
    <div
      id="add-domain-modal"
      className={`${modalVisibility} ${styles.modalBg}`}>
      <div className={styles.modalContainer}>
        {!lgpdItem ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.title}>
              {i18next.t('compliance.lgpd.modal.reason')}
            </div>
            <div className="text-sm mb-6">
              <ReactMarkdown>{lgpdItem.reason}</ReactMarkdown>
            </div>

            <div className={`${styles.title} ${styles.sectionBorder}`}>
              {i18next.t('compliance.lgpd.modal.lgpdArticle')}
            </div>
            <blockquote className="relative ml-4">
              <div className="font-semibold relative z-50 capitalize-first text-sm mb-2">
                {lgpdItem.title}
              </div>
              <div className="text-sm">
                <ReactMarkdown>{lgpdItem.content}</ReactMarkdown>
              </div>
            </blockquote>
          </>
        )}
      </div>
    </div>
  )
}

ItemModal.propTypes = {
  lgpdItem: PropTypes.object,
  modalVisibility: PropTypes.string,
  setModalVisibility: PropTypes.func,
}

export default ItemModal
