import React, {useState} from 'react'
import PropTypes from 'prop-types'
import RemoveCloudIntegrationButton from 'components/partials/buttons/RemoveCloudIntegrationButton'

const IntegrationHeader = ({
  provider,
  providersProperties,
  integration,
  icon,
  styles,
  rightSideContent,
  hideIntegrationOptions = false,
}) => {
  const [hoveredIntegration, setHoveredIntegration] = useState(null)

  return (
    <div
      className={styles.idContainer}
      onMouseEnter={() => setHoveredIntegration(integration.id)}
      onMouseLeave={() => setHoveredIntegration(null)}>
      <div className="flex items-center">
        <figure className={styles.logoContainer}>
          <img src={icon} alt={`${provider}Icon`} />
        </figure>

        <div className="flex">
          <p>{providersProperties[provider].text}:</p>
          <span className={styles.idText}>{integration.nameWithAlias}</span>
        </div>

        {!hideIntegrationOptions && (
          <RemoveCloudIntegrationButton
            provider={integration.provider}
            integration={integration}
            isHovered={hoveredIntegration === integration.id}
          />
        )}
      </div>

      {rightSideContent}
    </div>
  )
}

export default IntegrationHeader

IntegrationHeader.propTypes = {
  integration: PropTypes.object,
  provider: PropTypes.string,
  providersProperties: PropTypes.object,
  rightSideContent: PropTypes.object,
  icon: PropTypes.string,
  styles: PropTypes.object,
  hideIntegrationOptions: PropTypes.bool,
}
