import React, {useEffect, useState} from 'react'
import i18next from 'i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
  fetchCsrf,
  fetchReports,
  generateReport,
  loading,
  toggleRestrictionModal,
} from 'actions'

import Spinner from '../partials/Spinner'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import Subsection from 'components/partials/headers/Subsection'
import {Page} from 'components/partials/Page'
import PlanUtils from 'utils/plan'
import ReportsList from './ReportsList'
import ReportsEmptyState from './ReportsEmptyState'
import {alertStyle} from 'constant'
import CrownIcon from 'res/icons/crownIcon'
import {isLoading} from 'utils'

export default function Reports() {
  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const reportsState = useSelector(state => state.reports)
  const userState = useSelector(state => state.user)

  const dispatch = useDispatch()

  const [reports, setReports] = useState([])

  const planUtils = PlanUtils()

  const onClickGenerateReport = () => {
    planUtils.hasPlanRestriction()
      ? dispatch(
          toggleRestrictionModal({
            contentId: 'generateReportBtn',
            showModal: true,
          })
        )
      : handleGenerateReport()
  }

  const handleGenerateReport = () => {
    dispatch(loading({generateReport: true}))
    dispatch(generateReport({_csrf: csrfState}))
  }

  useEffect(() => {
    dispatch(fetchCsrf())
    dispatch(fetchReports())
  }, [])

  useEffect(() => {
    if (reportsState && reportsState.length) {
      setReports(reportsState)
    }
  }, [reportsState])

  const isGeneratingReport = isLoading(loadingState.generateReport, false)

  if (isLoading(loadingState.reports)) return <Spinner />

  return (
    <Page
      pageTitle={i18next.t('pageTitles.reports')}
      helpPath={'executiveReport'}>
      {isGeneratingReport && (
        <div className={`px-4 py-3 rounded text-xs ${alertStyle.info}`}>
          {i18next.t('warnings.generatingReport')}
        </div>
      )}

      {!reportsState?.length ? (
        <ReportsEmptyState
          handleGenerateReport={handleGenerateReport}
          loading={isGeneratingReport}
        />
      ) : (
        <Subsection
          title={i18next.t('reports.executiveReports')}
          content={<ReportsList reports={reports} />}
          button={
            <PrimaryButton
              text={i18next.t('buttons.generateReport')}
              loading={isGeneratingReport}
              disabled={userState?.role === 'read-only'}
              onClick={onClickGenerateReport}
              margin="ml-auto"
              size="sm"
              icon={
                planUtils.hasPlanRestriction() ? (
                  <CrownIcon width="20" height="20" />
                ) : null
              }
            />
          }
          margin="mt-8"
        />
      )}
    </Page>
  )
}
