import React, {useEffect, useMemo} from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import {DefaultModal} from 'components/partials/DefaultModal'
import {useDispatch, useSelector} from 'react-redux'
import PrimaryTableV8 from 'components/partials/tables/PrimaryTableV8'
import QuestionnaireEvaluationBadge from 'components/questionnaires/QuestionnaireEvaluationBadge'
import {clearAction, questionnaireReview} from 'actions'
import {tooltipStyle} from 'constant'

export default function QuestionnaireReviewModal({
  supplierId,
  questionnaireId,
  questions,
  setShowReviewModal,
}) {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const questionnaireReviewSuccessState = useSelector(
    state => state.questionnaireReviewSuccess
  )

  const onClickDecision = decision => {
    dispatch(
      questionnaireReview({
        supplierId,
        questionnaireId,
        decision,
        _csrf: csrfState,
      })
    )
  }

  const hasPartialOrNotMetEvaluation = () =>
    questions.some(
      question =>
        question.review?.evaluation === 'partiallyMeets' ||
        question.review?.evaluation === 'doesNotMeet'
    )

  const getEvaluationCount = evaluation => {
    let count = 0

    questions.forEach(question => {
      const review = question.review

      if (evaluation === 'noEvaluated') {
        if (!review) count++
      } else {
        if (review?.evaluation === evaluation) count++
      }
    })

    return count
  }

  const data = [
    {id: 'meets', questions: getEvaluationCount('meets')},
    {id: 'partiallyMeets', questions: getEvaluationCount('partiallyMeets')},
    {id: 'doesNotMeet', questions: getEvaluationCount('doesNotMeet')},
    {id: 'noEvaluated', questions: getEvaluationCount('noEvaluated')},
  ]

  const columns = useMemo(
    () => [
      {
        header: i18next.t('questionnaires.review.modal.table.evaluation'),
        accessorKey: 'id',
        enableSorting: false,
        size: 10000,
        cell: cell => (
          <QuestionnaireEvaluationBadge evaluation={cell.getValue()} />
        ),
        meta: {
          align: 'left',
        },
      },

      {
        header: i18next.t('questionnaires.review.modal.table.questions'),
        accessorKey: 'questions',
        enableSorting: false,
      },
    ],
    []
  )

  useEffect(() => {
    if (questionnaireReviewSuccessState) setShowReviewModal(false)

    return () => dispatch(clearAction('questionnaireReviewSuccess'))
  }, [questionnaireReviewSuccessState])

  return (
    <DefaultModal
      id="questionnaireReviewModal"
      title={i18next.t('questionnaires.review.modal.title')}
      width="max-w-xl"
      body={
        <div className="mb-8">
          <div className="text-sm leading-6 text-gray-700">
            {i18next.t('questionnaires.review.modal.body')}
          </div>
          <div className="my-2">
            <PrimaryTableV8
              columns={columns}
              data={data}
              hideSearch
              hidePagination
            />
          </div>

          <div className="text-xs text-gray-600 mt-6 -mb-4">
            {i18next.t('questionnaires.review.modal.note')}
          </div>
        </div>
      }
      primaryBtnText={i18next.t('questionnaires.review.modal.approveBtn')}
      onClickPrimaryBtn={() => onClickDecision('approved')}
      secondayBtnText={i18next.t(
        'questionnaires.review.modal.requestChangesBtn'
      )}
      onClickSecondaryBtn={() => onClickDecision('changesRequested')}
      secondaryBtnTooltip={
        !hasPartialOrNotMetEvaluation() ? (
          <span
            className={`${tooltipStyle.default} absolute top-0 left-0 -mt-12 w-100`}>
            {i18next.t('questionnaires.review.modal.evaluationTooltip')}
          </span>
        ) : null
      }
      disableSecondaryBtn={!hasPartialOrNotMetEvaluation()}
      onCloseModal={() => setShowReviewModal(false)}
    />
  )
}

QuestionnaireReviewModal.propTypes = {
  supplierId: PropTypes.string,
  questionnaireId: PropTypes.string,
  questions: PropTypes.array,
  setShowReviewModal: PropTypes.func,
}
