import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import {DefaultModal} from 'components/partials/DefaultModal'
import config from 'config'

export default function DocumentsDownloadModal({
  downloadDocumentModal,
  setDownloadDocumentModal,
}) {
  const handleDownloadDocument = id => {
    const url = new URL(`${config.SERVER_URL}/artifacts/${id}`)
    window.open(url, '_blank')

    setDownloadDocumentModal({
      selected: null,
      showModal: false,
    })
  }

  return (
    <DefaultModal
      id="downloadDocumentModal"
      title={i18next.t('documents.modal.download.modal.title')}
      primaryBtnText={i18next.t('documents.modal.download.modal.primaryBtn')}
      secondayBtnText={i18next.t('documents.modal.download.modal.secondaryBtn')}
      body={
        <div className="text-sm text-gray-700 leading-6">
          {i18next.t('documents.modal.download.modal.body')}
        </div>
      }
      onClickPrimaryBtn={() =>
        handleDownloadDocument(downloadDocumentModal.selected.id)
      }
      onClickSecondaryBtn={() => {
        setDownloadDocumentModal({
          selected: null,
          showModal: false,
        })
      }}
    />
  )
}

DocumentsDownloadModal.propTypes = {
  downloadDocumentModal: PropTypes.object,
  setDownloadDocumentModal: PropTypes.func,
}
