import i18next from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'

import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import SecondaryCheckbox from 'components/partials/inputs/SecondaryCheckbox'

export default function FilterMenu({
  setFilterMenuVisibility,
  filterMenuVisibility,
  filterForm,
  filterValue,
  setAppliedFilters,
  setFilterForm,
  initialFormState,
  refreshTableData,
}) {
  //Ao marcar um filtro, marcar a fonte correlata
  const checkRelatedSources = (name, checked) => {
    if (
      name === 'googleWorkspaceFilters' &&
      checked &&
      !filterForm.source.workspace
    )
      setFilterForm(prev => ({
        ...prev,
        source: {...prev.source, workspace: true},
      }))

    if (
      name === 'microsoft365Filters' &&
      checked &&
      !filterForm.source.microsoft365
    )
      setFilterForm(prev => ({
        ...prev,
        source: {...prev.source, microsoft365: true},
      }))
  }

  // Ao desmarcar uma fonte, desmarcar todos os filtros correlatos
  const uncheckRelatedFilters = (name, value, checked) => {
    if (
      (value === 'workspace' && !checked) ||
      (value === 'microsoft365' && checked)
    ) {
      return setFilterForm(prev => ({
        ...prev,
        googleWorkspaceFilters: {...initialFormState.googleWorkspaceFilters},
        [name]: {...prev[name], [value]: checked},
      }))
    }

    if (
      (value === 'microsoft365' && !checked) ||
      (value === 'workspace' && checked)
    )
      return setFilterForm(prev => ({
        ...prev,
        microsoft365Filters: {...initialFormState.microsoft365Filters},
        [name]: {...prev[name], [value]: checked},
      }))
  }

  const handleInputChange = ({target}) => {
    const {name, value, checked} = target

    checkRelatedSources(name, checked)
    uncheckRelatedFilters(name, value, checked)

    setFilterForm(prev => ({
      ...prev,
      [name]: {...prev[name], [value]: checked},
    }))
  }

  const handleExcludentOptions = (event, option1, option2) => {
    const {name, value, checked} = event.target
    const alternateValue = value === option1 ? option2 : option1

    checkRelatedSources(name, checked)
    uncheckRelatedFilters(name, value, checked)

    if (filterForm[name][alternateValue])
      setFilterForm(prev => ({
        ...prev,
        [name]: {
          ...prev[name],
          [alternateValue]: false,
        },
      }))

    setFilterForm(prev => ({
      ...prev,
      [name]: {...prev[name], [value]: checked},
    }))
  }

  const handleClearFilters = () => setFilterForm(initialFormState)

  const applyFilters = () => {
    setAppliedFilters(filterForm)
    refreshTableData(filterValue, filterForm, null, null)
  }

  return (
    <div className={filterMenuVisibility} id="filter-menu">
      <form className="absolute top-0 left-0 select-none bg-white rounded-md shadow-sm border overflow-hidden w-480px z-50 px-6 pt-8 pb-6 mt-10">
        <button
          onClick={e => {
            e.preventDefault()
            setFilterMenuVisibility('hidden')
          }}
          className="icofont-close absolute top-0 right-0 mt-4 mr-4 text-sideral-300"
        />
        <div>
          <h2 className="text-sm text-sideral-600 font-medium mb-4">
            {i18next.t('people.filters.accountSource')}
          </h2>
          <div className="flex flex-wrap">
            <div className="flex w-full">
              <div className="relative">
                <input
                  type="checkbox"
                  name="source"
                  value="workspace"
                  id="workspace"
                  checked={filterForm.source.workspace}
                  onChange={e =>
                    handleExcludentOptions(e, 'workspace', 'microsoft365')
                  }
                  className="appearance-none"
                />
                <label
                  htmlFor="workspace"
                  className={`${
                    filterForm.source.workspace
                      ? 'border-sky-900 bg-sky-900 text-white'
                      : 'bg-white text-sideral-300 border-sideral-100'
                  } ${
                    !filterForm.source.workspace &&
                    !filterForm.source.microsoft365
                      ? 'border-r'
                      : 'border-r-0'
                  } hover:shadow-sm border cursor-pointer select-none text-sm rounded-lg rounded-r-none p-2`}>
                  Google Workspace
                </label>
              </div>

              <div>
                <input
                  type="checkbox"
                  name="source"
                  value="microsoft365"
                  id="microsoft365"
                  checked={filterForm.source.microsoft365}
                  onChange={e =>
                    handleExcludentOptions(e, 'workspace', 'microsoft365')
                  }
                  className="appearance-none"
                />
                <label
                  htmlFor="microsoft365"
                  className={`${
                    filterForm.source.microsoft365
                      ? 'border-sky-900 bg-sky-900 text-white'
                      : 'bg-white text-sideral-300 border-sideral-100'
                  } hover:shadow-sm border border-l-0 cursor-pointer select-none text-sm rounded-lg rounded-l-none p-2`}>
                  Microsoft 365
                </label>
              </div>
            </div>
            <SecondaryCheckbox
              name="source"
              value="registration"
              id="registration"
              checked={filterForm.source.registration}
              onChange={handleInputChange}
              text={'Unxpose'}
              margin="mt-6"
            />
            <SecondaryCheckbox
              name="source"
              value="web"
              id="web"
              checked={filterForm.source.web}
              onChange={handleInputChange}
              text={'Web'}
              margin="mt-6 ml-4"
            />
          </div>
        </div>
        {filterForm.source.workspace ? (
          <div className="mt-8 pt-8 border-t">
            <h2 className="text-sm text-sideral-600 font-medium mb-4">
              {i18next.t('people.filters.googleWorkspaceFilters')}
            </h2>
            <div className="flex flex-wrap">
              <div className="relative">
                <input
                  type="checkbox"
                  name="googleWorkspaceFilters"
                  value="activeAccountsOnly"
                  id="activeAccountsOnly"
                  checked={filterForm.googleWorkspaceFilters.activeAccountsOnly}
                  onChange={e =>
                    handleExcludentOptions(
                      e,
                      'activeAccountsOnly',
                      'suspendedAccountsOnly'
                    )
                  }
                  className="appearance-none"
                />
                <label
                  htmlFor="activeAccountsOnly"
                  className={`${
                    filterForm.googleWorkspaceFilters.activeAccountsOnly
                      ? 'border-sky-900 bg-sky-900 text-white'
                      : 'bg-white text-sideral-300 border-sideral-100'
                  } ${
                    !filterForm.googleWorkspaceFilters.activeAccountsOnly &&
                    !filterForm.googleWorkspaceFilters.suspendedAccountsOnly
                      ? 'border-r'
                      : 'border-r-0'
                  } hover:shadow-sm border cursor-pointer select-none text-sm rounded-lg rounded-r-none p-2`}>
                  {i18next.t('people.filters.activeAccountsOnly')}
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="googleWorkspaceFilters"
                  value="suspendedAccountsOnly"
                  id="suspendedAccountsOnly"
                  checked={
                    filterForm.googleWorkspaceFilters.suspendedAccountsOnly
                  }
                  onChange={e =>
                    handleExcludentOptions(
                      e,
                      'activeAccountsOnly',
                      'suspendedAccountsOnly'
                    )
                  }
                  className="appearance-none"
                />
                <label
                  htmlFor="suspendedAccountsOnly"
                  className={`${
                    filterForm.googleWorkspaceFilters.suspendedAccountsOnly
                      ? 'border-sky-900 bg-sky-900 text-white'
                      : 'bg-white text-sideral-300 border-sideral-100'
                  } hover:shadow-sm border border-l-0 cursor-pointer select-none text-sm rounded-lg rounded-l-none p-2`}>
                  {i18next.t('people.filters.suspendedAccountsOnly')}
                </label>
              </div>

              <SecondaryCheckbox
                name="googleWorkspaceFilters"
                value="adminOnly"
                id="adminOnly"
                checked={filterForm.googleWorkspaceFilters.adminOnly}
                onChange={handleInputChange}
                text={i18next.t('people.filters.adminOnly')}
                margin="mt-6"
              />

              <SecondaryCheckbox
                name="googleWorkspaceFilters"
                value="mfaDisabledOnly"
                id="mfaDisabledOnly"
                checked={filterForm.googleWorkspaceFilters.mfaDisabledOnly}
                onChange={handleInputChange}
                text={i18next.t('people.filters.mfaDisabledOnly')}
                margin="mt-6 ml-4"
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {filterForm.source.microsoft365 ? (
          <div className="mt-8 pt-8 border-t">
            <h2 className="text-sm text-sideral-600 font-medium mb-4">
              {i18next.t('people.filters.microsoft365Filters')}
            </h2>
            <div className="flex">
              <SecondaryCheckbox
                name="microsoft365Filters"
                value="adminOnly"
                id="microsoft365AdminOnly"
                checked={filterForm.microsoft365Filters.adminOnly}
                onChange={handleInputChange}
                text={i18next.t('people.filters.adminOnly')}
              />
              <SecondaryCheckbox
                name="microsoft365Filters"
                value="mfaDisabledOnly"
                id="microsoft365mfa"
                checked={filterForm.microsoft365Filters.mfaDisabledOnly}
                onChange={handleInputChange}
                text={i18next.t('people.filters.mfaDisabledOnly')}
                margin="ml-4"
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="flex mt-8 mb-2">
          <div className="flex w-1/2 items-center">
            <SecondaryButton
              text={i18next.t('people.filters.clearFilters')}
              onClick={handleClearFilters}
              size="sm"
            />
          </div>
          <div className="flex justify-end w-1/2">
            <PrimaryButton
              text={i18next.t('buttons.apply')}
              onClick={e => {
                e.preventDefault()
                setFilterMenuVisibility('hidden')
                applyFilters()
              }}
              theme="blue"
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  )
}

FilterMenu.propTypes = {
  setFilterMenuVisibility: PropTypes.func,
  filterValue: PropTypes.string,
  filterForm: PropTypes.object,
  filterMenuVisibility: PropTypes.string,
  initialFormState: PropTypes.object,
  refreshTableData: PropTypes.func,
  setAppliedFilters: PropTypes.func,
  setFilterForm: PropTypes.func,
}
