export const getMostRecentDate = dates => {
  return dates.reduce(function (a, b) {
    return a > b ? a : b
  })
}

export const getKeyAge = user => {
  const dates = []

  if (
    user.data.access_key_1_last_rotated &&
    user.data.access_key_1_last_rotated !== 'N/A'
  )
    dates.push(user.data.access_key_1_last_rotated)

  if (
    user.data.access_key_2_last_rotated &&
    user.data.access_key_2_last_rotated !== 'N/A'
  )
    dates.push(user.data.access_key_2_last_rotated)

  if (dates.length === 1) return dates.pop()

  return dates.length ? getMostRecentDate(dates) : false
}

export const getLastActivity = user => {
  const dates = []

  if (
    user.data.password_last_used &&
    user.data.password_last_used !== 'N/A' &&
    user.data.password_last_used !== 'no_information'
  )
    dates.push(user.data.password_last_used)

  if (
    user.data.access_key_1_last_used_date &&
    user.data.access_key_1_last_used_date !== 'N/A' &&
    user.data.access_key_1_last_used_date !== 'no_information'
  )
    dates.push(user.data.access_key_1_last_used_date)

  if (
    user.data.access_key_2_last_used_date &&
    user.data.access_key_2_last_used_date !== 'N/A' &&
    user.data.access_key_1_last_used_date !== 'no_information'
  )
    dates.push(user.data.access_key_2_last_used_date)

  if (dates.length === 1) return dates.pop()

  return dates.length ? getMostRecentDate(dates) : false
}

export const getAwsIntegrationUpdatePath = (method, externalId) => {
  const awsIntegrationUpdatePath = '/cloud/aws/integration/update'

  return method === 'cloudFormation'
    ? `${awsIntegrationUpdatePath}/cloudformation?externalId=${externalId}`
    : `${awsIntegrationUpdatePath}/manual`
}
//Azure
export const mapAzureErrors = entries => {
  const result = {errors: []}
  const applicationNotFound = []
  const endpointResolutionError = []
  const expiredClientSecret = []
  const invalidClientSecret = []
  const noSubscriptionFound = []
  const tenantNotFound = []
  const subscriptionTypeNotPermitted = []

  entries.forEach(entry => {
    const [, value] = entry

    if (!value || !value.code || value.code === 'ok') return

    switch (value.code) {
      case 'INVALID_CLIENT_SECRET': {
        invalidClientSecret.push(entry)
        break
      }
      case 'APPLICATION_NOT_FOUND': {
        applicationNotFound.push(entry)
        break
      }
      case 'EXPIRED_CLIENT_SECRET': {
        expiredClientSecret.push(entry)
        break
      }
      case 'ENDPOINT_RESOLUTION_ERROR': {
        endpointResolutionError.push(entry)
        break
      }
      case 'NO_SUBSCRIPTION_FOUND': {
        noSubscriptionFound.push(entry)
        break
      }
      case 'SUBSCRIPTION_TYPE_NOT_PERMITTED': {
        subscriptionTypeNotPermitted.push(entry)
        break
      }
      case 'TENANT_NOT_FOUND': {
        tenantNotFound.push(entry)
        break
      }
      default: {
        result.unknownError = true
        break
      }
    }
  })

  result.errors = [
    ...applicationNotFound,
    ...endpointResolutionError,
    ...invalidClientSecret,
    ...expiredClientSecret,
    ...noSubscriptionFound,
    ...tenantNotFound,
    ...subscriptionTypeNotPermitted,
  ].filter(arr => arr.length)
  return result
}

//GCP
export const mapGcpErrors = entries => {
  const result = {errors: []}
  const billingDisabled = []
  const disabledApiError = []
  const notAuthorizedError = []
  const invalidProjectError = []
  const accountDeletedError = []

  entries.forEach(entry => {
    const [, value] = entry

    if (!value || !value.code || value.code === 'ok') return

    switch (value.code) {
      case 'BILLING_DISABLED': {
        billingDisabled.push(entry)
        break
      }
      case 'DISABLED_API': {
        disabledApiError.push(entry)
        break
      }
      case 'NOT_AUTHORIZED': {
        notAuthorizedError.push(entry)
        break
      }
      case 'INVALID_PROJECT': {
        invalidProjectError.push(entry)
        break
      }
      case 'ACCOUNT_DELETED': {
        accountDeletedError.push(entry)
        break
      }
      default: {
        result.unknownError = true
        break
      }
    }
  })

  result.errors = [
    ...disabledApiError,
    ...notAuthorizedError,
    ...billingDisabled,
    ...invalidProjectError,
    ...accountDeletedError,
  ].filter(arr => arr.length)
  return result
}
