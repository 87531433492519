import Chart from 'react-apexcharts'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import {
  fetchComplianceStats,
  fetchHasCloudIntegration,
  fetchLgpdIntegrations,
  redirect404,
} from 'actions'
import {hasFeature} from 'utils'
import {sky500, sky900} from 'constant'
import images from 'res'

import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import Spinner from 'components/partials/Spinner'
import Subsection from 'components/partials/headers/Subsection'
import {Page} from 'components/partials/Page'
import PlanUtils from 'utils/plan'
import SectionAdvantages from 'components/partials/SectionAdvantages'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'

export default function Dashboard() {
  const dispatch = useDispatch()

  const planUtils = PlanUtils()

  const userState = useSelector(state => state.user)
  const lgpdIntegrationsState = useSelector(state => state.lgpdIntegrations)
  const complianceStatsState = useSelector(state => state.complianceStats)
  const hasCloudIntegrationState = useSelector(
    state => state.hasCloudIntegration
  )

  const styles = {
    providerContainer: 'bg-white border border-sideral-50 rounded-lg mt-4 p-4',
    idContainer: 'flex border-b text-sm pb-4',
    idText: 'font-light text-sm pt-2',
    statsContainer: 'flex flex-wrap md:flex-nowrap items-center',
    statsBoxesContainer: 'flex w-full md:w-auto mt-4 md:mt-0 ml-auto',
    statsBox:
      'flex h-20 flex-wrap md:w-24 items-center justify-center rounded-lg border p-2 mr-4',
    statsBoxText: 'w-full text-center text-sm font-light',
  }

  const hasActiveLgpdIntegration = integrations => {
    if (!integrations) return false

    return integrations.some(integration => integration.monitoring)
  }

  useEffect(() => {
    if (userState && !hasFeature(userState, 'compliance'))
      dispatch(redirect404())
  }, [userState])

  useEffect(() => {
    if (!planUtils.hasPlanRestriction()) {
      dispatch(fetchHasCloudIntegration())
      dispatch(fetchLgpdIntegrations())
      dispatch(fetchComplianceStats())
    }
  }, [])

  if (planUtils.hasPlanRestriction())
    return (
      <>
        <Page pageTitle={i18next.t('pageTitles.compliance')}>
          <Subsection
            title="LGPD"
            content={<LgpdEmptyState styles={styles} />}
          />
        </Page>
      </>
    )

  if (hasCloudIntegrationState === undefined) return <Spinner />

  return (
    <Page pageTitle={i18next.t('pageTitles.compliance')}>
      <>
        {!lgpdIntegrationsState || !complianceStatsState ? (
          <Spinner />
        ) : hasActiveLgpdIntegration(lgpdIntegrationsState) ? (
          <LgpdHasIntegration styles={styles} />
        ) : (
          <Subsection
            title="LGPD"
            content={<LgpdEmptyState styles={styles} />}
          />
        )}
      </>
    </Page>
  )
}

const LgpdEmptyState = ({styles}) => {
  const hasCloudIntegrationState = useSelector(
    state => state.hasCloudIntegration
  )
  const navigate = useNavigate()

  const [dropdownIsOpen, setDropdownIsOpen] = useState(true)

  const planUtils = PlanUtils()

  const handleDropdown = () => setDropdownIsOpen(state => !state)

  const redirectUrl =
    planUtils.hasPlanRestriction() || !hasCloudIntegrationState
      ? '/cloud/integration'
      : '/compliance/lgpd/integration'

  return (
    <div className={styles.providerContainer + ' border-none'}>
      <div className="text-sideral-700 text-sm border rounded-md p-4">
        <SectionAdvantages
          customTitle={
            <div>
              <h2 className="font-medium mb-2">
                {i18next.t('compliance.lgpd.lgpdAndCloud')}
              </h2>
              <p>{i18next.t('compliance.lgpd.definition')}</p>
            </div>
          }
          advantagesItems={[
            i18next.t('compliance.lgpd.lgpdBenefitsItems.first'),
            i18next.t('compliance.lgpd.lgpdBenefitsItems.second'),
            i18next.t('compliance.lgpd.lgpdBenefitsItems.third'),
          ]}
          actionButtons={
            <PrimaryButton
              text={i18next.t('compliance.lgpd.buttons.startMonitoring')}
              onClick={() => navigate(redirectUrl)}
            />
          }
          rightSideImage={
            <img className="w-full h-full" src={images.complianceCards} />
          }
        />
      </div>

      <div
        onClick={handleDropdown}
        className="cursor-pointer border rounded-md p-4 mt-6">
        <div className="flex items-center">
          <p className="text-sideral-700 font-medium text-sm">
            {i18next.t('compliance.lgpd.disclaimer.title')}
          </p>
          <button className="ml-auto">
            <images.Chevron
              width="10"
              color={sky500}
              direction={dropdownIsOpen ? 'up' : 'down'}
            />
          </button>
        </div>
        <p
          className={`${
            dropdownIsOpen ? '' : 'hidden'
          } text-sm font-light w-11/12 mt-4`}>
          {i18next.t('compliance.lgpd.disclaimer.body')}
        </p>
      </div>
    </div>
  )
}

const LgpdHasIntegration = ({styles}) => {
  const complianceStatsState = useSelector(state => state.complianceStats)
  const navigate = useNavigate()

  const [chartColor, setChartColor] = useState(sky900)
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const handleDropdown = () => {
    return setDropdownIsOpen(state => !state)
  }

  const chartData = {
    options: {
      chart: {
        type: 'radialBar',
      },
      colors: [chartColor],
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              show: true,
              fontSize: '15px',
            },
            value: {
              show: true,
              fontSize: '18px',
            },
          },
          hollow: {
            size: '70%',
          },
        },
      },
      labels: [i18next.t('compliance.lgpd.stats.adhesion')],
    },
  }

  function getChartColor(percentage) {
    if (percentage <= 20) return setChartColor('#c53030')
    if (percentage > 20 && percentage <= 40) return setChartColor('#f56565')
    if (percentage > 40 && percentage <= 60) return setChartColor('#ed8936')
    if (percentage > 60 && percentage <= 80) return setChartColor('#ecc94b')
    if (percentage > 80 && percentage <= 99) return setChartColor('#48bb78')
    if (percentage === 100) return setChartColor('#fff')

    return setChartColor('#a0aec0')
  }
  useEffect(() => {
    getChartColor(complianceStatsState.lgpd.percentage)
  }, [complianceStatsState.lgpd.percentage])

  return (
    <div className={styles.providerContainer}>
      <div className={styles.idContainer}>
        <p className="text-base">LGPD</p>
        <SecondaryButton
          text={i18next.t('buttons.details')}
          onClick={() => navigate(`/compliance/lgpd`)}
          size="sm"
          direction="right"
          margin="ml-auto"
        />
      </div>
      <div className="flex">
        <div className="w-8/12 mr-4">
          <p className="pt-4 text-sm">
            {i18next.t('compliance.lgpd.lgpdAndCloud')}
          </p>
          <p className={styles.idText}>
            {i18next.t('compliance.lgpd.definition')}
          </p>
          <p className="text-sm pt-2">
            {i18next.t('compliance.lgpd.improveYourCompliance')}
          </p>
        </div>

        <div className={`${styles.statsBoxesContainer} pt-4`}>
          <div className={styles.statsBox}>
            <span className="text-lg">
              {complianceStatsState.lgpd.cloudIntegrationsCount}
            </span>
            <p className={styles.statsBoxText}>
              {i18next.t('compliance.lgpd.stats.providers')}
            </p>
          </div>
          <div className={styles.statsBox}>
            <span className="text-lg">
              {complianceStatsState.lgpd.assetsCount}
            </span>
            <p className={styles.statsBoxText}>
              {i18next.t('compliance.lgpd.stats.assets')}
            </p>
          </div>
          <div className={styles.statsBox}>
            <span className="text-lg">
              {complianceStatsState.lgpd.findingsCount}
            </span>
            <p className={styles.statsBoxText}>
              {i18next.t('compliance.lgpd.stats.findings')}
            </p>
          </div>
          <div className="flex justify-center border rounded-md">
            <Chart
              options={chartData.options}
              series={[complianceStatsState.lgpd.percentage]}
              type="radialBar"
              height={180}
              width={160}
            />
          </div>
        </div>
      </div>
      <div
        onClick={handleDropdown}
        className="cursor-pointer border rounded-md p-4 mt-6">
        <div className="flex items-center">
          <p className="text-sideral-700 font-medium text-sm">
            {i18next.t('compliance.lgpd.disclaimer.title')}
          </p>
          <button className="ml-auto">
            <images.Chevron
              width="10"
              color={sky500}
              direction={dropdownIsOpen ? 'up' : 'down'}
            />
          </button>
        </div>
        <p
          className={`${
            dropdownIsOpen ? '' : 'hidden'
          } text-sm font-light w-11/12 mt-4`}>
          {i18next.t('compliance.lgpd.disclaimer.body')}
        </p>
      </div>
    </div>
  )
}

LgpdEmptyState.propTypes = {
  styles: PropTypes.object,
}

LgpdHasIntegration.propTypes = {
  styles: PropTypes.object,
}
