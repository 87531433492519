import PropTypes from 'prop-types'
import React, {useState} from 'react'
import i18next from 'i18next'

import images from 'res'

import RemoveCloudIntegrationButton from 'components/partials/buttons/RemoveCloudIntegrationButton'
import BackButton from 'components/partials/buttons/BackButton'

export default function IntegrationStats({
  provider,
  score,
  integration,
  nAssets,
  nFindings,
}) {
  const [hoveredIntegration, setHoveredIntegration] = useState(false)

  const getIntegrationId = (provider, integration) => {
    switch (provider) {
      case 'aws':
        return integration.nameWithAlias
      case 'azure':
        return integration.tenantId
      case 'gcp':
        return integration.projectId
    }
  }

  const styles = {
    mainContainer:
      'flex flex-wrap text-astral-900 text-sm rounded-lg border bg-white w-full p-6',
    statsContainer: 'flex md:w-6/12 w-full justify-between mt-4 md:mt-0',
    statsLabel: 'w-full text-center text-base font-light',
    stats:
      'flex flex-wrap md:w-32 items-center justify-center rounded-lg border p-2',
    figure: 'flex items-center w-16 mr-6',
    provider: 'text-xl py-2',
  }

  const providers = {
    aws: {
      name: 'Amazon Web Services',
      icon: images.awsIcon,
      identifier: i18next.t('cloud.accountId'),
    },
    gcp: {
      name: 'Google Cloud Platform',
      icon: images.gcpIcon,
      identifier: i18next.t('cloud.projectId'),
    },
    azure: {
      name: 'Microsoft Azure',
      icon: images.azureIcon,
      identifier: i18next.t('cloud.directoryId'),
    },
  }

  return (
    <div
      onMouseEnter={() => setHoveredIntegration(true)}
      onMouseLeave={() => setHoveredIntegration(false)}
      className={styles.mainContainer}>
      <div className="flex md:w-6/12 w-full">
        <figure className={styles.figure}>
          <img className="w-full" src={providers[provider].icon} />
        </figure>
        <div className="mr-6">
          <BackButton fallbackLink={'cloud'} />

          <h2 className={styles.provider}>{providers[provider].name}</h2>
          <div>
            <span>{providers[provider].identifier}:</span>
            <span className="inline-flex font-light ml-1">
              {getIntegrationId(provider, integration)}
              <RemoveCloudIntegrationButton
                provider={provider}
                integration={integration}
                isHovered={hoveredIntegration}
              />
            </span>
          </div>
        </div>
      </div>

      <div className={styles.statsContainer}>
        <div className={styles.stats}>
          <span className="text-2xl">{nAssets}</span>
          <span className={styles.statsLabel}>{i18next.t('misc.assets')}</span>
        </div>
        <div className={styles.stats}>
          <span className="text-2xl">{nFindings}</span>
          <span className={styles.statsLabel}>
            {i18next.t('misc.findings')}
          </span>
        </div>
        <ScoreBadge score={score || '-'} />
      </div>
    </div>
  )
}

const ScoreBadge = ({score}) => {
  let color

  switch (score) {
    case 'A':
      color = 'bg-teal-500'
      break
    case 'B':
      color = 'bg-green-500'
      break
    case 'C':
      color = 'bg-yellow-500'
      break
    case 'D':
      color = 'bg-orange-500'
      break
    case 'E':
      color = 'bg-red-500'
      break
    case 'F':
      color = 'bg-red-700'
      break
    default:
      color = 'bg-gray-400 text-gray-600'
      break
  }

  return (
    <div
      className={`flex flex-wrap md:w-32 items-center justify-center rounded-lg border p-2 text-white ${color}`}>
      <span className="text-2xl">{score}</span>
      <span className="w-full text-center text-base ">Score</span>
    </div>
  )
}

ScoreBadge.propTypes = {
  score: PropTypes.string,
}

IntegrationStats.propTypes = {
  provider: PropTypes.string,
  integration: PropTypes.object,
  nAssets: PropTypes.number,
  nFindings: PropTypes.number,
  score: PropTypes.string,
}
