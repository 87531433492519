import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import PrimaryButton from './PrimaryButton'
import FilterIcon from 'res/icons/FilterIcon'
import {sky900} from 'constant'

export default function FilterButton({filtersCount, onClick}) {
  return (
    <PrimaryButton
      text={i18next.t('people.filters.filters')}
      theme="blue-outline"
      size="sm"
      onClick={onClick}
      icon={<FilterIcon height="20" width="20" color={sky900} />}
      rightContent={
        <div
          className={`flex items-center justify-center w-5 h-5 rounded-full ${
            filtersCount ? 'bg-sky-900 text-white' : 'bg-sideral-50'
          }`}>
          {filtersCount}
        </div>
      }
    />
  )
}

FilterButton.propTypes = {
  filtersCount: PropTypes.number,
  onClick: PropTypes.func,
}
