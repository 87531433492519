import i18next from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'
import Select from 'react-select'

import {sideral100} from 'constant'

export function matchesMultiSelect(rows, id, filterValue) {
  return rows.filter(row => {
    const prop = row && row.values[id]
    return filterValue && filterValue.length
      ? filterValue.find(x => x.value === prop)
      : true
  })
}

export function TypeFilter({column: {filterValue, setFilter}}) {
  const reactSelectTheme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      neutral20: '#e2e8f0',
    },
  })

  const reactSelectStyles = {
    control: provided => ({
      ...provided,
      border: `1px solid ${sideral100}`,
    }),
    placeholder: provided => ({
      ...provided,
      color: '#ccd5e1',
    }),
  }

  function handleFilter(filter) {
    setFilter(filter)
  }

  const recordTypesList = {
    //the order statusList is sorted has influence in filters sort
    a: 'A',
    aaaa: 'AAAA',
    caa: 'CAA',
    cname: 'CNAME',
    mx: 'MX',
    ns: 'NS',
    ptr: 'PTR',
    soa: 'SOA',
    srv: 'SRV',
    txt: 'TXT',
    tlsa: 'TLSA',
    ds: 'DS',
    dnskey: 'DNSKEY',
    rrsig: 'RRSIG',
  }

  return (
    <div className="w-64 text-xs inline-block ml-4">
      <Select
        isMulti
        placeholder={i18next.t('dns.filters.typePlaceholder')}
        styles={reactSelectStyles}
        options={
          recordTypesList &&
          Object.keys(recordTypesList).map(key => ({
            value: key,
            label: recordTypesList[key],
          }))
        }
        theme={reactSelectTheme}
        value={filterValue}
        onChange={item => {
          handleFilter(item)
        }}
      />
    </div>
  )
}

TypeFilter.propTypes = {column: PropTypes.object}
