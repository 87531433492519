import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'

import {loading, fetchQuestionnaireTemplates, sendQuestionnaire} from 'actions'
import {Page} from 'components/partials/Page'
import BackButton from 'components/partials/buttons/BackButton'
import Subsection from 'components/partials/headers/Subsection'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'
import {useParams} from 'react-router-dom'
import SecondaryButton from 'components/partials/buttons/SecondaryButton'
import {isLoading} from 'utils'

export default function SupplierSendQuestionnaire() {
  return (
    <Page pageTitle={i18next.t('pageTitles.suppliers')}>
      <BackButton fallbackLink="/suppliers" />
      <div className="mt-4">
        <Subsection
          title={i18next.t('questionnaires.sendQuestionnaire.title')}
          content={<Form />}
        />
      </div>
    </Page>
  )
}

const Form = () => {
  const initialFormState = {
    questionnaireName: '',
    questionnaireDescription: '',
    questionnaireTemplateId: '',
  }

  const dispatch = useDispatch()
  const params = useParams()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)
  const questionnaireTemplatesState = useSelector(
    state => state.questionnaireTemplates
  )

  const [form, setForm] = useState(initialFormState)

  const handleInputChange = e => {
    const {name} = e.target
    setForm({...form, [name]: e.target.value})
  }

  const handleSelectChange = selected => {
    setForm({...form, questionnaireTemplateId: selected.id})
  }

  const handleSubmit = e => {
    e.preventDefault()

    dispatch(loading({sendQuestionnaire: true}))

    dispatch(
      sendQuestionnaire({
        questionnaireId: form.questionnaireTemplateId,
        name: form.questionnaireName,
        description: form.questionnaireDescription,
        supplierId: params.id,
        _csrf: csrfState,
      })
    )
  }

  return (
    <>
      <form className="xl:w-10/12 py-4 px-6" onSubmit={handleSubmit}>
        <div id="inputs-container">
          <div className="md:flex border-b border-sideral-50 pt-8 pb-6">
            <div className="basis-1/3 mr-8">
              <p className="text-sm text-sideral-900">
                {i18next.t(
                  'questionnaires.sendQuestionnaire.questionnaireName'
                )}
                *
              </p>
              <p className="text-light text-xs text-sideral-400 pt-1">
                {i18next.t(
                  'questionnaires.sendQuestionnaire.questionnaireNameHelper'
                )}
              </p>
            </div>
            <div className="w-full sm:basis-2/3">
              <PrimaryInput
                name="questionnaireName"
                type="text"
                value={form.questionnaireName}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="md:flex border-b border-sideral-50 pt-8 pb-6">
            <div className="basis-1/3 mr-8">
              <p className="text-sm text-sideral-900">
                {i18next.t(
                  'questionnaires.sendQuestionnaire.questionnaireDescription'
                )}
              </p>
              <p className="text-light text-xs text-sideral-400 pt-1">
                {i18next.t(
                  'questionnaires.sendQuestionnaire.questionnaireDescriptionHelper'
                )}
              </p>
            </div>
            <div className="w-full sm:basis-2/3">
              <textarea
                rows="3"
                name="questionnaireDescription"
                onChange={handleInputChange}
                value={form.questionnaireDescription}
                className="w-full text-sm border border-sideral-100 rounded-md mt-2 p-2"
              />
            </div>
          </div>

          <div className="md:flex pt-8 pb-6">
            <div className="basis-1/3 mr-8">
              <p className="text-sm text-sideral-900">
                {i18next.t('questionnaires.sendQuestionnaire.template')}
              </p>
              <p className="text-light text-xs text-sideral-400 pt-1">
                {i18next.t('questionnaires.sendQuestionnaire.templateHelper')}
              </p>
            </div>
            <div className="w-full sm:basis-2/3">
              <PrimarySelect
                name="questionnaireTemplate"
                options={questionnaireTemplatesState?.map(template => ({
                  id: template.id,
                  label: template.name,
                  value: template.name,
                }))}
                onChange={handleSelectChange}
                onMenuOpen={() => {
                  dispatch(loading({questionnaireTemplates: true}))
                  dispatch(fetchQuestionnaireTemplates())
                }}
                isLoading={isLoading(
                  loadingState.questionnaireTemplates,
                  false
                )}
                addNewButton={
                  <SecondaryButton
                    text={i18next.t('misc.addNew')}
                    onClick={() =>
                      window.open('/questionnaires/templates/new', '_blank')
                    }
                    size="sm"
                  />
                }
                required
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-2 mb-4">
          <PrimaryButton
            text={i18next.t('questionnaires.sendQuestionnaire.sendBtn')}
            type="submit"
            loading={isLoading(loadingState.sendQuestionnaire, false)}
          />
        </div>
      </form>
    </>
  )
}
