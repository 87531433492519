import i18next from 'i18next'
import React from 'react'
import {useSelector} from 'react-redux'
import Chart from 'react-apexcharts'
import moment from 'moment'

import {sideral300, sky500, sky900} from 'constant'

export default function TotalVsClosedChart() {
  const issuesStatusState = useSelector(state => state.issuesStatus)

  const TotalVsClosedData = {
    series: [
      {
        name: i18next.t('labels.totalFindings'),
        type: 'area',
        data: issuesStatusState ? issuesStatusState.total : [],
      },
      {
        name: i18next.t('labels.closedFindings'),
        type: 'area',
        data: issuesStatusState ? issuesStatusState.closed : [],
      },
    ],
    options: {
      chart: {
        fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
        toolbar: {
          show: false,
          tools: {
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            reset: false,
            download: false,
          },
        },
        events: {
          beforeZoom: function (ctx) {
            ctx.w.config.xaxis.range = undefined
          },
        },
      },
      legend: {
        show: true,
        height: 32,
        width: '100%',
        horizontalAlign: 'left',
        offsetX: -6,

        markers: {
          width: 16,
          height: 16,
          offsetY: 3,
          radius: 3,
        },
      },
      colors: [sky900, sky500],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        categories: issuesStatusState ? issuesStatusState.dates : [],
        range: moment().valueOf() - moment().subtract(15, 'days').valueOf(),
        labels: {
          style: {
            colors: sideral300,
            fontSize: 14,
            fontWeight: 400,
          },
          formatter: x => {
            return moment(x).utc().format('DD/MMM')
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: sideral300,
            fontSize: 14,
            fontWeight: 600,
          },
        },
      },
      tooltip: {
        style: {
          colors: sideral300,
          fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
          fontSize: 12,
        },
        x: {
          show: true,
          format: 'dd/MM/yy',
        },
        marker: {show: false},
      },
    },
  }

  return (
    <div className="p-2">
      <Chart
        className="mx-auto"
        options={TotalVsClosedData.options}
        series={TotalVsClosedData.series}
        type="area"
        width="100%"
        height="240"
      />
    </div>
  )
}
