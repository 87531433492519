import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import i18next from 'i18next'
import {userRolesList} from 'constant'
import {DefaultModal} from 'components/partials/DefaultModal'
import {genericError, inviteMember} from 'actions'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import PrimarySelect from 'components/partials/inputs/PrimarySelect'

export default function InviteUserModal({setShowInviteModal}) {
  const dispatch = useDispatch()

  const companyState = useSelector(state => state.company)
  const invitesState = useSelector(state => state.invites)
  const disabledState = useSelector(state => state.disabled)
  const successState = useSelector(state => state.success)
  const csrfState = useSelector(state => state.csrf)

  const [invite, setInvite] = useState({
    email: '',
    name: '',
    roleName: '',
  })

  const handleInvite = e => {
    e.preventDefault()

    if (!invite.name || !invite.email) return
    if (invite.email.length < 5)
      return dispatch(
        genericError({message: i18next.t('errors.atLeastFiveCharacters')})
      )
    if (invite.name.length < 2)
      return dispatch(
        genericError({message: i18next.t('errors.atLeastTwoCharacters')})
      )

    if (
      companyState &&
      companyState.members.some(member => member.email === invite.email)
    )
      return dispatch(
        genericError({
          message: i18next.t('settings.errors.userAlreadyRegisteredInCompany', {
            email: invite.email,
            company: companyState.name,
          }),
        })
      )

    if (
      invitesState.some(
        invited => invited.email === invite.email && invited.status === 'sent'
      )
    )
      return dispatch(
        genericError({
          message: i18next.t('settings.errors.userAlreadyInvited', {
            email: invite.email,
            company: companyState.name,
          }),
        })
      )

    dispatch(
      inviteMember({
        _csrf: csrfState,
        companyId: companyState.id,
        companyName: companyState.name,
        email: invite.email,
        name: invite.name,
        roleName: invite.roleName,
      })
    )
  }

  const handleInviteChange = e => {
    const {name, value} = e.target
    setInvite({...invite, [name]: value})
  }

  const handleSelectChange = event => {
    const {value} = event
    setInvite({...invite, roleName: value})
  }

  useEffect(() => {
    if (successState) {
      setInvite({})
      setShowInviteModal(false)
    }
  }, [successState])

  return (
    <form onSubmit={handleInvite}>
      <DefaultModal
        id="member-invite-modal"
        title={i18next.t('titles.inviteMembers')}
        body={
          <>
            <PrimaryInput
              text={i18next.t('labels.name')}
              type="text"
              name="name"
              required={true}
              value={invite.name || ''}
              onChange={handleInviteChange}
              margin="mb-6"
            />
            <PrimaryInput
              text={i18next.t('labels.email')}
              type="email"
              name="email"
              required={true}
              value={invite.email || ''}
              onChange={handleInviteChange}
              margin="mb-6"
            />
            <div className="relative mt-2 mb-6">
              <PrimarySelect
                text={i18next.t('labels.role')}
                name="roleName"
                onChange={handleSelectChange}
                options={userRolesList}
                menuPlacement="top"
              />
            </div>

            {(invite.roleName === 'regular' ||
              invite.roleName === 'read-only') && (
              <div className="text-xs text-gray-600">
                {i18next.t('settings.members.inviteUserModal.note')}
              </div>
            )}
          </>
        }
        primaryButtonType="submit"
        primaryBtnText={i18next.t('buttons.sendInvite')}
        primaryButtonLoading={disabledState}
        secondayBtnText={i18next.t('buttons.cancel')}
        onClickSecondaryBtn={() => {
          setShowInviteModal(false)
        }}
        onCloseModal={() => setShowInviteModal(false)}
      />
    </form>
  )
}

InviteUserModal.propTypes = {
  setShowInviteModal: PropTypes.func,
}
