import React, {useState} from 'react'
import {Trans} from 'react-i18next'
import moment from 'moment'
import {useSelector} from 'react-redux'
import Alert from './Alert'

const MAX_ELAPSED_MINUTES = 10

const ProcessingAlert = () => {
  const userState = useSelector(state => state.user)

  const [showAlert, setShowAlert] = useState(false)

  const nowDateTime = moment()
  const accountCreationDate = moment(userState?.company?.createdAt)
  const elapsedMinutes = nowDateTime.diff(accountCreationDate, 'minutes')

  useState(() => {
    if (elapsedMinutes <= MAX_ELAPSED_MINUTES) setShowAlert(true)
  }, [])

  return (
    <Alert
      iconContent={
        <span className="relative flex h-3 w-3 mr-4">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-orange-400"></span>
        </span>
      }
      content={<Trans i18nKey="processingAlert.content" />}
      style="bg-orange-200 text-yellow-900"
      showAlert={showAlert}
      hideCloseIcon
    />
  )
}

export default ProcessingAlert
