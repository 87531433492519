import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import {tableStyles} from 'constant'
import {Link} from 'react-router-dom'

import Pagination from 'components/partials/tables/Pagination'
import GlobalFilter from 'components/partials/tables/GlobalFilter'

import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

export default function TableBreaches(props) {
  const [filterValue, setFilterValue] = useState('')
  const [imageErrorMap, setImageErrorMap] = useState({})

  const handleImageError = rowId => {
    setImageErrorMap(prev => ({...prev, [rowId]: true}))
  }

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 100, // minWidth is only used as a limit for resizing
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    rows,

    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: {pageIndex},
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn,
      initialState: {
        pageIndex: props.pageNumber,
        pageSize: props.pageSize || 5,
        sortBy: props.defaultSorted || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  )

  return (
    <div>
      <div className="tableWrap relative">
        <div className="flex flex-wrap justify-end sm:justify-between items-center mb-4">
          <div className="w-full sm:w-auto order-last sm:order-none mt-2 sm:mt-0">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={filterValue}
              setFilterValue={setFilterValue}
              setGlobalFilter={setGlobalFilter}
              gotoPage={gotoPage}
              filterWidth="w-full sm:w-64"
            />
          </div>
          <Pagination
            handlePrevious={previousPage}
            handleNext={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            paginationClass={props.paginationClass}
            pageIndex={pageIndex}
            pageCount={pageCount}
            total={rows.length || 0}
          />
        </div>
        {page && page.length ? (
          <div className="overflow-x-auto">
            <table {...getTableProps()} className="w-full">
              <thead>
                {headerGroups.map(headerGroup => {
                  // trick to disable the first headerGroup
                  if (headerGroup.headers.length === 1)
                    return <tr key={uuidv4()}></tr>

                  return (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={uuidv4()}
                      className="p-2">
                      {headerGroup.headers.map(column => (
                        <th
                          className="dm-mono text-sideral-300 text-sm"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          key={uuidv4()}>
                          {column.render('Header').toUpperCase()}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' ↓'
                                : ' ↑'
                              : ''}
                          </span>
                        </th>
                      ))}
                    </tr>
                  )
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={tableStyles.tableRow}
                      key={uuidv4()}>
                      {row.cells.map(cell => {
                        if (cell.column.id === 'description')
                          return (
                            <td
                              className={cell.column.classes || ''}
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              <Link to={`/people/${row.original.id}`}>
                                <div
                                  dangerouslySetInnerHTML={(() => {
                                    return {__html: cell.value}
                                  })()}
                                />
                              </Link>
                            </td>
                          )

                        if (cell.column.id === 'logo') {
                          if (
                            (cell.value &&
                              cell.value ===
                                'https://s3-eu-west-1.amazonaws.com/be-resources/dataleaks/List.png') ||
                            imageErrorMap[row.id]
                          )
                            return (
                              <td {...cell.getCellProps()} key={uuidv4()}>
                                <Link
                                  className="hover:no-underline"
                                  to={`/people/${row.original.id}`}>
                                  <figure className="flex items-center justify-center rounded-md h-16 ml-4">
                                    <i className="icofont-database text-4xl text-sideral-300 mx-3" />
                                  </figure>
                                </Link>
                              </td>
                            )

                          return (
                            <td
                              {...cell.getCellProps()}
                              className="bg-astral-200"
                              key={uuidv4()}>
                              <Link
                                className="hover:no-underline"
                                to={`/people/${row.original.id}`}>
                                <figure className="flex items-center justify-center rounded-md h-16 ">
                                  <img
                                    src={cell.value}
                                    style={{
                                      maxWidth: '50px',
                                      maxHeight: '35px',
                                    }}
                                    alt={cell.value}
                                    className="mx-auto"
                                    onError={() => handleImageError(row.id)}
                                  />
                                </figure>
                              </Link>
                            </td>
                          )
                        }

                        if (typeof cell.value === 'object')
                          return (
                            <td
                              className={cell.column.classes || ''}
                              {...cell.getCellProps()}
                              key={uuidv4()}>
                              <Link to={`/people/${row.original.id}`}>
                                {cell.value ? cell.value.join(', ') : '—'}
                              </Link>
                            </td>
                          )

                        return (
                          <td
                            className={cell.column.classes || ''}
                            {...cell.getCellProps()}
                            key={uuidv4()}>
                            <Link to={`/people/${row.original.id}`}>
                              {cell.value ? cell.render('Cell') : '—'}
                            </Link>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <>
            {filterValue
              ? props.emptyStateWithFilter
              : props.emptyStateWithoutFilter}
          </>
        )}
      </div>
    </div>
  )
}

TableBreaches.propTypes = {
  defaultSorted: PropTypes.array,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  paginationClass: PropTypes.string,
  history: PropTypes.object,
  emptyStateWithFilter: PropTypes.object,
  emptyStateWithoutFilter: PropTypes.object,
}
