import i18next from 'i18next'
import React from 'react'

import Footer from './Footer'
import CopyToClipboard from 'components/partials/CopyToClipboard'

export default function Jira() {
  const summaryField = '[{{webhookData.severity}}] - {{webhookData.title}}'
  const descriptionField = `{{webhookData.text}}
  URL: {{webhookData.findingUrl}}
  Description: {{webhookData.description}}
  Solution: {{webhookData.solution}}
  References: {{webhookData.references}}`

  const copyToClipboard = id => {
    switch (id) {
      case 'description': {
        return navigator.clipboard.writeText(descriptionField)
      }
      case 'summary': {
        return navigator.clipboard.writeText(summaryField)
      }
      default:
        return
    }
  }

  return (
    <>
      <div className="py-4 px-8">
        <div className="flex">
          <div className="w-full text-sm">
            <h1 className="font-medium mt-2 mb-4 text-lg">
              {i18next.t('help.jira.menu.title')}
            </h1>
            <h2 className="font-medium mt-2 mb-4 text-normal">
              {i18next.t('help.jira.intro.title')}
            </h2>
            <p className="mb-4">{i18next.t('help.jira.intro.body')}</p>

            <h2 className="font-medium mt-2 mb-4 text-normal">
              {i18next.t('help.jira.jiraConfig.title')}
            </h2>

            <ol className=" list-decimal ml-4">
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.jiraConfig.step1'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.jiraConfig.step2'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.jiraConfig.step3'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.jiraConfig.step4'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.jiraConfig.step5'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.jiraConfig.step6'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.jiraConfig.step7'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.jiraConfig.step8'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.jiraConfig.step9.text'),
                }}
                className="mt-2"
              />
              <ul className="list-disc ml-4">
                <li
                  dangerouslySetInnerHTML={{
                    __html: i18next.t('help.jira.jiraConfig.step9.subItem1'),
                  }}
                  className="mt-2"
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: i18next.t('help.jira.jiraConfig.step9.subItem2'),
                  }}
                  className="mt-2"
                />

                <CopyToClipboard
                  content={<pre>{summaryField}</pre>}
                  onClick={() => copyToClipboard('summary')}
                  classes="mt-2"
                />

                <li
                  dangerouslySetInnerHTML={{
                    __html: i18next.t('help.jira.jiraConfig.step9.subItem3'),
                  }}
                  className="mt-2"
                />

                <CopyToClipboard
                  content={<pre>{descriptionField}</pre>}
                  onClick={() => copyToClipboard('description')}
                  classes="mt-2"
                />

                <li
                  dangerouslySetInnerHTML={{
                    __html: i18next.t('help.jira.jiraConfig.step9.subItem4'),
                  }}
                  className="mt-2"
                />
              </ul>
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.jiraConfig.step10'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.jiraConfig.step11'),
                }}
                className="mt-2 mb-4"
              />
            </ol>

            <h2 className="font-medium mt-2 mb-4 text-normal">
              {i18next.t('help.jira.unxposeConfig.title')}
            </h2>

            <ol className="list-decimal ml-4">
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.unxposeConfig.step1'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.unxposeConfig.step2'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.unxposeConfig.step3'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.unxposeConfig.step4'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.unxposeConfig.step5'),
                }}
                className="mt-2"
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: i18next.t('help.jira.unxposeConfig.step6'),
                }}
                className="mt-2 mb-4"
              />
            </ol>

            <h2 className="font-medium mt-2 mb-4 text-normal">
              {i18next.t('help.jira.conclusion.title')}
            </h2>
            <p className="">{i18next.t('help.jira.conclusion.body')}</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
