import {getRequestParams} from 'utils/pagination'
import {handler} from './handler'
import config from '../config'
import {
  fetchGoogleWorkspaceIntegrationsDone,
  fetchGoogleWorkspaceIntegrations,
  fetchCompanyEmailsDone,
  genericError,
  fetchGoogleWorkspaceFindingDone,
  fetchGoogleWorkspaceFindingActivity,
  fetchGoogleWorkspaceFindingActivityDone,
  success,
  fetchGoogleWorkspaceFinding,
  removeWorkspaceIntegrationDone,
} from 'actions'

export function* handleRemoveWorkspaceIntegration(action) {
  yield handler({
    handler: removeWorkspaceIntegration,
    error: genericError,
    success: [
      () =>
        success({
          message: action.payload.successMessage,
        }),
      removeWorkspaceIntegrationDone,
      fetchGoogleWorkspaceIntegrations,
    ],
    action,
  })
}

const removeWorkspaceIntegration = function (body) {
  return fetch(
    `${config.SERVER_URL}/workspace/integrations/${body.integrationId}`,
    {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      body: JSON.stringify(body),
    }
  ).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGoogleWorkspaceIntegrations(action) {
  yield handler({
    handler: _fetchGoogleWorkspaceIntegrations,
    error: [
      () =>
        genericError({
          loading: {googleWorkspaceIntegrations: false},
        }),
    ],
    success: fetchGoogleWorkspaceIntegrationsDone,
    action,
  })
}

const _fetchGoogleWorkspaceIntegrations = function () {
  return fetch(config.SERVER_URL + '/workspace/integrations', {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchCompanyEmails(action) {
  yield handler({
    handler: _fetchCompanyEmails,
    error: [
      () =>
        genericError({
          loading: {companyEmails: false},
        }),
      () =>
        fetchCompanyEmailsDone({
          payload: [],
        }),
    ],
    success: fetchCompanyEmailsDone,
    action,
  })
}

const _fetchCompanyEmails = function (params) {
  const url = new URL(`${config.SERVER_URL}/people/accounts`)
  url.search = new URLSearchParams(getRequestParams(params)).toString()

  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

const _fetchGoogleWorkspaceFinding = findingId => {
  return fetch(`${config.SERVER_URL}/workspace/finding/${findingId}`, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  })
}

export function* handleFetchGoogleWorkspaceFinding(action) {
  yield handler({
    handler: _fetchGoogleWorkspaceFinding,
    error: genericError,
    success: fetchGoogleWorkspaceFindingDone,
    action,
  })
}

const _fetchGoogleWorkspaceFindingActivity = findingId => {
  const url = `${config.SERVER_URL}/workspace/finding/${findingId}/activity`

  return fetch(url, {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGoogleWorkspaceFindingActivity(action) {
  yield handler({
    handler: _fetchGoogleWorkspaceFindingActivity,
    success: fetchGoogleWorkspaceFindingActivityDone,
    action,
  })
}

const _updateGoogleWorkspaceFindingStatus = change => {
  return fetch(
    `${config.SERVER_URL}/workspace/finding/${change.findingId}/${change.status}`,
    {
      method: 'PATCH',
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
    }
  )
}

export function* handleUpdateGoogleWorkspaceFindingStatus(action) {
  const {updateStatusMessage, findingId} = action.payload

  yield handler({
    handler: _updateGoogleWorkspaceFindingStatus,
    success: [
      () =>
        success({
          message: updateStatusMessage,
        }),
      () => fetchGoogleWorkspaceFinding(findingId),
      () => fetchGoogleWorkspaceFindingActivity(findingId),
    ],
    error: genericError,
    action,
  })
}

const _fetchGoogleWorkspaceAuthorization = body => {
  const url = `${config.SERVER_URL}/workspace/integrations`

  return fetch(url, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleFetchGoogleWorkspaceAuthorization(action) {
  yield handler({
    handler: _fetchGoogleWorkspaceAuthorization,
    success: res => (window.location.href = res.authUrl),
    action,
  })
}

const _updateGoogleWorkspaceIntegration = integrationId => {
  const url = `${config.SERVER_URL}/workspace/integrations/${integrationId}/update`

  return fetch(url, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    credentials: 'include',
  }).catch(err => {
    console.trace(err)
  })
}

export function* handleUpdateGoogleWorkspaceIntegration(action) {
  yield handler({
    handler: _updateGoogleWorkspaceIntegration,
    success: success,
    action,
  })
}
