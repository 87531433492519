import i18next from 'i18next'
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {genericError, integrateGcp, loading} from 'actions'
import {borderStyle, integrationStyles, linkStyle} from 'constant'
import config from 'config'
import images from 'res'

import BackButton from 'components/partials/buttons/BackButton'
import Dropzone from './Dropzone'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'
import {Page} from 'components/partials/Page'
import {isLoading} from 'utils'

export default function GcpTerraformIntegration() {
  const dispatch = useDispatch()

  const csrfState = useSelector(state => state.csrf)
  const loadingState = useSelector(state => state.loading)

  const [file, setFile] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const terraformDownloadLink = `${config.CLIENT_URL}/cloud/gcp-integration.zip`

  const handleClickIntegrate = e => {
    e.preventDefault()

    setFormSubmitted(true)

    if (!file) return

    dispatch(loading({gcpIntegration: true}, {_csrf: csrfState}))

    try {
      const configFile = JSON.parse(file)

      dispatch(
        integrateGcp({
          _csrf: csrfState,
          config: configFile,
          version: config.gcpIntegrationVersion,
        })
      )
    } catch (err) {
      dispatch(
        genericError({
          message: i18next.t(
            'cloud.gcp.manualIntegration.stepFour.invalidConfigFile'
          ),
          loading: {gcpIntegration: false},
        })
      )
      console.trace(err)
    }
  }

  return (
    <Page pageTitle={i18next.t('pageTitles.cloud')}>
      <BackButton fallbackLink="/cloud/gcp/integration/options" />
      <div className={`${borderStyle} bg-white p-6 mt-4`}>
        <div className="lg:w-8/12">
          <div className={integrationStyles.mainTitle}>
            {i18next.t('cloud.gcp.terraformIntegration.title')}
          </div>

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.terraformIntegration.intro.title')}
          </div>
          <div className="text-sm">
            {i18next.t('cloud.gcp.terraformIntegration.intro.body')}
          </div>

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.terraformIntegration.prerequisites.title')}
          </div>
          <div className="text-sm">
            <ul className="list-disc ml-8">
              <li className="mb-1">
                {i18next.t(
                  'cloud.gcp.terraformIntegration.prerequisites.gcpAccount'
                )}
              </li>
              <li className="mb-1">
                {i18next.t(
                  'cloud.gcp.terraformIntegration.prerequisites.gcpCli'
                )}
              </li>
              <li className="mb-1">
                {i18next.t(
                  'cloud.gcp.terraformIntegration.prerequisites.terraform'
                )}
              </li>
            </ul>
          </div>
          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.terraformIntegration.stepOne.title')}
          </div>
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: i18next.t('cloud.gcp.terraformIntegration.stepOne.body', {
                command: `<pre class="inline dm-mono text-xs border bg-astral-50 overflow-auto my-2 p-1">gcloud auth application-default login</pre>`,
                interpolation: {
                  escapeValue: false,
                },
              }),
            }}
          />

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.terraformIntegration.stepTwo.title')}
          </div>
          <div className="text-sm">
            <a
              href={terraformDownloadLink}
              className={linkStyle}
              target="_blank"
              rel="noreferrer">
              {i18next.t('cloud.gcp.terraformIntegration.stepTwo.downloadLink')}
            </a>
            {i18next.t('cloud.gcp.terraformIntegration.stepTwo.body')}
          </div>

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.terraformIntegration.stepThree.title')}
          </div>
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: i18next.t(
                'cloud.gcp.terraformIntegration.stepThree.body',
                {
                  file: `<b>main.tf</b>`,
                  organization_id: `<b>organization_id</b>`,
                  project_ids: `<b>project_ids</b>`,
                  interpolation: {
                    escapeValue: false,
                  },
                }
              ),
            }}
          />
          <figure>
            <img
              src={images.gcpTerraformProjectIds}
              className={integrationStyles.image}
            />
          </figure>

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.terraformIntegration.stepFour.title')}
          </div>
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: i18next.t(
                'cloud.gcp.terraformIntegration.stepFour.body',
                {
                  file: `<b>main.tf</b>`,
                  command: `<code class="dm-mono text-xs border bg-astral-50 overflow-auto my-2 p-1">terraform init</code>`,
                  interpolation: {
                    escapeValue: false,
                  },
                }
              ),
            }}
          />

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.terraformIntegration.stepFive.title')}
          </div>
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: i18next.t(
                'cloud.gcp.terraformIntegration.stepFive.body',
                {
                  command: `<code class="dm-mono text-xs border bg-astral-50 overflow-auto my-2 p-1">terraform apply</code>`,
                  interpolation: {
                    escapeValue: false,
                  },
                }
              ),
            }}
          />

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.terraformIntegration.stepSix.title')}
          </div>
          <div className="text-sm">
            {i18next.t('cloud.gcp.terraformIntegration.stepSix.body')}
          </div>

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.terraformIntegration.stepSeven.title')}
          </div>
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: i18next.t(
                'cloud.gcp.terraformIntegration.stepSeven.body',
                {
                  file: `<b>clientLibraryConfig-[PROJECT_NAME].json</b>`,
                  interpolation: {
                    escapeValue: false,
                  },
                }
              ),
            }}
          />

          <div className="cursor-pointer mr-4 mt-4">
            <Dropzone setFile={setFile} file={file} />
            {formSubmitted && !file ? (
              <div className="text-mars-700 text-center my-1">
                You must upload the config file provided by GCP.
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className={integrationStyles.secondaryTitle}>
            {i18next.t('cloud.gcp.terraformIntegration.conclusion.title')}
          </div>
          <div className="text-sm">
            {i18next.t('cloud.gcp.terraformIntegration.conclusion.body')}

            <div className="w-full mt-10 md:w-56 md:ml-auto">
              <PrimaryButton
                text={i18next.t(
                  'cloud.gcp.manualIntegration.theEnd.monitorMyAccount'
                )}
                type="submit"
                onClick={handleClickIntegrate}
                theme="blue"
                size="full"
                loading={isLoading(loadingState.gcpIntegration, false)}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}
