import React, {useEffect, useState, useRef} from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import PrimaryButton from 'components/partials/buttons/PrimaryButton'

const AcceptRiskModal = ({
  updateFinding,
  modalVisibility,
  setModalVisibility,
}) => {
  const disabledState = useSelector(state => state.disabled)

  const initialFormState = {aceeptRiskJustification: ''}

  const [form, setForm] = useState(initialFormState)
  const justificationTxtAreaRef = useRef()

  const handleFormChange = e => {
    const {name, value} = e.target
    setForm({...form, [name]: value})
  }

  const handleSubmit = e => {
    e.preventDefault()

    updateFinding({
      justification: form.aceeptRiskJustification,
    })

    setModalVisibility(false)
  }

  useEffect(() => {
    document.addEventListener('mouseup', e => {
      const acceptRiskModal = document.querySelector('#aceeptRiskModal')
      if (e.target === acceptRiskModal) setModalVisibility(false)
    })
  }, [])

  useEffect(() => {
    if (modalVisibility === false) return setForm(initialFormState)
    if (justificationTxtAreaRef.current) justificationTxtAreaRef.current.focus()
  }, [modalVisibility])

  return (
    <div
      id="aceeptRiskModal"
      className="modal-bg fixed top-0 left-0 w-screen h-screen z-50">
      <div className="fixed bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 max-w-lg w-full bg-white rounded-md shadow-md p-6">
        <form id="modal-form" onSubmit={handleSubmit}>
          <div
            id="modal-header"
            className="text-sideral-900 leading-none border-b border-sideral-50 text-lg pb-6 mb-6 font-medium">
            {i18next.t('finding.modal.title')}
          </div>
          <div id="modal-body">
            <label
              htmlFor="aceeptRiskJustification"
              className="block tracking-wide text-sideral-700 text-sm font-medium mb-1">
              {i18next.t('finding.modal.label')}
            </label>
            <div className="description text-sm text-gray-600 mb-4">
              {i18next.t('finding.modal.description')}
            </div>
            <textarea
              ref={justificationTxtAreaRef}
              id="aceeptRiskJustification"
              name="aceeptRiskJustification"
              className="border text-sm p-2 w-full mb-4"
              rows="5"
              maxLength="1000"
              onChange={handleFormChange}
            />
          </div>
          <div id="modal-footer" className="flex">
            <div className="w-full">
              <PrimaryButton
                text={i18next.t('finding.modal.concludeBtn')}
                type="submit"
                size="full"
                margin="mr-2"
                loading={disabledState}
              />
            </div>
            <div className="w-full">
              <PrimaryButton
                text={i18next.t('finding.modal.cancelBtn')}
                theme="blue-outline"
                type="button"
                size="full"
                margin="ml-2"
                onClick={() => {
                  setModalVisibility(false)
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

AcceptRiskModal.propTypes = {
  updateFinding: PropTypes.func,
  modalVisibility: PropTypes.bool,
  setModalVisibility: PropTypes.func,
}

export default AcceptRiskModal
