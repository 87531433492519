import React, {useEffect} from 'react'
import Moment from 'react-moment'
import {i18next} from 'translate/i18n'
import PropTypes from 'prop-types'
import {useSelector, useDispatch} from 'react-redux'
import ReactMarkdown from 'react-markdown'
import {useParams} from 'react-router-dom'

import {fetchPartnerFinding} from 'actions'
import {sky900} from 'constant'
import images from 'res'

import Spinner from 'components/partials/Spinner'
import WrappedProperty from 'components/partials/WrappedProperty'
import SeverityChip from 'components/partials/SeverityChip'
import ContentBox from 'components/partials/ContentBox'
import PrimaryInput from 'components/partials/inputs/PrimaryInput'
import BackButton from 'components/partials/buttons/BackButton'
import {Page} from 'components/partials/Page'

export default function PartnerFinding() {
  const partnerFindingState = useSelector(state => state.partnerFinding)

  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    if (params.id) {
      dispatch(fetchPartnerFinding(params.id))
    }
  }, [dispatch, params.id])

  return (
    <Page pageTitle={i18next.t('pageTitles.wiser')}>
      {!partnerFindingState ? <Spinner /> : <></>}

      {partnerFindingState ? (
        <>
          <div className="rounded-lg border border-sideral-100 mb-6">
            <div className="border-b border-sideral-100 flex">
              <div className="w-20 p-4">
                <images.FindingIcon width="60" height="60" color="#90A4AE" />
              </div>
              <div className="w-11/12 p-4 md:flex">
                <div className="w-2/3">
                  <BackButton fallbackLink={'/partner'} />
                  <div className="capitalize-first font-medium text-lg pt-2">
                    {partnerFindingState.title}
                  </div>
                </div>

                <div className="w-1/3 md:mt-0 mt-8">
                  <div className="float-right">
                    <PrimaryInput
                      text="Status"
                      value={partnerFindingState.status.toUpperCase() || '-'}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 md:flex bg-white rounded-lg">
              <div className="flex-1 md:mb-0 mb-6 md:pb-0 pb-6 md:border-none border-b">
                <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
                  <images.InfoIcon size="20" height="20" color={sky900} />
                </div>

                {typeof partnerFindingState.asset === 'string' &&
                !partnerFindingState.hostname ? (
                  <div className="py-1 px-2">
                    <span className="font-semibold mr-2">
                      {i18next.t('misc.asset')}:
                    </span>
                    {partnerFindingState.asset}
                  </div>
                ) : (
                  <>
                    <div className="py-1 px-2">
                      <span className="font-semibold mr-2">
                        {i18next.t('misc.domain')}:
                      </span>
                      {partnerFindingState.hostname}
                    </div>
                    <div className="py-1 px-2">
                      <span className="font-semibold mr-2">
                        {i18next.t('web.port')}:
                      </span>
                      {partnerFindingState.port}
                    </div>{' '}
                    <div className="py-1 px-2">
                      <span className="font-semibold mr-2">
                        {i18next.t('web.protocol')}:
                      </span>
                      {partnerFindingState.protocol}
                    </div>
                  </>
                )}
              </div>

              <div className="flex-1 md:mb-0 mb-6 md:pb-0 pb-6 md:border-none border-b">
                <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 px-2 ml-1">
                  <images.FireIcon size="20" height="20" color={sky900} />
                </div>

                <div>
                  <WrappedProperty
                    label={i18next.t('finding.severity')}
                    helpPath="score"
                    value={
                      <SeverityChip severity={partnerFindingState.severity} />
                    }
                  />
                </div>
              </div>

              <div className="flex-1 md:mb-2 md:pb-0 pb-6 md:border-none last:border-none border-b">
                <div className="bg-sky-50 rounded inline-block w-7 h-7 p-1 ml-1">
                  <images.EyeIcon size="20" height="20" color={sky900} />
                </div>

                <div className="py-1 px-2">
                  <span className="font-semibold mr-2">
                    {i18next.t('finding.firstSeen')}
                  </span>
                  <Moment
                    format="LLL"
                    date={partnerFindingState.createdAt || '-'}
                  />
                </div>
                <div className="py-1 px-2">
                  <span className="font-semibold mr-2">
                    {i18next.t('tables.updatedAt')}
                  </span>
                  <Moment
                    format="LLL"
                    date={partnerFindingState.updatedAt || '-'}
                  />
                </div>
              </div>
            </div>
          </div>
          {partnerFindingState.description && (
            <ContentBox
              header={i18next.t('titles.about')}
              content={
                <div className="template-markdown py-2 rounded-lg break-words overflow-auto">
                  <ReactMarkdown>
                    {partnerFindingState.description}
                  </ReactMarkdown>
                </div>
              }
            />
          )}
        </>
      ) : (
        <></>
      )}
    </Page>
  )
}

PartnerFinding.propTypes = {
  fallbackLink: PropTypes.string,
}
